import { PerformanceReviewIntegrationSource } from "@prisma/client";
import { type NextApiHandler } from "next";
import { mixed, number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  type CompanyPerformanceReviewIntegrationSettings,
  fetchCompanyPerformanceReviewIntegrationSettings,
} from "~/services/performance-review/fetch-company-performance-review-integration-settings";

export const FetchCompanyPerformanceReviewIntegrationSettingsSchema = object({
  companyId: number().required(),
  source: mixed<PerformanceReviewIntegrationSource>()
    .oneOf(Object.values(PerformanceReviewIntegrationSource))
    .required(),
});

export type FetchCompanyPerformanceReviewIntegrationSettingsInput = YupOutputType<
  typeof FetchCompanyPerformanceReviewIntegrationSettingsSchema
>;

const handler: NextApiHandler<CompanyPerformanceReviewIntegrationSettings> = async (req, res) => {
  const input = await FetchCompanyPerformanceReviewIntegrationSettingsSchema.validate(req.body, { abortEarly: false });

  const performanceReviewIntegrationSettings = await fetchCompanyPerformanceReviewIntegrationSettings(req, input);

  return res.status(200).json(performanceReviewIntegrationSettings);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSettings: true,
  },
});

export const FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_SETTINGS_QUERY_KEY: ApiRoute["pathname"] =
  "/api/performance-review/fetch-company-performance-review-integration-settings";

export const useFetchCompanyPerformanceReviewIntegrationSettingsQuery = createQuery<
  typeof handler,
  typeof FetchCompanyPerformanceReviewIntegrationSettingsSchema
>({
  path: FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_SETTINGS_QUERY_KEY,
  schema: FetchCompanyPerformanceReviewIntegrationSettingsSchema,
});
