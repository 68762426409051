import { ChevronLeft } from "@mui/icons-material";
import { Breadcrumbs, IconButton, Stack, Typography } from "@mui/material";
import classNames from "classnames";
import { type FormikComputedProps, type FormikHandlers, type FormikState } from "formik";
import { type Route } from "nextjs-routes";
import { type ComponentProps } from "react";
import { DRAWER_WIDTH, HEADER_HEIGHT, HEADER_HEIGHT_WITH_SUBHEADER } from "~/components/layout/header-layout-drawer";
import { Layout } from "~/components/layout/layout";
import { useShouldDisplayNotices } from "~/components/layout/user-notices";
import { Link, NextLinkComposed } from "~/components/ui/core/Link";
import { Button } from "~/components/ui/core/button";
import { useI18n } from "~/lib/i18n/use-i18n";

export type BreadcrumbItem = { href?: Route; text: string; disabled?: boolean };

type useFormReturnValue = FormikState<unknown> & FormikComputedProps<unknown> & FormikHandlers;

type Props = React.PropsWithChildren<{
  className?: string;
  childrenClassName?: string;
  breadcrumbs?: BreadcrumbItem[];
  title: string | JSX.Element;
  header?: JSX.Element;
  subHeader?: JSX.Element;
  backLink?: Route;
  form?: useFormReturnValue;
  extraActions?: JSX.Element;
  submitText?: string;
  banner?: JSX.Element;
  openDrawer?: boolean;
  withSpacing?: boolean;
}>;

export const HeaderLayout: React.FC<Props> = ({
  className,
  childrenClassName,
  breadcrumbs,
  children,
  form,
  submitText,
  extraActions,
  title,
  header,
  subHeader,
  backLink,
  banner,
  openDrawer,
  withSpacing = true,
}) => {
  const { t } = useI18n();
  const shouldDisplayNotices = useShouldDisplayNotices();

  const fullHeader = (
    <Stack
      className={classNames({
        "sticky top-16 right-0 left-0 z-[1201] border-b border-gray-200 bg-white px-8 py-4 md:top-0 md:left-[84px]":
          true,
        "md:top-8": shouldDisplayNotices,
      })}
      spacing={2}
    >
      <Stack direction="row">
        <Breadcrumbs separator="›">
          {breadcrumbs?.map((breadcrumb) => {
            if (breadcrumb.disabled) {
              return <Typography key={breadcrumb.text}>{breadcrumb.text}</Typography>;
            }

            if (breadcrumb.href) {
              return (
                <Link underline="hover" key={breadcrumb.text} color="inherit" to={breadcrumb.href} className="mr-1">
                  {breadcrumb.text}
                </Link>
              );
            }

            return (
              // eslint-disable-next-line figures/forbid-props
              <Typography key={breadcrumb.text} color="primary" className="-my-1 ml-1 bg-primary-100 py-1 px-2">
                {breadcrumb.text}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Stack>

      {header ? (
        header
      ) : (
        <Stack>
          <Stack direction="row" justifyContent="space-between" className="max-h-12" spacing={4}>
            {backLink && (
              <IconButton className="-mx-4 mt-1 h-8 w-8" component={NextLinkComposed} to={backLink}>
                <ChevronLeft fontSize="inherit" />
              </IconButton>
            )}
            <Stack direction="row" alignItems="center" className="flex-1 overflow-hidden">
              <Typography variant="h1" noWrap>
                {title}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="stretch" spacing={2} className="whitespace-nowrap">
              {extraActions}
              {form && (
                <Button
                  disabled={!form.isValid}
                  isLoading={form.isSubmitting}
                  type="submit"
                  size="small"
                  variant="contained"
                >
                  {submitText ?? t("common.save")}
                </Button>
              )}
            </Stack>
          </Stack>
          {subHeader}
        </Stack>
      )}
    </Stack>
  );

  const childrenWithDrawer = (
    <div style={{ marginRight: openDrawer ? DRAWER_WIDTH : 0 }}>
      {banner && (
        <div className="sticky z-[1201]" style={{ top: subHeader ? HEADER_HEIGHT_WITH_SUBHEADER : HEADER_HEIGHT }}>
          {banner}
        </div>
      )}

      <div
        className={classNames(childrenClassName, "mb-16 mt-6", {
          "mx-8": withSpacing,
        })}
      >
        {children}
      </div>
    </div>
  );

  return (
    <Layout fluid fullscreen className={classNames("mx-0 ", className)}>
      {form ? (
        <form onSubmit={form.handleSubmit}>
          {fullHeader}

          {childrenWithDrawer}
        </form>
      ) : (
        <div className="w-full">
          {fullHeader}

          {childrenWithDrawer}
        </div>
      )}
    </Layout>
  );
};

export const HeaderLayoutBanner: React.FC<ComponentProps<typeof Stack>> = ({ children, className, ...props }) => {
  return (
    <Stack className={classNames(className, "px-8 py-4")} {...props}>
      {children}
    </Stack>
  );
};
