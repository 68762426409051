import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  type GetActiveFlagByEmployeeIdResult,
  getActiveFlagByEmployeeId,
} from "~/services/employee-data-validation-flag/get-employee-data-validation-flag-by-id";

export const GetActiveFlagbyEmployeeIdSchema = object({
  employeeId: number().required(),
});

export type GetActiveFlagByEmployeeIdInput = YupOutputType<typeof GetActiveFlagbyEmployeeIdSchema>;

const handler: NextApiHandler<GetActiveFlagByEmployeeIdResult> = async (req, res) => {
  const input = GetActiveFlagbyEmployeeIdSchema.validateSync(req.body, {
    abortEarly: false,
  });

  const employeeDataValidationFlag = await getActiveFlagByEmployeeId(req, input);

  res.setHeader("Cache-Control", "s-maxage=1, stale-while-revalidate");
  res.status(HttpStatus.OK).json(employeeDataValidationFlag);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessRawData: true },
});

export const GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY: ApiRoute["pathname"] =
  "/api/employee-data-validation-flag/get-active-flag-by-employee-id";

export const useGetActiveFlagByEmployeeId = createQuery<typeof handler, typeof GetActiveFlagbyEmployeeIdSchema>({
  path: GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY,
  schema: GetActiveFlagbyEmployeeIdSchema,
});
