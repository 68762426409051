import { Error, Warning } from "@mui/icons-material";
import { Chip, Collapse, Slide, Stack, Tab, Tabs } from "@mui/material";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import {
  EXTERNAL_EMPLOYEE_DRAWER_TAB_KEY,
  type ExtraTabForExternalEmployeeDrawer,
} from "~/components/external-employee/drawer/external-employee-drawer";
import { EmployeeHeader } from "~/components/external-employee/drawer/header";
import { Overview } from "~/components/external-employee/drawer/overview";
import { EmployeeFormProvider, useEmployeeForm } from "~/components/external-employee/drawer/use-employee-form";
import { ExternalEmployeeCompensationHistory } from "~/components/external-employee/external-employee-compensation-history";
import { OffCycleReviewRequestsBanner } from "~/components/external-employee/off-cycle-review-requests-banner";
import { Mode, ModeProvider, useEditMode } from "~/components/external-employee/use-edit-mode";
import { value } from "~/components/helpers";
import { isCustomChoice } from "~/components/ui/core/SelectAChoiceModal";
import { EmployeeUpdateReasonSelectorModal } from "~/components/ui/employee-update-reason-selector-modal";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import { parseString } from "~/lib/query-params";
import { currentUrlWithQueryParams } from "~/lib/url";
import { canManageEmployee } from "~/services/employee/permissions";

type Props = {
  externalEmployeeId: number;
  extraTabs: ExtraTabForExternalEmployeeDrawer[] | undefined;
  defaultTab: string;
};

export type ExternalEmployeeDrawerPossibleTab = "overview" | "compensation-history";

export const ExternalEmployeeDrawerContent: React.FC<Props> = ({ externalEmployeeId, defaultTab, extraTabs }) => {
  const { user } = useSession();
  const canEdit = canManageEmployee({ user });

  return (
    <ModeProvider defaultMode={canEdit ? Mode.EDIT : Mode.DISPLAY}>
      <EmployeeFormProvider externalEmployeeId={externalEmployeeId}>
        <TabbedContent extraTabs={extraTabs} defaultTab={defaultTab} />
      </EmployeeFormProvider>
    </ModeProvider>
  );
};

type TabbedContentProps = {
  extraTabs: ExtraTabForExternalEmployeeDrawer[] | undefined;
  defaultTab: string;
};

const TabbedContent: React.FC<TabbedContentProps> = ({ extraTabs, defaultTab }) => {
  const { t } = useI18n();
  const router = useRouter();
  const featureFlags = useFeatureFlags();
  const { externalEmployeeId, externalEmployee, isLoading, form } = useEmployeeForm();
  const [showEmployeeUpdateReasonModal, setShowEmployeeUpdateReasonModal] = useState(false);

  const parsedTab = parseString(router.query, EXTERNAL_EMPLOYEE_DRAWER_TAB_KEY);
  const tab = (parsedTab as ExternalEmployeeDrawerPossibleTab) ?? defaultTab;
  const extraTab = extraTabs?.find((extraTab) => extraTab.value === tab);

  useEffect(() => {
    if (parsedTab === null) {
      void router.replace(currentUrlWithQueryParams(router, { [EXTERNAL_EMPLOYEE_DRAWER_TAB_KEY]: tab }), undefined);
    }
  }, [parsedTab]);

  const onTabChange = (tab: string) => {
    void router.push(currentUrlWithQueryParams(router, { [EXTERNAL_EMPLOYEE_DRAWER_TAB_KEY]: tab }), undefined, {
      shallow: true,
    });
  };

  const banner = value(() => {
    if (!externalEmployee) return;

    const request = externalEmployee.offCycleReviewRequests[0];
    if (featureFlags.CAN_ACCESS_OFF_CYCLE_REVIEWS && !!request) {
      return <OffCycleReviewRequestsBanner externalEmployee={externalEmployee} request={request} />;
    }
  });

  const content = (
    <>
      <div className="sticky top-0 z-20 bg-white">
        {banner && <div className="border-b border-secondary-500">{banner}</div>}
        <div className="h-12 bg-primary-100"></div>
        <div className="-mt-10 px-4">
          <EmployeeHeader tab={tab} />

          <Tabs value={tab} onChange={(e, tab) => onTabChange(tab)} className="mb-0 mt-4 border-b">
            <Tab
              disabled={isLoading}
              label={t("components.external-employee-drawer.tabs.overview")}
              value="overview"
              className="normal-case"
            />
            <Tab
              disabled={isLoading}
              label={t("components.external-employee-drawer.tabs.compensation-history")}
              value="compensation-history"
              className="normal-case"
            />
            {extraTabs?.map((extraTab) => (
              <Tab
                key={extraTab.value}
                disabled={isLoading}
                label={extraTab.label}
                value={extraTab.value}
                className="normal-case"
              />
            ))}

            <div className="ml-auto">
              <VisualHelpers tab={tab} />
            </div>
          </Tabs>
        </div>
      </div>

      <div className="px-4 py-6">
        {tab === "overview" && <Overview />}
        {tab === "compensation-history" && (
          <ExternalEmployeeCompensationHistory externalEmployeeId={externalEmployeeId} />
        )}
        {extraTab && extraTab.component}
      </div>
    </>
  );

  // wrap everything in a form to prevent to be able to show the save button at the top...
  return (
    <form
      onSubmit={(event) => {
        if (tab !== "overview") {
          event.preventDefault();
          return;
        }

        setShowEmployeeUpdateReasonModal(true);
        event.preventDefault();
      }}
    >
      {tab === "overview" && (
        <EmployeeUpdateReasonSelectorModal
          isOpen={showEmployeeUpdateReasonModal}
          onClose={() => setShowEmployeeUpdateReasonModal(false)}
          onSubmit={async (reason) => {
            await form.asyncUpdateValues({
              updateReason: isCustomChoice(reason) ? reason.customValue ?? reason.label : reason.label,
              updateStrategy: reason.strategy ?? undefined,
            });
            form.handleSubmit();
          }}
        />
      )}
      {content}
    </form>
  );
};

const VisualHelpers: React.FC<{ tab: ExternalEmployeeDrawerPossibleTab }> = ({ tab }) => {
  const { t } = useI18n();
  const { mode } = useEditMode();
  const { unsavedChanges, form } = useEmployeeForm();

  const errorsCount = useMemo(() => Object.keys(form.errors).length, [form.errors]);

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      {mode === Mode.EDIT && tab === "overview" && (
        <>
          <Collapse orientation="horizontal" in={errorsCount > 0} mountOnEnter unmountOnExit>
            <Chip
              variant="outlined"
              color="error"
              icon={<Error className="!ml-1.5" />}
              size="small"
              label={t("components.external-employee-drawer.feedback.errors", {
                count: errorsCount,
              })}
            />
          </Collapse>

          <Slide direction="left" in={unsavedChanges > 0 && !form.isSubmitting} mountOnEnter unmountOnExit>
            <Chip
              variant="outlined"
              color="warning"
              icon={<Warning className="!ml-1.5" />}
              size="small"
              label={t("components.external-employee-drawer.feedback.unsaved-changes", {
                count: unsavedChanges,
              })}
            />
          </Slide>
        </>
      )}
    </Stack>
  );
};
