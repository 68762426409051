/*
 * This function gives us a flattened object with keys in dot notation.
 * For instance: { user: { id: 3 } } becomes { "user.id": 3 }.
 * This is required as datadog only supports flat objects but recreates them in the web app.
 * */
import { isDate } from "lodash";

export const flattenObject = (obj?: Record<string, unknown> | null, prefix = "") => {
  if (!obj) {
    return {};
  }

  return Object.entries(obj).reduce((acc: Record<string, unknown>, [key, value]) => {
    const newPrefix = (prefix.length ? prefix + "." : "") + key;

    if (isDate(value)) {
      acc[newPrefix] = value.toISOString();
      return acc;
    }

    if (typeof value === "object" && value !== null) {
      Object.assign(acc, flattenObject(value as Record<string, unknown>, newPrefix));
      return acc;
    }

    acc[newPrefix] = value;
    return acc;
  }, {});
};
