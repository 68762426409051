import { UserLocale } from "@prisma/client";
import { pick } from "lodash";
import { type GetServerSidePropsResult } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { type GetServerSideProps, type GetServerSidePropsContext, type Route } from "nextjs-routes";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type ApiHandlerOptions, ApiValidationError, ForbiddenError } from "~/lib/api";
import { generateUserHMAC } from "~/lib/crypto";
import { trackPageRequested } from "~/lib/external/segment/server/events";
import { logError, logWarn } from "~/lib/logger";
import { NEXT_PROPS_ERROR_KEY_NAME } from "~/lib/nextPropsErrorKeyName";
import { parseString } from "~/lib/query-params";
import { notFound, redirect } from "~/lib/redirect";
import { guard, SessionKey } from "~/lib/session";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { getOnboardingRedirection } from "~/services/onboarding/get-onboarding-redirection";
import { productToPermission } from "~/services/subscriptions/utils";

export const isServer = typeof window === "undefined";

export const isClient = !isServer;

export const ssr = <T extends Record<string, unknown>, R extends Route["pathname"]>(
  handler?: GetServerSideProps<T, R>,
  options?: ApiHandlerOptions & {
    i18nNamespace?: string;
  }
): GetServerSideProps<T, R> => {
  return async (ctx: GetServerSidePropsContext<R>) => {
    try {
      let user: NullableAuthenticatedUser = null;
      let userHash: string | null = null;
      let impersonatingCompany = false;
      let impersonatingUser = false;

      const {
        user: resolvedUser,
        impersonatingCompany: resolvedImpersonatingCompany,
        impersonatingUser: resolvedImpersonatingUser,
        redirection,
      } = await guard(ctx, options?.authentication);

      if (redirection) {
        return redirect(redirection);
      }

      user = resolvedUser;
      userHash = ctx.req.session.get<string>(SessionKey.USER_HASH) ?? null;
      impersonatingCompany = resolvedImpersonatingCompany === true;
      impersonatingUser = resolvedImpersonatingUser === true;

      if (!userHash) {
        userHash = generateUserHMAC(resolvedUser);
        ctx.req.session.set(SessionKey.USER_HASH, userHash);
        await ctx.req.session.save();
      }

      const onboardingRedirection = getOnboardingRedirection(ctx);
      if (onboardingRedirection) {
        return redirect(onboardingRedirection);
      }

      if (options?.feature && !ctx.req.featureFlags[options.feature]) {
        return redirect({ pathname: "/home" });
      }

      if (options?.product && !ctx.req.subscriptions[productToPermission(options.product)]) {
        return redirect({ pathname: "/home" });
      }

      if (options?.access) {
        const redirectPath = options?.access(ctx.req);

        if (redirectPath) {
          return redirect(redirectPath);
        }
      }

      const res = await value(async () => {
        if (!handler) {
          return { props: {} };
        }

        try {
          return await handler(ctx);
        } catch (error) {
          if (error.name === "NotFoundError") {
            return notFound();
          }

          if (error instanceof ForbiddenError && error.redirect) {
            return redirect(error.redirect);
          }

          throw error;
        } finally {
          await trackPageRequested(ctx.req, { ctx });
        }
      });

      const locale = (parseString(ctx.query, "locale") ?? user?.locale ?? UserLocale.EN).toLowerCase();

      const translations = await serverSideTranslations(locale);
      if (translations._nextI18Next) {
        translations._nextI18Next.initialI18nStore = pick(translations._nextI18Next?.initialI18nStore, locale);
      }

      if ("props" in res) {
        const props = await res.props;

        // `user` and `impersonating` are globally injected within props but never accessible through their type.
        // They're extracted inside a React context in _app.tsx

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props.user = user;
        // eslint-disable-next-line
        // @ts-ignore
        props.userHash = userHash;
        // eslint-disable-next-line
        // @ts-ignore
        props.impersonatingCompany = impersonatingCompany;
        // eslint-disable-next-line
        // @ts-ignore
        props.impersonatingUser = impersonatingUser;
        // eslint-disable-next-line
        // @ts-ignore
        props.featureFlags = ctx.req.featureFlags;
        // eslint-disable-next-line
        // @ts-ignore
        props.subscriptions = ctx.req.subscriptions;
        // eslint-disable-next-line
        // @ts-ignore
        props._permissions = ctx.req.globalPermissionsContext;
        // eslint-disable-next-line
        // @ts-ignore
        Object.assign(props, translations);

        // In development, to avoid a known issue with Next.js + Prisma,
        // serialize everything returned by queries to JSON, to avoid
        // passing `Date` instances to `getServerSideProps`.
        // @see https://github.com/vercel/next.js/issues/11993
        if (config.app.env === "development") {
          res.props = JSON.parse(JSON.stringify(props));
        }
      }

      return res as GetServerSidePropsResult<T>;
    } catch (error) {
      if (error instanceof ApiValidationError) {
        logWarn(ctx.req, "[ssr] Query Parameter Validation error", { error });
      } else {
        logError(ctx.req, "[ssr] Generic error", { error });
      }

      if (config.app.env === "development") {
        throw error;
      }

      return {
        props: {
          [NEXT_PROPS_ERROR_KEY_NAME]: true,
          error: {
            statusCode: error.statusCode,
            message: value(() => {
              if (error.statusCode === 500 || error.statusCode === "500") {
                return null;
              }

              return error.message;
            }),
          },
        } as unknown as T,
      };
    }
  };
};
