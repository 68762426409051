import { type PermissionsParams } from "~/services/user/permissions/authentication-options";
import { canAccessCompensationReview } from "~/services/user/permissions/feature-access-permissions";
import { getUserRoles } from "~/services/user/permissions/utils/get-user-roles";

export const canAccessCompensationReviewBonusAllocation = (params: PermissionsParams): boolean => {
  const { featureFlags } = params;
  if (!canAccessCompensationReview(params)) return false;

  return !!featureFlags?.CAN_ACCESS_COMPENSATION_REVIEW_BONUS_ALLOCATION;
};

export const canAccessOffCycleReviews = (params: PermissionsParams): boolean => {
  const { featureFlags } = params;

  if (!canAccessCompensationReview(params)) return false;

  return !!featureFlags?.CAN_ACCESS_OFF_CYCLE_REVIEWS;
};

export const canCreateCompensationReviewCampaign = (params: PermissionsParams): boolean => {
  const { user } = params;
  if (!canAccessCompensationReview(params)) return false;

  if (!user) return false;

  return getUserRoles(user).isAdmin;
};

export const canDeleteCompensationReviewCampaign = (params: PermissionsParams): boolean => {
  const { user } = params;
  if (!canAccessCompensationReview(params)) return false;

  if (!user) return false;

  return getUserRoles(user).isAdmin;
};
