import { type Prisma } from "@prisma/client";
import { externalEmployeeFieldsSelectForDisplay } from "~/services/additional-field/get-all-additional-fields";
import { externalEmployeeSelectForDisplay } from "~/services/compensation-review/campaigns/admin/fetch-compensation-review-employees";
import { CurrentOffCycleReviewRequestStatuses } from "~/services/compensation-review/off-cycle-reviews/off-cycle-review-request-status";

export const externalEmployeePanelSelect = {
  ...externalEmployeeSelectForDisplay,
  ...externalEmployeeFieldsSelectForDisplay,
  offCycleReviewRequests: {
    where: {
      status: { in: CurrentOffCycleReviewRequestStatuses },
    },
    select: {
      id: true,
      status: true,
    },
  },
  mappedEmployee: {
    select: {
      baseSalary: true,
      onTargetBonus: true,
      fixedBonus: true,
      currency: {
        select: {
          code: true,
          euroExchangeRate: true,
        },
      },
    },
  },
  currency: true,
  remunerationItems: {
    orderBy: [
      {
        date: {
          sort: "desc",
          nulls: "last",
        },
      },
      { amount: "desc" },
    ],
    select: {
      amount: true,
      date: true,
      status: true,
      nature: {
        select: {
          name: true,
          mappedType: true,
        },
      },
    },
  },
  job: {
    select: {
      name: true,
    },
  },
  level: {
    select: {
      name: true,
    },
  },
  location: {
    select: {
      name: true,
      country: {
        select: {
          name: true,
          alpha2: true,
        },
      },
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

export type ExternalEmployeePanelSelect = Prisma.ExternalEmployeeGetPayload<{
  select: typeof externalEmployeePanelSelect;
}>;
