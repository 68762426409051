import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { FETCH_IMPERSONATION_ACCESS_HISTORY_QUERY_KEY } from "~/pages/api/auth/company-impersonation/fetch-impersonation-access-history";
import { FETCH_IMPERSONATION_ACCESS_REQUEST_QUERY_KEY } from "~/pages/api/auth/company-impersonation/find-impersonation-access-request";
import { HAS_IMPERSONATION_ACCESS_QUERY_KEY } from "~/pages/api/auth/company-impersonation/has-impersonation-access";
import { declineImpersonationAccessRequest } from "~/services/impersonation/decline-impersonation-access-request";

const handler: NextApiHandler = async (req, res) => {
  await declineImpersonationAccessRequest(req);

  res.status(HttpStatus.OK).json({ message: "OK" });
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSettings: true,
  },
});

export const useDeclineImpersonationAccessMutation = createMutation<typeof handler>({
  path: "/api/auth/company-impersonation/decline-impersonation-access",
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, HAS_IMPERSONATION_ACCESS_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_IMPERSONATION_ACCESS_HISTORY_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_IMPERSONATION_ACCESS_REQUEST_QUERY_KEY);
    },
  }),
});
