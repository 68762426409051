import { integrationIcons } from "./integration-icons";

export type IconName = keyof typeof icons;

/**
 * @see https://icones.netlify.app/collection/mdi (copy JSX)
 */

export const icons = {
  ...integrationIcons,

  "account-group": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9A3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42c-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3a3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.536 5.536 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13v-1.75M0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9c-.59.68-.95 1.62-.95 2.65V20H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65c2.56.34 4.45 1.51 4.45 2.9V20Z"
      ></path>
    </svg>
  ),

  "account-group-outlined": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 5a3.5 3.5 0 0 0-3.5 3.5A3.5 3.5 0 0 0 12 12a3.5 3.5 0 0 0 3.5-3.5A3.5 3.5 0 0 0 12 5m0 2a1.5 1.5 0 0 1 1.5 1.5A1.5 1.5 0 0 1 12 10a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 12 7M5.5 8A2.5 2.5 0 0 0 3 10.5c0 .94.53 1.75 1.29 2.18c.36.2.77.32 1.21.32c.44 0 .85-.12 1.21-.32c.37-.21.68-.51.91-.87A5.42 5.42 0 0 1 6.5 8.5v-.28c-.3-.14-.64-.22-1-.22m13 0c-.36 0-.7.08-1 .22v.28c0 1.2-.39 2.36-1.12 3.31c.12.19.25.34.4.49a2.482 2.482 0 0 0 1.72.7c.44 0 .85-.12 1.21-.32c.76-.43 1.29-1.24 1.29-2.18A2.5 2.5 0 0 0 18.5 8M12 14c-2.34 0-7 1.17-7 3.5V19h14v-1.5c0-2.33-4.66-3.5-7-3.5m-7.29.55C2.78 14.78 0 15.76 0 17.5V19h3v-1.93c0-1.01.69-1.85 1.71-2.52m14.58 0c1.02.67 1.71 1.51 1.71 2.52V19h3v-1.5c0-1.74-2.78-2.72-4.71-2.95M12 16c1.53 0 3.24.5 4.23 1H7.77c.99-.5 2.7-1 4.23-1Z"
      ></path>
    </svg>
  ),

  "account-multiple-plus-outline": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M13 11a3 3 0 1 0-3-3a3 3 0 0 0 3 3m0-4a1 1 0 1 1-1 1a1 1 0 0 1 1-1m4.11 3.86a5 5 0 0 0 0-5.72A2.91 2.91 0 0 1 18 5a3 3 0 0 1 0 6a2.91 2.91 0 0 1-.89-.14M13 13c-6 0-6 4-6 4v2h12v-2s0-4-6-4m-4 4c0-.29.32-2 4-2c3.5 0 3.94 1.56 4 2m7 0v2h-3v-2a5.6 5.6 0 0 0-1.8-3.94C24 13.55 24 17 24 17M8 12H5v3H3v-3H0v-2h3V7h2v3h3Z"
      ></path>
    </svg>
  ),

  "add-user": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M15 4a4 4 0 0 0-4 4a4 4 0 0 0 4 4a4 4 0 0 0 4-4a4 4 0 0 0-4-4m0 1.9a2.1 2.1 0 1 1 0 4.2A2.1 2.1 0 0 1 12.9 8A2.1 2.1 0 0 1 15 5.9M4 7v3H1v2h3v3h2v-3h3v-2H6V7H4m11 6c-2.67 0-8 1.33-8 4v3h16v-3c0-2.67-5.33-4-8-4m0 1.9c2.97 0 6.1 1.46 6.1 2.1v1.1H8.9V17c0-.64 3.1-2.1 6.1-2.1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "announcement": () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 9h-2V5h2v6zm0 4h-2v-2h2v2z"
        fill="currentColor"
      />
    </svg>
  ),

  "arrow-bottom-right": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M5 6.41L6.41 5L17 15.59V9h2v10H9v-2h6.59L5 6.41z" fill="currentColor"></path>
    </svg>
  ),

  "arrow-down": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M11 4h2v12l5.5-5.5l1.42 1.42L12 19.84l-7.92-7.92L5.5 10.5L11 16V4z" fill="currentColor"></path>
    </svg>
  ),

  "arrow-left": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M18 11v2h-8l3.5 3.5l-1.42 1.42L6.16 12l5.92-5.92L13.5 7.5L10 11h8M2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10a10 10 0 0 1-10 10A10 10 0 0 1 2 12m2 0a8 8 0 0 0 8 8a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8Z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "arrow-right": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M6 13v-2h8l-3.5-3.5l1.42-1.42L17.84 12l-5.92 5.92l-1.42-1.42L14 13H6m16-1a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10m-2 0a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8a8 8 0 0 0 8-8z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "arrow-top-right": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M5 17.59L15.59 7H9V5h10v10h-2V8.41L6.41 19L5 17.59z" fill="currentColor"></path>
    </svg>
  ),

  "arrow-top-right-outlined": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 3.97c-4.41 0-8.03 3.62-8.03 8.03c0 4.41 3.62 8.03 8.03 8.03c4.41 0 8.03-3.62 8.03-8.03c0-4.41-3.62-8.03-8.03-8.03M12 2c5.54 0 10 4.46 10 10s-4.46 10-10 10S2 17.54 2 12S6.46 2 12 2m1.88 9.53L16 13.64V8h-5.64l2.11 2.12L7.5 15.1l1.4 1.4"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "arrow-up": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M13 20h-2V8l-5.5 5.5l-1.42-1.42L12 4.16l7.92 7.92l-1.42 1.42L13 8v12z" fill="currentColor"></path>
    </svg>
  ),

  "arrow-up-down": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M17.45 17.55L12 23l-5.45-5.45l1.41-1.41L11 19.17V4.83L7.96 7.86L6.55 6.45L12 1l5.45 5.45l-1.41 1.41L13 4.83v14.34l3.04-3.03l1.41 1.41Z"
      ></path>
    </svg>
  ),

  "average": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M2 15s0-6 6-6c4 0 4.5 3.5 7.5 3.5c4 0 4-3.5 4-3.5H22s0 6-6 6c-4 0-5.5-3.5-7.5-3.5c-4 0-4 3.5-4 3.5H2"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "back": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M5 13l4 4l-1.4 1.42L1.18 12L7.6 5.58L9 7l-4 4h16v2H5m16-7v2H11V6h10m0 10v2H11v-2h10z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "bar-chart": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M22 21H2V3h2v16h2v-9h4v9h2V6h4v13h2v-5h4v7z" fill="currentColor"></path>
    </svg>
  ),

  "bell": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M23 7v6h-2V7m0 8h2v2h-2M12 2a2 2 0 0 0-2 2a2 2 0 0 0 0 .29C7.12 5.14 5 7.82 5 11v6l-2 2v1h18v-1l-2-2v-6c0-3.18-2.12-5.86-5-6.71A2 2 0 0 0 14 4a2 2 0 0 0-2-2m-2 19a2 2 0 0 0 2 2a2 2 0 0 0 2-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "binoculars": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M11 6h2v7h-2V6M9 20a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-5l2-9h4v7a1 1 0 0 1-1 1v6m1-15H7V3h3v2m5 15v-6a1 1 0 0 1-1-1V6h4l2 9v5a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1M14 5V3h3v2h-3z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "block": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 0a12 12 0 0 1 12 12a12 12 0 0 1-12 12A12 12 0 0 1 0 12A12 12 0 0 1 12 0m0 2A10 10 0 0 0 2 12c0 2.4.85 4.6 2.26 6.33L18.33 4.26A9.984 9.984 0 0 0 12 2m0 20a10 10 0 0 0 10-10c0-2.4-.85-4.6-2.26-6.33L5.67 19.74A9.984 9.984 0 0 0 12 22z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "broom": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m19.36 2.72l1.42 1.42l-5.72 5.71c1.07 1.54 1.22 3.39.32 4.59L9.06 8.12c1.2-.9 3.05-.75 4.59.32l5.71-5.72M5.93 17.57c-2.01-2.01-3.24-4.41-3.58-6.65l4.88-2.09l7.44 7.44l-2.09 4.88c-2.24-.34-4.64-1.57-6.65-3.58Z"
      ></path>
    </svg>
  ),

  "building": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5 3v18h6v-3.5h2V21h6V3H5m2 2h2v2H7V5m4 0h2v2h-2V5m4 0h2v2h-2V5M7 9h2v2H7V9m4 0h2v2h-2V9m4 0h2v2h-2V9m-8 4h2v2H7v-2m4 0h2v2h-2v-2m4 0h2v2h-2v-2m-8 4h2v2H7v-2m8 0h2v2h-2v-2Z"
      ></path>
    </svg>
  ),

  "bulb": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M20 11h3v2h-3v-2M1 11h3v2H1v-2M13 1v3h-2V1h2M4.92 3.5l2.13 2.14l-1.42 1.41L3.5 4.93L4.92 3.5m12.03 2.13l2.12-2.13l1.43 1.43l-2.13 2.12l-1.42-1.42M12 6a6 6 0 0 1 6 6c0 2.22-1.21 4.16-3 5.2V19a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-1.8c-1.79-1.04-3-2.98-3-5.2a6 6 0 0 1 6-6m2 15v1a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-1h4m-3-3h2v-2.13c1.73-.44 3-2.01 3-3.87a4 4 0 0 0-4-4a4 4 0 0 0-4 4c0 1.86 1.27 3.43 3 3.87V18z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "calendar": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M19 4h-1V2h-2v2H8V2H6v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2m0 16H5V10h14v10M5 8V6h14v2H5m5.56 10.46l5.94-5.93l-1.07-1.06l-4.87 4.87l-2.11-2.11l-1.06 1.06l3.17 3.17z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "calendar-cursor": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M21.7 20.26l-1.41 1.41l-3.23-3.17L16 20.97L14 14l7 2l-2.53 1.03l3.23 3.23M19 8H5v11h8.41l.56 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1V1h2v2h8V1h2v2h1c1.1 0 2 .9 2 2v8.95l-2-.6V8m-7 2v5H7v-5h5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "cancel-user": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M10 4a4 4 0 0 0-4 4a4 4 0 0 0 4 4a4 4 0 0 0 4-4a4 4 0 0 0-4-4m0 2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0 7c-2.67 0-8 1.33-8 4v3h9.5a6.5 6.5 0 0 1-.47-1.9H3.9V17c0-.64 3.13-2.1 6.1-2.1c.5 0 1 .05 1.5.13a6.5 6.5 0 0 1 1.05-1.74C11.61 13.1 10.71 13 10 13m7.5 0C15 13 13 15 13 17.5s2 4.5 4.5 4.5s4.5-2 4.5-4.5s-2-4.5-4.5-4.5m0 1.5c1.66 0 3 1.34 3 3c0 .56-.15 1.08-.42 1.5L16 14.92c.42-.27.94-.42 1.5-.42M14.92 16L19 20.08c-.42.27-.94.42-1.5.42c-1.66 0-3-1.34-3-3c0-.56.15-1.08.42-1.5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "chart": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M17.45 15.18L22 7.31V21H2V3h2v12.54L9.5 6L16 9.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L4.31 19h2.26l4.39-7.56l6.49 3.74z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "chart-arc": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M16.18 19.6l-2.01-3.48c.98-.72 1.66-1.84 1.8-3.12H20a8.55 8.55 0 0 1-3.82 6.6M13 7.03V3c4.3.26 7.74 3.7 8 8h-4.03A4.488 4.488 0 0 0 13 7.03M7 12.5c0 .64.13 1.25.38 1.8L3.9 16.31A8.42 8.42 0 0 1 3 12.5C3 7.97 6.54 4.27 11 4v4.03c-2.25.25-4 2.15-4 4.47m4.5 8.5c-2.97 0-5.58-1.5-7.1-3.82l3.48-2.01A4.47 4.47 0 0 0 11.5 17c.64 0 1.25-.13 1.8-.38l2.01 3.48c-1.15.58-2.44.9-3.81.9z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "chart-area-spline": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M17.45 15.18L22 7.31V21H2V3h2v12.54L9.5 6L16 9.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L4.31 19h2.26l4.39-7.56l6.49 3.74z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "chart-waterfall": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path fill="currentColor" d="M2 2h2v18h18v2H2V2m15 0h3v16h-3V2M6 11h3v7H6v-7m7-8h3v4h-3V3m-3 5h3v4h-3V8Z"></path>
    </svg>
  ),

  "chat-question": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 3C6.5 3 2 6.6 2 11c0 2.2 1.1 4.2 2.8 5.5c0 .6-.4 2.2-2.8 4.5c2.4-.1 4.6-1 6.5-2.5c1.1.3 2.3.5 3.5.5c5.5 0 10-3.6 10-8s-4.5-8-10-8m0 14c-4.4 0-8-2.7-8-6s3.6-6 8-6s8 2.7 8 6s-3.6 6-8 6m.2-10.5c-.9 0-1.6.2-2.1.5c-.6.4-.9 1-.8 1.7h2c0-.3.1-.5.3-.6c.2-.1.4-.2.7-.2c.3 0 .6.1.8.3c.2.2.3.4.3.7c0 .3-.1.5-.2.7c-.2.2-.4.4-.6.5c-.5.3-.9.6-1.1.8c-.4.3-.5.6-.5 1.1h2c0-.3.1-.5.1-.7c.1-.2.3-.3.5-.5c.5-.2.8-.5 1.1-.9c.3-.4.4-.8.4-1.2c0-.7-.3-1.3-.8-1.7c-.4-.3-1.2-.5-2.1-.5M11 13v2h2v-2h-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "message-question": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m-7 12h-2v-2h2v2m1.8-5c-.3.4-.7.6-1.1.8c-.3.2-.4.3-.5.5c-.2.2-.2.4-.2.7h-2c0-.5.1-.8.3-1.1c.2-.2.6-.5 1.1-.8c.3-.1.5-.3.6-.5c.1-.2.2-.5.2-.7c0-.3-.1-.5-.3-.7c-.2-.2-.5-.3-.8-.3c-.3 0-.5.1-.7.2c-.2.1-.3.3-.3.6h-2c.1-.7.4-1.3.9-1.7c.5-.4 1.2-.5 2.1-.5c.9 0 1.7.2 2.2.6c.5.4.8 1 .8 1.7c.1.4 0 .8-.3 1.2Z"
      ></path>
    </svg>
  ),

  "check": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4l8-8l-1.41-1.42z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "checkbox-blank": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 2v14H5V5h14z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "checkbox-checked": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 2v14H5V5h14m-9 12l-4-4l1.41-1.42L10 14.17l6.59-6.59L18 9"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "check-circle-outlined": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4l8-8l-1.41-1.42Z"
      ></path>
    </svg>
  ),

  "chevron-down": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6l1.41-1.42Z" fill="currentColor"></path>
    </svg>
  ),

  "chevron-left": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6l6 6l1.41-1.42z" fill="currentColor"></path>
    </svg>
  ),

  "chevron-double-left": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M18.41 7.41L17 6l-6 6l6 6l1.41-1.41L13.83 12l4.58-4.59m-6 0L11 6l-6 6l6 6l1.41-1.41L7.83 12l4.58-4.59Z"
      ></path>
    </svg>
  ),

  "chevron-right": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor"></path>
    </svg>
  ),

  "chevron-double-right": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M5.59 7.41L7 6l6 6l-6 6l-1.41-1.41L10.17 12L5.59 7.41m6 0L13 6l6 6l-6 6l-1.41-1.41L16.17 12l-4.58-4.59Z"
      ></path>
    </svg>
  ),

  "chevron-up": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6l1.41 1.41Z" fill="currentColor"></path>
    </svg>
  ),

  "clock": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7h1.5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "close": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2m2.59 6L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "code": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M8 3a2 2 0 0 0-2 2v4a2 2 0 0 1-2 2H3v2h1a2 2 0 0 1 2 2v4a2 2 0 0 0 2 2h2v-2H8v-5a2 2 0 0 0-2-2a2 2 0 0 0 2-2V5h2V3m6 0a2 2 0 0 1 2 2v4a2 2 0 0 0 2 2h1v2h-1a2 2 0 0 0-2 2v4a2 2 0 0 1-2 2h-2v-2h2v-5a2 2 0 0 1 2-2a2 2 0 0 1-2-2V5h-2V3h2Z"
      ></path>
    </svg>
  ),

  "cog": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 8a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m-2 12c-.25 0-.46-.18-.5-.42l-.37-2.65c-.63-.25-1.17-.59-1.69-.99l-2.49 1.01c-.22.08-.49 0-.61-.22l-2-3.46a.493.493 0 0 1 .12-.64l2.11-1.66L4.5 12l.07-1l-2.11-1.63a.493.493 0 0 1-.12-.64l2-3.46c.12-.22.39-.31.61-.22l2.49 1c.52-.39 1.06-.73 1.69-.98l.37-2.65c.04-.24.25-.42.5-.42h4c.25 0 .46.18.5.42l.37 2.65c.63.25 1.17.59 1.69.98l2.49-1c.22-.09.49 0 .61.22l2 3.46c.13.22.07.49-.12.64L19.43 11l.07 1l-.07 1l2.11 1.63c.19.15.25.42.12.64l-2 3.46c-.12.22-.39.31-.61.22l-2.49-1c-.52.39-1.06.73-1.69.98l-.37 2.65c-.04.24-.25.42-.5.42h-4m1.25-18l-.37 2.61c-1.2.25-2.26.89-3.03 1.78L5.44 7.35l-.75 1.3L6.8 10.2a5.55 5.55 0 0 0 0 3.6l-2.12 1.56l.75 1.3l2.43-1.04c.77.88 1.82 1.52 3.01 1.76l.37 2.62h1.52l.37-2.61c1.19-.25 2.24-.89 3.01-1.77l2.43 1.04l.75-1.3l-2.12-1.55c.4-1.17.4-2.44 0-3.61l2.11-1.55l-.75-1.3l-2.41 1.04a5.42 5.42 0 0 0-3.03-1.77L12.75 4h-1.5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "company": () => (
    <svg focusable="false" height="1em" viewBox="0 0 16 16" width="1em">
      <g fill="currentColor">
        <path
          d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
          fillRule="evenodd"
        ></path>
        <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"></path>
      </g>
    </svg>
  ),

  "compare": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M4 2a2 2 0 0 0-2 2v8h2V8h2v4h2V4a2 2 0 0 0-2-2H4m0 2h2v2H4m18 9.5V14a2 2 0 0 0-2-2h-4v10h4a2 2 0 0 0 2-2v-1.5a1.54 1.54 0 0 0-1.5-1.5a1.54 1.54 0 0 0 1.5-1.5M20 20h-2v-2h2v2m0-4h-2v-2h2M5.79 21.61l-1.58-1.22l14-18l1.58 1.22z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "compare-horizontal": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M9 14H2v2h7v3l4-4l-4-4v3m6-1v-3h7V8h-7V5l-4 4l4 4z" fill="currentColor"></path>
    </svg>
  ),

  "compare-vertical": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M14 15v7h2v-7h3l-4-4l-4 4h3m-1-6h-3V2H8v7H5l4 4l4-4z" fill="currentColor"></path>
    </svg>
  ),

  "content-duplicate": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M11 17H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h12v2H4v12h7v-2l4 3l-4 3v-2m8 4V7H8v6H6V7a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-2h2v2h11z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "crown": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 8l3 5.2l3-2.7l-.7 3.5H6.7L6 10.5l3 2.7L12 8m0-4l-3.5 6L3 5l2 11h14l2-11l-5.5 5L12 4m7 14H5v1c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "currency": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12.89 11.1c-1.78-.59-2.64-.96-2.64-1.9c0-1.02 1.11-1.39 1.81-1.39c1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.45-.82-1.92-2.54-2.24V5h-2v1.26c-2.48.56-2.49 2.86-2.49 2.96c0 2.27 2.25 2.91 3.35 3.31c1.58.56 2.28 1.07 2.28 2.03c0 1.13-1.05 1.61-1.98 1.61c-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 2.9 2.96V19h2v-1.24c.4-.09 2.9-.59 2.9-3.22c0-1.39-.61-2.61-3.01-3.44zM3 21H1v-6h6v2H4.52c1.61 2.41 4.36 4 7.48 4a9 9 0 0 0 9-9h2c0 6.08-4.92 11-11 11c-3.72 0-7.01-1.85-9-4.67V21zm-2-9C1 5.92 5.92 1 12 1c3.72 0 7.01 1.85 9 4.67V3h2v6h-6V7h2.48C17.87 4.59 15.12 3 12 3a9 9 0 0 0-9 9H1z"
      ></path>
    </svg>
  ),

  "database": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 3C7.58 3 4 4.79 4 7v10c0 2.21 3.59 4 8 4s8-1.79 8-4V7c0-2.21-3.58-4-8-4m6 14c0 .5-2.13 2-6 2s-6-1.5-6-2v-2.23c1.61.78 3.72 1.23 6 1.23s4.39-.45 6-1.23V17m0-4.55c-1.3.95-3.58 1.55-6 1.55s-4.7-.6-6-1.55V9.64c1.47.83 3.61 1.36 6 1.36s4.53-.53 6-1.36v2.81M12 9C8.13 9 6 7.5 6 7s2.13-2 6-2s6 1.5 6 2s-2.13 2-6 2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "database-refresh": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M6 12.45V9.64c1.47.83 3.61 1.36 6 1.36s4.53-.53 6-1.36v2.39c.17-.03.33-.03.5-.03c.5 0 1 .07 1.5.18V7c0-2.21-3.58-4-8-4S4 4.79 4 7v10c0 2.21 3.59 4 8 4h.5c-.26-.63-.41-1.3-.47-2H12c-3.87 0-6-1.5-6-2v-2.23c1.61.78 3.72 1.23 6 1.23h.5c.35-.82.84-1.54 1.45-2.14c-.63.09-1.28.14-1.95.14c-2.42 0-4.7-.6-6-1.55M12 5c3.87 0 6 1.5 6 2s-2.13 2-6 2s-6-1.5-6-2s2.13-2 6-2m6 13.5l1.77-1.77A2.5 2.5 0 1 0 20 20h1.71A3.991 3.991 0 0 1 18 22.5c-2.21 0-4-1.79-4-4s1.79-4 4-4c1.11 0 2.11.45 2.83 1.17L22 14.5v4h-4z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "database-search": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M11 18.95c-3.23-.23-5-1.5-5-1.95v-2.23c1.13.55 2.5.92 4 1.1c0-.66.04-1.33.21-1.98c-1.71-.22-3.24-.73-4.21-1.44V9.64c1.43.81 3.5 1.33 5.82 1.36c.03-.03.05-.07.08-.1c2.2-2.19 5.6-2.49 8.1-.87V7c0-2.21-3.58-4-8-4S4 4.79 4 7v10c0 2.21 3.59 4 8 4c.34 0 .68 0 1-.03c-.38-.25-.76-.53-1.1-.87c-.35-.36-.65-.74-.9-1.15M12 5c3.87 0 6 1.5 6 2s-2.13 2-6 2s-6-1.5-6-2s2.13-2 6-2m8.31 12.9c.44-.69.69-1.52.69-2.4c0-2.5-2-4.5-4.5-4.5S12 13 12 15.5s2 4.5 4.5 4.5c.87 0 1.69-.25 2.38-.68L22 22.39L23.39 21l-3.08-3.1m-3.81.1a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "delete": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 2a10 10 0 0 1 10 10a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8a8 8 0 0 0 8 8a8 8 0 0 0 8-8a8 8 0 0 0-8-8m4 6v7a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-7h8m-2.5-4l1 1H17v2H7V7h2.5l1-1h3z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "delete-user": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M1.46 8.88l1.42-1.42L5 9.59l2.12-2.13l1.42 1.42L6.41 11l2.13 2.12l-1.42 1.42L5 12.41l-2.12 2.13l-1.42-1.42L3.59 11L1.46 8.88M15 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 1.9A2.1 2.1 0 0 0 12.9 8a2.1 2.1 0 0 0 2.1 2.1a2.1 2.1 0 1 0 0-4.2m0 7.1c2.67 0 8 1.33 8 4v3H7v-3c0-2.67 5.33-4 8-4m0 1.9c-3 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "document": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M8 12h8v2H8v-2m2 8H6V4h7v5h5v3.1l2-2V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h4v-2m-2-2h4.1l.9-.9V16H8v2m12.2-5c.1 0 .3.1.4.2l1.3 1.3c.2.2.2.6 0 .8l-1 1l-2.1-2.1l1-1c.1-.1.2-.2.4-.2m0 3.9L14.1 23H12v-2.1l6.1-6.1l2.1 2.1Z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "dollar": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M7 15h2c0 1.08 1.37 2 3 2s3-.92 3-2c0-1.1-1.04-1.5-3.24-2.03C9.64 12.44 7 11.78 7 9c0-1.79 1.47-3.31 3.5-3.82V3h3v2.18C15.53 5.69 17 7.21 17 9h-2c0-1.08-1.37-2-3-2s-3 .92-3 2c0 1.1 1.04 1.5 3.24 2.03C14.36 11.56 17 12.22 17 15c0 1.79-1.47 3.31-3.5 3.82V21h-3v-2.18C8.47 18.31 7 16.79 7 15z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "downgrade": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M13 5v6h1.17L12 13.17L9.83 11H11V5h2m2-2H9v6H5l7 7l7-7h-4V3m4 15H5v2h14v-2z" fill="currentColor"></path>
    </svg>
  ),

  "download": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path fill="currentColor" d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7l7-7Z"></path>
    </svg>
  ),

  "drag-vertical": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M9 3h2v2H9V3m4 0h2v2h-2V3M9 7h2v2H9V7m4 0h2v2h-2V7m-4 4h2v2H9v-2m4 0h2v2h-2v-2m-4 4h2v2H9v-2m4 0h2v2h-2v-2m-4 4h2v2H9v-2m4 0h2v2h-2v-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "earth": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M17.9 17.39c-.26-.8-1.01-1.39-1.9-1.39h-1v-3a1 1 0 0 0-1-1H8v-2h2a1 1 0 0 0 1-1V7h2a2 2 0 0 0 2-2v-.41a7.984 7.984 0 0 1 2.9 12.8M11 19.93c-3.95-.49-7-3.85-7-7.93c0-.62.08-1.22.21-1.79L9 15v1a2 2 0 0 0 2 2m1-16A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "email": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6m-2 0l-8 5l-8-5h16m0 12H4V8l8 5l8-5v10z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "empty-flask": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M5 19a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1c0-.21-.07-.41-.18-.57L13 8.35V4h-2v4.35L5.18 18.43c-.11.16-.18.36-.18.57m1 3a3 3 0 0 1-3-3c0-.6.18-1.16.5-1.63L9 7.81V6a1 1 0 0 1-1-1V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1a1 1 0 0 1-1 1v1.81l5.5 9.56c.32.47.5 1.03.5 1.63a3 3 0 0 1-3 3H6z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "empty-star": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 15.39l-3.76 2.27l.99-4.28l-3.32-2.88l4.38-.37L12 6.09l1.71 4.04l4.38.37l-3.32 2.88l.99 4.28M22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.45 4.73L5.82 21L12 17.27L18.18 21l-1.64-7.03L22 9.24z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "export": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6m4 18H6V4h7v5h5v11m-2-9v7.1L13.9 16l-2.8 2.8L8.3 16l2.8-2.8L8.9 11H16z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "factory": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M4 18v2h4v-2H4m0-4v2h10v-2H4m6 4v2h4v-2h-4m6-4v2h4v-2h-4m0 4v2h4v-2h-4M2 22V8l5 4V8l5 4V8l5 4l1-10h3l1 10v10H2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "female": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 4a6 6 0 0 1 6 6c0 2.97-2.16 5.44-5 5.92V18h2v2h-2v2h-2v-2H9v-2h2v-2.08c-2.84-.48-5-2.95-5-5.92a6 6 0 0 1 6-6m0 2a4 4 0 0 0-4 4a4 4 0 0 0 4 4a4 4 0 0 0 4-4a4 4 0 0 0-4-4z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "filter": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M15 19.88c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0L9.29 16.7a.989.989 0 0 1-.29-.83v-5.12L4.21 4.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4L15 10.75v9.13M7.04 5L11 10.06v5.52l2 2v-7.53L16.96 5H7.04z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "fix": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M7.5 5.6L5 7l1.4-2.5L5 2l2.5 1.4L10 2L8.6 4.5L10 7L7.5 5.6m12 9.8L22 14l-1.4 2.5L22 19l-2.5-1.4L17 19l1.4-2.5L17 14l2.5 1.4M22 2l-1.4 2.5L22 7l-2.5-1.4L17 7l1.4-2.5L17 2l2.5 1.4L22 2m-8.66 10.78l2.44-2.44l-2.12-2.12l-2.44 2.44l2.12 2.12m1.03-5.49l2.34 2.34c.39.37.39 1.02 0 1.41L5.04 22.71c-.39.39-1.04.39-1.41 0l-2.34-2.34c-.39-.37-.39-1.02 0-1.41L12.96 7.29c.39-.39 1.04-.39 1.41 0z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "flag": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M12.36 6l.4 2H18v6h-3.36l-.4-2H7V6h5.36M14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6" fill="currentColor"></path>
    </svg>
  ),

  "gamepad": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M5 8a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m7-9a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m0 12a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m7-9a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "gender": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M17.58 4H14V2h7v7h-2V5.41l-3.83 3.83A5 5 0 0 1 12 16.9V19h2v2h-2v2h-2v-2H8v-2h2v-2.1A5 5 0 0 1 6 12a5 5 0 0 1 5-5c1 0 1.96.3 2.75.83L17.58 4M11 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "gift": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M22 12v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8a1 1 0 0 1-1-1V8a2 2 0 0 1 2-2h3.17A3 3 0 0 1 6 5a3 3 0 0 1 3-3c1 0 1.88.5 2.43 1.24v-.01L12 4l.57-.77v.01C13.12 2.5 14 2 15 2a3 3 0 0 1 3 3a3 3 0 0 1-.17 1H21a2 2 0 0 1 2 2v3a1 1 0 0 1-1 1M4 20h7v-8H4v8m16 0v-8h-7v8h7M9 4a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1m6 0a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1M3 8v2h8V8H3m10 0v2h8V8h-8z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "google": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M21.35 11.1h-9.17v2.73h6.51c-.33 3.81-3.5 5.44-6.5 5.44C8.36 19.27 5 16.25 5 12c0-4.1 3.2-7.27 7.2-7.27c3.09 0 4.9 1.97 4.9 1.97L19 4.72S16.56 2 12.1 2C6.42 2 2.03 6.8 2.03 12c0 5.05 4.13 10 10.22 10c5.35 0 9.25-3.67 9.25-9.09c0-1.15-.15-1.81-.15-1.81z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "greeting": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M11 14H9a9 9 0 0 1 9-9v2c-3.87 0-7 3.13-7 7m7-3V9c-2.76 0-5 2.24-5 5h2c0-1.66 1.34-3 3-3M7 4c0-1.11-.89-2-2-2s-2 .89-2 2s.89 2 2 2s2-.89 2-2m4.45.5h-2A2.982 2.982 0 0 1 6.5 7h-3C2.67 7 2 7.67 2 8.5V11h6V8.74a4.982 4.982 0 0 0 3.45-4.24M19 17c1.11 0 2-.89 2-2s-.89-2-2-2s-2 .89-2 2s.89 2 2 2m1.5 1h-3c-1.5 0-2.71-1.08-2.95-2.5h-2c.2 2 1.59 3.65 3.45 4.24V22h6v-2.5c0-.83-.67-1.5-1.5-1.5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "group": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 5a3.5 3.5 0 0 0-3.5 3.5A3.5 3.5 0 0 0 12 12a3.5 3.5 0 0 0 3.5-3.5A3.5 3.5 0 0 0 12 5m0 2a1.5 1.5 0 0 1 1.5 1.5A1.5 1.5 0 0 1 12 10a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 12 7M5.5 8A2.5 2.5 0 0 0 3 10.5c0 .94.53 1.75 1.29 2.18c.36.2.77.32 1.21.32c.44 0 .85-.12 1.21-.32c.37-.21.68-.51.91-.87A5.42 5.42 0 0 1 6.5 8.5v-.28c-.3-.14-.64-.22-1-.22m13 0c-.36 0-.7.08-1 .22v.28c0 1.2-.39 2.36-1.12 3.31c.12.19.25.34.4.49a2.482 2.482 0 0 0 1.72.7c.44 0 .85-.12 1.21-.32c.76-.43 1.29-1.24 1.29-2.18A2.5 2.5 0 0 0 18.5 8M12 14c-2.34 0-7 1.17-7 3.5V19h14v-1.5c0-2.33-4.66-3.5-7-3.5m-7.29.55C2.78 14.78 0 15.76 0 17.5V19h3v-1.93c0-1.01.69-1.85 1.71-2.52m14.58 0c1.02.67 1.71 1.51 1.71 2.52V19h3v-1.5c0-1.74-2.78-2.72-4.71-2.95M12 16c1.53 0 3.24.5 4.23 1H7.77c.99-.5 2.7-1 4.23-1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "hail": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M4 16h3v6H4v-6M19 2.39c-.08 1.47-.45 2.74-1.14 3.82C17.17 7.29 16.22 8 15 8.39V22h-2v-6h-2v6H9V10.08c-.28.09-.5.2-.61.33C7.45 11.16 7 12.19 7 13.5v.5H5v-.5c0-1.97.72-3.56 2.13-4.78C8.53 7.56 10.16 7 12 7c1.41 0 2.56-.36 3.47-1.05C16.5 5.11 17 3.95 17 2.5V2h2v.39M12 2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2Z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "hammer-wrench": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M13.78 15.3l6 6l2.11-2.16l-6-6l-2.11 2.16m3.72-5.2c-.39 0-.81-.05-1.14-.19L4.97 21.25l-2.11-2.11l7.41-7.4L8.5 9.96l-.72.7l-1.45-1.41v2.86l-.7.7l-3.52-3.56l.7-.7h2.81l-1.4-1.41l3.56-3.56a2.976 2.976 0 0 1 4.22 0L9.89 5.74l1.41 1.4l-.71.71l1.79 1.78l1.82-1.88c-.14-.33-.2-.75-.2-1.12a3.49 3.49 0 0 1 3.5-3.52c.59 0 1.11.14 1.58.42L16.41 6.2l1.5 1.5l2.67-2.67c.28.47.42.97.42 1.6c0 1.92-1.55 3.47-3.5 3.47z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "home": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 20c-4.4 0-8-3.6-8-8s3.6-8 8-8s8 3.6 8 8s-3.6 8-8 8m0-18C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-1 12h2v3h3v-5h2l-6-5l-6 5h2v5h3v-3"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "home-lightning": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M12 2L2 12h2v9h16v-9h2M12 5l6 6v8H6v-8m5.5 3H9l3.5-7v4H15l-3.5 7v-4z" fill="currentColor"></path>
    </svg>
  ),

  "incognito": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M17.06 13c-1.86 0-3.42 1.33-3.82 3.1c-.95-.41-1.82-.3-2.48-.01C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17s1.77 4 3.94 4c2.06 0 3.74-1.62 3.9-3.68c.34-.24 1.23-.69 2.32.02c.18 2.05 1.84 3.66 3.9 3.66c2.17 0 3.94-1.79 3.94-4s-1.77-4-3.94-4M6.94 19.86c-1.56 0-2.81-1.28-2.81-2.86s1.26-2.86 2.81-2.86c1.56 0 2.81 1.28 2.81 2.86s-1.25 2.86-2.81 2.86m10.12 0c-1.56 0-2.81-1.28-2.81-2.86s1.25-2.86 2.81-2.86s2.82 1.28 2.82 2.86s-1.27 2.86-2.82 2.86M22 10.5H2V12h20v-1.5m-6.47-7.87c-.22-.49-.78-.75-1.31-.58L12 2.79l-2.23-.74l-.05-.01c-.53-.15-1.09.13-1.29.64L6 9h12l-2.44-6.32l-.03-.05z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "incognito-off": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M22.11 21.46L2.39 1.73L1.11 3l5.2 5.2L6 9h1.11l1.5 1.5H2V12h8.11l3.39 3.37c-.12.24-.2.48-.26.73c-.95-.41-1.83-.3-2.48-.01C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17s1.77 4 3.94 4c2.06 0 3.74-1.62 3.9-3.68c.34-.24 1.23-.69 2.32.02c.18 2.05 1.84 3.66 3.9 3.66c.6 0 1.16-.14 1.66-.39l2.12 2.12l1.27-1.27m-15.17-1.6c-1.56 0-2.81-1.28-2.81-2.86c0-1.58 1.26-2.86 2.81-2.86c1.56 0 2.81 1.28 2.81 2.86c0 1.58-1.25 2.86-2.81 2.86m10.12 0c-1.56 0-2.81-1.28-2.81-2.86c0-.26.04-.5.11-.75l3.48 3.48c-.25.08-.5.13-.78.13M22 12h-6.8l-1.5-1.5H22V12m-4.94 1c2.17 0 3.94 1.79 3.94 4c0 .25-.03.5-.07.73l-1.09-1.09a2.825 2.825 0 0 0-2.46-2.47l-1.09-1.08c.25-.06.51-.09.77-.09M12.2 9L7.72 4.5l.71-1.82c.2-.51.76-.79 1.29-.64l.05.01l2.23.74l2.22-.74c.53-.17 1.1.09 1.32.58l.02.05L18 9h-5.8z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "information": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M11 9h2V7h-2m1 13c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m-1 15h2v-6h-2v6z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "integrations": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M21.4 7.5c.8.8.8 2.1 0 2.8l-2.8 2.8l-7.8-7.8l2.8-2.8c.8-.8 2.1-.8 2.8 0l1.8 1.8l3-3l1.4 1.4l-3 3l1.8 1.8m-5.8 5.8l-1.4-1.4l-2.8 2.8l-2.1-2.1l2.8-2.8l-1.4-1.4l-2.8 2.8l-1.5-1.4l-2.8 2.8c-.8.8-.8 2.1 0 2.8l1.8 1.8l-4 4l1.4 1.4l4-4l1.8 1.8c.8.8 2.1.8 2.8 0l2.8-2.8l-1.4-1.4l2.8-2.9z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "level": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M19.5 5.5v13h-2v-13h2M21 4h-5v16h5V4m-7 5H9v11h5V9m-7 5H2v6h5v-6z" fill="currentColor"></path>
    </svg>
  ),

  "lightning": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M11 9.47V11h3.76L13 14.53V13H9.24L11 9.47M13 1L6 15h5v8l7-14h-5V1z" fill="currentColor"></path>
    </svg>
  ),

  "link": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7a5 5 0 0 0-5 5a5 5 0 0 0 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1M8 13h8v-2H8v2m9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1c0 1.71-1.39 3.1-3.1 3.1h-4V17h4a5 5 0 0 0 5-5a5 5 0 0 0-5-5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "linkedin": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "lock": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 17a2 2 0 0 1-2-2c0-1.11.89-2 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2m6 3V10H6v10h12m0-12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10c0-1.11.89-2 2-2h1V6a5 5 0 0 1 5-5a5 5 0 0 1 5 5v2h1m-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "lock-open-variant": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M18 1c-2.76 0-5 2.24-5 5v2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12c1.11 0 2-.89 2-2V10a2 2 0 0 0-2-2h-1V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2h2V6c0-2.76-2.24-5-5-5m-8 12a2 2 0 0 1 2 2c0 1.11-.89 2-2 2a2 2 0 1 1 0-4Z"
      ></path>
    </svg>
  ),

  "magnify": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M9.5 3A6.5 6.5 0 0 1 16 9.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5l-1.5 1.5l-5-5v-.79l-.27-.27A6.516 6.516 0 0 1 9.5 16A6.5 6.5 0 0 1 3 9.5A6.5 6.5 0 0 1 9.5 3m0 2C7 5 5 7 5 9.5S7 14 9.5 14S14 12 14 9.5S12 5 9.5 5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "male": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M9 9c1.29 0 2.5.41 3.47 1.11L17.58 5H13V3h8v8h-2V6.41l-5.11 5.09c.7 1 1.11 2.2 1.11 3.5a6 6 0 0 1-6 6a6 6 0 0 1-6-6a6 6 0 0 1 6-6m0 2a4 4 0 0 0-4 4a4 4 0 0 0 4 4a4 4 0 0 0 4-4a4 4 0 0 0-4-4z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "map-marker": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5M12 2a7 7 0 0 1 7 7c0 5.25-7 13-7 13S5 14.25 5 9a7 7 0 0 1 7-7m0 2a5 5 0 0 0-5 5c0 1 0 3 5 9.71C17 12 17 10 17 9a5 5 0 0 0-5-5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "medal": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M14.94 19.5L12 17.77L9.06 19.5l.78-3.34l-2.59-2.24l3.41-.29L12 10.5l1.34 3.13l3.41.29l-2.59 2.24M20 2H4v2l4.86 3.64a8 8 0 1 0 6.28 0L20 4m-2 11a6 6 0 1 1-7.18-5.88a5.86 5.86 0 0 1 2.36 0A6 6 0 0 1 18 15m-5.37-8h-1.26l-4-3h9.34z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "menu-down": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M7 10l5 5l5-5H7z" fill="currentColor"></path>
    </svg>
  ),

  "menu-right": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M10 17l5-5l-5-5v10z" fill="currentColor"></path>
    </svg>
  ),

  "merge": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M8 17l4-4h3.2c.4 1.2 1.5 2 2.8 2c1.7 0 3-1.3 3-3s-1.3-3-3-3c-1.3 0-2.4.8-2.8 2H12L8 7V3H3v5h3l4.2 4L6 16H3v5h5v-4z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "microsoft": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M2 3h9v9H2V3m9 19H2v-9h9v9M21 3v9h-9V3h9m0 19h-9v-9h9v9z" fill="currentColor"></path>
    </svg>
  ),

  "minus": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2M7 13h10v-2H7"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "money-check": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M3 6v12h10.32a6.38 6.38 0 0 1-.32-2H7a2 2 0 0 0-2-2v-4c1.11 0 2-.89 2-2h10a2 2 0 0 0 2 2v.06c.67 0 1.34.12 2 .34V6H3m9 3c-1.7.03-3 1.3-3 3s1.3 2.94 3 3c.38 0 .77-.08 1.14-.23c.27-1.1.72-2.14 1.83-3.16C14.85 10.28 13.59 8.97 12 9m9.63 3.27l-3.87 3.9l-1.35-1.37L15 16.22L17.75 19l5.28-5.32l-1.4-1.41z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "money-refund": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12.3 8.93L9.88 6.5h5.62V10H17V5H9.88l2.42-2.43l-1.06-1.07L7 5.75L11.24 10l1.06-1.07M12 14a3 3 0 1 0 3 3a3 3 0 0 0-3-3m-9-3v12h18V11m-2 8a2 2 0 0 0-2 2H7a2 2 0 0 0-2-2v-4a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "monitor-dashboard": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M21 16V4H3v12h18m0-14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-7v2h2v2H8v-2h2v-2H3a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2h18M5 6h9v5H5V6m10 0h4v2h-4V6m4 3v5h-4V9h4M5 12h4v2H5v-2m5 0h4v2h-4v-2Z"
      ></path>
    </svg>
  ),

  "music": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M13 11V7.5l2.2-2.21c.8-.79.95-2.05.39-3.03A2.467 2.467 0 0 0 13.45 1c-.21 0-.45.03-.64.09C11.73 1.38 11 2.38 11 3.5v3.24L7.86 9.91a5.946 5.946 0 0 0-1.25 6.43c.77 1.9 2.45 3.21 4.39 3.55v.61c0 .26-.23.5-.5.5H9v2h1.5c1.35 0 2.5-1.11 2.5-2.5V20c2.03 0 4.16-1.92 4.16-4.75c0-2.3-1.92-4.25-4.16-4.25m0-7.5c0-.23.11-.41.32-.47c.22-.06.45.03.56.23a.5.5 0 0 1-.08.61l-.8.86V3.5m-2 8c-.97.64-1.7 1.74-1.96 2.76l1.96.52v3.05a3.86 3.86 0 0 1-2.57-2.26c-.59-1.46-.27-3.12.83-4.24L11 9.5v2m2 6.5v-5.06c1.17 0 2.18 1.1 2.18 2.31C15.18 17 13.91 18 13 18Z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "network-check": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M15 20a1 1 0 0 0-1-1h-1v-2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h4v2h-1a1 1 0 0 0-1 1H2v2h7a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1h7v-2h-7m-8-5V5h10v10H7m1-4.63l1.24-1.24l1.69 1.7L14.76 7L16 8.5l-5.07 5.07L8 10.37z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "network-plus": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M15 20a1 1 0 0 0-1-1h-1v-2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h4v2h-1a1 1 0 0 0-1 1H2v2h7a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1h7v-2h-7m-8-5V5h10v10H7m8-4h-2v2h-2v-2H9V9h2V7h2v2h2v2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "numbered-list": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M3 13v-2h14v2H3m0 6v-2h14v2H3M3 7V5h14v2H3m17 1V5h-1V4h2v4h-1m-1 9v-1h3v4h-3v-1h2v-.5h-1v-1h1V17h-2m2.25-7a.749.749 0 0 1 .54 1.27L20.12 13H22v1h-3v-.92L21 11h-2v-1h2.25z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "offer": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M16 11.5a2.5 2.5 0 0 1 5 0a2.5 2.5 0 0 1-5 0M13 3v17h11V3H13m9 13c-1.1 0-2 .9-2 2h-3a2 2 0 0 0-2-2V7c1.11 0 2-.89 2-2h3a2 2 0 0 0 2 2v9M7 6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m0-2C4.79 4 3 5.79 3 8s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4m0 10c-3.87 0-7 1.79-7 4v2h11v-2H2c0-.58 1.75-2 5-2c1.83 0 3.17.45 4 .95v-2.23C9.87 14.27 8.5 14 7 14z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "overview": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 10a2 2 0 0 1 2 2a2 2 0 0 1-.47 1.29L16.7 22h-2.13L12 14.93L9.43 22H7.3l3.17-8.71A2 2 0 0 1 10 12a2 2 0 0 1 2-2m0-2a4 4 0 0 0-4 4c0 .5.1 1 .28 1.46l-.88 2.4A6.026 6.026 0 0 1 6 12a6 6 0 0 1 6-6a6 6 0 0 1 6 6c0 1.47-.53 2.81-1.4 3.86l-.88-2.4C15.9 13 16 12.5 16 12a4 4 0 0 0-4-4m0-4a8 8 0 0 0-8 8c0 2.36 1 4.5 2.64 5.94l-.72 2A10.005 10.005 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10c0 3.23-1.54 6.11-3.92 7.94l-.72-2C19 16.5 20 14.36 20 12a8 8 0 0 0-8-8z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "package": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18c-.21 0-.41-.06-.57-.18l-7.9-4.44A.991.991 0 0 1 3 16.5v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18c.21 0 .41.06.57.18l7.9 4.44c.32.17.53.5.53.88v9M12 4.15l-1.89 1.07L16 8.61l1.96-1.11L12 4.15M6.04 7.5L12 10.85l1.96-1.1l-5.88-3.4L6.04 7.5M5 15.91l6 3.38v-6.71L5 9.21v6.7m14 0v-6.7l-6 3.37v6.71l6-3.38z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "pencil": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M14.06 9l.94.94L5.92 19H5v-.92L14.06 9m3.6-6c-.25 0-.51.1-.7.29l-1.83 1.83l3.75 3.75l1.83-1.83c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29m-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "percent": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M18.5 3.5l2 2l-15 15l-2-2l15-15M7 4c1.66 0 3 1.34 3 3s-1.34 3-3 3s-3-1.34-3-3s1.34-3 3-3m10 10c1.66 0 3 1.34 3 3s-1.34 3-3 3s-3-1.34-3-3s1.34-3 3-3M7 6c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1m10 10c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "percentile": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4v2z" fill="currentColor"></path>
    </svg>
  ),

  "permissions": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 4.21c1.24.51 2.65.79 4 .79c1.05 0 2.1-.16 3.08-.46C18.5 5.5 18 6.71 18 8c0 1.32.54 2.93 1.1 4.63c.4 1.2.9 2.7.9 3.37c0 1.03-3.53 3-8 3.96C7.54 19 4 17.03 4 16c0-.67.5-2.17.9-3.37C5.46 10.93 6 9.32 6 8c0-1.29-.5-2.5-1.08-3.46C5.9 4.84 6.96 5 8 5c1.35 0 2.76-.28 4-.79M20 2c-1.15.64-2.6 1-4 1s-2.86-.37-4-1c-1.14.63-2.6 1-4 1s-2.85-.36-4-1L2 4s2 2 2 4s-2 6-2 8c0 4 10 6 10 6s10-2 10-6c0-2-2-6-2-8s2-4 2-4l-2-2m-4.95 14.45l-3.08-1.86l-3.07 1.86l.82-3.5L7 10.61l3.58-.31L11.97 7l1.4 3.29l3.58.31l-2.72 2.34l.82 3.51"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "plain-arrow-right": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11H4z" fill="currentColor"></path>
    </svg>
  ),

  "plain-check": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59L21 7z" fill="currentColor"></path>
    </svg>
  ),

  "plain-close": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "plain-information": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M13.5 4A1.5 1.5 0 0 0 12 5.5A1.5 1.5 0 0 0 13.5 7A1.5 1.5 0 0 0 15 5.5A1.5 1.5 0 0 0 13.5 4m-.36 4.77c-1.19.1-4.44 2.69-4.44 2.69c-.2.15-.14.14.02.42c.16.27.14.29.33.16c.2-.13.53-.34 1.08-.68c2.12-1.36.34 1.78-.57 7.07c-.36 2.62 2 1.27 2.61.87c.6-.39 2.21-1.5 2.37-1.61c.22-.15.06-.27-.11-.52c-.12-.17-.24-.05-.24-.05c-.65.43-1.84 1.33-2 .76c-.19-.57 1.03-4.48 1.7-7.17c.11-.64.41-2.04-.75-1.94z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "plain-plus": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="currentColor"></path>
    </svg>
  ),

  "plus": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m1 5h-2v4H7v2h4v4h2v-4h4v-2h-4V7z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "pointer": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M14 3v10l3.2-1.69l.22-.03c.29 0 .55.12.74.32l.74.77l-4.9 4.2c-.26.27-.61.43-1 .43H6.5c-.77 0-1.5-.7-1.5-1.5v-4.36c0-.61.35-1.14.85-1.34l4.94-2.2L12 7.47V3a1 1 0 0 1 1-1a1 1 0 0 1 1 1M5 19h8v3H5v-3z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "popcorn": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M7 22H4.75s-.75 0-.94-1.35L2.04 3.81L2 3.5C2 2.67 2.9 2 4 2s2 .67 2 1.5C6 2.67 6.9 2 8 2s2 .67 2 1.5c0-.83.9-1.5 2-1.5c1.09 0 2 .66 2 1.5c0-.83.9-1.5 2-1.5s2 .67 2 1.5c0-.83.9-1.5 2-1.5s2 .67 2 1.5l-.04.31l-1.77 16.84C20 22 19.25 22 19.25 22H7M17.85 4.93C17.55 4.39 16.84 4 16 4c-.81 0-1.64.36-2 .87L13.78 20h2.88l1.19-15.07M10 4.87C9.64 4.36 8.81 4 8 4c-.84 0-1.55.39-1.85.93L7.34 20h2.88L10 4.87z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "refresh": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M17.65 6.35A7.958 7.958 0 0 0 12 4a8 8 0 0 0-8 8a8 8 0 0 0 8 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18a6 6 0 0 1-6-6a6 6 0 0 1 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "restore-user": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M21.5 9h-5l1.86-1.86A7.991 7.991 0 0 0 12 4c-4.42 0-8 3.58-8 8c0 1.83.61 3.5 1.64 4.85c1.22-1.4 3.51-2.35 6.36-2.35c2.85 0 5.15.95 6.36 2.35A7.945 7.945 0 0 0 20 12h2c0 5.5-4.5 10-10 10S2 17.5 2 12S6.5 2 12 2c3.14 0 5.95 1.45 7.78 3.72L21.5 4v5M12 20c1.9 0 3.64-.66 5-1.76c-.64-1.01-2.55-1.74-5-1.74s-4.36.73-5 1.74c1.36 1.1 3.1 1.76 5 1.76m0-14c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6m0 2c-.83 0-1.5.67-1.5 1.5S11.17 11 12 11s1.5-.67 1.5-1.5S12.83 8 12 8z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "robot": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M19 8h1v1h-1V8m1-3h-1v2h1V6h.5c.28 0 .5-.22.5-.5v-2c0-.28-.22-.5-.5-.5H18v1h2v1m-3-2h-1v4h1V3m-3.5 12.5a2 2 0 1 0 4 0c0-1.11-.89-2-2-2s-2 .9-2 2M17 8h-1v1h1V8m5 6h-1c0-1.5-.47-2.87-1.26-4h-2.77c1.22.91 2.03 2.36 2.03 4v2h2v1h-2v3H5v-3H3v-1h2v-2c0-2.76 2.24-5 5-5h4c.34 0 .68.04 1 .1V7.08c-.33-.05-.66-.08-1-.08h-1V5.73A2 2 0 1 0 10 4c0 .74.4 1.39 1 1.73V7h-1c-3.87 0-7 3.13-7 7H2c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h1v1a2 2 0 0 0 2 2h14c1.11 0 2-.89 2-2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1m-13.5-.5c-1.1 0-2 .9-2 2s.9 2 2 2s2-.89 2-2s-.89-2-2-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "rocket": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M13.13 22.19l-1.63-3.83c1.57-.58 3.04-1.36 4.4-2.27l-2.77 6.1M5.64 12.5l-3.83-1.63l6.1-2.77C7 9.46 6.22 10.93 5.64 12.5M19.22 4c.28 0 .53 0 .74.05c.17 1.39-.02 4.25-3.3 7.53c-1.7 1.71-3.73 3.02-6.01 3.89l-2.15-2.1c.92-2.31 2.23-4.34 3.92-6.03C15.18 4.58 17.64 4 19.22 4m0-2c-1.98 0-4.98.69-8.22 3.93c-2.19 2.19-3.5 4.6-4.35 6.71c-.28.75-.09 1.57.46 2.13l2.13 2.12c.38.38.89.61 1.42.61c.23 0 .47-.06.7-.15A19.1 19.1 0 0 0 18.07 13c5.66-5.66 3.54-10.61 3.54-10.61S20.7 2 19.22 2m-4.68 7.46c-.78-.78-.78-2.05 0-2.83s2.05-.78 2.83 0c.77.78.78 2.05 0 2.83c-.78.78-2.05.78-2.83 0m-5.66 7.07l-1.41-1.41l1.41 1.41M6.24 22l3.64-3.64c-.34-.09-.67-.24-.97-.45L4.83 22h1.41M2 22h1.41l4.77-4.76l-1.42-1.41L2 20.59V22m0-2.83l4.09-4.08c-.21-.3-.36-.62-.45-.97L2 17.76v1.41z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "rss": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M6.18 15.64a2.18 2.18 0 0 1 2.18 2.18C8.36 19 7.38 20 6.18 20C5 20 4 19 4 17.82a2.18 2.18 0 0 1 2.18-2.18M4 4.44A15.56 15.56 0 0 1 19.56 20h-2.83A12.73 12.73 0 0 0 4 7.27V4.44m0 5.66a9.9 9.9 0 0 1 9.9 9.9h-2.83A7.07 7.07 0 0 0 4 12.93V10.1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "save": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M17 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V7l-4-4m2 16H5V5h11.17L19 7.83V19m-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3M6 6h9v4H6V6z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "scale-balance": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 3c-1.27 0-2.4.8-2.82 2H3v2h1.95L2 14c-.47 2 1 3 3.5 3s4.06-1 3.5-3L6.05 7h3.12c.33.85.98 1.5 1.83 1.83V20H2v2h20v-2h-9V8.82c.85-.32 1.5-.97 1.82-1.82h3.13L15 14c-.47 2 1 3 3.5 3s4.06-1 3.5-3l-2.95-7H21V5h-6.17C14.4 3.8 13.27 3 12 3m0 2a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1m-6.5 5.25L7 14H4l1.5-3.75m13 0L20 14h-3l1.5-3.75Z"
      ></path>
    </svg>
  ),

  "share": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81c1.66 0 3-1.34 3-3s-1.34-3-3-3s-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.15c-.05.21-.08.43-.08.66c0 1.61 1.31 2.91 2.92 2.91s2.92-1.3 2.92-2.91s-1.31-2.92-2.92-2.92M18 4c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1M6 13c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1m12 7c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1Z"
      ></path>
    </svg>
  ),

  "sign-out": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M16 17v-3H9v-4h7V7l5 5l-5 5M14 2a2 2 0 0 1 2 2v2h-2V4H5v16h9v-2h2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "skip": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M6 18l8.5-6L6 6m2 3.86L11.03 12L8 14.14M16 6h2v12h-2" fill="currentColor"></path>
    </svg>
  ),

  "spreadsheet": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M19 3H5c-1.104 0-1.99.896-1.99 2l-.008 3H3v11a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm0 8h-8v8H9v-8H5V9h4V5h2v4h8v2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "star": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.62L12 2L9.19 8.62L2 9.24l5.45 4.73L5.82 21L12 17.27z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "star-empty": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 15.39l-3.76 2.27l.99-4.28l-3.32-2.88l4.38-.37L12 6.09l1.71 4.04l4.38.37l-3.32 2.88l.99 4.28M22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.45 4.73L5.82 21L12 17.27L18.18 21l-1.64-7.03L22 9.24z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "star-four": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 6.7l1.45 3.85L17.3 12l-3.85 1.45L12 17.3l-1.45-3.85L6.7 12l3.85-1.45L12 6.7M12 1L9 9l-8 3l8 3l3 8l3-8l8-3l-8-3l-3-8z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "survey": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M15 5h3l-1.5 2L15 5M5 2h14a2 2 0 0 1 2 2v16c0 1.11-.89 2-2 2H5a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2m0 2v4h14V4H5m0 16h14V10H5v10m2-8h10v2H7v-2m0 4h10v2H7v-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "table": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M5 4h14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2m0 4v4h6V8H5m8 0v4h6V8h-6m-8 6v4h6v-4H5m8 0v4h6v-4h-6z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "tag": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M21.41 11.58l-9-9A2 2 0 0 0 11 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 .59 1.42l9 9A2 2 0 0 0 13 22a2 2 0 0 0 1.41-.59l7-7A2 2 0 0 0 22 13a2 2 0 0 0-.59-1.42M13 20l-9-9V4h7l9 9M6.5 5A1.5 1.5 0 1 1 5 6.5A1.5 1.5 0 0 1 6.5 5z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "target": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M11 2v2.07A8.002 8.002 0 0 0 4.07 11H2v2h2.07A8.002 8.002 0 0 0 11 19.93V22h2v-2.07A8.002 8.002 0 0 0 19.93 13H22v-2h-2.07A8.002 8.002 0 0 0 13 4.07V2m-2 4.08V8h2V6.09c2.5.41 4.5 2.41 4.92 4.91H16v2h1.91c-.41 2.5-2.41 4.5-4.91 4.92V16h-2v1.91C8.5 17.5 6.5 15.5 6.08 13H8v-2H6.09C6.5 8.5 8.5 6.5 11 6.08M12 11a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "timer": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 20a7 7 0 0 1-7-7a7 7 0 0 1 7-7a7 7 0 0 1 7 7a7 7 0 0 1-7 7m7.03-12.61l1.42-1.42c-.45-.51-.9-.97-1.41-1.41L17.62 6c-1.55-1.26-3.5-2-5.62-2a9 9 0 0 0-9 9a9 9 0 0 0 9 9c5 0 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61M11 14h2V8h-2m4-7H9v2h6V1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "tour": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M11 12H3.5L6 9.5L3.5 7H11V3l1-1l1 1v4h5l2.5 2.5L18 12h-5v8a2 2 0 0 1 2 2H9a2 2 0 0 1 2-2v-8z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "transfer": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path d="M3 8h2v8H3V8m4 0h2v8H7V8m4 0h2v8h-2V8m4 11.25V4.75L22.25 12L15 19.25z" fill="currentColor"></path>
    </svg>
  ),

  "tune": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M3 17v2h6v-2H3M3 5v2h10V5H3m10 16v-2h8v-2h-8v-2h-2v6h2M7 9v2H3v2h4v2h2V9H7m14 4v-2H11v2h10m-6-4h2V7h4V5h-4V3h-2v6z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "turnover": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M7.17 7.91l1.73 1l3.18-5.49l2.25 3.89l-2.6 1.5l5.46 1.46l1.47-5.46l-2.6 1.5l-2.25-3.9a1.988 1.988 0 0 0-2.73-.73c-.27.15-.5.37-.67.63M10 20v-2H3.66l2.24-3.9l2.6 1.5l-1.46-5.46l-5.47 1.46l2.6 1.5L1.92 17a2.003 2.003 0 0 0 1.62 3m15.52-8.5l-1.74 1L20.5 18H16v-3l-4 4l4 4v-3h4.5c1.11 0 2-.89 2-2c0-.31-.08-.62-.22-.89z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "upgrade": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M9 10v6h6v-6h4l-7-7l-7 7h4m3-4.2L14.2 8H13v6h-2V8H9.8L12 5.8M19 18H5v2h14v-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "upload": () => (
    <svg focusable="false" height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M9 10v6h6v-6h4l-7-7l-7 7h4m3-4.2L14.2 8H13v6h-2V8H9.8L12 5.8M19 18H5v2h14v-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "user": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m0 7c2.67 0 8 1.33 8 4v3H4v-3c0-2.67 5.33-4 8-4m0 1.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "user-arrow": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M19 21v-2h-4v-2h4v-2l3 3l-3 3m-6-3c0 .71.15 1.39.42 2H2v-3c0-2.21 3.58-4 8-4c1 0 1.96.09 2.85.26c.83.16 1.59.38 2.26.66c-1.28.91-2.11 2.4-2.11 4.08m-9-1v1h7c0-1.04.23-2.03.64-2.92L10 15c-3.31 0-6 .9-6 2m6-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "user-check": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08l5.13-5.17M11 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m0 7c.68 0 1.5.09 2.41.26l-1.67 1.67l-.74-.03c-2.97 0-6.1 1.46-6.1 2.1v1.1h6.2L13 20H3v-3c0-2.66 5.33-4 8-4z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "user-leave": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M18 21l-3-3l3-3v2h4v2h-4v2m-5-3c0 .71.15 1.39.42 2H2v-3c0-2.21 3.58-4 8-4c1 0 1.96.09 2.85.26c.83.16 1.59.38 2.26.66c-1.28.91-2.11 2.4-2.11 4.08m-9-1v1h7c0-1.04.23-2.03.64-2.92L10 15c-3.31 0-6 .9-6 2m6-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "view-dashboard": () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24">
      <path fill="currentColor" d="M13 3v6h8V3m-8 18h8V11h-8M3 21h8v-6H3m0-2h8V3H3v10Z"></path>
    </svg>
  ),

  "wall": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M3 16h9v5H3v-5m-1-6h6v5H2v-5m7 0h6v5H9v-5m7 0h6v5h-6v-5m-3 6h8v5h-8v-5M3 4h8v5H3V4m9 0h9v5h-9V4z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "warning": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M12 2c-.5 0-1 .19-1.41.59l-8 8c-.79.78-.79 2.04 0 2.82l8 8c.78.79 2.04.79 2.82 0l8-8c.79-.78.79-2.04 0-2.82l-8-8C13 2.19 12.5 2 12 2m0 2l8 8l-8 8l-8-8m7-5v6h2V7m-2 8v2h2v-2z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "wheel": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="M2 11l2.05.1a7.96 7.96 0 0 1 3.2-5.54L6.13 3.84c-.27-.48-.13-1.09.37-1.37c.5-.27 1.09-.11 1.37.37l.93 1.82a8.102 8.102 0 0 1 6.4 0l.93-1.82c.28-.48.87-.64 1.37-.37c.5.28.64.89.37 1.37l-1.12 1.72a7.96 7.96 0 0 1 3.2 5.54L22 11a1 1 0 0 1 1 1a1 1 0 0 1-1 1l-2.05-.1a7.96 7.96 0 0 1-3.2 5.54l1.12 1.72c.27.48.13 1.09-.37 1.37c-.5.27-1.09.11-1.37-.37l-.93-1.82a8.102 8.102 0 0 1-6.4 0l-.93 1.82c-.28.48-.87.64-1.37.37c-.5-.28-.64-.89-.37-1.37l1.12-1.72a7.96 7.96 0 0 1-3.2-5.54L2 13a1 1 0 0 1-1-1a1 1 0 0 1 1-1m7.07.35c.13-.61.46-1.15.93-1.56L8.34 7.25a5.993 5.993 0 0 0-2.29 3.95l3.02.15M12 9c.32 0 .62.05.9.14l1.38-2.69C13.58 6.16 12.81 6 12 6c-.81 0-1.58.16-2.28.45l1.38 2.69c.28-.09.58-.14.9-.14m2.93 2.35l3.02-.15a5.993 5.993 0 0 0-2.29-3.95L14 9.79c.47.41.8.95.93 1.56m0 1.3c-.13.61-.46 1.15-.93 1.56l1.66 2.54a5.993 5.993 0 0 0 2.29-3.95l-3.02-.15M12 15c-.32 0-.62-.05-.91-.14l-1.37 2.69c.7.29 1.47.45 2.28.45c.81 0 1.58-.16 2.28-.45l-1.37-2.69c-.29.09-.59.14-.91.14m-2.93-2.35l-3.02.15c.22 1.6 1.06 3.01 2.29 3.95L10 14.21c-.47-.41-.8-.95-.93-1.56z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "youtube": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z"
        fill="currentColor"
      ></path>
    </svg>
  ),

  "chart-gantt": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M2 5h8V2h2v20h-2v-4H6v-3h4v-2H4v-3h6V8H2V5m12 0h3v3h-3V5m0 5h5v3h-5v-3m0 5h8v3h-8v-3Z"
      ></path>
    </svg>
  ),

  "abacus": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M5 5h2v6H5V5m5 0H8v6h2V5M5 19h2v-6H5v6m5-6H8v6h2v-2h5v-2h-5v-2m-8 8h2V3H2v18M20 3v4h-7V5h-2v6h2V9h7v6h-2v-2h-2v6h2v-2h2v4h2V3h-2Z"
      ></path>
    </svg>
  ),

  "format-list-checks": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M3 5h6v6H3V5m2 2v2h2V7H5m6 0h10v2H11V7m0 8h10v2H11v-2m-6 5l-3.5-3.5l1.41-1.41L5 17.17l4.59-4.58L11 14l-6 6Z"
      ></path>
    </svg>
  ),

  "monitor-shimmer": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M21 16H3V4h18m0-2H3c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h7v2H8v2h8v-2h-2v-2h7c1.11 0 2-.89 2-2V4a2 2 0 0 0-2-2m-6 3.5l-.62 1.37L13 7.5l1.38.63L15 9.5l.63-1.37L17 7.5l-1.37-.63L15 5.5m-4.5 2L9.41 9.91L7 11l2.41 1.09l1.09 2.41l1.1-2.41L14 11l-2.4-1.09l-1.1-2.41"
      ></path>
    </svg>
  ),

  "domain": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7H12Z"
      ></path>
    </svg>
  ),

  "chat-outline": () => (
    <svg height="1em" viewBox="0 0 24 24" width="1em">
      <path
        fill="currentColor"
        d="M12 3C6.5 3 2 6.58 2 11a7.218 7.218 0 0 0 2.75 5.5c0 .6-.42 2.17-2.75 4.5c2.37-.11 4.64-1 6.47-2.5c1.14.33 2.34.5 3.53.5c5.5 0 10-3.58 10-8s-4.5-8-10-8m0 14c-4.42 0-8-2.69-8-6s3.58-6 8-6s8 2.69 8 6s-3.58 6-8 6Z"
      ></path>
    </svg>
  ),

  "anonymous": () => (
    <svg width="32" height="32" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54L2 5.27M12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.79 11.79 0 0 1-4 5.19l-1.42-1.43A9.862 9.862 0 0 0 20.82 12A9.821 9.821 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.821 9.821 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13Z"
      />
    </svg>
  ),
};
