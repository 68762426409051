import { map } from "bluebird";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type UpdateCustomJobInput } from "~/pages/api/update-custom-job";
export const updateCustomJob = async (ctx: AppContext, input: UpdateCustomJobInput) => {
  const user = getRequiredUser(ctx);

  const jobs = await ctx.prisma.job.findMany({
    where: {
      id: {
        in: input.jobs.map((item) => {
          return item.job.id;
        }),
      },
    },
  });

  const description =
    `Custom job updated by ${user.firstName} ${user.lastName}\n\n` +
    jobs
      .map((job) => {
        const weight =
          input.jobs.find((item) => {
            return item.job.id === job.id;
          })?.weight ?? 1;

        return `· ${job.name} (weight: ${Math.round(weight)})`;
      })
      .join("\n");

  const customJob = await ctx.prisma.customJob.findUniqueOrThrow({
    where: { id: input.id },
    select: { items: true, id: true },
  });

  await map(
    input.jobs,
    async (item) => {
      return ctx.prisma.customJobItem.update({
        where: { id: item.id, customJobId: customJob.id, jobId: item.job.id },
        data: { weight: item.weight },
      });
    },
    { concurrency: 10 }
  );

  return ctx.prisma.customJob.update({
    where: { id: customJob.id },
    data: {
      user: {
        connect: { id: user.id },
      },
      name: input.name,
      description,
      sharedWithCompany: input.sharedWithCompany,
    },
  });
};
