import { mapSeries } from "bluebird";
import { compact } from "lodash";
import { type AppContext } from "~/lib/context";
import { updateEmployeesStats } from "~/services/employee-stats/update-employees-stats";
import { createOrUpdateEmployeeMappingFromExternalEmployee } from "~/services/employee/employee-mapping";
import { externalEmployeeMappingUpdateArgs } from "~/services/external-employee/external-employee-mapping";
import { synchroniseCompanySalaryRangeEmployees } from "~/services/salary-bands/configuration/synchronise-company-salary-range-employees";

export const updateExternalEmployeesAfterMapping = async (
  ctx: AppContext,
  externalEmployeeIds: number[],
  companyId: number
) => {
  const impactedExternalEmployees = await ctx.prisma.externalEmployee.findMany({
    where: { id: { in: externalEmployeeIds }, companyId },
    ...externalEmployeeMappingUpdateArgs,
  });

  const impactedEmployees = await mapSeries(impactedExternalEmployees, async (externalEmployee) =>
    createOrUpdateEmployeeMappingFromExternalEmployee(ctx, externalEmployee)
  );

  await updateEmployeesStats(ctx, {
    companyId,
    employeesIds: compact(impactedEmployees).map((employee) => employee.id),
  });

  await synchroniseCompanySalaryRangeEmployees(ctx, { companyId });
};
