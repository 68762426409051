import { ExternalEmployeeStatus, UserRole } from "@prisma/client";
import { Client } from "intercom-client";
import { compact } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { CacheKeys } from "~/lib/external/redis/cache-keys";
import { getRequiredUser } from "~/lib/get-required-user";
import { logInfo } from "~/lib/logger";
import { assertNotNil } from "~/lib/utils";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/select-external-employee-user-for-picture";
import { formatSalaryBandName } from "~/services/salary-bands/helpers/format-salary-band-name";
export const fetchCommandPaletteCommands = async (ctx: AppContext) => {
  const salaryBands = await fetchSalaryBands(ctx);
  const externalEmployees = await fetchExternalEmployees(ctx, { salaryBands });
  const articles = await fetchIntercomArticles(ctx);

  return {
    salaryBands,
    externalEmployees,
    articles,
  };
};

export type FetchCommandPaletteCommandsResult = AsyncReturnType<typeof fetchCommandPaletteCommands>;

const fetchSalaryBands = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  if (!ctx.subscriptions.CAN_ACCESS_SALARY_BANDS || !user.company.defaultSalaryGridId) return [];

  const salaryBands = await ctx.prisma.salaryBand.findMany({
    where: { gridId: user.company.defaultSalaryGridId },
    select: {
      id: true,
      location: { select: { name: true } },
      job: { select: { name: true } },
    },
    orderBy: [{ job: { name: "asc" } }, { location: { name: "asc" } }],
  });

  return salaryBands.map((salaryBand) => ({
    ...salaryBand,
    keywords: [
      ctx.t("enum.palette-command-type.salary-band-group"),
      salaryBand.job.name,
      salaryBand.location.name,
      formatSalaryBandName(salaryBand),
    ],
  }));
};

export type CommandPaletteSalaryBand = AsyncReturnType<typeof fetchSalaryBands>[number];

const fetchExternalEmployees = async (ctx: AppContext, params: { salaryBands: CommandPaletteSalaryBand[] }) => {
  const user = getRequiredUser(ctx);

  const isDefaultCompensationReviewAdmin = user.isSuperAdmin || user.permissions.role === UserRole.ADMIN;

  const externalEmployees = await ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: user.companyId,
      status: { not: ExternalEmployeeStatus.SKIPPED },
    },
    select: {
      id: true,
      firstName: true,
      lastName: true,
      employeeNumber: true,
      externalId: true,
      email: true,
      picture: true,
      mappedEmployee: {
        select: {
          jobId: true,
          locationId: true,
          level: true,
        },
      },
      liveSalaryRangeEmployee: {
        select: {
          gridId: true,
          range: {
            select: { bandId: true },
          },
        },
      },
      compensationReviewEmployees: {
        where: {
          isEligible: true,
          campaign: {
            ...(!isDefaultCompensationReviewAdmin && {
              admins: { some: { userId: user.id } },
            }),
          },
        },
        select: {
          id: true,
          campaign: {
            select: { id: true, name: true },
          },
        },
      },
      ...selectExternalEmployeeUserPicture,
    },
    orderBy: [{ firstName: "asc" }, { lastName: "asc" }],
  });

  return externalEmployees.map((externalEmployee) => {
    const salaryBand = params.salaryBands.find((band) => {
      return band.id === externalEmployee.liveSalaryRangeEmployee?.range.bandId;
    });

    const compensationReviewEmployees = externalEmployee.compensationReviewEmployees.map((employee) => ({
      ...employee,
      campaign: assertNotNil(employee.campaign),
    }));

    return {
      ...externalEmployee,
      compensationReviewEmployees,
      salaryBand,
      keywords: compact([
        ctx.t("enum.palette-command-type.employee-group"),
        formatExternalEmployeeName(externalEmployee),
        externalEmployee.firstName,
        externalEmployee.lastName,
        externalEmployee.employeeNumber,
        `${externalEmployee.id}`,
        externalEmployee.email,
      ]),
    };
  });
};

export type CommandPaletteEmployee = AsyncReturnType<typeof fetchExternalEmployees>[number];

const fetchIntercomArticles = async (ctx: AppContext) => {
  const { remember, t } = ctx;

  if (!config.intercom.accessToken) {
    logInfo(ctx, "[intercom] Access token is missing. Skipping fetching articles.");
    return [];
  }

  const intercom = new Client({
    tokenAuth: { token: config.intercom.accessToken as string },
  });

  const fetchAllPages = async (page = 1): Promise<AsyncReturnType<typeof intercom.articles.list>["data"]> => {
    const response = await intercom.articles.list({ perPage: 150, page });

    if (response.pages.total_pages <= page) {
      return response.data;
    }

    const nextPage = await fetchAllPages(page + 1);

    return response.data.concat(nextPage);
  };

  const articles = await remember(CacheKeys.IntercomArticles, async () => {
    const articles = await fetchAllPages();

    return articles
      .filter((article) => !!article.title && article.state === "published")
      .map((article) => ({
        name: article.title,
        url: article.url,
      }));
  });

  return articles.map((article) => ({
    ...article,
    keywords: [t("enum.palette-command-type.help-center-group"), article.name],
  }));
};
