import { EmployeeMappingSkipReason } from "@prisma/client";
import { type TFunction } from "next-i18next";
import React, { useMemo, useState } from "react";
import { match } from "ts-pattern";
import { formatEmployeeMappingSkipReason } from "~/components/employee/employee-form-utils";
import { type ModalAction } from "~/components/ui/core/Modal";
import { type Choice, SelectAChoiceModal } from "~/components/ui/core/SelectAChoiceModal";
import { useI18n } from "~/lib/i18n/use-i18n";

export type SkipDataType = "location" | "job" | "level" | "employee";

type Reason = {
  reason: EmployeeMappingSkipReason;
} & Choice;

const CommonSkipReason = [EmployeeMappingSkipReason.UNDISCLOSED, EmployeeMappingSkipReason.OTHER];
const LocationSkipReason = [EmployeeMappingSkipReason.MISSING_LOCATION, ...CommonSkipReason];
const JobSkipReason = [EmployeeMappingSkipReason.MISSING_JOB, ...CommonSkipReason];

const getPredefinedReasons = (
  t: TFunction,
  values: EmployeeMappingSkipReason[],
  type: SkipDataType
): readonly Reason[] =>
  values.map((reason) => ({
    label: formatEmployeeMappingSkipReason(t, reason, type),
    reason,
  }));

const getPredefinedReasonsForType = (t: TFunction, type: SkipDataType): readonly Reason[] => {
  return match(type)
    .with("location", () => getPredefinedReasons(t, LocationSkipReason, type))
    .with("job", () => getPredefinedReasons(t, JobSkipReason, type))
    .with("level", () => getPredefinedReasons(t, CommonSkipReason, type))
    .with("employee", () => getPredefinedReasons(t, Object.values(EmployeeMappingSkipReason), type))
    .exhaustive();
};

type Props = {
  type: SkipDataType;
  reason?: Reason | null;
  onSubmit: (reason: Reason) => void;
} & Pick<React.ComponentProps<typeof SelectAChoiceModal>, "isOpen" | "onClose">;

export const SkipReasonSelectorModal: React.FC<Props> = ({ isOpen, onClose, reason, onSubmit, type }) => {
  const [selectedReason, setSelectedReason] = useState(reason);
  const { t } = useI18n();

  const primaryAction = useMemo<ModalAction>(
    () => ({
      label: t("components.ui.skip-reason-selector-modal.submit"),
      onClick: () => {
        if (!selectedReason) {
          return;
        }

        onSubmit(selectedReason);
        setSelectedReason(null);
        onClose();
      },
      disabled: !selectedReason,
    }),
    [selectedReason]
  );

  const secondaryAction = useMemo<ModalAction>(
    () => ({
      label: t("components.ui.skip-reason-selector-modal.cancel"),
      onClick: () => {
        setSelectedReason(null);
        onClose();
      },
    }),
    []
  );

  return (
    <SelectAChoiceModal
      choices={getPredefinedReasonsForType(t, type)}
      onChoiceChange={setSelectedReason}
      selectedChoice={selectedReason}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      icon="message-question"
      className="w-[560px]"
      isOpen={isOpen}
      title={t("components.ui.skip-reason-selector-modal.why-did-you-choose-to-skip-this-type", { type })}
      subtitle={t("components.ui.skip-reason-selector-modal.by-skipping-this-type", { type })}
      onClose={() => {
        setSelectedReason(null);
        onClose();
      }}
    />
  );
};
