import { AutoAwesomeOutlined } from "@mui/icons-material";
import { Alert, Divider, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useBoolean } from "react-use";
import { object, string } from "yup";
import { Modal } from "~/components/ui/core/Modal";
import { Button } from "~/components/ui/core/button";
import { useApi } from "~/hooks/useApi";
import { useForm } from "~/hooks/useForm";
import { type CreateJobRequestResponse } from "~/pages/api/create-job-request";
import { type SuggestJobDescriptionResponse } from "~/pages/api/suggest-job-description";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  externalJob?: { id?: number; name?: string };
};

type FormValues = {
  name: string;
  description: string;
  comment: string;
};

export const JobRequestModal: React.FC<Props> = ({ isOpen, onClose, externalJob }) => {
  const { apiFetch } = useApi();
  const [isLoading, setIsLoading] = useBoolean(false);
  const [displaySuggestion, setDisplaySuggestion] = useBoolean(true);

  const form = useForm<FormValues>({
    initialValues: {
      name: externalJob?.name ?? "",
      description: "",
      comment: "",
    },
    validationSchema: object({
      name: string().min(1).required(),
      description: string(),
      comment: string(),
    }),
    onSubmit: async (values) => {
      await apiFetch<CreateJobRequestResponse>("/api/create-job-request", {
        body: {
          name: values.name,
          description: values.description,
          comment: values.comment,
          externalJobId: externalJob?.id,
        },
        successMessage: `The ${values.name} job request has successfully been sent`,
      });

      handleClose();
    },
  });

  const handleClose = () => {
    onClose();

    form.resetForm();
  };

  const onSuggestClick = async () => {
    const res = await apiFetch<SuggestJobDescriptionResponse>("/api/suggest-job-description", {
      method: "POST",
      body: { jobName: form.values.name },
      setIsLoading,
    });

    void form.setValues({ ...form.values, description: res.suggestion });
  };

  return (
    <Modal isOpen={isOpen} title="Request a new job title" onClose={handleClose} className="w-[720px]">
      <Stack className="mt-4 w-full px-4">
        {displaySuggestion && (
          <Alert severity="info" variant="outlined" onClose={() => setDisplaySuggestion(false)}>
            <Stack spacing={2}>
              <Typography>
                • Provide the name of the job you think should be created and any additional context you can share.
                We’ll take it from there!
              </Typography>

              <Typography>
                • Client feedback is our primary source to determine what new jobs we need to create in Figures, so
                thanks a lot for your help 🙏
              </Typography>

              <Typography>
                • Please note that we’ll only create a new job if we have enough data to display results. For some
                requests, it may take some time. Therefore, we'll come back to you only if we create a new job.
              </Typography>
            </Stack>
          </Alert>
        )}

        <form className="mt-4 w-full" onSubmit={form.handleSubmit}>
          <Stack spacing={4}>
            <TextField
              value={form.values.name}
              label="Job name"
              onChange={(event) => {
                void form.setFieldValue("name", event.target.value);
              }}
              placeholder="The desired job name"
              required
            />

            <Stack className="relative">
              <TextField
                multiline
                rows={6}
                label="Job description"
                placeholder="The responsibilities and scope associated to this job"
                error={!!form.errors.description}
                helperText={form.errors.description}
                value={form.values.description}
                onChange={(event) => void form.setFieldValue("description", event.target.value)}
              />
              <Button
                onClick={onSuggestClick}
                isLoading={isLoading}
                startIcon={<AutoAwesomeOutlined />}
                variant="text"
                size="small"
                className="absolute right-0 -top-2 z-10"
                disabled={form.values.name.length === 0}
              >
                Suggest with AI
              </Button>
            </Stack>

            <TextField
              value={form.values.comment}
              label="Comment"
              onChange={(event) => {
                void form.setFieldValue("comment", event.target.value);
              }}
              placeholder="You can help us by giving us more context: how it is different from another existing job, why you need this specific job, ..."
              multiline={true}
              rows={4}
            />
          </Stack>
          <Divider className="my-6 -mx-3" />
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Button variant="contained" disabled={!form.isValid || form.isSubmitting} type="submit">
              Make a job request
            </Button>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};
