import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  type FetchSelectedPerformanceReviewCycles,
  fetchSelectedPerformanceReviewCycles,
} from "~/services/performance-review/fetch-selected-performance-review-cycles";
import { PerformanceReviewScopeSchema } from "~/services/performance-review/find-or-create-performance-review-cycle";

export const FetchSelectedPerformanceReviewCyclesSchema = object({
  companyId: number().required(),
  performanceReviewIntegrationSettingsId: number().required(),
  performanceReviewScope: PerformanceReviewScopeSchema,
});

export type FetchSelectedPerformanceReviewCyclesInput = YupOutputType<
  typeof FetchSelectedPerformanceReviewCyclesSchema
>;

const handler: NextApiHandler<FetchSelectedPerformanceReviewCycles> = async (req, res) => {
  const input = await FetchSelectedPerformanceReviewCyclesSchema.validate(req.body, { abortEarly: false });

  const response = await fetchSelectedPerformanceReviewCycles(req, input);

  return res.status(HttpStatus.OK).json(response);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessIntegrations: true,
  },
});

export const FETCH_SELECTED_PERFORMANCE_REVIEW_CYCLES_QUERY_KEY: ApiRoute["pathname"] =
  "/api/performance-review/fetch-selected-performance-review-cycles";

export const useFetchSelectedPerformanceReviewCyclesQuery = createQuery<
  typeof handler,
  typeof FetchSelectedPerformanceReviewCyclesSchema
>({
  path: FETCH_SELECTED_PERFORMANCE_REVIEW_CYCLES_QUERY_KEY,
  schema: FetchSelectedPerformanceReviewCyclesSchema,
});
