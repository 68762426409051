import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type FetchExternalEmployeeOffCycleReviewRequestsInput } from "~/pages/api/compensation-review/off-cycle-reviews/fetch-external-employee-off-cycle-review-requests";
import { fetchOffCycleReviewConfiguration } from "~/services/compensation-review/off-cycle-reviews/fetch-off-cycle-review-configuration";
export const fetchExternalEmployeeOffCycleReviewRequests = async (
  ctx: AppContext,
  input: FetchExternalEmployeeOffCycleReviewRequestsInput
) => {
  const user = getRequiredUser(ctx);

  const configuration = await fetchOffCycleReviewConfiguration(ctx);

  return ctx.prisma.offCycleReviewRequest.findMany({
    where: {
      configurationId: configuration.id,
      companyId: user.companyId,
      externalEmployeeId: input.externalEmployeeId,
    },
    orderBy: { createdAt: "desc" },
    select: {
      id: true,
      user: {
        select: {
          id: true,
          firstName: true,
          lastName: true,
        },
      },
      status: true,
      reason: true,
      effectiveDate: true,
      comment: true,
      createdAt: true,
      declineComment: true,
      declinedAt: true,
      declinedBy: {
        select: {
          id: true,
          firstName: true,
          lastName: true,
        },
      },
      approvedAt: true,
      approvedBy: {
        select: {
          id: true,
          firstName: true,
          lastName: true,
        },
      },
      targetRangeAfterPromotion: {
        select: {
          level: { select: { name: true } },
          band: {
            select: {
              job: { select: { name: true } },
              location: { select: { name: true } },
            },
          },
        },
      },
      configuration: {
        select: {
          id: true,
          maxReviewersCount: true,
        },
      },
    },
  });
};

export type FetchExternalEmployeeOffCycleReviewRequestsResult = AsyncReturnType<
  typeof fetchExternalEmployeeOffCycleReviewRequests
>;
