import { type Prisma } from "@prisma/client";
import { omit } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { traceBusinessService } from "~/lib/datadog/tracing";
import { flattenObject } from "~/lib/flatten-object";
import { AuthenticatedUserIncludes } from "~/services/auth/authenticated-user-includes";

export const fetchAuthenticatedUser = async (
  ctx: AppContext,
  params: {
    userId: number;
    extraWhere?: Omit<Prisma.UserWhereUniqueInput, "id">;
  }
) => {
  return traceBusinessService(
    {
      tags: {
        "user.id": params.userId,
        "extraWhere": flattenObject(params.extraWhere, "extraWhere"),
      },
      serviceName: "fetchAuthenticatedUser",
    },
    async () => {
      const rawUser = await ctx.prisma.user.findUnique({
        where: { id: params.userId, ...params.extraWhere },
        include: {
          ...AuthenticatedUserIncludes,
          compensationReviewAdmins: {
            select: { id: true },
            take: 1,
          },
        },
      });

      if (!rawUser) {
        return null;
      }

      const user = omit(rawUser, "compensationReviewAdmins");

      return Object.assign(user, {
        isCompensationReviewCampaignAdmin: rawUser.compensationReviewAdmins.length > 0,
      });
    }
  );
};

export type NullableAuthenticatedUser = AsyncReturnType<typeof fetchAuthenticatedUser>;

export const fetchRequiredAuthenticatedUser = async (
  ctx: AppContext,
  params: {
    userId: number;
    extraWhere?: Omit<Prisma.UserWhereUniqueInput, "id">;
  }
) => {
  const user = await fetchAuthenticatedUser(ctx, params);

  if (!user) {
    throw new Error("User not found");
  }

  return user;
};

export type RequiredAuthenticatedUser = AsyncReturnType<typeof fetchRequiredAuthenticatedUser>;
type BaseAuthenticatedUser = Omit<RequiredAuthenticatedUser, "isCompensationReviewCampaignAdmin">;

export const augmentAuthenticatedUser = async (ctx: AppContext, params: { user: BaseAuthenticatedUser }) => {
  // Need to keep augmentation logic duplicated otherwise we end up with circular type dependencies
  return Object.assign(params.user, {
    isCompensationReviewCampaignAdmin: await isCompensationReviewCampaignAdmin(ctx, { userId: params.user.id }),
  });
};

const isCompensationReviewCampaignAdmin = async (ctx: AppContext, params: { userId: number }) => {
  const compensationReviewAdmin = await ctx.prisma.compensationReviewAdmin.findFirst({
    where: { userId: params.userId },
  });

  return !!compensationReviewAdmin;
};
