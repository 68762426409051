import { Alert, Collapse } from "@mui/material";
import React, { type PropsWithChildren } from "react";

export const HeaderAlert: React.FC<PropsWithChildren<{ visible: boolean }>> = ({ visible, children }) => {
  return (
    <Collapse in={visible}>
      <Alert severity="warning" className="mt-4 items-center" sx={{ ".MuiAlert-message": { flexGrow: 1 } }}>
        {children}
      </Alert>
    </Collapse>
  );
};
