import { ExternalEmployeeSource, IntegrationSource, QueueJobName } from "@prisma/client";
import { mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { baseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { filterValidRowsForSchema, jobCacheTransaction } from "~/lib/queue/queue-job-cache";
import { sendJob } from "~/lib/queue/send-job";
import { type YupOutputType } from "~/lib/utils";
import {
  postSyncExternalEmployees,
  prepareAndSyncHierarchicalRelationships,
  updateIntegrationCounters,
} from "~/services/synchronization/post-sync-external-employees";
import { integrationSettingsForSyncInclude } from "~/services/synchronization/sync-external-employees";

const PostSyncExternalEmployeesJobDataSchema = baseJobDataSchema.concat(object({ companyId: number().required() }));

export const PostSyncExternalEmployeesDataSchema = object({
  source: mixed<IntegrationSource>().oneOf(Object.values(IntegrationSource)).required(),
  deleted: number(),
  created: number(),
  updated: number(),
  externalEmployeeId: number(),
  managerExternalId: string(),
});

export const PostSyncExternalEmployeesDeletesReportDataSchema = object({
  source: mixed<IntegrationSource>().oneOf(Object.values(IntegrationSource)).required(),
  deleted: number().required(),
});

export const PostSyncExternalEmployeesUpdatesReportDataSchema = object({
  source: mixed<IntegrationSource>().oneOf(Object.values(IntegrationSource)).required(),
  created: number().required(),
  updated: number().required(),
});

export const PostSyncExternalEmployeesHierarchyDataSchema = object({
  source: mixed<ExternalEmployeeSource>().oneOf(Object.values(ExternalEmployeeSource)).required(),
  externalEmployeeId: number().required(),
  managerExternalId: string().nullable().required(),
});

export type PostSyncExternalEmployeesJobData = YupOutputType<typeof PostSyncExternalEmployeesJobDataSchema>;

const processDataRows = (data: (typeof PostSyncExternalEmployeesDataSchema)["__outputType"][]) => {
  return {
    deletes: filterValidRowsForSchema(data, PostSyncExternalEmployeesDeletesReportDataSchema),
    updates: filterValidRowsForSchema(data, PostSyncExternalEmployeesUpdatesReportDataSchema),
    hierarchy: filterValidRowsForSchema(data, PostSyncExternalEmployeesHierarchyDataSchema),
  };
};

export const postSyncExternalEmployeesWorkerService = async (
  ctx: AppContext,
  data: PostSyncExternalEmployeesJobData
) => {
  const { companyId } = PostSyncExternalEmployeesJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    include: {
      integrationSettings: {
        where: { enabled: true },
        include: integrationSettingsForSyncInclude,
      },
      defaultCountry: true,
    },
  });

  await jobCacheTransaction(
    ctx,
    {
      companyId: company.id,
      queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
      validationSchema: PostSyncExternalEmployeesDataSchema,
    },
    async (ctx, data) => {
      const { updates, deletes, hierarchy } = processDataRows(data);

      await updateIntegrationCounters(ctx, { company, updates, deletes });

      await prepareAndSyncHierarchicalRelationships(ctx, hierarchy, company);
    }
  );

  await postSyncExternalEmployees(ctx, { company });
};

export const sendPostSyncExternalEmployeesJob = async (ctx: AppContext, data: PostSyncExternalEmployeesJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
    data,
    options: { singletonKey: data.companyId.toString() },
  });
};
