import { type Job, JobFamilyCategory } from "@prisma/client";
import { chain, intersection } from "lodash";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { getId } from "~/lib/utils";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { splitCustomJobIds } from "~/services/custom-jobs/split-custom-job-ids";
import { getJobsForPredictiveBenchmark } from "~/services/market-data/get-jobs-for-predictive-benchmark";
import { whereCustomJobIsOwned } from "~/services/permissions/own-company-permissions";

type ResolveJobsInput = {
  jobsIds: string[];
};

export type WeightedJob = {
  job: Job;
  weight: number;
};

export type ResolveWeightedJobsOptions = {
  useTestData: boolean;
};

export const resolveWeightedJobs = async (
  ctx: AppContext,
  input: ResolveJobsInput,
  options?: ResolveWeightedJobsOptions
): Promise<WeightedJob[]> => {
  const { user } = ctx;

  const { jobIds, customJobIds: customJobsIds } = splitCustomJobIds(input.jobsIds);

  const resolvedJobs: Record<number, { jobId: number; weight: number }> = {};

  const addJob = (jobId: number, weight: number) => {
    const resolvedJob = resolvedJobs[jobId];

    if (resolvedJob) {
      resolvedJob.weight += weight;
    } else {
      resolvedJobs[jobId] = { jobId, weight };
    }
  };

  // Add all regular (combined) jobs with a default weight of 1

  jobIds.forEach((jobId) => {
    addJob(jobId, 1);
  });

  // When custom jobs are present, fetch them in the database
  // and add all their "children" jobs with their weights

  if (customJobsIds.length && !options?.useTestData) {
    const customJobs = await ctx.prisma.customJob.findMany({
      where: {
        id: { in: customJobsIds },
        ...(user && whereCustomJobIsOwned(user)),
      },
      include: { items: true },
    });

    customJobs.forEach((customJob) => {
      customJob.items.forEach((item) => {
        addJob(item.jobId, item.weight);
      });
    });
  }

  // Fetch and merge actual jobs models with their weight

  const jobsIds = Object.values(resolvedJobs).map((item) => {
    return item.jobId;
  });

  const jobs = await getJobsForPredictiveBenchmark(ctx, { jobIds: jobsIds });

  const weightedJobs = chain(resolvedJobs)
    .mapValues((item) => {
      const job = jobs.find((job) => {
        return job.id === item.jobId;
      });

      return {
        job: job as Job,
        weight: item.weight,
      };
    })
    .values()
    .value();

  return weightedJobs;
};

export const formatJobFamilyCategory = (category: JobFamilyCategory) => {
  if (!isJobFamilyCategory(category)) {
    return category;
  }

  return match(category)
    .with("GENERAL_AND_ADMINISTRATIVE", () => {
      return "General & Administrative";
    })
    .with("SALES_AND_MARKETING", () => {
      return "Sales & Marketing";
    })
    .with("TECH", () => {
      return "Tech";
    })
    .exhaustive();
};

export const getCompanyAllowedJobIds = (user: NullableAuthenticatedUser) => {
  if (!user) {
    return null;
  }

  return user.company.companyBenchmarkRestriction?.allowedJobs.map(getId);
};

export const getAllowedJobFamilyIds = (user: NullableAuthenticatedUser) => {
  if (!user) {
    return null;
  }

  const allowedJobFamilyIdsForCompany = value(() => {
    if (!user.company.companyBenchmarkRestriction) {
      return null;
    }

    const jobFamilyIds = user.company.companyBenchmarkRestriction.allowedJobFamilies.map(getId);

    if (jobFamilyIds.length === 0) {
      return null;
    }

    return jobFamilyIds;
  });

  if (user.permissions.allowedJobFamilies.length === 0) {
    return allowedJobFamilyIdsForCompany;
  }

  const allowedJobFamilyIdsForUser = user.permissions.allowedJobFamilies.map(getId);

  if (!allowedJobFamilyIdsForCompany) {
    return allowedJobFamilyIdsForUser;
  }

  return intersection(allowedJobFamilyIdsForCompany, allowedJobFamilyIdsForUser);
};

export const isJobFamilyCategory = (category: JobFamilyCategory | null | string): category is JobFamilyCategory => {
  return Object.values(JobFamilyCategory).includes(category as JobFamilyCategory);
};

export const fetchJobForBackoffice = async (ctx: AppContext, jobId: number) => {
  const job = await ctx.prisma.job.findUniqueOrThrow({
    select: {
      id: true,
      name: true,
      description: true,
      availableLevels: true,
      family: true,
      jobAliases: {
        select: {
          id: true,
          name: true,
        },
      },
    },
    where: { id: jobId },
  });

  const mappedExternalJobs = await ctx.prisma.externalJob.findMany({
    where: { mappedJobId: job.id },
    select: { id: true },
  });

  return { ...job, mappedExternalJobs };
};
