import { isNumber, isString } from "lodash";

export type RawHolidayAllowanceValue = string | number | Record<string, string> | null;

export const cleanHolidayAllowanceValue = (value: RawHolidayAllowanceValue | undefined) => {
  if (!value) {
    return null;
  }

  if (isNumber(value)) {
    return value;
  }

  if (!isString(value)) {
    return null;
  }

  // This will remove any non-digit in the value (such as currency symbol) except for , and .
  const parsedValue = parseInt(value.replaceAll(/[^0-9,.]/g, ""));

  if (isNaN(parsedValue)) {
    return null;
  }

  return parsedValue;
};
