import { CompensationReviewCampaignStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { assertNotNil } from "~/lib/utils";
import { type FetchExternalEmployeeCompensationHistoryInput } from "~/pages/api/external-employee/fetch-external-employee-compensation-history";
import { extractCompensationReviewData } from "~/services/compensation-review/campaigns/campaign-handler";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensation-review-context";
import {
  compensationReviewEmployeeSelect,
  enrichCompensationReviewEmployees,
} from "~/services/compensation-review/shared/enrich-compensation-review-employees";
export const fetchExternalEmployeeCompensationHistory = async (
  ctx: AppContext,
  input: FetchExternalEmployeeCompensationHistoryInput
) => {
  const user = getRequiredUser(ctx);

  const compensationReviewEmployees = await ctx.prisma.compensationReviewEmployee.findMany({
    where: {
      externalEmployeeId: input.externalEmployeeId,
      onTargetEarningsIncrease: { not: null },
      campaign: {
        companyId: user.companyId,
        status: CompensationReviewCampaignStatus.CLOSED,
        ...(input.excludedCampaignId && { id: { not: input.excludedCampaignId } }),
      },
    },
    select: {
      campaign: {
        select: {
          id: true,
          name: true,
          createdAt: true,
          startDate: true,
          endDate: true,
        },
      },
      ...compensationReviewEmployeeSelect,
    },
    orderBy: [
      { campaign: { endDate: "desc" } },
      { campaign: { startDate: "desc" } },
      { campaign: { createdAt: "desc" } },
    ],
  });

  const compensationReviewEmployeesWithCampaign = compensationReviewEmployees.map((employee) => ({
    ...employee,
    campaign: assertNotNil(employee.campaign),
  }));

  return mapSeries(compensationReviewEmployeesWithCampaign, async (employee) => {
    const campaignCtx = await fetchCompensationReviewCampaignContext(ctx, { campaignId: employee.campaign.id });

    const [row] = await enrichCompensationReviewEmployees(campaignCtx, { rows: [{ employee }] });

    return {
      compensationReview: extractCompensationReviewData(campaignCtx),
      employee: assertNotNil(row).employee,
    };
  });
};

export type FetchExternalEmployeeCompensationHistoryResult = AsyncReturnType<
  typeof fetchExternalEmployeeCompensationHistory
>;
