import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { type FetchCompanyPerformanceReviewIntegrationSettingsInput } from "~/pages/api/performance-review/fetch-company-performance-review-integration-settings";

export const fetchCompanyPerformanceReviewIntegrationSettings = async (
  ctx: AppContext,
  input: FetchCompanyPerformanceReviewIntegrationSettingsInput
) => {
  return ctx.prisma.performanceReviewIntegrationSettings.findUnique({
    where: { companyId_source: input },
  });
};

export type CompanyPerformanceReviewIntegrationSettings = AsyncReturnType<
  typeof fetchCompanyPerformanceReviewIntegrationSettings
>;
