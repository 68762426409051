import { QueueJobName } from "@prisma/client";
import { mixed, object } from "yup";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { baseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { sendJob } from "~/lib/queue/send-job";
import { SpreadsheetAction } from "~/lib/spreadsheet/templates";
import { type YupOutputType } from "~/lib/utils";
import { importInitialExternalEmployees, type ImportMode } from "~/services/import-initial-external-employees";
import { parsePartialExternalEmployees } from "~/services/partial-spreadsheet-update/import-partial-external-employees";
import { persistPartialExternalEmployees } from "~/services/partial-spreadsheet-update/persist-partial-external-employees";
import { type XlsxToJsonResult } from "~/services/xlsx-to-json";
const ImportSpreadsheetJobDataSchema = baseJobDataSchema.concat(
  object({
    companyId: mixed<number>().required(),
    action: mixed<SpreadsheetAction>().oneOf(Object.values(SpreadsheetAction)).required(),
    importMode: mixed<ImportMode>().required(),
    rows: mixed<XlsxToJsonResult["rows"]>().required(),
  })
);

export type ImportSpreadsheetJobData = YupOutputType<typeof ImportSpreadsheetJobDataSchema>;

export const importSpreadsheetWorkerService = async (ctx: AppContext, data: ImportSpreadsheetJobData) => {
  const user = getRequiredUser(ctx);
  const input = ImportSpreadsheetJobDataSchema.validateSync(data, { abortEarly: false });

  if (data.action === SpreadsheetAction.SYNC) {
    await importInitialExternalEmployees(ctx, {
      company: user.company,
      collectedAt: new Date(),
      importMode: input.importMode,
      rows: input.rows,
    });
  } else if (data.action === SpreadsheetAction.UPDATE) {
    const { rows } = await parsePartialExternalEmployees(ctx, input);

    await persistPartialExternalEmployees(ctx, {
      spreadsheetRows: rows,
    });
  }
};

export const sendImportSpreadsheetJob = async (ctx: AppContext, data: ImportSpreadsheetJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.IMPORT_SPREADSHEET,
    data,
    options: { singletonKey: data.companyId.toString() },
  });
};
