import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import {
  type GetCompanyRemappingRequestResult,
  getCompanyRemappingRequest,
} from "~/services/remapping-request/get-company-remapping-request";

const handler: NextApiHandler<GetCompanyRemappingRequestResult> = async (req, res) => {
  const remappingRequests = await getCompanyRemappingRequest(req);

  return res.status(HttpStatus.OK).json(remappingRequests);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessRawData: true,
  },
});

export const GET_COMPANY_REMAPPING_REQUEST_QUERY_KEY: ApiRoute["pathname"] =
  "/api/remapping-request/get-company-remapping-request";

export const useFetchCompanyRemappingRequestQuery = createQuery<typeof handler>({
  path: GET_COMPANY_REMAPPING_REQUEST_QUERY_KEY,
});
