import { map, mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { notifyPerformanceReviewIntegrationError } from "~/lib/external/slack/notifications";
import { logError, logInfo, logWarn } from "~/lib/logger";
import { getPerformanceReviews } from "~/lib/performance-review-integration";
import { createOrUpdatePerformanceRating } from "~/services/performance-review/create-or-update-performance-rating";
import {
  type PerformanceReviewScopeInput,
  findOrCreatePerformanceReviewCycle,
} from "~/services/performance-review/find-or-create-performance-review-cycle";
import { type CompanyWithPerformanceReviewIntegrationSettings } from "~/services/synchronization/sync-performance-reviews-by-source";

export const syncPerformanceReviews = async (
  ctx: AppContext,
  company: CompanyWithPerformanceReviewIntegrationSettings,
  options?: { performanceReviewScope?: PerformanceReviewScopeInput }
): Promise<void> => {
  logInfo(ctx, "[sync] Synchronizing performance reviews", {
    companyId: company.id,
    activeIntegrations: company.performanceReviewIntegrationSettings.map((integration) => integration.source),
  });

  await mapSeries(company.performanceReviewIntegrationSettings, async (integrationSettings) => {
    logInfo(ctx, "[sync] Synchronizing performance reviews for integration", {
      companyName: company.name,
      companyId: company.id,
      source: integrationSettings.source,
    });

    try {
      const performanceReviewCycle = await findOrCreatePerformanceReviewCycle(ctx, {
        companyId: company.id,
        performanceReviewScope: options?.performanceReviewScope ?? null,
      });

      const performanceReviews = await getPerformanceReviews(integrationSettings.source)(
        ctx,
        integrationSettings,
        performanceReviewCycle
      );

      if (performanceReviews.length === 0) {
        logWarn(ctx, "[perf-review] Partner returned 0 reviews. Assuming random hiccup and ignoring this sync.", {
          companyId: company.id,
          source: integrationSettings.source,
        });
        return;
      }

      await map(performanceReviews, async (performanceReview) => {
        return createOrUpdatePerformanceRating(ctx, {
          performanceReviewCycleId: performanceReviewCycle.id,
          rating: performanceReview,
          companyId: company.id,
          performanceReviewScope: options?.performanceReviewScope ?? null,
        });
      });

      await ctx.prisma.performanceReviewIntegrationSettings.update({
        where: { id: integrationSettings.id },
        data: { lastSynchronisedAt: new Date() },
      });
    } catch (error) {
      logError(ctx, "[sync] Error while synchronizing performance reviews. Disabling integration for company.", {
        error,
        companyName: company.name,
        companyId: company.id,
        source: integrationSettings.source,
      });

      await notifyPerformanceReviewIntegrationError(ctx, {
        error,
        source: integrationSettings.source,
        companyId: company.id,
        companyName: company.name,
      });

      await ctx.prisma.performanceReviewIntegrationSettings.update({
        where: { id: integrationSettings.id },
        data: { enabled: false },
      });

      throw error;
    }
  });
};
