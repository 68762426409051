import { type QueueJobName, QueueJobStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import tracer from "~/lib/datadog/tracing";
import { flattenObject } from "~/lib/flatten-object";
import { getRequiredUser } from "~/lib/get-required-user";
import { logError, logInfo } from "~/lib/logger";
import { type BaseJobData } from "~/lib/queue/base-job-data-schema";
import { makeQueueDriver, type SendOptions } from "~/lib/queue/make-queue-driver";
import { updateQueueJobStatus } from "~/lib/queue/update-queue-job-status";
import packageJson from "../../../package.json";

const DEFAULT_JOB_OPTIONS: SendOptions = {
  retryLimit: 3,
  retryDelay: 5, // seconds
  retryBackoff: true,
} as const;

type JobParams<T extends BaseJobData> = {
  data: T;
  jobName: QueueJobName;
  options: SendOptions & { singletonKey: string };
};

export const sendJob = async <T extends BaseJobData>(ctx: AppContext, params: JobParams<T>) => {
  const { data, jobName, options } = params;

  const defaultedOptions = { ...DEFAULT_JOB_OPTIONS, ...options };

  try {
    const tags = {
      "span.kind": "server",
      "jobqueue.jobname": jobName,
      ...flattenObject(defaultedOptions, "jobqueue.options"),
    };

    const user = getRequiredUser(ctx);

    const jobId = await tracer.trace("jobqueue.send", { tags, service: `${packageJson.name}.jobqueue` }, async () => {
      return await makeQueueDriver().sendJob(
        ctx,
        jobName,
        {
          meta: {
            userId: user.id,
            companyId: ctx.impersonatingCompanyId ?? user.companyId,
          },
          ...data,
        },
        defaultedOptions
      );
    });

    if (jobId) {
      logInfo(ctx, "[worker] Job created", { jobId, data });

      await updateQueueJobStatus(ctx, {
        name: jobName,
        singletonKey: options.singletonKey,
        status: QueueJobStatus.SCHEDULED,
        externalJobId: jobId,
      });
    } else if (!options.singletonKey) {
      throw new Error(`Could not create job "${jobName}"`);
    }
  } catch (error) {
    logError(ctx, "[worker] Unexpected error", { error, data });

    throw error;
  }
};
