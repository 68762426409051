import { DataQuality, type EmployeeLevel } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { fetchCompanyWithCompensationSettings } from "~/services/employee-stats/fetch-company-with-compensation-settings";
import { type EmployeeForStats } from "~/services/employee-stats/fetch-comparable-employees-for-stats";
import { findComparableEmployees } from "~/services/employee-stats/find-comparable-employees";
import {
  getAmountForPercentile,
  getPercentileAmountForMeasure,
  getTargetPercentileForEmployee,
} from "~/services/employee-stats/utils";
import { getLiveEmployees } from "~/services/employee/get-live-employees";
import { enrichEmployeesWithCapitalLocationId, getCapitalLocationsByCountryId } from "~/services/locations/location";
import { getPredictivePercentileAmountForMeasure } from "~/services/market-data/get-market-data-predictive-benchmark";
//TO BE REMOVED WITH NEW EMPLOYEE FORM?
export const computeTotalCashStatsForInput = async (
  ctx: AppContext,
  input: {
    jobId: number;
    level: EmployeeLevel;
    locationId: number;
    currencyId: number;
    totalCash: number;
  }
) => {
  const user = getRequiredUser(ctx);

  const company = await fetchCompanyWithCompensationSettings(ctx, { companyId: user.companyId });
  const currency = await ctx.prisma.currency.findUniqueOrThrow({ where: { id: input.currencyId } });

  const job = await ctx.prisma.job.findUniqueOrThrow({
    where: { id: input.jobId },
    select: { id: true, familyId: true, name: true },
  });

  const location = await ctx.prisma.employeeLocation.findUniqueOrThrow({
    where: { id: input.locationId },
    select: { id: true, countryId: true, name: true, country: { select: { name: true } } },
  });

  const marketEmployees = await getLiveEmployees(ctx, {
    buckets: [{ countryId: location.countryId, jobId: input.jobId, level: input.level }],
  });

  const marketEmployeesWithCapitalLocationId = await enrichEmployeesWithCapitalLocationId(ctx, {
    employeesWithLocation: marketEmployees,
  });

  const capitalLocationsByCountryId = await getCapitalLocationsByCountryId(ctx);
  const employee = {
    id: -1,
    companyId: user.companyId,
    jobId: input.jobId,
    locationId: input.locationId,
    capitalLocationId: capitalLocationsByCountryId[input.locationId],
    level: input.level,
    baseSalary: input.totalCash,
    onTargetBonus: 0,
    currency: currency,
    location: {
      name: location.name,
      countryId: location.countryId,
      country: {
        name: location.country.name,
      },
    },
    job: {
      familyId: job.familyId,
      name: job.name,
    },
  } as EmployeeForStats;

  const comparison = findComparableEmployees({
    company,
    employee,
    marketEmployees: marketEmployeesWithCapitalLocationId,
  });

  const targetPercentile = getTargetPercentileForEmployee({ company, employee });

  let datasetEmployeeStats = getPercentileAmountForMeasure({
    employee,
    measure: "totalCash",
    comparison,
    targetPercentile,
  });

  if (datasetEmployeeStats.dataQuality === DataQuality.NONE) {
    const predictiveData = await getPredictivePercentileAmountForMeasure(ctx, {
      currency,
      percentile: targetPercentile,
      level: employee.level,
      jobName: employee.job.name,
      countryName: employee.location.country.name,
      locationName: employee.location.name,
    });

    if (predictiveData) {
      const amountForPercentile = getAmountForPercentile({
        amounts: [predictiveData["totalCash"].amountForPercentile],
        amount: employee.baseSalary,
        percentile: targetPercentile,
      });
      datasetEmployeeStats = {
        amountForPercentile: Math.round(predictiveData["totalCash"].amountForPercentile),
        difference: amountForPercentile.difference,
        percentageDifference: amountForPercentile.percentageDifference,
        dataQuality: predictiveData["totalCash"].dataQuality,
      };
    }
  }

  return datasetEmployeeStats;
};
