import { CompanyType } from "@prisma/client";
import { mapSeries } from "bluebird";
import { chain } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type RefreshContext } from "~/cli/helpers";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { dangerouslyIgnorePrismaRestrictions } from "~/lib/prisma-tokens";
import { validateRequestAuthorised } from "~/lib/security";

export const MAPPING_THRESHOLD = 0.75;
export const IMPORTED_EMPLOYEES_THRESHOLD = 10;

const selectForCompany = {
  id: true,
  type: true,
  _count: {
    select: {
      externalEmployees: true,
    },
  },
};

type CompanyForUpdate = AsyncReturnType<typeof fetchAllCompaniesForOnboardingStateUpdate>;

export const setOnboardingState = async (ctx: AppContext, params: { refreshContext: RefreshContext }) => {
  const { refreshContext } = params;

  const allActiveCompanies = await fetchAllCompaniesForOnboardingStateUpdate(ctx);

  await mapSeries(allActiveCompanies, async (company) => {
    await refreshContext({ companyId: company.id });
    await setOnboardingStateForCompany(ctx, { company });
  });
};

export const setOnboardingStateForCompany = async (ctx: AppContext, params: { company: CompanyForUpdate[number] }) => {
  const { company } = params;

  const externalJobs = await ctx.prisma.externalJob.findMany({
    ...dangerouslyIgnorePrismaRestrictions(),
    where: {
      companyId: company.id,
      employees: {
        some: {},
      },
    },
    select: {
      mappedJobId: true,
    },
  });

  const mappedJobs = chain(externalJobs)
    .filter((externalJob) => externalJob.mappedJobId !== null)
    .map((externalJob) => externalJob.mappedJobId)
    .value();

  const mappedJobsProgress = mappedJobs.length / externalJobs.length;

  const hasFinishedMapping = mappedJobsProgress > MAPPING_THRESHOLD;
  const hasImportedEmployees =
    company.type === CompanyType.PARTICIPANT ? company._count.externalEmployees > IMPORTED_EMPLOYEES_THRESHOLD : true;

  const updatedCompany = await ctx.prisma.company.update({
    data: {
      hasFinishedMapping,
      hasImportedEmployees,
    },
    where: {
      id: company.id,
    },
    select: {
      id: true,
      hasFinishedMapping: true,
      hasImportedEmployees: true,
    },
  });

  logInfo(ctx, "[chore] Updated Onboarding Stats for", {
    companyId: company.id,
    hasFinishedMapping,
    hasImportedEmployees,
  });

  return updatedCompany;
};

export const fetchAllCompaniesForOnboardingStateUpdate = async (ctx: AppContext) => {
  return ctx.prisma.company.findMany({
    where: {
      disabledAt: null,
    },
    select: selectForCompany,
  });
};

export const updateStatusForCompanyId = async (ctx: AppContext, params: { companyId: number }) => {
  const { companyId } = params;
  validateRequestAuthorised(ctx, { companyId });

  const company = await ctx.prisma.company.findUnique({
    where: {
      id: companyId,
    },
    select: selectForCompany,
  });

  if (!company) {
    return;
  }

  await setOnboardingStateForCompany(ctx, { company });
};
