import {
  CompanyMarketPositioningType,
  type EmployeeLevel,
  EmployeeStatus,
  MarketFilterSelectionMode,
  type Prisma,
} from "@prisma/client";
import { chain } from "lodash";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { hasTags, whereCompanyIsPartOfDataset } from "~/services/company";
import { generateEmployeeBucketHash } from "~/services/company-dashboard/generate-employee-bucket-hash";
import { hasFundingRound, isWithinSize } from "~/services/company-survey/helpers";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetch-company-with-compensation-settings";
import { getMergedLevels } from "~/services/employee/employee-level";
import { getHeadcountRange } from "~/services/headcount";
import { enrichEmployeesWithCapitalLocationId } from "~/services/locations/location";

export const employeeForStatsSelect = {
  id: true,
  companyId: true,
  company: {
    select: {
      id: true,
      tags: { select: { id: true } },
    },
  },
  jobId: true,
  locationId: true,
  level: true,
  baseSalary: true,
  fixedBonus: true,
  fixedBonusPercentage: true,
  onTargetBonus: true,
  onTargetBonusPercentage: true,
  currency: true,
  location: {
    select: {
      name: true,
      countryId: true,
      country: {
        select: {
          name: true,
        },
      },
    },
  },
  job: {
    select: {
      name: true,
      familyId: true,
    },
  },
  survey: true,
} satisfies Prisma.EmployeeSelect;

// This method is exported to ease tests
// (see "createDataset" helper function in `tests/unit/services/employees-stats.test.ts`)
export const fetchComparableEmployeesForStats = async (
  ctx: AppContext,
  params: { company: CompanyWithCompensationSettings; employeesIds: number[] }
) => {
  const { featureFlags, user } = ctx;

  const employeesForBucket = await ctx.prisma.employee.findMany({
    where: { id: { in: params.employeesIds } },
    select: {
      jobId: true,
      level: true,
      location: {
        select: {
          id: true,
          countryId: true,
        },
      },
    },
  });

  const buckets = chain(employeesForBucket)
    .flatMap((employee) => {
      // We "merge" advanced levels because we may need them in `findComparableEmployeesWithinDataset`
      const levels = getMergedLevels(employee.level, {
        mergeAdvancedLevels: !params.company.useAdvancedLevels,
      }) as EmployeeLevel[];

      return levels.flatMap((level) => {
        const countryIds =
          params.company.compareSpecificLocations.length > 0
            ? params.company.compareSpecificLocations.map((location) => location.countryId)
            : [employee.location.countryId];

        return countryIds.map((countryId) => ({
          countryId: countryId,
          jobId: employee.jobId,
          level,
        }));
      });
    })
    .uniqBy(({ countryId, jobId, level }) => [countryId, jobId, level].join("|"))
    .value();

  const comparableEmployees = await ctx.prisma.employee.findMany({
    where: {
      company: whereCompanyIsPartOfDataset(user),
      status: EmployeeStatus.LIVE,
      employeeDataValidationFlags: { none: { isLive: true } },
      bucketHash: { in: buckets.map(generateEmployeeBucketHash) },
    },
    select: employeeForStatsSelect,
  });

  const comparableEmployeesWithCapitalLocationId = await enrichEmployeesWithCapitalLocationId(ctx, {
    employeesWithLocation: comparableEmployees,
  });

  if (!params.company.marketPositioningId) {
    return comparableEmployeesWithCapitalLocationId;
  }

  const defaultFilter = await ctx.prisma.marketPositioning.findUniqueOrThrow({
    where: {
      id: params.company.marketPositioningId,
    },
  });

  return comparableEmployeesWithCapitalLocationId.filter((employee) => {
    return match(defaultFilter.type)
      .with(CompanyMarketPositioningType.ENTIRE_MARKET, () => true)
      .with(CompanyMarketPositioningType.SIMILAR_HEADCOUNT, () => {
        const size = featureFlags.CAN_ACCESS_FIGURES_AI_V2
          ? getHeadcountRange(defaultFilter.headcount)
          : { min: defaultFilter.minHeadcount, max: defaultFilter.maxHeadcount };

        const predicate = isWithinSize(size);

        return predicate(employee.survey);
      })
      .with(CompanyMarketPositioningType.SIMILAR_FUNDING_STAGE, () => {
        if (!defaultFilter.fundingRounds) {
          return true;
        }

        const fundingRounds = defaultFilter.fundingRounds;
        return hasFundingRound(fundingRounds)(employee.survey);
      })
      .with(CompanyMarketPositioningType.SPECIFIC_INDUSTRY, () => {
        if (!defaultFilter.industryId) {
          return true;
        }

        return hasTags([defaultFilter.industryId], MarketFilterSelectionMode.AND)(employee.company);
      })
      .exhaustive();
  });
};

export type EmployeeForStats = AsyncReturnType<typeof fetchComparableEmployeesForStats>[number];
