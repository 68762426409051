import { isString } from "lodash";
import { useRouter } from "next/router";
import { type Route, route } from "nextjs-routes";
import { useEffect, useState } from "react";
import { config } from "~/config";

export const useOpenLink = () => {
  const router = useRouter();

  const [isMetaPressed, setIsMetaPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      setIsMetaPressed(event.metaKey || event.ctrlKey);
    };

    const handleKeyUp = () => {
      setIsMetaPressed(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const openLink = (url: string | Route, options?: { forceNewTab?: boolean }) => {
    const openInNewTab = options?.forceNewTab || isMetaPressed;

    if (openInNewTab) {
      const fullUrl = isString(url) ? url : `${config.app.url}${route(url)}`;

      window.open(fullUrl, "_blank")?.focus();
    } else if (isString(url)) {
      window.location.href = url;
    } else {
      void router.push(url);
    }
  };

  return { openLink, isMetaPressed };
};
