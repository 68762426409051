import { InfoOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import classNames from "classnames";
import React, { type ComponentProps, type ReactNode } from "react";
import { TitledTooltip } from "~/components/ui/core/TitledTooltip";

type Props = {
  label: string;
  labelActions?: JSX.Element;
  description?: string | string[];
  tooltip?: Omit<ComponentProps<typeof TitledTooltip>, "children">;
  control: ReactNode;
  component?: "div" | "label";
  alignItems?: ComponentProps<typeof Stack>["alignItems"];
  labelClassNames?: string;
};

export const CardFormControl: React.FC<Props> = ({
  label,
  labelActions,
  description,
  tooltip,
  control,
  component = "label",
  alignItems = "start",
  labelClassNames,
}) => {
  const descriptions = Array.isArray(description) ? description : [description];

  const labelElement = tooltip ? (
    <TitledTooltip {...tooltip}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="subtitle1">{label}</Typography>
        <InfoOutlined className="text-base text-gray-600" />
        {labelActions}
      </Stack>
    </TitledTooltip>
  ) : (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="subtitle1">{label}</Typography>
      {labelActions}
    </Stack>
  );

  return (
    <Stack direction="row" columnGap={8} rowGap={4} alignItems={alignItems} component={component} flexWrap="wrap">
      <Stack
        spacing={1}
        className={classNames(labelClassNames, {
          "w-72 shrink-0": true,
          "cursor-pointer": component === "label",
        })}
      >
        {labelElement}
        {descriptions.map((description, index) => (
          <Typography key={index} variant="caption" color="text.secondary">
            {description}
          </Typography>
        ))}
      </Stack>
      {control}
    </Stack>
  );
};
