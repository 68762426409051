import { Drawer } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { useCommandPalette } from "~/components/command-palette/command-palette-context";
import { PortalRootProvider } from "~/hooks/usePortalRoot";

export type SidePanelProps = React.PropsWithChildren<{
  isOpen: boolean;
  sideButtons?: JSX.Element;
  width?: "large" | "base" | "narrow" | "extra-large";
  onClose: () => void;
  side?: "left" | "right";
  disableBottomPadding?: boolean;
}>;

export const SidePanel: React.FC<SidePanelProps> = ({
  isOpen,
  onClose,
  sideButtons,
  width = "large",
  side = "right",
  disableBottomPadding = false,
  children,
}) => {
  const { showPalette } = useCommandPalette();

  return (
    <Drawer
      disableEnforceFocus={showPalette}
      ModalProps={{ closeAfterTransition: true }}
      open={isOpen}
      onClose={onClose}
      SlideProps={{
        direction: side === "left" ? "right" : "left",
        timeout: 150,
        in: isOpen,
      }}
      sx={{
        "& .MuiPaper-root": {
          overflowY: "initial",
          outline: "none",
        },
      }}
      anchor={side}
    >
      <div
        className={classNames({
          "relative flex h-full w-screen flex-col overflow-y-auto overflow-x-hidden bg-white shadow-xl": true,
          "pb-24": !disableBottomPadding,
          "max-w-6xl": width === "extra-large",
          "max-w-4xl": width === "large",
          "max-w-xl": width === "base",
          "max-w-sm": width === "narrow",
        })}
        id="side-panel"
      >
        <PortalRootProvider selector="#side-panel">{children}</PortalRootProvider>
      </div>
      {sideButtons && (
        <div className="absolute top-16 -left-10 flex w-10 flex-col space-y-1 rounded-l border-r bg-gray-200 p-0.5 shadow-sm">
          {sideButtons}
        </div>
      )}
    </Drawer>
  );
};
