import { type EmployeeLevel, ExternalEmployeeStatus, ExternalRemunerationType, type Prisma } from "@prisma/client";
import { chain, isNil, isNull } from "lodash";

export const selectExternalEmployeeForPartialCheck = {
  id: true,
  gender: true,
  status: true,
  deletedAt: true,
  jobId: true,
  levelId: true,
  locationId: true,
  remunerationItems: { select: { amount: true, nature: { select: { mappedType: true } } } },
} satisfies Prisma.ExternalEmployeeSelect;

export const selectExternalEmployeeForMappedCheck = {
  ...selectExternalEmployeeForPartialCheck,
  job: { select: { mappedJobId: true } },
  location: { select: { mappedLocationId: true } },
  level: { select: { mappedLevel: true } },
} satisfies Prisma.ExternalEmployeeSelect;

export type ExternalEmployeeForMappedCheck = Prisma.ExternalEmployeeGetPayload<{
  select: typeof selectExternalEmployeeForMappedCheck;
}>;

export type ExternalEmployeeForPartialCheck = Prisma.ExternalEmployeeGetPayload<{
  select: typeof selectExternalEmployeeForPartialCheck;
}>;

export const isValidPartialEmployee = (externalEmployee: ExternalEmployeeForPartialCheck) => {
  if (externalEmployee.status === ExternalEmployeeStatus.SKIPPED) {
    return false;
  }

  return (
    !isNil(externalEmployee.gender) &&
    !isNil(externalEmployee.jobId) &&
    !isNil(externalEmployee.levelId) &&
    !isNil(externalEmployee.locationId) &&
    externalEmployee.remunerationItems.length > 0 &&
    chain(externalEmployee.remunerationItems)
      .filter((remunerationItem) => remunerationItem.nature.mappedType === ExternalRemunerationType.FIXED_SALARY)
      .sumBy((remunerationItem) => remunerationItem.amount)
      .value() > 0
  );
};

export const isValidMappedEmployee = (
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => {
  if (externalEmployee.status === ExternalEmployeeStatus.SKIPPED) {
    return false;
  }

  const hasMappedJob = !isNull(getMappedJobId(externalEmployee, individualMapping));
  const hasMappedLevel = !isNull(getMappedLevel(externalEmployee, individualMapping));
  const hasMappedLocation = !isNull(getMappedLocationId(externalEmployee, individualMapping));

  return (
    !isNil(externalEmployee.gender) &&
    externalEmployee.remunerationItems.length > 0 &&
    chain(externalEmployee.remunerationItems)
      .filter((remunerationItem) => remunerationItem.nature.mappedType === ExternalRemunerationType.FIXED_SALARY)
      .sumBy((remunerationItem) => remunerationItem.amount)
      .value() > 0 &&
    hasMappedJob &&
    hasMappedLevel &&
    hasMappedLocation
  );
};

export type IndividualMapping = {
  jobId?: number | null;
  level?: EmployeeLevel | null;
  locationId?: number | null;
};

export const getMappedJobId = (
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => individualMapping?.jobId ?? externalEmployee.job?.mappedJobId ?? null;

export const getMappedLevel = (
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => individualMapping?.level ?? externalEmployee.level?.mappedLevel ?? null;

export const getMappedLocationId = (
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => individualMapping?.locationId ?? externalEmployee.location?.mappedLocationId ?? null;
