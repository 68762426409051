import { QueueJobName, SalaryGridMeasure } from "@prisma/client";
import { match } from "ts-pattern";
import { array, boolean, mixed, number, object, ref, string } from "yup";
import { type AppContext } from "~/lib/context";
import { baseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { sendJob } from "~/lib/queue/send-job";
import { type YupOutputType } from "~/lib/utils";
import { updateSalaryBandMeasures } from "~/services/salary-bands/configuration/update-salary-band-measures";
import { updateSalaryRangeBounds } from "~/services/salary-bands/configuration/update-salary-range-bounds";
import { updateSalaryRangeMidpoints } from "~/services/salary-bands/configuration/update-salary-range-midpoints";
import { updateSalaryRangeTargetPercentiles } from "~/services/salary-bands/configuration/update-salary-range-target-percentiles";
import { updateSalaryRangeWidths } from "~/services/salary-bands/configuration/update-salary-range-widths";
import { salaryGridFiltersSchema } from "~/services/salary-bands/helpers/salary-grid-filters";

export enum UpdateType {
  BAND_MEASURE = "band-measure",
  RANGE_BOUNDS = "range-bounds",
  RANGE_MIDPOINT = "range-midpoint",
  RANGE_TARGET_PERCENTILE = "range-target-percentile",
  RANGE_WIDTH = "range-width",
}

export type BulkUpdateSalaryRangeBoundsInput = YupOutputType<typeof bulkUpdateSalaryRangeBoundsSchema>;

export const bulkUpdateSalaryBandMeasuresSchema = object({
  allBands: boolean().required(),
  filters: salaryGridFiltersSchema,
  measure: mixed<SalaryGridMeasure>().oneOf(Object.values(SalaryGridMeasure)).required(),
  salaryGridId: number().required(),
  salaryRangeIds: array().of(number().required()).ensure(),
  reason: string().nullable(),
});

export const bulkUpdateSalaryRangeBoundsSchema = object({
  allRanges: boolean().required(),
  filters: salaryGridFiltersSchema,
  min: number().min(0).max(ref("max")).required(),
  max: number().required(),
  salaryGridId: number().required(),
  salaryRangeIds: array().of(number().required()).ensure(),
  reason: string().nullable(),
});

export type BulkUpdateSalaryBandMeasuresInput = YupOutputType<typeof bulkUpdateSalaryBandMeasuresSchema>;

export const bulkUpdateSalaryRangeMidpointsSchema = object({
  allRanges: boolean().required(),
  filters: salaryGridFiltersSchema,
  midpoint: number().required(),
  salaryGridId: number().required(),
  salaryRangeIds: array().of(number().required()).ensure(),
  reason: string().nullable(),
});

export type BulkUpdateSalaryRangeMidpointsInput = YupOutputType<typeof bulkUpdateSalaryRangeMidpointsSchema>;

export const bulkUpdateSalaryRangeTargetPercentilesSchema = object({
  allRanges: boolean().required(),
  filters: salaryGridFiltersSchema,
  percentileRank: number().required().min(0).max(1),
  salaryGridId: number().required(),
  salaryRangeIds: array().of(number().required()).ensure(),
  reason: string().nullable(),
});

export type BulkUpdateSalaryRangeTargetPercentilesInput = YupOutputType<
  typeof bulkUpdateSalaryRangeTargetPercentilesSchema
>;

export const bulkUpdateSalaryRangeWidthsSchema = object({
  allRanges: boolean().required(),
  filters: salaryGridFiltersSchema,
  salaryGridId: number().required(),
  salaryRangeIds: array().of(number().required()).ensure(),
  width: number().required().min(0).max(1),
  reason: string().nullable(),
});

export type BulkUpdateSalaryRangeWidthsInput = YupOutputType<typeof bulkUpdateSalaryRangeWidthsSchema>;

const updateSalaryRangeSettingsJobDataSchema = baseJobDataSchema.concat(
  object({
    updateType: mixed<UpdateType>().oneOf(Object.values(UpdateType)).required(),
    input: mixed().when("updateType", ([updateType], schema) => {
      return match(updateType)
        .with(UpdateType.BAND_MEASURE, () => bulkUpdateSalaryBandMeasuresSchema.required())
        .with(UpdateType.RANGE_BOUNDS, () => bulkUpdateSalaryRangeBoundsSchema.required())
        .with(UpdateType.RANGE_MIDPOINT, () => bulkUpdateSalaryRangeMidpointsSchema.required())
        .with(UpdateType.RANGE_TARGET_PERCENTILE, () => bulkUpdateSalaryRangeTargetPercentilesSchema.required())
        .with(UpdateType.RANGE_WIDTH, () => bulkUpdateSalaryRangeWidthsSchema.required())
        .otherwise(() => schema);
    }),
  })
);

type UpdateSalaryRangeSettingsJobData = YupOutputType<typeof updateSalaryRangeSettingsJobDataSchema>;

export const updateSalaryRangeSettingsWorkerService = async (
  ctx: AppContext,
  data: UpdateSalaryRangeSettingsJobData
) => {
  const { updateType, input } = updateSalaryRangeSettingsJobDataSchema.validateSync(data);

  await match(updateType)
    .with(UpdateType.BAND_MEASURE, async () => {
      await updateSalaryBandMeasures(ctx, input);
    })
    .with(UpdateType.RANGE_BOUNDS, async () => {
      await updateSalaryRangeBounds(ctx, input);
    })

    .with(UpdateType.RANGE_MIDPOINT, async () => {
      await updateSalaryRangeMidpoints(ctx, input);
    })
    .with(UpdateType.RANGE_TARGET_PERCENTILE, async () => {
      await updateSalaryRangeTargetPercentiles(ctx, input);
    })
    .with(UpdateType.RANGE_WIDTH, async () => {
      await updateSalaryRangeWidths(ctx, input);
    })
    .exhaustive();
};

export const sendUpdateSalaryRangeSettingsJob = async (ctx: AppContext, data: UpdateSalaryRangeSettingsJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.UPDATE_SALARY_RANGE_SETTINGS,
    data,
    options: { singletonKey: data.input.salaryGridId.toString() },
  });
};
