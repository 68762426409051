import { addHours } from "date-fns";
import { BusinessLogicError } from "~/lib/api";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { logInfo } from "~/lib/logger";
import { getFiguresCompanyId } from "~/lib/utils";
import { type RequestTemporarySuperAdminRightsInput } from "~/pages/api/user/request-temporary-super-admin-rights";
export const TEMPORARY_SUPER_ADMIN_RIGHTS_DURATION_IN_HOURS = 8;

export const requestTemporarySuperAdminRights = async (
  ctx: AppContext,
  input: RequestTemporarySuperAdminRightsInput
) => {
  const user = getRequiredUser(ctx);

  if (user.isSuperAdmin) {
    throw new BusinessLogicError("You already possess super admin rights");
  }

  if (user.companyId !== getFiguresCompanyId()) {
    throw new BusinessLogicError("You are not allowed to request super admin rights");
  }

  const expirationDate = addHours(new Date(), TEMPORARY_SUPER_ADMIN_RIGHTS_DURATION_IN_HOURS);

  await ctx.prisma.user.update({
    where: { id: user.id },
    data: {
      isSuperAdmin: true,
      superAdminRightsLogs: {
        create: {
          reason: input.reason,
          expiresAt: expirationDate,
        },
      },
    },
  });

  logInfo(ctx, `[request-temporary-super-admin-rights] Authorised super admin rights`, {
    userId: user.id,
    expiresAt: expirationDate,
  });
};
