import { ExternalEmployeeStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type UnskipExternalEmployeesInput } from "~/pages/api/external-employee/unskip-external-employees";
import { transitionExternalEmployeeStatus } from "~/services/external-employee/status-helpers";
export const unskipExternalEmployees = async (ctx: AppContext, input: UnskipExternalEmployeesInput) => {
  const user = getRequiredUser(ctx);

  const externalEmployees = await ctx.prisma.externalEmployee.findMany({
    where: { id: { in: input.externalEmployeeIds }, companyId: user.companyId, status: ExternalEmployeeStatus.SKIPPED },
  });

  if (externalEmployees.length !== input.externalEmployeeIds.length) {
    throw new Error("Not all external employees from input exist, are skipped or are part of the user's company.");
  }

  await ctx.prisma.externalEmployee.updateMany({
    where: { id: { in: externalEmployees.map(({ id }) => id) } },
    data: { status: ExternalEmployeeStatus.UNMAPPED, mappingSkipReason: null },
  });

  await mapSeries(externalEmployees, async (externalEmployee) => {
    await transitionExternalEmployeeStatus(ctx, externalEmployee.id);
  });

  return externalEmployees.length;
};
