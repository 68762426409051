import { type IncomingMessage, type ServerResponse } from "http";
import { type AppContext } from "~/lib/context";

export type RateLimitOptions = {
  max: number;
  duration: number;
};

export class RateLimitError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, RateLimitError.prototype);
  }
}

export const rateLimit = async (
  ctx: AppContext,
  req: IncomingMessage,
  res: ServerResponse,
  options?: RateLimitOptions
): Promise<void> => {
  const { rateLimiter, user, redis } = ctx;

  if (!redis.isAvailable()) {
    return;
  }

  const multiplier = user?.isSuperAdmin ? 10 : 1;

  const ip = req.ip;
  const id = user ? `${user.id}` : ip;
  const limit = await rateLimiter.get({
    id,
    max: (options?.max ?? 200) * multiplier,
    duration: (options?.duration ?? 60) * 1000,
  });

  if (!res.finished && !res.headersSent) {
    res.setHeader("X-Rate-Limit-Limit", limit.total);
    res.setHeader("X-Rate-Limit-Remaining", Math.max(0, limit.remaining - 1));
    res.setHeader("X-Rate-Limit-Reset", limit.reset);
  }

  if (!limit.remaining) {
    throw new RateLimitError(`Rate-limit hit`);
  }
};
