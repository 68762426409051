import classNames from "classnames";
import React, { type HTMLAttributes } from "react";
import { Icon } from "~/components/ui/core/Icon";
import { type IconName } from "~/components/ui/icons";

type Props = React.PropsWithChildren<HTMLAttributes<HTMLDivElement> & { icon?: IconName; size?: HeadingSize }>;

const Sizes = {
  "xs": {
    text: "text-xs",
  },
  "sm": {
    text: "text-sm",
  },
  "md": {
    text: "text-base",
  },
  "lg": {
    text: "text-lg",
  },
  "xl": {
    text: "text-xl",
  },
  "2xl": {
    text: "text-2xl",
  },
  "3xl": {
    text: "text-3xl",
  },
  "4xl": {
    text: "text-4xl",
  },
  "5xl": {
    text: "text-5xl",
  },
};

type HeadingSize = keyof typeof Sizes;

export const LegacyHeading: React.FC<Props> = (props) => {
  const { className, icon, size = "xl", children, ...rest } = props;
  const isStringChild = typeof children === "string";

  return (
    <h1
      className={classNames(
        className,
        Sizes[size].text,
        "flex items-center space-x-2 font-semibold leading-none tracking-wide text-gray-800"
      )}
      {...rest}
    >
      {icon && <Icon className="h-4 w-4 text-gray-500" name={icon} />}
      {isStringChild && <span>{children}</span>}
      {!isStringChild && <>{children}</>}
    </h1>
  );
};
