import { type Prisma } from "@prisma/client";
import { type PermissionsSchema } from "~/services/permissions/permissions-schema";

export const generatePublicPermissionsSchema = () => {
  const publicPermissions = { read: true, update: true, delete: true };

  return {
    QueueJob: publicPermissions,
    MarketPositioning: publicPermissions,
    EmailNotifications: publicPermissions,
    CompanySamlConfiguration: publicPermissions,
    CompanyTag: publicPermissions,
    CompanyStats: publicPermissions,
    MarketFilters: publicPermissions,
    Image: publicPermissions,
    Contact: publicPermissions,
    MarketDataSearch: publicPermissions,
    QueueJobCache: publicPermissions,
    IntegrationLock: publicPermissions,
  } satisfies Partial<PermissionsSchema>;
};

export const BLACKLISTED_MODELS_NOT_TO_RESTRICT = [
  // EMPLOYEE
  "Employee",

  //DATA SET EMPLOYEE
  "DatasetEmployee",

  // MARKET DATA
  "Country",
  "EmployeeLocation",
  "JobFamily",
  "Job",
  "JobAliasEmbedding",

  // ACCOUNT
  "Company",
  "User",
  "UserPermissions",
  "UserSession",
  "UserAuthenticationToken",
  "UserFlags",
] as readonly Prisma.ModelName[];
