import { type CompanyEmploymentCost, type Country } from "@prisma/client";
import { chain, isEmpty } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { CacheKeys } from "~/lib/external/redis/cache-keys";

export const getEmploymentCostsByCountryId = (
  countries: Array<Pick<Country, "id" | "employmentCostRate">>,
  employmentCosts: Array<Pick<CompanyEmploymentCost, "costRate" | "countryId">>
) => {
  return chain(countries)
    .keyBy("id")
    .mapValues((country) => {
      const employmentCost = employmentCosts.find(({ countryId }) => countryId === country.id);

      return employmentCost?.costRate ?? country.employmentCostRate ?? 0;
    })
    .value();
};

export type BackofficeCountry = AsyncReturnType<typeof getBackofficeCountries>[number];

export const getBackofficeCountries = async (ctx: AppContext) => {
  return ctx.prisma.country.findMany({
    include: {
      locations: {
        select: {
          id: true,
          name: true,
          isRemote: true,
          isCountryCapital: true,
        },
      },
    },
  });
};

export const fetchAllCountries = async (ctx: AppContext) => {
  const { remember } = ctx;

  return remember(CacheKeys.Countries, async () => {
    return ctx.prisma.country.findMany({
      orderBy: { name: "asc" },
    });
  });
};

export const fetchCountriesByRestrictions = async (ctx: AppContext) => {
  const countries = await fetchAllCountries(ctx);

  if (isEmpty(ctx.user?.company.companyBenchmarkRestriction?.allowedLocations)) {
    return countries;
  }

  const companyAllowedLocations = chain(ctx.user?.company.companyBenchmarkRestriction?.allowedLocations)
    .map((location) => location.countryId)
    .uniq()
    .value();

  return countries.filter((country) => companyAllowedLocations.includes(country.id));
};
