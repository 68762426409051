import { FiberManualRecord } from "@mui/icons-material";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { type Currency, EmployeeMarketPositioning } from "@prisma/client";
import React from "react";
import { Trans } from "react-i18next";
import { match } from "ts-pattern";
import { Icon } from "~/components/ui/core/Icon";
import { useMoney } from "~/hooks/useMoney";
import { useI18n } from "~/lib/i18n/use-i18n";
import { type getMarketPositioning } from "~/services/market-positioning";

type Props = React.PropsWithChildren<{
  positioning: ReturnType<typeof getMarketPositioning>;
}>;

export const MarketPositioningChip: React.FC<Props> = ({ positioning }) => {
  return (
    <Chip
      className="font-medium"
      icon={<FiberManualRecord className="!ml-2" />}
      color={getMarketPositioningColor(positioning.type)}
      size="small"
      label={positioning.title}
    />
  );
};

export const MarketPositioningTooltip: React.FC<
  React.PropsWithChildren<{
    currency: Pick<Currency, "code">;
    amount: number | null;
    targetAmount: number | null;
    comparedLocation?: string;
    numberEmployeesBenchmarked?: number;
    numberCompaniesBenchmarked?: number;
    hasExtendedLocationScope?: boolean;
  }>
> = ({
  currency,
  amount,
  targetAmount,
  comparedLocation,
  numberEmployeesBenchmarked,
  numberCompaniesBenchmarked,
  hasExtendedLocationScope = false,
  children,
}) => {
  const { t } = useI18n();
  const money = useMoney(currency);

  return (
    <Tooltip
      title={
        <Stack className="p-2">
          <Typography variant="subtitle2">{t("components.ui.market-positioning-chip.benchmark-details")}</Typography>
          <Typography variant="caption">
            {t("components.ui.market-positioning-chip.benchmark-data-consists")}
          </Typography>

          {amount && targetAmount && (
            <div className="flex">
              <Stack className="border-grey-400 !mt-4 w-40 border-l-4 pl-2">
                <Typography>{t("components.ui.market-positioning-chip.salary")}</Typography>
                <Typography variant="h2">{money.format(amount, { roundTo: 100 })}</Typography>
              </Stack>

              <Stack className="border-grey-400 !mt-4 border-l-4 pl-2">
                <Typography>{t("components.ui.market-positioning-chip.target")}</Typography>
                <Typography variant="h2">{money.format(targetAmount, { roundTo: 100 })}</Typography>
              </Stack>
            </div>
          )}

          {Number(numberEmployeesBenchmarked) > 0 && Number(numberCompaniesBenchmarked) > 0 && (
            <div className="flex">
              <Stack className="border-grey-400 !mt-4 w-40 border-l-4 pl-2">
                <Typography>{t("components.ui.market-positioning-chip.employees")}</Typography>
                <Typography variant="h2">{numberEmployeesBenchmarked}</Typography>
              </Stack>

              <Stack className="border-grey-400 !mt-4 border-l-4 pl-2">
                <Typography>{t("components.ui.market-positioning-chip.companies")}</Typography>
                <Typography variant="h2">{numberCompaniesBenchmarked}</Typography>
              </Stack>
            </div>
          )}

          {comparedLocation && (
            <div className="flex">
              <Stack className="border-grey-400 !mt-4 border-l-4 pl-2">
                <Typography>{t("components.ui.market-positioning-chip.geographic-strategy")}</Typography>
                <Typography variant="h2">
                  <div className="flex items-center">
                    {hasExtendedLocationScope && <Icon name="warning" className="mr-2 text-orange-400" />}
                    {comparedLocation}
                  </div>
                </Typography>
              </Stack>
            </div>
          )}
          {hasExtendedLocationScope && <WarningMessage comparedLocation={comparedLocation} />}
        </Stack>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};

const getMarketPositioningColor = (type: EmployeeMarketPositioning) => {
  return match(type)
    .with(EmployeeMarketPositioning.WAY_BELOW, () => "secondary" as const)
    .with(EmployeeMarketPositioning.BELOW, () => "secondary-alt" as const)
    .with(EmployeeMarketPositioning.ON_TARGET, () => "info" as const)
    .with(EmployeeMarketPositioning.ABOVE, () => "primary-alt" as const)
    .with(EmployeeMarketPositioning.WAY_ABOVE, () => "primary" as const)
    .exhaustive();
};

const WarningMessage: React.FC<{ comparedLocation?: string }> = ({ comparedLocation }) => {
  return (
    <Stack className="mt-2 max-w-sm rounded-lg border-2 border-orange-200 bg-orange-100 p-2">
      <Typography>
        <Trans i18nKey="components.ui.market-positioning-chip.our-sample-size-data" values={{ comparedLocation }}>
          <b />
        </Trans>
      </Typography>
    </Stack>
  );
};
