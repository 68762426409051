import { type ParsedUrlQuery } from "querystring";
import { array, boolean, number, object, string } from "yup";
import { parseArray, parseBooleanArray, parseDateArray, parseNumericArray } from "~/lib/query-params";
import { type YupOutputType, getKeys } from "~/lib/utils";
import { type FetchCompensationReviewEmployeeFilterOptionsResult } from "~/services/employee-filter/fetch-compensation-review-employee-filter-options";

export const EmployeeFilterOptionsType = {
  ExternalEmployee: "ExternalEmployee",
  CompensationReviewEmployee: "CompensationReviewEmployee",
} as const;

export const baseEmptyFilters = {
  jobs: [],
  locations: [],
  levels: [],
  performanceReviewRatings: [],
  managers: [],
  businessUnits: [],
  genders: [],
  hireDate: [],
  isPromoted: [],
  reviewers: [],
};

// Compensation review employee filter options already contain external employee filter
export type FetchEmployeeFilterOptionsResult = FetchCompensationReviewEmployeeFilterOptionsResult;

export const FilterOptionsSchema = object({
  jobs: array(number().required()),
  locations: array(number().required()),
  levels: array(number().required()),
  performanceReviewRatings: array(number().required()),
  managers: array(number().required()),
  businessUnits: array(string().required()),
  genders: array(string().required()),
  hireDate: array(string()),
  isPromoted: array(boolean()),
  reviewers: array(number().required()),
}).nullable();

export type FilterOptionsInput = YupOutputType<typeof FilterOptionsSchema> & {
  [key: `additional-field-${number}`]: string[];
  [key: `date-additional-field-${number}`]: [Date, Date];
  [key: `number-additional-field-${number}`]: [number, number];
  [key: `percentage-additional-field-${number}`]: [number, number];
};

export type SelectedFilterOptions = {
  // External employee filters
  locations: number[];
  jobs: number[];
  levels: number[];
  managers: number[];
  businessUnits: string[];
  genders: string[];
  hireDate: [string, string];
  [key: `additional-field-${number}`]: string[];
  [key: `date-additional-field-${number}`]: [Date, Date];
  [key: `number-additional-field-${number}`]: [number, number];
  [key: `percentage-additional-field-${number}`]: [number, number];

  // Compensation review employee filters
  isPromoted: boolean[];
  reviewers: number[];

  // Shared filters
  performanceReviewRatings: number[];
};

export const isStringAdditionalKey = (key: string): key is `additional-field-${number}` =>
  key.startsWith("additional-field-");

export const isNumberRangeAdditionalKey = (key: string): key is `number-additional-field-${number}` =>
  key.startsWith("number-additional-field-");

export const isPercentageRangeAdditionalKey = (key: string): key is `percentage-additional-field-${number}` =>
  key.startsWith("percentage-additional-field-");

export const isDateRangeAdditionalKey = (key: string): key is `date-additional-field-${number}` =>
  key.startsWith("date-additional-field-");

export const parseSelectedFilterOptions = (query: ParsedUrlQuery): SelectedFilterOptions => {
  const additionalFields = getKeys(query).filter(isStringAdditionalKey);
  const numberRangeAdditionalFields = getKeys(query).filter(isNumberRangeAdditionalKey);
  const percentageRangeAdditionalFields = getKeys(query).filter(isPercentageRangeAdditionalKey);
  const dateRangeAdditionalFields = getKeys(query).filter(isDateRangeAdditionalKey);

  return {
    // External employee filters only
    locations: parseNumericArray(query, "locations"),
    jobs: parseNumericArray(query, "jobs"),
    levels: parseNumericArray(query, "levels"),
    managers: parseNumericArray(query, "managers"),
    genders: parseArray(query, "genders"),
    businessUnits: parseArray(query, "business-units"),
    hireDate: parseArray(query, "hire-date") as [string, string],
    ...additionalFields.reduce((acc, key) => ({ ...acc, [key]: parseArray(query, key) }), {}),
    ...dateRangeAdditionalFields.reduce((acc, key) => ({ ...acc, [key]: parseDateArray(query, key) }), {}),
    ...numberRangeAdditionalFields.reduce((acc, key) => ({ ...acc, [key]: parseNumericArray(query, key, true) }), {}),
    ...percentageRangeAdditionalFields.reduce(
      (acc, key) => ({ ...acc, [key]: parseNumericArray(query, key, true) }),
      {}
    ),

    // Compensation Review employee filters only
    isPromoted: parseBooleanArray(query, "is-promoted"),
    reviewers: parseNumericArray(query, "reviewers"),

    // Shared filters
    performanceReviewRatings: parseNumericArray(query, "performance-ratings"),
  };
};
