import { type AppContext } from "~/lib/context";
import { type PerformanceReviewData } from "~/pages/api/sync-performance-reviews";
import { type PerformanceReviewScopeInput } from "~/services/performance-review/find-or-create-performance-review-cycle";

export const createOrUpdatePerformanceRating = async (
  ctx: AppContext,
  input: {
    performanceReviewCycleId: number;
    rating: PerformanceReviewData;
    companyId: number;
    performanceReviewScope: PerformanceReviewScopeInput;
  }
) => {
  const { performanceReviewCycleId, rating, companyId, performanceReviewScope } = input;

  const performanceReviewRating = await ctx.prisma.performanceReviewRating.upsert({
    where: {
      performanceReviewCycleId_name: {
        performanceReviewCycleId: performanceReviewCycleId,
        name: rating.name,
      },
    },
    create: {
      company: { connect: { id: companyId } },
      name: rating.name,
      position: rating.position,
      description: rating.description,
      performanceReviewCycle: { connect: { id: performanceReviewCycleId } },
    },
    update: {
      position: rating.position,
      description: rating.description,
    },
  });

  if (!performanceReviewScope?.compensationReviewCampaignId) {
    await ctx.prisma.externalEmployee.updateMany({
      where: { id: { in: rating.externalEmployeeIds } },
      data: { performanceReviewRatingId: performanceReviewRating.id },
    });
  }

  if (performanceReviewScope?.compensationReviewCampaignId) {
    await ctx.prisma.compensationReviewEmployee.updateMany({
      where: {
        campaignId: performanceReviewScope.compensationReviewCampaignId,
        externalEmployeeId: { in: rating.externalEmployeeIds },
      },
      data: { performanceRatingId: performanceReviewRating.id },
    });
  }

  return rating;
};
