import { addHours } from "date-fns";
import { BusinessLogicError } from "~/lib/api";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { logInfo } from "~/lib/logger";
import { getFiguresCompanyId } from "~/lib/utils";
import { type RequestExceptionalImpersonationInput } from "~/pages/api/user/request-exceptional-impersonation";
export const EXCEPTIONAL_IMPERSONATION_DURATION_IN_HOURS = 1;

export const requestExceptionalImpersonation = async (ctx: AppContext, input: RequestExceptionalImpersonationInput) => {
  const user = getRequiredUser(ctx);

  // Required to make sure we check the proper company ID when impersonating
  const originalUser = await ctx.prisma.user.findUniqueOrThrow({
    where: { id: user.id },
    select: { companyId: true },
  });

  if (!user.isSuperAdmin || originalUser.companyId !== getFiguresCompanyId()) {
    throw new BusinessLogicError("You are not allowed to perform this action");
  }

  const expirationDate = addHours(new Date(), EXCEPTIONAL_IMPERSONATION_DURATION_IN_HOURS);

  await ctx.prisma.exceptionalImpersonationLog.create({
    data: {
      userId: user.id,
      reason: input.reason,
      companyId: input.companyId,
      expiresAt: expirationDate,
    },
  });

  logInfo(ctx, `[request-exceptional-impersonation] Exceptional impersonation requested`, {
    userId: user.id,
    companyId: input.companyId,
    expiresAt: expirationDate,
  });
};
