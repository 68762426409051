import { CompanyImpersonationLogAction } from "@prisma/client";
import { addDays } from "date-fns";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { trackImpersonationAccessGranted } from "~/lib/external/segment/server/events";
import { notifyImpersonationAccessApproval } from "~/lib/external/slack/notifications";
import { getRequiredUser } from "~/lib/get-required-user";
import {
  type ApproveImpersonationAccessSchemaInput,
  IMPERSONATION_ACCESS_TYPE,
  type ImpersonationAccessType,
} from "~/pages/api/auth/company-impersonation/approve-impersonation-access";
import { findImpersonationAccessRequest } from "~/services/impersonation/find-impersonation-access-request";
import { IMPERSONATION_ACCESS_DURATION_IN_DAYS } from "~/services/impersonation/helper";
export const approveImpersonationAccess = async (ctx: AppContext, input: ApproveImpersonationAccessSchemaInput) => {
  const user = getRequiredUser(ctx);

  if (config.app.isProduction && user.isSuperAdmin) {
    throw new Error("Figures Administrators are not allowed to approve access on behalf of a company.");
  }

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: user.companyId },
  });

  const impersonationAccessRequested = await findImpersonationAccessRequest(ctx, { companyId: user.companyId });
  const expirationDate = getExpiresAt(input);

  const whitelist = await ctx.prisma.companyImpersonationAccess.upsert({
    where: { id: impersonationAccessRequested?.id ?? -1 },
    create: {
      expiresAt: expirationDate,
      companyId: user.companyId,
      logs: {
        create: {
          userId: user.id,
          message: "Successfully approved impersonation access",
          action: CompanyImpersonationLogAction.CREATION,
        },
      },
    },
    update: {
      expiresAt: expirationDate,
      companyId: user.companyId,
      requestedAt: null,
      logs: {
        create: {
          userId: user.id,
          message: "Successfully approved impersonation access request",
          action: CompanyImpersonationLogAction.APPROVE,
        },
      },
    },
  });

  await notifyImpersonationAccessApproval(ctx, { company, accessData: whitelist });

  await trackImpersonationAccessGranted(ctx, { expiresAt: whitelist.expiresAt });
};

const getExpiresAt = ({ accessType }: { accessType: ImpersonationAccessType }) => {
  if (accessType === IMPERSONATION_ACCESS_TYPE.PERMANENT) {
    return null;
  }

  return addDays(new Date(), IMPERSONATION_ACCESS_DURATION_IN_DAYS);
};
