import { useEffect, useState } from "react";
import { EmployeeDiff } from "~/components/employee/employee-diff";
import { LegacyHeading } from "~/components/ui/core/LegacyHeading";
import { LegacyText } from "~/components/ui/core/LegacyText";
import { Button } from "~/components/ui/core/button";
import { useGetEmployeeHistoryQuery } from "~/hooks/useQueries";
import { DateFormats } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";
import { type GetEmployeeHistoryResponse } from "~/pages/api/get-employee-history";
import { type MappedEmployeeRow } from "~/services/external-employee/external-employee-read";

type EmployeeHistoryProps = {
  employee: MappedEmployeeRow;
};

export const EmployeeHistory: React.FC<EmployeeHistoryProps> = ({ employee }) => {
  const { formatDate } = useI18n();
  const [employeeHistory, setEmployeeHistory] = useState<GetEmployeeHistoryResponse["employeeHistory"]>([]);
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState(0);
  const { data: employeeHistoryResponse } = useGetEmployeeHistoryQuery({
    employeeId: employee.id,
  });

  useEffect(() => {
    if (employeeHistoryResponse) {
      setEmployeeHistory(employeeHistoryResponse.employeeHistory);
      setCurrentEmployeeIndex(Math.max(employeeHistoryResponse.employeeHistory.length - 1, 0));
    }
  }, [employeeHistoryResponse]);

  const onClickPrevious = () => {
    if (currentEmployeeIndex > 0) {
      setCurrentEmployeeIndex(currentEmployeeIndex - 1);
    }
  };

  const onClickNext = () => {
    if (currentEmployeeIndex < employeeHistory.length - 1) {
      setCurrentEmployeeIndex(currentEmployeeIndex + 1);
    }
  };

  if (!employee) {
    return null;
  }

  if (employeeHistory.length === 0) {
    return null;
  }

  return (
    <div className="mt-6 flex flex-col">
      <div className="flex flex-col">
        <LegacyHeading className="-mx-6 h-12 bg-gray-100 px-3" icon="clock" size="md">
          Employee history
        </LegacyHeading>
        <div className="flex justify-between py-3">
          <LegacyHeading size="sm">
            {formatDate(employeeHistory[currentEmployeeIndex]?.createdAt as Date, DateFormats.FULL_DATE)} Version
          </LegacyHeading>
          <div className="flex items-center justify-between">
            <Button
              variant="outlined"
              disabled={currentEmployeeIndex === 0}
              onClick={onClickPrevious}
              className="min-w-0"
              size="small"
            >
              &larr;
            </Button>
            <LegacyText className="px-2">
              {currentEmployeeIndex + 1} / {employeeHistory.length}
            </LegacyText>
            <Button
              variant="outlined"
              disabled={currentEmployeeIndex === employeeHistory.length - 1}
              onClick={onClickNext}
              className="min-w-0"
              size="small"
            >
              &rarr;
            </Button>
          </div>
        </div>
        <div className="mt-1 flex-1">
          <LegacyText className="pb-3" icon="user">
            {employeeHistory[currentEmployeeIndex]?.user.email}
            {employeeHistory[currentEmployeeIndex]?.updateReason &&
              ` : ${employeeHistory[currentEmployeeIndex]?.updateReason}`}
          </LegacyText>
          <EmployeeDiff
            employee={employee}
            liveVersion={employeeHistory[currentEmployeeIndex] as GetEmployeeHistoryResponse["employeeHistory"][number]}
          />
        </div>
      </div>
    </div>
  );
};
