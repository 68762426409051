import { Delete, PriorityHigh, Save, SkipNext, Undo } from "@mui/icons-material";
import {
  Alert,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  type Currency,
  DataValidationFlagOrigin,
  type DataValidationFlagType,
  type EmployeeLevel,
  type EmployeeMappingSkipReason,
  type EmployeeUpdateStrategy,
  ExternalEmployeeStatus,
  type Gender,
} from "@prisma/client";
import { noop } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import CountryFlag from "react-country-flag";
import { Trans } from "react-i18next";
import { useBoolean } from "react-use";
import { match } from "ts-pattern";
import { OverrideEmployeeFlagModal } from "~/components/employee/employee-data-validation-flag/override-employee-flag-modal";
import { ReportEmployeeDataModal } from "~/components/employee/employee-data-validation-flag/report-employee-data-modal";
import { EmployeeFormMarketPositioningChip } from "~/components/employee/employee-form-compensation-badge";
import { FormHint, MappingPrompt } from "~/components/employee/employee-form-utils";
import { EmployeeHistory } from "~/components/employee/employee-history";
import { EmployeePicture } from "~/components/employee/employee-picture";
import { ExternalEmployeeDiff } from "~/components/employee/external-employee-diff";
import { LegacyEmployeeReconciliation } from "~/components/employee/legacy-employee-reconciliation";
import { value } from "~/components/helpers";
import { JobSelector } from "~/components/ui/JobSelector";
import { HtmlTooltip } from "~/components/ui/core/HtmlTooltip";
import { Icon } from "~/components/ui/core/Icon";
import { LegacyFormControl } from "~/components/ui/core/LegacyFormControl";
import { LegacyHeading } from "~/components/ui/core/LegacyHeading";
import { LegacyText } from "~/components/ui/core/LegacyText";
import { isCustomChoice } from "~/components/ui/core/SelectAChoiceModal";
import { SelectableTextField } from "~/components/ui/core/SelectableTextField";
import { Button } from "~/components/ui/core/button";
import { DatePicker } from "~/components/ui/core/date-picker";
import { CurrencySelector } from "~/components/ui/currency-selector";
import { EmployeeRemovalReasonSelectorModal } from "~/components/ui/employee-removal-reason-selector-modal";
import { EmployeeUpdateReasonSelectorModal } from "~/components/ui/employee-update-reason-selector-modal";
import { ExpandedGenderSelector } from "~/components/ui/expanded-gender-selector";
import { LevelSelector } from "~/components/ui/level-selector";
import { type LocationForSelector, LocationSelector } from "~/components/ui/location-selector";
import { SkipReasonSelectorModal } from "~/components/ui/skip-reason-selector-modal";
import { useApi } from "~/hooks/useApi";
import { useForm } from "~/hooks/useForm";
import { useMoney } from "~/hooks/useMoney";
import { usePermissions } from "~/hooks/usePermissions";
import { useListJobsQuery } from "~/hooks/useQueries";
import { useSession } from "~/hooks/useSession";
import { DateFormats } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";
import { convertCurrency } from "~/lib/money";
import { isEmployeeImportedFromIntegration } from "~/lib/utils";
import { type ClassifyJobResponse } from "~/pages/api/classify-job";
import { useCreateEmployeeDataValidationFlagMutation } from "~/pages/api/employee-data-validation-flag/create-employee-data-validation-flag";
import { useGetActiveFlagByEmployeeId } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { useUpdateEmployeeDataValidationFlagMutation } from "~/pages/api/employee-data-validation-flag/update-employee-data-validation-flag";
import { PartialExternalEmployeeSchema } from "~/pages/api/map-external-employee";
import { computeEmployeeCompensation, formatEmployeeName } from "~/services/employee";
import { formatDataValidationFlagType } from "~/services/employee-data-validation-flag/data-flagging";
import { type EmployeeDeleteReason } from "~/services/employee/employee-delete";
import { formatLevel, getLevelDefinitions } from "~/services/employee/employee-level";
import { CreateEmployeeSchema, EmployeeSchema } from "~/services/employee/employee-schemas";
import { computeExternalEmployeeTotalRemuneration, getEmployeeSourceDetails } from "~/services/external-employee";
import {
  type ExistingEmployeeSuggestion,
  type ExternalEmployeeRow,
  type MappedEmployeeRow,
} from "~/services/external-employee/external-employee-read";
import { type FetchPerformanceReviewRatings } from "~/services/performance-review/find-performance-review-ratings";

type Props = {
  employee: MappedEmployeeRow | null;
  externalEmployee?: ExternalEmployeeRow;
  performanceReviewRatings: FetchPerformanceReviewRatings;
  existingEmployeeSuggestions?: ExistingEmployeeSuggestion[];
  defaultCurrency: Currency;
  submitButtonText: string;
  useAdvancedLevels?: boolean;
  isSkippedEmployeeButtonDisabled?: boolean;

  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: (reason: EmployeeDeleteReason) => Promise<void>;
  onReconciliate?: (values: FormValues, reconciliatedEmployeeId: number) => Promise<void>;
  onSkip?: (reason: EmployeeMappingSkipReason) => Promise<void>;
  onUnskip?: () => Promise<void>;
};

export type FormValues = {
  employeeNumber: string | null;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  location: LocationForSelector | null;
  job: { id: number } | null;
  level: EmployeeLevel | null;
  externalJobTitle: string | null;
  externalLevel: string | null;
  externalLocation: string | null;
  currency: Currency;
  baseSalary: number | null;
  fixedBonus: number | null;
  fixedBonusPercentage: number | null;
  onTargetBonus: number | null;
  onTargetBonusPercentage: number | null;
  isFounder: boolean;
  pictureId: number | null;
  hireDate: string | null;
  birthDate: string | null;
  reason: string | null;
  updateStrategy: EmployeeUpdateStrategy | null;

  alwaysMapLocation: boolean;
  alwaysMapJob: boolean;
  alwaysMapLevel: boolean;
  performanceReviewRatingId: number | null;
};

export const ImportedJobOrLevelField = (props: React.ComponentProps<typeof TextField>) => {
  const { t } = useI18n();
  if (props.disabled) {
    return (
      <HtmlTooltip
        title={
          <div className="rounded bg-red-200 px-4 py-2">
            <Typography variant="caption">
              {t("components.ui.employee-form.this-data-is-populated-by-your-hris")}
            </Typography>
          </div>
        }
        placement="bottom"
      >
        <TextField {...props} />
      </HtmlTooltip>
    );
  }

  return <TextField {...props} />;
};

export const EmployeeForm: React.FC<Props> = ({
  employee,
  externalEmployee,
  performanceReviewRatings,
  existingEmployeeSuggestions,
  defaultCurrency,
  submitButtonText,
  useAdvancedLevels,
  onSubmit,
  onDelete,
  onReconciliate,
  onSkip,
  onUnskip,
  isSkippedEmployeeButtonDisabled,
}) => {
  const { t, formatDate } = useI18n();
  const { apiFetch } = useApi();
  const { user } = useSession();
  const [reconciliatedEmployeeId, setReconciliatedEmployeeId] = useState<number | null>(null);
  const [showEmployeeSkipReasonModal, setShowEmployeeSkipReasonModal] = useState(false);
  const [showEmployeeUpdateReasonModal, setShowEmployeeUpdateReasonModal] = useState(false);
  const [showEmployeeRemovalReasonModal, setShowEmployeeRemovalReasonModal] = useState(false);
  const [showReportEmployeeDataModal, setShowReportEmployeeDataModal] = useState(false);
  const [showOverrideEmployeeFlagModal, setShowOverrideEmployeeFlagModal] = useBoolean(false);
  const { permissions } = usePermissions();
  const { data: jobsData } = useListJobsQuery();
  const selectedJob = jobsData?.jobs.find((job) => job.id === employee?.jobId);
  const availableLevel = selectedJob?.availableLevels ?? [];

  const { data: activeDataValidationFlag } = useGetActiveFlagByEmployeeId({
    options: {
      enabled: !!employee?.id,
    },
    input: {
      employeeId: employee?.id,
    },
  });

  const updateEmployeeDataValidationFlag = useUpdateEmployeeDataValidationFlagMutation({
    successMessage: t("components.ui.employee-form.employee-flags-successfully-updated"),
  });
  const createEmployeeDataValidationFlag = useCreateEmployeeDataValidationFlagMutation({
    successMessage: t("components.ui.employee-form.employee-flag-successfully-created"),
  });

  const picture = employee?.picture ?? externalEmployee?.picture ?? null;

  const requireUpdateReason = employee && !externalEmployee;

  const initialValues: FormValues = {
    employeeNumber: employee?.employeeNumber ?? externalEmployee?.employeeNumber ?? "",
    firstName: employee?.firstName ?? externalEmployee?.firstName ?? null,
    lastName: employee?.lastName ?? externalEmployee?.lastName ?? null,
    gender: employee?.gender ?? externalEmployee?.gender ?? null,
    location: employee?.mappingLocation ?? externalEmployee?.location?.mappedLocation ?? null,
    job: employee?.jobId
      ? { id: employee.jobId }
      : externalEmployee?.job?.mappedJobId
      ? { id: externalEmployee.job.mappedJobId }
      : null,
    level: employee?.level ?? externalEmployee?.level?.mappedLevel ?? null,
    externalJobTitle: employee?.externalJobTitle ?? externalEmployee?.job?.name ?? null,
    externalLevel: employee?.externalLevel ?? externalEmployee?.level?.name ?? null,
    currency: employee?.currency ?? externalEmployee?.currency ?? defaultCurrency,
    onTargetBonusPercentage: employee?.onTargetBonusPercentage
      ? parseFloat((employee?.onTargetBonusPercentage * 100).toFixed(2))
      : null,
    fixedBonusPercentage: employee?.fixedBonusPercentage
      ? parseFloat((employee?.fixedBonusPercentage * 100).toFixed(2))
      : null,
    baseSalary: value(() => {
      if (employee?.baseSalary) {
        return Math.round(employee.baseSalary / 100);
      }

      if (externalEmployee?.remunerationItems.length) {
        return Math.round(computeExternalEmployeeTotalRemuneration(externalEmployee, "FIXED_SALARY") / 100) || null;
      }

      return null;
    }),
    fixedBonus: value(() => {
      if (employee?.fixedBonus) {
        return Math.round(employee.fixedBonus / 100);
      }

      if (externalEmployee?.remunerationItems.length) {
        return Math.round(computeExternalEmployeeTotalRemuneration(externalEmployee, "FIXED_BONUS") / 100) || null;
      }

      return null;
    }),
    onTargetBonus: value(() => {
      if (employee?.onTargetBonus) {
        return Math.round(employee.onTargetBonus / 100);
      }

      if (externalEmployee?.remunerationItems.length) {
        return Math.round(computeExternalEmployeeTotalRemuneration(externalEmployee, "VARIABLE_BONUS") / 100) || null;
      }

      return null;
    }),
    isFounder: employee?.isFounder ?? externalEmployee?.isFounder ?? false,
    pictureId: employee?.picture?.id ?? externalEmployee?.picture?.id ?? null,
    hireDate: value(() => {
      if (employee?.hireDate) {
        return formatDate(employee.hireDate, DateFormats.DATE_PICKER);
      }

      if (externalEmployee?.hireDate) {
        return formatDate(externalEmployee.hireDate, DateFormats.DATE_PICKER);
      }

      return null;
    }),
    birthDate: value(() => {
      if (employee?.birthDate) {
        return formatDate(employee.birthDate, DateFormats.DATE_PICKER);
      }

      if (externalEmployee?.birthDate) {
        return formatDate(externalEmployee.birthDate, DateFormats.DATE_PICKER);
      }

      return null;
    }),
    reason: "",
    updateStrategy: null,

    alwaysMapLocation: false,
    alwaysMapJob: false,
    alwaysMapLevel: false,
    performanceReviewRatingId:
      employee?.externalEmployee?.performanceReviewRating?.id ?? externalEmployee?.performanceReviewRatingId ?? -1,

    externalLocation: employee?.location?.name ?? externalEmployee?.location?.name ?? null,
  };

  const onFormSubmit = useCallback(
    async (values: FormValues) => {
      if (onReconciliate && reconciliatedEmployeeId) {
        await onReconciliate(values, reconciliatedEmployeeId);
        return;
      }

      await onSubmit(values);
    },
    [reconciliatedEmployeeId, onReconciliate, onSubmit]
  );

  const validationSchema = value(() => {
    if (!externalEmployee) {
      if (!!employee) {
        return CreateEmployeeSchema;
      }
      return EmployeeSchema;
    }

    return match(externalEmployee.status)
      .with(ExternalEmployeeStatus.UNMAPPED, () => PartialExternalEmployeeSchema)
      .with(ExternalEmployeeStatus.SKIPPED, () => PartialExternalEmployeeSchema)
      .with(ExternalEmployeeStatus.PARTIAL, () => PartialExternalEmployeeSchema)
      .with(ExternalEmployeeStatus.MAPPED, () => EmployeeSchema)
      .with(ExternalEmployeeStatus.NEEDS_REMAPPING, () => EmployeeSchema)
      .exhaustive();
  });

  const form = useForm<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (onTargetBonusMeasurement === "currency") {
        values.onTargetBonusPercentage = null;
      }

      if (fixedBonusMeasurement === "currency") {
        values.fixedBonusPercentage = null;
      }

      const hasReasonAndStrategy = !!values.reason && !!values.updateStrategy;

      if (requireUpdateReason && !hasReasonAndStrategy) {
        setShowEmployeeUpdateReasonModal(true);
        return;
      }

      const isValidMapped = EmployeeSchema.isValidSync(form.values);
      const isValidPartial = PartialExternalEmployeeSchema.isValidSync(form.values);

      if (!isValidMapped && isValidPartial) {
        values.alwaysMapJob = true;
        values.alwaysMapLevel = true;
        values.alwaysMapLocation = true;
      }

      await onFormSubmit({
        ...values,
        performanceReviewRatingId: values.performanceReviewRatingId === -1 ? null : values.performanceReviewRatingId,
      });
    },
  });

  const money = useMoney(form.values.currency);
  const [showDuplicateSection, setShowDuplicateSection] = useState(true);
  const [jobsSuggestions, setJobsSuggestions] = useState<ClassifyJobResponse | null>(null);

  const incompatibleJobLevelWarning =
    form.values.job && availableLevel.length && form.values.level && !availableLevel.includes(form.values.level);

  const title = value(() => {
    if (form.values.firstName && form.values.lastName) {
      return `${form.values.firstName} ${form.values.lastName}`;
    }

    if (form.values.employeeNumber) {
      return `#${form.values.employeeNumber}`;
    }

    return t("components.ui.employee-form.new-employee");
  });

  const externalEmployeeDetails = externalEmployee?.source ? getEmployeeSourceDetails(externalEmployee.source) : null;

  const totalCash = computeEmployeeCompensation(
    {
      baseSalary: (form.values.baseSalary ?? 0) * 100,
      fixedBonus: (form.values.fixedBonus ?? 0) * 100,
      onTargetBonus: (form.values.onTargetBonus ?? 0) * 100,
      currency: form.values.currency || defaultCurrency,
    },
    {
      targetCurrency: form.values.currency || defaultCurrency,
    }
  );

  const convertedGuessedBaseSalary = convertCurrency(
    externalEmployee ? computeExternalEmployeeTotalRemuneration(externalEmployee, "FIXED_SALARY") : 0,
    defaultCurrency,
    form.values.currency
  );

  const convertedGuessedOnTargetBonus = convertCurrency(
    externalEmployee ? computeExternalEmployeeTotalRemuneration(externalEmployee, "VARIABLE_BONUS") : 0,
    defaultCurrency,
    form.values.currency
  );

  const convertedGuessedFixedBonus = convertCurrency(
    externalEmployee ? computeExternalEmployeeTotalRemuneration(externalEmployee, "FIXED_BONUS") : 0,
    defaultCurrency,
    form.values.currency
  );

  const fetchJobsSuggestions = async (signal: AbortSignal, jobName: string) => {
    return apiFetch<ClassifyJobResponse>(`/api/classify-job`, {
      method: "GET",
      query: { job: jobName },
      signal,
    });
  };

  const onReconciliateClick = async (suggestion: ExistingEmployeeSuggestion) => {
    if (!suggestion) {
      return;
    }

    if (!form.values.location) {
      await form.setFieldValue("location", suggestion.location);
    }

    if (!form.values.gender) {
      await form.setFieldValue("gender", suggestion.gender);
    }

    if (!form.values.job) {
      await form.setFieldValue("job", { id: suggestion.jobId });
    }

    if (!form.values.level) {
      await form.setFieldValue("level", suggestion.level);
    }

    if (!form.values.externalLevel) {
      await form.setFieldValue("externalLevel", suggestion.externalLevel);
    }

    if (!form.values.baseSalary) {
      await form.setFieldValue("baseSalary", Math.round(suggestion.baseSalary / 100));
    }

    if (!form.values.onTargetBonus && suggestion.onTargetBonus) {
      await form.setFieldValue("onTargetBonus", Math.round(suggestion.onTargetBonus / 100));
    }

    if (!form.values.fixedBonus && suggestion.fixedBonus) {
      await form.setFieldValue("fixedBonus", Math.round(suggestion.fixedBonus / 100));
    }

    if (!form.values.isFounder && suggestion.isFounder) {
      await form.setFieldValue("isFounder", true);
    }

    setShowDuplicateSection(false);
    setReconciliatedEmployeeId(suggestion.id);
  };

  const flagEmployeeData = async (
    employeeIds: number[],
    comment: string,
    type: DataValidationFlagType,
    companyId?: number
  ) => {
    const employeeId = employeeIds[0];

    if (!employeeId) {
      return;
    }

    await createEmployeeDataValidationFlag.mutateAsync({
      employeeId,
      comment,
      type,
      companyId,
      authorId: user?.id,
      origin: DataValidationFlagOrigin.MANUAL,
    });
  };

  const dismissFlag = async () => {
    if (!activeDataValidationFlag) {
      return;
    }

    await updateEmployeeDataValidationFlag.mutateAsync({
      id: activeDataValidationFlag.id,
      employeeId: activeDataValidationFlag.employeeId,
      comment: activeDataValidationFlag.comment,
      isLive: false,
    });
  };

  useEffect(() => {
    form.resetForm({ values: initialValues });
    setReconciliatedEmployeeId(null);
    setShowDuplicateSection(true);
  }, [employee, externalEmployee]);

  useEffect(() => {
    const alwaysMapLocation =
      !!form.values.location && form.values.location.id === externalEmployee?.location?.mappedLocation?.id;

    void form.setFieldValue("alwaysMapLocation", alwaysMapLocation);
  }, [externalEmployee, form.values.location]);

  useEffect(() => {
    const alwaysMapJob = !!form.values.job && form.values.job.id === externalEmployee?.job?.mappedJob?.id;

    void form.setFieldValue("alwaysMapJob", alwaysMapJob);
  }, [externalEmployee, form.values.job]);

  useEffect(() => {
    const alwaysMapLevel = !!form.values.level && form.values.level === externalEmployee?.level?.mappedLevel;

    void form.setFieldValue("alwaysMapLevel", alwaysMapLevel);
  }, [externalEmployee, form.values.level]);

  useEffect(() => {
    if (!form.values.externalJobTitle) {
      return;
    }

    const controller = new AbortController();

    fetchJobsSuggestions(controller.signal, form.values.externalJobTitle).then(setJobsSuggestions).catch(noop);

    return () => {
      controller.abort();
    };
  }, [form.values.externalJobTitle]);

  useEffect(() => {
    if (onTargetBonusMeasurement === "percentage" && form.values.onTargetBonusPercentage) {
      void form.setFieldValue(
        "onTargetBonus",
        form.values.baseSalary ? (form.values.baseSalary * form.values.onTargetBonusPercentage) / 100 : null
      );
    }

    if (fixedBonusMeasurement === "percentage" && form.values.fixedBonusPercentage) {
      void form.setFieldValue(
        "fixedBonus",
        form.values.baseSalary ? (form.values.baseSalary * form.values.fixedBonusPercentage) / 100 : null
      );
    }
  }, [form.values.baseSalary]);

  const disableActions = !!isSkippedEmployeeButtonDisabled || form.isSubmitting;

  const [onTargetBonusMeasurement, setOnTargetBonusMeasurement] = useState<"currency" | "percentage">(
    form.values?.onTargetBonusPercentage ? "percentage" : "currency"
  );
  const [fixedBonusMeasurement, setFixedBonusMeasurement] = useState<"currency" | "percentage">(
    form.values?.fixedBonusPercentage ? "percentage" : "currency"
  );

  const isEmployeeFromIntegration =
    isEmployeeImportedFromIntegration(externalEmployee) || isEmployeeImportedFromIntegration(employee);

  const employeeNeedsRemapping = externalEmployee?.status === ExternalEmployeeStatus.NEEDS_REMAPPING;
  const shouldShowSubmitButton =
    externalEmployee?.status !== ExternalEmployeeStatus.MAPPED &&
    externalEmployee?.status !== ExternalEmployeeStatus.SKIPPED;

  const canSubmitExternalEmployee = value(() => {
    if (disableActions || !shouldShowSubmitButton) {
      return false;
    }

    return form.isValid;
  });

  const submitButtonTooltipOptions = {
    disabled: <Alert severity="warning">{t("components.ui.employee-form.submitting-this-employee-is-disabled")}</Alert>,
    fullyMapped: (
      <Alert severity="success">{t("components.ui.employee-form.clicking-submit-will-completely-map")}</Alert>
    ),
    partiallyMapped: (
      <Alert severity="info">{t("components.ui.employee-form.clicking-submit-will-partially-map")}</Alert>
    ),
  };

  const submitHeader = value(() => {
    if (
      !externalEmployee ||
      externalEmployee.status === ExternalEmployeeStatus.SKIPPED ||
      externalEmployee.status === ExternalEmployeeStatus.MAPPED
    ) {
      return null;
    }

    if (!canSubmitExternalEmployee) {
      return submitButtonTooltipOptions.disabled;
    }

    const isValidMapped = EmployeeSchema.isValidSync(form.values);
    const isValidPartial = PartialExternalEmployeeSchema.isValidSync(form.values);

    return match(externalEmployee.status)
      .with(ExternalEmployeeStatus.UNMAPPED, () => {
        if (isValidMapped) {
          return submitButtonTooltipOptions.fullyMapped;
        }

        if (isValidPartial) {
          return submitButtonTooltipOptions.partiallyMapped;
        }

        return submitButtonTooltipOptions.disabled;
      })
      .with(ExternalEmployeeStatus.PARTIAL, () => {
        if (isValidMapped) {
          return submitButtonTooltipOptions.fullyMapped;
        }

        if (isValidPartial) {
          return null;
        }

        return submitButtonTooltipOptions.disabled;
      })
      .with(ExternalEmployeeStatus.NEEDS_REMAPPING, () => {
        if (isValidMapped) {
          return null;
        }

        return submitButtonTooltipOptions.disabled;
      })
      .exhaustive();
  });

  const employeeId = employee?.id || externalEmployee?.mappedEmployeeId;
  const shouldShowGlobalMappingHint = EmployeeSchema.isValidSync(form.values);

  return (
    <>
      <EmployeeUpdateReasonSelectorModal
        isOpen={showEmployeeUpdateReasonModal}
        onClose={() => setShowEmployeeUpdateReasonModal(false)}
        onSubmit={async (reason) => {
          const newValues = {
            ...form.values,
            reason: isCustomChoice(reason) ? reason.customValue : reason.label,
            updateStrategy: reason.strategy,
          };

          await form.setValues(newValues);
          await onFormSubmit(newValues);
        }}
      />

      <EmployeeRemovalReasonSelectorModal
        isOpen={showEmployeeRemovalReasonModal}
        onClose={() => setShowEmployeeRemovalReasonModal(false)}
        onSubmit={async ({ reason }) => {
          if (!onDelete) {
            return;
          }

          form.setSubmitting(true);

          await onDelete(reason);

          form.setSubmitting(false);
        }}
      />

      {!!employeeId && (
        <ReportEmployeeDataModal
          employeeIds={[employeeId]}
          isOpen={showReportEmployeeDataModal}
          onClose={() => {
            setShowReportEmployeeDataModal(false);
          }}
          onSubmit={flagEmployeeData}
          initialFlag={activeDataValidationFlag}
        />
      )}

      {!!activeDataValidationFlag && user?.isSuperAdmin && (
        <OverrideEmployeeFlagModal
          initialFlag={activeDataValidationFlag}
          isOpen={showOverrideEmployeeFlagModal}
          onClose={setShowOverrideEmployeeFlagModal}
        />
      )}

      <SkipReasonSelectorModal
        type="employee"
        isOpen={showEmployeeSkipReasonModal}
        onClose={() => setShowEmployeeSkipReasonModal(false)}
        onSubmit={async ({ reason }) => {
          if (!onSkip) {
            return;
          }

          form.setSubmitting(true);

          await onSkip(reason);

          form.setSubmitting(false);
        }}
      />

      <form className="relative" onSubmit={form.handleSubmit}>
        <div className="sticky top-0 z-20 flex h-16 shrink-0 items-center bg-primary-300 pl-6 pr-4 shadow">
          <div className="flex w-full items-center justify-between text-lg font-medium text-gray-900">
            <div className="flex items-center">
              <EmployeePicture
                className="-ml-3 mr-3 border-2 border-secondary-400"
                picture={picture}
                placeholderId={employee?.id ?? externalEmployee?.id ?? 1}
                size="xl"
                pictureUrl={externalEmployee?.userPermissions?.user?.profilePictureUrl}
              />

              <div className="max-w-md">
                <Typography variant="h1" noWrap>
                  {title}
                </Typography>
              </div>
            </div>

            <div className="flex space-x-4">
              {onSkip && externalEmployee?.status !== ExternalEmployeeStatus.SKIPPED && (
                <Button
                  size="small"
                  color="white"
                  variant="contained"
                  startIcon={<SkipNext />}
                  disabled={disableActions}
                  onClick={() => setShowEmployeeSkipReasonModal(true)}
                >
                  {t("common.skip")}
                </Button>
              )}

              {onUnskip && externalEmployee?.status === ExternalEmployeeStatus.SKIPPED && (
                <Button
                  size="small"
                  color="white"
                  variant="contained"
                  startIcon={<Undo />}
                  disabled={disableActions}
                  onClick={async () => await onUnskip()}
                >
                  {t("common.unskip")}
                </Button>
              )}

              {!activeDataValidationFlag &&
                (employee?.id || externalEmployee?.status === ExternalEmployeeStatus.MAPPED) && (
                  <Button
                    size="small"
                    color="error"
                    variant="outlined"
                    startIcon={<PriorityHigh />}
                    disabled={disableActions}
                    onClick={() => setShowReportEmployeeDataModal(true)}
                  >
                    {t("common.report")}
                  </Button>
                )}

              {onDelete && (
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  startIcon={<Delete />}
                  disabled={disableActions}
                  onClick={() => setShowEmployeeRemovalReasonModal(true)}
                >
                  {t("common.remove")}
                </Button>
              )}

              {shouldShowSubmitButton && (
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<Save />}
                  disabled={!canSubmitExternalEmployee}
                  type="submit"
                >
                  {submitButtonText}
                </Button>
              )}
            </div>
          </div>
        </div>

        {!!submitHeader && <div className="py-3">{submitHeader}</div>}

        <div className="flex-1 px-6">
          {activeDataValidationFlag && (
            <div className="flex flex-col">
              <div className="-mx-6 flex h-12 items-center justify-between bg-secondary-100 px-3 pr-4">
                <Chip label={t("components.ui.employee-form.this-employees-data-has-been-reported")} color="error" />

                <Stack direction="row" spacing={4}>
                  {user?.isSuperAdmin && activeDataValidationFlag.origin === DataValidationFlagOrigin.HRIS_SYNC && (
                    <Button size="small" variant="contained" color="error" onClick={setShowOverrideEmployeeFlagModal}>
                      {t("common.override")}
                    </Button>
                  )}

                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => setShowReportEmployeeDataModal(true)}
                  >
                    {t("common.edit")}
                  </Button>

                  <Button size="small" variant="contained" color="error" onClick={() => dismissFlag()}>
                    {t("common.dismiss")}
                  </Button>
                </Stack>
              </div>

              <div className="my-5 flex flex-1">
                <div className="flex-1">
                  <div className=" text-xs uppercase text-gray-500">{t("components.ui.employee-form.type")}</div>
                  <div className="mt-1 border-l-2 pl-3">
                    {formatDataValidationFlagType(activeDataValidationFlag.type)}
                  </div>
                </div>

                <div className="flex-1">
                  <div className=" text-xs uppercase text-gray-500">{t("components.ui.employee-form.reason")}</div>
                  <div className="mt-1 border-l-2 pl-3">{activeDataValidationFlag.comment}</div>
                </div>
              </div>
            </div>
          )}

          {user?.isSuperAdmin && employee && (
            <div className="flex flex-col">
              <div className="my-5 flex flex-1 flex-col">
                <div className="flex">
                  <div className="flex-1">
                    <div className="text-xs uppercase text-gray-500">{t("components.ui.employee-form.author")}</div>
                    <div className="mt-1 border-l-2 pl-3">
                      {employee.user.firstName} {employee.user.lastName} ({employee.user.email})
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className=" text-xs uppercase text-gray-500">
                      {t("components.ui.employee-form.context-reason")}
                    </div>
                    <div className="mt-1 border-l-2 pl-3">{employee.updateReason}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!!existingEmployeeSuggestions?.length && externalEmployee && showDuplicateSection && onReconciliate && (
            <LegacyEmployeeReconciliation
              existingEmployeeSuggestions={existingEmployeeSuggestions}
              externalEmployee={externalEmployee}
              isSubmitting={disableActions}
              onReconciliateClick={onReconciliateClick}
              onSkipClick={() => {
                setShowDuplicateSection(false);
              }}
            />
          )}

          {employee && externalEmployee?.status === ExternalEmployeeStatus.NEEDS_REMAPPING && (
            <div className="flex flex-col">
              <LegacyHeading className="-mx-6 h-12 bg-primary-100 px-3" icon="warning" size="md">
                {t("components.employee.employee-form.important-data-changes")}
              </LegacyHeading>

              <div className="my-5 flex flex-1 flex-col">
                <span>
                  {t("components.ui.employee-form.weve-detected-the-following-changes-from", {
                    name: externalEmployeeDetails?.name,
                  })}
                </span>
                <span>{t("components.employee.external-employee-diff.click-on-submit")}</span>

                <div className="mt-4">
                  <ExternalEmployeeDiff externalEmployee={externalEmployee} employee={employee} />
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-col">
            <LegacyHeading className="-mx-6 h-12 bg-primary-100 px-3" icon="user-check" size="md">
              {t("components.ui.employee-form.general-information")}
            </LegacyHeading>

            {!form.initialValues?.employeeNumber && (
              <div className="mt-5 grid grid-cols-4 gap-4">
                <FormControl>
                  <FormLabel>{t("components.ui.employee-form.employee")}</FormLabel>
                  <TextField
                    placeholder={t("components.ui.employee-form.employee-number-xxx")}
                    value={form.values.employeeNumber ?? ""}
                    onChange={(event) => {
                      void form.setFieldValue("employeeNumber", event.target.value);
                    }}
                  />
                </FormControl>
              </div>
            )}

            {(!form.initialValues?.firstName || !form.initialValues?.lastName) && (
              <div className="mt-5 grid grid-cols-4 gap-4">
                <TextField
                  type="text"
                  error={!!form.errors.firstName}
                  value={form.values.firstName ?? ""}
                  onChange={(event) => {
                    void form.setFieldValue("firstName", event.target.value);
                  }}
                  label={t("common.first-name")}
                />

                <LegacyFormControl
                  errors={form.errors.lastName}
                  label={t("common.last-name")}
                  touched={form.touched.lastName}
                >
                  <TextField
                    type="text"
                    value={form.values.lastName ?? ""}
                    onChange={(event) => {
                      void form.setFieldValue("lastName", event.target.value);
                    }}
                  />
                </LegacyFormControl>
              </div>
            )}

            <div className="mt-5 grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <LegacyFormControl
                  requiredHint
                  errors={[form.errors.location, form.errors.externalLocation]}
                  label={t("components.ui.employee-form.location")}
                  touched={true}
                >
                  <LocationSelector
                    formatLabel={([location]) => {
                      return (
                        <TextField
                          inputProps={{
                            readOnly: true,
                          }}
                          InputProps={{
                            startAdornment: location ? (
                              <InputAdornment position="start">
                                <CountryFlag svg countryCode={location.country.alpha2} />
                              </InputAdornment>
                            ) : undefined,
                          }}
                          className="cursor-pointer"
                          placeholder={t("components.ui.employee-form.select-a-location")}
                          value={location?.name ?? ""}
                        />
                      );
                    }}
                    value={form.values.location ? [form.values.location] : []}
                    onChange={(event) => {
                      const location = event.target.value[0];

                      void form.setFieldValue("location", location ?? null);

                      if (!form.values.externalLocation) {
                        void form.setFieldValue("externalLocation", location?.name ?? null);
                      }
                    }}
                    hideFallbackedLocation={false}
                    dense={false}
                  />
                </LegacyFormControl>
                {externalEmployee?.location && !form.values.location && (
                  <FormHint icon={externalEmployeeDetails?.icon ?? null}>{externalEmployee?.location.name}</FormHint>
                )}
                {externalEmployee?.location && form.values.location && shouldShowGlobalMappingHint && (
                  <MappingPrompt
                    value={form.values.alwaysMapLocation}
                    onChange={(alwaysMapLocation) => {
                      void form.setFieldValue("alwaysMapLocation", alwaysMapLocation);
                    }}
                  >
                    <Trans
                      i18nKey="components.ui.employee-form.always-map-location-employees-from-to"
                      values={{
                        externalLocation: externalEmployee?.location.name,
                        location: form.values.location.name,
                      }}
                    >
                      <span className="font-semibold" />
                    </Trans>
                  </MappingPrompt>
                )}
              </div>

              <div className="flex justify-between space-x-4">
                <div className="flex-1">
                  <LegacyFormControl
                    requiredHint
                    errors={form.errors.gender}
                    label={t("components.ui.employee-form.gender")}
                    touched={true}
                  >
                    <ExpandedGenderSelector
                      value={form.values.gender}
                      onChange={(event) => {
                        void form.setFieldValue("gender", event.target.value);
                      }}
                    />
                  </LegacyFormControl>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex flex-col">
            <LegacyHeading className="-mx-6 h-12 bg-primary-100 px-3" icon="hammer-wrench" size="md">
              {t("components.ui.employee-form.job-seniority-level")}
            </LegacyHeading>
            <div className="mt-5 flex flex-col space-y-4">
              <div className="flex justify-between space-x-4">
                <div className="flex flex-1 flex-col">
                  <LegacyFormControl
                    requiredHint
                    errors={form.errors.externalJobTitle}
                    label={t("components.ui.employee-form.your-job-title")}
                    tooltip={
                      <div className="flex w-64 flex-col">
                        <span>{t("components.ui.employee-form.your-internal-job-title-for-this-employee")}</span>
                      </div>
                    }
                    touched={true}
                  >
                    <ImportedJobOrLevelField
                      value={form.values.externalJobTitle ?? ""}
                      disabled={isEmployeeFromIntegration}
                      onChange={(event) => {
                        void form.setFieldValue("externalJobTitle", event.target.value);
                      }}
                    />
                  </LegacyFormControl>
                </div>

                <Icon className="mt-7 text-gray-400" name="transfer" size="xl" />

                <div className="flex flex-1 flex-col">
                  <LegacyFormControl
                    requiredHint
                    errors={form.errors.job}
                    label={t("components.ui.employee-form.figures-job")}
                    touched={form.touched.job}
                  >
                    <JobSelector
                      className="w-full"
                      jobIds={form.values.job ? [`${form.values.job.id}`] : []}
                      placeholder={t("components.ui.employee-form.select-a-job")}
                      suggestions={jobsSuggestions?.results ?? []}
                      onChange={([jobId]) => {
                        const job = jobsData?.jobs.find((job) => `${job.id}` === jobId) ?? null;

                        void form.setFieldValue("job", jobId ? { id: parseInt(jobId) } : null);

                        if (!externalEmployee?.job && !employee?.externalJobTitle) {
                          void form.setFieldValue("externalJobTitle", job?.name ?? null);
                        }
                      }}
                      {...(externalEmployee?.job && {
                        jobRequest: { externalJob: externalEmployee.job },
                      })}
                    />
                  </LegacyFormControl>
                  {externalEmployee?.job && !form.values.job && (
                    <FormHint icon={externalEmployeeDetails?.icon ?? null}>{externalEmployee?.job.name}</FormHint>
                  )}
                  {externalEmployee?.job && !!form.values.job && shouldShowGlobalMappingHint && (
                    <MappingPrompt
                      value={form.values.alwaysMapJob}
                      onChange={(alwaysMapJob) => {
                        void form.setFieldValue("alwaysMapJob", alwaysMapJob);
                      }}
                    >
                      <Trans
                        i18nKey="components.ui.employee-form.always-map-job-employees-from-to"
                        values={{
                          externalJob: externalEmployee?.job.name,
                          job: selectedJob?.name,
                        }}
                      >
                        <span className="font-semibold" />
                      </Trans>
                    </MappingPrompt>
                  )}
                </div>
              </div>

              <div className="flex justify-between space-x-4">
                <div className="flex flex-1 flex-col">
                  <LegacyFormControl
                    requiredHint
                    errors={form.errors.externalLevel}
                    label={t("components.ui.employee-form.your-level")}
                    tooltip={
                      <div className="flex w-64 flex-col">
                        <span>{t("components.ui.employee-form.your-internal-level-for-this-employee")}</span>
                      </div>
                    }
                    touched={true}
                  >
                    <ImportedJobOrLevelField
                      value={form.values.externalLevel ?? ""}
                      onChange={(event) => {
                        void form.setFieldValue("externalLevel", event.target.value);
                      }}
                      disabled={
                        isEmployeeFromIntegration && (!!form.initialValues.externalLevel || !!form.values.level)
                      }
                    />
                  </LegacyFormControl>
                </div>

                <Icon className="mt-7 text-gray-400" name="transfer" size="xl" />

                <div className="flex flex-1 flex-col">
                  <LegacyFormControl
                    requiredHint
                    errors={form.errors.level}
                    label={t("components.ui.employee-form.figures-seniority-level")}
                    touched={form.touched.level}
                  >
                    <LevelSelector
                      dense={false}
                      noFilter
                      useAdvancedLevels={useAdvancedLevels}
                      placeholder={t("components.ui.employee-form.select-a-level")}
                      value={form.values.level ? [form.values.level] : []}
                      onChange={(event) => {
                        void form.setFieldValue("level", event.target.value[0] ?? null);

                        if (!externalEmployee?.level && !employee?.externalLevel) {
                          void form.setFieldValue(
                            "externalLevel",
                            event.target.value[0] ? formatLevel(t, event.target.value[0]) : null
                          );
                        }
                      }}
                    />
                  </LegacyFormControl>
                  {externalEmployee?.level && !form.values.level && (
                    <FormHint icon={externalEmployeeDetails?.icon ?? null}>{externalEmployee?.level.name}</FormHint>
                  )}
                  {!!form.values.job && !!incompatibleJobLevelWarning && (
                    <FormHint warning icon="warning">
                      <div>
                        <Trans
                          i18nKey="components.ui.employee-form.this-is-an-unusual-combination-of-job-and-level"
                          values={{
                            levels: availableLevel
                              .map((level) => {
                                return getLevelDefinitions(t)[level].name;
                              })
                              .join(", "),
                          }}
                        >
                          <br />
                          <span className="font-semibold" />
                        </Trans>
                      </div>
                    </FormHint>
                  )}
                  {externalEmployee?.level && form.values.level && shouldShowGlobalMappingHint && (
                    <MappingPrompt
                      value={form.values.alwaysMapLevel}
                      onChange={(alwaysMapLevel) => {
                        void form.setFieldValue("alwaysMapLevel", alwaysMapLevel);
                      }}
                    >
                      <Trans
                        i18nKey="components.ui.employee-form.always-map-level-employees-from-to"
                        values={{
                          externalLevel: externalEmployee?.level.name,
                          level: formatLevel(t, form.values.level),
                        }}
                      >
                        <span className="font-semibold" />
                      </Trans>
                    </MappingPrompt>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex flex-col">
            <div className="-mx-6 flex h-12 items-center bg-primary-100 px-3">
              <LegacyHeading icon="money-check" size="md">
                {t("components.ui.employee-form.salary")}
              </LegacyHeading>

              <CurrencySelector
                formatLabel={(currency) => {
                  return (
                    <div className="ml-6 flex items-center">
                      <span className="flex items-baseline">
                        <span className="text-xs uppercase text-gray-500">{t("common.currency")}</span>
                        <span className="ml-1 text-sm font-medium text-gray-700">{currency?.name}</span>
                      </span>

                      <Icon className="text-gray-500 " name="menu-down" />
                    </div>
                  );
                }}
                value={form.values.currency}
                onChange={(event) => {
                  void form.setFieldValue("currency", event.target.value);
                }}
              />

              {totalCash > 0 && (
                <span className="ml-6 flex items-baseline">
                  <span className="text-xs uppercase text-gray-500">
                    {t("components.ui.employee-form.total-package")}
                  </span>
                  <span className="ml-1 text-sm font-medium text-gray-700">
                    {money.format(totalCash, { roundTo: 1 })}
                  </span>
                </span>
              )}

              {permissions.canAccessMarketData && (
                <div className="ml-auto mr-4">
                  <EmployeeFormMarketPositioningChip
                    currency={form.values.currency}
                    totalCash={totalCash}
                    jobId={form.values.job?.id ?? undefined}
                    level={form.values.level}
                    location={form.values.location}
                  />
                </div>
              )}
            </div>

            <div className="mt-5 grid grid-cols-4 gap-4">
              <div className="flex flex-1 flex-col">
                <LegacyFormControl
                  requiredHint
                  errors={form.errors.baseSalary}
                  label={t("components.ui.employee-form.base-salary")}
                  touched={true}
                >
                  <TextField
                    type="number"
                    value={form.values.baseSalary ?? ""}
                    onChange={(event) => {
                      void form.setFieldValue("baseSalary", event.target.value ? parseInt(event.target.value) : null);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{form.values.currency.symbol}</InputAdornment>,
                    }}
                    inputProps={{
                      min: 0,
                    }}
                  />
                </LegacyFormControl>
                {employeeNeedsRemapping &&
                  computeExternalEmployeeTotalRemuneration(externalEmployee, "FIXED_SALARY") > 0 && (
                    <FormHint
                      icon={externalEmployeeDetails?.icon ?? null}
                      showConfirmation={Math.round(convertedGuessedBaseSalary / 100) !== form.values.baseSalary}
                      onConfirm={() => {
                        void form.setFieldValue("baseSalary", Math.round(convertedGuessedBaseSalary / 100));
                      }}
                    >
                      {money.format(convertedGuessedBaseSalary, { roundTo: 1 })}
                    </FormHint>
                  )}
              </div>

              <div className="flex flex-1 flex-col">
                <LegacyFormControl
                  errors={form.errors.onTargetBonus}
                  label={t("components.ui.employee-form.on-target-bonus")}
                  tooltip={
                    onTargetBonusMeasurement === "percentage" ? (
                      <span className="w-64">{t("components.ui.employee-form.with-percent-selected-the-amount")}</span>
                    ) : undefined
                  }
                  touched={form.touched.onTargetBonus}
                >
                  <SelectableTextField
                    size="small"
                    type="number"
                    value={
                      onTargetBonusMeasurement === "currency"
                        ? form.values.onTargetBonus ?? ""
                        : form.values.onTargetBonusPercentage ?? ""
                    }
                    onChange={(event) => {
                      if (onTargetBonusMeasurement === "currency") {
                        void form.setFieldValue(
                          "onTargetBonus",
                          event.target.value ? parseInt(event.target.value) : null
                        );
                      }
                      if (onTargetBonusMeasurement === "percentage") {
                        void form.setFieldValue(
                          "onTargetBonusPercentage",
                          event.target.value ? parseFloat(event.target.value) : null
                        );
                        void form.setFieldValue(
                          "onTargetBonus",
                          event.target.value && form.values.baseSalary
                            ? (form.values.baseSalary * parseFloat(event.target.value)) / 100
                            : null
                        );
                      }
                    }}
                    selectValue={onTargetBonusMeasurement}
                    onSelectChange={(event) => {
                      setOnTargetBonusMeasurement(event.target.value as "currency" | "percentage");
                    }}
                  >
                    <MenuItem value="currency">{form.values.currency.symbol}</MenuItem>
                    <MenuItem value="percentage">%</MenuItem>
                  </SelectableTextField>
                </LegacyFormControl>

                {employeeNeedsRemapping &&
                  computeExternalEmployeeTotalRemuneration(externalEmployee, "VARIABLE_BONUS") > 0 && (
                    <FormHint
                      icon={externalEmployeeDetails?.icon ?? null}
                      showConfirmation={convertedGuessedOnTargetBonus / 100 !== form.values.onTargetBonus}
                      onConfirm={() => {
                        void form.setFieldValue("onTargetBonus", convertedGuessedOnTargetBonus / 100);
                      }}
                    >
                      {money.format(convertedGuessedOnTargetBonus, { roundTo: 1 })}
                    </FormHint>
                  )}
              </div>

              <div className="flex-1">
                <LegacyFormControl
                  errors={form.errors.fixedBonus}
                  label={t("components.ui.employee-form.fixed-bonus")}
                  tooltip={
                    <span className="w-64">
                      {t("components.ui.employee-form.any-remaining-individual-or-collective")}
                      {fixedBonusMeasurement === "percentage" &&
                        t("components.ui.employee-form.with-percent-selected-the-amount")}
                    </span>
                  }
                  touched={form.touched.fixedBonus}
                >
                  <SelectableTextField
                    size="small"
                    type="number"
                    value={
                      fixedBonusMeasurement === "currency"
                        ? form.values.fixedBonus ?? ""
                        : form.values.fixedBonusPercentage ?? ""
                    }
                    onChange={(event) => {
                      if (fixedBonusMeasurement === "currency") {
                        void form.setFieldValue("fixedBonus", event.target.value ? parseInt(event.target.value) : null);
                      }
                      if (fixedBonusMeasurement === "percentage") {
                        void form.setFieldValue(
                          "fixedBonusPercentage",
                          event.target.value ? parseFloat(event.target.value) : null
                        );
                        void form.setFieldValue(
                          "fixedBonus",
                          event.target.value && form.values.baseSalary
                            ? (form.values.baseSalary * parseFloat(event.target.value)) / 100
                            : null
                        );
                      }
                    }}
                    selectValue={fixedBonusMeasurement}
                    onSelectChange={(event) => {
                      setFixedBonusMeasurement(event.target.value as "currency" | "percentage");
                    }}
                  >
                    <MenuItem value="currency">{form.values.currency.symbol}</MenuItem>
                    <MenuItem value="percentage">%</MenuItem>
                  </SelectableTextField>
                </LegacyFormControl>
                {employeeNeedsRemapping &&
                  computeExternalEmployeeTotalRemuneration(externalEmployee, "FIXED_BONUS") > 0 && (
                    <FormHint
                      icon={externalEmployeeDetails?.icon ?? null}
                      showConfirmation={convertedGuessedFixedBonus / 100 !== form.values.fixedBonus}
                      onConfirm={() => {
                        void form.setFieldValue("fixedBonus", convertedGuessedFixedBonus / 100);
                      }}
                    >
                      {money.format(convertedGuessedFixedBonus, { roundTo: 1 })}
                    </FormHint>
                  )}
              </div>
            </div>
          </div>

          {performanceReviewRatings.length > 0 && (
            <Stack className="mt-6">
              <LegacyHeading className="-mx-6 mb-5 h-12 bg-primary-100 px-3" icon="lightning" size="md">
                {t("components.ui.employee-form.performance-rating-review")}
              </LegacyHeading>

              <InputLabel className="mb-1">{t("components.ui.employee-form.employee-performance-rating")}</InputLabel>
              <Select
                className="max-w-lg"
                size="small"
                value={form.values.performanceReviewRatingId}
                onChange={(event) =>
                  form.setValues({ ...form.values, performanceReviewRatingId: parseInt(`${event.target.value}`, 10) })
                }
              >
                {performanceReviewRatings.map((rating) => (
                  <MenuItem key={rating.id} value={rating.id}>
                    {rating.name}
                  </MenuItem>
                ))}

                <MenuItem value={-1}>{t("common.none")}</MenuItem>
              </Select>
            </Stack>
          )}

          <div className="mt-6 flex flex-col">
            <LegacyHeading className="-mx-6 h-12 bg-primary-100 px-3" icon="information" size="md">
              {t("components.ui.employee-form.misc")}
            </LegacyHeading>

            <div className="mt-5 grid grid-cols-2 gap-4">
              {!!externalEmployee?.manager && (
                <LegacyFormControl label={t("components.ui.employee-form.manager")}>
                  <LegacyText className="ml-1">{formatEmployeeName(externalEmployee.manager)}</LegacyText>
                </LegacyFormControl>
              )}

              <LegacyFormControl
                errors={form.errors.isFounder}
                label={t("components.ui.employee-form.founding-team")}
                tooltip={
                  <span className="w-64">{t("components.ui.employee-form.please-indicate-if-this-employee")}</span>
                }
                touched={form.touched.isFounder}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={form.values.isFounder}
                      onChange={(event) => {
                        void form.setFieldValue("isFounder", event.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography>
                      {t("components.ui.employee-form.is-a-founder", {
                        employee: externalEmployee?.firstName ?? t("components.ui.employee-form.this-employee"),
                      })}
                    </Typography>
                  }
                />
              </LegacyFormControl>
            </div>

            <div className="mt-5 grid grid-cols-2 gap-4">
              <LegacyFormControl
                errors={form.errors.hireDate}
                label={t("components.ui.employee-form.hire-date")}
                touched={form.touched.hireDate}
              >
                <DatePicker
                  value={form.values.hireDate}
                  onChange={(value) => {
                    void form.setFieldValue("hireDate", value);
                  }}
                />
              </LegacyFormControl>

              <LegacyFormControl
                errors={form.errors.birthDate}
                label={t("components.ui.employee-form.birth-date")}
                touched={form.touched.birthDate}
              >
                <DatePicker
                  value={form.values.birthDate}
                  onChange={(value) => {
                    void form.setFieldValue("birthDate", value);
                  }}
                />
              </LegacyFormControl>
            </div>
          </div>

          {employee && !externalEmployee && <EmployeeHistory employee={employee} />}
        </div>
      </form>
    </>
  );
};
