import { QueueJobName } from "@prisma/client";
import { map } from "bluebird";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { baseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { fillJobCache } from "~/lib/queue/queue-job-cache";
import { sendJob } from "~/lib/queue/send-job";
import { type YupOutputType } from "~/lib/utils";
import { fetchExternalEmployeesFromHRIS } from "~/services/synchronization/fetch-external-employees-from-hris";
import { updateIntegrationCounters } from "~/services/synchronization/post-sync-external-employees";
import {
  EmployeeDataSchema,
  integrationSettingsForSyncInclude,
} from "~/services/synchronization/sync-external-employees";
import { PostSyncExternalEmployeesDeletesReportDataSchema } from "~/workers/post-sync-external-employees";
import { sendSyncExternalEmployeesJob } from "~/workers/sync-external-employees";

const PreSyncExternalEmployeesJobDataSchema = baseJobDataSchema.concat(
  object({
    companyId: number().required(),
  })
);

export type PreSyncExternalEmployeesJobData = YupOutputType<typeof PreSyncExternalEmployeesJobDataSchema>;

export const preSyncExternalEmployeesWorkerService = async (ctx: AppContext, data: PreSyncExternalEmployeesJobData) => {
  const { companyId } = PreSyncExternalEmployeesJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    include: {
      integrationSettings: {
        where: { enabled: true },
        include: integrationSettingsForSyncInclude,
      },
      defaultCountry: true,
    },
  });

  logInfo(ctx, "[fetch] Fetching external employees", {
    companyId: company.id,
    activeIntegrations: company.integrationSettings.map((integration) => integration.source),
  });

  let totalEmployees = 0;

  await map(
    company.integrationSettings,
    async (integrationSettings) => {
      const { deleted, mappedEmployees } = await fetchExternalEmployeesFromHRIS(ctx, company, integrationSettings);

      logInfo(ctx, "[fetch] Fetched external employees", {
        companyId: company.id,
        source: integrationSettings.source,
        employees: mappedEmployees.length,
        deleted: deleted,
      });

      if (mappedEmployees.length === 0) {
        logInfo(ctx, "[fetch] No external employees to fetch", {
          companyId: company.id,
          source: integrationSettings.source,
        });

        await updateIntegrationCounters(ctx, {
          company,
          deletes: [{ source: integrationSettings.source, deleted }],
          updates: [],
        });

        return;
      }

      totalEmployees += mappedEmployees.length;

      await fillJobCache(ctx, {
        companyId: company.id,
        queueJobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
        data: mappedEmployees as (typeof EmployeeDataSchema)["__outputType"][],
        validationSchema: EmployeeDataSchema,
      });

      await fillJobCache(ctx, {
        companyId: company.id,
        queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
        data: { deleted, source: integrationSettings.source },
        validationSchema: PostSyncExternalEmployeesDeletesReportDataSchema,
      });
    },
    { concurrency: 5 }
  );

  logInfo(ctx, "[fetch] Done fetching external employees", {
    companyId: company.id,
  });

  if (totalEmployees > 0) {
    await sendSyncExternalEmployeesJob(ctx, { singletonKey: 0, companyId: company.id });
  }
};

export const sendPreSyncExternalEmployeesJob = async (ctx: AppContext, data: PreSyncExternalEmployeesJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES,
    data,
    options: { singletonKey: data.companyId.toString() },
  });
};
