import { Edit, OpenInNew, Workspaces } from "@mui/icons-material";
import { Box, Chip, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { type ExternalJob } from "@prisma/client";
import { Cascader, ConfigProvider } from "antd";
import classNames from "classnames";
import { compact, isString, omit, range, takeRight } from "lodash";
import React, { type ReactNode, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useDebounce } from "use-debounce";
import { CustomJobCreationModal } from "~/components/modals/custom-job-creation-modal";
import { CustomJobEditionModal } from "~/components/modals/CustomJobEditionModal";
import { JobRequestModal } from "~/components/modals/job-request-modal";
import { HighlightedText } from "~/components/ui/core/HighlightedText";
import { Link } from "~/components/ui/core/Link";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { useClassifyJobQuery } from "~/hooks/useQueries";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import { theme } from "~/lib/theme";
import { type ClassifyJobResponse } from "~/pages/api/classify-job";
import { useFetchJobFamiliesForSelectorQuery } from "~/pages/api/job/fetch-job-families-for-selector";
import { CUSTOM_JOB_PREFIX } from "~/services/custom-jobs/custom-job-prefix";

export const JOB_SELECTOR_LIMIT = 20;

// Make sure to keep in sync with the CSS selector in index.css
const BEST_MATCH_KEY = "best-match";

const LABEL_SEPARATOR = "\n";

const extractLabel = (label: string | undefined) => {
  if (!isString(label)) return label;

  const [name] = label.split(LABEL_SEPARATOR);

  return name;
};

type Props = {
  jobIds: string[];
  onChange: (jobIds: string[]) => void;
  multiple?: boolean;
  placeholder?: string;
  enableCustomJobs?: boolean;
  className?: string;
  disabled?: boolean;
  jobRequest?: {
    externalJob: Pick<ExternalJob, "id" | "name">;
  };
  suggestions?: ClassifyJobResponse["results"];
};

export const JobSelector: React.FC<Props> = ({
  jobIds,
  onChange,
  multiple = false,
  className,
  enableCustomJobs = false,
  placeholder,
  disabled = false,
  jobRequest,
  suggestions,
}) => {
  const { t } = useI18n();
  const { user } = useSession();
  const { CAN_BYPASS_JOB_SELECTOR_LIMIT } = useFeatureFlags();
  const {
    data: jobFamilies,
    isFetching,
    refetch: refetchJobs,
  } = useFetchJobFamiliesForSelectorQuery({
    input: { enableCustomJobs },
  });
  const [open, isOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [openJobRequestModal, setOpenJobRequestModal] = useState(false);
  const [openCustomJobCreationModal, setOpenCustomJobCreationModal] = useState(false);
  const [selectedCustomJobForEdition, setSelectedCustomJobForEdition] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 300);

  const { data: jobsSuggestionsData, isFetching: isClassifying } = useClassifyJobQuery({
    search: debouncedSearch,
  });
  const isLoading = isFetching || isClassifying;
  const dynamicPlaceholder = open ? t("components.ui.job-selector.open-placeholder") : placeholder;

  const flatJobs = useMemo(() => {
    if (!jobFamilies) return [];

    return jobFamilies
      .map((family) => family.jobs.map((job) => ({ ...job, familyId: `${family.id}`, family: omit(family, "jobs") })))
      .flat();
  }, [jobFamilies]);

  const values = useMemo(() => {
    const values = jobIds.map((jobId) => {
      const flatJob = flatJobs.find((flatJob) => flatJob.id === jobId);
      if (!flatJob) return;

      return [flatJob.family.id, flatJob.id];
    });

    return compact(values);
  }, [jobIds, jobFamilies]);

  const options = useMemo(() => {
    if (!jobFamilies) {
      return [];
    }

    const options = jobFamilies
      .map((jobFamily) => ({
        label: jobFamily.name,
        value: `${jobFamily.id}`,
        aliases: [],
        children: jobFamily.jobs.map((job) => ({
          label: [job.name, ...job.aliases].join(LABEL_SEPARATOR),
          value: `${job.id}`,
          aliases: job.aliases,
        })),
      }))
      .filter((option) => {
        return option.children.length > 0;
      });

    if (suggestions?.length) {
      options.unshift({
        label: t("components.ui.job-selector.suggestions"),
        value: "suggestions",
        aliases: [],
        children: suggestions.map((suggestion) => ({
          label: suggestion.job.name,
          value: `${suggestion.job.id}`,
          aliases: suggestion.companyExternalJobTitles ?? [],
        })),
      });
    }

    if (jobsSuggestionsData?.results.length) {
      options.unshift({
        label: search,
        value: BEST_MATCH_KEY,
        aliases: [],
        children: jobsSuggestionsData.results.map((suggestion) => ({
          label: suggestion.job.name,
          value: `${suggestion.job.id}`,
          aliases: suggestion.companyExternalJobTitles ?? [],
        })),
      });
    }

    return options;
  }, [jobFamilies, jobsSuggestionsData]);

  const filteredOptions = useMemo(() => {
    const lowerSearch = debouncedSearch.toLowerCase();

    return options
      .map((option) => {
        if (option.label.toLowerCase().includes(lowerSearch)) {
          return option;
        }

        return {
          ...option,
          children: option.children.filter((child) => child.label.toLowerCase().includes(lowerSearch)),
        };
      })
      .filter((option) => option.children.length > 0);
  }, [options, debouncedSearch]);

  const onJobChange = (option: string[] | null) => {
    onJobsChange(option ? [option] : []);
  };

  const onJobsChange = (options: string[][]) => {
    let newJobIds = compact(
      options
        .flatMap(([jobFamilyId, jobId]) =>
          flatJobs.filter((job) => {
            // When there's only one best match, no job id is provided so we have to manually fetch it from the matches
            if (jobFamilyId === BEST_MATCH_KEY && jobsSuggestionsData?.results.length === 1)
              return job.id === `${jobsSuggestionsData?.results[0]?.job.id}`;

            if (!jobId) return job.familyId === jobFamilyId;

            return job.id === jobId;
          })
        )
        .map((job) => job.id)
    );

    if (!CAN_BYPASS_JOB_SELECTOR_LIMIT) {
      newJobIds = takeRight(newJobIds, JOB_SELECTOR_LIMIT);
    }

    onChange(newJobIds);
    setSearch("");
  };

  const canAddJob = useMemo(() => {
    if (!multiple || CAN_BYPASS_JOB_SELECTOR_LIMIT) return true;

    return values.length < JOB_SELECTOR_LIMIT;
  }, [multiple, values]);

  const dropdownRender = (menu: ReactNode) => {
    return (
      <Stack className="z-[9999] rounded border">
        {!canAddJob && (
          <Box className="border-b bg-secondary-50 px-4 py-1">
            <Typography variant="caption">
              {t("components.ui.job-selector.max-jobs", { limit: JOB_SELECTOR_LIMIT })}
            </Typography>
          </Box>
        )}

        {!filteredOptions.length ? (
          <Box className="px-4 py-2">
            {isLoading ? (
              <Stack spacing={1}>
                {range(5).map((index) => (
                  <Skeleton key={index} height={20} width={`calc(100% - ${index * 20}px)`} />
                ))}
              </Stack>
            ) : (
              t("components.ui.job-selector.no-results")
            )}
          </Box>
        ) : (
          menu
        )}

        {jobRequest && (
          <Box className="border-t bg-gray-50 px-4 py-2">
            <a className="group" onClick={() => setOpenJobRequestModal(true)}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="caption" color="text.secondary">
                  {t("components.ui.job-selector.cant-map-this-job")}
                </Typography>
                <Typography variant="caption" color="text.secondary" className="underline group-hover:text-gray-900">
                  {t("components.ui.job-selector.make-a-job-request")}
                </Typography>
              </Stack>
            </a>
          </Box>
        )}

        {user && enableCustomJobs && (
          <Box className="border-t bg-gray-50 px-4 py-2">
            <a className="group" onClick={() => setOpenCustomJobCreationModal(true)}>
              <Typography variant="caption" color="text.secondary">
                <Trans i18nKey="components.ui.job-selector.create-your-own-custom-jobs">
                  <span className="cursor-pointer underline group-hover:text-gray-900" />
                </Trans>
              </Typography>
            </a>
          </Box>
        )}

        <Box className="border-t bg-gray-50 px-4 py-2">
          <Link to="/jobs" newTab noLinkStyle className="group">
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption" color="text.secondary" className="group-hover:text-gray-900">
                {t("components.ui.job-selector.learn-about-figures-jobs")}
              </Typography>
              <OpenInNew className="text-sm text-gray-500 group-hover:text-gray-900" />
            </Stack>
          </Link>
        </Box>
      </Stack>
    );
  };

  const displayRender = (labels: string[]) => {
    return extractLabel(labels[1]) ?? labels[0];
  };

  const optionRender = (option: (typeof options)[number]) => {
    const isSearch = "__rc_cascader_search_mark__" in option;

    if (!option.value.startsWith(CUSTOM_JOB_PREFIX)) {
      const aliases = option.aliases?.slice(0, 5);
      const hasMoreAliases = option.aliases?.length > 5;

      return (
        <Stack>
          <span
            className={classNames({
              "truncate": true,
              "max-w-sm": !isSearch,
              "max-w-lg": isSearch,
            })}
          >
            {extractLabel(option.label)}
          </span>
          {option.aliases?.length > 0 && (
            <span
              className={classNames({
                "whitespace-normal text-xs !font-normal text-gray-500": true,
                "max-w-sm": !isSearch,
                "max-w-lg": isSearch,
              })}
            >
              {aliases.map((alias, index) => (
                <>
                  {index > 0 && <span>, </span>}
                  <HighlightedText key={alias} search={search} text={alias} />
                </>
              ))}
              {hasMoreAliases && <span>, ...</span>}
            </span>
          )}
        </Stack>
      );
    }

    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <span className="max-w-xs truncate">{option.label}</span>
        <IconButton
          className="!p-1"
          onClick={(event) => {
            event.stopPropagation();
            setSelectedCustomJobForEdition(option.value);
          }}
        >
          <Edit className="-my-1 text-xs text-gray-500" />
        </IconButton>
      </Stack>
    );
  };

  const searchRender = (input: string, path: typeof options) => {
    const isBestMatch = path[0]?.value === BEST_MATCH_KEY;

    return path.map((option, index) => {
      const text = extractLabel(option.label) as string;

      return (
        <>
          {index > 0 && <span className="!text-gray-500"> › </span>}
          {isBestMatch && index === 0 ? text : <HighlightedText text={text} search={input} />}
        </>
      );
    });
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 4,
          colorHighlight: theme.colors.secondary[500],
          colorBorder: theme.colors.gray[300],
          colorTextPlaceholder: theme.colors.gray[400],
          colorPrimary: theme.colors.primary[400],
          colorPrimaryHover: theme.colors.gray[400],
          boxShadowSecondary: "none",
          zIndexPopupBase: 9999,
        },
        components: {
          Select: {
            fontSize: 16,
          },
        },
      }}
    >
      {jobRequest && (
        <JobRequestModal
          isOpen={openJobRequestModal}
          onClose={async () => {
            setOpenJobRequestModal(false);
          }}
          externalJob={jobRequest.externalJob}
        />
      )}

      {enableCustomJobs && (
        <CustomJobCreationModal
          isOpen={openCustomJobCreationModal}
          onClose={async () => {
            setOpenCustomJobCreationModal(false);
            await refetchJobs();
          }}
        />
      )}

      {enableCustomJobs && selectedCustomJobForEdition && (
        <CustomJobEditionModal
          isOpen={!!selectedCustomJobForEdition}
          customJobId={selectedCustomJobForEdition}
          onClose={async () => {
            setSelectedCustomJobForEdition(null);
            await refetchJobs();
          }}
        />
      )}

      {multiple ? (
        <Cascader
          multiple
          disabled={disabled}
          className={className}
          showSearch={{ render: searchRender }}
          searchValue={search}
          onSearch={(value) => setSearch(value)}
          changeOnSelect
          tagRender={(option) => {
            const isGroup = !!options.find((o) => o.value === option.value);
            return (
              <Chip
                size="small"
                variant="outlined"
                className="mx-1 my-0.5"
                label={option.label}
                icon={isGroup ? <Workspaces className="!ml-1.5" fontSize="small" /> : undefined}
                onDelete={() => option.onClose()}
              />
            );
          }}
          optionRender={optionRender}
          displayRender={displayRender}
          dropdownRender={dropdownRender}
          value={values}
          options={options}
          onChange={onJobsChange}
          placeholder={dynamicPlaceholder}
          open={open}
          onDropdownVisibleChange={(open) => isOpen(open)}
        />
      ) : (
        <Cascader
          disabled={disabled}
          className={className}
          showSearch={{ render: searchRender }}
          searchValue={search}
          onSearch={(value) => setSearch(value)}
          displayRender={displayRender}
          dropdownRender={dropdownRender}
          optionRender={optionRender}
          value={values[0]}
          options={options}
          onChange={onJobChange}
          placeholder={dynamicPlaceholder}
          open={open}
          onDropdownVisibleChange={(open) => isOpen(open)}
        />
      )}
    </ConfigProvider>
  );
};
