import { Stack, type SvgIconProps, Typography } from "@mui/material";
import classNames from "classnames";
import { Command } from "cmdk";
import React, { type ComponentType, type PropsWithChildren } from "react";
import { trackCommandPaletteSelect } from "~/lib/external/segment/web/events";
import { useI18n } from "~/lib/i18n/use-i18n";

type Props = {
  label: string;
  keywords: string[];
  Icon?: ComponentType<SvgIconProps>;
  disabled?: boolean;
  onSelect: () => void;
};

export const CommandItem: React.FC<PropsWithChildren<Props>> = ({
  label,
  keywords,
  Icon,
  disabled = false,
  onSelect,
  children,
}) => {
  const { t } = useI18n();

  return (
    <Command.Item
      disabled={disabled}
      onSelect={() => {
        void trackCommandPaletteSelect({ keywords });
        onSelect();
      }}
      keywords={keywords}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" className="w-full" spacing={4}>
        <Stack direction="row" spacing={3} alignItems="center">
          {Icon && (
            <Icon
              className={classNames({
                "mx-0.5 h-5 w-5": true,
                "text-primary-500": !disabled,
                "text-gray-500": disabled,
              })}
            />
          )}
          {children}
        </Stack>
        <Typography variant="caption" color="text.secondary" className="shrink-0">
          {disabled ? t("components.command-palette.command-item.unavailable") : label}
        </Typography>
      </Stack>
    </Command.Item>
  );
};
