import { ExternalEmployeeStatus } from "@prisma/client";
import { uniq } from "lodash";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { type UpdateMappingSchemaInput } from "~/pages/api/update-mapping";
import { mapExternalJobs } from "~/services/mapping/map-external-jobs";
import { mapExternalLevels } from "~/services/mapping/map-external-levels";
import { mapExternalLocations } from "~/services/mapping/map-external-locations";
import { updateExternalEmployeesAfterMapping } from "~/services/mapping/update-external-employees-after-mapping";
import { updateStatusForCompanyId } from "~/services/onboarding/set-onboarding-state";

export const updateMapping = async (ctx: AppContext, input: UpdateMappingSchemaInput) => {
  logInfo(ctx, "[mapping] Updating mapping grid", { companyId: input.companyId });

  const externalEmployeeIds = uniq([
    ...(await mapExternalJobs(ctx, { mapping: input.externalJobs, companyId: input.companyId })),
    ...(await mapExternalLevels(ctx, input.externalLevels, input.companyId)),
    ...(await mapExternalLocations(ctx, input.externalLocations, input.companyId)),
  ]);

  await updateStatusForCompanyId(ctx, { companyId: input.companyId });

  if (externalEmployeeIds.length > 0) {
    logInfo(ctx, "[mapping] Updating impacted employees", {
      companyId: input.companyId,
      impactedEmployeeIds: externalEmployeeIds,
    });

    // unskip all linked external employees if they are not linked to another skipped entity
    await ctx.prisma.externalEmployee.updateMany({
      where: {
        id: { in: externalEmployeeIds },
        companyId: input.companyId,
        status: ExternalEmployeeStatus.SKIPPED,
        job: { skippedAt: null },
        location: { skippedAt: null },
        level: { skippedAt: null },
      },
      data: { status: ExternalEmployeeStatus.UNMAPPED, mappingSkipReason: null },
    });

    await updateExternalEmployeesAfterMapping(ctx, externalEmployeeIds, input.companyId);

    logInfo(ctx, "[mapping] Updated impacted employees", {
      companyId: input.companyId,
      impactedEmployeeIds: externalEmployeeIds,
    });
  }
};
