import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import {
  type GetAssistedOnboardingStatusResult,
  getAssistedOnboardingStatus,
} from "~/services/onboarding/get-assisted-onboarding-status";

const handler: NextApiHandler<GetAssistedOnboardingStatusResult> = async (req, res) => {
  const getAssistedOnboardingStatusResult = await getAssistedOnboardingStatus(req);
  return res.status(HttpStatus.OK).json(getAssistedOnboardingStatusResult);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSettings: true,
  },
});

export const GET_ASSISTED_ONBOARDING_STATUS_QUERY_KEY: ApiRoute["pathname"] =
  "/api/onboarding/get-assisted-onboarding-status";

export const useGetAssistedOnboardingStatusQuery = createQuery<typeof handler>({
  path: GET_ASSISTED_ONBOARDING_STATUS_QUERY_KEY,
});
