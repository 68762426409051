import { ExternalRemunerationType, Gender, UserLocale } from "@prisma/client";
import { type TFunction } from "i18next";
import { capitalize } from "lodash";
import { type Field } from "react-spreadsheet-import/types/types";
import { DateFormats, formatDate } from "~/lib/dates";
import { computeExternalEmployeeTotalRemuneration } from "~/services/external-employee";
import { type PartialSpreadsheetExternalEmployees } from "~/services/external-employee/fetch-partial-spreadsheet-external-employees";
import { type EmployeeData } from "~/services/synchronization/sync-external-employees";

export type ExternalEmployeeField<Key extends string> = {
  key: Key;
  rsiField: Field<Key>;
  toSpreadsheet?: (externalEmployee: PartialSpreadsheetExternalEmployees[number]) => string;
  fromSpreadsheet?: (value: string) => EmployeeData["input"];
};

const NUMBER_FIELD_PATTERN = "^\\s*(?:\\d+|\\d{1,3}(?:,\\d{3})+)(?:\\.\\d+)?\\s*$";
const OPTIONAL_NUMBER_FIELD_PATTERN = "^\\s*(?:\\d+|\\d{1,3}(?:,\\d{3})+)?(?:\\.\\d+)?\\s*$";

const formatMoney = (amount: number | null) => {
  return amount ? Math.floor(amount / 100).toString() : "";
};

export const employeeNumberField = (t: TFunction): ExternalEmployeeField<"employeeNumber"> => ({
  key: "employeeNumber",
  toSpreadsheet: (externalEmployee) => externalEmployee.employeeNumber?.toString() || "",
  rsiField: {
    label: "Employee Number",
    key: "employeeNumber",
    fieldType: { type: "input" },
    example: "0000123",
    validations: [
      {
        rule: "required",
        errorMessage: t("services.spreadsheet.external-employee-fields.is-required", { field: "Employee Number" }),
        level: "error",
      },
      {
        rule: "unique",
        errorMessage: t("services.spreadsheet.external-employee-fields.unique-employee-id"),
        level: "error",
      },
    ],
  },
});

export const genderField: ExternalEmployeeField<"gender"> = {
  key: "gender",
  toSpreadsheet: (externalEmployee) => capitalize(externalEmployee.gender ?? "Undisclosed"),
  rsiField: {
    label: "Gender",
    key: "gender",
    fieldType: {
      type: "select",
      options: [
        { label: "Male", value: Gender.MALE },
        { label: "Female", value: Gender.FEMALE },
        { label: "Undisclosed", value: Gender.UNDISCLOSED },
      ],
    },
    example: "Female",
  },
};

export const birthDateField: ExternalEmployeeField<"birthDate"> = {
  key: "birthDate",
  toSpreadsheet: (externalEmployee) =>
    externalEmployee.birthDate ? formatDate(externalEmployee.birthDate, DateFormats.FULL_DATE, UserLocale.EN) : "",
  rsiField: {
    label: "Birth Date",
    key: "birthDate",
    fieldType: { type: "input" },
    example: "1984-02-13",
  },
};

export const firstNameField: ExternalEmployeeField<"firstName"> = {
  key: "firstName",
  rsiField: {
    label: "First name",
    key: "firstName",
    fieldType: { type: "input" },
    example: "Alain",
  },
};

export const lastNameField: ExternalEmployeeField<"lastName"> = {
  key: "lastName",
  rsiField: {
    label: "Last name",
    key: "lastName",
    fieldType: { type: "input" },
    example: "Delon",
  },
};

export const workEmailField: ExternalEmployeeField<"email"> = {
  key: "email",
  rsiField: {
    label: "Work email",
    key: "email",
    fieldType: { type: "input" },
    example: "alain.delon@figures.hr",
  },
};

export const hireDateField: ExternalEmployeeField<"hireDate"> = {
  key: "hireDate",
  toSpreadsheet: (externalEmployee) =>
    externalEmployee.hireDate ? formatDate(externalEmployee.hireDate, DateFormats.FULL_DATE, UserLocale.EN) : "",
  rsiField: {
    label: "Hire Date",
    key: "hireDate",
    fieldType: { type: "input" },
    example: "2024-02-13",
  },
};

export const jobTitleField: ExternalEmployeeField<"jobTitle"> = {
  key: "jobTitle",
  toSpreadsheet: (externalEmployee) => externalEmployee.job?.name ?? "",
  rsiField: {
    label: "Job Title",
    key: "jobTitle",
    fieldType: { type: "input" },
    example: "Chief Happiness Officer",
  },
};

export const levelField: ExternalEmployeeField<"level"> = {
  key: "level",
  toSpreadsheet: (externalEmployee) => externalEmployee.level?.name ?? "",
  rsiField: {
    label: "Job Level",
    key: "level",
    fieldType: { type: "input" },
    example: "Senior",
  },
};

export const currencyField: ExternalEmployeeField<"currency"> = {
  key: "currency",
  toSpreadsheet: (externalEmployee) => externalEmployee.currency.code ?? "",
  rsiField: {
    label: "Currency",
    key: "currency",
    fieldType: { type: "input" },
    example: "EUR",
  },
};

export const baseSalaryField = (t: TFunction): ExternalEmployeeField<"baseSalary"> => ({
  key: "baseSalary",
  toSpreadsheet: (externalEmployee) => {
    if (externalEmployee.mappedEmployee?.baseSalary) {
      return formatMoney(externalEmployee.mappedEmployee?.baseSalary);
    }

    return formatMoney(
      computeExternalEmployeeTotalRemuneration(externalEmployee, ExternalRemunerationType.FIXED_SALARY)
    );
  },
  rsiField: {
    label: "Annual Base Salary (Full-time Equivalent)",
    key: "baseSalary",
    fieldType: { type: "input" },
    example: "50000",
    validations: [
      {
        rule: "regex",
        value: NUMBER_FIELD_PATTERN,
        errorMessage: t("services.spreadsheet.external-employee-fields.positive-number", {
          field: "Annual Base Salary",
        }),
        level: "error",
      },
    ],
  },
});

export const fteDividerField: ExternalEmployeeField<"fteDivider"> = {
  key: "fteDivider",
  rsiField: {
    label: "Full-time Equivalent Coefficient",
    key: "fteDivider",
    fieldType: { type: "input" },
    example: "0,7",
  },
};

export const onTargetBonusField = (t: TFunction): ExternalEmployeeField<"onTargetBonus"> => ({
  key: "onTargetBonus",
  toSpreadsheet: (externalEmployee) => {
    if (externalEmployee.mappedEmployee?.onTargetBonus) {
      return formatMoney(externalEmployee.mappedEmployee?.onTargetBonus);
    }

    return formatMoney(
      computeExternalEmployeeTotalRemuneration(externalEmployee, ExternalRemunerationType.VARIABLE_BONUS)
    );
  },
  rsiField: {
    label: "On Target Bonus",
    key: "onTargetBonus",
    fieldType: { type: "input" },
    example: "10000",
    validations: [
      {
        rule: "regex",
        value: OPTIONAL_NUMBER_FIELD_PATTERN,
        errorMessage: t("services.spreadsheet.external-employee-fields.positive-number", { field: "On Target Bonus" }),
        level: "error",
      },
    ],
  },
});

export const fixedBonusField = (t: TFunction): ExternalEmployeeField<"fixedBonus"> => ({
  key: "fixedBonus",
  toSpreadsheet: (externalEmployee) => {
    if (externalEmployee.mappedEmployee?.fixedBonus) {
      return formatMoney(externalEmployee.mappedEmployee?.fixedBonus);
    }

    return formatMoney(
      computeExternalEmployeeTotalRemuneration(externalEmployee, ExternalRemunerationType.FIXED_BONUS)
    );
  },
  rsiField: {
    label: "Other Individual/Group Bonus",
    key: "fixedBonus",
    fieldType: { type: "input" },
    example: "5000",
    validations: [
      {
        rule: "regex",
        value: OPTIONAL_NUMBER_FIELD_PATTERN,
        errorMessage: t("services.spreadsheet.external-employee-fields.positive-number", {
          field: "Other Individual/Group Bonus",
        }),
        level: "error",
      },
    ],
  },
});

export const locationField: ExternalEmployeeField<"location"> = {
  key: "location",
  toSpreadsheet: (externalEmployee) => externalEmployee.location?.name ?? "",
  rsiField: {
    label: "Location",
    key: "location",
    fieldType: { type: "input" },
    example: "Paris HQ",
  },
};

export const isFounderField: ExternalEmployeeField<"isFounder"> = {
  key: "isFounder",
  rsiField: {
    label: "Founder ?",
    key: "isFounder",
    fieldType: { type: "checkbox" },
    example: "YES",
  },
};

export const performanceRatingField: ExternalEmployeeField<"performanceRating"> = {
  key: "performanceRating",
  toSpreadsheet: (externalEmployee) => externalEmployee.performanceReviewRating?.name ?? "",
  rsiField: {
    label: "Performance Rating",
    key: "performanceRating",
    fieldType: { type: "input" },
    example: "Takes their job very seriously",
  },
};

export const managerField: ExternalEmployeeField<"manager"> = {
  key: "manager",
  toSpreadsheet: (externalEmployee) => externalEmployee.manager?.employeeNumber ?? "",
  rsiField: {
    label: "Manager Employee Number",
    key: "manager",
    fieldType: { type: "input" },
    example: "0000123",
  },
};

export const businessUnitField: ExternalEmployeeField<"businessUnit"> = {
  key: "businessUnit",
  toSpreadsheet: (externalEmployee) => externalEmployee.businessUnit ?? "",
  rsiField: {
    label: "Business Unit",
    key: "businessUnit",
    fieldType: { type: "input" },
    example: "Sales",
  },
};
