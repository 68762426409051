import {
  type PerformanceReviewCycle,
  type PerformanceReviewIntegrationSettings,
  PerformanceReviewIntegrationSource,
  type Prisma,
} from "@prisma/client";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { getCultureAmpPerformanceReviews } from "~/lib/performance-review/culture-amp";
import { type PerformanceReviewData } from "~/pages/api/sync-performance-reviews";

export type PerformanceReviewIntegrationConfig = Prisma.PerformanceReviewIntegrationSettingsUncheckedCreateInput;

export type GetPerformanceReviews = (
  ctx: AppContext,
  integrationSettings: PerformanceReviewIntegrationSettings,
  performanceReviewCycle: Pick<PerformanceReviewCycle, "id" | "selectedCycleIds">
) => Promise<PerformanceReviewData[]>;

export type PerformanceReviewSourceConfig = {
  default: PerformanceReviewIntegrationConfig;
} & Record<string, PerformanceReviewIntegrationConfig>;

export const getPerformanceReviews = (source: PerformanceReviewIntegrationSource): GetPerformanceReviews =>
  match(source)
    .with(PerformanceReviewIntegrationSource.CULTURE_AMP, () => getCultureAmpPerformanceReviews)
    .exhaustive();
