import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { handleExceptionalRightsChange } from "~/components/layout/user-notices";
import { broadcastTabSyncingEvent } from "~/hooks/use-tab-syncing";
import { api } from "~/lib/api";
import { createMutation } from "~/lib/react-query";
import { stopCompanyImpersonation } from "~/lib/session";
import { relinquishTemporarySuperAdminRights } from "~/services/user/relinquish-temporary-super-admin-rights";

const handler: NextApiHandler = async (req, res) => {
  await stopCompanyImpersonation(req);
  await relinquishTemporarySuperAdminRights(req);

  return res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: { superAdmin: true },
});

export const useRelinquishTemporarySuperAdminRightsMutation = createMutation<typeof handler>({
  path: "/api/user/relinquish-temporary-super-admin-rights",
  options: () => ({
    onSuccess: () => {
      broadcastTabSyncingEvent({
        event: "super-admin",
        payload: { isSuperAdmin: false },
      });

      handleExceptionalRightsChange();
    },
  }),
});
