import {
  type CompensationReviewConfigurationStep,
  type Country,
  type EmployeeLevel,
  type EmployeeLocation,
  type Job,
  type JobFamily,
  type MarketDataSearchSource,
  type OnboardingStep,
  type SalaryGridConfigurationStep,
  type SalaryGridMeasure,
  type UserPermissions,
  type UserRole,
} from "@prisma/client";
import { type GetServerSidePropsContext, type NextApiRequest } from "next";
import packageJson from "~/../package.json";
import { type AppContext } from "~/lib/context";
import { mapServerContext, trackServerEvent } from "~/lib/external/segment/server/client";
import { type Recipient } from "~/lib/external/sendinblue/client";
import { getRequiredUser } from "~/lib/get-required-user";
import { type AuthenticatedUser } from "~/lib/session";
import { type UpdateUserPermissionsPolicyInput } from "~/pages/api/account/update-user-permissions-policy";
import { type Input as MarketDataInput } from "~/pages/api/get-market-data-stats";
import { type WeightedBenchmark } from "~/pages/api/internal-partner/market-data-benchmark";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { type CompensationReviewScope } from "~/services/compensation-review/compensation-review-scope";
import { type FeatureFlagForCompany } from "~/services/feature-flags";
import { type WeightedLevel } from "~/services/level-classification";
import { type MarketDataBenchmark } from "~/services/market-data/get-market-data-benchmark";
import { type SalaryBandJobTemplate } from "~/services/salary-bands/configuration/create-preset-salary-band-jobs";
import { formatUserFullName, formatUserRole } from "~/services/user/utils";
import { type BulkUpdateSalaryRangeWidthsInput } from "~/workers/update-salary-range-settings";
const shouldTrackApiEndpoint = (req: NextApiRequest) => {
  // We're mostly interested in POST endpoints that usually match a user action
  if (req.method === "GET") {
    return false;
  }

  const blacklistedApiEndpoints = [
    // We have custom events for market data stats searches
    "/api/get-market-data-stats",
  ];

  return !blacklistedApiEndpoints.includes(req.url as string);
};

export const trackApiEndpointCalled = async (ctx: AppContext, event: { req: NextApiRequest; error?: Error }) => {
  if (!shouldTrackApiEndpoint(event.req)) {
    return;
  }

  await trackServerEvent(ctx.segment, {
    event: "API Endpoint Called",
    ...mapServerContext(ctx, event.req.user),
    properties: {
      method: event.req.method,
      path: event.req.url,
      ...(event.error && {
        error: {
          name: event.error?.name,
        },
      }),
    },
  });
};

export const trackPageRequested = async (ctx: AppContext, event: { ctx: GetServerSidePropsContext; error?: Error }) => {
  await trackServerEvent(ctx.segment, {
    event: "Page Requested",
    ...mapServerContext(ctx, event.ctx.req.user),
    properties: {
      path: event.ctx.resolvedUrl,
      query: event.ctx.query,
      ...(event.error && {
        error: {
          name: event.error?.name,
        },
      }),
    },
  });
};

export const trackEmailSent = async (
  ctx: AppContext,
  event: { template: string; recipients: Omit<Recipient, "email" | "name">[] }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Email sent",
    ...mapServerContext(ctx),
    properties: {
      template: event.template,
      recipients: event.recipients.map((recipient) => ({
        id: recipient.id ?? null,
        company: {
          id: recipient.company?.id ?? null,
          name: recipient.company?.name ?? null,
        },
      })),
    },
  });
};

export const trackUserInteracted = async (ctx: AppContext, user: AuthenticatedUser) => {
  await trackServerEvent(ctx.segment, {
    event: "User Interacted",
    ...mapServerContext(ctx, user),
  });
};

export const trackUserSignedUp = async (ctx: AppContext, user: AuthenticatedUser) => {
  await trackServerEvent(ctx.segment, {
    event: "User Signed Up",
    ...mapServerContext(ctx, user),
  });
};

export const trackUserSignedIn = async (ctx: AppContext, user: AuthenticatedUser) => {
  await trackServerEvent(ctx.segment, {
    event: "User Signed In",
    ...mapServerContext(ctx, user),
  });
};

export const trackUserSignedOut = async (ctx: AppContext, user: AuthenticatedUser) => {
  await trackServerEvent(ctx.segment, {
    event: "User Signed Out",
    ...mapServerContext(ctx, user),
  });
};

export const trackUserMissingSession = async (ctx: AppContext, userId: number) => {
  await trackServerEvent(ctx.segment, {
    event: "User Missing Session",
    userId,
    context: {
      appVersion: packageJson.version,
    },
  });
};

export const trackLoginLinkEmailed = async (ctx: AppContext, user: NullableAuthenticatedUser) => {
  await trackServerEvent(ctx.segment, {
    event: "Login Link Emailed",
    ...mapServerContext(ctx, user),
  });
};

type PermissionsWithAllowedModels = UserPermissions & {
  allowedJobFamilies: JobFamily[];
  allowedCountries: Country[];
};

export const trackUserPermissionsUpdated = async (
  ctx: AppContext,
  event: {
    userId: number;
    previousPermissions: PermissionsWithAllowedModels;
    newPermissions: PermissionsWithAllowedModels;
  }
) => {
  const mapPermissions = (permissions: PermissionsWithAllowedModels) => {
    return {
      role: formatUserRole(ctx.t_en, permissions.role),
      managerExternalEmployeeId: permissions.externalEmployeeId,
      canAccessMarketData: permissions.canAccessMarketData,
      isManager: permissions.isManager,
      allowedLevels: permissions.allowedLevels,
      allowedJobFamiliesIds: permissions.allowedJobFamilies.map((jobFamily) => jobFamily.id),
      allowedCountriesIds: permissions.allowedCountries.map((country) => country.id),
    };
  };

  await trackServerEvent(ctx.segment, {
    event: "User Permissions Updated",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      previousPermissions: mapPermissions(event.previousPermissions),
      newPermissions: mapPermissions(event.newPermissions),
      userId: event.userId,
    },
  });
};

export const trackUserPermissionsPolicyUpdated = async (
  ctx: AppContext,
  userPermissionsPolicy: UpdateUserPermissionsPolicyInput
) => {
  await trackServerEvent(ctx.segment, {
    event: "User Permissions Policy Updated",
    ...mapServerContext(ctx, ctx.user),
    properties: userPermissionsPolicy,
  });
};

export const trackApiKeyAuthenticated = async (
  ctx: AppContext,
  event: {
    ipAddress: string;
    url: string;
    partner: string;
  }
) => {
  await trackServerEvent(ctx.segment, {
    event: "API Key Authenticated",
    ...mapServerContext(ctx),
    properties: {
      ipAddress: event.ipAddress,
      requestedUrl: event.url,
      partner: event.partner,
    },
  });
};

export const trackUserInvited = async (
  ctx: AppContext,
  event: {
    invitedBy: AuthenticatedUser;
    invitedUserId: number;
    role: UserRole;
  }
) => {
  await trackServerEvent(ctx.segment, {
    event: "User Invited",
    ...mapServerContext(ctx, event.invitedBy),
    properties: {
      invitedUserId: event.invitedUserId,
      role: event.role,
    },
  });
};

export const trackMarketDataSearched = async (
  ctx: AppContext,
  event: {
    input: MarketDataInput;
    origin: "INITIAL_SEARCH" | "INPUT_UPDATE" | MarketDataSearchSource;
  }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Market Data Searched",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      origin: event.origin,
      input: event.input,
    },
  });
};

export const trackUserInvitedNeedsReview = async (
  ctx: AppContext,
  event: {
    invitedBy: AuthenticatedUser;
    invitedUserId: number;
    role: UserRole;
  }
) => {
  await trackServerEvent(ctx.segment, {
    event: "User Invited Needs Review",
    ...mapServerContext(ctx, event.invitedBy),
    properties: {
      invitedUserId: event.invitedUserId,
      role: event.role,
    },
  });
};

export const trackUsersInvited = async (
  ctx: AppContext,
  event: {
    invitedBy: AuthenticatedUser;
    count: number;
  }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Users Invited",
    ...mapServerContext(ctx, event.invitedBy),
    properties: { count: event.count },
  });
};

export const trackImpersonationAccessGranted = async (ctx: AppContext, event: { expiresAt: Date | null }) => {
  await trackServerEvent(ctx.segment, {
    event: "Impersonation Access Granted",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      expiresAt: event.expiresAt,
    },
  });
};

export const trackImpersonationAccessRevoked = async (ctx: AppContext) => {
  await trackServerEvent(ctx.segment, {
    event: "Impersonation Access Revoked",
    ...mapServerContext(ctx, ctx.user),
  });
};

export const trackImpersonateCompany = async (ctx: AppContext) => {
  await trackServerEvent(ctx.segment, {
    event: "Impersonate Company",
    ...mapServerContext(ctx, ctx.user),
  });
};

export const trackStopImpersonateCompany = async (ctx: AppContext) => {
  await trackServerEvent(ctx.segment, {
    event: "Stop Impersonate Company",
    ...mapServerContext(ctx, ctx.user),
  });
};

export const trackImpersonateUser = async (ctx: AppContext) => {
  await trackServerEvent(ctx.segment, {
    event: "Impersonate User",
    ...mapServerContext(ctx, ctx.user),
  });
};

export const trackStopImpersonateUser = async (ctx: AppContext) => {
  await trackServerEvent(ctx.segment, {
    event: "Stop Impersonate User",
    ...mapServerContext(ctx, ctx.user),
  });
};

export const trackFeatureFlagUpdated = async (
  ctx: AppContext,
  event: {
    featureFlag: Pick<FeatureFlagForCompany, "name" | "scope"> & { companiesCount: number };
  }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Feature Flag Updated",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      name: event.featureFlag.name,
      scope: event.featureFlag.scope,
      companiesCount: event.featureFlag.companiesCount,
    },
  });
};

export const trackOnboardingStepCompleted = async (ctx: AppContext, event: { completedStep: OnboardingStep }) => {
  await trackServerEvent(ctx.segment, {
    event: "Onboarding Step Completed",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      completedStep: event.completedStep,
    },
  });
};

export const trackSalaryBandJobTemplateSelected = async (
  ctx: AppContext,
  event: {
    salaryGridId: number;
    template: SalaryBandJobTemplate;
  }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Job Template Selected",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      template: event.template,
    },
  });
};

export const trackSalaryBandJobStructureReset = async (ctx: AppContext, event: { salaryGridId: number }) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Job Structure Reset",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
    },
  });
};

export const trackSalaryBandShared = async (ctx: AppContext, event: { salaryGridId: number; email: string }) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Shared",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      email: event.email,
    },
  });
};

export const trackSalaryBandSharedToUser = async (
  ctx: AppContext,
  event: {
    salaryGridId: number;
    userId: number;
    salaryBandNames: string;
    canAccessSalaryBandBenchmarkData: boolean;
  }
) => {
  const user = getRequiredUser(ctx);

  await trackServerEvent(ctx.segment, {
    event: "Salary Band Shared To User",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      userId: event.userId,
      sharedBy: formatUserFullName(user),
      salaryBandNames: event.salaryBandNames,
      canAccessSalaryBandBenchmarkData: event.canAccessSalaryBandBenchmarkData,
    },
  });
};

export const trackSalaryBandConfigurationStepCompleted = async (
  ctx: AppContext,
  event: { salaryGridId: number; completedStep: SalaryGridConfigurationStep }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Bands Configuration Step Completed",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      completedStep: event.completedStep,
    },
  });
};

export const trackSalaryBandsCreated = async (ctx: AppContext, event: { salaryGridId: number }) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Bands Created",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
    },
  });
};

export const trackSalaryBandsActivated = async (ctx: AppContext, event: { salaryGridId: number }) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Bands Activated",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
    },
  });
};

export const trackSalaryBandsDeactivated = async (ctx: AppContext, event: { salaryGridId: number }) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Bands De-Activated",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
    },
  });
};

export const trackSalaryBandCreated = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryBandId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Created",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryBandId: event.salaryBandId,
    },
  });
};

export const trackSalaryBandValidated = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryBandId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Validated",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryBandId: event.salaryBandId,
    },
  });
};

export const trackSalaryBandConvertedToDraft = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryBandId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Converted To Draft",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryBandId: event.salaryBandId,
    },
  });
};

export const trackSalaryBandWidthEdited = async (ctx: AppContext, event: { salaryGridId: number }) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Bands Width Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
    },
  });
};

export const trackSalaryRangesWidthEdited = async (ctx: AppContext, event: BulkUpdateSalaryRangeWidthsInput) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Ranges Width Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryRangeIds: event.salaryRangeIds,
      width: event.width,
    },
  });
};

export const trackSalaryGridMeasureEdited = async (
  ctx: AppContext,
  event: { salaryGridId: number; measure: SalaryGridMeasure }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Bands Measure Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      measure: event.measure,
    },
  });
};

export const trackSalaryRangeMidpointEdited = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryRangeId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Range Midpoint Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryRangeId: event.salaryRangeId,
    },
  });
};

export const trackSalaryRangeMinOrMaxEdited = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryRangeId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Range Min or Max Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryRangeId: event.salaryRangeId,
    },
  });
};

export const trackSalaryBandBenchmarkedJobsEdited = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryBandId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Benchmarked Jobs Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryBandId: event.salaryBandId,
    },
  });
};

export const trackSalaryBandBenchmarkedLocationsEdited = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryBandId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Benchmarked Locations Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryBandId: event.salaryBandId,
    },
  });
};

export const trackSalaryBandMarketPositioningEdited = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryBandId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Market Positioning Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryBandId: event.salaryBandId,
    },
  });
};

export const trackSalaryBandMarketPositioningAlignedOnAllBands = async (
  ctx: AppContext,
  event: { salaryGridId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Market Positioning Aligned On All Bands",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
    },
  });
};

export const trackSalaryBandExternalBenchmarkImported = async (
  ctx: AppContext,
  event: { salaryGridId: number; externalBenchmarkId: number; externalBenchmarkName: string }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band External Benchmark Imported",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      externalBenchmarkId: event.externalBenchmarkId,
      externalBenchmarkName: event.externalBenchmarkName,
    },
  });
};

export const trackSalaryBandExternalBenchmarkImportEdited = async (
  ctx: AppContext,
  event: { salaryGridId: number; externalBenchmarkId: number; externalBenchmarkName: string }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band External Benchmark Import Edited",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      externalBenchmarkId: event.externalBenchmarkId,
      externalBenchmarkName: event.externalBenchmarkName,
    },
  });
};

export const trackSalaryBandExternalBenchmarkGroupsUpdated = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryBandId: number; externalBenchmarkId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band External Benchmark Groups Updated",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryBandId: event.salaryBandId,
      externalBenchmarkId: event.externalBenchmarkId,
    },
  });
};

export const trackSalaryBandLevelExternalBenchmarkLevelsUpdated = async (
  ctx: AppContext,
  event: { salaryGridId: number; salaryBandLevelId: number; externalBenchmarkId: number }
) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Band Level External Benchmark Levels Updated",
    ...mapServerContext(ctx, ctx.user),
    properties: {
      salaryGridId: event.salaryGridId,
      salaryBandLevelId: event.salaryBandLevelId,
      externalBenchmarkId: event.externalBenchmarkId,
    },
  });
};

type InternalPartnerMarketDataBenchmarkData = {
  input: {
    "job-title": string;
    "location": string;
    "years-of-experience": number;
  };
  classifiedInput: {
    country: Pick<Country, "id" | "name">;
    levels: WeightedLevel[];
    location?: Pick<EmployeeLocation, "id" | "name">;
    job: Pick<Job, "id" | "name">;
  };
  benchmarksByLevel: {
    level: EmployeeLevel;
    benchmark: MarketDataBenchmark;
  }[];
  finalBenchmark: {
    ic: WeightedBenchmark;
    manager: WeightedBenchmark;
    classic: WeightedBenchmark;
  };
};

export const trackInternalPartnerMarketDataBenchmarkData = async (
  ctx: AppContext,
  event: InternalPartnerMarketDataBenchmarkData
) => {
  await trackServerEvent(ctx.segment, {
    event: "Internal Market Data Search",
    ...mapServerContext(ctx, ctx.user),
    properties: event,
  });
};

type CompensationReviewRecommandationsSubmitted = {
  scope: CompensationReviewScope;
};

export const trackCompensationReviewRecommandationsSubmitted = async (
  ctx: AppContext,
  event: CompensationReviewRecommandationsSubmitted
) => {
  await trackServerEvent(ctx.segment, {
    event: "Compensation Review Recommendations Submitted",
    ...mapServerContext(ctx, ctx.user),
    properties: event,
  });
};

type CompensationReviewCampaignClosed = {
  campaignId: number;
};

export const trackCompensationReviewCampaignClosed = async (
  ctx: AppContext,
  event: CompensationReviewCampaignClosed
) => {
  await trackServerEvent(ctx.segment, {
    event: "Compensation Review Campaign Closed",
    ...mapServerContext(ctx, ctx.user),
    properties: event,
  });
};

type CompensationReviewConfigurationStepFinished = {
  campaignId: number;
  configurationStep: CompensationReviewConfigurationStep;
};

export const trackCompensationReviewConfigurationStepFinished = async (
  ctx: AppContext,
  event: CompensationReviewConfigurationStepFinished
) => {
  await trackServerEvent(ctx.segment, {
    event: "Compensation Review Configuration Step Finished",
    ...mapServerContext(ctx, ctx.user),
    properties: event,
  });
};

type SalaryGridNewVersionCreated = {
  existingSalaryGridId: number;
  newSalaryGridId: number;
  name: string;
};

export const trackSalaryGridNewVersionCreated = async (ctx: AppContext, event: SalaryGridNewVersionCreated) => {
  await trackServerEvent(ctx.segment, {
    event: "Salary Grid New Version Created",
    ...mapServerContext(ctx, ctx.user),
    properties: event,
  });
};
