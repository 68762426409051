/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  type Currency,
  ExternalEmployeeSource,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { parseISO } from "date-fns";
import { chain, compact, get, isNumber, isString } from "lodash";
import { type ParsedUrlQueryInput } from "querystring";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { companyHasAccessToBulkInviteUsers } from "~/lib/hris/helpers/company-has-access-to-bulk-invite-users";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/compute-additional-field-value-payloads";
import { getEmployeeProfilePicture } from "~/lib/hris/helpers/get-employee-profile-picture";
import { type IntegrationCustomFields, getMissingCustomFields } from "~/lib/hris/helpers/get-missing-custom-fields";
import { getNumberOfMonth } from "~/lib/hris/helpers/get-number-of-month";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/map-custom-remuneration-item";
import { type IntegrationConfig, type SourceConfig, type StaticModels } from "~/lib/integration";
import { logWarn } from "~/lib/logger";
import { buildExternalUrl } from "~/lib/url";
import { assertNotNil, assertProps } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetch-company-integration-diagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/sync-external-employees";

const base: IntegrationConfig = {
  source: "ALEXISHR",
  companyId: 0,
  clientId: null,
  domain: null,
  clientSecret: config.alexishr.clientSecret,
  anonymous: false,
  enabled: true,
  retryCount: 0,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  variableCustomFieldName: null,
  externalIdCustomFieldName: null,
  variableCustomFieldFrequency: null,
  holidayAllowanceCustomFieldName: null,
  holidayAllowanceCustomFieldFrequency: null,
  isCustomFieldOnlyHolidayAllowance: null,
  isHolidayAllowanceIncludedInBaseSalary: null,
  businessUnitCustomFieldName: null,
  locationCustomFieldName: null,
};

export const alexishrConfigs: SourceConfig = {
  "default": base,
  "anonymous": { ...base, anonymous: true },
  "custom fields": {
    ...base,
    anonymous: true,
    fteCustomFieldName: "FTE",
    levelCustomFieldName: "level",
    variableCustomFieldName: "bonusCom",
    externalIdCustomFieldName: "externalEmployeeId",
    variableCustomFieldFrequency: "YEARLY",
    businessUnitCustomFieldName: "department",
  },
};

type Credentials = {
  clientSecret: string;
  anonymous: boolean;
};

export type AlexishrEmployee = {
  id: string;
  employeeNumber: string;
  managerEmployeeId?: string;
  avatarUrl?: string;
  firstName?: string;
  lastName?: string;
  active: boolean;
  title?: string;
  birthDate?: string;
  gender?: string;
  workEmail?: string;
  privateEmail?: string;
  workPhone?: string;
  hireDate?: string;
  compensationId?: string;
  officeId?: string;
  employmentTypeId?: string;
  employmentCountry?: string;
  custom?: Record<string, string | number>;
};

export const enum AlexishrPayoutPeriod {
  HOURLY = "HOURLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  BI_WEEKLY = "BI_WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  ANNUAL = "ANNUAL",
}

export type AlexishrCompensation = {
  id: string;
  employeeId: string;
  currency: string;
  payoutPeriod: AlexishrPayoutPeriod;
  amount: number;
  effectiveDate: string;
  documentVersion: number;
};

export type AlexishrEmploymentType = {
  id: string;
  name: string;
};

export type AlexishrOffice = {
  id: string;
  name: string;
};

export type CompleteAlexishrEmployee = AlexishrEmployee & {
  office?: AlexishrOffice;
  compensation?: AlexishrCompensation;
  employmentType?: AlexishrEmploymentType;
  historicalCompensations?: AlexishrCompensation[];
};

type AlexishrResponse<T extends AlexishrEmployee | AlexishrCompensation | AlexishrOffice | AlexishrEmploymentType> = {
  status: string;
  total: number;
  count: number;
  offset: number;
  data: T[];
};

type AlexishrIntegrationSettingsInput = Credentials & IntegrationCustomFields;

const DEFAULT_LIMIT = 100;

const alexishrFetch = async <
  T extends AlexishrEmployee | AlexishrCompensation | AlexishrOffice | AlexishrEmploymentType,
>(
  credentials: Credentials,
  endpoint: string,
  {
    method = "GET",
    query,
    paginate = true,
  }: {
    method?: "GET";
    query?: ParsedUrlQueryInput;
    paginate?: boolean;
  } = {}
): Promise<T[]> => {
  const isSandbox = credentials.clientSecret === config.alexishr.clientSecret;
  const domain = isSandbox ? "api.sandbox.alexishr.com" : "api.alexishr.com";
  const baseUrl = `https://${domain}/v1/${endpoint}/`;

  const res = await fetch(buildExternalUrl(baseUrl, { limit: DEFAULT_LIMIT, ...query, format: "json" }), {
    method,
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${credentials.clientSecret}`,
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    const { message } = await res.json();
    throw new Error(`[alexishr] ${res.status} ${res.statusText} : ${message}`);
  }

  const json: AlexishrResponse<T> = await res.json();

  const isLastPageOfData = json.count + json.offset === json.total;

  // If we have reached the end of paginated data just return what we have
  if (isLastPageOfData || !paginate) {
    return json.data;
  }

  const nextPage = await alexishrFetch<T>(credentials, endpoint, {
    method,
    query: {
      limit: DEFAULT_LIMIT,
      ...query,
      offset: json.count + json.offset,
    },
  });

  return [...json.data, ...nextPage];
};

const getAlexishrProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: CompleteAlexishrEmployee }
) => {
  if (!options.apiEmployee.avatarUrl) {
    return undefined;
  }

  return getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id,
    source: "ALEXISHR",
    integrationSettings: options.integrationSettings,
    fetch: () => fetch(options.apiEmployee.avatarUrl as string),
  });
};

const getCustomField = (apiEmployee: CompleteAlexishrEmployee, fieldId: string | null) => {
  if (!fieldId || !apiEmployee.custom) return null;
  const customField = get(apiEmployee.custom, fieldId);
  if (!(isString(customField) || isNumber(customField))) return null;
  return customField.toString();
};

export const mapAlexishrEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: CompleteAlexishrEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  const {
    fteCustomFieldName,
    levelCustomFieldName,
    variableCustomFieldName,
    variableCustomFieldFrequency,
    externalIdCustomFieldName,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    additionalFields = [],
    customRemunerationItems = [],
  } = integrationSettings;
  const customFte = getCustomField(apiEmployee, fteCustomFieldName);
  const customLevel = getCustomField(apiEmployee, levelCustomFieldName);
  const customVariable = getCustomField(apiEmployee, variableCustomFieldName);
  const customEmployeeNumber = getCustomField(apiEmployee, externalIdCustomFieldName);
  const holidayAllowanceValue = getCustomField(apiEmployee, holidayAllowanceCustomFieldName);
  const businessUnit = getCustomField(apiEmployee, businessUnitCustomFieldName);
  const customLocation = getCustomField(apiEmployee, locationCustomFieldName);
  const customJob = getCustomField(apiEmployee, jobCustomFieldName);

  const additionalFieldValues = additionalFields.map(({ hrisFieldName, id }) => ({
    additionalFieldId: id,
    value: getCustomField(apiEmployee, hrisFieldName),
  }));

  const customRemunerationItemsValues = customRemunerationItems.map((customRemunerationItem) => ({
    ...customRemunerationItem,
    value: getCustomField(apiEmployee, customRemunerationItem.hrisFieldName),
  }));

  const currency = value(() => {
    const employeeCompensationCurrency =
      apiEmployee.compensation?.currency &&
      staticModels.currencies.find(({ code }) => code === apiEmployee.compensation?.currency);

    if (employeeCompensationCurrency) return employeeCompensationCurrency;

    const countryWithCurrency = apiEmployee.employmentCountry
      ? staticModels.countries.find((country) => country.alpha2 === apiEmployee.employmentCountry)
      : staticModels.countries.find((country) => country.id === company.defaultCountryId);

    if (!!countryWithCurrency?.defaultCurrencyId) {
      const countryCurrency = staticModels.currencies.find(({ id }) => id === countryWithCurrency.defaultCurrencyId);

      if (countryCurrency) {
        return countryCurrency;
      }
    }

    const euro = assertNotNil(staticModels.currencies.find((currency) => currency.code === "EUR"));

    return euro as Currency;
  });

  const country = value(() => {
    if (!apiEmployee.employmentCountry) {
      return null;
    }
    return staticModels.countries.find((country) => country.alpha2 === apiEmployee.employmentCountry);
  });

  const location = value(() => {
    if (customLocation) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: customLocation,
              },
            },
            create: {
              externalId: customLocation,
              name: customLocation,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
              ...(country && { country: { connect: { id: country.id } } }),
            },
          },
        },
      };
    }

    if (apiEmployee.office) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: apiEmployee.office.id,
              },
            },
            create: {
              externalId: apiEmployee.office.id,
              name: apiEmployee.office.name,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
              ...(country && { country: { connect: { id: country.id } } }),
            },
          },
        },
      };
    }

    return null;
  });

  const job = value(() => {
    const jobTitle = customJob ?? apiEmployee.title;

    if (!jobTitle) return;

    return {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: jobTitle,
            },
          },
          create: {
            name: jobTitle,
            externalId: jobTitle,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    };
  });

  // If the employee is not a permanent, we create them with a SKIPPED status
  const isAutoSkipped = apiEmployee.employmentType?.name === "consultant";

  const hasAccessToEmails = await companyHasAccessToBulkInviteUsers(ctx, company.id);

  const input: EmployeeData["input"] = {
    source: "ALEXISHR",
    externalId: apiEmployee.id,
    status: isAutoSkipped ? "SKIPPED" : "UNMAPPED",
    ...(isAutoSkipped && {
      mappingSkipReason: "NOT_PERMANENT_EMPLOYEE",
    }),
    firstName: apiEmployee.firstName,
    lastName: apiEmployee.lastName,
    email: hasAccessToEmails && apiEmployee.workEmail ? apiEmployee.workEmail : null,
    employeeNumber: customEmployeeNumber ? customEmployeeNumber : apiEmployee.employeeNumber,
    gender: value(() => {
      if (!apiEmployee.gender) {
        return null;
      }
      if (apiEmployee.gender === Gender.FEMALE) {
        return Gender.FEMALE;
      }
      if (apiEmployee.gender === Gender.MALE) {
        return Gender.MALE;
      }
      return Gender.UNDISCLOSED;
    }),
    hireDate: apiEmployee.hireDate ? parseISO(apiEmployee.hireDate) : null,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency.code },
    },
    ...location,
    ...job,
    ...(customLevel && {
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: customLevel,
            },
          },
          create: {
            externalId: customLevel,
            name: customLevel,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(businessUnit && { businessUnit }),
  };

  const numberMonths = getNumberOfMonth({
    externalId: location?.location?.connectOrCreate?.create?.externalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const remunerationItems: EmployeeData["remunerationItems"] = compact([
    mapAlexishrBaseSalary(ctx, {
      company,
      compensation: apiEmployee.compensation,
      externalRemunerationStatus: ExternalRemunerationStatus.LIVE,
      numberMonths,
    }),
    ...(apiEmployee?.historicalCompensations
      ? apiEmployee.historicalCompensations.map((compensation) =>
          mapAlexishrBaseSalary(ctx, {
            company,
            compensation,
            externalRemunerationStatus: ExternalRemunerationStatus.HISTORICAL,
            numberMonths,
          })
        )
      : []),
  ]);

  if (customVariable && variableCustomFieldFrequency) {
    const multiplier = match(variableCustomFieldFrequency)
      .with("MONTHLY", () => 12)
      .with("QUARTERLY", () => 4)
      .with("YEARLY", () => 1)
      .exhaustive();

    const amount = Math.round(parseInt(customVariable) * multiplier * 100);

    remunerationItems.push({
      source: "ALEXISHR",
      externalId: "variable-bonus",
      amount,
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: "ALEXISHR",
              externalId: "variable-bonus",
            },
          },
          create: {
            source: "ALEXISHR",
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: "VARIABLE_BONUS",
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    });
  }

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  return {
    input,
    picturePath: !ignoreProfilePicture
      ? await getAlexishrProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId: apiEmployee.managerEmployeeId,
    holidayAllowanceValue,
    ...(customFte && {
      fte: customFte,
      ignoreFte: apiEmployee.compensation?.payoutPeriod === AlexishrPayoutPeriod.HOURLY,
    }),
  };
};

const mapAlexishrBaseSalary = (
  ctx: AppContext,
  params: {
    company: Company;
    compensation?: AlexishrCompensation;
    externalRemunerationStatus: ExternalRemunerationStatus;
    numberMonths: number;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, compensation, externalRemunerationStatus, numberMonths } = params;
  // Get Fixed salary
  if (!compensation?.amount) {
    return null;
  }

  const amount = compensation.amount;

  if (amount === 0) {
    return null;
  }

  const interval = compensation?.payoutPeriod;

  if (
    ![
      AlexishrPayoutPeriod.HOURLY,
      AlexishrPayoutPeriod.MONTHLY,
      AlexishrPayoutPeriod.QUARTERLY,
      AlexishrPayoutPeriod.ANNUAL,
    ].includes(interval)
  ) {
    logWarn(ctx, `[sync] Unhandled AlexisHR fix salary interval`, { interval });
  }

  const numberOfMonthMultiplier = numberMonths / 12;

  const multiplier = value(() => {
    if (interval === AlexishrPayoutPeriod.HOURLY) {
      return 40 * 52 * numberOfMonthMultiplier;
    }

    if (interval === AlexishrPayoutPeriod.MONTHLY) {
      return 12 * numberOfMonthMultiplier;
    }

    if (interval === AlexishrPayoutPeriod.QUARTERLY) {
      return 4 * numberOfMonthMultiplier;
    }

    return 1;
  });

  const yearlySalary = Math.round(amount * multiplier) * 100;

  const externalId = match(externalRemunerationStatus)
    .with(ExternalRemunerationStatus.LIVE, () => "fix-salary")
    .with(ExternalRemunerationStatus.HISTORICAL, () => `fix-salary-historical-${compensation.id}`)
    .exhaustive();

  return {
    source: ExternalEmployeeSource.ALEXISHR,
    externalId,
    amount: yearlySalary,
    status: externalRemunerationStatus,
    numberMonths,
    date: compensation?.effectiveDate ? parseISO(compensation.effectiveDate) : null,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: ExternalEmployeeSource.ALEXISHR,
            externalId: "fix-salary",
          },
        },
        create: {
          source: ExternalEmployeeSource.ALEXISHR,
          externalId: "fix-salary",
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  };
};

const anonymise = (employee: AlexishrEmployee): AlexishrEmployee => {
  delete employee.firstName;
  delete employee.lastName;
  delete employee.birthDate;
  delete employee.avatarUrl;
  delete employee.workEmail;
  delete employee.privateEmail;
  delete employee.workPhone;
  return employee;
};

export const getAlexishrEmployees = async (credentials: Credentials): Promise<CompleteAlexishrEmployee[]> => {
  const [employees, compensations, offices, employmentTypes] = await Promise.all([
    alexishrFetch<AlexishrEmployee>(credentials, "employee"),
    alexishrFetch<AlexishrCompensation>(credentials, "compensation"),
    alexishrFetch<AlexishrOffice>(credentials, "office"),
    alexishrFetch<AlexishrEmploymentType>(credentials, "employment-type"),
  ]);

  return employees
    .filter((employee) => employee.active)
    .map((employee) => (credentials.anonymous ? anonymise(employee) : employee))
    .map((employee) => {
      // Only one compensation can be active at one time, so we only pick the one with the highest effective date
      const compensation = chain(compensations)
        .filter((compensation) => compensation.employeeId === employee.id)
        .maxBy((compensation) => compensation.effectiveDate)
        .value();

      const historicalCompensations = chain(compensations)
        .filter((compensation) => compensation.employeeId === employee.id)
        .filter((historicalCompensation) => historicalCompensation.id !== compensation.id)
        .orderBy(["id", "documentVersion"], ["asc", "desc"])
        .uniqBy("id")
        .value();

      const office = offices.find((office) => office.id === employee.officeId);
      const employmentType = employmentTypes.find((type) => type.id === employee.employmentTypeId);
      return { ...employee, compensation, office, employmentType, historicalCompensations };
    });
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedAlexishrEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const alexishrEmployees = await getAlexishrEmployees(safeIntegrationSettings);

  return mapSeries(alexishrEmployees, (alexishrEmployee) =>
    mapAlexishrEmployee(ctx, company, alexishrEmployee, safeIntegrationSettings, staticModels, ignoreProfilePicture)
  );
};

export const getAlexishrDiagnostic = async (
  ctx: AppContext,
  input: AlexishrIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const employees = await alexishrFetch<AlexishrEmployee>(input, "employee", {
      query: { limit: 1 },
      paginate: false,
    });

    if (employees.length === 0) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your Alexishr HR account",
        missingFields: [],
        availableFields: [],
      };
    }

    const availableFields = chain(employees)
      .map((employee) =>
        chain(employee.custom)
          .pickBy((value) => isString(value) || isNumber(value))
          .keys()
          .value()
      )
      .flatten()
      .uniq()
      .map((field) => ({ id: field, name: field }))
      .value();

    const missingFields = getMissingCustomFields(input, availableFields);

    return {
      connection: true,
      connectionError: "",
      missingFields,
      availableFields,
    };
  } catch (error) {
    return {
      connection: false,
      connectionError: error.message,
      missingFields: [],
      availableFields: [],
    };
  }
};

export const getRawAlexishrEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<CompleteAlexishrEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getAlexishrEmployees(safeIntegrationSettings);
};
