import { type AppContext } from "~/lib/context";
import { applyUserAwareTFunction } from "~/lib/i18n/apply-user-aware-tfunction";
import { protectPrismaClient } from "~/lib/prisma";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { getActiveFeatureFlagsForUser } from "~/services/feature-flags";
import { getActivePermissionsForUser } from "~/services/permissions/validate-permissions";
import { getActiveProductsForUser } from "~/services/subscriptions/validate-subscription";

export const initContext = async (ctx: AppContext, user?: NullableAuthenticatedUser) => {
  if (user) {
    ctx.user = user;
  }

  // /!\ We need to compute FFs before protecting prisma
  //
  // /!\ Careful if prisma has already been protected,
  // /!\ feature flags will be generated with previous restrictions
  // /!\ Particular attention to companies included with feature flags
  // /!\ ADMINS_FROM_SELECTED_COMPANIES and ALL_USERS_FROM_SELECTED_COMPANIES scopes
  // /!\ will be tested with `previousUser.companyId === currentUser.companyId`
  ctx.featureFlags = await getActiveFeatureFlagsForUser(ctx);
  ctx.subscriptions = await getActiveProductsForUser(ctx);
  ctx.globalPermissionsContext = await getActivePermissionsForUser(ctx);
  ctx.prisma = await protectPrismaClient(ctx);
  applyUserAwareTFunction(ctx);

  return ctx;
};
