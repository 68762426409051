import React from "react";
import { BaseField, type BaseFieldProps } from "~/components/external-employee/drawer/fields/base-field";
import { Mode, useEditMode } from "~/components/external-employee/use-edit-mode";
import { useExternalEmployeeSource } from "~/components/external-employee/use-external-employee-source";
import { value as valueFn } from "~/components/helpers";
import { Icon } from "~/components/ui/core/Icon";
import { useI18n } from "~/lib/i18n/use-i18n";

type ExternalFieldProps = BaseFieldProps;

export const ExternalField: React.FC<ExternalFieldProps> = ({ label, value, ...rest }) => {
  const { t } = useI18n();
  const { mode } = useEditMode();
  const source = useExternalEmployeeSource();

  const adornment =
    source && source.icon ? <Icon name={source.icon} className={source ? "opacity-50 grayscale" : ""} /> : undefined;

  return (
    <BaseField
      {...rest}
      label={label}
      value={value}
      endAdornment={adornment}
      readonly={mode === Mode.DISPLAY || !!source}
      readonlyHelperText={valueFn(() => {
        if (!source) {
          return undefined;
        }

        if (source.isHRIS) {
          return t("components.external-employee-drawer.feedback.field-sync-with-integration", {
            source: source.name,
          });
        }

        if (source.isSpreadsheet) {
          return t("components.external-employee-drawer.feedback.field-coming-from-spreadsheet");
        }
      })}
    />
  );
};
