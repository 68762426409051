import { identity, isEmpty, pickBy } from "lodash";
import { type NextRouter } from "next/router";
import { type Route } from "nextjs-routes";
import { type ParsedUrlQueryInput, stringify } from "querystring";
import { type ExternalLink } from "~/components/ui/core/Link";
import { config } from "~/config";

export const addHttpsProtocolToUrl = (url: string | null) => {
  if (isEmpty(url)) {
    return null;
  }

  if (url?.match(/https:\/\//)) {
    return url;
  }

  return `https://${url}`;
};

export const buildFiguresUrl = (path?: string) => {
  const websiteUrl = config.app.websiteUrl;

  return `${websiteUrl}${path}` as ExternalLink;
};

export const buildExternalUrl = (path: string, query?: ParsedUrlQueryInput) => {
  // This removes any unwanted double slashes in the URL (https://stackoverflow.com/a/15638147)
  const cleanPath = path.replace(/([^:])(\/\/+)/g, "$1/");

  return !isEmpty(query) ? (`${cleanPath}?${stringify(query)}` as ExternalLink) : (cleanPath as ExternalLink);
};

export const currentUrlWithQueryParams = (router: NextRouter, params: ParsedUrlQueryInput) => {
  const mergedParams = {
    ...router.query,
    ...params,
  };

  // Removes falsy values
  const cleanParams = pickBy(mergedParams, identity);

  return { pathname: router.pathname, query: cleanParams } as Route;
};

export const hasValidHostname = (url: string | undefined, validHostnames: readonly string[]): boolean => {
  if (!url) {
    return false;
  }

  try {
    // Use the URL constructor to verify that the URL provided is a legit URL
    // It handles a lot of edge cases / tricks, don't try to do it yourself
    const { hostname } = new URL(url);
    const domainName = hostname.split(".").slice(-2).join(".");

    return validHostnames.includes(domainName);
  } catch (err) {
    return false;
  }
};
