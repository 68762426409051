import { chain } from "lodash";
import { type AppContext } from "~/lib/context";
import { partialSpreadsheet } from "~/lib/spreadsheet/templates";

export const partialImportColumnsMap = (ctx: AppContext) =>
  chain(partialSpreadsheet(ctx.t).availableColumns)
    .keyBy("key")
    .mapValues((value) => {
      return value.rsiField.label;
    })
    .value();
