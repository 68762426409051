import { type Prisma } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { roundTo } from "~/lib/math";
import { getDefaultCurrency } from "~/services/currency";
import { auditLogUpdateRange } from "~/services/salary-bands/audit-logs/update-range";
import { computeAmountPercentileRank } from "~/services/salary-bands/benchmark/compute-salary-range-percentile-rank";
import { fetchLiveEmployeesForBenchmark } from "~/services/salary-bands/benchmark/fetch-live-employees-for-benchmark";
import { buildSalaryRangeWhereClause } from "~/services/salary-bands/helpers/salary-grid-filters";
import { type BulkUpdateSalaryRangeBoundsInput } from "~/workers/update-salary-range-settings";

export const updateSalaryRangeBounds = async (ctx: AppContext, input: BulkUpdateSalaryRangeBoundsInput) => {
  const currency = await getDefaultCurrency(ctx);

  const filtersWhereClause = buildSalaryRangeWhereClause(ctx, { salaryGridId: input.salaryGridId }, input.filters);
  const ranges = await ctx.prisma.salaryRange.findMany({
    where: { ...filtersWhereClause, ...(!input.allRanges && { id: { in: input.salaryRangeIds } }) },
    select: rangeSelect,
  });

  await mapSeries(ranges, async (range) => {
    const midpoint = roundTo((input.min + input.max) / 2, 100);

    const employees = await fetchLiveEmployeesForBenchmark(ctx, {
      jobIds: range.band.benchmarkedJobs.map(({ job }) => job.id),
      locationIds: range.band.benchmarkedLocations.map(({ location }) => location.id),
      levels: range.level.benchmarkedLevels.map(({ level }) => level),
      marketPositioning: range.band.marketPositioning,
    });

    const inferredPercentileRank = computeAmountPercentileRank({
      amount: midpoint,
      currency,
      employees,
      measure: range.band.measure,
    });

    const data = {
      targetPercentileRank: null,
      inferredPercentileRank,
      midpoint,
      min: Math.round(input.min),
      max: Math.round(input.max),
    };
    await ctx.prisma.salaryRange.update({ where: { id: range.id }, data });

    await auditLogUpdateRange(ctx, {
      rangeId: range.id,
      bandId: range.band.id,
      gridId: input.salaryGridId,
      initialState: mapRangeToAuditLogState(range),
      newState: mapRangeToAuditLogState({ ...range, ...data }),
      reason: input.reason,
    });
  });
};

const rangeSelect = {
  band: {
    select: {
      benchmarkedJobs: { select: { job: { select: { id: true, familyId: true } } } },
      benchmarkedLocations: { select: { location: { select: { id: true } } } },
      id: true,
      job: { select: { id: true, name: true } },
      location: { select: { id: true, name: true } },
      marketPositioning: true,
      measure: true,
    },
  },
  id: true,
  inferredPercentileRank: true,
  level: { select: { benchmarkedLevels: { select: { level: true } }, id: true, name: true } },
  max: true,
  midpoint: true,
  min: true,
  targetPercentileRank: true,
} satisfies Prisma.SalaryRangeSelect;

type RangeData = Prisma.SalaryRangeGetPayload<{ select: typeof rangeSelect }>;

const mapRangeToAuditLogState = (range: RangeData) => {
  return {
    id: range.id,
    min: range.min,
    midpoint: range.midpoint,
    max: range.max,
    targetPercentileRank: range.targetPercentileRank,
    inferredPercentileRank: range.inferredPercentileRank,
    band: {
      id: range.band.id,
      job: range.band.job,
      location: range.band.location,
    },
    level: { id: range.level.id, name: range.level.name },
  };
};
