import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { boolean, number, object, string } from "yup";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/get-required-user";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { FETCH_COMPANY_EMPLOYEES_QUERY_KEY } from "~/pages/api/employee/fetch-company-employees";

export const EmployeeDataValidationSchema = object({
  id: number().required(),
  employeeId: number(),
  comment: string(),
  isLive: boolean(),
});

const handler: NextApiHandler = async (req, res) => {
  const user = getRequiredUser(req);

  const input = EmployeeDataValidationSchema.validateSync(req.body, {
    abortEarly: false,
  });

  await req.prisma.employeeDataValidationFlag.update({
    where: {
      id: input.id,
      ...(!user.isSuperAdmin && {
        employee: { companyId: user.companyId },
      }),
    },
    data: {
      employeeId: input.employeeId,
      comment: input.comment?.trim(),
      isLive: input.isLive,
      ...(!input.isLive && {
        closedAt: new Date(),
        closedById: user.id,
      }),
    },
  });

  res.status(HttpStatus.OK).json({
    message: "Employee Data Validation Flag has been updated",
  });
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessRawData: true },
});

export const useUpdateEmployeeDataValidationFlagMutation = createMutation<
  typeof handler,
  typeof EmployeeDataValidationSchema
>({
  path: "/api/employee-data-validation-flag/update-employee-data-validation-flag",
  schema: EmployeeDataValidationSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_COMPANY_EMPLOYEES_QUERY_KEY);
    },
  }),
});
