import { type Country, type EmployeeLocation } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { arrayHasValues, getId, type Predicate } from "~/lib/utils";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { fetchCompanyComparisonEmployeeLocations } from "~/services/locations/fetch-company-comparison-employee-locations";

// France
const GUEST_DEFAULT_COUNTRY_ALPHA2 = "FR";

export type EmployeeLocationWithCountry = EmployeeLocation & {
  country: Country;
};

export type BackofficeEmployeeLocation = AsyncReturnType<typeof getBackofficeEmployeeLocations>[number];

export const getBackofficeEmployeeLocations = async (ctx: AppContext) => {
  return ctx.prisma.employeeLocation.findMany({
    select: {
      id: true,
      name: true,
      isCountryCapital: true,
      fallbackLocationId: true,
      country: {
        select: {
          id: true,
          name: true,
        },
      },
    },
  });
};

export const getDefaultLocations = async (
  ctx: AppContext,
  options?: { hideFallbackedLocation?: boolean }
): Promise<EmployeeLocationWithCountry[]> => {
  const { user } = ctx;
  const hideFallbackedLocation = options?.hideFallbackedLocation ?? true;

  // For guest users or users without companies, return default guest locations.
  // We might want to do it based on IP geolocation in the future.
  if (!user?.company) {
    return ctx.prisma.employeeLocation.findMany({
      where: {
        country: {
          alpha2: GUEST_DEFAULT_COUNTRY_ALPHA2,
        },
        ...(hideFallbackedLocation && { fallbackLocationId: null }),
      },
      include: {
        country: true,
      },
    });
  }

  const companySpecificLocationIds = (await fetchCompanyComparisonEmployeeLocations(ctx)).map(getId);
  const companyAllowedLocationIds = getCompanyAllowedLocationIds(user);

  const locationIds = [...companySpecificLocationIds, ...(companyAllowedLocationIds ?? [])];

  // For users with allowed locations
  if (
    arrayHasValues(user.permissions.allowedCountries) ||
    arrayHasValues(user.company.companyBenchmarkRestriction?.allowedLocations)
  ) {
    const where = arrayHasValues(locationIds)
      ? {
          OR: [{ countryId: { in: user.permissions.allowedCountries.map(getId) } }, { id: { in: locationIds } }],
          ...(hideFallbackedLocation && { fallbackLocationId: null }),
        }
      : {
          countryId: { in: user.permissions.allowedCountries.map(getId) },
          ...(hideFallbackedLocation && { fallbackLocationId: null }),
        };

    return ctx.prisma.employeeLocation.findMany({
      where,
      include: {
        country: true,
      },
    });
  }

  const where =
    companySpecificLocationIds.length > 0
      ? {
          OR: [{ countryId: user.company.defaultCountryId }, { id: { in: companySpecificLocationIds } }],
          ...(hideFallbackedLocation && { fallbackLocationId: null }),
        }
      : {
          countryId: user.company.defaultCountryId,
          ...(hideFallbackedLocation && { fallbackLocationId: null }),
        };

  // For users with companies, return locations attached to their default country.
  return ctx.prisma.employeeLocation.findMany({
    where,
    include: {
      country: true,
    },
  });
};

export type GetDefaultLocationResult = AsyncReturnType<typeof getDefaultLocations>;

export const isRemote = (): Predicate<EmployeeLocation> => {
  return (location) => {
    return location.isRemote === true;
  };
};

export const isOther = (): Predicate<EmployeeLocation> => {
  return (location) => {
    return location.name === "Other";
  };
};

export const canAccessEmployeeLocation = (user: NullableAuthenticatedUser, location: EmployeeLocation) => {
  const allowedCountries = getAllowedCountryIds(user);

  if (allowedCountries === null) {
    return true;
  }

  return allowedCountries.includes(location.countryId);
};

export const getAllowedCountryIds = (user: NullableAuthenticatedUser) => {
  if (!user) {
    return null;
  }

  if (!user.permissions.allowedCountries.length) {
    return null;
  }

  return user.permissions.allowedCountries.map(getId);
};

export const getCompanyAllowedLocationIds = (user: NullableAuthenticatedUser) => {
  if (!user) {
    return null;
  }

  if (!user.company.companyBenchmarkRestriction) {
    return null;
  }

  return user.company.companyBenchmarkRestriction.allowedLocations.map(getId);
};
