import { Gender } from "@prisma/client";
import React from "react";
import { Button } from "~/components/ui/core/button";
import { GenderLabel } from "~/components/ui/gender-label";

type Event = {
  target: {
    name?: string;
    value: Gender;
  };
};

type Props = {
  name?: string;
  value: Gender | null;
  onChange: (event: Event) => void;
  disabled?: boolean;
};

export const ExpandedGenderSelector: React.FC<Props> = ({ name, value, onChange, disabled = false }) => {
  return (
    <div className="flex space-x-0.5">
      {Object.values(Gender).map((gender) => {
        return (
          <Button
            key={gender}
            variant={value === gender ? "outlined" : "contained"}
            className="!rounded"
            color={value === gender ? "primary" : "white"}
            onClick={() => {
              onChange({
                target: {
                  name,
                  value: gender,
                },
              });
            }}
            disabled={disabled}
          >
            <GenderLabel gender={gender} />
          </Button>
        );
      })}
    </div>
  );
};
