import { type Prisma } from "@prisma/client";
import { chain, concat } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { assertNotNil } from "~/lib/utils";
const externalLevelSelect = {
  _count: { select: { employees: true } },
  id: true,
  name: true,
  mappedLevel: true,
  skippedAt: true,
} satisfies Prisma.ExternalLevelSelect;

const externalJobSelect = {
  _count: { select: { employees: true } },
  id: true,
  name: true,
  skippedAt: true,
  autoMappedAt: true,
  mappedJobId: true,
  suggestionSkippedAt: true,
  suggestionConfidenceScore: true,
  mappedJob: {
    select: {
      id: true,
      name: true,
      description: true,
      descriptionTranslations: true,
      familyId: true,
      family: {
        select: {
          id: true,
          name: true,
        },
      },
    },
  },
  suggestedJob: {
    select: {
      id: true,
      name: true,
      description: true,
      descriptionTranslations: true,
      familyId: true,
    },
  },
  mappingSuggestions: {
    select: {
      suggestedJob: {
        select: {
          id: true,
          name: true,
          description: true,
          descriptionTranslations: true,
          familyId: true,
        },
      },
      similarityScore: true,
    },
  },
} satisfies Prisma.ExternalJobSelect;

const externalLocationSelect = {
  _count: { select: { employees: true } },
  id: true,
  name: true,
  skippedAt: true,
  country: { select: { id: true, name: true, alpha2: true } },
  mappedLocation: {
    select: {
      id: true,
      name: true,
      isRemote: true,
      country: {
        select: { id: true, name: true, alpha2: true },
      },
    },
  },
} satisfies Prisma.ExternalLocationSelect;

export const fetchExternalJobsForMapping = async (ctx: AppContext, params: { forLegacyAutomapping: boolean }) => {
  const user = getRequiredUser(ctx);

  const externalJobs = await ctx.prisma.externalJob.findMany({
    where: {
      companyId: user.companyId,
      employees: {
        some: {
          deletedAt: null,
        },
      },
    },
    select: externalJobSelect,
  });

  return externalJobs.map((externalJob) => {
    const suggestions = value(() => {
      if (!params.forLegacyAutomapping && ctx.featureFlags.AI_ASSISTED_JOB_MAPPING_ENABLED) {
        const suggestedJobs = chain(externalJob.mappingSuggestions)
          .map((suggestion) => ({
            job: suggestion.suggestedJob,
            jobConfidenceScore: suggestion.similarityScore,
          }))
          .orderBy("jobConfidenceScore", "desc")
          .value();

        const oldSuggestedJobShouldBeAdded =
          !!externalJob.suggestedJob &&
          !externalJob.mappingSuggestions.some((s) => s.suggestedJob.id === assertNotNil(externalJob.suggestedJob).id);

        if (oldSuggestedJobShouldBeAdded) {
          return concat(suggestedJobs, {
            job: assertNotNil(externalJob.suggestedJob),
            jobConfidenceScore: externalJob.suggestionConfidenceScore ?? 0,
          });
        }

        return suggestedJobs;
      }

      if (!!externalJob.mappedJob || !externalJob.suggestedJob) {
        return [];
      }

      return [
        {
          job: externalJob.suggestedJob,
          jobConfidenceScore: externalJob.suggestionConfidenceScore ?? 0,
        },
      ];
    });

    return {
      ...externalJob,
      suggestions,
    };
  });
};

export const fetchExternalLevelsForMapping = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalLevel.findMany({
    where: { companyId: user.companyId, employees: { some: {} } },
    select: externalLevelSelect,
  });
};

export const fetchExternalLocationsForMapping = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalLocation.findMany({
    where: { companyId: user.companyId, employees: { some: {} } },
    select: externalLocationSelect,
  });
};

export type ExternalLevelForMapping = AsyncReturnType<typeof fetchExternalLevelsForMapping>[number];
export type ExternalJobForMapping = AsyncReturnType<typeof fetchExternalJobsForMapping>[number];
export type ExternalLocationForMapping = AsyncReturnType<typeof fetchExternalLocationsForMapping>[number];
