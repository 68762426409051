import { readFileSync, writeFileSync } from "fs";
import { isArray, isObject, isPlainObject, map, mapValues } from "lodash";

/**
 * Called right after generating the Prisma client to patch the generated type definitions to replace `BigInt` with `number`.
 */
export const patchPrismaClientTypeDefinitions = () => {
  const CLIENT_PATH = `${__dirname}/../../node_modules/.prisma/client/index.d.ts`;

  const contents = readFileSync(CLIENT_PATH, "utf8");

  const patchedContents = contents
    .replaceAll(/bigint/g, "number")
    .replaceAll(/: BigIntFieldUpdateOperationsInput/g, ": IntFieldUpdateOperationsInput")
    .replaceAll(/BigIntFilter</g, "IntFilter<")
    .replaceAll(/BigIntWithAggregatesFilter</g, "IntWithAggregatesFilter<")
    .replaceAll(/'BigInt'>/g, "'Int'>");

  writeFileSync(CLIENT_PATH, patchedContents);
};

/**
 * Called by our Prisma proxy on every Prisma query response to convert Prisma's `BigInt` type to a `Number`.
 */
export const convertPrismaBigInts = (res: unknown) => {
  return mapValuesDeep(res, (value) => {
    if (typeof value === "bigint") {
      return Number(value);
    }

    return value;
  });
};

const mapValuesDeep = (
  object: unknown,
  fn: (value: unknown, key: unknown, baseObject: unknown) => unknown,
  key?: unknown,
  baseObject = object
): unknown => {
  if (isArray(object)) {
    return map(object, (innerObject, index) => mapValuesDeep(innerObject, fn, index));
  }

  if (isPlainObject(object)) {
    return mapValues(object as object, (value, key) => mapValuesDeep(value, fn, key, object));
  }

  if (isObject(object)) {
    return object;
  }

  return fn(object, key, baseObject);
};
