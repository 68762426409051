import { type Company, CompanyType, type UserPermissions } from "@prisma/client";
import { type AuthenticatedUser } from "~/lib/session";
import { getFiguresCompanyId } from "~/lib/utils";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";

export const FIGURES = {
  id: getFiguresCompanyId(),
  name: "Figures",
  type: CompanyType.PARTICIPANT,
  demo: false,
  excludedFromDataset: false,
} as const;

export type UserForSegment = Pick<
  AuthenticatedUser,
  "id" | "companyId" | "email" | "isSuperAdmin" | "onboardingRole"
> & {
  company: CompanyForSegment;
  permissions: Pick<UserPermissions, "role">;
};

export type CompanyForSegment = Pick<Company, "id" | "name" | "type" | "demo" | "excludedFromDataset">;

export const mapSegmentUser = (user?: NullableAuthenticatedUser) => {
  if (!user) {
    return null;
  }

  return {
    userPermissions: {
      role: user.permissions.role,
      canAccessMarketData: user.permissions.canAccessMarketData,
      isManager: user.permissions.isManager,
      allowedLevels: user.permissions.allowedLevels,
      allowedJobFamiliesIds: user.permissions.allowedJobFamilies.map((jobFamily) => jobFamily.id),
      allowedCountriesIds: user.permissions.allowedCountries.map((country) => country.id),
    },
  };
};

/**
 * / ! \ Do not change this method please!
 *
 * When a user logs in, it will have the trait `company` with their own company id and names
 * When they impersonate, the code below adds the trait `impersonatedCompany`
 * and changes the company trait to Figures.
 * Otherwise, if the user goes from impersonating one company to another,
 * Segment will keep the previous impersonated company in the user.companyId,
 * and we will send wrong information.
 */
export const mapSegmentUserTraits = (user: UserForSegment, { impersonating }: { impersonating: boolean }) => {
  if (impersonating) {
    return {
      id: user.id,
      email: user.email,
      impersonatedCompany: {
        id: user.companyId,
        name: user.company.name,
      },
      company: {
        id: FIGURES.id,
        name: FIGURES.name,
      },
      role: user.permissions.role,
      isSuperAdmin: user.isSuperAdmin,
    };
  }

  return {
    id: user.id,
    email: user.email,
    company: {
      id: user.companyId,
      name: user.company.name,
    },
    impersonatedCompany: undefined,
    role: user.permissions.role,
    onboardingRole: user.onboardingRole,
    isSuperAdmin: user.isSuperAdmin,
  };
};

export const mapSegmentGroupTraits = (user: UserForSegment, { impersonating }: { impersonating: boolean }) => {
  return impersonating
    ? FIGURES
    : {
        id: user.company.id,
        name: user.company.name,
        type: user.company.type,
        demo: user.company.demo,
        excludedFromDataset: user.company.excludedFromDataset,
      };
};
