import React from "react";
import { BaseField, type BaseFieldProps } from "~/components/external-employee/drawer/fields/base-field";
import { Mode, useEditMode } from "~/components/external-employee/use-edit-mode";

type PercentageFieldProps = BaseFieldProps & {
  value: number | undefined;
};

export const PercentageField: React.FC<PercentageFieldProps> = ({ value, readonly, ...rest }) => {
  const { mode } = useEditMode();

  return (
    <>
      <BaseField
        {...rest}
        value={value !== undefined ? value + "%" : "-"}
        readonly={mode === Mode.DISPLAY || readonly}
      />
    </>
  );
};
