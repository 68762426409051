import { SafetyCheck } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { SidePanel } from "~/components/ui/core/SidePanel";
import { Button } from "~/components/ui/core/button";
import { useForm } from "~/hooks/useForm";
import {
  type RequestTemporarySuperAdminRightsInput,
  RequestTemporarySuperAdminRightsSchema,
  useRequestTemporarySuperAdminRightsMutation,
} from "~/pages/api/user/request-temporary-super-admin-rights";
import { TEMPORARY_SUPER_ADMIN_RIGHTS_DURATION_IN_HOURS } from "~/services/user/request-temporary-super-admin-rights";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const RequestTemporarySuperAdminRightsPanel: React.FC<Props> = (props) => {
  const requestSuperAdminRights = useRequestTemporarySuperAdminRightsMutation({
    successMessage: "Super admin rights granted, have fun!",
  });

  const form = useForm<RequestTemporarySuperAdminRightsInput>({
    initialValues: {
      reason: "",
    },
    validateOnMount: true,
    validationSchema: RequestTemporarySuperAdminRightsSchema,
    onSubmit: async (values) => {
      return requestSuperAdminRights.mutateAsync(values);
    },
  });

  return (
    <SidePanel {...props} width="narrow" side="left" disableBottomPadding>
      <Stack className="h-full" direction="column" component="form" onSubmit={form.handleSubmit}>
        <Stack
          className="sticky top-0 z-20 h-16 shrink-0 items-center space-x-2 bg-primary-300 pl-6 pr-4 shadow"
          direction="row"
        >
          <SafetyCheck fontSize="medium" />
          <Typography variant="h2">Request Super Admin Rights</Typography>
        </Stack>

        <Stack className="p-4" spacing={2}>
          <Typography>Please explain quickly the reason for which you require super admin rights</Typography>

          <TextField
            multiline
            rows={4}
            placeholder="Why do you need those rights? (required)"
            value={form.values.reason}
            onKeyDown={(event) => event.stopPropagation()} // https://figures-hr.slack.com/archives/C05845FH6TB/p1718974198249509 🤷
            onChange={(event) => {
              void form.setFieldValue("reason", event.target.value);
            }}
          />

          <Typography variant="h4">
            Once requested, you will have super admin rights for {TEMPORARY_SUPER_ADMIN_RIGHTS_DURATION_IN_HOURS} hour
            {TEMPORARY_SUPER_ADMIN_RIGHTS_DURATION_IN_HOURS > 1 ? "s" : ""}.
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="end" spacing={4} className="px-4">
          <Button variant="outlined" color="secondary" onClick={props.onClose}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!form.isValid || form.isSubmitting}
            isLoading={requestSuperAdminRights.isLoading}
            onClick={form.submitForm}
          >
            Get those rights, now!
          </Button>
        </Stack>
      </Stack>
    </SidePanel>
  );
};
