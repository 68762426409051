import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type GetActiveFlagByEmployeeIdInput } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
export const getActiveFlagByEmployeeId = async (ctx: AppContext, input: GetActiveFlagByEmployeeIdInput) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.employeeDataValidationFlag.findFirst({
    where: {
      isLive: true,
      employeeId: input.employeeId,
      ...(!user.isSuperAdmin && {
        companyId: user.companyId,
      }),
    },
    select: {
      id: true,
      comment: true,
      companyId: true,
      isLive: true,
      employeeId: true,
      type: true,
      origin: true,
    },
  });
};

export type GetActiveFlagByEmployeeIdResult = AsyncReturnType<typeof getActiveFlagByEmployeeId>;
