import { type Company, ExternalEmployeeSource, ExternalRemunerationStatus } from "@prisma/client";
import { chain, isNumber } from "lodash";
import { parseISO } from "~/lib/dates";
import { type CustomSalary, type LuccaEmployee, type LuccaUser } from "~/lib/hris/lucca";
import { getId } from "~/lib/utils";
import { type EmployeeData } from "~/services/synchronization/sync-external-employees";

type SalaryType = {
  id: number;
  name: string;
};

export const getSalaryItem = (user: LuccaEmployee) => user.extendedData?.e_salary || user.extendedData?.e_Salary;
export const getSalaryType = (item: CustomSalary) => (item.value.e_salary_type || item.value.e_Salary_type)?.value ?? 0;
export const getSalaryDate = (item: CustomSalary) =>
  (item.value.e_salary_date || item.value.e_Salary_date)?.value ?? "";
export const getSalaryAmount = (item: CustomSalary) => {
  const value = (item.value.e_salary_amount || item.value.e_Salary_amount)?.value;

  if (isNumber(value)) {
    return value;
  }

  return parseInt(value?.replace(/\s/g, "") ?? "0");
};

const getLatestCurrentItem = (salaryTypeIds: number[] = [], salaryItems?: CustomSalary[]) => {
  return chain(salaryItems)
    .filter((item) => !!getSalaryType(item) && !!getSalaryDate(item) && !!getSalaryAmount(item))
    .filter((item) => salaryTypeIds.includes(getSalaryType(item)))
    .maxBy((item) => getSalaryDate(item))
    .value();
};

const getHistoricalItems = (
  salaryTypeIds: number[] = [],
  salaryItems?: CustomSalary[],
  latestCurrentItem?: CustomSalary
) => {
  return chain(salaryItems)
    .filter((item) => !!getSalaryType(item) && !!getSalaryDate(item) && !!getSalaryAmount(item))
    .filter((item) => salaryTypeIds.includes(getSalaryType(item)))
    .filter((item) => !!latestCurrentItem && item.id !== latestCurrentItem.id)
    .value();
};

const withNames = (names: string[]) => {
  return (salaryType: SalaryType) => names.includes(salaryType.name);
};

export const getSalariesFromDossierRh = (users: LuccaEmployee[], salaryTypes: SalaryType[]): LuccaUser[] => {
  const fixedSalaryTypeIds = salaryTypes
    .filter(withNames(["Base brute annuelle", "Gross annual base", "Gross annual basis"]))
    .map(getId);
  const variableBonusTypeIds = salaryTypes.filter(withNames(["Vide", "Variable", "Commission"])).map(getId);
  const fixedBonusTypeIds = salaryTypes.filter(withNames(["Prime", "Bonus"])).map(getId);

  return users.map((user) => {
    const fixedSalaryFromDossierRh = getLatestCurrentItem(fixedSalaryTypeIds, getSalaryItem(user));
    const historicalFixedSalariesFromDossierRh = getHistoricalItems(fixedSalaryTypeIds, getSalaryItem(user));
    const variableBonusesFromDossierRh = getLatestCurrentItem(variableBonusTypeIds, getSalaryItem(user));
    const fixedBonusesFromDossierRh = getLatestCurrentItem(fixedBonusTypeIds, getSalaryItem(user));

    return {
      ...user,
      fixedSalariesFromDossierRh: [fixedSalaryFromDossierRh].filter(Boolean),
      historicalFixedSalariesFromDossierRh: historicalFixedSalariesFromDossierRh.filter(Boolean),
      variableBonusesFromDossierRh: [variableBonusesFromDossierRh].filter(Boolean),
      fixedBonusesFromDossierRh: [fixedBonusesFromDossierRh].filter(Boolean),
    };
  });
};

export const mapDossierRhFixedSalary = (company: Company, numberMonths: number, status: ExternalRemunerationStatus) => {
  return (item: CustomSalary): EmployeeData["remunerationItems"][number] => {
    const prefix = status === ExternalRemunerationStatus.HISTORICAL ? "historical-" : "";

    return {
      source: ExternalEmployeeSource.LUCCA,
      externalId: `${prefix}${item.id.toString()}`,
      amount: getSalaryAmount(item) * 100,
      date: parseISO(getSalaryDate(item)),
      status,
      numberMonths,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.LUCCA,
              externalId: `fixed-salary`,
            },
          },
          create: {
            source: ExternalEmployeeSource.LUCCA,
            externalId: "fixed-salary",
            name: getSalaryType(item).toString(),
            mappedType: "FIXED_SALARY",
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    };
  };
};

export const mapDossierRhVariableBonus = (company: Company) => {
  return (item: CustomSalary): EmployeeData["remunerationItems"][number] => {
    return {
      source: ExternalEmployeeSource.LUCCA,
      externalId: item.id.toString(),
      amount: getSalaryAmount(item) * 100,
      date: parseISO(getSalaryDate(item)),
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.LUCCA,
              externalId: `variable-bonus`,
            },
          },
          create: {
            source: ExternalEmployeeSource.LUCCA,
            externalId: `variable-bonus`,
            name: getSalaryType(item).toString(),
            mappedType: "VARIABLE_BONUS",
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    };
  };
};

export const mapDossierRhFixedBonus = (company: Company) => {
  return (item: CustomSalary): EmployeeData["remunerationItems"][number] => {
    return {
      source: ExternalEmployeeSource.LUCCA,
      externalId: item.id.toString(),
      amount: getSalaryAmount(item) * 100,
      date: parseISO(getSalaryDate(item)),
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.LUCCA,
              externalId: `fixed-bonus`,
            },
          },
          create: {
            source: ExternalEmployeeSource.LUCCA,
            externalId: `fixed-bonus`,
            name: getSalaryType(item).toString(),
            mappedType: "FIXED_BONUS",
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    };
  };
};
