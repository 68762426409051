import { ExternalEmployeeStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { chain, compact } from "lodash";
import { assertNotNil } from "~/lib/utils";
import {
  createCompensationReviewEmployee,
  externalEmployeeSelectForCompensationReviewEmployeeCreation,
} from "~/services/compensation-review/campaigns/admin/create-compensation-review-employee";
import { refreshCampaignCurrencies } from "~/services/compensation-review/campaigns/admin/refresh-campaign-currencies";
import { type CompensationReviewContext } from "~/services/compensation-review/compensation-review-context";
import { findOrCreatePerformanceReviewCycle } from "~/services/performance-review/find-or-create-performance-review-cycle";

export const createCampaignEmployees = async (ctx: CompensationReviewContext) => {
  const campaign = assertNotNil(ctx.campaign);

  const externalEmployees = await ctx.prisma.externalEmployee.findMany({
    orderBy: { id: "asc" },
    where: {
      companyId: campaign.companyId,
      status: { not: ExternalEmployeeStatus.SKIPPED },
    },
    select: externalEmployeeSelectForCompensationReviewEmployeeCreation,
  });

  const { compensationReviewCurrencies } = await refreshCampaignCurrencies(ctx, { campaign });

  const performanceCycle = await findOrCreatePerformanceReviewCycle(ctx, {
    companyId: campaign.companyId,
    performanceReviewScope: { compensationReviewCampaignId: campaign.id },
  });

  const employeesPerformanceRatings = chain(externalEmployees)
    .filter((externalEmployee) => !!externalEmployee.performanceReviewRating)
    .map((externalEmployee) => externalEmployee.performanceReviewRating)
    .uniqBy((rating) => rating?.name)
    .orderBy((rating) => rating?.position)
    .value();

  const performanceRatings = await mapSeries(employeesPerformanceRatings, async (performanceRating) => {
    if (!performanceRating) return;

    return ctx.prisma.performanceReviewRating.upsert({
      where: {
        performanceReviewCycleId_name: {
          performanceReviewCycleId: performanceCycle.id,
          name: performanceRating.name,
        },
      },
      create: {
        companyId: campaign.companyId,
        name: performanceRating.name,
        performanceReviewCycleId: performanceCycle.id,
        position: performanceRating.position,
      },
      update: {},
    });
  });

  await mapSeries(externalEmployees, async (externalEmployee) => {
    const currency = assertNotNil(
      compensationReviewCurrencies.find((currency) => currency.currencyId === externalEmployee.currency.id)
    );

    return createCompensationReviewEmployee(ctx, {
      externalEmployee,
      compensationReviewCurrencyId: currency.id,
      performanceRatings: compact(performanceRatings),
    });
  });
};
