import { OffCycleReviewRequestReason } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { date, mixed, number, object, string } from "yup";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { FETCH_EXTERNAL_EMPLOYEE_OFF_CYCLE_REVIEW_REQUESTS_KEY } from "~/pages/api/compensation-review/off-cycle-reviews/fetch-external-employee-off-cycle-review-requests";
import { requestOffCycleReview } from "~/services/compensation-review/off-cycle-reviews/request-off-cycle-review";

export const RequestOffCycleReviewSchema = object({
  externalEmployeeId: number().required(),
  reason: mixed<OffCycleReviewRequestReason>().oneOf(Object.values(OffCycleReviewRequestReason)).required(),
  targetRangeId: number().nullable(),
  effectiveDate: date().required(),
  comment: string().required(),
}).noUnknown();

export type RequestOffCycleReviewInput = YupOutputType<typeof RequestOffCycleReviewSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = RequestOffCycleReviewSchema.validateSync(req.body, { abortEarly: false });

  await requestOffCycleReview(req, input);

  return res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessOffCycleReviews: true },
});

export const useRequestOffCycleReviewMutation = createMutation<typeof handler, typeof RequestOffCycleReviewSchema>({
  path: "/api/compensation-review/off-cycle-reviews/request-off-cycle-review",
  schema: RequestOffCycleReviewSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, FETCH_EXTERNAL_EMPLOYEE_OFF_CYCLE_REVIEW_REQUESTS_KEY);
    },
  }),
});
