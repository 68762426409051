import { type AppContext } from "~/lib/context";
import { logError, logWarn } from "~/lib/logger";

type Field = {
  id: string;
  value: string | number;
  error: string;
  meta?: Record<string, string | number>;
};

export type ApiErrorResponse = {
  error: string;
  fields?: Field[];
};

export class ApiError extends Error {
  error: string;
  fields?: Field[];

  constructor(error: string, fields?: Field[]) {
    super("API error");

    this.error = error;
    this.fields = fields;

    Object.setPrototypeOf(this, ApiError.prototype);
  }

  static fromRawError(rawError: unknown): ApiError | null {
    if ("error" in (rawError as ApiErrorResponse)) {
      const { error, fields } = rawError as ApiErrorResponse;
      return new ApiError(error, fields);
    }

    return null;
  }
}

// Throw this error on kombo 503 timeout
// And catch it in sync external employees to avoid deactivation of the integration
export class KomboTimeoutError extends Error {
  error: string;

  constructor(error: string) {
    super(error);

    this.error = error;

    Object.setPrototypeOf(this, KomboTimeoutError.prototype);
  }
}

export const handleProcessErrors = (ctx: AppContext): void => {
  process.on("unhandledRejection", (error) => {
    logError(ctx, "[api] Unhandled Rejection", { error });
  });

  process.on("uncaughtException", (error) => {
    logError(ctx, "[api] Uncaught exception", { error });
  });

  process.on("warning", (error) => {
    logWarn(ctx, "[api] Process warning", { error });
  });
};
