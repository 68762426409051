import { UserRole } from "@prisma/client";
import { Action, createAbility, Subject } from "~/services/user/casl-permissions";
import { type PermissionsParams } from "~/services/user/permissions/authentication-options";

export const canManageUsers = (params: PermissionsParams): boolean => {
  const { user } = params;

  return createAbility(user, params).can(Action.Update, Subject.Users);
};

export const canImpersonateUsers = (params: PermissionsParams): boolean => {
  const { user } = params;

  return createAbility(user, params).can(Action.Access, Subject.UserImpersonation);
};

export const canSetupSSO = (params: PermissionsParams): boolean => {
  const { subscriptions, user, featureFlags } = params;

  if (!user || !subscriptions) {
    return false;
  }

  if (!featureFlags?.CAN_ACCESS_SAML_SSO) {
    return false;
  }

  if (user.permissions.canSetupSSO) {
    return true;
  }

  if (user.permissions.role === UserRole.ADMIN) {
    return true;
  }

  return false;
};
