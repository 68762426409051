import { CompensationFrequency, ExternalRemunerationStatus, type Prisma } from "@prisma/client";
import slugify from "slugify";
import { match } from "ts-pattern";
import {
  type CustomRemunerationItemForSync,
  type IntegrationSettingsForSync,
} from "~/services/synchronization/sync-external-employees";

type EnrichedCustomRemunerationItem = CustomRemunerationItemForSync & {
  value?: string | null;
};

export const mapCustomRemunerationItem = (
  integrationSettings: IntegrationSettingsForSync,
  item: EnrichedCustomRemunerationItem
) => {
  if (!item.value) {
    return null;
  }

  const multiplier = match(item.frequency)
    .with(CompensationFrequency.MONTHLY, () => 12)
    .with(CompensationFrequency.QUARTERLY, () => 4)
    .with(CompensationFrequency.YEARLY, () => 1)
    .exhaustive();

  const amount = Math.round(parseInt(item.value) * multiplier);

  if (!amount) {
    return null;
  }

  const externalId = `custom-${slugify(item.name).toLowerCase()}`;

  return {
    source: integrationSettings.source,
    externalId,
    amount: Math.round(amount * 100),
    status: ExternalRemunerationStatus.LIVE,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: integrationSettings.companyId,
            source: integrationSettings.source,
            externalId,
          },
        },
        create: {
          source: integrationSettings.source,
          externalId,
          name: item.name,
          mappedType: item.type,
          mappedSubType: item.subtype,
          company: {
            connect: {
              id: integrationSettings.companyId,
            },
          },
        },
      },
    },
  } satisfies Prisma.ExternalRemunerationItemCreateWithoutEmployeeInput;
};
