import { type ExternalRemunerationType } from "@prisma/client";
import { chain } from "lodash";
import { type RemunerationItemWithNature } from "~/services/employee/employee-mapping";

export const sumRemunerationItems = (
  remunerationItems: RemunerationItemWithNature[],
  externalRemunerationType: ExternalRemunerationType
): number => {
  return chain(remunerationItems)
    .filter(({ nature }) => nature.mappedType === externalRemunerationType)
    .sumBy(({ amount }) => amount)
    .value();
};
