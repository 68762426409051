import { RemappingRequestStatus } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type UpdateRemappingRequestInput } from "~/pages/api/remapping-request/update-remapping-request";
import { updateMapping } from "~/services/mapping/update-mapping";
export const updateRemappingRequest = async (ctx: AppContext, input: UpdateRemappingRequestInput) => {
  const user = getRequiredUser(ctx);

  await ctx.prisma.remappingRequest.updateMany({
    where: { id: { in: input.ids }, companyId: user.companyId },
    data: {
      status: input.status,
      updatedById: user.id,
    },
  });

  const remappingRequests = await ctx.prisma.remappingRequest.findMany({
    where: { id: { in: input.ids }, companyId: user.companyId },
  });

  if (input.status === RemappingRequestStatus.ACCEPTED) {
    const jobMappings = remappingRequests.map((remappingRequest) => ({
      externalJobId: remappingRequest.externalJobId,
      mappedJobId: remappingRequest.newJobId,
    }));

    await updateMapping(ctx, {
      companyId: user.companyId,
      externalJobs: jobMappings,
      externalLevels: [],
      externalLocations: [],
    });
  }

  return { success: true };
};

export type UpdateRemappingRequestResult = AsyncReturnType<typeof updateRemappingRequest>;
