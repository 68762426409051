/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  EmployeeMappingSkipReason,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  Gender,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { add, isAfter, parseISO } from "date-fns";
import { type ParsedUrlQueryInput } from "querystring";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { getEmployeeProfilePicture } from "~/lib/hris/helpers/get-employee-profile-picture";
import { getNumberOfMonth } from "~/lib/hris/helpers/get-number-of-month";
import { type IntegrationConfig, type SourceConfig, type StaticModels } from "~/lib/integration";
import { logError, logWarn } from "~/lib/logger";
import { buildExternalUrl } from "~/lib/url";
import { assertProps } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetch-company-integration-diagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/sync-external-employees";

const base: IntegrationConfig = {
  source: "SALARYDK",
  companyId: 0,
  domain: null,
  clientId: null,
  clientSecret: config.salarydk.clientSecret,
  anonymous: false,
  enabled: true,
  retryCount: 0,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  variableCustomFieldName: null,
  externalIdCustomFieldName: null,
  variableCustomFieldFrequency: null,
  businessUnitCustomFieldName: null,
};

export const salarydkConfigs: SourceConfig = {
  default: base,
  anonymous: { ...base, anonymous: true },
};

type Credentials = {
  clientSecret: string;
  anonymous: boolean;
};

export type SalarydkEmployee = {
  id: string;
  profileImageURL?: string;
  name?: string;
  gender: "Female" | "Male" | "Unknown";
  employmentStatus: "Employed" | "New" | "Terminated" | "On Leave";
  companyID: string;
  country: string;
  email?: string;
  productionUnitID?: string;
  affiliationType: "Freelancer" | "Standard" | "MajorityShareholder" | "Director";
  activeEmployment?: {
    employeeNumber: string;
    startDate: string;
  };
  activeContract?: {
    cachedSalaryAmount?: number;
    position?: string;
    employmentPositionID?: string;
    salaryCycleID?: string;
  };
};

export type SalarydkSalary = {
  id: string;
  salaryTypeID: string;
  rate: number;
};

export type SalarydkEmployeeContracts = {
  id: string;
  employeeID: string;
  remuneration: {
    salary: SalarydkSalary[];
  };
};

export type SalarydkSalaryTypes = {
  id: string;
  class: "Hourly" | "Fixed" | "SupplementVaried" | "Supplement";
  name: string;
};

export type SalarydkSalaryCycles = {
  id: string;
  frequency: "Monthly" | "Weekly" | "BiWeekly";
};

export type SalarydkCompany = {
  id: string;
  productionUnits?: [
    {
      id: string;
      name: string;
    },
  ];
};

export type CompleteSalarydkEmployee = SalarydkEmployee & {
  salaries?: {
    id: string;
    salaryTypeID: string;
    rate: number;
    salaryType: SalarydkSalaryTypes | undefined;
  }[];
  salaryCycle?: SalarydkSalaryCycles;
  location?: {
    id: string;
    name: string;
  };
};

type SalarydkReportResponse<
  T extends SalarydkEmployee | SalarydkSalaryCycles | SalarydkCompany | SalarydkSalaryTypes | SalarydkEmployeeContracts,
> = {
  pagination: { count: number };
  data: T[];
};

export type SalarydkIntegrationSettingsInput = Credentials;

const authorization: Record<string, { accessToken: string; expiresAt: Date } | null> = {};

const baseUrl = (credentials: Credentials) =>
  credentials.clientSecret === config.salarydk.clientSecret ? "api-staging.sallysalary.dk" : "api.salary.dk";

const authenticate = async (credentials: Credentials): Promise<string> => {
  const res = await fetch(`https://${baseUrl(credentials)}/v2/auth`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      apiClientID: config.salarydk.appCredentials.clientId,
      apiClientSecret: config.salarydk.appCredentials.clientSecret,
      apiKey: credentials.clientSecret,
    }),
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(`[salarydk] ${res.status} ${res.statusText}`);
  }
  return json.data?.accessToken as string;
};

const salarydkFetch = async <
  T extends SalarydkEmployee | SalarydkSalaryCycles | SalarydkCompany | SalarydkSalaryTypes | SalarydkEmployeeContracts,
>(
  credentials: Credentials,
  endpoint: string,
  {
    method = "GET",
    query,
  }: {
    method?: "POST" | "GET";
    query?: ParsedUrlQueryInput;
  } = {}
): Promise<T[]> => {
  const url = `https://${baseUrl(credentials)}/v2/${endpoint}`;

  const needsRefresh = value(() => {
    const authInfo = authorization[config.salarydk.appCredentials.clientId];
    if (!authInfo) {
      return true;
    }
    if (isAfter(new Date(), authInfo.expiresAt)) {
      return true;
    }
    return false;
  });

  if (needsRefresh) {
    const accessToken = await authenticate(credentials);
    const expiresAt = add(new Date(), { hours: 1 });

    authorization[config.salarydk.appCredentials.clientId] = {
      accessToken: accessToken,
      expiresAt,
    };
  }

  const res = await fetch(buildExternalUrl(url, { ...query }), {
    method,
    headers: {
      "Accept": "application/json",
      "Authorization": authorization[config.salarydk.appCredentials.clientId]?.accessToken as string,
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    throw new Error(`[salarydk] ${res.status} ${res.statusText} : ${await res.text()}`);
  }

  const json: SalarydkReportResponse<T> = await res.json();

  return json.data;
};

const getSalarydkProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: CompleteSalarydkEmployee }
) => {
  if (!options.apiEmployee.profileImageURL) {
    return undefined;
  }

  return getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id,
    source: "SALARYDK",
    integrationSettings: options.integrationSettings,
    fetch: () => fetch(options.apiEmployee.profileImageURL as string),
  });
};

export const mapSalarydkEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: CompleteSalarydkEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  const countryCode = apiEmployee.country;

  const country = value(() => {
    if (!countryCode) {
      return null;
    }
    return staticModels.countries.find((country) => country.alpha2 === countryCode);
  });

  const [firstName, lastName] = apiEmployee.name ? apiEmployee.name.split(" ", 2) : "";

  const currency = "DKK";

  const jobExternalId = apiEmployee.activeContract?.employmentPositionID
    ? apiEmployee.activeContract?.employmentPositionID
    : apiEmployee.activeContract?.position;

  // If the employee is not a permanent, we create them with a SKIPPED status
  const isAutoSkipped = apiEmployee.affiliationType === "Freelancer";

  const input: EmployeeData["input"] = {
    source: "SALARYDK",
    externalId: apiEmployee.id,
    status: isAutoSkipped ? ExternalEmployeeStatus.SKIPPED : ExternalEmployeeStatus.UNMAPPED,
    mappingSkipReason: isAutoSkipped ? EmployeeMappingSkipReason.NOT_PERMANENT_EMPLOYEE : null,
    firstName: firstName,
    lastName: lastName,
    employeeNumber: apiEmployee.activeEmployment ? apiEmployee.activeEmployment.employeeNumber : apiEmployee.id,
    gender: value(() => {
      if (!apiEmployee.gender) {
        return null;
      }
      if (apiEmployee.gender === "Female") {
        return Gender.FEMALE;
      }
      if (apiEmployee.gender === "Male") {
        return Gender.MALE;
      }
      return Gender.UNDISCLOSED;
    }),
    hireDate: apiEmployee.activeEmployment ? parseISO(apiEmployee.activeEmployment?.startDate) : null,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency },
    },
    ...(apiEmployee.location && {
      location: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: apiEmployee.location.id,
            },
          },
          create: {
            externalId: apiEmployee.location.id,
            name: apiEmployee.location.name,
            autoMappingEnabled: true,
            company: {
              connect: { id: company.id },
            },
            ...(country && { country: { connect: { id: country.id } } }),
          },
        },
      },
    }),

    ...(apiEmployee.activeContract?.position &&
      jobExternalId && {
        job: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: jobExternalId,
              },
            },
            create: {
              name: apiEmployee.activeContract.position,
              externalId: jobExternalId,
              company: {
                connect: { id: company.id },
              },
            },
          },
        },
      }),
  };

  const remunerationItems: EmployeeData["remunerationItems"] = value(() => {
    if (!apiEmployee.salaries) {
      return [];
    }

    const interval = apiEmployee.salaryCycle?.frequency;

    if (interval && interval !== "Monthly") {
      logWarn(ctx, `[sync] Unhandled salaryDK fix salary interval`, { interval });
    }

    const numberMonths = getNumberOfMonth({
      externalId: apiEmployee?.location?.id,
      additionalMonthRules: staticModels.additionalMonthRules,
      externalLocations: staticModels.externalLocations,
    });

    const multiplier = interval === "Monthly" ? numberMonths : 1;

    return apiEmployee.salaries.map((salary) => {
      const amount = salary.rate;
      const yearlySalary = Math.round(amount * multiplier) * 100;

      return {
        source: "SALARYDK",
        externalId: `${salary.salaryType?.class.toLowerCase()} salary`,
        amount: yearlySalary,
        numberMonths,
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: company.id,
                source: "SALARYDK",
                externalId: `${salary.salaryType?.class.toLowerCase()} salary`,
              },
            },
            create: {
              source: "SALARYDK",
              externalId: `${salary.salaryType?.class.toLowerCase()} salary`,
              name: `${salary.salaryType?.name.toLowerCase()} salary`,
              mappedType: salary.salaryType?.class === "Fixed" ? "FIXED_SALARY" : "VARIABLE_BONUS",
              company: {
                connect: {
                  id: company.id,
                },
              },
            },
          },
        },
      };
    });
  });

  return {
    input,
    picturePath: !ignoreProfilePicture
      ? await getSalarydkProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
  };
};

const anonymise = (credentials: Credentials) => {
  return (employee: SalarydkEmployee): SalarydkEmployee => {
    if (credentials.anonymous) {
      delete employee.name;
      delete employee.profileImageURL;
      delete employee.email;
    }
    return employee;
  };
};

export const getSalarydkEmployees = async (ctx: AppContext, credentials: Credentials, filtered = true) => {
  // Get all relevant data from SalaryDK API
  const companies = await salarydkFetch<SalarydkCompany>(credentials, "companies");
  const companyID = companies[0]?.id;
  if (!companyID) {
    logError(ctx, "[salarydk] Error when try to find company id");
    return [];
  }
  const [employees, employeeContracts, salaryTypes, salaryCycles] = await Promise.all([
    salarydkFetch<SalarydkEmployee>(credentials, "employees", { query: { companyID } }),
    salarydkFetch<SalarydkEmployeeContracts>(credentials, "employeeContracts", {
      query: { companyID },
    }),
    salarydkFetch<SalarydkSalaryTypes>(credentials, "salaryTypes", { query: { companyID } }),
    salarydkFetch<SalarydkSalaryCycles>(credentials, "salaryCycles"),
  ]);

  if (!filtered) {
    return employees;
  }
  return (
    employees
      // Exclude inactive
      .filter((employee) => employee.employmentStatus !== "Terminated")
      .map(anonymise(credentials))
      .map((employee) => {
        const employeeContract = employeeContracts.find((contract) => contract.employeeID === employee.id);

        const salaries = employeeContract?.remuneration.salary.map((salary) => {
          const salaryType = salaryTypes.find((salaryType) => salary.salaryTypeID === salaryType.id);
          return { ...salary, salaryType };
        });

        // This is current base salary
        const salaryCycle = salaryCycles.find(
          (salaryCycle) => salaryCycle.id === employee.activeContract?.salaryCycleID
        );

        const company = companies.find((company) => company.id === employee.companyID);

        const location = company?.productionUnits?.find(
          (productionUnit) => productionUnit.id === employee.productionUnitID
        );
        return {
          ...employee,
          salaries,
          salaryCycle,
          location,
        };
      })
  );
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedSalarydkEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const salarydkEmployees = await getSalarydkEmployees(ctx, safeIntegrationSettings);

  return mapSeries(salarydkEmployees, (salarydkEmployee) =>
    mapSalarydkEmployee(ctx, company, salarydkEmployee, safeIntegrationSettings, staticModels, ignoreProfilePicture)
  );
};

export const getSalarydkDiagnostic = async (
  ctx: AppContext,
  input: SalarydkIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const [employee] = await getSalarydkEmployees(ctx, input, false);

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your SalaryDK account",
        missingFields: [],
        availableFields: [],
      };
    }
    return {
      connection: true,
      connectionError: "",
      missingFields: [],
      availableFields: [],
    };
  } catch (error) {
    return {
      connection: false,
      connectionError: error.message,
      missingFields: [],
      availableFields: [],
    };
  }
};

export const getRawSalarydkEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<SalarydkEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getSalarydkEmployees(ctx, safeIntegrationSettings);
};
