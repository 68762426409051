import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { DataValidationFlagType } from "@prisma/client";
import React, { useMemo, useState } from "react";
import { Modal, type ModalAction } from "~/components/ui/core/Modal";
import { type SelectAChoiceModal } from "~/components/ui/core/SelectAChoiceModal";
import { TextareaInput } from "~/components/ui/core/TextareaInput";
import { useI18n } from "~/lib/i18n/use-i18n";
import { useRefreshServerProps } from "~/lib/utils";
import { useGetActiveFlagByEmployeeId } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { useUpdateEmployeeDataValidationFlagMutation } from "~/pages/api/employee-data-validation-flag/update-employee-data-validation-flag";
import { type GetActiveFlagByEmployeeIdResult } from "~/services/employee-data-validation-flag/get-employee-data-validation-flag-by-id";

type Props = {
  initialFlag?: GetActiveFlagByEmployeeIdResult | null;
  employeeIds: number[];
  companyId?: number;
  onSubmit: (employeeIds: number[], comment: string, type: DataValidationFlagType, companyId?: number) => void;
} & Pick<React.ComponentProps<typeof SelectAChoiceModal>, "isOpen" | "onClose">;

type DataValidationOptions = {
  label: string;
  value: DataValidationFlagType;
}[];

export const ReportEmployeeDataModal: React.FC<Props> = ({
  employeeIds,
  companyId,
  initialFlag,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useI18n();
  const { data: flag } = useGetActiveFlagByEmployeeId({
    options: {
      initialData: initialFlag,
      enabled: isOpen && !!initialFlag,
    },
    input: {
      employeeId: employeeIds[0] ? employeeIds[0] : initialFlag?.employeeId,
    },
  });

  //TODO can be removed once employee lists is migrated to react-query
  const refreshServerProps = useRefreshServerProps();

  const [comment, setComment] = useState(flag?.comment ?? "");
  const [type, setType] = useState<DataValidationFlagType>(flag?.type ?? ("placeholder" as DataValidationFlagType));
  const disabled = comment === "" || employeeIds.length === 0 || type === ("placeholder" as DataValidationFlagType);
  const updateEmployeeDataValidationFlag = useUpdateEmployeeDataValidationFlagMutation({
    successMessage: t("components.employee-data-validation-flag.report-employee-data-modal.flag-updated-successfully"),
  });

  const types: DataValidationOptions = [
    {
      label: t("components.employee-data-validation-flag.report-employee-data-modal.unusual-compensation"),
      value: DataValidationFlagType.SUSPICIOUS_COMPENSATION,
    },
    {
      label: t("components.employee-data-validation-flag.report-employee-data-modal.unusual-job-title"),
      value: DataValidationFlagType.SUSPICIOUS_JOB_TITLE,
    },
    {
      label: t("components.employee-data-validation-flag.report-employee-data-modal.unusual-level"),
      value: DataValidationFlagType.SUSPICIOUS_LEVEL,
    },
    {
      label: t("components.employee-data-validation-flag.report-employee-data-modal.other"),
      value: DataValidationFlagType.OTHER,
    },
  ];

  const resetModal = async () => {
    await refreshServerProps();
    onClose();
  };

  const primaryAction = useMemo<ModalAction>(() => {
    if (flag) {
      return {
        label: t("common.update"),
        onClick: async () => {
          await updateEmployeeDataValidationFlag.mutateAsync({
            id: flag.id,
            employeeId: flag.employeeId,
            comment: comment,
            isLive: true,
          });
          await resetModal();
        },
        disabled,
      };
    }

    return {
      label: t("components.employee-data-validation-flag.report-employee-data-modal.create-flags", {
        number: employeeIds.length,
      }),
      onClick: async () => {
        onSubmit(employeeIds, comment, type, companyId);
        setComment("");
        await resetModal();
      },
      disabled,
    };
  }, [comment, flag, type, employeeIds]);

  const secondaryAction = useMemo<ModalAction>(
    () => ({
      label: t("common.cancel"),
      onClick: () => {
        setComment("");
        onClose();
      },
    }),
    []
  );

  const tertiaryAction = useMemo<ModalAction | undefined>(() => {
    if (flag) {
      return {
        label: t("common.delete"),
        onClick: async () => {
          await updateEmployeeDataValidationFlag.mutateAsync({
            id: flag.id,
            employeeId: flag.employeeId,
            comment: comment,
            isLive: false,
          });

          setComment("");
          onClose();
        },
      };
    }
  }, [comment, flag]);

  return (
    <Modal
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      tertiaryAction={tertiaryAction}
      icon="message-question"
      className="w-[560px]"
      isOpen={isOpen}
      title={t(
        "components.employee-data-validation-flag.report-employee-data-modal.report-an-issue-with-employee-data"
      )}
      onClose={onClose}
    >
      <Stack className="w-full">
        <Typography variant="caption">
          {t("components.employee-data-validation-flag.report-employee-data-modal.if-youve-identified-something-wrong")}
        </Typography>
        <Stack spacing={2} className="mt-4">
          <Select
            size="small"
            defaultValue={flag?.type ?? "placeholder"}
            className={type === ("placeholder" as DataValidationFlagType) ? "text-gray-400" : "text-gray-900"}
            onChange={(event) => {
              setType(event.target.value as DataValidationFlagType);
            }}
          >
            <MenuItem value="placeholder" disabled>
              {t("components.employee-data-validation-flag.report-employee-data-modal.choose-a-type")}
            </MenuItem>
            {types.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
          <TextareaInput
            className="mt-2 w-full appearance-none rounded border border-gray-300 outline-none"
            placeholder={t("components.employee-data-validation-flag.report-employee-data-modal.your-comment")}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
