import { ExternalEmployeeSource, type IntegrationSource } from "@prisma/client";
import React, { createContext, useContext, useState } from "react";
import { match } from "ts-pattern";
import { type IconName } from "~/components/ui/icons";
import { SUPPORTED_INTEGRATIONS } from "~/services/integration";

export type RemunerationSource = {
  name: string;
  icon?: IconName;
  isHRIS: boolean;
  isSpreadsheet: boolean;
  isManual: boolean;
};

const UNKNOWN_SOURCE: RemunerationSource = { name: "", isHRIS: false, isManual: false, isSpreadsheet: false };

const Context = createContext<RemunerationSource>(UNKNOWN_SOURCE);

export const ExternalEmployeeSourceProvider: React.FC<React.PropsWithChildren<{ source: ExternalEmployeeSource }>> = ({
  source,
  children,
}) => {
  const [integrationSource] = useState<RemunerationSource>(mapExternalEmployeeSourceToSource(source));

  return <Context.Provider value={integrationSource}>{children}</Context.Provider>;
};

export function useExternalEmployeeSource() {
  return useContext<RemunerationSource>(Context);
}

export const mapExternalEmployeeSourceToSource = (source: ExternalEmployeeSource): RemunerationSource => {
  return match(source)
    .with(ExternalEmployeeSource.MANUAL, () => ({
      name: "Manual action",
      isHRIS: false,
      isSpreadsheet: false,
      isManual: true,
    }))
    .with(ExternalEmployeeSource.SPREADSHEET, () => ({
      name: "Spreadsheet",
      icon: "spreadsheet",
      isHRIS: false,
      isSpreadsheet: true,
      isManual: false,
    }))
    .otherwise(() => {
      if (!source || !(source in SUPPORTED_INTEGRATIONS)) {
        return UNKNOWN_SOURCE;
      }

      const integration = SUPPORTED_INTEGRATIONS[source as IntegrationSource];

      return {
        name: integration.name,
        icon: integration.icon,
        isHRIS: true,
        isSpreadsheet: false,
        isManual: false,
      };
    });
};
