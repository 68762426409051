import { type PermissionsSchema } from "~/services/permissions/permissions-schema";

export const generateReadOnlyPermissionsSchema = () => {
  const readOnlyPermissions = { read: true, update: false, delete: false };

  return {
    FeatureFlag: readOnlyPermissions,
    Subscription: readOnlyPermissions,
    Currency: readOnlyPermissions,
    CountryAlias: readOnlyPermissions,
    CountryStats: readOnlyPermissions,
    EmployeeLocationStats: readOnlyPermissions,
    EmployeeLocationAlias: readOnlyPermissions,
    JobAlias: readOnlyPermissions,
    JobAliasEmbedding: readOnlyPermissions,
    GlassdoorCompensationData: readOnlyPermissions,
    SuperAdminRightsLog: readOnlyPermissions,
    ExceptionalImpersonationLog: readOnlyPermissions,
  } satisfies Partial<PermissionsSchema>;
};
