import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { boolean, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  fetchJobFamiliesForSelector,
  type FetchJobFamiliesForSelectorResult,
} from "~/services/job/fetchJobFamiliesForSelector";

export const FetchJobFamiliesForSelectorSchema = object({
  enableCustomJobs: boolean().required(),
}).noUnknown();

export type FetchJobFamiliesForSelectorInput = YupOutputType<typeof FetchJobFamiliesForSelectorSchema>;

const handler: NextApiHandler<FetchJobFamiliesForSelectorResult> = async (req, res) => {
  const input = FetchJobFamiliesForSelectorSchema.validateSync(req.body);

  const jobs = await fetchJobFamiliesForSelector(req, input);

  return res.status(HttpStatus.OK).json(jobs);
};

export default api(handler, {
  method: "POST",
  authentication: {
    optional: true,
  },
});

export const FETCH_JOB_FAMILIES_FOR_SELECTOR_QUERY_KEY: ApiRoute["pathname"] =
  "/api/job/fetch-job-families-for-selector";

export const useFetchJobFamiliesForSelectorQuery = createQuery<
  typeof handler,
  typeof FetchJobFamiliesForSelectorSchema
>({
  path: FETCH_JOB_FAMILIES_FOR_SELECTOR_QUERY_KEY,
  schema: FetchJobFamiliesForSelectorSchema,
});
