import React, { createContext, type Dispatch, type SetStateAction, useContext, useState } from "react";

export const Mode = {
  DISPLAY: "DISPLAY",
  EDIT: "EDIT",
} as const;
export type Mode = (typeof Mode)[keyof typeof Mode];

type Props = {
  mode: Mode;
  switchMode: Dispatch<SetStateAction<Mode>>;
};

const Context = createContext<Props>({ mode: Mode.DISPLAY, switchMode: () => undefined });

export const ModeProvider: React.FC<React.PropsWithChildren<{ defaultMode: Mode }>> = ({ defaultMode, children }) => {
  const [mode, switchMode] = useState<Mode>(defaultMode);

  return <Context.Provider value={{ mode, switchMode }}>{children}</Context.Provider>;
};

export function useEditMode() {
  return useContext<Props>(Context);
}
