import { MoreVert } from "@mui/icons-material";
import { Grow, ListItemText, Menu, MenuItem } from "@mui/material";
import { DataValidationFlagOrigin, type DataValidationFlagType, ExternalEmployeeStatus } from "@prisma/client";
import React, { useState } from "react";
import { OffCycleReviewRequestModal } from "~/components/compensation-review/off-cycle-reviews/off-cycle-review-request-modal";
import { ReportEmployeeDataModal } from "~/components/employee/employee-data-validation-flag/report-employee-data-modal";
import { useEmployeeForm } from "~/components/external-employee/drawer/use-employee-form";
import { Button } from "~/components/ui/core/button";
import { SkipReasonSelectorModal } from "~/components/ui/skip-reason-selector-modal";
import { useOpenClose } from "~/hooks/useOpenClose";
import { usePermissions } from "~/hooks/usePermissions";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import { useCreateEmployeeDataValidationFlagMutation } from "~/pages/api/employee-data-validation-flag/create-employee-data-validation-flag";
import { useGetActiveFlagByEmployeeId } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { useSkipExternalEmployeeMappingMutation } from "~/pages/api/external-employee/skip-external-employee-mapping";
import { useUnskipExternalEmployeesMutation } from "~/pages/api/external-employee/unskip-external-employees";

export const MoreActionsButton: React.FC = () => {
  const { t } = useI18n();
  const { user } = useSession();
  const { permissions } = usePermissions();
  const { externalEmployee, externalEmployeeId, isLoading } = useEmployeeForm();

  const [showSkipReasonModal, setShowSkipReasonModal] = useState(false);
  const [showReportDataModal, setShowReportDataModal] = useState(false);

  const [offCycleReviewModalIsOpen, showOffCycleReviewModal, closeOffCycleReviewModal] = useOpenClose();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const skipExternalEmployeeMappingMutation = useSkipExternalEmployeeMappingMutation({
    successMessage: t("components.external-employee-drawer.feedback.employee-has-been-skipped"),
  });

  const unskipExternalEmployeeMappingMutation = useUnskipExternalEmployeesMutation({
    successMessage: t("components.external-employee-drawer.feedback.employee-has-been-unskipped"),
  });

  const createEmployeeDataValidationFlag = useCreateEmployeeDataValidationFlagMutation({
    successMessage: t("components.ui.employee-form.employee-flag-successfully-created"),
  });

  const { data: activeDataValidationFlag } = useGetActiveFlagByEmployeeId({
    options: {
      enabled: !!externalEmployee?.mappedEmployee,
    },
    input: {
      employeeId: externalEmployee?.mappedEmployee?.id,
    },
  });

  const canShowSkipItem = externalEmployee && externalEmployee.status !== ExternalEmployeeStatus.SKIPPED;
  const canShowUnskipItem = externalEmployee && externalEmployee.status === ExternalEmployeeStatus.SKIPPED;
  const canShowRequestOffcycleReview = permissions.canAccessOffCycleReviews;
  const canShowReportItem = !!externalEmployee?.mappedEmployee;
  const hasItemToShow = canShowSkipItem || canShowUnskipItem || canShowReportItem;

  const flagEmployeeData = async (
    employeeIds: number[],
    comment: string,
    type: DataValidationFlagType,
    companyId?: number
  ) => {
    if (employeeIds.length === 0) {
      return;
    }

    await createEmployeeDataValidationFlag.mutateAsync({
      employeeId: employeeIds[0] as number,
      comment,
      type,
      companyId,
      authorId: user?.id,
      origin: DataValidationFlagOrigin.MANUAL,
    });
  };

  return (
    <>
      <Grow in={!isLoading && hasItemToShow}>
        <Button
          variant="outlined"
          color="gray"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setMenuAnchorEl(event.currentTarget);
          }}
          className="min-w-fit px-2"
        >
          <MoreVert fontSize="small" />
        </Button>
      </Grow>
      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {canShowRequestOffcycleReview && (
          <MenuItem
            onClick={() => {
              showOffCycleReviewModal();
              setMenuAnchorEl(null);
            }}
            disabled={externalEmployee && externalEmployee.offCycleReviewRequests.length > 0}
          >
            <ListItemText>{t("components.external-employee-panel.request-off-cycle-review")}</ListItemText>
          </MenuItem>
        )}
        {canShowSkipItem && (
          <MenuItem
            onClick={() => {
              setShowSkipReasonModal(true);
            }}
          >
            <ListItemText>{t("components.external-employee-drawer.actions.skip")}</ListItemText>
          </MenuItem>
        )}
        {canShowUnskipItem && (
          <MenuItem
            onClick={async () => {
              await unskipExternalEmployeeMappingMutation.mutateAsync({ externalEmployeeIds: [externalEmployeeId] });
              setMenuAnchorEl(null);
            }}
          >
            <ListItemText>{t("components.external-employee-drawer.actions.unskip")}</ListItemText>
          </MenuItem>
        )}
        {canShowReportItem && (
          <MenuItem
            onClick={() => {
              setShowReportDataModal(true);
            }}
          >
            <ListItemText>{t("components.external-employee-drawer.actions.report")}</ListItemText>
          </MenuItem>
        )}
      </Menu>

      {externalEmployee && (
        <>
          <SkipReasonSelectorModal
            type="employee"
            isOpen={showSkipReasonModal}
            onClose={() => setShowSkipReasonModal(false)}
            onSubmit={async ({ reason }) => {
              await skipExternalEmployeeMappingMutation.mutateAsync({
                externalEmployeeId,
                reason,
              });
              setMenuAnchorEl(null);
            }}
          />

          {canShowRequestOffcycleReview && (
            <OffCycleReviewRequestModal
              isOpen={offCycleReviewModalIsOpen}
              onClose={closeOffCycleReviewModal}
              externalEmployeeId={externalEmployee.id}
            />
          )}

          {externalEmployee.mappedEmployee && (
            <ReportEmployeeDataModal
              employeeIds={[externalEmployee.mappedEmployee.id]}
              isOpen={showReportDataModal}
              onClose={() => {
                setShowReportDataModal(false);
              }}
              onSubmit={async (
                employeeIds: number[],
                comment: string,
                type: DataValidationFlagType,
                companyId?: number
              ) => {
                await flagEmployeeData(employeeIds, comment, type, companyId);
                setMenuAnchorEl(null);
              }}
              initialFlag={activeDataValidationFlag}
            />
          )}
        </>
      )}
    </>
  );
};
