import { FilterList } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { type AdditionalField, type AdditionalFieldNature } from "@prisma/client";
import { chain, compact, isArray, isNull, isPlainObject, omit } from "lodash";
import { type TFunction } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import CountryFlag from "react-country-flag";
import { match } from "ts-pattern";
import { useDebouncedCallback } from "use-debounce";
import { DateRangeFilter } from "~/components/ui/core/date-range-filter";
import { OptionsFilter } from "~/components/ui/core/options-filter";
import { PercentageRangeFilter } from "~/components/ui/core/percentage-range-filter";
import { RangeFilter } from "~/components/ui/core/range-filter";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { DateFormats, parseISO } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";
import { currentUrlWithQueryParams } from "~/lib/url";
import { getKeys } from "~/lib/utils";
import {
  type FetchEmployeeFilterOptionsResult,
  isDateRangeAdditionalKey,
  isNumberRangeAdditionalKey,
  isPercentageRangeAdditionalKey,
  isStringAdditionalKey,
  parseSelectedFilterOptions,
} from "~/services/employee-filter";

const ADDITIONAL_FIELD_PREFIX = "additional-field-" as const;
const NUMBER_ADDITIONAL_FIELD_PREFIX = "number-additional-field-" as const;
const PERCENTAGE_ADDITIONAL_FIELD_PREFIX = "percentage-additional-field-" as const;
const DATE_ADDITIONAL_FIELD_PREFIX = "date-additional-field-" as const;

type FiltersStateType = {
  "locations": FetchEmployeeFilterOptionsResult["locations"];
  "jobs": FetchEmployeeFilterOptionsResult["jobs"];
  "levels": FetchEmployeeFilterOptionsResult["levels"];
  "managers": FetchEmployeeFilterOptionsResult["managers"];
  "performance-ratings"?: FetchEmployeeFilterOptionsResult["performanceReviewRatings"];
  "business-units"?: FetchEmployeeFilterOptionsResult["businessUnits"];
  "genders": FetchEmployeeFilterOptionsResult["genders"];
  "hire-date"?: [string | undefined, string | undefined];
  [
    key: `${typeof ADDITIONAL_FIELD_PREFIX}${number}`
  ]: FetchEmployeeFilterOptionsResult["additionalFields"][number]["values"];
  [key: `${typeof NUMBER_ADDITIONAL_FIELD_PREFIX}${number}`]: [number, number];
  [key: `${typeof PERCENTAGE_ADDITIONAL_FIELD_PREFIX}${number}`]: [number, number];
  [key: `${typeof DATE_ADDITIONAL_FIELD_PREFIX}${number}`]: [Date, Date];

  // Compensation review filters
  "is-promoted"?: FetchEmployeeFilterOptionsResult["isPromoted"];
  "reviewers"?: FetchEmployeeFilterOptionsResult["reviewers"];
};

type RangeFilterType = Extract<AdditionalFieldNature, "NUMBER" | "DATE" | "PERCENTAGE">;

const buildRangeAdditionalFieldId = (field: Pick<AdditionalField, "id" | "nature">) =>
  match<RangeFilterType, `${"date" | "number" | "percentage"}-additional-field-${number}`>(
    field.nature as RangeFilterType
  )
    .with("DATE", () => `date-additional-field-${field.id}`)
    .with("NUMBER", () => `number-additional-field-${field.id}`)
    .with("PERCENTAGE", () => `percentage-additional-field-${field.id}`)
    .exhaustive();

const formatFilters = (t: TFunction, filterId: string) =>
  match(filterId)
    .with("locations", () => t("components.core.external-employees-filters.locations"))
    .with("jobs", () => t("components.core.external-employees-filters.jobs"))
    .with("levels", () => t("components.core.external-employees-filters.levels"))
    .with("managers", () => t("components.core.external-employees-filters.managers"))
    .with("businessUnits", () => t("components.core.external-employees-filters.business-unit"))
    .with("genders", () => t("components.core.external-employees-filters.gender"))
    .with("hireDate", () => t("components.core.external-employees-filters.hire-date"))
    .with("performanceReviewRatings", () => t("components.core.external-employees-filters.performance"))

    // Compensation review filters
    .with("isPromoted", () => t("components.core.external-employees-filters.is-promoted"))
    .with("reviewers", () => t("components.core.external-employees-filters.reviewers"))

    .otherwise(() => filterId);

export const useExternalEmployeesFilters = (params: {
  filterOptions: FetchEmployeeFilterOptionsResult;
  shallow?: boolean;
}) => {
  const { filterOptions, shallow = false } = params;

  const { t, formatDate } = useI18n();
  const router = useRouter();
  const { CAN_ACCESS_BUSINESS_UNITS } = useFeatureFlags();

  const selectedFilterOptions = parseSelectedFilterOptions(router.query);

  const locationsFromRoute = filterOptions.locations.filter(({ id }) => selectedFilterOptions.locations.includes(id));
  const jobsFromRoute = filterOptions.jobs.filter(({ id }) => selectedFilterOptions.jobs.includes(id));
  const levelsFromRoute = filterOptions.levels.filter(({ id }) => selectedFilterOptions.levels.includes(id));
  const managersFromRoute = filterOptions.managers.filter(({ id }) => selectedFilterOptions.managers.includes(id));
  const performanceReviewRatingsFromRoute = filterOptions.performanceReviewRatings.filter(({ id }) =>
    selectedFilterOptions.performanceReviewRatings.includes(id)
  );
  const gendersFromRoute = filterOptions.genders.filter(({ id }) => selectedFilterOptions.genders.includes(id));
  const hireDateFromRoute = selectedFilterOptions.hireDate;

  const businessUnitsFromRoute = filterOptions.businessUnits?.filter(({ id }) =>
    selectedFilterOptions.businessUnits.includes(id)
  );
  const additionalFieldsFromRoute = filterOptions.additionalFields?.reduce(
    (acc, field) => ({
      ...acc,
      [`${ADDITIONAL_FIELD_PREFIX}${field.id}`]: field.values.filter(
        ({ id }) => selectedFilterOptions[`${ADDITIONAL_FIELD_PREFIX}${field.id}`]?.includes(id)
      ),
    }),
    {}
  );

  const rangeAdditionalFieldsFromRoute = filterOptions.rangeAdditionalFields.reduce(
    (acc, field) => ({
      ...acc,
      [buildRangeAdditionalFieldId(field)]: [
        selectedFilterOptions[buildRangeAdditionalFieldId(field)]?.[0] ?? null,
        selectedFilterOptions[buildRangeAdditionalFieldId(field)]?.[1] ?? null,
      ],
    }),
    {}
  );

  const isPromotedFromRoute = filterOptions.isPromoted.filter(
    ({ id }) => selectedFilterOptions.isPromoted?.includes(id)
  );

  const reviewersFromRoute = filterOptions.reviewers.filter(({ id }) => selectedFilterOptions.reviewers?.includes(id));

  const [filters, setFilters] = useState<FiltersStateType>({
    "locations": locationsFromRoute,
    "jobs": jobsFromRoute,
    "levels": levelsFromRoute,
    "managers": managersFromRoute,
    "genders": gendersFromRoute,
    "hire-date": hireDateFromRoute,
    "performance-ratings": performanceReviewRatingsFromRoute,
    ...(CAN_ACCESS_BUSINESS_UNITS && { "business-units": businessUnitsFromRoute }),
    ...additionalFieldsFromRoute,
    ...rangeAdditionalFieldsFromRoute,

    // Compensation review filters
    "is-promoted": isPromotedFromRoute,
    "reviewers": reviewersFromRoute,
  });

  const [selectedFilters, setSelectedFilers] = useState(
    chain(selectedFilterOptions)
      .pickBy((value) => (isArray(value) && value.length > 0) || isPlainObject(value))
      .keys()
      .map((key) => ({ id: key, name: formatFilters(t, key) }))
      .value()
  );

  const filterList = compact([
    ...getKeys(omit(filterOptions, ["rangeAdditionalFields", "additionalFields"])).map((key) => {
      // Do not display empty filter
      if (isArray(filterOptions[key]) && filterOptions[key]?.length === 0) {
        return null;
      }

      if (key === "businessUnits") return { id: "businessUnits", name: "Business Units" };

      return {
        id: key,
        name: formatFilters(t, key),
      };
    }),
    ...filterOptions.additionalFields.map((field) => ({
      id: `${ADDITIONAL_FIELD_PREFIX}${field.id}`,
      name: field.name,
    })),
    ...filterOptions.rangeAdditionalFields.map((field) => ({
      id: buildRangeAdditionalFieldId(field),
      name: field.name,
      nature: field.nature,
    })),
  ]);

  const buildQueryParams = (filters: FiltersStateType) => {
    const additionalFieldKeys = getKeys(filters).filter(isStringAdditionalKey);
    const numberRangeAdditionalFieldKeys = getKeys(filters)
      .filter(isNumberRangeAdditionalKey)
      .filter((fieldId) => compact(filters[fieldId]).length);
    const percentageRangeAdditionalFieldKeys = getKeys(filters)
      .filter(isPercentageRangeAdditionalKey)
      .filter((fieldId) => compact(filters[fieldId]).length);
    const dateRangeAdditionalFieldKeys = getKeys(filters)
      .filter(isDateRangeAdditionalKey)
      .filter((fieldId) => compact(filters[fieldId]).length);

    return {
      // ExternalEmployee filters
      "locations": filters.locations.map((location) => location.id).join(","),
      "jobs": filters.jobs.map((job) => job.id).join(","),
      "levels": filters.levels.map((level) => level.id).join(","),
      "managers": filters.managers.map((manager) => manager.id).join(","),
      "genders": filters.genders.map((gender) => gender.id).join(","),
      "business-units": filters["business-units"]?.map((businessUnit) => businessUnit.id).join(","),
      "hire-date": filters["hire-date"]
        ?.map((date) => (date ? formatDate(date, DateFormats.DATE_PICKER) : ""))
        .join(","),
      ...additionalFieldKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: filters[key]?.map((value) => value.id).join(","),
        }),
        {}
      ),
      ...numberRangeAdditionalFieldKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: filters[key]?.map((value) => value ?? "").join(","),
        }),
        {}
      ),
      ...percentageRangeAdditionalFieldKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: filters[key]?.map((value) => value ?? "").join(","),
        }),
        {}
      ),
      ...dateRangeAdditionalFieldKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: filters[key]?.map((value) => (value ? value.getTime() : "")).join(","),
        }),
        {}
      ),

      // Compensation review employee filters
      "is-promoted": filters["is-promoted"]?.map((isPromoted) => `${isPromoted.id}`).join(","),
      "reviewers": filters["reviewers"]?.map((reviewer) => reviewer.id).join(","),

      // Shared filters
      "performance-ratings": filters["performance-ratings"]
        ?.map((performanceReviewRating) => performanceReviewRating.id)
        .join(","),
    };
  };

  const debounceUpdateRoute = useDebouncedCallback(() => {
    const queryParams = buildQueryParams(filters);

    void router.replace(currentUrlWithQueryParams(router, { page: null, ...queryParams }), undefined, { shallow });
  }, 500);

  const isFiltering = Object.values(filters).filter(
    (values) => !!values?.filter((value) => !isNull(value)).length
  ).length;

  const displayLocationFilter = selectedFilters.some(({ id }) => id === "locations");
  const displayJobFilter = selectedFilters.some(({ id }) => id === "jobs");
  const displayLevelFilter = selectedFilters.some(({ id }) => id === "levels");
  const displayManagerFilter = selectedFilters.some(({ id }) => id === "managers");
  const displayPerformanceReviewRatingFilter = selectedFilters.some(({ id }) => id === "performanceReviewRatings");
  const displayGenderFilter = selectedFilters.some(({ id }) => id === "genders");
  const displayHireDateFilter = selectedFilters.some(({ id }) => id === "hireDate");
  const displayBusinessUnitFilter = selectedFilters.some(({ id }) => id === "businessUnits");
  const displayedAdditionalFields = filterOptions.additionalFields?.filter((field) =>
    selectedFilters.some(({ id }) => id === `${ADDITIONAL_FIELD_PREFIX}${field.id}`)
  );
  const displayedRangeAdditionalFields = filterOptions.rangeAdditionalFields?.filter((field) =>
    selectedFilters.some(({ id }) => id === buildRangeAdditionalFieldId(field))
  );

  // Compensation review filters
  const displayIsPromotedFilter = selectedFilters.some(({ id }) => id === "isPromoted");
  const displayReviewerFilter = selectedFilters.some(({ id }) => id === "reviewers");

  const onFiltersChange: typeof setFilters = (newFilters) => {
    setFilters(newFilters);
    debounceUpdateRoute();
  };

  const clearFilters = () => {
    onFiltersChange({
      "locations": [],
      "jobs": [],
      "levels": [],
      "managers": [],
      "business-units": [],
      "performance-ratings": [],
      "genders": [],
      ...filterOptions.additionalFields?.reduce(
        (acc, field) => ({
          ...acc,
          [`${ADDITIONAL_FIELD_PREFIX}${field.id}`]: [],
        }),
        {}
      ),
      ...filterOptions.rangeAdditionalFields?.reduce(
        (acc, field) => ({
          ...acc,
          [`${buildRangeAdditionalFieldId(field)}${field.id}`]: [],
        }),
        {}
      ),

      // Compensation review filters
      "is-promoted": [],
      "reviewers": [],
    });
  };

  return {
    filters,
    onFiltersChange,
    clearFilters,
    selectedFilters,
    setSelectedFilers,
    filterList,
    isFiltering,
    displayLocationFilter,
    displayJobFilter,
    displayLevelFilter,
    displayManagerFilter,
    displayGenderFilter,
    displayHireDateFilter,
    displayBusinessUnitFilter,
    displayedAdditionalFields,
    displayedRangeAdditionalFields,
    displayPerformanceReviewRatingFilter,

    // Compensation review filters
    displayIsPromotedFilter,
    displayReviewerFilter,
  };
};

export const ExternalEmployeesFilters: React.FC<{
  filterOptions: FetchEmployeeFilterOptionsResult;
  filters: ReturnType<typeof useExternalEmployeesFilters>;
}> = ({ filterOptions, filters }) => {
  const { t, formatDate } = useI18n();
  if (!filters.selectedFilters.length) return null;

  return (
    <Stack direction="row" gap={3} className="pr-2">
      {filters.displayLocationFilter && (
        <OptionsFilter
          label={formatFilters(t, "locations")}
          options={filterOptions.locations}
          value={filters.filters["locations"]}
          placeholder={formatFilters(t, "locations")}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, locations: newValue }));
          }}
          renderOption={(location) => (
            <Stack direction="row" justifyContent="space-between" className="w-full">
              <Stack direction="row" alignItems="center">
                {!!location.country && <CountryFlag svg className="mr-1.5" countryCode={location.country.alpha2} />}
                <Typography noWrap>{location.name}</Typography>
              </Stack>
            </Stack>
          )}
        />
      )}

      {filters.displayJobFilter && (
        <OptionsFilter
          label={formatFilters(t, "jobs")}
          options={filterOptions.jobs}
          value={filters.filters["jobs"]}
          placeholder={formatFilters(t, "jobs")}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, jobs: newValue }));
          }}
        />
      )}

      {filters.displayLevelFilter && (
        <OptionsFilter
          label={formatFilters(t, "levels")}
          options={filterOptions.levels}
          value={filters.filters["levels"]}
          placeholder={formatFilters(t, "levels")}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, levels: newValue }));
          }}
        />
      )}

      {filters.displayManagerFilter && (
        <OptionsFilter
          label={formatFilters(t, "managers")}
          options={filterOptions.managers}
          value={filters.filters["managers"]}
          placeholder={formatFilters(t, "managers")}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, managers: newValue }));
          }}
        />
      )}

      {filters.displayGenderFilter && (
        <OptionsFilter
          label={formatFilters(t, "genders")}
          options={filterOptions.genders}
          value={filters.filters.genders}
          placeholder={formatFilters(t, "genders")}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, genders: newValue }));
          }}
        />
      )}

      {filters.displayHireDateFilter && (
        <DateRangeFilter
          label={formatFilters(t, "hireDate")}
          min={filters.filters["hire-date"]?.[0] ? parseISO(filters.filters["hire-date"][0]) : undefined}
          max={filters.filters["hire-date"]?.[1] ? parseISO(filters.filters["hire-date"][1]) : undefined}
          onChange={(newValue) => {
            const [min, max] = newValue;

            filters.onFiltersChange((prev) => ({
              ...prev,
              "hire-date": [
                min ? formatDate(min, DateFormats.DATE_PICKER) : "",
                max ? formatDate(max, DateFormats.DATE_PICKER) : "",
              ],
            }));
          }}
        />
      )}

      {filters.displayPerformanceReviewRatingFilter && filters.filters["performance-ratings"] && (
        <OptionsFilter
          label={formatFilters(t, "performanceReviewRatings")}
          options={filterOptions.performanceReviewRatings}
          value={filters.filters["performance-ratings"]}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, ["performance-ratings"]: newValue }));
          }}
        />
      )}

      {filters.displayIsPromotedFilter && filterOptions.isPromoted && filters.filters["is-promoted"] && (
        <OptionsFilter
          label={formatFilters(t, "isPromoted")}
          options={filterOptions.isPromoted}
          value={filters.filters["is-promoted"]}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, ["is-promoted"]: newValue }));
          }}
        />
      )}

      {filters.displayReviewerFilter && filterOptions.reviewers && filters.filters["reviewers"] && (
        <OptionsFilter
          label={formatFilters(t, "reviewers")}
          options={filterOptions.reviewers}
          value={filters.filters["reviewers"]}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, ["reviewers"]: newValue }));
          }}
        />
      )}

      {filters.displayBusinessUnitFilter && filterOptions.businessUnits && filters.filters["business-units"] && (
        <OptionsFilter
          label={formatFilters(t, "businessUnits")}
          options={filterOptions.businessUnits}
          value={filters.filters["business-units"]}
          placeholder={formatFilters(t, "businessUnits")}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({ ...prev, ["business-units"]: newValue }));
          }}
        />
      )}

      {filters.displayedAdditionalFields?.map((field) => (
        <OptionsFilter
          key={field.id}
          label={field.name}
          options={field.values}
          value={filters.filters[`additional-field-${field.id}`] ?? []}
          placeholder={t("common.search")}
          onChange={(newValue) => {
            filters.onFiltersChange((prev) => ({
              ...prev,
              [`additional-field-${field.id}`]: newValue,
            }));
          }}
        />
      ))}

      {filters.displayedRangeAdditionalFields?.map((field) =>
        match(field.nature as RangeFilterType)
          .with("NUMBER", () => (
            <RangeFilter
              key={field.id}
              label={field.name}
              min={filters.filters[`${NUMBER_ADDITIONAL_FIELD_PREFIX}${field.id}`]?.[0] ?? undefined}
              max={filters.filters[`${NUMBER_ADDITIONAL_FIELD_PREFIX}${field.id}`]?.[1] ?? undefined}
              onChange={(newValue) => {
                const [min, max] = newValue;

                filters.onFiltersChange((prev) => ({
                  ...prev,
                  [buildRangeAdditionalFieldId(field)]: [min, max],
                }));
              }}
            />
          ))
          .with("PERCENTAGE", () => (
            <PercentageRangeFilter
              key={field.id}
              label={field.name}
              min={filters.filters[`${PERCENTAGE_ADDITIONAL_FIELD_PREFIX}${field.id}`]?.[0] ?? undefined}
              max={filters.filters[`${PERCENTAGE_ADDITIONAL_FIELD_PREFIX}${field.id}`]?.[1] ?? undefined}
              onChange={(newValue) => {
                const [min, max] = newValue;

                filters.onFiltersChange((prev) => ({
                  ...prev,
                  [buildRangeAdditionalFieldId(field)]: [min, max],
                }));
              }}
            />
          ))
          .with("DATE", () => (
            <DateRangeFilter
              key={field.id}
              label={field.name}
              min={filters.filters[`${DATE_ADDITIONAL_FIELD_PREFIX}${field.id}`]?.[0] ?? undefined}
              max={filters.filters[`${DATE_ADDITIONAL_FIELD_PREFIX}${field.id}`]?.[1] ?? undefined}
              onChange={(newValue) => {
                const [min, max] = newValue;

                filters.onFiltersChange((prev) => ({
                  ...prev,
                  [buildRangeAdditionalFieldId(field)]: [min, max],
                }));
              }}
            />
          ))
          .exhaustive()
      )}
    </Stack>
  );
};

export const ExternalEmployeesOptionsFilter: React.FC<{
  filters: ReturnType<typeof useExternalEmployeesFilters>;
}> = ({ filters }) => {
  const { t } = useI18n();

  return (
    <OptionsFilter
      label={t("components.core.external-employees-filters.filter")}
      className="shrink-0"
      options={filters.filterList}
      value={filters.selectedFilters}
      placeholder={t("components.core.external-employees-filters.filter-on")}
      withChips={false}
      buttonIcon={<FilterList fontSize="small" />}
      onChange={(newSelectedFilters) => {
        if (filters.selectedFilters.length > newSelectedFilters.length) {
          const removedFilter = filters.selectedFilters.filter(
            ({ id }) => !newSelectedFilters.some((filter) => filter.id === id)
          )[0];

          if (removedFilter) {
            filters.onFiltersChange((prev) => ({ ...prev, [removedFilter.id]: [] }));
          }
        }
        filters.setSelectedFilers(newSelectedFilters);
      }}
    />
  );
};
