import { type Prisma } from "@prisma/client";
import { type PermissionsSchema } from "~/services/permissions/permissions-schema";

export const DangerouslyIncludeSoftDeletedExternalEmployeesToken = Symbol(
  "dangerouslyIncludeSoftDeletedExternalEmployees"
) as unknown as string;

export const dangerouslyIncludeSoftDeletedExternalEmployees = (): Record<string, boolean> => {
  return { [DangerouslyIncludeSoftDeletedExternalEmployeesToken]: true };
};

export const generateExternalEmployeesSoftDeleteSchema = (): Partial<PermissionsSchema> => {
  const condition = { deletedAt: null } satisfies Prisma.ExternalEmployeeWhereInput;

  return {
    ExternalEmployee: {
      read: condition,
      update: condition,
      delete: condition,
    },

    SalaryRangeEmployee: {
      read: { externalEmployee: condition },
      update: { externalEmployee: condition },
      delete: { externalEmployee: condition },
    },
  };
};
