import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type OverrideAllEmployeeDataValidationFlagInput } from "~/pages/api/admin/employee-data-validation/override-all-employee-data-validation-flag-by-comment";
import { type OverrideEmployeeDataValidationFlagInput } from "~/pages/api/admin/employee-data-validation/override-employee-data-validation-flag";
export const overrideEmployeeDataValidationFlag = async (
  ctx: AppContext,
  input: OverrideEmployeeDataValidationFlagInput
) => {
  const user = getRequiredUser(ctx);

  await ctx.prisma.employeeDataValidationFlag.update({
    where: {
      id: input.id,
      ...(!user.isSuperAdmin && {
        companyId: user.companyId,
      }),
    },
    data: {
      employeeId: input.employeeId,
      isLive: false,
      ignore: true,
      closedAt: new Date(),
      closedById: user.id,
    },
  });
};

export type OverrideEmployeeDataValidationFlagResult = AsyncReturnType<typeof overrideEmployeeDataValidationFlag>;

export const overrideAllEmployeeDataValidationFlag = async (
  ctx: AppContext,
  input: OverrideAllEmployeeDataValidationFlagInput
) => {
  const user = getRequiredUser(ctx);

  const commentCode = input.comment?.trim().split(" ")[0];

  await ctx.prisma.employeeDataValidationFlag.updateMany({
    where: {
      employee: { companyId: input.companyId },
      isLive: true,
      ignore: false,
      comment: { startsWith: commentCode },
      ...(!user.isSuperAdmin && {
        companyId: user.companyId,
      }),
    },
    data: {
      isLive: false,
      ignore: true,
      closedAt: new Date(),
      closedById: user.id,
    },
  });
};

export type OverrideAllEmployeeDataValidationFlagResult = AsyncReturnType<typeof overrideAllEmployeeDataValidationFlag>;
