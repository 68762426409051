import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object, string } from "yup";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { overrideAllEmployeeDataValidationFlag } from "~/services/employee-data-validation-flag/override-employee-data-validation-flag";

export const OverrideAllEmployeeDataValidationSchema = object({
  companyId: number().required(),
  comment: string().required(),
});

export type OverrideAllEmployeeDataValidationFlagInput = YupOutputType<typeof OverrideAllEmployeeDataValidationSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = OverrideAllEmployeeDataValidationSchema.validateSync(req.body, {
    abortEarly: false,
  });

  await overrideAllEmployeeDataValidationFlag(req, input);

  res.status(HttpStatus.OK).json({
    message: "Employee Data Validation Flag has been dismissed and won't be updated",
  });
};

export default api(handler, {
  method: "POST",
  authentication: { superAdmin: true },
});

export const useOverrideAllEmployeeDataValidationFlagMutation = createMutation<
  typeof handler,
  typeof OverrideAllEmployeeDataValidationSchema
>({
  path: "/api/admin/employee-data-validation/override-all-employee-data-validation-flag-by-comment",
  schema: OverrideAllEmployeeDataValidationSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY);
    },
  }),
});
