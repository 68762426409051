import { Chip } from "@mui/material";
import { type ExternalEmployeeStatus } from "@prisma/client";
import React from "react";
import { useI18n } from "~/lib/i18n/use-i18n";
import { formatExternalEmployeeStatus } from "~/services/external-employee/external-employee-status";

type Props = {
  status: ExternalEmployeeStatus;
};

export const ExternalEmployeeStatusChip: React.FC<Props> = ({ status }) => {
  const { t } = useI18n();
  const { label, color } = formatExternalEmployeeStatus(t, status);

  return <Chip label={label} size="small" color={color} />;
};
