import { ExternalEmployeeStatus, type Prisma } from "@prisma/client";
import { map } from "bluebird";
import { compact, isArray, omit, orderBy, sum } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { and } from "~/lib/prisma-helpers";
import {
  buildRevieweesWherePayload,
  buildReviewersWherePayload,
  selectReviewerIds,
} from "~/services/compensation-review/campaigns/admin/employee-reviewers";
import { type CompensationReviewContext } from "~/services/compensation-review/compensation-review-context";
import { baseEmptyFilters, type FilterOptionsInput } from "~/services/employee-filter";
import {
  buildExternalEmployeeFilterWhereClauses,
  fetchExternalEmployeeFilterOptions,
} from "~/services/employee-filter/fetch-external-employee-filter-options";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/select-external-employee-user-for-picture";

const selectRevieweesIds = {
  reviewees1: true,
  reviewees2: true,
  reviewees3: true,
  reviewees4: true,
  reviewees5: true,
  reviewees6: true,
  reviewees7: true,
  reviewees8: true,
  reviewees9: true,
  reviewees10: true,
} satisfies Prisma.CompensationReviewReviewerSelect;

export const compensationReviewEmployeeWhereOmiter = (
  where: Prisma.CompensationReviewEmployeeWhereInput,
  omitKey: "performanceRatingId" | "isPromoted"
) => {
  if (!where?.AND || !isArray(where?.AND)) {
    return omit(where, [omitKey]);
  }

  if (isArray(where?.AND) && where?.AND.length === 0) {
    return omit(where, [omitKey]);
  }

  return {
    ...where,
    AND: where.AND.map((where) => omit(where, [omitKey])).filter((where) => !!where && Object.keys(where).length !== 0),
  };
};

const fetchCompensationReviewEmployeesPromotions = async (
  ctx: AppContext,
  compensationReviewEmployeeWhere: Prisma.CompensationReviewEmployeeWhereInput,
  compensationReviewEmployeeScope: Prisma.CompensationReviewEmployeeWhereInput
) => {
  return map([true, false], async (isPromoted) => {
    const count = await ctx.prisma.compensationReviewEmployee.count({
      where: {
        companyId: getRequiredUser(ctx).companyId,
        isPromoted,
        AND: [
          compensationReviewEmployeeScope,
          compensationReviewEmployeeWhereOmiter(compensationReviewEmployeeWhere, "isPromoted"),
        ],
      },
    });

    return {
      id: isPromoted,
      name: isPromoted ? ctx.t("common.yes") : ctx.t("common.no"),
      count,
    };
  });
};

const compensationReviewEmployeeReviewersWhereOmiter = (where: Prisma.CompensationReviewEmployeeWhereInput) => {
  const reviewerKeys = Object.keys(selectReviewerIds);

  if (!where?.AND || !isArray(where?.AND)) {
    return omit(where, reviewerKeys);
  }

  return {
    ...where,
    AND: where.AND.map((where) => {
      return {
        ...omit(where, reviewerKeys),
        ...(where.OR?.length && { OR: where.OR?.map((or) => omit(or, reviewerKeys)) }),
      };
    }).filter((where) => !!where && Object.keys(where).length !== 0),
  };
};

const buildReviewerCountClause = (
  ctx: AppContext,
  compensationReviewEmployeeWhere: Prisma.CompensationReviewEmployeeWhereInput,
  compensationReviewEmployeeScope: Prisma.CompensationReviewEmployeeWhereInput
) => {
  return Object.keys(selectRevieweesIds).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        where: {
          companyId: getRequiredUser(ctx).companyId,
          AND: [
            compensationReviewEmployeeScope,
            compensationReviewEmployeeReviewersWhereOmiter(compensationReviewEmployeeWhere),
          ],
        },
      },
    }),
    {}
  );
};

export const fetchCompensationReviewReviewers = async (
  ctx: CompensationReviewContext,
  compensationReviewEmployeeWhere: Prisma.CompensationReviewEmployeeWhereInput,
  compensationReviewEmployeeScope: Prisma.CompensationReviewEmployeeWhereInput
) => {
  return ctx.prisma.compensationReviewReviewer.findMany({
    where: {
      AND: [
        {
          OR: compact([
            buildRevieweesWherePayload(ctx, compensationReviewEmployeeScope),
            ctx.parameters.finalReviewerId && { id: ctx.parameters.finalReviewerId },
          ]),
        },
      ],
    },
    select: {
      _count: {
        select: buildReviewerCountClause(ctx, compensationReviewEmployeeWhere, compensationReviewEmployeeScope),
      },
      id: true,
      externalEmployee: {
        select: {
          firstName: true,
          lastName: true,
          externalId: true,
          employeeNumber: true,
          ...selectExternalEmployeeUserPicture,
        },
      },
    },
  });
};

const fetchCompensationReviewEmployeesPerformanceReviewRatings = async (
  ctx: AppContext,
  compensationReviewEmployeeWhere: Prisma.CompensationReviewEmployeeWhereInput,
  compensationReviewEmployeeScope: Prisma.CompensationReviewEmployeeWhereInput
) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.performanceReviewRating.findMany({
    where: {
      companyId: user.companyId,
      compensationReviewEmployees: {
        some: compensationReviewEmployeeScope,
      },
    },
    select: {
      _count: {
        select: {
          compensationReviewEmployees: {
            where: {
              companyId: user.companyId,
              AND: [
                compensationReviewEmployeeScope,
                compensationReviewEmployeeWhereOmiter(compensationReviewEmployeeWhere, "performanceRatingId"),
              ],
            },
          },
        },
      },
      id: true,
      name: true,
    },
  });
};

export type FetchCompensationReviewEmployeeFilterOptionsParams = {
  where?: Prisma.CompensationReviewEmployeeWhereInput;
  scope?: Prisma.CompensationReviewEmployeeWhereInput;
};

export const fetchCompensationReviewEmployeeFilterOptions = async (
  ctx: CompensationReviewContext,
  params: FetchCompensationReviewEmployeeFilterOptionsParams
) => {
  const { where = {}, scope = {} } = params;

  const baseExternalEmployeeWhere = {
    companyId: ctx.user.companyId,
    status: { not: ExternalEmployeeStatus.SKIPPED },
  } satisfies Prisma.ExternalEmployeeWhereInput;

  const externalEmployeeFilterOptions = await fetchExternalEmployeeFilterOptions(ctx, {
    where: and([baseExternalEmployeeWhere, where?.externalEmployee]),
    scope: and([baseExternalEmployeeWhere, scope?.externalEmployee]),
  });

  const [rawPerformanceReviewRatings, isPromoted, rawReviewers] = await Promise.all([
    fetchCompensationReviewEmployeesPerformanceReviewRatings(ctx, where, scope),
    fetchCompensationReviewEmployeesPromotions(ctx, where, scope),
    fetchCompensationReviewReviewers(ctx, where, scope),
  ]);

  const performanceReviewRatings = rawPerformanceReviewRatings.map((performanceReview) => ({
    id: performanceReview.id,
    name: performanceReview.name,
    count: performanceReview._count.compensationReviewEmployees,
  }));

  const reviewers = rawReviewers.map((reviewer) => ({
    id: reviewer.id,
    name: formatExternalEmployeeName(reviewer.externalEmployee),
    count: sum(Object.values(reviewer._count)),
  }));

  return {
    ...baseEmptyFilters,
    ...externalEmployeeFilterOptions,
    performanceReviewRatings: orderBy(performanceReviewRatings, ["count", "name"], ["asc", "asc"]),
    reviewers: orderBy(reviewers, ["name"], ["asc"]),
    isPromoted: orderBy(isPromoted, ["count"], ["asc"]),
  };
};

export type FetchCompensationReviewEmployeeFilterOptionsResult = AsyncReturnType<
  typeof fetchCompensationReviewEmployeeFilterOptions
>;

export const buildCompensationReviewEmployeeFilterWhereClauses = (
  ctx: CompensationReviewContext,
  selectedFilterOptions: FilterOptionsInput
): Prisma.CompensationReviewEmployeeWhereInput[] => {
  const externalEmployeeFilterOptions = buildExternalEmployeeFilterWhereClauses(selectedFilterOptions);

  const filteredExternalEmployeeFilterOptions = externalEmployeeFilterOptions.filter(
    (where) => !where.performanceReviewRatingId
  );

  return compact([
    !!filteredExternalEmployeeFilterOptions?.length && {
      externalEmployee: {
        AND: filteredExternalEmployeeFilterOptions,
      },
    },
    // Override the externalEmployee performanceReviewRatings
    // by the comp review performanceReviewRatings
    !!selectedFilterOptions?.performanceReviewRatings?.length && {
      performanceRatingId: { in: selectedFilterOptions.performanceReviewRatings },
    },

    // If there is more than one value it means we want true and false results
    // which means no where clause is needed
    selectedFilterOptions?.isPromoted?.length === 1 && {
      isPromoted: selectedFilterOptions.isPromoted[0],
    },

    !!selectedFilterOptions?.reviewers?.length &&
      buildReviewersWherePayload(ctx, { in: selectedFilterOptions.reviewers }),
  ]);
};
