import { Popover } from "@headlessui/react";
import { CancelOutlined, Edit, ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Input,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { sumBy } from "lodash";
import React, { type ComponentProps, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { Icon } from "~/components/ui/core/Icon";
import { usePortalRoot } from "~/hooks/usePortalRoot";
import { useScreenDimensions } from "~/hooks/useScreenDimensions";
import { theme } from "~/lib/theme";

type Event<T> = {
  target: {
    name?: string;
    value: T[];
  };
};

type EditableOption = {
  onEdit?: () => void;
};

export type Group<T> = {
  label: string | JSX.Element;
  options: (T extends Record<string, unknown> ? T & EditableOption : T)[];
  bypassSearch?: boolean;
  canSelectAll?: boolean;
};

export type BetterSelectInputWidth = "md" | "lg" | "xl" | "2xl" | "3xl";

type SearchInputPropsType = Omit<
  ComponentProps<typeof Input>,
  "autoFocus" | "className" | "placeholder" | "type" | "value" | "onChange" | "startAdornment" | "endAdornment"
>;

type Props<T> = {
  accordion?: boolean;
  name?: string;
  disabled?: boolean;
  placeholder: string;
  value: T[];
  onClose?: () => void;
  onOpen?: () => void;
  onChange: (event: Event<T>) => void;
  multiple?: boolean;
  clearable?: boolean;
  horizontal?: boolean;
  groups: Group<T>[];
  dense?: boolean;
  hideGroupLabels?: boolean;
  formatLabel: (values: T[]) => string | JSX.Element;
  formatOption: (value: T, options: { selected: boolean }) => string | JSX.Element;
  formatOptionAffix?: (value: T) => string | JSX.Element | null;
  formatOptionTooltip?: (value: T) => JSX.Element;
  inline?: boolean;
  optionToId: (value: T) => string | number;
  searchOptions?: {
    placeholder: string;
    optionToSearchableStrings: (value: T) => string[];
    onSearchChange?: (search: string) => void;
  };
  width?: BetterSelectInputWidth;
  verticalOffset?: number;
  headerSlot?: JSX.Element;
  excludedItems?: T[];
  SearchInputProps?: SearchInputPropsType;
  jobRequestButton?: JSX.Element;
  className?: string;
};

export const SelectInput = <T,>({
  name,
  value,
  placeholder,
  groups,
  formatLabel,
  formatOption,
  formatOptionAffix,
  formatOptionTooltip,
  searchOptions,
  optionToId,
  onClose,
  onOpen,
  onChange,
  dense = false,
  hideGroupLabels = false,
  horizontal = false,
  disabled = false,
  inline = false,
  multiple = false,
  clearable = true,
  width = "md",
  verticalOffset,
  headerSlot,
  excludedItems = [],
  accordion = false,
  SearchInputProps,
  jobRequestButton,
  className,
}: Props<T>): JSX.Element => {
  const portalRoot = usePortalRoot();
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const listElement = useRef<HTMLDivElement>(null);
  const screenDimensions = useScreenDimensions();
  const useFullScreenPopover = screenDimensions.width < parseInt(theme.screens.sm as string);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, dense ? -30 : -43],
        },
      },
    ],
  });
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const searchLower = search.toLowerCase();
  const filteredGroups = groups
    .map((group) => {
      const options = group.options.filter((option) => {
        const isExcluded = !!excludedItems.find((item) => {
          return optionToId(option) === optionToId(item);
        });
        if (isExcluded) {
          return false;
        }

        if (group.bypassSearch) {
          return true;
        }

        if (!searchOptions) {
          return true;
        }

        const strings = searchOptions.optionToSearchableStrings(option);

        return strings.find((string) => {
          return string.toLowerCase().indexOf(searchLower) >= 0;
        });
      });

      return {
        label: group.label,
        options,
        canSelectAll: group.canSelectAll ?? false,
      };
    })
    .filter(({ options }) => options.length > 0);

  const onOptionSelect = (option: T) => {
    if (!multiple) {
      const isSelected = !!value.find((item) => {
        return optionToId(item) === optionToId(option);
      });

      onChange({
        target: {
          name,
          value: isSelected ? [] : [option],
        },
      });
      close();

      return;
    }

    const newValue = [...value];
    const index = value.findIndex((item) => {
      return optionToId(item) === optionToId(option);
    });
    if (index === -1) {
      newValue.push(option);
    } else {
      newValue.splice(index, 1);
    }

    onChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  const onGroupSelect = (group: Group<T>) => {
    const newValue = [...value];

    group.options.forEach((item) => {
      const index = value.findIndex((i) => {
        return optionToId(item) === optionToId(i);
      });

      if (index === -1) {
        newValue.push(item);
      }
    });

    onChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  const onGroupUnselect = (group: Group<T>) => {
    const newValue = value.filter((item) => {
      const index = group.options.findIndex((i) => {
        return optionToId(item) === optionToId(i);
      });
      return index === -1;
    });

    onChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  const close = () => {
    setIsOpen(false);
    setSearch("");

    (referenceElement?.children[0] as HTMLDivElement)?.focus();
    onClose?.();
  };

  const optionsCount = sumBy(filteredGroups, (group) => {
    return group.options.length;
  });

  useEffect(() => {
    if (!isOpen || !listElement.current || selectedIndex === null) {
      return;
    }

    listElement.current.querySelector(`[data-full-index="${selectedIndex}"]`)?.scrollIntoView({
      block: "center",
    });
  }, [selectedIndex, isOpen, listElement.current]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();

        setSelectedIndex(selectedIndex === null ? 0 : (selectedIndex + 1 + optionsCount) % optionsCount);
      }

      if (event.key === "ArrowUp") {
        event.preventDefault();

        setSelectedIndex(selectedIndex === null ? 0 : (selectedIndex - 1 + optionsCount) % optionsCount);
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [selectedIndex, setSelectedIndex, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Enter" && selectedIndex !== null) {
        const option = filteredGroups.flatMap((group) => {
          return group.options;
        })[selectedIndex];

        if (option) {
          event.preventDefault();

          onOptionSelect(option);
        }
      }

      if (event.key === "Escape") {
        event.preventDefault();
        close();
      }
    };

    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [filteredGroups, selectedIndex, isOpen]);

  useEffect(() => {
    searchOptions?.onSearchChange?.(search);
  }, [search]);

  return (
    <Popover className={className}>
      <Popover.Button as="div" className="w-full">
        <button
          ref={setReferenceElement}
          className="w-full text-left focus:outline-none"
          disabled={disabled}
          type="button"
          onClick={() => {
            if (!isOpen) {
              onOpen?.();
            }

            setIsOpen(true);
          }}
          onKeyUp={(event) => {
            if (isOpen) {
              return;
            }

            if (event.key === "Enter" || event.key === " ") {
              event.preventDefault();
              event.stopPropagation();

              setIsOpen(true);
            }
          }}
        >
          {formatLabel(value) ?? placeholder}
        </button>
      </Popover.Button>

      {isOpen && (
        <>
          {ReactDOM.createPortal(
            <div
              className="fixed inset-0 z-40 bg-gray-900 opacity-[0.02]"
              onClick={(event) => {
                event.stopPropagation();
                close();
              }}
            />,
            document.querySelector(portalRoot) as HTMLDivElement
          )}

          {ReactDOM.createPortal(
            <Popover.Panel
              ref={setPopperElement}
              style={{
                ...styles.popper,
                maxHeight: "50vh",
                minHeight: horizontal ? "50vh" : "auto",
                ...(useFullScreenPopover && {
                  position: "fixed",
                  inset: "0.5rem",
                  transform: "none",
                  maxHeight: "none",
                  width: "calc(100% - 1rem)",
                }),
                ...(verticalOffset && {
                  marginTop: verticalOffset,
                }),
              }}
              {...attributes.popper}
              static
              className={classNames({
                "z-50 flex flex-col rounded border border-primary-500 bg-white shadow-2xl": true,
                "w-64": width === "md",
                "w-80": width === "lg",
                "w-96": width === "xl",
                "w-[416px]": width === "2xl",
                "w-[574px]": width === "3xl",
              })}
            >
              {searchOptions && (
                <Input
                  className="z-20 w-full rounded p-1.5"
                  autoFocus
                  placeholder={searchOptions.placeholder}
                  type="text"
                  value={search}
                  onChange={(event) => {
                    setSelectedIndex(0);
                    setSearch(event.target.value);
                  }}
                  startAdornment={<Search fontSize="small" className="text-gray-500" />}
                  endAdornment={
                    search && (
                      <button onClick={() => setSearch("")}>
                        <CancelOutlined fontSize="small" className="text-gray-500" />
                      </button>
                    )
                  }
                  {...SearchInputProps}
                  onKeyDown={(event) => event.stopPropagation()} // https://figures-hr.slack.com/archives/C05845FH6TB/p1718974198249509 🤷
                />
              )}

              <div
                ref={listElement}
                className={classNames({
                  "hide-scrollbar flex divide-y overflow-y-auto rounded": true,
                  "flex-col": !horizontal,
                })}
              >
                {headerSlot}
                {value.length === 0 && jobRequestButton}

                {filteredGroups.map((group, groupIndex) => {
                  const hasAllSelected = group.options.every((option) => {
                    return value.find((item) => optionToId(item) === optionToId(option));
                  });

                  const groupAction = (group: Group<T>) => {
                    if (hasAllSelected) {
                      onGroupUnselect(group);
                    } else {
                      onGroupSelect(group);
                    }
                  };

                  return (
                    <div
                      key={groupIndex}
                      className={classNames({
                        "relative flex flex-1 flex-col": true,
                        "px-4 pt-2 pb-4": inline && !accordion,
                        "px-2 pb-1": accordion,
                        "overflow-y-auto": horizontal,
                        "w-[416px]": width === "2xl",
                        "w-[574px]": width === "3xl",
                      })}
                    >
                      {!hideGroupLabels && !inline && !accordion && (
                        <div
                          className={classNames({
                            "sticky top-0 z-10 flex items-center justify-between p-2 py-2.5": true,
                            "bg-gray-200": !inline,
                            "rounded-t": groupIndex === 0 && !searchOptions,
                          })}
                        >
                          <Typography variant="subtitle2">{group.label}</Typography>

                          {multiple && group.canSelectAll && (
                            <SelectButton hasAllSelected={hasAllSelected} onClick={() => groupAction(group)} />
                          )}
                        </div>
                      )}
                      {accordion && (
                        <SelectAccordion>
                          <SelectAccordionSummary expandIcon={<ExpandMore />}>
                            <Stack direction="row" className="w-[calc(100%-28px)] pl-1" alignItems="center">
                              <Typography variant="subtitle2">{group.label}</Typography>

                              {multiple && group.canSelectAll && (
                                <SelectButton
                                  hasAllSelected={hasAllSelected}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    groupAction(group);
                                  }}
                                />
                              )}
                            </Stack>
                          </SelectAccordionSummary>

                          <AccordionDetails sx={{ padding: "0" }} className="mb-1">
                            <Stack direction="row" rowGap={2} columnGap={2} flexWrap="wrap">
                              <GroupOptionFormat
                                filteredGroups={filteredGroups}
                                formatOption={formatOption}
                                group={group}
                                groupIndex={groupIndex}
                                inline={inline}
                                onOptionClick={onOptionSelect}
                                optionToId={optionToId}
                                selectedIndex={selectedIndex}
                                value={value}
                                clearable={clearable}
                                formatOptionAffix={formatOptionAffix}
                                formatOptionTooltip={formatOptionTooltip}
                              />
                            </Stack>
                          </AccordionDetails>
                        </SelectAccordion>
                      )}
                      {inline && !accordion && (
                        <Stack className="mb-1" direction="row">
                          <Typography variant="subtitle2">{group.label}</Typography>

                          {multiple && group.canSelectAll && (
                            <SelectButton hasAllSelected={hasAllSelected} onClick={() => groupAction(group)} />
                          )}
                        </Stack>
                      )}
                      <div
                        className={classNames({
                          "flex": true,
                          "flex-col": !inline,
                          "flex-wrap gap-2": inline,
                        })}
                      >
                        {group.options.length === 0 && (
                          <div className="flex items-center px-2 py-1.5 text-left text-sm text-gray-500">
                            No matching results
                          </div>
                        )}

                        {!accordion && (
                          <GroupOptionFormat
                            filteredGroups={filteredGroups}
                            formatOption={formatOption}
                            group={group}
                            groupIndex={groupIndex}
                            inline={inline}
                            onOptionClick={onOptionSelect}
                            optionToId={optionToId}
                            selectedIndex={selectedIndex}
                            value={value}
                            clearable={clearable}
                            formatOptionAffix={formatOptionAffix}
                            formatOptionTooltip={formatOptionTooltip}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                {!filteredGroups.length && (
                  <Stack className="py-3 px-2">
                    <Typography color="text.secondary">No result matches your search</Typography>
                  </Stack>
                )}
              </div>
            </Popover.Panel>,
            document.querySelector(portalRoot) as HTMLDivElement
          )}
        </>
      )}
    </Popover>
  );
};

type FilteredGroup<T> = {
  label: string | JSX.Element;
  options: T[];
  canSelectAll: boolean;
};

type GroupOptionFormatProps<T> = {
  filteredGroups: FilteredGroup<T>[];
  group: FilteredGroup<T>;
  value: T[];
  inline: boolean;
  clearable?: boolean;
  groupIndex: number;
  selectedIndex: number | null;
  optionToId: (value: T) => string | number;
  formatOptionAffix?: (value: T) => string | JSX.Element | null;
  formatOptionTooltip?: (value: T) => JSX.Element;
  formatOption: (value: T, options: { selected: boolean }) => string | JSX.Element;
  onOptionClick: (option: T) => void;
};

const GroupOptionFormat = <T,>({
  group,
  inline,
  optionToId,
  value,
  formatOptionAffix,
  formatOptionTooltip,
  formatOption,
  clearable,
  onOptionClick,
  filteredGroups,
  groupIndex,
  selectedIndex,
}: GroupOptionFormatProps<T>): JSX.Element => {
  return (
    <>
      {group.options.map((option, index) => {
        const id = optionToId(option);
        const isSelected = !!value.find((item) => optionToId(item) === id);
        const affix = formatOptionAffix?.(option);
        const fullIndex =
          sumBy(filteredGroups.slice(0, groupIndex), (group) => {
            return group.options.length;
          }) + index;
        const isPreSelected = selectedIndex === fullIndex;
        const formattedOption = formatOption(option, { selected: isSelected });

        return (
          <Tooltip
            key={id}
            title={formatOptionTooltip?.(option) ?? <></>}
            disableHoverListener={!formatOptionTooltip}
            placement="right"
          >
            <div
              className={classNames({
                "group flex cursor-pointer items-center justify-between whitespace-nowrap text-left hover:text-gray-900 focus:outline-none":
                  true,
                "px-2 py-1.5": !inline,
                "bg-primary-200 text-primary-900": !inline && isSelected && !isPreSelected,
                "bg-white text-gray-900 hover:bg-primary-100": !inline && !isSelected,
                "bg-primary-200": !inline && isPreSelected && !isSelected,
                "bg-red-200": !inline && isPreSelected && isSelected,
                "hover:bg-red-100": !inline && isSelected && clearable,
                "hover:text-white": !inline && isSelected && !clearable,
              })}
              data-full-index={fullIndex}
              onClick={(event) => {
                event.stopPropagation();
                onOptionClick(option);
              }}
              role="button"
            >
              <div className="flex items-center space-x-2 text-sm">
                {inline && (
                  <div
                    className={classNames({
                      "group flex items-center rounded-md border px-1 text-sm": true,
                      "border-primary-400 hover:border-gray-200": isSelected,
                      "hover:border-primary-400 hover:bg-primary-100": !isSelected,
                    })}
                  >
                    <Typography typography={{ "text-transform": "capitalize" }}>{formattedOption}</Typography>
                    {isSelected && (
                      <Icon className="ml-1 text-primary-400 group-hover:text-gray-400" name="plain-close" />
                    )}
                  </div>
                )}
                {!inline && <div>{formattedOption}</div>}
                {affix && !inline && (
                  <span
                    className={classNames({
                      "-mb-0.5 text-xs": true,
                      "text-gray-500": !isSelected,
                      "text-primary-700": isSelected,
                    })}
                  >
                    {affix}
                  </span>
                )}
              </div>

              {(option as EditableOption).onEdit && (
                <IconButton
                  size="small"
                  className="-my-2 hidden group-hover:flex"
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    event.preventDefault();
                    (option as EditableOption).onEdit?.();
                  }}
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              )}

              {isSelected && clearable && !inline && (
                <>
                  <Icon
                    className={classNames({
                      "group-hover:hidden": true,
                      "text-red-400": isPreSelected,
                    })}
                    name={isPreSelected ? "close" : "check"}
                  />
                  <Icon className="hidden text-red-400 group-hover:block" name="close" />
                </>
              )}
            </div>
          </Tooltip>
        );
      })}
    </>
  );
};

const SelectAccordion = styled(Accordion)(() => {
  return {
    "width": "380px",
    "minHeight": "32px",
    "boxShadow": "none",
    "&.Mui-expanded": {
      margin: 0,
    },
    "::before": {
      display: "none",
    },
  };
});

const SelectAccordionSummary = styled(AccordionSummary)(() => ({
  "flexDirection": "row-reverse",
  "minHeight": "32px",
  "padding": "0",
  "&.Mui-expanded": {
    minHeight: "32px",
  },
  "& .MuiAccordionSummary-contentGutters": {
    "margin": "0",
    "whiteSpace": "nowrap",
    "&.Mui-expanded": {
      margin: "0",
    },
  },
}));

type SelectButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hasAllSelected: boolean;
};

const SelectButton: React.FC<SelectButtonProps> = ({ onClick, hasAllSelected }) => (
  <button
    className={classNames({
      "ml-auto text-xs text-gray-400": true,
      "hover:bg-primary-20 hover:text-primary-500": !hasAllSelected,
      "hover:bg-red-20 hover:text-red-500": hasAllSelected,
    })}
    onClick={onClick}
  >
    {hasAllSelected ? "Remove all" : "Add all"}
  </button>
);
