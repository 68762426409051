import {
  type Company,
  type Currency,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { chain, compact, isBoolean, isNil, isString } from "lodash";
import uniqid from "uniqid";
import { mixed, object, string } from "yup";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { parseExcelDate } from "~/lib/dates";
import { companyHasAccessToBulkInviteUsers } from "~/lib/hris/helpers/company-has-access-to-bulk-invite-users";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/compute-additional-field-value-payloads";
import { getStaticModelsForSync } from "~/lib/integration";
import { logInfo } from "~/lib/logger";
import { parseMoney } from "~/lib/money";
import { assertNotNil } from "~/lib/utils";
import { initialImportColumnsMap } from "~/services/initial-import-columns-map";
import { findOrCreatePerformanceReviewCycle } from "~/services/performance-review/find-or-create-performance-review-cycle";
import { savePerformanceReviewRatings } from "~/services/performance-review/save-performance-review-ratings";
import { synchroniseCompanySalaryRangeEmployees } from "~/services/salary-bands/configuration/synchronise-company-salary-range-employees";
import { prepareAndSyncHierarchicalRelationships } from "~/services/synchronization/post-sync-external-employees";
import { suggestMappingForExternalJobs } from "~/services/synchronization/suggest-mapping-for-external-jobs";
import { syncExternalEmployee } from "~/services/synchronization/sync-external-employee";
import {
  type EmployeeData,
  handleDeletedEmployees,
  integrationSettingsForSyncInclude,
} from "~/services/synchronization/sync-external-employees";
import { validateRowsWithSchema, XlsxImportError, type XlsxToJsonResult } from "~/services/xlsx-to-json";

export const INITIAL_IMPORT_SHEET_NAME = "1. Individual Data Collection";

export type ImportMode = "erase" | "update";

type ImportInitialExternalEmployeesInput = {
  company: Company;
  collectedAt: Date;
  importMode: ImportMode;
  rows: XlsxToJsonResult["rows"];
};

export const importInitialExternalEmployeesSchema = (currencies: Pick<Currency, "code">[]) => {
  return object({
    employeeNumber: string()
      .required()
      .transform((value) => {
        return `${value}`;
      }),
    firstName: string(),
    lastName: string(),
    email: string(),
    jobTitle: string()
      .required()
      .transform((value) => {
        return value.trim();
      }),
    level: string().required(),
    currency: string()
      .required()
      .oneOf(
        currencies.map((currency) => {
          return currency.code;
        })
      )
      .transform((value) => {
        return value.substr(0, 3);
      }),
    baseSalary: mixed<number>()
      .required()
      .transform(parseMoney)
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => !!value && value >= 0,
      }),
    onTargetBonus: mixed<number>()
      .transform((value) => {
        if (value === "") {
          return null;
        }
        return parseMoney(value);
      })
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => (isNil(value) ? true : value >= 0),
      }),
    fixedBonus: mixed<number>()
      .transform((value) => {
        if (value === "") {
          return null;
        }
        return parseMoney(value);
      })
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => (isNil(value) ? true : value >= 0),
      }),
    isFounder: mixed<boolean>().transform((value) => {
      if (isString(value)) {
        return value.toUpperCase() === "YES";
      } else if (isBoolean(value)) {
        return value;
      }
    }),
    manager: string().transform((value) => {
      return `${value}`;
    }),
    location: string().required(),
    performanceRating: string(),
    hireDate: mixed<Date>().nullable().transform(parseExcelDate),
    birthDate: mixed<Date>().nullable().transform(parseExcelDate),
    businessUnit: string(),
    gender: mixed<Gender>()
      .required()
      .oneOf([Gender.MALE, Gender.FEMALE, Gender.UNDISCLOSED])
      .transform((value) => {
        if (value === "Non-binary / Not disclosed") {
          return Gender.UNDISCLOSED;
        }

        if (value.toLowerCase() === "male") {
          return Gender.MALE;
        }

        if (value.toLowerCase() === "female") {
          return Gender.FEMALE;
        }

        return value.toUpperCase();
      }),
  });
};

export const importInitialExternalEmployees = async (
  ctx: AppContext,
  input: ImportInitialExternalEmployeesInput
): Promise<void> => {
  const staticModels = await getStaticModelsForSync(ctx);

  const validRows = validateRowsWithSchema(
    ctx,
    input.rows,
    initialImportColumnsMap(ctx),
    importInitialExternalEmployeesSchema(staticModels.currencies)
  );

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: input.company.id },
    include: {
      integrationSettings: {
        include: integrationSettingsForSyncInclude,
      },
      defaultCountry: true,
    },
  });

  const survey = await value(() => {
    if (input.company.liveSurveyId) {
      return ctx.prisma.companySurvey.update({
        where: { id: input.company.liveSurveyId },
        data: {
          collectedAt: input.collectedAt,
        },
      });
    } else {
      return ctx.prisma.companySurvey.create({
        data: {
          status: "LIVE",
          collectedAt: input.collectedAt,
          company: {
            connect: { id: company.id },
          },
        },
      });
    }
  });

  await ctx.prisma.company.update({
    where: { id: company.id },
    data: {
      lastSpreadsheetImportedAt: new Date(),
      liveSurvey: {
        connect: { id: survey.id },
      },
    },
  });

  const employeeNumbers = validRows.map((row) => {
    return row.employeeNumber;
  });

  // We shouldn't import the XLSX if there are any duplicate employee numbers
  const duplicateEmployeeNumbers = chain(employeeNumbers)
    .groupBy()
    .pickBy((occurrences) => occurrences.length > 1)
    .keys()
    .value();

  if (duplicateEmployeeNumbers.length) {
    throw new XlsxImportError(
      `Aborted import : the following employee numbers have duplicates (${duplicateEmployeeNumbers.join(",")})`
    );
  }

  // Handle employees that may have been deleted since the last import
  if (input.importMode === "erase") {
    const deletedEmployeesNumber = await handleDeletedEmployees(ctx, {
      companyId: company.id,
      employeeNumbers,
    });

    logInfo(
      ctx,
      `[Import Initial External Employees] Deleted ${deletedEmployeesNumber} employees that were not in the spreadsheet`
    );
  }

  const externalEmployees = await mapSeries(validRows, async (row) => {
    const currency = staticModels.currencies.find((currency) => {
      return currency.code === row.currency;
    }) as Currency;

    const hasAccessToEmails = await companyHasAccessToBulkInviteUsers(ctx, company.id);

    const externalEmployeePayload: EmployeeData["input"] = {
      source: ExternalEmployeeSource.SPREADSHEET,
      externalId: uniqid(),
      employeeNumber: row.employeeNumber,
      status: ExternalEmployeeStatus.UNMAPPED,
      firstName: row.firstName,
      lastName: row.lastName,
      email: hasAccessToEmails ? row.email : null,
      gender: row.gender,
      isFounder: row.isFounder || false,
      hireDate: row.hireDate,
      birthDate: row.birthDate,
      businessUnit: row.businessUnit,
      company: {
        connect: { id: company.id },
      },
      currency: { connect: { id: currency.id } },
      location: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: row.location,
            },
          },
          create: {
            name: row.location,
            externalId: row.location,
            autoMappingEnabled: true,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: row.jobTitle,
            },
          },
          create: {
            name: row.jobTitle,
            externalId: row.jobTitle,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: row.level,
            },
          },
          create: {
            name: row.level,
            externalId: row.level,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    };

    const remunerationItems: EmployeeData["remunerationItems"] = [
      {
        source: ExternalEmployeeSource.SPREADSHEET,
        externalId: "fixed-salary",
        amount: row.baseSalary || 0,
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: company.id,
                source: ExternalEmployeeSource.SPREADSHEET,
                externalId: "fixed-salary",
              },
            },
            create: {
              source: ExternalEmployeeSource.SPREADSHEET,
              externalId: "fixed-salary",
              name: "Basic salary",
              mappedType: ExternalRemunerationType.FIXED_SALARY,
              company: { connect: { id: company.id } },
            },
          },
        },
      },
    ];

    if (row.fixedBonus) {
      remunerationItems.push({
        source: ExternalEmployeeSource.SPREADSHEET,
        externalId: "fixed-bonus",
        amount: row.fixedBonus,
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: company.id,
                source: ExternalEmployeeSource.SPREADSHEET,
                externalId: "fixed-bonus",
              },
            },
            create: {
              source: ExternalEmployeeSource.SPREADSHEET,
              externalId: "fixed-bonus",
              name: "Fixed bonus",
              mappedType: ExternalRemunerationType.FIXED_BONUS,
              company: { connect: { id: company.id } },
            },
          },
        },
      });
    }

    if (row.onTargetBonus) {
      remunerationItems.push({
        source: ExternalEmployeeSource.SPREADSHEET,
        externalId: "variable-bonus",
        amount: row.onTargetBonus,
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: company.id,
                source: ExternalEmployeeSource.SPREADSHEET,
                externalId: "variable-bonus",
              },
            },
            create: {
              source: ExternalEmployeeSource.SPREADSHEET,
              externalId: "variable-bonus",
              name: "Variable bonus",
              mappedType: ExternalRemunerationType.VARIABLE_BONUS,
              company: { connect: { id: company.id } },
            },
          },
        },
      });
    }

    const additionalFieldValues = await value(async () => {
      if (Object.keys(row.additionalFields).length === 0) {
        return [];
      }

      const additionalFieldNames = Object.keys(row.additionalFields);
      const additionalFields = await ctx.prisma.additionalField.findMany({
        where: { name: { in: additionalFieldNames }, companyId: company.id },
      });

      return additionalFields.map(({ id, name }) => ({
        additionalFieldId: id,
        value: row.additionalFields[name]?.stringValue ?? null,
      }));
    });

    const { externalEmployee } = await syncExternalEmployee(
      ctx,
      {
        input: externalEmployeePayload,
        remunerationItems,
        additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
      },
      { company: company, staticModels, discriminatorKey: "employeeNumber" }
    );

    return { ...externalEmployee, manager: row.manager, performanceRating: row.performanceRating };
  });

  const performanceReviewCycle = await findOrCreatePerformanceReviewCycle(ctx, {
    companyId: company.id,
    performanceReviewScope: null,
  });

  const externalEmployeesWithRatings = chain(externalEmployees)
    .filter(({ performanceRating }) => !!performanceRating)
    .map(({ id, performanceRating }) => ({ id, performanceRating: assertNotNil(performanceRating) }))
    .value();

  await savePerformanceReviewRatings(ctx, {
    performanceReviewCycleId: performanceReviewCycle.id,
    externalEmployees: externalEmployeesWithRatings,
  });

  const hierarchy = compact(
    await mapSeries(externalEmployees, async (externalEmployee) => {
      const manager = await value(async () => {
        if (!externalEmployee.manager) {
          return null;
        }

        return ctx.prisma.externalEmployee.findFirst({
          where: {
            companyId: company.id,
            employeeNumber: externalEmployee.manager,
          },
          select: { id: true, externalId: true },
        });
      });

      if (!manager) {
        return null;
      }

      return {
        source: externalEmployee.source,
        externalEmployeeId: externalEmployee.id,
        managerExternalId: manager.externalId,
      };
    })
  );

  await prepareAndSyncHierarchicalRelationships(ctx, hierarchy, company);

  await suggestMappingForExternalJobs(ctx, company.id);

  await synchroniseCompanySalaryRangeEmployees(ctx, { companyId: company.id });
};
