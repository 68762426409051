import { type ExternalEmployeeDriftField } from "@prisma/client";
import { type Column, Table } from "~/components/ui/core/Table";
import { useI18n } from "~/lib/i18n/use-i18n";
import { formatLevel } from "~/services/employee/employee-level";
import { type ExternalEmployeeRow, type MappedEmployeeRow } from "~/services/external-employee/external-employee-read";

type ExternalEmployeeDiffProps = {
  externalEmployee: ExternalEmployeeRow;
  employee: MappedEmployeeRow;
};

type ExternalEmployeeField = {
  type: ExternalEmployeeDriftField;
  name: string;
  mapA: (employee: ExternalEmployeeRow) => string | JSX.Element;
  mapB: (employee: MappedEmployeeRow) => string | JSX.Element;
  canApply?: () => boolean;
};

export const ExternalEmployeeDiff: React.FC<ExternalEmployeeDiffProps> = ({ externalEmployee, employee }) => {
  const { t } = useI18n();

  const fields: ExternalEmployeeField[] = [
    {
      type: "JOB",
      name: t("components.employee.external-employee-diff.new-job"),
      mapA: (externalEmployee) => {
        return externalEmployee.job?.name ?? "∅";
      },
      mapB: (employee) => {
        return employee.job?.name ?? "∅";
      },
    },
    {
      type: "LEVEL",
      name: t("components.employee.external-employee-diff.new-level"),
      mapA: (externalEmployee) => {
        return externalEmployee.level?.name ?? "∅";
      },
      mapB: (employee) => {
        return formatLevel(t, employee.level) ?? "∅";
      },
    },
    {
      type: "LOCATION",
      name: t("components.employee.external-employee-diff.new-location"),
      mapA: (externalEmployee) => {
        return externalEmployee.location?.name ?? "∅";
      },
      mapB: (employee) => {
        return employee.location.name ?? "∅";
      },
    },
  ];

  const modifiedFields = fields.filter((field) => {
    return externalEmployee.driftFields.includes(field.type);
  });

  const columns: Column<ExternalEmployeeField>[] = [
    {
      id: "field",
      name: t("components.employee.external-employee-diff.field"),
      selector: (field) => {
        return field.name;
      },
      format: function Cell(field) {
        return <span className="text-sm text-gray-600">{field.name}</span>;
      },
    },
    {
      id: "yourValue",
      name: t("components.employee.external-employee-diff.your-value"),
      format: function Cell(field) {
        return <div className="text-sm text-red-600">{field.mapA(externalEmployee)}</div>;
      },
    },
    {
      id: "separator",
      format: function Cell() {
        return <>&rarr;</>;
      },
    },
    {
      id: "mappedTo",
      name: t("components.employee.external-employee-diff.mapped-to"),
      format: function Cell(field) {
        return <div className="text-sm text-red-600">{field.mapB(employee)}</div>;
      },
    },
  ];

  return <Table dense columns={columns} data={modifiedFields} />;
};
