import { PerformanceReviewIntegrationSource, type Prisma } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { mixed, object, string } from "yup";
import { api } from "~/lib/api";
import { type AppContext } from "~/lib/context";
import { notifyPerformanceReviewIntegrationSetup } from "~/lib/external/slack/notifications";
import { getRequiredUser } from "~/lib/get-required-user";
import { logError } from "~/lib/logger";
import { getCultureAmpDiagnostic } from "~/lib/performance-review/culture-amp";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { FETCH_AVAILABLE_CULTURE_AMP_PERFORMANCE_REVIEW_CYCLES_QUERY_KEY } from "~/pages/api/performance-review/culture-amp/fetch-available-culture-amp-performance-review-cycles";
import { FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_DIAGNOSTIC_QUERY_KEY } from "~/pages/api/performance-review/fetch-company-performance-review-integration-diagnostic";
import { FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_SETTINGS_QUERY_KEY } from "~/pages/api/performance-review/fetch-company-performance-review-integration-settings";
import { type PerformanceReviewIntegrationDiagnostic } from "~/pages/api/sync-performance-reviews";
export const PerformanceReviewIntegrationInputSchema = object({
  source: mixed<PerformanceReviewIntegrationSource>()
    .oneOf(Object.values(PerformanceReviewIntegrationSource))
    .required(),
  clientId: string().min(1).nullable().defined(),
  clientSecret: string().min(1).required(),
});

export type PerformanceReviewIntegrationInput = YupOutputType<typeof PerformanceReviewIntegrationInputSchema>;

export const CultureAmpInputSchema = PerformanceReviewIntegrationInputSchema.concat(
  object({
    clientId: string().required(),
    clientSecret: string().required(),
  })
);

export const validatePerformanceReviewIntegrationSettingsInput = async (
  integrationSettings: PerformanceReviewIntegrationInput
) => {
  return match(integrationSettings.source)
    .with(PerformanceReviewIntegrationSource.CULTURE_AMP, async () =>
      CultureAmpInputSchema.validate(integrationSettings)
    )
    .exhaustive();
};

type ValidatedPerformanceReviewIntegrationSettingsInput = AsyncReturnType<
  typeof validatePerformanceReviewIntegrationSettingsInput
>;

export const getPerformanceReviewDiagnostic = async (
  ctx: AppContext,
  input: ValidatedPerformanceReviewIntegrationSettingsInput
): Promise<PerformanceReviewIntegrationDiagnostic> => {
  return match(input.source)
    .with(PerformanceReviewIntegrationSource.CULTURE_AMP, async () => getCultureAmpDiagnostic(ctx, input))
    .exhaustive();
};

export const connectAndSavePerformanceReviewIntegration = async (
  ctx: AppContext,
  input: PerformanceReviewIntegrationInput
) => {
  const user = getRequiredUser(ctx);

  const diagnostic: PerformanceReviewIntegrationDiagnostic = await getPerformanceReviewDiagnostic(
    ctx,
    input as ValidatedPerformanceReviewIntegrationSettingsInput
  );

  if (diagnostic.connected) {
    const payload: Prisma.PerformanceReviewIntegrationSettingsCreateWithoutCompanyInput = {
      source: input.source,
      clientId: input.clientId,
      clientSecret: input.clientSecret,
      enabled: true,
    };

    await ctx.prisma.performanceReviewIntegrationSettings.upsert({
      where: { companyId_source: { companyId: user.companyId, source: input.source } },
      create: {
        ...payload,
        companyId: user.companyId,
      },
      update: payload,
    });

    await notifyPerformanceReviewIntegrationSetup(ctx, { user, integration: input.source });
  }

  return diagnostic;
};

const handler: NextApiHandler<PerformanceReviewIntegrationDiagnostic> = async (req, res) => {
  const input = await PerformanceReviewIntegrationInputSchema.validate(req.body, {
    abortEarly: false,
  });

  try {
    const validatedInput = await validatePerformanceReviewIntegrationSettingsInput(input);

    const diagnostic = await connectAndSavePerformanceReviewIntegration(req, validatedInput);

    res.status(HttpStatus.CREATED).json(diagnostic);
  } catch (error) {
    logError(req, `[${input.source}] Error when connecting to company account`, {
      companyId: req.user?.companyId,
      error,
    });

    res.status(HttpStatus.ACCEPTED).json({ connected: false, error: error.message });
  }
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessIntegrations: true },
});

export const useConnectToPerformanceReviewIntegrationMutation = createMutation<
  typeof handler,
  typeof PerformanceReviewIntegrationInputSchema
>({
  path: "/api/performance-review/connect-to-performance-review-integration",
  schema: PerformanceReviewIntegrationInputSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, FETCH_AVAILABLE_CULTURE_AMP_PERFORMANCE_REVIEW_CYCLES_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_SETTINGS_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_DIAGNOSTIC_QUERY_KEY);
    },
  }),
});
