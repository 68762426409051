import { PerformanceReviewIntegrationSource } from "@prisma/client";
import { type NextApiHandler } from "next";
import { type AsyncReturnType } from "type-fest";
import { mixed, number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api, ApiAuthenticationError } from "~/lib/api";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  getPerformanceReviewDiagnostic,
  validatePerformanceReviewIntegrationSettingsInput,
} from "~/pages/api/performance-review/connect-to-performance-review-integration";
export const FetchCompanyPerformanceReviewIntegrationDiagnosticSchema = object({
  companyId: number().required(),
  source: mixed<PerformanceReviewIntegrationSource>()
    .oneOf(Object.values(PerformanceReviewIntegrationSource))
    .required(),
});

export type FetchCompanyPerformanceReviewIntegrationDiagnosticInput = YupOutputType<
  typeof FetchCompanyPerformanceReviewIntegrationDiagnosticSchema
>;

export const handler: NextApiHandler<CompanyPerformanceReviewIntegrationDiagnostic> = async (req, res) => {
  const input = await FetchCompanyPerformanceReviewIntegrationDiagnosticSchema.validate(req.body, {
    abortEarly: false,
  });

  if (!getRequiredUser(req).isSuperAdmin && getRequiredUser(req).companyId !== input.companyId) {
    throw new ApiAuthenticationError("Unauthorized access");
  }

  const diagnostic = await fetchCompanyPerformanceReviewIntegrationDiagnostic(req, input);

  if (!diagnostic) {
    return res.status(200).json(null);
  }

  return res.status(200).json(diagnostic);
};

type CompanyPerformanceReviewIntegrationDiagnostic = AsyncReturnType<
  typeof fetchCompanyPerformanceReviewIntegrationDiagnostic
>;
const fetchCompanyPerformanceReviewIntegrationDiagnostic = async (
  ctx: AppContext,
  input: FetchCompanyPerformanceReviewIntegrationDiagnosticInput
) => {
  const performanceReviewIntegrationSettings = await ctx.prisma.performanceReviewIntegrationSettings.findUnique({
    where: { companyId_source: input },
  });

  if (!performanceReviewIntegrationSettings) {
    return null;
  }

  const safePerformanceReviewIntegrationSettings = await validatePerformanceReviewIntegrationSettingsInput(
    performanceReviewIntegrationSettings
  );

  return getPerformanceReviewDiagnostic(ctx, safePerformanceReviewIntegrationSettings);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessSettings: true },
});

export const FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_DIAGNOSTIC_QUERY_KEY: ApiRoute["pathname"] =
  "/api/performance-review/fetch-company-performance-review-integration-diagnostic";

export const useFetchCompanyPerformanceReviewIntegrationDiagnosticQuery = createQuery<
  typeof handler,
  typeof FetchCompanyPerformanceReviewIntegrationDiagnosticSchema
>({
  path: FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_DIAGNOSTIC_QUERY_KEY,
  schema: FetchCompanyPerformanceReviewIntegrationDiagnosticSchema,
});
