import { type User, UserProvider } from "@prisma/client";
import { mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { decryptFromStorage, encryptForStorage } from "~/lib/crypto";
import { sendUserProviderConfirmationEmail } from "~/lib/external/sendinblue/templates";
import { type YupOutputType } from "~/lib/utils";
import { type ProfileData } from "~/services/auth/profile-data";
import { formatUserProvider } from "~/services/user/utils";

export class AuthenticationRequiresProviderConfirmationError extends Error {
  constructor(
    public email: string,
    public provider: string
  ) {
    super("Authentication requires provider confirmation");
    Object.setPrototypeOf(this, AuthenticationRequiresProviderConfirmationError.prototype);
  }
}

export const requireUserProviderConfirmation = async (
  ctx: AppContext,
  params: {
    user: Pick<User, "id" | "email">;
    profileData: Pick<ProfileData, "provider" | "providerId">;
  }
) => {
  const token = encryptUserData({
    id: params.user.id,
    provider: params.profileData.provider,
    providerId: params.profileData.providerId,
  });

  await sendUserProviderConfirmationEmail(ctx, {
    email: params.user.email,
    token,
  });

  throw new AuthenticationRequiresProviderConfirmationError(
    params.user.email,
    formatUserProvider(ctx.t_en, params.profileData.provider)
  );
};

export const encryptUserData = (userData: YupOutputType<typeof UserDataSchema>) => {
  return encryptForStorage(JSON.stringify(userData));
};

const UserDataSchema = object({
  id: number().required(),
  provider: mixed<UserProvider>().oneOf(Object.values(UserProvider)).required(),
  providerId: string().required(),
});

export const confirmUserProvider = async (ctx: AppContext, token: string) => {
  const userData = UserDataSchema.validateSync(JSON.parse(decryptFromStorage(token)));

  return ctx.prisma.user.update({
    where: { id: userData.id },
    data: {
      provider: userData.provider,
      providerId: userData.providerId,
    },
    select: { id: true },
  });
};
