import { createCipheriv, createDecipheriv, createHash, createHmac, randomBytes } from "crypto";
import { config } from "~/config";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";

// AES 256 takes a 32 byte string as key
const storageKey = createHash("sha256").update(String(config.app.password)).digest("base64").slice(0, 32);

export const encryptForStorage = (clearMessage: string) => {
  const iv = randomBytes(16);
  const cipher = createCipheriv("aes-256-gcm", storageKey, iv);

  const encryptedMessage = cipher.update(clearMessage, "utf8", "hex") + cipher.final("hex");

  const payload = JSON.stringify({
    iv: iv.toString("hex"),
    authTag: cipher.getAuthTag().toString("hex"),
    encryptedMessage,
  });
  return Buffer.from(payload).toString("hex");
};

type EncryptedMessageWithIv = { iv: string; authTag: string; encryptedMessage: string };

export const decryptFromStorage = (message: string) => {
  const json = Buffer.from(message, "hex").toString();
  const { iv, authTag, encryptedMessage } = JSON.parse(json) as EncryptedMessageWithIv;

  const decipher = createDecipheriv("aes-256-gcm", storageKey, Buffer.from(iv, "hex"));
  decipher.setAuthTag(Buffer.from(authTag, "hex"));

  const clearMessage = decipher.update(encryptedMessage, "hex", "utf-8") + decipher.final();

  return clearMessage;
};

export const generateUserHMAC = (user: NullableAuthenticatedUser) => {
  if (!user) {
    return null;
  }

  return createHmac("SHA256", config.intercom.secretKey).update(user.id.toString()).digest("hex");
};
