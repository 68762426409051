import { subject } from "@casl/ability";
import { type WhereInputPerModel } from "@casl/prisma/dist/types/prismaClientBoundTypes";
import { Prisma, type PrismaClient, UserLocale } from "@prisma/client";
import { cloneDeep, compact, isArray, isSymbol, mapValues, pick, upperFirst } from "lodash";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { addTranslationsToArgs, translatePrismaResponse } from "~/lib/i18n/prisma-i18n";
import { convertPrismaBigInts } from "~/lib/prisma-bigint-patch";
import { applyDynamicPrismaRestrictions } from "~/lib/prisma-restrictions/apply-dynamic-prisma-restrictions";
import { generatePrismaRestrictionsSchemasFn } from "~/lib/prisma-restrictions/generate-prisma-restrictions-schema-fn";
import { DangerouslyIncludeSoftDeletedExternalEmployeesToken } from "~/lib/prisma-restrictions/schemas/generate-external-employees-soft-delete-schema";
import { DangerouslyIncludeHistoricalExternalRemunerationItemsToken } from "~/lib/prisma-restrictions/schemas/generate-external-remuneration-item-historical-schema";
import {
  applyPrismaRestrictions,
  ApplyPrismaRestrictionsToken,
  DangerouslyIgnorePrismaRestrictionsToken,
  DebugQueryToken,
  removeTokensFromPayload,
} from "~/lib/prisma-tokens";
import {
  type PrismaDelegateFunction,
  type PrismaMethod,
  type PrismaModel,
  type PrismaPayload,
  prismaSchemaDef,
  ProtectedPrismaUniqueMethods,
} from "~/lib/prisma-types";
import { isIn } from "~/lib/utils";
import { type AppAbility } from "~/services/permissions/app-ability";
import { createAbility } from "~/services/permissions/create-ability";
import { BLACKLISTED_MODELS_NOT_TO_RESTRICT } from "~/services/permissions/public-permissions";

export const restrictDeepIncludeOrSelect = (
  upperFirstModelName: Prisma.ModelName,
  includeOrSelect: PrismaPayload,
  prismaRestrictions: WhereInputPerModel
): PrismaPayload => {
  const getModelRestrictions = (
    modelName: Prisma.ModelName,
    property: Record<string, unknown>,
    value: PrismaPayload,
    key: string
  ) => {
    if (!Object.hasOwn(Prisma.ModelName, modelName)) {
      // Happens when reaching the last leaf of a branch and the value is not a protected model
      // e.g. include: { externalLocations: true }
      return value;
    }

    const prismaModelRestriction = prismaRestrictions[modelName];

    if (Object.keys(prismaModelRestriction).length === 0) {
      // Happens when model is restricted but user has no restriction defined
      // e.g. user can read all jobs
      return value;
    }

    if (property.type === "array") {
      // Handles *-to-many relationships
      return {
        where: {
          ...includeOrSelect[key].where,
          ...prismaModelRestriction,
        },
      };
    }

    // Supposedly reaching this point means we don't really care so far
    // about the value and just want to keep it as is
    // e.g. fetching the employee's manager { include: { manager: true } }
    return value;
  };

  const properties = prismaSchemaDef.definitions[upperFirstModelName].properties;

  return mapValues(includeOrSelect, (value, key) => {
    if (!Object.hasOwn(properties, key)) {
      // Happens when querying a property not directly related to the model
      // e.g. user: { select: { companyId: true } }
      return value;
    }

    const property = properties[key];
    const anyOfItem = property.anyOf?.find((item: Record<string, string>) => !!item?.$ref);
    const reference = property?.$ref || property.items?.$ref || anyOfItem?.$ref;

    if (!reference) {
      // Happens when reaching the last leaf of a branch and the value is a simple one
      // e.g. defaultCurrency: { select: { code: true } }
      return value;
    }

    const correspondingDependencyModel = reference.replace("#/definitions/", "") as keyof typeof Prisma.ModelName;

    const restrictedValue = {
      ...getModelRestrictions(correspondingDependencyModel, property, value, key),
      ...pick(value, ["orderBy", "cursor", "take", "skip", "distinct"]),
    };

    // Recursively restricting nested include or select statements and
    // since Prisma doesn't allow for both statements on the same level,
    // this is enough to handle both cases
    if (value.include || value.select) {
      const restrictedNestedIncludeOrSelect = restrictDeepIncludeOrSelect(
        correspondingDependencyModel,
        value.include ?? value.select,
        prismaRestrictions
      );

      const statement = value.include ? "include" : "select";

      return {
        ...restrictedValue,
        [statement]: restrictedNestedIncludeOrSelect,
      };
    }

    return restrictedValue;
  });
};

export const restrictPrismaParams = ({
  model,
  args,
  method,
  actionRestrictions,
  readRestrictions,
}: {
  model: string;
  args: PrismaPayload;
  method?: PrismaMethod;
  actionRestrictions: WhereInputPerModel;
  readRestrictions: WhereInputPerModel;
}) => {
  const modelName = getPrismaModelName(model);
  const isProtectedModel = Object.hasOwn(Prisma.ModelName, modelName);

  if (args.include) {
    args.include = restrictDeepIncludeOrSelect(modelName, args.include, readRestrictions);
  }

  if (args.select) {
    args.select = restrictDeepIncludeOrSelect(modelName, args.select, readRestrictions);
  }

  if (!isProtectedModel) {
    return args;
  }

  // Handling of combined keys here, such has bandId_levelId
  if (isIn(method, ProtectedPrismaUniqueMethods)) {
    args.where = {
      ...args.where,
      ...actionRestrictions[modelName],
    };

    return args;
  }

  const whereValues = compact([args.where, actionRestrictions[modelName]]);

  if (whereValues.length === 1) {
    args.where = whereValues[0];
  }

  if (whereValues.length > 1) {
    args.where = {
      AND: whereValues,
    };
  }

  return args;
};

const prismaActionAndMethodMapping = {
  findFirst: "read",
  findFirstOrThrow: "read",
  findMany: "read",
  count: "read",
  aggregate: "read",
  groupBy: "read",
  findUnique: "read",
  findUniqueOrThrow: "read",
  deleteMany: "delete",
  delete: "delete",
  updateMany: "update",
  update: "update",
  upsert: "create",
  create: "create",
  createMany: "create",
} as const;

let ORIGINAL_PRISMA_CLIENT: PrismaClient;

export const protectPrismaClient = async (ctx: AppContext) => {
  const isCliOrTest = config.app.isJest || config.app.isCli;
  // We don't want to use the original Prisma client if we are currently in a transaction or if we specified a new permissions scope
  const shouldUseOriginalPrismaClient = isCliOrTest && !!ctx.prisma.$transaction;

  if (shouldUseOriginalPrismaClient && !ORIGINAL_PRISMA_CLIENT) {
    ORIGINAL_PRISMA_CLIENT = ctx.prisma;
  }

  const ability = await createAbility(ctx);
  const applyPrismaRestrictionSchemas = generatePrismaRestrictionsSchemasFn();

  return new Proxy(shouldUseOriginalPrismaClient ? ORIGINAL_PRISMA_CLIENT : ctx.prisma, {
    // WARNING: we moved the types to `any` because of the following issue:
    // tsc was complaining about the complexity of the combination of all our types
    // Before, it was: `target: PrismaClient, modelOrProperty: keyof PrismaClient`
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get: (target: any, modelOrProperty: any) => {
      if (isSymbol(modelOrProperty) || modelOrProperty.startsWith("$") || modelOrProperty.startsWith("_")) {
        return target[modelOrProperty];
      }

      return mapValues(target[modelOrProperty], (fn: PrismaDelegateFunction, method: PrismaMethod) => {
        return async (args: PrismaPayload = {}) => {
          const modelName = getPrismaModelName(modelOrProperty);
          const payloadWithoutTokens = cloneDeep(removeTokensFromPayload(args)) as PrismaPayload;

          if (Object.hasOwn(args, DangerouslyIgnorePrismaRestrictionsToken)) {
            return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
          }

          // Some models are not to be protected until everything is cleaned up
          const isBlacklistedModel = BLACKLISTED_MODELS_NOT_TO_RESTRICT.includes(modelName);

          // Some methods needs to be protected manually, like protectedUpsert below.
          const isManualProtectionRequested = Object.hasOwn(args, ApplyPrismaRestrictionsToken);

          if (!isManualProtectionRequested && isBlacklistedModel) {
            return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
          }

          if (method === "upsert") {
            return protectedUpsert(ctx, ability, modelOrProperty, payloadWithoutTokens);
          }

          if (method === "create") {
            // ⚠️ No protection for creation atm
            // return protectedCreate(originalPrismaClient, ability, modelOrProperty, argsWithoutToken.data);
            return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
          }

          if (method === "createMany") {
            if (!isArray(payloadWithoutTokens.data)) {
              // ⚠️ No protection for creation atm
              // await protectedCreate(originalPrismaClient, ability, modelOrProperty, argsWithoutToken.data);
              // return { count: 1 };
              return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            payloadWithoutTokens.data.forEach((data: any) => {
              if (!!ability && !ability.can("create", subject(modelName, data))) {
                throw new Error(`You are not allowed to create this data`);
              }
            });

            return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
          }

          try {
            const prismaAction = prismaActionAndMethodMapping[method];

            const protectedArgs = value(() => {
              if (!ability) return args;

              const { actionRestrictions, readRestrictions } = applyDynamicPrismaRestrictions({
                ability,
                prismaAction,
                prismaRestrictionSchemas: applyPrismaRestrictionSchemas({
                  includeSoftDeletedExternalEmployees: Object.hasOwn(
                    args,
                    DangerouslyIncludeSoftDeletedExternalEmployeesToken
                  ),
                  includeHistoricalRemunerationItems: Object.hasOwn(
                    args,
                    DangerouslyIncludeHistoricalExternalRemunerationItemsToken
                  ),
                }),
              });

              return restrictPrismaParams({
                model: modelOrProperty,
                args: payloadWithoutTokens,
                method,
                actionRestrictions,
                readRestrictions,
              });
            });

            handleQueryDebug({ originalArgs: args, protectedArgs, modelOrProperty, method });

            return wrapPrismaOperation(fn, protectedArgs, method, modelName, ctx.user?.locale);
          } catch (error) {
            throw error;

            throw new Error(`
            === SECURITY WARNING ===

            ${error.message}

            Incriminated Prisma query:

            prisma.${modelOrProperty}.${method}(${JSON.stringify(args, null, 2)});

            Please check your query against what you expected the restriction to be and report the issue if needed.
            `);
          }
        };
      });
    },
  }) as PrismaClient;
};

export const wrapPrismaOperation = async (
  fn: PrismaDelegateFunction,
  args: PrismaPayload,
  method: PrismaMethod,
  modelName: Prisma.ModelName,
  locale?: UserLocale
) => {
  addTranslationsToArgs(args, modelName, method);

  let res = await fn(args);

  res = translatePrismaResponse(res, method, locale ?? UserLocale.EN);
  res = convertPrismaBigInts(res);

  return res;
};

// Will be useful once we'll re-enact model creation protection
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
const protectedCreate = async (prisma: PrismaClient, ability: AppAbility, model: PrismaModel, data: any) => {
  if (!ability.can("create", subject(getPrismaModelName(model), data))) {
    throw new Error(`You are not allowed to create this data`);
  }

  const prismaDelegate = prisma[model] as Record<PrismaMethod, PrismaDelegateFunction>;
  return prismaDelegate.create({
    data: data,
  });
};

export const protectedUpsert = async (
  ctx: AppContext,
  ability: AppAbility | null,
  model: PrismaModel,
  args: PrismaPayload
) => {
  const prismaDelegate = ctx.prisma[model] as Record<PrismaMethod, PrismaDelegateFunction>;

  // Restricted upsert algorithm is as follows:
  // - check if you have READ access to the data
  //  - if prisma finds it: it exists, so you can proceed to try and update it
  //  - if it doesn't: maybe data exists, but you don't have access to it, or it doesn't exist at all
  //    - to make sure, query the data without restrictions
  //     - if it exists, you are not allowed to READ it, so throw an error
  //     - if it doesn't exist, you can proceed to try and create it

  const queryResult = await prismaDelegate.findUnique({
    ...applyPrismaRestrictions(),
    where: args.where,
  });

  if (!!queryResult) {
    return prismaDelegate.update({
      ...applyPrismaRestrictions(),
      where: args.where,
      data: args.update,
    });
  }

  const queryResultWithoutRestriction = await prismaDelegate.findUnique({
    where: args.where,
  });

  if (!!queryResultWithoutRestriction) {
    throw Error("You are not allowed to read this data");
  }

  if (!!ability && ability.can("create", subject(getPrismaModelName(model), args.create))) {
    return prismaDelegate.create({
      data: args.create,
    });
  }

  throw new Error("You are not allowed to create this data");
};

const getPrismaModelName = (model: PrismaModel | string) => {
  return upperFirst(model) as Prisma.ModelName;
};

const handleQueryDebug = (params: {
  originalArgs: PrismaPayload;
  protectedArgs: PrismaPayload;
  modelOrProperty: string;
  method: string;
}) => {
  if (Object.hasOwn(params.originalArgs, DebugQueryToken)) {
    // eslint-disable-next-line no-console
    console.log(`=== DEBUG QUERY - ORIGINAL REQUEST ===

prisma.${params.modelOrProperty}.${params.method}(${JSON.stringify(params.originalArgs, null, 2)});`);

    // eslint-disable-next-line no-console
    console.log(`\n=== DEBUG QUERY - PROTECTED REQUEST ===

prisma.${params.modelOrProperty}.${params.method}(${JSON.stringify(params.protectedArgs, null, 2)});`);
  }
};
