import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { handleExceptionalRightsChange } from "~/components/layout/user-notices";
import { broadcastTabSyncingEvent } from "~/hooks/use-tab-syncing";
import { api, ApiImpersonationError } from "~/lib/api";
import { createMutation } from "~/lib/react-query";
import { impersonateCompany } from "~/lib/session";
import { hasImpersonationAccess } from "~/services/impersonation/helper";

export const ImpersonateCompanySchema = object({
  companyId: number().required(),
});

const handler: NextApiHandler = async (req, res) => {
  const input = ImpersonateCompanySchema.validateSync(req.body, { abortEarly: false });

  const hasValidImpersonationAccess = await hasImpersonationAccess(req, {
    companyId: input.companyId,
    logAccess: true,
  });

  if (!hasValidImpersonationAccess) {
    throw new ApiImpersonationError("Impersonation not allowed");
  }

  await impersonateCompany(req, { companyId: input.companyId });

  res.status(HttpStatus.OK).json({ message: "OK" });
};

export default api(handler, {
  method: "POST",
  authentication: { superAdmin: true },
});

export const useImpersonateCompanyMutation = createMutation<typeof handler, typeof ImpersonateCompanySchema>({
  path: "/api/auth/company-impersonation/impersonate-company",
  schema: ImpersonateCompanySchema,
  options: () => ({
    onSuccess: (_, variables) => {
      broadcastTabSyncingEvent({
        event: "company-impersonation",
        payload: { companyId: variables.companyId },
      });

      handleExceptionalRightsChange();
    },
  }),
});
