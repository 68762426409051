import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object, string } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { DYNAMIC_MODELS_STALE_TIME } from "~/hooks/useQueries";
import { api } from "~/lib/api";
import { PaginationSchema } from "~/lib/pagination";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  type AutocompleteCompanyExternalEmployees,
  autocompleteCompanyExternalEmployees,
} from "~/services/external-employee/autocomplete-list-company-external-employees";

export const FetchAutocompleteExternalEmployeesForCompany = object({
  query: string().nullable(),
  pagination: PaginationSchema.nullable(),
  companyId: number().required(),
});

export type AutocompleteCompanyExternalEmployeesInput = YupOutputType<
  typeof FetchAutocompleteExternalEmployeesForCompany
>;

export type ListCompanyExternalEmployeesResponse = {
  externalEmployees: AutocompleteCompanyExternalEmployees;
};

const handler: NextApiHandler<ListCompanyExternalEmployeesResponse> = async (req, res) => {
  const input = FetchAutocompleteExternalEmployeesForCompany.validateSync(req.body, { abortEarly: false });

  const externalEmployees = await autocompleteCompanyExternalEmployees(req, input);

  res.status(HttpStatus.OK).json({ externalEmployees });
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const FETCH_AUTOCOMPLETE_EXTERNAL_EMPLOYEE_FOR_COMPANY_KEY: ApiRoute["pathname"] =
  "/api/external-employee/fetch-autocomplete-external-employees-for-company";

export const useAutocompleteCompanyExternalEmployeesQuery = createQuery<
  typeof handler,
  typeof FetchAutocompleteExternalEmployeesForCompany
>({
  path: FETCH_AUTOCOMPLETE_EXTERNAL_EMPLOYEE_FOR_COMPANY_KEY,
  schema: FetchAutocompleteExternalEmployeesForCompany,
  options: () => ({ staleTime: DYNAMIC_MODELS_STALE_TIME }),
});
