import { UserLocale } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";

export const fetchJobFamilies = async (ctx: AppContext) => {
  const { user, remember } = ctx;

  return remember(
    `fetch-job-families-${user?.locale ?? UserLocale.EN}`,
    () =>
      ctx.prisma.jobFamily.findMany({
        orderBy: { name: "asc" },
        select: {
          id: true,
          name: true,
          jobs: {
            orderBy: { name: "asc" },
            select: {
              id: true,
              name: true,
              description: true,
              jobAliases: {
                where: { locale: user?.locale ?? UserLocale.EN, isRootAlias: false },
                orderBy: { name: "asc" },
                select: {
                  id: true,
                  name: true,
                },
              },
            },
          },
        },
      }),
    { ttl: 3600 }
  );
};

export type FetchJobFamiliesResult = AsyncReturnType<typeof fetchJobFamilies>;
