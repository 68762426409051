import { type Currency, EmployeeUpdateStrategy, type Gender } from "@prisma/client";
import { intersection, omit, omitBy } from "lodash";
import React, { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { number, object, string } from "yup";
import { type EmployeeAutocompleteRow } from "~/components/ui/external-employee-autocomplete";
import { type LocationForSelector } from "~/components/ui/location-selector";
import { useAlerts } from "~/hooks/useAlerts";
import { useForm } from "~/hooks/useForm";
import { usePermissions } from "~/hooks/usePermissions";
import { ApiValidationError } from "~/lib/api";
import { useI18n } from "~/lib/i18n/use-i18n";
import { useUpdateEmployeeAggregateMutation } from "~/pages/api/employee-aggregate/update";
import {
  type FetchExternalEmployeeResponse,
  useFetchExternalEmployeeQuery,
} from "~/pages/api/external-employee/fetch-external-employee";
import { type EmployeeLevel } from "~/services/employee/employee-level";
import { type RemunerationsWithSources, computeRemunerationsWithSources } from "~/services/external-employee";

export type EmployeeFormValues = {
  updateReason: string;
  updateStrategy: EmployeeUpdateStrategy;
  currency: Currency | undefined;
  baseSalary: number | undefined;
  fixedBonus: number | undefined;
  fixedBonusPercentage: number | undefined;
  onTargetBonus: number | undefined;
  onTargetBonusPercentage: number | undefined;
  manager: EmployeeAutocompleteRow | undefined | null;
  benchmarkJobId: number | undefined;
  benchmarkLevel: EmployeeLevel | undefined;
  benchmarkLocation: LocationForSelector | undefined;
  performanceReviewRatingId: number | undefined | null;

  employeeNumber: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  gender: Gender | undefined;
  job: string | undefined;
  level: string | undefined;
  location: string | undefined;
  fteDivider: number | undefined | null;
  businessUnit: string | undefined | null;
  hireDate: Date | undefined | null;
};

type HookValue = {
  externalEmployeeId: number;
  externalEmployee: FetchExternalEmployeeResponse | undefined;
  isLoading: boolean;
  form: ReturnType<typeof useForm<EmployeeFormValues>>;
  unsavedChanges: number;
  remunerations: RemunerationsWithSources | undefined;
};

const formEmptyState: EmployeeFormValues = {
  updateReason: "",
  updateStrategy: EmployeeUpdateStrategy.NEW_VERSION,
  currency: undefined,
  baseSalary: undefined,
  fixedBonus: undefined,
  fixedBonusPercentage: undefined,
  onTargetBonus: undefined,
  onTargetBonusPercentage: undefined,
  manager: undefined,
  benchmarkJobId: undefined,
  benchmarkLevel: undefined,
  benchmarkLocation: undefined,
  performanceReviewRatingId: undefined,

  employeeNumber: undefined,
  firstName: undefined,
  lastName: undefined,
  gender: undefined,
  job: undefined,
  location: undefined,
  level: undefined,
  fteDivider: undefined,
  businessUnit: undefined,
  hireDate: undefined,
};

type Params = {
  externalEmployeeId: number;
};

const Context = createContext<HookValue>({} as HookValue);

export const EmployeeFormProvider: React.FC<React.PropsWithChildren<Params>> = ({ children, externalEmployeeId }) => {
  const { t } = useI18n();
  const updateEmployee = useUpdateEmployeeAggregateMutation({
    successMessage: t("components.external-employee-drawer.feedback.employee-modified"),
  });

  const { permissions } = usePermissions();
  const { triggerAlert } = useAlerts();

  const { data: externalEmployee, isLoading } = useFetchExternalEmployeeQuery({
    input: { externalEmployeeId },
    options: {
      enabled: permissions.canAccessRawData,
    },
  });

  const initialValues = useRef<EmployeeFormValues>();
  const form = useForm<EmployeeFormValues>({
    validationSchema: object({
      employeeNumber: string().required(),
      firstName: string().required(),
      lastName: string().required(),
      baseSalary: number().required(),
      fteDivider: number()
        .nullable()
        .test(
          "percentage",
          t("components.external-employee-drawer.feedback.validation-errors.percentage-out-of-range"),
          (value) => !value || (value >= 0 && value <= 1)
        ),
    }),
    onSubmit: async (values, helpers) => {
      const formModifiedValues = {
        ...formEmptyState,
        ...getDifferences(form.values, initialValues.current ?? formEmptyState),
      };

      // we need to also send base salary to compute all other remuneration items
      if (
        intersection(Object.keys(formModifiedValues), [
          "fixedBonus",
          "fixedBonusPercentage",
          "onTargetBonus",
          "onTargetBonusPercentage",
        ]).length > 0
      ) {
        formModifiedValues.baseSalary = form.values.baseSalary;
      }

      try {
        await updateEmployee.mutateAsync(
          {
            externalEmployeeId: externalEmployee?.id ?? externalEmployeeId,
            values: {
              updateReason: formModifiedValues.updateReason,
              updateStrategy: formModifiedValues.updateStrategy,
              currencyId: formModifiedValues.currency?.id,
              baseSalary: formModifiedValues.baseSalary,
              fixedBonus: formModifiedValues.fixedBonus,
              fixedBonusPercentage: formModifiedValues.fixedBonusPercentage,
              onTargetBonus: formModifiedValues.onTargetBonus,
              onTargetBonusPercentage: formModifiedValues.onTargetBonusPercentage,
              managerId: formModifiedValues.manager === undefined ? undefined : formModifiedValues.manager?.id ?? null,
              benchmarkJobId: formModifiedValues.benchmarkJobId,
              benchmarkLevel: formModifiedValues.benchmarkLevel,
              benchmarkLocationId: formModifiedValues.benchmarkLocation?.id,
              performanceReviewRatingId: formModifiedValues.performanceReviewRatingId,

              employeeNumber: formModifiedValues.employeeNumber,
              firstName: formModifiedValues.firstName,
              lastName: formModifiedValues.lastName,
              gender: formModifiedValues.gender,
              job: formModifiedValues.job,
              location: formModifiedValues.location,
              level: formModifiedValues.level,
              fteDivider: formModifiedValues.fteDivider,
              businessUnit: formModifiedValues.businessUnit,
              hireDate: formModifiedValues.hireDate,
            },
          },
          {
            onSuccess: async () => {
              // submitted values are now the initial values
              void helpers.resetForm({
                values,
              });
            },
            onError: (error) => {
              if (error instanceof ApiValidationError) {
                helpers.setErrors(error.fields);

                triggerAlert({
                  type: "error",
                  icon: "warning",
                  message: t("components.external-employee-drawer.feedback.submit-error"),
                });
              }
            },
          }
        );
      } catch (error) {
        triggerAlert({
          type: "error",
          icon: "warning",
          message: t("components.external-employee-drawer.feedback.submit-error"),
        });
      }
    },
    initialValues: formEmptyState,
  });

  const unsavedChanges = useMemo(() => {
    return Object.keys(omit(getDifferences(form.initialValues, form.values), ["updateReason", "updateStrategy"]))
      .length;
  }, [form.values, form.isSubmitting]);

  const remunerations = useMemo(() => {
    return externalEmployee ? computeRemunerationsWithSources(externalEmployee) : undefined;
  }, [externalEmployee]);

  // this is the true initial values of the form
  useEffect(() => {
    const values = {
      ...form.values,
      currency: externalEmployee?.currency ?? undefined,
      baseSalary: remunerations?.computed.baseSalary ?? undefined,
      fixedBonus: remunerations?.computed.fixedBonus ?? undefined,
      fixedBonusPercentage: remunerations?.computed.fixedBonusPercentage
        ? remunerations?.computed.fixedBonusPercentage * 100
        : undefined,
      onTargetBonus: remunerations?.computed.onTargetBonus ?? undefined,
      onTargetBonusPercentage: remunerations?.computed.onTargetBonusPercentage
        ? remunerations?.computed.onTargetBonusPercentage * 100
        : undefined,
      manager: externalEmployee?.manager ?? undefined,
      benchmarkJobId: externalEmployee?.mappedEmployee?.job.id ?? undefined,
      benchmarkLevel: externalEmployee?.mappedEmployee?.level ?? undefined,
      benchmarkLocation: externalEmployee?.mappedEmployee?.location ?? undefined,
      performanceReviewRatingId: externalEmployee?.performanceReviewRatingId ?? undefined,

      employeeNumber: externalEmployee?.employeeNumber ?? undefined,
      firstName: externalEmployee?.firstName ?? undefined,
      lastName: externalEmployee?.lastName ?? undefined,
      gender: externalEmployee?.gender ?? undefined,
      job: externalEmployee?.job?.name ?? undefined,
      location: externalEmployee?.location?.name ?? undefined,
      level: externalEmployee?.level?.name ?? undefined,
      fteDivider: externalEmployee?.fteDivider ?? undefined,
      businessUnit: externalEmployee?.businessUnit ?? undefined,
      hireDate: externalEmployee?.hireDate ?? undefined,
    };

    initialValues.current = values;
    void form.resetForm({
      values,
    });
  }, [remunerations]);

  return (
    <Context.Provider value={{ externalEmployeeId, externalEmployee, isLoading, form, unsavedChanges, remunerations }}>
      {children}
    </Context.Provider>
  );
};

export function useEmployeeForm() {
  return useContext<HookValue>(Context);
}

const getDifferences = (obj1: object, obj2: Record<string, unknown>) => {
  return omitBy(obj1, (v, k) => {
    return obj2[k] === v;
  });
};
