import { TextField, Typography } from "@mui/material";
import classNames from "classnames";
import { type ComponentProps, useRef } from "react";
import { Modal } from "~/components/ui/core/Modal";
import { TextareaInput } from "~/components/ui/core/TextareaInput";

export type Choice = {
  label: string;
  description?: string;
};

export type CustomChoice = Choice & {
  isCustom: true;
  customValue: string | null;
  type: "text" | "number";
};

export type ChoiceOrCustomChoice = Choice | CustomChoice;

type Props<T> = {
  choices: readonly T[];
  onChoiceChange: (choice: T) => void;
  selectedChoice?: T | null;
} & ComponentProps<typeof Modal>;

export const SelectAChoiceModal = <T extends ChoiceOrCustomChoice>({
  choices,
  onChoiceChange,
  selectedChoice,
  ...rest
}: Props<T>) => {
  const customOptionRef = useRef<HTMLDivElement>(null);

  return (
    <Modal {...rest} className="max-w-[600px]">
      <div className="flex w-full flex-col space-y-4 px-3 pt-3">
        {choices.map((choice) => (
          <div
            key={choice.label}
            onClick={() => {
              onChoiceChange(choice);

              if (isCustomChoice(choice)) {
                setTimeout(() => {
                  customOptionRef.current?.querySelector("textarea")?.focus();
                }, 10);
              }
            }}
            className={classNames(
              "flex cursor-pointer flex-col space-y-2 rounded-lg border border-gray-300 p-3 hover:border-primary-400 hover:bg-primary-100 hover:text-primary-600",
              {
                "!border-transparent bg-primary-100 text-primary-600 ring ring-primary-400":
                  choice.label === selectedChoice?.label,
              }
            )}
          >
            <Typography variant="body1">{choice.label}</Typography>
            {choice.description && <Typography color="gray.600">{choice.description}</Typography>}
          </div>
        ))}

        {isCustomChoice(selectedChoice) && (
          <div ref={customOptionRef} className="col-span-2">
            {selectedChoice.type === "text" && (
              <TextareaInput
                placeholder="Please specify"
                value={selectedChoice.customValue ?? ""}
                onChange={(event) => {
                  onChoiceChange({
                    ...selectedChoice,
                    customValue: event.target.value,
                  });
                }}
              />
            )}
            {selectedChoice.type === "number" && (
              <TextField
                type="number"
                fullWidth
                inputProps={{ type: "number", min: 0, max: 100 }}
                placeholder="Please specify"
                value={selectedChoice.customValue ?? ""}
                onChange={(event) => {
                  onChoiceChange({
                    ...selectedChoice,
                    customValue: event.target.value,
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export const isCustomChoice = (choice?: ChoiceOrCustomChoice | null): choice is CustomChoice => {
  return (choice as CustomChoice)?.isCustom;
};
