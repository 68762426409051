import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type AsyncReturnType } from "type-fest";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { fetchReconciliationSuggestions } from "~/services/external-employee/reconciliation";

export const FetchReconciliationSuggestionsSchema = object({
  externalEmployeeId: number().positive().required(),
}).noUnknown();
export type FetchReconciliationSuggestionsInput = YupOutputType<typeof FetchReconciliationSuggestionsSchema>;

export type FetchReconciliationSuggestionsResponse = {
  employees: AsyncReturnType<typeof fetchReconciliationSuggestions>;
};

const handler: NextApiHandler<FetchReconciliationSuggestionsResponse> = async (req, res) => {
  const input = FetchReconciliationSuggestionsSchema.validateSync(req.body, { abortEarly: false });

  const employees = await fetchReconciliationSuggestions(req, {
    externalEmployeeId: input.externalEmployeeId,
  });

  res.status(HttpStatus.OK).json({ employees });
};

export default api(handler, {
  method: "POST",
  authentication: { canManageUsers: true },
});

export const FETCH_RECONCILIATION_SUGGESTIONS_KEY: ApiRoute["pathname"] =
  "/api/external-employee/fetch-reconciliation-suggestions";

export const useFetchReconciliationSuggestionsQuery = createQuery<
  typeof handler,
  typeof FetchReconciliationSuggestionsSchema
>({
  path: FETCH_RECONCILIATION_SUGGESTIONS_KEY,
  schema: FetchReconciliationSuggestionsSchema,
});
