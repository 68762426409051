import { mapSeries } from "bluebird";
import { getRequiredUser } from "~/lib/get-required-user";
import { compensationReviewEmployeeSelectForReviewersChain } from "~/services/compensation-review/campaigns/admin/compensation-review-employee-select-for-reviewers";
import { type CompensationReviewContext } from "~/services/compensation-review/compensation-review-context";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensation-review-scope";
import { fetchExternalEmployeeManagers } from "~/services/compensation-review/off-cycle-reviews/fetch-external-employee-managers";

export const findOrCreateCompensationReviewReviewer = async (
  ctx: CompensationReviewContext,
  params: { externalEmployeeId: number }
) => {
  const user = getRequiredUser(ctx);

  const existingReviewer = await ctx.prisma.compensationReviewReviewer.findFirst({
    where: {
      companyId: user.companyId,
      externalEmployeeId: params.externalEmployeeId,
      ...prismaCompensationReviewScope(ctx.scope),
    },
    select: compensationReviewEmployeeSelectForReviewersChain,
  });

  if (existingReviewer) return existingReviewer;

  return ctx.prisma.compensationReviewReviewer.create({
    data: {
      companyId: user.companyId,
      ...prismaCompensationReviewScope(ctx.scope),
      externalEmployeeId: params.externalEmployeeId,
      canSeeAnalytics: false,
      canSeeBudgets: false,
      canReviewExceptionalBonus: false,
    },
    select: compensationReviewEmployeeSelectForReviewersChain,
  });
};

export const findOrCreateCompensationReviewReviewers = async (
  ctx: CompensationReviewContext,
  params: { externalEmployeeId: number }
) => {
  const { managers } = await fetchExternalEmployeeManagers(ctx, {
    externalEmployeeId: params.externalEmployeeId,
    scope: ctx.scope,
  });

  return mapSeries(managers, async (manager) => {
    return findOrCreateCompensationReviewReviewer(ctx, { externalEmployeeId: manager.id });
  });
};
