import { Stack } from "@mui/material";
import { UserLocale } from "@prisma/client";
import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { Button } from "~/components/ui/core/button";
import { SnackBar } from "~/components/ui/core/snack-bar";
import { useLocale } from "~/hooks/useLocale";
import { useUpdateUserLocaleQuery } from "~/pages/api/user/update-user-locale";

export const TranslationSnackbar: React.FC = () => {
  const { locale } = useLocale();
  const [showTranslationSnackbar, setShowTranslationSnackbar] = useLocalStorage("show-translation-snackbar", true);
  const [showSnackBar, setShowSnackBar] = useState(false);

  const updateUserLocale = useUpdateUserLocaleQuery({
    onSuccess: () => {
      window.location.reload();
    },
  });

  // This useEffect is needed to avoid hydratation error
  useEffect(() => setShowSnackBar(!!showTranslationSnackbar && locale === UserLocale.FR), [showTranslationSnackbar]);

  return (
    <SnackBar
      title="Migration en français 🇫🇷"
      message="Nous sommes actuellement en train de traduire Figures en Français ! Malheureusement toutes les pages ne sont pas encore disponibles. Merci de votre patience"
      open={showSnackBar}
      onClose={() => setShowTranslationSnackbar(false)}
    >
      <Stack>
        <Button
          color="white"
          className="!ml-auto mt-1"
          variant="contained"
          isLoading={updateUserLocale.isLoading}
          onClick={() => updateUserLocale.mutateAsync({})}
        >
          Back to english
        </Button>
      </Stack>
    </SnackBar>
  );
};
