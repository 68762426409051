import { EmployeeMappingSkipReason } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { mixed, number, object } from "yup";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY } from "~/pages/api/external-employee/count-external-employees-by-status";
import { FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY } from "~/pages/api/imported-employees/fetch-all-imported-employees";
import { skipExternalEmployeeMapping } from "~/services/external-employee/skip-external-employee-mapping";

const SkipExternalEmployeeMappingSchema = object({
  externalEmployeeId: number().required(),
  reason: mixed<EmployeeMappingSkipReason>().oneOf(Object.values(EmployeeMappingSkipReason)).required(),
});

export type SkipExternalEmployeeMappingInput = YupOutputType<typeof SkipExternalEmployeeMappingSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = SkipExternalEmployeeMappingSchema.validateSync(req.body, {
    abortEarly: false,
  });

  await skipExternalEmployeeMapping(req, input);

  res.status(HttpStatus.OK).json({
    message: "Employee skipped",
  });
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessIntegrations: true,
    canAccessRawData: true,
  },
});

export const useSkipExternalEmployeeMappingMutation = createMutation<
  typeof handler,
  typeof SkipExternalEmployeeMappingSchema
>({
  path: "/api/external-employee/skip-external-employee-mapping",
  schema: SkipExternalEmployeeMappingSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY);
      await invalidateQuery(queryClient, COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY);
    },
  }),
});
