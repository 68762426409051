import { type Prisma } from "@prisma/client";
import { uniqueId } from "lodash";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import {
  computeRemunerationsWithSources,
  externalEmployeeSelectForRemunerationComputation,
} from "~/services/external-employee";

export const fillEmployeeFromExternalEmployee = async (
  ctx: AppContext,
  params: {
    externalEmployeeId: number;
    employeeId: number;
  }
) => {
  const user = getRequiredUser(ctx);

  const externalEmployee = await ctx.prisma.externalEmployee.findFirstOrThrow({
    where: {
      id: params.externalEmployeeId,
      companyId: user.companyId,
    },
    include: {
      mappedEmployee: true,
      currency: { select: { id: true } },
      job: {
        select: {
          name: true,
          mappedJob: { select: { id: true } },
        },
      },
      level: {
        select: {
          name: true,
          mappedLevel: true,
        },
      },
      location: {
        select: {
          mappedLocation: { select: { id: true } },
        },
      },
      ...externalEmployeeSelectForRemunerationComputation,
    },
  });

  const employee = await ctx.prisma.employee.findFirstOrThrow({
    where: {
      id: params.employeeId,
      companyId: user.companyId,
    },
    include: {
      location: { select: { id: true } },
      job: { select: { id: true } },
    },
  });

  const remunerations = computeRemunerationsWithSources(externalEmployee);

  const payload: Prisma.EmployeeUpdateInput = {
    baseSalary: remunerations.external.baseSalary ?? 0,
    fixedBonus: remunerations.external.fixedBonus,
    fixedBonusPercentage: remunerations.external.fixedBonusPercentage,
    onTargetBonus: remunerations.external.onTargetBonus,
    onTargetBonusPercentage: remunerations.external.onTargetBonusPercentage,

    employeeNumber: externalEmployee.employeeNumber ?? `figures-${uniqueId()}`,
    externalId: externalEmployee.externalId,
    source: externalEmployee.source,

    currency: { connect: { id: externalEmployee.currency.id } },

    ...(externalEmployee.job && { externalJobTitle: externalEmployee.job.name }),
    ...(externalEmployee.level && { externalLevel: externalEmployee.level.name }),

    // for the followings, don't erase the existing values by null
    ...((externalEmployee.firstName !== null || employee.firstName === null) && {
      firstName: externalEmployee.firstName,
    }),
    ...((externalEmployee.lastName !== null || employee.lastName === null) && {
      lastName: externalEmployee.lastName,
    }),
    ...((externalEmployee.gender !== null || employee.gender === null) && {
      gender: externalEmployee.gender,
    }),
    ...((externalEmployee.hireDate !== null || employee.hireDate === null) && {
      hireDate: externalEmployee.hireDate,
    }),
  };

  await ctx.prisma.employee.update({
    where: {
      id: params.employeeId,
      companyId: user.companyId,
    },
    data: payload,
  });
};
