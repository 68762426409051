import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { type FormikErrors } from "formik";
import { flatMap, isArray, isString } from "lodash";
import React from "react";

type FormikErrorOrString = string | string[] | FormikErrors<unknown> | FormikErrors<unknown>[] | undefined;

type Props = React.PropsWithChildren<{
  nativeLabel?: boolean;
  label?: string | JSX.Element;
  requiredHint?: boolean;
  errors?: FormikErrorOrString;
  touched?: boolean;
  tooltip?: JSX.Element;
  className?: string;
}>;

export const getErrorMessages = (errors: FormikErrorOrString): string[] => {
  if (!errors) {
    return [];
  }
  if (!isArray(errors)) {
    return isString(errors) ? [errors] : flatMap(Object.keys(errors).map(getErrorMessages));
  }
  return flatMap([
    ...(errors.filter(isString) as string[]),
    ...errors
      .filter((error) => {
        return !isString(error);
      })
      .map(getErrorMessages),
  ]);
};

export const LegacyFormControl: React.FC<Props> = ({
  label,
  requiredHint = false,
  errors,
  touched = false,
  nativeLabel = false,
  tooltip,
  children,
  className,
}) => {
  const errorMessages = getErrorMessages(errors);
  const showErrors = errorMessages.length > 0 && touched;

  return (
    <label
      className={classNames(className, {
        "flex flex-col justify-start space-y-1 text-gray-500 focus-within:text-primary-600": true,
      })}
    >
      {!nativeLabel && label && (
        <div className="flex items-center">
          <span
            className={classNames({
              "ml-1 text-xs uppercase": true,
              "text-red-500": showErrors,
            })}
          >
            {label}
            {requiredHint && <span className="ml-1 font-bold text-gray-500">*</span>}
          </span>
          {tooltip && (
            <Tooltip title={tooltip}>
              <InfoOutlined fontSize="small" className="ml-2 text-sm text-gray-400" />
            </Tooltip>
          )}
        </div>
      )}

      <div className="flex flex-1 flex-col">
        {children}

        {showErrors &&
          errorMessages.map((error, idx) => {
            return (
              <span key={idx} className="ml-1 mt-1 text-sm text-red-500">
                {error}
              </span>
            );
          })}
      </div>
    </label>
  );
};
