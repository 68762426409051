import { Divider, Modal as MuiModal, Typography } from "@mui/material";
import classNames from "classnames";
import { isString } from "lodash";
import React, { useEffect } from "react";
import { useCommandPalette } from "~/components/command-palette/command-palette-context";
import { Button } from "~/components/ui/core/button";
import { Icon } from "~/components/ui/core/Icon";
import { type IconName } from "~/components/ui/icons";
import { PortalRootProvider } from "~/hooks/usePortalRoot";
import { trackModalOpened } from "~/lib/external/segment/web/events";

export type ModalAction = {
  label: string;
  onClick: () => unknown | Promise<unknown>;
  disabled?: boolean;
  isProcessing?: boolean;
};

type Props = React.PropsWithChildren<{
  icon?: IconName | React.ReactNode;
  title: string;
  isOpen: boolean;
  keepMounted?: boolean;
  onClose: (event?: object, reason?: string) => void;
  subtitle?: string;
  className?: string;
  showCloseButton?: boolean;
  primaryAction?: ModalAction;
  secondaryAction?: ModalAction;
  tertiaryAction?: ModalAction;
  customFooter?: JSX.Element;
}>;

export const Modal: React.FC<Props> = ({
  icon,
  title,
  isOpen,
  keepMounted,
  onClose,
  subtitle,
  className,
  showCloseButton,
  children,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  customFooter,
}) => {
  const { showPalette } = useCommandPalette();

  useEffect(() => {
    if (isOpen) {
      void trackModalOpened({ name: title });
    }
  }, [isOpen, title]);

  return (
    <MuiModal
      disableEnforceFocus={showPalette}
      open={isOpen}
      onClose={onClose}
      keepMounted={keepMounted}
      className="flex min-h-screen items-center justify-center focus:outline-none"
      id="modal"
    >
      <div
        className={classNames(
          className,
          "relative z-10 mx-auto animate-scale overflow-auto rounded-lg bg-white shadow-2xl focus:outline-none",
          { "pb-3": !primaryAction && !secondaryAction },
          "flex flex-col"
        )}
        style={{ maxHeight: "98vh" }}
      >
        <div className="flex justify-center p-3">
          <div className="flex flex-col items-center text-center">
            {showCloseButton && (
              <Button
                className="absolute top-5 right-4 min-w-0 text-gray-800 hover:text-gray-700 focus:outline-none"
                tabIndex={-1}
                onClick={(event: React.MouseEvent<HTMLElement>) => onClose(event, "closeButtonClick")}
              >
                <Icon name="plain-close" size="lg" />
              </Button>
            )}

            {icon && (
              <div className="my-4 rounded-full bg-secondary-100 p-4">
                {isString(icon) ? <Icon name={icon as IconName} size="2xl" className="text-secondary-500" /> : icon}
              </div>
            )}

            <Typography variant="h2">{title}</Typography>

            {subtitle && (
              <Typography variant="subtitle2" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </div>
        </div>

        <Divider />

        <div className="flex flex-1 p-3">
          <PortalRootProvider selector="#modal">{children}</PortalRootProvider>
        </div>

        {!!customFooter && customFooter}

        {(primaryAction || secondaryAction) && (
          <>
            <Divider />

            <div className="flex w-full justify-end space-x-4 p-7">
              {tertiaryAction && (
                <Button
                  className="mr-auto"
                  variant="text"
                  onClick={tertiaryAction.onClick}
                  disabled={tertiaryAction.disabled}
                  isLoading={tertiaryAction.isProcessing}
                  tabIndex={2}
                >
                  {tertiaryAction.label}
                </Button>
              )}

              {secondaryAction && (
                <Button
                  variant="outlined"
                  onClick={secondaryAction.onClick}
                  disabled={secondaryAction.disabled}
                  isLoading={secondaryAction.isProcessing}
                  tabIndex={2}
                >
                  {secondaryAction.label}
                </Button>
              )}

              {primaryAction && (
                <Button
                  variant="contained"
                  onClick={primaryAction.onClick}
                  disabled={primaryAction.disabled}
                  isLoading={primaryAction.isProcessing}
                  tabIndex={1}
                >
                  {primaryAction.label}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </MuiModal>
  );
};
