import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type FetchSelectedPerformanceReviewCyclesInput } from "~/pages/api/performance-review/fetch-selected-performance-review-cycles";
import { findOrCreatePerformanceReviewCycle } from "~/services/performance-review/find-or-create-performance-review-cycle";
export type FetchSelectedPerformanceReviewCycles = AsyncReturnType<typeof fetchSelectedPerformanceReviewCycles>;

export const fetchSelectedPerformanceReviewCycles = async (
  ctx: AppContext,
  input: FetchSelectedPerformanceReviewCyclesInput
) => {
  const user = getRequiredUser(ctx);

  const cycle = await findOrCreatePerformanceReviewCycle(ctx, {
    companyId: user.isSuperAdmin ? input.companyId ?? user.companyId : user.companyId,
    performanceReviewScope: input.performanceReviewScope,
  });

  return cycle.selectedCycleIds;
};
