import { type Prisma, SalaryGridConfigurationChangeLabel, type SalaryRange } from "@prisma/client";
import { randomUUID } from "crypto";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { auditLogConfigurationChange, type AuditLogOptions } from "~/services/salary-bands/audit-logs";
import { type selectCreatedSalaryBand } from "~/services/salary-bands/creation/create-new-salary-bands";

export const auditLogCreateBand = async (
  ctx: AppContext,
  band: Prisma.SalaryBandGetPayload<{ select: typeof selectCreatedSalaryBand }>,
  ranges: Pick<SalaryRange, "id">[],
  options?: AuditLogOptions
) => {
  const input = {
    gridId: band.gridId,
    bandId: band.id,
    userId: getRequiredUser(ctx).id,
    actionId: options?.actionId ?? randomUUID(),
    label: SalaryGridConfigurationChangeLabel.CREATE_BAND,
    newState: { ...band, ranges },
  };

  await auditLogConfigurationChange(ctx, input);
};
