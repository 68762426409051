import { type CustomJob, UserRole } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { array, boolean, number, object, string } from "yup";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/get-required-user";
import { type YupOutputType } from "~/lib/utils";
import { createCustomJob } from "~/services/custom-jobs/create-custom-job";

export const WeightedJobSchema = object({
  job: object({ id: number().required() }),
  weight: number().positive().required(),
});

export const CreateCustomJobSchema = object({
  name: string().required(),
  jobs: array().of(WeightedJobSchema).min(2).ensure(),
  sharedWithCompany: boolean().required(),
});

export type CreateCustomJobInput = YupOutputType<typeof CreateCustomJobSchema>;

export type CreateCustomJobResponse = {
  customJob: CustomJob;
};

const handler: NextApiHandler<CreateCustomJobResponse> = async (req, res) => {
  const user = getRequiredUser(req);

  const input = CreateCustomJobSchema.validateSync(req.body, {
    abortEarly: false,
  });

  if (user.permissions.role === UserRole.RECRUITER) {
    input.sharedWithCompany = false;
  }

  const customJob = await createCustomJob(req, input);

  res.status(HttpStatus.CREATED).json({ customJob });
};

export default api(handler, {
  method: "POST",
  authentication: {},
});
