import { Typography } from "@mui/material";
import { type Prisma, type SalaryGridMeasure, SalaryGridTiersMode } from "@prisma/client";
import classNames from "classnames";
import { isNil, isUndefined } from "lodash";
import React from "react";
import { DEFAULT_TIERS_MODE } from "~/services/salary-bands-positioning";
import { getRangePenetrationForMeasure } from "~/services/salary-bands/benchmark/range-penetration";

export const salaryRangeEmployeeForRangePenetrationIndicator = {
  baseSalaryRangePenetration: true,
  onTargetEarningsRangePenetration: true,
} satisfies Prisma.SalaryRangeEmployeeSelect;

type SalaryRangeEmployeeForRangePenetrationIndicator = Prisma.SalaryRangeEmployeeGetPayload<{
  select: typeof salaryRangeEmployeeForRangePenetrationIndicator;
}>;

type Props = {
  rangePenetration?: number;
  salaryRangeEmployee?: SalaryRangeEmployeeForRangePenetrationIndicator;
  measure?: SalaryGridMeasure;
  newRangePenetration?: number;
  tiersMode?: SalaryGridTiersMode;
  size?: "small" | "medium";
};

const rangePenetrationToPosition = (rangePenetration: number) => {
  if (rangePenetration > 1) {
    return "calc(100% + 2rem)";
  }

  if (rangePenetration < 0) {
    return "-2rem";
  }

  return `${rangePenetration * 100}%`;
};

export const RangePenetrationIndicator: React.FC<Props> = ({
  salaryRangeEmployee,
  rangePenetration,
  measure,
  newRangePenetration,
  tiersMode = DEFAULT_TIERS_MODE,
  size = "small",
}) => {
  if (salaryRangeEmployee && measure) {
    rangePenetration = getRangePenetrationForMeasure(salaryRangeEmployee, measure) ?? undefined;
  }

  if (isUndefined(rangePenetration)) {
    return <Typography variant="caption">N/A</Typography>;
  }

  const mainRangePenetration = newRangePenetration ?? rangePenetration;

  return (
    <div
      className={classNames({
        "relative": true,
        "h-4 w-64": size === "small",
        "h-6 w-96": size === "medium",
      })}
    >
      <div className="absolute inset-0 top-1/2 h-px bg-gradient-to-r from-secondary-300 via-secondary-500 to-secondary-300" />

      <div className="absolute inset-y-0 inset-x-16 rounded border border-secondary-500 bg-secondary-300">
        <div
          className={classNames({
            "absolute inset-y-0 ml-px -translate-x-px bg-secondary-400": true,
            ...(tiersMode === SalaryGridTiersMode.QUARTERS && {
              "w-1/4": mainRangePenetration > 0 && mainRangePenetration < 1,
              "left-0": mainRangePenetration < 0.25,
              "left-1/4": mainRangePenetration >= 0.25 && mainRangePenetration < 0.5,
              "left-1/2": mainRangePenetration >= 0.5 && mainRangePenetration < 0.75,
              "left-3/4": mainRangePenetration >= 0.75,
            }),
            ...(tiersMode === SalaryGridTiersMode.THIRDS && {
              "w-1/3": mainRangePenetration > 0 && mainRangePenetration < 1,
              "left-0": mainRangePenetration < 1 / 3,
              "left-1/3": mainRangePenetration >= 1 / 3 && mainRangePenetration < 2 / 3,
              "left-2/3": mainRangePenetration >= 2 / 3,
            }),
            ...(tiersMode === SalaryGridTiersMode.HALVES && {
              "w-1/2": mainRangePenetration > 0 && mainRangePenetration < 1,
              "left-0": mainRangePenetration < 0.5,
              "left-1/2": mainRangePenetration >= 0.5,
            }),
          })}
        />

        {tiersMode === SalaryGridTiersMode.HALVES && (
          <>
            <div className="absolute inset-y-0 left-1/2 -translate-x-px border-l border-dotted border-secondary-600" />
          </>
        )}
        {tiersMode === SalaryGridTiersMode.THIRDS && (
          <>
            <div className="absolute inset-y-0 left-1/3 -translate-x-px border-l border-dotted border-secondary-600" />
            <div className="absolute inset-y-0 left-2/3 -translate-x-px border-l border-dotted border-secondary-600" />
          </>
        )}
        {tiersMode === SalaryGridTiersMode.QUARTERS && (
          <>
            <div className="absolute inset-y-0 left-1/4 -translate-x-px border-l border-dotted border-secondary-600" />
            <div className="absolute inset-y-0 left-1/2 -translate-x-px border-l border-dotted border-secondary-600" />
            <div className="absolute inset-y-0 left-3/4 -translate-x-px border-l border-dotted border-secondary-600" />
          </>
        )}

        <div
          className={`mt-p absolute top-1/2 h-3 w-3 -translate-y-1/2 -translate-x-1/2 rounded-full bg-secondary-800 ${
            !isNil(newRangePenetration) && "opacity-50"
          }`}
          style={{ left: rangePenetrationToPosition(rangePenetration) }}
        />
        {!isNil(newRangePenetration) && (
          <div
            className="mt-p absolute top-1/2 h-3 w-3 -translate-y-1/2 -translate-x-1/2 rounded-full bg-secondary-800"
            style={{ left: rangePenetrationToPosition(newRangePenetration) }}
          />
        )}
      </div>
    </div>
  );
};
