import { HMRPlugin } from "i18next-hmr/plugin";
import Backend from "i18next-http-backend";
import { type UserConfig } from "next-i18next";
import { configureYupErrors } from "~/lib/i18n/yup-errors";

const isDev = process.env.NODE_ENV === "development";
const isServer = typeof window === "undefined";
const isClient = !isServer;

configureYupErrors();

const config: UserConfig = {
  i18n: {
    defaultLocale: "en",
    locales: ["en", "fr"],
  },
  debug: false,
  ns: ["common"],
  defaultNS: "common",
  fallbackLng: "en",
  preload: ["en", "fr"],
  keySeparator: ".",
  serializeConfig: false,
  ...(isDev && {
    reloadOnPrerender: true,
    ...(isClient && { use: [Backend, new HMRPlugin({ client: true })] }),
    ...(isServer && { use: [new HMRPlugin({ server: true })] }),
  }),
};

export default config;
