import { InputAdornment, MenuItem } from "@mui/material";
import { type Currency } from "@prisma/client";
import React, { useEffect, useState } from "react";
import { match } from "ts-pattern";
import { SelectableTextField } from "~/components/ui/core/SelectableTextField";
import { formatMoneyForTextInput } from "~/lib/money";

export const Measurement = {
  CURRENCY: "CURRENCY",
  PERCENTAGE: "PERCENTAGE",
} as const;
export type Measurement = (typeof Measurement)[keyof typeof Measurement];

type Props = {
  currency: Currency;
  amount: number | undefined;
  percentage: number | undefined;
  onChange: (value: string, measurement: Measurement) => void;
  onSelectChange?: (measurement: Measurement) => void;
};

export const SwitchablePercentageMoneyField: React.FC<Props> = ({
  currency,
  amount,
  percentage,
  onChange,
  onSelectChange,
}) => {
  const [measurement, setMeasurement] = useState<Measurement>(
    percentage !== undefined ? Measurement.PERCENTAGE : Measurement.CURRENCY
  );

  // ugly but easiest solution
  useEffect(() => {
    const measurement = percentage !== undefined ? Measurement.PERCENTAGE : Measurement.CURRENCY;
    setMeasurement(measurement);
    onSelectChange?.(measurement);
  }, [percentage]);

  const value = match<Measurement, number | string>(measurement)
    .with(Measurement.CURRENCY, () => (amount ? formatMoneyForTextInput(amount) : ""))
    .with(Measurement.PERCENTAGE, () => percentage ?? "")
    .exhaustive();

  return (
    <SelectableTextField
      size="small"
      type="number"
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {measurement === Measurement.CURRENCY ? currency.symbol : "%"}
          </InputAdornment>
        ),
      }}
      onChange={(event) => {
        onChange(event.target.value, measurement);
      }}
      selectValue={measurement}
      onSelectChange={(event) => {
        const measurement = event.target.value as Measurement;
        setMeasurement(event.target.value as Measurement);
        onSelectChange?.(measurement);
      }}
    >
      <MenuItem key={Measurement.CURRENCY} value={Measurement.CURRENCY}>
        {currency.code}
      </MenuItem>
      <MenuItem key={Measurement.PERCENTAGE} value={Measurement.PERCENTAGE}>
        {"%".padStart(currency.code.length)}
        {[...Array(currency.code.length - 1).keys()].map((i) => (
          <React.Fragment key={i}>&ensp;</React.Fragment>
        ))}
      </MenuItem>
    </SelectableTextField>
  );
};
