import { type TFunction } from "i18next";
import { type Field } from "react-spreadsheet-import/types/types";
import { match } from "ts-pattern";
import { type AnySchema } from "yup";
import type ObjectSchema from "yup/lib/object";
import {
  baseSalaryField,
  birthDateField,
  businessUnitField,
  currencyField,
  employeeNumberField,
  type ExternalEmployeeField,
  firstNameField,
  fixedBonusField,
  fteDividerField,
  genderField,
  hireDateField,
  isFounderField,
  jobTitleField,
  lastNameField,
  levelField,
  locationField,
  managerField,
  onTargetBonusField,
  performanceRatingField,
  workEmailField,
} from "~/lib/spreadsheet/external-employee-fields";

export const SpreadsheetTemplate = {
  PARTIAL: "PARTIAL",
  INITIAL: "INITIAL",
} as const;

export type SpreadsheetTemplate = (typeof SpreadsheetTemplate)[keyof typeof SpreadsheetTemplate];

export const SpreadsheetAction = {
  SYNC: "SYNC",
  UPDATE: "UPDATE",
} as const;

export type SpreadsheetAction = (typeof SpreadsheetAction)[keyof typeof SpreadsheetAction];

export type SpreadsheetSpec = {
  availableColumns: ExternalEmployeeField<string>[];
  requiredColumns: ExternalEmployeeField<string>[];
};

export type SpreadsheetCustomization<
  Spec extends SpreadsheetSpec,
  AvailableKeys extends string = Spec["availableColumns"][number]["key"],
  RequiredKeys extends string = Spec["requiredColumns"][number]["key"],
> = {
  spec: Spec;
  validationSchema: ObjectSchema<Record<RequiredKeys, AnySchema>>;
  mode: SpreadsheetAction | null;
  optionalColumns: Field<AvailableKeys>[];
  data: ObjectSchema<Record<RequiredKeys, AnySchema>>["__outputType"][];
};

const initialSpreadsheetAvailableColumns = (t: TFunction) =>
  [
    employeeNumberField(t),
    genderField,
    birthDateField,
    firstNameField,
    lastNameField,
    workEmailField,
    hireDateField,
    jobTitleField,
    levelField,
    locationField,
    currencyField,
    baseSalaryField(t),
    onTargetBonusField(t),
    fixedBonusField(t),
    isFounderField,
    managerField,
    performanceRatingField,
    businessUnitField,
  ].map(patchFieldWithAlternateMatch);

const initialSpreadsheetRequiredColumns = (t: TFunction) =>
  [
    employeeNumberField(t),
    genderField,
    jobTitleField,
    levelField,
    locationField,
    currencyField,
    baseSalaryField(t),
  ].map(patchFieldWithAlternateMatch);

const patchFieldWithAlternateMatch = <T extends { rsiField: Field<string> }>(field: T) => ({
  ...field,
  rsiField: { ...field.rsiField, alternateMatches: [field.rsiField.label] },
});

const addValidationToRequiredField = (t: TFunction, spreadsheetSpec: SpreadsheetSpec): SpreadsheetSpec => {
  const newRequiredColumns: ExternalEmployeeField<string>[] = spreadsheetSpec.requiredColumns.map((column) => {
    return {
      ...column,
      rsiField: {
        ...column.rsiField,
        validations: [
          ...(column.rsiField.validations ? column.rsiField.validations : []),
          {
            rule: "required",
            errorMessage: t("services.spreadsheet.external-employee-fields.is-required", {
              field: column.rsiField.label,
            }),
            level: "error",
          },
        ],
      },
    };
  });

  const newAvailableColumns: ExternalEmployeeField<string>[] = spreadsheetSpec.availableColumns.map((column) => {
    const foundInRequiredColumns = newRequiredColumns.find((requiredColumn) => requiredColumn.key === column.key);

    if (foundInRequiredColumns) {
      return foundInRequiredColumns;
    }

    return column;
  });

  return { availableColumns: newAvailableColumns, requiredColumns: newRequiredColumns };
};

export const initialSpreadsheet = (t: TFunction) =>
  addValidationToRequiredField(t, {
    availableColumns: initialSpreadsheetAvailableColumns(t),
    requiredColumns: initialSpreadsheetRequiredColumns(t),
  });

const externalEmployeeAvailableColumns = (t: TFunction) =>
  [
    employeeNumberField(t),
    genderField,
    birthDateField,
    firstNameField,
    lastNameField,
    hireDateField,
    jobTitleField,
    levelField,
    locationField,
    currencyField,
    fteDividerField,
    baseSalaryField(t),
    onTargetBonusField(t),
    fixedBonusField(t),
    isFounderField,
    managerField,
    performanceRatingField,
    businessUnitField,
  ].map(patchFieldWithAlternateMatch);

const partialSpreadsheetRequiredColumns = (t: TFunction) => [employeeNumberField(t)].map(patchFieldWithAlternateMatch);

export const partialSpreadsheet = (t: TFunction) => ({
  availableColumns: externalEmployeeAvailableColumns(t),
  requiredColumns: partialSpreadsheetRequiredColumns(t),
});

export const getSpreadsheetSpec = (t: TFunction, templateName: SpreadsheetTemplate) =>
  match(templateName)
    .with(SpreadsheetTemplate.INITIAL, () => initialSpreadsheet(t))
    .with(SpreadsheetTemplate.PARTIAL, () => partialSpreadsheet(t))
    .exhaustive();
