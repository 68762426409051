import { Stack, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import React, { type ComponentProps, useState } from "react";
import { Button } from "~/components/ui/core/button";
import { Modal } from "~/components/ui/core/Modal";
import { config } from "~/config";
import { useI18n } from "~/lib/i18n/use-i18n";

type Props = {
  title: string;
  className?: string;
  description: string | JSX.Element;
  isOpen: boolean;
  isLoading?: boolean;
  cancelLabel?: string;
  confirmLabel: string;
  confirmColor?: ComponentProps<typeof Button>["color"];
  securityText?: string;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmationModal: React.FC<Props> = ({
  title,
  className,
  description,
  isOpen,
  isLoading,
  cancelLabel,
  confirmLabel,
  confirmColor = "secondary",
  securityText,
  onConfirm,
  onClose,
}) => {
  const [userSecurityText, setUserSecurityText] = useState(config.app.isProduction ? "" : securityText);
  const { t } = useI18n();

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} className={classNames("max-w-[600px]", className)}>
      <Stack className="w-full p-3 pb-0">
        {React.isValidElement(description) ? description : <Typography>{description}</Typography>}

        {!!securityText && (
          <TextField
            inputRef={(input) => input && input.focus()}
            className="mt-8 w-full"
            onChange={(event) => {
              setUserSecurityText(event.target.value);
            }}
            value={userSecurityText}
            label={t("components.ui.core.confirmation-modal.confirmation")}
            placeholder={t("components.ui.core.confirmation-modal.type-securitytext-to-confirm", {
              securityText,
            })}
          />
        )}

        <Stack className="mt-8" spacing={4} direction="row" justifyContent="end">
          <Button variant="contained" color="white" type="button" onClick={onClose}>
            {cancelLabel ?? t("components.ui.core.confirmation-modal.cancel")}
          </Button>
          <Button
            type="button"
            variant="contained"
            color={confirmColor}
            disabled={!!securityText && userSecurityText !== securityText}
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {confirmLabel}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
