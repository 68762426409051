import { type EmployeeLocation, type ExternalLocation } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { CacheKeys } from "~/lib/external/redis/cache-keys";

export const getCapitalLocationsByCountryId = async (ctx: AppContext) => {
  const capitals = await ctx.prisma.employeeLocation.findMany({
    where: {
      isCountryCapital: true,
    },
    select: {
      id: true,
      countryId: true,
    },
  });

  const capitalLocationsByCountryId = capitals.reduce<Record<number, number>>((acc, capital) => {
    acc[capital.countryId] = capital.id;
    return acc;
  }, {});

  return capitalLocationsByCountryId;
};

export const getLocationName = async (ctx: AppContext, locationId: number) => {
  const { t } = ctx;
  const locationName = await ctx.prisma.employeeLocation.findUnique({
    where: {
      id: locationId,
    },

    select: {
      name: true,
    },
  });

  return locationName?.name ?? t("common.unknown");
};

export const enrichEmployeesWithCapitalLocationId = async <T extends { location: Pick<EmployeeLocation, "countryId"> }>(
  ctx: AppContext,
  params: { employeesWithLocation: T[] }
) => {
  const capitalLocationsByCountryId = await getCapitalLocationsByCountryId(ctx);

  const employeesWithCapitalLocationId = params.employeesWithLocation.map((employee) => {
    return {
      ...employee,
      capitalLocationId: capitalLocationsByCountryId[employee.location.countryId],
    };
  });

  return employeesWithCapitalLocationId;
};

export const fetchUnmappedNotSkippedExternalLocations = async (ctx: AppContext, companyId: number) => {
  return ctx.prisma.externalLocation.findMany({
    where: {
      companyId,
      skippedAt: null,
      suggestedLocationId: { not: null },
      mappedLocationId: null,
    },
    orderBy: { name: "asc" },
  });
};

export const mapExternalLocationsToSuggestedLocations = async (
  ctx: AppContext,
  externalLocations: ExternalLocation[]
) => {
  return mapSeries(externalLocations, async (externalLocation) => {
    if (!externalLocation.suggestedLocationId || !externalLocation.autoMappingEnabled) {
      return;
    }

    await ctx.prisma.externalLocation.update({
      where: { id: externalLocation.id },
      data: { mappedLocationId: externalLocation.suggestedLocationId },
    });
  });
};

export const fetchActualLocations = async (ctx: AppContext) => {
  return ctx.remember(CacheKeys.ActualLocations, async () =>
    ctx.prisma.employeeLocation.findMany({
      select: { id: true, name: true, country: { select: { id: true, name: true } } },
      where: { name: { notIn: ["Other", "Remote"] } },
      orderBy: { name: "asc" },
    })
  );
};
