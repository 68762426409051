import { type CustomJob, UserRole } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { array, boolean, number, object, string } from "yup";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/get-required-user";
import { type YupOutputType } from "~/lib/utils";
import { WeightedJobSchema } from "~/pages/api/create-custom-job";
import { updateCustomJob } from "~/services/custom-jobs/update-custom-job";

const ExistingWeightedJobSchema = WeightedJobSchema.concat(
  object({
    id: number().required(),
  })
);

export const UpdateCustomJobSchema = object({
  id: number().required(),
  name: string().required(),
  jobs: array().of(ExistingWeightedJobSchema).min(2).ensure(),
  sharedWithCompany: boolean().required(),
});

export type UpdateCustomJobInput = YupOutputType<typeof UpdateCustomJobSchema>;

export type UpdateCustomJobResponse = {
  customJob: CustomJob;
};

const handler: NextApiHandler<UpdateCustomJobResponse> = async (req, res) => {
  const user = getRequiredUser(req);

  const input = UpdateCustomJobSchema.validateSync(req.body, {
    abortEarly: false,
  });

  if (user.permissions.role === UserRole.RECRUITER) {
    input.sharedWithCompany = false;
  }

  const customJob = await updateCustomJob(req, input);

  res.status(HttpStatus.OK).json({ customJob });
};

export default api(handler, {
  method: "POST",
  authentication: {},
});
