import { Gender } from "@prisma/client";
import React from "react";
import { Icon } from "~/components/ui/core/Icon";

type Props = {
  gender: Gender | null;
  withoutText?: boolean;
};

export const GenderLabel: React.FC<Props> = ({ gender, withoutText = false }) => {
  if (!gender) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2">
      {gender === Gender.MALE && (
        <>
          <Icon name="male" />
          {!withoutText && <span>Male</span>}
        </>
      )}
      {gender === Gender.FEMALE && (
        <>
          <Icon name="female" />
          {!withoutText && <span>Female</span>}
        </>
      )}
      {gender === Gender.UNDISCLOSED && (
        <>
          <Icon name="gender" />
          {!withoutText && <span>Undisclosed</span>}
        </>
      )}
    </div>
  );
};
