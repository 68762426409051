import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type AsyncReturnType } from "type-fest";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { getPercentageMappedEmployees } from "~/services/onboarding/external-employee";

const handler: NextApiHandler<AsyncReturnType<typeof getPercentageMappedEmployees>> = async (req, res) => {
  const percentage = await getPercentageMappedEmployees(req);

  return res.status(HttpStatus.OK).json(percentage);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessSettings: true },
});

export const GET_PERCENTAGE_OF_MAPPED_EMPLOYEES_QUERY_KEY: ApiRoute["pathname"] =
  "/api/onboarding/get-percentage-of-mapped-employees";

export const useGetPercentageOfMappedEmployeesQuery = createQuery<typeof handler>({
  path: GET_PERCENTAGE_OF_MAPPED_EMPLOYEES_QUERY_KEY,
});
