import { Typography } from "@mui/material";
import React from "react";
import { EmployeePicture, type PictureForDisplay } from "~/components/employee/employee-picture";
import { EmployeeCard, type EmployeeForCard, employeeSelectForCard } from "~/components/market-data/employee-card";
import { HtmlTooltip } from "~/components/ui/core/HtmlTooltip";

export type EmployeeForBadgeList = EmployeeForCard & { picture: PictureForDisplay | null };

type Props = {
  firstFiveEmployees: EmployeeForBadgeList[];
  count: number;
};

export const selectForEmployeeBadgeList = {
  ...employeeSelectForCard,
  picture: {
    select: {
      path: true,
      height: true,
      width: true,
    },
  },
};

export const EmployeeBadgeList: React.FC<Props> = ({ firstFiveEmployees: employees, count }) => {
  return (
    <div className="flex items-center">
      <div className="flex justify-between -space-x-2">
        {employees.map((employee) => {
          return (
            <HtmlTooltip
              key={employee.id}
              className="flex-1"
              title={<EmployeeCard employee={employee} currency={employee.currency} />}
            >
              <div>
                <EmployeePicture picture={employee.picture} placeholderId={employee.id} />
              </div>
            </HtmlTooltip>
          );
        })}
      </div>
      {count > 5 && (
        <Typography className="ml-2 space-x-2 whitespace-nowrap text-xs text-gray-600">
          and {count - 5} other{count > 6 ? "s" : ""}
        </Typography>
      )}
    </div>
  );
};
