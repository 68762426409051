import { UserRole } from "@prisma/client";
import { isNil } from "lodash";
import { type AuthenticatedUser } from "~/lib/session";
import { getId, isIn } from "~/lib/utils";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { type SubscriptionStatusWithPermissions } from "~/services/subscriptions/validate-subscription";

export const canAccessAdditionalField = (
  user: NullableAuthenticatedUser,
  { additionalFieldId }: { additionalFieldId: number }
): boolean => {
  if (!user) {
    return false;
  }

  if (user.isSuperAdmin) {
    return true;
  }

  if (isIn(user.permissions.role, [UserRole.ADMIN, UserRole.HR])) {
    return true;
  }

  if (!user.permissions.canAccessAdditionalFields) {
    return false;
  }

  if (user.permissions.allowedAdditionalFields.length === 0) {
    return true;
  }

  return user.permissions.allowedAdditionalFields.map(getId).some((id) => id === additionalFieldId);
};

const canAccessSalaryBandMarketData = ({
  user,
  salaryBandId,
}: {
  user: NullableAuthenticatedUser;
  salaryBandId: number;
}) => {
  //starts to check for user
  if (!user) {
    return false;
  }

  if (user.isSuperAdmin || user.permissions.role === UserRole.ADMIN) {
    return true;
  }

  //if the band is explicitely shared
  const share = user.permissions.salaryBandShares.find(({ bandId }) => bandId === salaryBandId);
  if (share) {
    return share.canAccessSalaryBandBenchmarkData;
  }

  //if the user has rights
  if (user.permissions.canAccessMarketData) {
    return true;
  }

  //if the policy allows it
  const policy = user?.company.userPermissionsPolicy;

  if (
    !policy ||
    (user.permissions.role !== UserRole.EMPLOYEE && !user.permissions.isManager) ||
    (user.permissions.isManager && isNil(policy.managerSalaryBandCanAccessMarketData)) ||
    (!user.permissions.isManager && isNil(policy.employeeSalaryBandCanAccessMarketData))
  ) {
    return false;
  }

  return user.permissions.isManager
    ? !!policy.managerSalaryBandCanAccessMarketData
    : !!policy.employeeSalaryBandCanAccessMarketData;
};

export const canAccessFiguresSalaryBandMarketData = ({
  user,
  salaryBandId,
  subscriptions,
}: {
  user: NullableAuthenticatedUser;
  salaryBandId: number;
  subscriptions: SubscriptionStatusWithPermissions;
}) => {
  if (user && user.isSuperAdmin) {
    return true;
  }

  if (!user || !subscriptions.CAN_ACCESS_BENCHMARK) {
    return false;
  }

  return canAccessSalaryBandMarketData({ user, salaryBandId });
};

export const canAccessExternalSalaryBandMarketData = ({
  user,
  salaryBandId,
}: {
  user: NullableAuthenticatedUser;
  salaryBandId: number;
}) => {
  return canAccessSalaryBandMarketData({ user, salaryBandId });
};

export const canUpdateCompany = (user: AuthenticatedUser, input: { companyId?: number | null }) => {
  if (user.isSuperAdmin) {
    return true;
  }

  if (!input.companyId) {
    return true;
  }

  return input.companyId === user.companyId;
};
