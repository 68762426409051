import { type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";

export const findImpersonationAccess = async (ctx: AppContext, { companyId }: { companyId: number }) => {
  return ctx.prisma.companyImpersonationAccess.findFirst({
    where: buildValidImpersonationAccessWhereClause({ companyId }),
  });
};

export const buildValidImpersonationAccessWhereClause = (params?: { companyId: number }) => {
  return {
    ...(params?.companyId && { companyId: params.companyId }),
    declinedAt: null,
    requestedAt: null,
    OR: [{ expiresAt: { gt: new Date() } }, { expiresAt: null }],
  } satisfies Prisma.CompanyImpersonationAccessWhereInput;
};

export type ImpersonationAccess = AsyncReturnType<typeof findImpersonationAccess>;
