import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { ExternalEmployeeDrawerContent } from "~/components/external-employee/drawer/drawer-content";
import { SidePanel, type SidePanelProps } from "~/components/ui/core/SidePanel";
import { parseNumber } from "~/lib/query-params";
import { currentUrlWithQueryParams } from "~/lib/url";

export const EXTERNAL_EMPLOYEE_DRAWER_TAB_KEY = "employee-tab";
export const EXTERNAL_EMPLOYEE_DRAWER_ID_KEY = "ee-id";

export type ExtraTabForExternalEmployeeDrawer = {
  value: string;
  label: string | JSX.Element;
  component: JSX.Element;
};

type ExternalEmployeeDrawerProps = SidePanelProps & {
  externalEmployeeId: number | null;
  extraTabs?: ExtraTabForExternalEmployeeDrawer[];
  defaultTab?: string;
};

export const useExternalEmployeeIdForDrawer = () => {
  const router = useRouter();

  const externalEmployeeIdFromQueryParams = useMemo(() => {
    return parseNumber(router.query, EXTERNAL_EMPLOYEE_DRAWER_ID_KEY);
  }, [router.query]);

  return {
    externalEmployeeId: externalEmployeeIdFromQueryParams,
    pushExternalEmployeeIdInQueryParams: async (externalEmployeeId: number) => {
      await router.push(
        currentUrlWithQueryParams(router, {
          [EXTERNAL_EMPLOYEE_DRAWER_ID_KEY]: externalEmployeeId,
        }),
        undefined,
        { shallow: true }
      );
    },
    removeExternalEmployeeIdFromQueryParams: async () => {
      await router.push(
        currentUrlWithQueryParams(router, {
          [EXTERNAL_EMPLOYEE_DRAWER_ID_KEY]: undefined,
          [EXTERNAL_EMPLOYEE_DRAWER_TAB_KEY]: undefined,
        }),
        undefined,
        { shallow: true }
      );
    },
  };
};

const ExternalEmployeeDrawer: React.FC<ExternalEmployeeDrawerProps> = ({
  externalEmployeeId,
  extraTabs,
  defaultTab = "overview",
  ...rest
}) => {
  return (
    <SidePanel {...rest}>
      {externalEmployeeId && (
        <ExternalEmployeeDrawerContent
          externalEmployeeId={externalEmployeeId}
          extraTabs={extraTabs}
          defaultTab={defaultTab}
        />
      )}
    </SidePanel>
  );
};

export default ExternalEmployeeDrawer;
