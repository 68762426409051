import { type Prisma } from "@prisma/client";
import { isObject } from "lodash";
import { type ParsedUrlQuery } from "querystring";
import { array, boolean, mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { parseBoolean, parseNumericArray, parseString } from "~/lib/query-params";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import { SalaryBandStatus } from "~/services/salary-bands/helpers/salary-band-status";
export const salaryGridFiltersSchema = object({
  salaryBandJobIds: array().of(number().required()).ensure(),
  salaryBandLocationIds: array().of(number().required()).ensure(),
  salaryBandLevelIds: array().of(number().required()).ensure(),
  employeeIds: array().of(number().required()).ensure(),
  showEmptyLevels: boolean().required(),
  search: string().nullable().defined(),
  status: mixed<SalaryBandStatus>()
    .oneOf([...Object.values(SalaryBandStatus), null])
    .nullable()
    .defined(),
});

export const buildSalaryRangeWhereClause = (
  ctx: AppContext,
  params: { salaryGridId: number },
  filters?: SalaryGridFilters
) => {
  const whereBand: Prisma.SalaryBandWhereInput = { grid: whereSalaryGridIs(params) };

  const where: Prisma.SalaryRangeWhereInput = { band: whereBand };

  if (filters?.salaryBandJobIds.length) {
    whereBand.jobId = { in: filters.salaryBandJobIds };
  }

  if (filters?.salaryBandLocationIds.length) {
    whereBand.locationId = { in: filters.salaryBandLocationIds };
  }

  if (filters?.status) {
    whereBand.isDraft = filters.status === SalaryBandStatus.DRAFT;
  }

  if (filters?.salaryBandLevelIds.length) {
    where.levelId = { in: filters.salaryBandLevelIds };
  }

  if (!filters?.showEmptyLevels) {
    where.employees = isObject(ctx.permissionsSchema.SalaryRangeEmployee.read)
      ? { some: ctx.permissionsSchema.SalaryRangeEmployee.read }
      : {};
  }

  const externalEmployeesSomeOrClauses = [];

  if (filters?.employeeIds?.length) {
    externalEmployeesSomeOrClauses.push({
      id: { in: filters.employeeIds },
    });
  }

  if (filters?.search) {
    const matches = { contains: filters.search, mode: "insensitive" } as const;
    externalEmployeesSomeOrClauses.push({ firstName: matches });
    externalEmployeesSomeOrClauses.push({ lastName: matches });
    externalEmployeesSomeOrClauses.push({ employeeNumber: matches });
  }

  if (externalEmployeesSomeOrClauses.length) {
    where.employees = {
      some: {
        externalEmployee: {
          deletedAt: null,
          OR: externalEmployeesSomeOrClauses,
        },
      },
    };
  }

  return where;
};

export const parseSalaryGridFilters = (ctx: AppContext, params: { query: ParsedUrlQuery }) => {
  const user = getRequiredUser(ctx);

  const salaryBandJobIds = parseNumericArray(params.query, "jobs");
  const salaryBandLocationIds = parseNumericArray(params.query, "locations");
  const salaryBandLevelIds = parseNumericArray(params.query, "levels");
  const employeeIds = parseNumericArray(params.query, "employees");
  const showEmptyLevels = parseBoolean(params.query, "show-empty-levels") ?? user.flags.showSalaryBandsEmptyLevels;
  const search = parseString(params.query, "search");
  const status = parseString<SalaryBandStatus>(params.query, "status");

  return { salaryBandJobIds, salaryBandLocationIds, salaryBandLevelIds, employeeIds, status, search, showEmptyLevels };
};

export type SalaryGridFilters = ReturnType<typeof parseSalaryGridFilters>;
