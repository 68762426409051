import { CloseOutlined } from "@mui/icons-material";
import { Drawer, type DrawerProps, IconButton, Stack } from "@mui/material";
import { value } from "~/components/helpers";
import { useShouldDisplayNotices } from "~/components/layout/user-notices";

type HeaderLayoutDrawerProps = Omit<DrawerProps, "anchor" | "sx" | "variant" | "onClose"> & {
  displaySubHeader?: boolean;
  onClose?: () => void;
};

export const DRAWER_WIDTH = "24rem";
export const HEADER_HEIGHT = 100;
export const HEADER_HEIGHT_WITH_SUBHEADER = 148;
export const USER_NOTICES_HEIGHT = 32;

export const HeaderLayoutDrawer: React.FC<HeaderLayoutDrawerProps> = ({
  children,
  displaySubHeader = false,
  open,
  onClose,
  ...props
}) => {
  const shouldDisplayNotices = useShouldDisplayNotices();

  const headerHeight = value(() => {
    const baseHeight = displaySubHeader ? HEADER_HEIGHT_WITH_SUBHEADER : HEADER_HEIGHT;

    return baseHeight + (shouldDisplayNotices ? USER_NOTICES_HEIGHT : 0);
  });

  return (
    <Drawer
      sx={{
        "width": DRAWER_WIDTH,
        "flexShrink": 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          top: headerHeight,
          boxSizing: "border-box",
          height: `calc(100% - ${headerHeight}px)`,
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{
        ...props.PaperProps,
        className: props.PaperProps?.className,
      }}
      {...props}
    >
      <Stack className="h-full">
        {children}

        <IconButton className="absolute right-3 top-3" onClick={onClose}>
          <CloseOutlined fontSize="small" />
        </IconButton>
      </Stack>
    </Drawer>
  );
};
