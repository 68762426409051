import { type Currency, type Prisma, type SalaryBand } from "@prisma/client";
import { isNil, sumBy } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { weightedMeanBy } from "~/lib/math";
import { convertCurrency } from "~/lib/money";
import { isNotNull } from "~/lib/utils";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import { type FetchExternalBenchmarkResult } from "~/services/salary-bands/external-benchmark/fetch-external-benchmark";
import { canAccessExternalSalaryBandMarketData } from "~/services/user/permissions/functions";
import { type salaryRangeSelect } from "./fetch-salary-range";
export const enrichSalaryRanges = async <T extends Prisma.SalaryRangeGetPayload<{ select: typeof salaryRangeSelect }>>(
  ctx: AppContext,
  params: {
    currency: Currency;
    salaryRanges: T[];
    salaryGridId: number;
    externalBenchmarks: FetchExternalBenchmarkResult[];
    externalBenchmarkGroupIds: number[];
    externalBenchmarkLevelIds: number[];
    getSalaryBand: (salaryRange: T) => Pick<SalaryBand, "id">;
  }
) => {
  const user = getRequiredUser(ctx);

  const externalBenchmarkEntries = await fetchExternalBenchmarkEntries(ctx, {
    salaryGridId: params.salaryGridId,
    externalBenchmarkGroupIds: params.externalBenchmarkGroupIds,
    externalBenchmarkLevelIds: params.externalBenchmarkLevelIds,
  });

  return params.salaryRanges.map((salaryRange) => {
    const salaryBand = params.getSalaryBand(salaryRange);

    if (!canAccessExternalSalaryBandMarketData({ user, salaryBandId: salaryBand.id })) {
      return {
        ...salaryRange,
        baseSalaryP10: null,
        baseSalaryP25: null,
        baseSalaryP50: null,
        baseSalaryP75: null,
        baseSalaryP90: null,
        onTargetEarningsP10: null,
        onTargetEarningsP25: null,
        onTargetEarningsP50: null,
        onTargetEarningsP75: null,
        onTargetEarningsP90: null,
        externalBenchmarks: [],
      };
    }

    const externalBenchmarks = params.externalBenchmarks
      .map((externalBenchmark) => {
        const benchmarkedLevelIds = salaryRange.level.externalBenchmarkLevels.map(({ id }) => id);

        const salaryRangeBenchmarks = externalBenchmarkEntries.filter(
          (externalBenchmarkEntry) =>
            externalBenchmarkEntry.benchmarkId === externalBenchmark.id &&
            externalBenchmarkEntry.group.bands.find((salaryBand) => salaryBand.id === salaryRange.bandId) &&
            benchmarkedLevelIds.includes(externalBenchmarkEntry.levelId)
        );

        if (!salaryRangeBenchmarks.length) {
          return null;
        }

        const salaryRangeBenchmark = computeSalaryRangeBenchmark({
          currency: params.currency,
          salaryRangeBenchmarks,
        });

        return {
          ...externalBenchmark,
          salaryRangeBenchmark,
          salaryRangeBenchmarks,
        };
      })
      .filter(isNotNull);

    return {
      ...salaryRange,
      externalBenchmarks,
    };
  });
};

const fetchExternalBenchmarkEntries = async (
  ctx: AppContext,
  params: {
    salaryGridId: number;
    externalBenchmarkGroupIds: number[];
    externalBenchmarkLevelIds: number[];
  }
) => {
  return ctx.prisma.externalBenchmarkEntry.findMany({
    where: {
      grid: whereSalaryGridIs(params),
      groupId: { in: params.externalBenchmarkGroupIds },
      levelId: { in: params.externalBenchmarkLevelIds },
    },
    select: {
      baseSalaryP10: true,
      baseSalaryP25: true,
      baseSalaryP50: true,
      baseSalaryP75: true,
      baseSalaryP90: true,
      onTargetEarningsP10: true,
      onTargetEarningsP25: true,
      onTargetEarningsP50: true,
      onTargetEarningsP75: true,
      onTargetEarningsP90: true,
      benchmarkId: true,
      levelId: true,
      currency: true,
      employeesCount: true,
      distinctCompaniesCount: true,
      level: {
        select: {
          name: true,
        },
      },
      group: {
        select: {
          id: true,
          jobName: true,
          locationName: true,
          bands: {
            select: {
              id: true,
            },
          },
        },
      },
    },
  });
};

const computeSalaryRangeBenchmark = (params: {
  currency: Currency;
  salaryRangeBenchmarks: AsyncReturnType<typeof fetchExternalBenchmarkEntries>;
}) => {
  const computeAmount = (
    getAmount: (salaryRangeBenchmark: (typeof params.salaryRangeBenchmarks)[number]) => number | null
  ): number | null => {
    return weightedMeanBy(params.salaryRangeBenchmarks, (salaryRangeBenchmark) => {
      const amount = getAmount(salaryRangeBenchmark);

      if (isNil(amount)) {
        return [0, 0];
      }

      return [convertCurrency(amount, salaryRangeBenchmark.currency, params.currency), 1];
    });
  };

  return {
    baseSalaryP10: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.baseSalaryP10),
    baseSalaryP25: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.baseSalaryP25),
    baseSalaryP50: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.baseSalaryP50),
    baseSalaryP75: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.baseSalaryP75),
    baseSalaryP90: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.baseSalaryP90),
    onTargetEarningsP10: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.onTargetEarningsP10),
    onTargetEarningsP25: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.onTargetEarningsP25),
    onTargetEarningsP50: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.onTargetEarningsP50),
    onTargetEarningsP75: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.onTargetEarningsP75),
    onTargetEarningsP90: computeAmount((salaryRangeBenchmark) => salaryRangeBenchmark.onTargetEarningsP90),

    employeesCount:
      sumBy(params.salaryRangeBenchmarks, (salaryRangeBenchmark) => salaryRangeBenchmark.employeesCount ?? 0) || null,

    distinctCompaniesCount:
      sumBy(params.salaryRangeBenchmarks, (salaryRangeBenchmark) => salaryRangeBenchmark.distinctCompaniesCount ?? 0) ||
      null,
  };
};
