import { mapSeries } from "bluebird";
import { compact, uniq } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { normaliseExternalEmployeeColumns } from "~/lib/external/external-employee";
import { getRequiredUser } from "~/lib/get-required-user";
import { getId } from "~/lib/utils";
import { ExternalEmployeeSchema, type MapExternalEmployeeInput } from "~/pages/api/map-external-employee";
import { updateEmployeesStats } from "~/services/employee-stats/update-employees-stats";
import { createRemunerationItemsForExternalEmployee } from "~/services/employee/employee-create";
import { updateEmployee } from "~/services/employee/employee-update";
import { getEmployeeSourceDetails } from "~/services/external-employee";
import { transitionExternalEmployeeStatus } from "~/services/external-employee/status-helpers";
const createExternalLevelIfNeeded = async (ctx: AppContext, input: MapExternalEmployeeInput) => {
  const user = getRequiredUser(ctx);

  if (!input.values.externalLevel || input.values.level) {
    return {};
  }

  const existingExternalLevel = await ctx.prisma.externalLevel.findFirst({
    where: {
      companyId: user.companyId,
      name: input.values.externalLevel,
    },
  });

  if (!!existingExternalLevel) {
    return {
      level: {
        connect: { id: existingExternalLevel.id },
      },
    };
  }

  return {
    level: {
      create: {
        externalId: input.values.externalLevel,
        name: input.values.externalLevel,
        company: {
          connect: { id: user.companyId },
        },
      },
    },
  };
};

const createExternalLocationIfNeeded = async (ctx: AppContext, input: MapExternalEmployeeInput) => {
  const user = getRequiredUser(ctx);

  if (!input.values.externalLocation || input.values.location) {
    return {};
  }

  const existingExternalLocation = await ctx.prisma.externalLocation.findFirst({
    where: {
      companyId: user.companyId,
      name: input.values.externalLocation,
    },
  });

  if (!!existingExternalLocation) {
    return {
      location: {
        connect: { id: existingExternalLocation.id },
      },
    };
  }

  return {
    location: {
      create: {
        externalId: input.values.externalLocation,
        name: input.values.externalLocation,
        autoMappingEnabled: true,
        company: {
          connect: { id: user.companyId },
        },
      },
    },
  };
};

const createExternalJobIfNeeded = async (ctx: AppContext, input: MapExternalEmployeeInput) => {
  const user = getRequiredUser(ctx);

  if (!input.values.externalJobTitle || input.values.job) {
    return {};
  }

  const existingExternalJob = await ctx.prisma.externalJob.findFirst({
    where: {
      companyId: user.companyId,
      name: input.values.externalJobTitle,
    },
  });

  if (!!existingExternalJob) {
    return {
      job: {
        connect: { id: existingExternalJob.id },
      },
    };
  }

  return {
    job: {
      create: {
        externalId: input.values.externalJobTitle,
        name: input.values.externalJobTitle,
        company: {
          connect: { id: user.companyId },
        },
      },
    },
  };
};

const updateAndFetchExternalEmployee = async (ctx: AppContext, input: MapExternalEmployeeInput) => {
  const externalEmployee = await ctx.prisma.externalEmployee.update({
    where: { id: input.externalEmployeeId, companyId: getRequiredUser(ctx).companyId },
    data: {
      gender: input.values.gender,
      firstName: input.values.firstName,
      lastName: input.values.lastName,
      currency: { connect: { id: input.values.currency.id } },
      employeeNumber: input.values.employeeNumber,
      hireDate: input.values.hireDate,
      birthDate: input.values.birthDate,

      ...(await createExternalLevelIfNeeded(ctx, input)),
      ...(await createExternalLocationIfNeeded(ctx, input)),
      ...(await createExternalJobIfNeeded(ctx, input)),
    },

    select: {
      id: true,
      source: true,
      employeeNumber: true,
      externalId: true,
      location: { select: { id: true, mappedLocationId: true } },
      job: { select: { id: true, mappedJobId: true } },
      level: { select: { id: true, mappedLevel: true } },
      mappedEmployee: { select: { id: true } },
      manager: { select: { id: true } },
    },
  });

  await normaliseExternalEmployeeColumns(ctx, {
    externalEmployee: { id: input.externalEmployeeId },
  });

  return externalEmployee;
};

type ExternalEmployeeForMapping = AsyncReturnType<typeof updateAndFetchExternalEmployee>;

const alwaysMapJob = async (
  ctx: AppContext,
  input: MapExternalEmployeeInput,
  externalEmployee: ExternalEmployeeForMapping
) => {
  if (!input.values.alwaysMapJob || !input.values.job || !externalEmployee.job) {
    return [];
  }

  const isAlreadyMapped = externalEmployee.job.mappedJobId === input.values.job.id;

  if (isAlreadyMapped) {
    return [];
  }

  const { employees } = await ctx.prisma.externalJob.update({
    where: { id: externalEmployee.job.id },
    data: {
      mappedJob: {
        connect: { id: input.values.job.id },
      },
    },
    select: { employees: { select: { id: true } } },
  });

  return employees.map(getId);
};

const alwaysMapLevel = async (
  ctx: AppContext,
  input: MapExternalEmployeeInput,
  externalEmployee: ExternalEmployeeForMapping
) => {
  if (!input.values.alwaysMapLevel || !input.values.level || !externalEmployee.level) {
    return [];
  }

  const isAlreadyMapped = externalEmployee.level.mappedLevel === input.values.level;

  if (isAlreadyMapped) {
    return [];
  }

  const { employees } = await ctx.prisma.externalLevel.update({
    where: { id: externalEmployee.level.id },
    data: {
      mappedLevel: input.values.level,
    },
    select: { employees: { select: { id: true } } },
  });

  return employees.map(getId);
};

const alwaysMapLocation = async (
  ctx: AppContext,
  input: MapExternalEmployeeInput,
  externalEmployee: ExternalEmployeeForMapping
) => {
  if (!input.values.alwaysMapLocation || !input.values.location || !externalEmployee.location) {
    return [];
  }

  const isAlreadyMapped = externalEmployee.location.mappedLocationId === input.values.location.id;

  if (isAlreadyMapped) {
    return [];
  }

  const { employees } = await ctx.prisma.externalLocation.update({
    where: { id: externalEmployee.location.id },
    data: {
      mappedLocation: {
        connect: { id: input.values.location.id },
      },
    },
    select: { employees: { select: { id: true } } },
  });

  return employees.map(getId);
};

export const mapExternalEmployee = async (ctx: AppContext, input: MapExternalEmployeeInput) => {
  const user = getRequiredUser(ctx);

  const externalEmployee = await updateAndFetchExternalEmployee(ctx, input);

  await createRemunerationItemsForExternalEmployee(
    ctx,
    { baseSalary: input.values.baseSalary },
    {
      companyId: user.companyId,
      source: externalEmployee.source,
    },
    {
      externalEmployeeId: externalEmployee.id,
      onTargetBonus: input.values.onTargetBonus,
      fixedBonus: input.values.fixedBonus,
    }
  );

  if (ExternalEmployeeSchema.isValidSync(input.values) && !!externalEmployee.mappedEmployee) {
    const details = getEmployeeSourceDetails(externalEmployee.source);

    const employee = await updateEmployee(ctx, externalEmployee.mappedEmployee.id, {
      ...input.values,
      reason: `Update from ${details.slug}`,
      updateStrategy: "NEW_VERSION",
    });

    await updateEmployeesStats(ctx, {
      companyId: employee.companyId,
      employeesIds: [employee.id],
    });
  }

  await transitionExternalEmployeeStatus(ctx, externalEmployee.id, {
    jobId: input.values.job?.id,
    level: input.values.level,
    locationId: input.values.location?.id,
  });

  const impactedExternalEmployeeIds: number[] = compact([
    ...(await alwaysMapLocation(ctx, input, externalEmployee)),
    ...(await alwaysMapJob(ctx, input, externalEmployee)),
    ...(await alwaysMapLevel(ctx, input, externalEmployee)),
  ]);

  if (impactedExternalEmployeeIds.length > 0) {
    await mapSeries(
      uniq(impactedExternalEmployeeIds).filter((externalEmployeeId) => externalEmployeeId !== input.externalEmployeeId),
      async (externalEmployeeId) => {
        await transitionExternalEmployeeStatus(ctx, externalEmployeeId);
      }
    );
  }
};
