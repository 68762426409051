import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { isNumber } from "lodash";
import React from "react";
import { useI18n } from "~/lib/i18n/use-i18n";
import { formatMoneyForTextInput } from "~/lib/money";
import { type FetchExternalEmployeeResponse } from "~/pages/api/external-employee/fetch-external-employee";

export type BaseFieldProps = {
  label?: string;
  value: string | number | undefined;
  currency?: Pick<FetchExternalEmployeeResponse["currency"], "symbol">;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  readonly?: boolean;
  readonlyHelperText?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
};

export const BaseField: React.FC<BaseFieldProps> = ({
  label,
  value,
  currency,
  startAdornment,
  endAdornment,
  readonly = false,
  readonlyHelperText,
  onChange,
  fullWidth = false,
  error = false,
  helperText,
}) => {
  const { t } = useI18n();

  if (currency && !startAdornment) {
    startAdornment = <InputAdornment position="start">{currency.symbol}</InputAdornment>;
  }

  if (currency && isNumber(value)) {
    value = formatMoneyForTextInput(value);
  }

  const field = (
    <TextField
      label={label}
      value={value ?? (readonly ? "-" : "")}
      onChange={onChange}
      error={error}
      helperText={helperText}
      sx={{
        width: fullWidth ? "100%" : undefined,
      }}
      FormHelperTextProps={{
        className: "font-medium",
        sx: {
          margin: 0,
        },
      }}
      InputProps={{
        disabled: readonly,
        endAdornment,
        startAdornment,
        sx: {
          "&.Mui-disabled": {
            bgcolor: "background.gray",
            borderColor: "text.disabled",
          },
          "&.Mui-disabled, .Mui-disabled": {
            cursor: "not-allowed",
          },
        },
      }}
    />
  );

  if (!readonly) {
    return field;
  }

  return (
    <Tooltip title={readonlyHelperText ?? t("components.external-employee-drawer.feedback.readonly-field")}>
      <div>{field}</div>
    </Tooltip>
  );
};
