import { type AppContext } from "~/lib/context";
import { logError } from "~/lib/logger";

export const deleteFile = async (
  ctx: AppContext,
  file: { bucket: string; path: string },
  options?: { key: string }
) => {
  const isFixture = file.path.indexOf("fixture") !== -1;

  if (isFixture) {
    return;
  }

  try {
    await ctx.files
      .deleteObject({
        Bucket: file.bucket,
        Key: `${options?.key ?? "assets/"}${file.path}`,
      })
      .promise();
  } catch (error) {
    logError(ctx, "[delete-file] Failed to delete file", { file, error });
    return;
  }
};
