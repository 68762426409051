import { type Currency } from "@prisma/client";
import { partition } from "lodash";
import React, { useEffect } from "react";
import { SelectInput } from "~/components/ui/core/BetterSelectInput";
import { TextInput } from "~/components/ui/core/TextInput";
import { useListCurrenciesQuery } from "~/hooks/useQueries";

type Event = {
  target: {
    name?: string;
    value: Currency;
  };
};

type Props = {
  name?: string;
  value: Currency | null;
  disabled?: boolean;
  onChange: (event: Event) => void;
  formatLabel?: (value: Currency | null) => JSX.Element;
  currencies?: Currency[];
};

const MainCurrenciesCodes = ["EUR", "GBP", "USD"];

export const CurrencySelector: React.FC<Props> = ({
  name,
  value,
  disabled = false,
  onChange,
  formatLabel,
  currencies: initialCurrencies,
}) => {
  const { data: currencyData } = useListCurrenciesQuery({
    enabled: !initialCurrencies,
    ...(initialCurrencies && {
      initialData: { currencies: initialCurrencies },
    }),
  });

  const currencies = currencyData?.currencies ?? [];

  useEffect(() => {
    if (!currencyData) {
      return;
    }

    if (value === null) {
      const defaultCurrency = currencyData.currencies[0];

      if (defaultCurrency) {
        onChange({
          target: {
            name,
            value: defaultCurrency,
          },
        });
      }
    }
  }, [currencyData]);

  const groupProps = (() => {
    if (!!initialCurrencies) {
      return {
        hideGroupLabels: true,
        groups: [{ label: "", options: initialCurrencies }],
      };
    }

    const [mainCurrencies, otherCurrencies] = partition(currencies, (currency) => {
      return MainCurrenciesCodes.indexOf(currency.code) >= 0;
    });

    return {
      groups: [
        { label: "Main currencies", options: mainCurrencies },
        { label: "Other currencies", options: otherCurrencies },
      ],
    };
  })();

  return (
    <SelectInput
      dense
      disabled={disabled}
      formatLabel={() => {
        if (formatLabel) {
          return formatLabel(value);
        }

        return (
          <TextInput readOnly className="cursor-pointer" placeholder="Select a currency" value={value?.name ?? ""} />
        );
      }}
      formatOption={(option) => {
        if (!option) {
          return "-";
        }

        return option.name;
      }}
      formatOptionAffix={(option) => {
        return option?.symbol ?? "";
      }}
      verticalOffset={48}
      groups={groupProps.groups}
      hideGroupLabels={groupProps.hideGroupLabels}
      name={name}
      optionToId={(option) => {
        return option?.id ?? "";
      }}
      placeholder="Select a currency"
      searchOptions={
        currencyData && currencyData.currencies.length > 5
          ? {
              placeholder: "Search for a currency",
              optionToSearchableStrings: (option) => {
                if (!option) {
                  return [];
                }

                return [option.name, option.code, option.symbol];
              },
            }
          : undefined
      }
      value={[value]}
      onChange={(event) => {
        const [currency] = event.target.value;
        if (!currency) {
          return;
        }

        onChange({
          target: {
            name,
            value: currency,
          },
        });
      }}
    />
  );
};
