import { type Headcount } from "@prisma/client";
import { isNil, pick } from "lodash";
import { assertNotNil } from "~/lib/utils";

const headcountMapping: Record<Headcount, { label: string; min: number; max: number | null }> = {
  // Set `min` to 0 for backward compatibility,
  // i.e. some market positioning records created before the addition of `headcount` have a `min` value of 0.
  UP_TO_24: { label: "1-24", min: 0, max: 24 },
  BETWEEN_25_AND_50: { label: "25 - 50", min: 25, max: 50 },
  BETWEEN_51_AND_150: { label: "51 - 150", min: 51, max: 150 },
  BETWEEN_151_AND_250: { label: "151 - 250", min: 151, max: 250 },
  BETWEEN_251_AND_1000: { label: "251 - 1000", min: 251, max: 1_000 },
  BETWEEN_1001_AND_3000: { label: "1001 - 3000", min: 1_001, max: 3_000 },
  BETWEEN_3001_AND_5000: { label: "3001 - 5000", min: 3_001, max: 5_000 },
  MORE_THAN_5000: { label: "5000+", min: 5_001, max: null },
};

export const getHeadcountLabel = (headcount: Headcount) => headcountMapping[headcount].label;

export const getHeadcountRange = (headcount: Headcount | null | undefined) => {
  if (!headcount) return { min: null, max: null };

  return pick(headcountMapping[headcount], ["min", "max"]);
};

export const mapRangeToHeadcount = ({ min, max }: { min?: number | null; max?: number | null }) => {
  if (isNil(min) && isNil(max)) return null;

  const value = assertNotNil(max ?? min);

  return getRelevantHeadcount(value);
};

const getRelevantHeadcount = (value: number) => {
  const mapping = Object.entries(headcountMapping).find(
    ([, mapping]) => mapping.min <= value && value <= (mapping.max ?? Number.POSITIVE_INFINITY)
  );

  const [headcount] = assertNotNil(mapping);

  return headcount as Headcount;
};
