import { AdditionalFieldNature, ExternalEmployeeStatus, Gender, type Prisma } from "@prisma/client";
import { map, mapSeries } from "bluebird";
import { parseISO } from "date-fns";
import { compact, get, isArray, omit, orderBy } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { getKeys } from "~/lib/utils";
import {
  baseEmptyFilters,
  type FilterOptionsInput,
  isDateRangeAdditionalKey,
  isNumberRangeAdditionalKey,
  isPercentageRangeAdditionalKey,
  isStringAdditionalKey,
} from "~/services/employee-filter";
import { formatGender } from "~/services/external-employee/gender";
export const externalEmployeeWhereOmiter = (
  where: Prisma.ExternalEmployeeWhereInput,
  omitKey: "locationId" | "levelId" | "jobId" | "gender" | "performanceReviewRatingId" | "managerExternalEmployeeId"
) => {
  if (!where?.AND || !isArray(where?.AND)) {
    return omit(where, [omitKey]);
  }

  if (isArray(where?.AND) && where?.AND.length === 0) {
    return omit(where, [omitKey]);
  }

  return {
    ...where,
    AND: where.AND.map((where) => omit(where, [omitKey])).filter((where) => !!where && Object.keys(where).length !== 0),
  };
};

const fetchCompanyExternalEmployeesLocations = async (
  ctx: AppContext,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput,
  externalEmployeeScope: Prisma.ExternalEmployeeWhereInput
) => {
  return ctx.prisma.externalLocation.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      employees: {
        some: {
          AND: [externalEmployeeScope, { status: { not: ExternalEmployeeStatus.SKIPPED } }],
        },
      },
    },
    select: {
      _count: {
        select: {
          employees: {
            where: {
              AND: [externalEmployeeScope, externalEmployeeWhereOmiter(externalEmployeeWhere, "locationId")],
            },
          },
        },
      },
      id: true,
      name: true,
      country: {
        select: {
          id: true,
          name: true,
          alpha2: true,
        },
      },
    },
  });
};

const fetchCompanyExternalEmployeesLevels = async (
  ctx: AppContext,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput,
  externalEmployeeScope: Prisma.ExternalEmployeeWhereInput
) => {
  return ctx.prisma.externalLevel.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      employees: {
        some: {
          AND: [externalEmployeeScope, { status: { not: ExternalEmployeeStatus.SKIPPED } }],
        },
      },
    },
    select: {
      _count: {
        select: {
          employees: {
            where: {
              AND: [externalEmployeeScope, externalEmployeeWhereOmiter(externalEmployeeWhere, "levelId")],
            },
          },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesJobs = async (
  ctx: AppContext,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput,
  externalEmployeeScope: Prisma.ExternalEmployeeWhereInput
) => {
  return ctx.prisma.externalJob.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      employees: {
        some: {
          AND: [externalEmployeeScope, { status: { not: ExternalEmployeeStatus.SKIPPED } }],
        },
      },
    },
    select: {
      _count: {
        select: {
          employees: {
            where: {
              AND: [externalEmployeeScope, externalEmployeeWhereOmiter(externalEmployeeWhere, "jobId")],
            },
          },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesPerformanceReviewRatings = async (
  ctx: AppContext,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput,
  externalEmployeeScope: Prisma.ExternalEmployeeWhereInput
) => {
  return ctx.prisma.performanceReviewRating.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      externalEmployees: {
        some: {
          AND: [externalEmployeeScope, { status: { not: ExternalEmployeeStatus.SKIPPED } }],
        },
      },
    },
    select: {
      _count: {
        select: {
          externalEmployees: {
            where: {
              AND: [
                externalEmployeeScope,
                externalEmployeeWhereOmiter(externalEmployeeWhere, "performanceReviewRatingId"),
              ],
            },
          },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesManagers = async (
  ctx: AppContext,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput,
  externalEmployeeScope: Prisma.ExternalEmployeeWhereInput
) => {
  const managerExternalEmployeeIds = (await ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      status: { not: ExternalEmployeeStatus.SKIPPED },
      managerExternalEmployeeId: { not: null },
    },
    distinct: ["managerExternalEmployeeId"],
    select: {
      managerExternalEmployeeId: true,
    },
  })) as { managerExternalEmployeeId: number }[];

  return ctx.prisma.externalEmployee.findMany({
    where: {
      id: { in: managerExternalEmployeeIds.map((manager) => manager.managerExternalEmployeeId) },
      companyId: getRequiredUser(ctx).companyId,
      managees: {
        some: {
          AND: [externalEmployeeScope, { status: { not: ExternalEmployeeStatus.SKIPPED } }],
        },
      },
    },
    select: {
      _count: {
        select: {
          managees: {
            where: {
              AND: [
                externalEmployeeScope,
                externalEmployeeWhereOmiter(externalEmployeeWhere, "managerExternalEmployeeId"),
              ],
            },
          },
        },
      },
      id: true,
      firstName: true,
      lastName: true,
    },
  });
};

const fetchCompanyExternalEmployeesGenders = async (
  ctx: AppContext,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput,
  externalEmployeeScope: Prisma.ExternalEmployeeWhereInput
) => {
  return mapSeries(Object.values(Gender), async (gender) => {
    const count = await ctx.prisma.externalEmployee.count({
      where: {
        gender,
        companyId: getRequiredUser(ctx).companyId,
        status: { not: ExternalEmployeeStatus.SKIPPED },
        AND: [externalEmployeeScope, externalEmployeeWhereOmiter(externalEmployeeWhere, "gender")],
      },
    });

    return {
      gender,
      count,
    };
  });
};

export const businessUnitWhereOmiter = (externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput) => {
  if (!externalEmployeeWhere?.AND || !isArray(externalEmployeeWhere?.AND)) {
    return omit(externalEmployeeWhere, ["businessUnit", "extraBusinessUnits"]);
  }

  if (isArray(externalEmployeeWhere?.AND) && externalEmployeeWhere?.AND.length === 0) {
    return omit(externalEmployeeWhere, ["businessUnit", "extraBusinessUnits"]);
  }

  return {
    ...omit(externalEmployeeWhere, ["businessUnit", "extraBusinessUnits"]),
    AND: externalEmployeeWhere.AND.filter(
      (where) => !where?.OR?.some((or) => or?.businessUnit || or?.extraBusinessUnits)
    ),
  };
};

const fetchCompanyExternalEmployeeBusinessUnits = async (
  ctx: AppContext,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput
) => {
  const {
    featureFlags: { CAN_ACCESS_BUSINESS_UNITS },
  } = ctx;

  const externalEmployees = CAN_ACCESS_BUSINESS_UNITS
    ? ((await ctx.prisma.externalEmployee.findMany({
        where: {
          companyId: getRequiredUser(ctx).companyId,
          status: { not: ExternalEmployeeStatus.SKIPPED },
          businessUnit: { not: null },
        },
        distinct: ["businessUnit"],
        select: {
          businessUnit: true,
        },
      })) as { businessUnit: string }[])
    : [];

  return map(externalEmployees, async ({ businessUnit }) => {
    const count = await ctx.prisma.externalEmployee.count({
      where: {
        companyId: getRequiredUser(ctx).companyId,
        status: { not: ExternalEmployeeStatus.SKIPPED },
        OR: [{ businessUnit }, { extraBusinessUnits: { has: businessUnit } }],
        ...businessUnitWhereOmiter(externalEmployeeWhere),
      },
    });

    return {
      id: businessUnit,
      name: businessUnit,
      count,
    };
  });
};

export const additionalFieldWhereOmiter = (
  additionalFieldId: number,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput
) => {
  if (!externalEmployeeWhere?.AND || !isArray(externalEmployeeWhere?.AND)) {
    return externalEmployeeWhere;
  }

  if (isArray(externalEmployeeWhere?.AND) && externalEmployeeWhere?.AND.length === 0) {
    return externalEmployeeWhere;
  }

  return {
    ...externalEmployeeWhere,
    AND: externalEmployeeWhere.AND.filter(
      (where) => where.additionalFieldValues?.some?.additionalFieldId !== additionalFieldId
    ),
  };
};

export const fetchCompanyStringAdditionalFields = async (
  ctx: AppContext,
  externalEmployeeWhere: Prisma.ExternalEmployeeWhereInput
) => {
  const additionalFields = await ctx.prisma.additionalField.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      nature: AdditionalFieldNature.STRING,
      values: {
        some: {},
      },
    },
    select: {
      values: {
        distinct: ["stringValue"],
        select: {
          id: true,
          stringValue: true,
        },
        orderBy: [{ stringValue: "asc" }],
      },
      id: true,
      name: true,
    },
  });

  return map(additionalFields, async (additionalField) => {
    const rawCount = await ctx.prisma.additionalFieldValue.groupBy({
      by: ["stringValue"],
      where: {
        additionalFieldId: additionalField.id,
        externalEmployee: {
          status: { not: ExternalEmployeeStatus.SKIPPED },
          ...additionalFieldWhereOmiter(additionalField.id, externalEmployeeWhere),
        },
      },
      _count: {
        externalEmployeeId: true,
      },
      orderBy: [{ _count: { externalEmployeeId: "desc" } }],
    });

    const count = rawCount.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.stringValue]: curr._count.externalEmployeeId,
      }),
      {}
    ) as Record<string, number>;

    const values = additionalField.values.map((value) => ({
      id: value.stringValue,
      name: value.stringValue,
      count: count[value.stringValue] ?? 0,
    }));

    return {
      ...additionalField,
      values: orderBy(values, ["count", "name"], ["desc", "asc"]),
    };
  });
};

export const fetchCompanyRangeAdditionalFields = async (ctx: AppContext) => {
  return await ctx.prisma.additionalField.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      nature: { in: [AdditionalFieldNature.DATE, AdditionalFieldNature.NUMBER, AdditionalFieldNature.PERCENTAGE] },
      values: {
        some: {},
      },
    },
    select: {
      id: true,
      name: true,
      nature: true,
    },
  });
};

export type FetchExternalEmployeeFilterOptionsParams = {
  where?: Prisma.ExternalEmployeeWhereInput;
  scope?: Prisma.ExternalEmployeeWhereInput;
};

export const fetchExternalEmployeeFilterOptions = async (
  ctx: AppContext,
  params: FetchExternalEmployeeFilterOptionsParams
) => {
  const { where = {}, scope = {} } = params;

  const [
    rawLocations,
    rawLevels,
    rawJobs,
    rawGenders,
    additionalFields,
    rangeAdditionalFields,
    businessUnits,
    rawPerformanceReviewRatings,
    rawManagers,
  ] = await Promise.all([
    fetchCompanyExternalEmployeesLocations(ctx, where, scope),
    fetchCompanyExternalEmployeesLevels(ctx, where, scope),
    fetchCompanyExternalEmployeesJobs(ctx, where, scope),
    fetchCompanyExternalEmployeesGenders(ctx, where, scope),
    fetchCompanyStringAdditionalFields(ctx, where),
    fetchCompanyRangeAdditionalFields(ctx),
    fetchCompanyExternalEmployeeBusinessUnits(ctx, where),
    fetchCompanyExternalEmployeesPerformanceReviewRatings(ctx, where, scope),
    fetchCompanyExternalEmployeesManagers(ctx, where, scope),
  ]);

  const locations = rawLocations.map((loc) => ({ ...loc, count: loc._count.employees }));
  const levels = rawLevels.map((level) => ({ ...level, count: level._count.employees }));
  const jobs = rawJobs.map((job) => ({ ...job, count: job._count.employees }));
  const managers = rawManagers.map((manager) => ({
    ...manager,
    name: `${manager.firstName} ${manager.lastName}`,
    count: manager._count.managees,
  }));
  const performanceReviewRatings = rawPerformanceReviewRatings.map((performanceReview) => ({
    id: performanceReview.id,
    name: performanceReview.name,
    count: performanceReview._count.externalEmployees,
  }));
  const genders = rawGenders.map((gender) => ({
    id: gender.gender,
    name: formatGender(ctx.t, gender.gender),
    count: gender.count,
  }));

  return {
    ...baseEmptyFilters,
    locations: orderBy(locations, ["count", "name"], ["desc", "asc"]),
    levels: orderBy(levels, ["count", "name"], ["desc", "asc"]),
    jobs: orderBy(jobs, ["count", "name"], ["desc", "asc"]),
    performanceReviewRatings: orderBy(performanceReviewRatings, ["position", "name"], ["asc", "asc"]),
    managers: orderBy(managers, ["count", "lastName", "firstName"], ["desc", "asc", "asc"]),
    genders: orderBy(genders, ["count", "name"], ["desc", "asc"]),
    businessUnits: orderBy(businessUnits, ["count", "name"], ["desc", "asc"]),
    hireDate: null,
    additionalFields,
    rangeAdditionalFields,
  };
};

export type FetchExternalEmployeeFilterOptionsResult = AsyncReturnType<typeof fetchExternalEmployeeFilterOptions>;

export const buildExternalEmployeeFilterWhereClauses = (
  selectedFilterOptions: FilterOptionsInput
): Prisma.ExternalEmployeeWhereInput[] => {
  const additionalFields = getKeys(selectedFilterOptions)
    .filter(isStringAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("additional-field-", "")),
      additionalFieldValues: selectedFilterOptions[key],
    }));

  const numberAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isNumberRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("number-additional-field-", "")),
      min: selectedFilterOptions[key]?.[0] ? Number(selectedFilterOptions[key]?.[0]) : undefined,
      max: selectedFilterOptions[key]?.[1] ? Number(selectedFilterOptions[key]?.[1]) : undefined,
    }));

  const percentageAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isPercentageRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("percentage-additional-field-", "")),
      min: selectedFilterOptions[key]?.[0] ? Number(selectedFilterOptions[key]?.[0]) : undefined,
      max: selectedFilterOptions[key]?.[1] ? Number(selectedFilterOptions[key]?.[1]) : undefined,
    }));

  const dateAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isDateRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("date-additional-field-", "")),
      min: (selectedFilterOptions[key]?.[0] as Date) ?? undefined,
      max: (selectedFilterOptions[key]?.[1] as Date) ?? undefined,
    }));

  const additionalFieldConditions = compact([
    ...additionalFields.map(
      ({ additionalFieldId, additionalFieldValues }) =>
        additionalFieldValues?.length && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              stringValue: { in: additionalFieldValues },
            },
          },
        }
    ),
    ...numberAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              numberValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
    ...percentageAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              percentageValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
    ...dateAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              dateValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
  ]) satisfies Prisma.ExternalEmployeeWhereInput[];

  return compact([
    !!selectedFilterOptions?.locations?.length && {
      locationId: { in: selectedFilterOptions.locations },
    },
    !!selectedFilterOptions?.jobs?.length && {
      jobId: { in: selectedFilterOptions.jobs },
    },
    !!selectedFilterOptions?.levels?.length && {
      levelId: { in: selectedFilterOptions.levels },
    },
    !!selectedFilterOptions?.performanceReviewRatings?.length && {
      performanceReviewRatingId: { in: selectedFilterOptions.performanceReviewRatings },
    },
    !!selectedFilterOptions?.managers?.length && {
      managerExternalEmployeeId: { in: selectedFilterOptions.managers },
    },
    !!selectedFilterOptions?.businessUnits?.length && {
      OR: [
        { businessUnit: { in: selectedFilterOptions.businessUnits } },
        { extraBusinessUnits: { hasSome: selectedFilterOptions.businessUnits } },
      ],
    },
    !!selectedFilterOptions.genders?.length && {
      gender: {
        in: compact(selectedFilterOptions.genders.map((rawGender) => get(Gender, rawGender))),
      },
    },
    selectedFilterOptions.hireDate?.length && {
      hireDate: {
        ...(selectedFilterOptions.hireDate?.[0] && {
          gte: parseISO(selectedFilterOptions.hireDate[0]),
        }),
        ...(selectedFilterOptions.hireDate?.[1] && {
          lte: parseISO(selectedFilterOptions.hireDate[1]),
        }),
      },
    },
    ...additionalFieldConditions,
  ]);
};
