import { UserLocale } from "@prisma/client";
import computeMedian from "compute-median";
import { chain, sortBy } from "lodash";
import ordinal from "ordinal";
import percentileRank from "percentile-rank";
import weightedMean from "weighted-mean";

export const formatPercent = (value: number, decimals = 0, signed = false): string => {
  const sign = value > 0 && signed ? "+" : "";

  return `${sign}${(value * 100).toFixed(decimals)}%`;
};

export const formatPercentile = (value: number, locale: UserLocale, decimals = 0): string => {
  const percentile = +(value * 100).toFixed(decimals);

  if (locale === UserLocale.FR) {
    const affix = percentile === 1 ? "er" : "ème";

    return `${percentile}${affix}`;
  }

  return ordinal(percentile);
};

export const serializePercent = (value: number): number => {
  return value / 100;
};

export const roundTo = (value: number, multiple: number): number => {
  return Math.round(value / multiple) * multiple;
};

export const approxEqual = (a: number, b: number, tolerance = 0.01): boolean => {
  return Math.abs(a - b) < tolerance;
};

export const percentageDifferenceToTarget = (amount: number, target: number) => {
  return amount / target - 1;
};

export const safePercentileRank = (values: number[], value: number): number => {
  if (values.length === 1) {
    return 0.5;
  }

  return percentileRank(sortBy(values), value);
};

export const medianBy = <T>(values: T[], iteratee: (item: T) => number): number => {
  const mapped = values.map(iteratee);

  return computeMedian(mapped);
};

export const weightedMeanBy = <T>(items: T[], iteratee: (item: T) => [number | null, number]): number => {
  return chain(items)
    .map((item) => {
      return iteratee(item) as [number | null, number];
    })
    .filter((item): item is [number, number] => {
      return item[0] !== null;
    })
    .thru((items) => {
      return items.length ? weightedMean(items) : null;
    })
    .value();
};

export const ratioBy = <T>(items: T[], iteratee: (item: T) => boolean): number => {
  if (!items.length) {
    return 0;
  }

  const filteredCount = items.filter(iteratee).length;

  return filteredCount / items.length;
};

export const addNoise = (value: number, percentage: number) =>
  value * (1 + Math.random() * percentage - percentage / 2);
