import React, { useMemo, useState } from "react";
import { type ModalAction } from "~/components/ui/core/Modal";
import { type Choice, SelectAChoiceModal } from "~/components/ui/core/SelectAChoiceModal";

type Reason = {
  reason: "not-an-employee-anymore" | "delete-from-dataset";
} & Choice;

const PREDEFINED_REASONS: readonly Reason[] = [
  { label: "They have left the company", reason: "not-an-employee-anymore" },
  { label: "I no longer want them to appear on Figures", reason: "delete-from-dataset" },
] as const;

type Props = {
  reason?: Reason | null;
  onSubmit: (reason: Reason) => void;
} & Pick<React.ComponentProps<typeof SelectAChoiceModal>, "isOpen" | "onClose">;

export const EmployeeRemovalReasonSelectorModal: React.FC<Props> = ({ isOpen, onClose, reason, onSubmit }) => {
  const [selectedReason, setSelectedReason] = useState(reason);

  const primaryAction = useMemo<ModalAction>(
    () => ({
      label: "Submit",
      onClick: () => {
        if (!selectedReason) {
          return;
        }

        onSubmit(selectedReason);
        setSelectedReason(null);
        onClose();
      },
      disabled: !selectedReason,
    }),
    [selectedReason]
  );

  const secondaryAction = useMemo<ModalAction>(
    () => ({
      label: "Cancel",
      onClick: () => {
        setSelectedReason(null);
        onClose();
      },
    }),
    []
  );

  return (
    <SelectAChoiceModal
      choices={PREDEFINED_REASONS}
      onChoiceChange={setSelectedReason}
      selectedChoice={selectedReason}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      icon="message-question"
      className="w-[560px]"
      isOpen={isOpen}
      title="Why are you removing this employee?"
      subtitle="Your answer will help us handle more cases in the future."
      onClose={() => {
        setSelectedReason(null);
        onClose();
      }}
    />
  );
};
