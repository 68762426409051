import { type EmployeeUpdateStrategy } from "@prisma/client";
import React, { useMemo, useState } from "react";
import { type ModalAction } from "~/components/ui/core/Modal";
import { type ChoiceOrCustomChoice, SelectAChoiceModal, isCustomChoice } from "~/components/ui/core/SelectAChoiceModal";
import { useI18n } from "~/lib/i18n/use-i18n";

export type Reason = {
  strategy: EmployeeUpdateStrategy | null;
} & ChoiceOrCustomChoice;

const PREDEFINED_REASONS: readonly Reason[] = [
  { label: "I fixed a mistake", strategy: "PATCH" },
  { label: "We went through a salary review", strategy: "NEW_VERSION" },
  { label: "This person has changed job or level", strategy: "NEW_VERSION" },
  { label: "Another reason", strategy: "NEW_VERSION", isCustom: true, type: "text" },
] as const;

type Props = {
  reason?: Reason | null;
  onSubmit: (reason: Reason) => void;
} & Pick<React.ComponentProps<typeof SelectAChoiceModal>, "isOpen" | "onClose">;

export const EmployeeUpdateReasonSelectorModal: React.FC<Props> = ({ isOpen, onClose, reason, onSubmit }) => {
  const { t } = useI18n();
  const [selectedReason, setSelectedReason] = useState(reason);

  const primaryAction = useMemo<ModalAction>(
    () => ({
      label: "Submit",
      onClick: () => {
        if (!selectedReason) {
          return;
        }

        onSubmit(selectedReason);
        setSelectedReason(null);
        onClose();
      },
      disabled: isReasonInvalid(selectedReason),
    }),
    [selectedReason]
  );

  const secondaryAction = useMemo<ModalAction>(
    () => ({
      label: "Cancel",
      onClick: () => {
        setSelectedReason(null);
        onClose();
      },
    }),
    []
  );

  return (
    <SelectAChoiceModal
      choices={PREDEFINED_REASONS}
      onChoiceChange={setSelectedReason}
      selectedChoice={selectedReason}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      icon="message-question"
      className="w-[560px]"
      isOpen={isOpen}
      title={t("components.external-employee-drawer.overview.modal.update-reason.title")}
      subtitle={t("components.external-employee-drawer.overview.modal.update-reason.subtitle")}
      onClose={() => {
        setSelectedReason(null);
        onClose();
      }}
    />
  );
};

const isReasonInvalid = (reason?: Reason | null) => {
  if (!reason) {
    return true;
  }

  return isCustomChoice(reason) && !reason.customValue?.trim();
};
