import { type ExternalRemunerationItem, type ExternalRemunerationNature } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { generateEmployeeBucketHash } from "~/services/company-dashboard/generate-employee-bucket-hash";
import { type ExternalEmployeeForMappingUpdate } from "~/services/external-employee/external-employee-mapping";
import { transitionExternalEmployeeStatus } from "~/services/external-employee/status-helpers";

export type RemunerationItemWithNature = Pick<ExternalRemunerationItem, "amount"> & {
  nature: Pick<ExternalRemunerationNature, "mappedType">;
};

export const createOrUpdateEmployeeMappingFromExternalEmployee = async (
  ctx: AppContext,
  externalEmployee: ExternalEmployeeForMappingUpdate
) => {
  logInfo(ctx, "[mapping] Updating employee", { externalEmployeeId: externalEmployee.id });

  await transitionExternalEmployeeStatus(ctx, externalEmployee.id);

  // If the external employee is already mapped to an employee, just apply the mapping to the employee
  if (externalEmployee.mappedEmployee) {
    logInfo(ctx, "[mapping] Employee is already mapped, updating", {
      externalEmployeeId: externalEmployee.id,
      employeeId: externalEmployee.mappedEmployeeId,
    });

    const mappedJobId = externalEmployee.job?.mappedJobId;
    const mappedLevel = externalEmployee.level?.mappedLevel;
    const mappedLocationId = externalEmployee.location?.mappedLocationId;

    return ctx.prisma.employee.update({
      where: { id: externalEmployee.mappedEmployee.id },
      data: {
        ...(mappedJobId && { job: { connect: { id: mappedJobId } } }),
        ...(mappedLevel && { level: mappedLevel }),
        ...(mappedLocationId && { mappingLocation: { connect: { id: mappedLocationId } } }),
        ...(mappedLocationId && {
          location: {
            connect: { id: externalEmployee.location?.mappedLocation?.fallbackLocationId ?? mappedLocationId },
          },
        }),

        bucketHash: generateEmployeeBucketHash({
          countryId:
            externalEmployee.location?.mappedLocation?.countryId ?? externalEmployee.mappedEmployee?.location.countryId,
          jobId: externalEmployee.job?.mappedJobId ?? externalEmployee.mappedEmployee?.jobId,
          level: externalEmployee.level?.mappedLevel ?? externalEmployee.mappedEmployee?.level,
        }),
      },
    });
  }
};
