import { DataValidationFlagOrigin, DataValidationFlagType, type EmployeeDataValidationFlag } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { mixed, number, object, string } from "yup";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { validateRequestAuthorised } from "~/lib/security";
import { GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { createFlagIfNoLiveFlags } from "~/services/employee-data-validation-flag/data-flagging";

export type CreateEmployeeDataValidationFlagReturns = {
  flag: EmployeeDataValidationFlag;
  message: string;
};

export const EmployeeDataValidationSchema = object({
  employeeId: number().required(),
  comment: string().required(),
  companyId: number().nullable(),
  authorId: number().nullable(),
  type: mixed<DataValidationFlagType>().oneOf(Object.values(DataValidationFlagType)).required(),
  origin: mixed<DataValidationFlagOrigin>().oneOf(Object.values(DataValidationFlagOrigin)).required(),
});

const handler: NextApiHandler<CreateEmployeeDataValidationFlagReturns> = async (req, res) => {
  const input = EmployeeDataValidationSchema.validateSync(req.body, {
    abortEarly: false,
  });

  validateRequestAuthorised(req, { companyId: input.companyId });

  const flag = await createFlagIfNoLiveFlags(req, input.employeeId, {
    comment: input.comment,
    isLive: true,
    type: input.type,
    origin: input.origin,
    authorId: input.authorId ?? undefined,
    companyId: input.companyId ?? undefined,
  });

  res.status(HttpStatus.OK).json({
    flag,
    message: "Employee Data has been flagged",
  });
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessRawData: true },
});

export const useCreateEmployeeDataValidationFlagMutation = createMutation<
  typeof handler,
  typeof EmployeeDataValidationSchema
>({
  path: "/api/employee-data-validation-flag/create-employee-data-validation-flag",
  schema: EmployeeDataValidationSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY);
    },
  }),
});
