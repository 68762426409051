import { Policy } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { type CompanyRowForSelector, CompanySelector } from "~/components/ui/company-selector";
import { SidePanel } from "~/components/ui/core/SidePanel";
import { Button } from "~/components/ui/core/button";
import { useForm } from "~/hooks/useForm";
import {
  type RequestExceptionalImpersonationInput,
  RequestExceptionalImpersonationSchema,
  useRequestExceptionalImpersonationMutation,
} from "~/pages/api/user/request-exceptional-impersonation";
import { EXCEPTIONAL_IMPERSONATION_DURATION_IN_HOURS } from "~/services/user/request-exceptional-impersonation";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const RequestExceptionalImpersonationPanel: React.FC<Props> = (props) => {
  const [selectedCompany, setSelectedCompany] = useState<CompanyRowForSelector[]>([]);

  const requestExceptionalImpersonation = useRequestExceptionalImpersonationMutation({
    successMessage: "Impersonation granted, have fun!",
  });

  const form = useForm<RequestExceptionalImpersonationInput>({
    initialValues: {
      reason: "",
      companyId: -1,
    },
    validateOnMount: true,
    validationSchema: RequestExceptionalImpersonationSchema,
    onSubmit: async (values) => {
      return requestExceptionalImpersonation.mutateAsync(values);
    },
  });

  return (
    <SidePanel {...props} width="narrow" side="left" disableBottomPadding>
      <Stack className="h-full" direction="column">
        <Stack
          className="sticky top-0 z-20 h-16 shrink-0 items-center space-x-2 bg-primary-300 pl-6 pr-4 shadow"
          direction="row"
        >
          <Policy fontSize="medium" />
          <Typography variant="h2">Request Exceptional Impersonation</Typography>
        </Stack>

        <Stack className="p-4" spacing={2}>
          <Typography>
            Please pick the company you need to impersonate exceptionally and explain quickly the reason for which you
            require it.
          </Typography>

          <CompanySelector
            allowSearchById
            includeVentureCapitals
            api="admin"
            value={selectedCompany}
            placeholder="Pick the company you wish to impersonate"
            onChange={(event) => {
              if (event.target.value[0]) {
                const companyToImpersonate = event.target.value[0];
                setSelectedCompany([companyToImpersonate]);
                void form.setFieldValue("companyId", companyToImpersonate.id);
              }
            }}
          />

          <TextField
            multiline
            rows={4}
            placeholder="Why do you need to impersonate this company? (required)"
            value={form.values.reason}
            onKeyDown={(event) => event.stopPropagation()} // https://figures-hr.slack.com/archives/C05845FH6TB/p1718974198249509 🤷
            onChange={(event) => {
              void form.setFieldValue("reason", event.target.value);
            }}
          />

          <Typography variant="h4">
            Once requested, you will impersonate {selectedCompany.length ? selectedCompany[0]?.name : "the company"}{" "}
            straight away. The impersonation access will be valid for {EXCEPTIONAL_IMPERSONATION_DURATION_IN_HOURS} hour
            {EXCEPTIONAL_IMPERSONATION_DURATION_IN_HOURS > 1 ? "s" : ""}.
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="end" spacing={4} className="px-4">
          <Button variant="outlined" color="secondary" onClick={props.onClose}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!form.isValid || form.isSubmitting}
            isLoading={requestExceptionalImpersonation.isLoading}
            onClick={form.submitForm}
          >
            Impersonate, now!
          </Button>
        </Stack>
      </Stack>
    </SidePanel>
  );
};
