import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/get-required-user";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { GET_ASSISTED_ONBOARDING_STATUS_QUERY_KEY } from "~/pages/api/onboarding/get-assisted-onboarding-status";
import { sendPreSyncExternalEmployeesJob } from "~/workers/pre-sync-external-employees";

type SyncExternalEmployeesResponse = {
  message?: string;
  error?: string;
};

const SyncExternalEmployeesAdminSchema = object({
  companyId: number().optional(),
});

const handler: NextApiHandler<SyncExternalEmployeesResponse> = async (req, res) => {
  const { companyId } = SyncExternalEmployeesAdminSchema.validateSync(req.body);
  const user = getRequiredUser(req);

  await sendPreSyncExternalEmployeesJob(req, { companyId: companyId ?? user.companyId });

  res.status(HttpStatus.ACCEPTED).json({ message: "Synchronisation scheduled" });
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessIntegrations: true },
});

export const useSyncExternalEmployeesMutation = createMutation<typeof handler, typeof SyncExternalEmployeesAdminSchema>(
  {
    path: "/api/sync-external-employees",
    schema: SyncExternalEmployeesAdminSchema,
    options: ({ queryClient }) => ({
      onSuccess: async () => {
        await invalidateQuery(queryClient, GET_ASSISTED_ONBOARDING_STATUS_QUERY_KEY);
      },
    }),
  }
);
