import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/get-required-user";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY } from "~/pages/api/external-employee/count-external-employees-by-status";
import { FETCH_EXTERNAL_EMPLOYEE_QUERY_KEY } from "~/pages/api/external-employee/fetch-external-employee";
import { FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY } from "~/pages/api/imported-employees/fetch-all-imported-employees";
import { updateEmployeesStats } from "~/services/employee-stats/update-employees-stats";
import { reconcileExternalEmployee } from "~/services/external-employee/reconciliation";

const ReconciliateExternalEmployeeSchema = object({
  externalEmployeeId: number().required(),
  employeeId: number().required(),
});

const handler: NextApiHandler = async (req, res) => {
  const user = getRequiredUser(req);

  const input = ReconciliateExternalEmployeeSchema.validateSync(req.body, {
    abortEarly: false,
  });

  await reconcileExternalEmployee(req, { externalEmployeeId: input.externalEmployeeId, employeeId: input.employeeId });

  await updateEmployeesStats(req, {
    companyId: user.companyId,
    employeesIds: [input.employeeId],
  });

  res.status(HttpStatus.OK).json({
    message: "Employees mapped",
  });
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessIntegrations: true,
    canAccessRawData: true,
  },
});

export const useReconciliateExternalEmployeeMutation = createMutation<
  typeof handler,
  typeof ReconciliateExternalEmployeeSchema
>({
  path: "/api/external-employee/reconciliate",
  schema: ReconciliateExternalEmployeeSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, FETCH_EXTERNAL_EMPLOYEE_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY);
      await invalidateQuery(queryClient, COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY);
    },
  }),
});
