import { type Company, ExternalEmployeeSource, ExternalRemunerationStatus } from "@prisma/client";
import { isAfter, isBefore, subYears } from "date-fns";
import { partition } from "lodash";
import { isFromOneYear, parseISO } from "~/lib/dates";
import {
  type FixedRemunerationItem,
  type LuccaEmployee,
  type LuccaUser,
  type VariableRemunerationItem,
} from "~/lib/hris/lucca";
import { type EmployeeData } from "~/services/synchronization/sync-external-employees";

const oneYearAgo = subYears(Date.now(), 1);

const itemIsForUser = (userId: number) => {
  return (item: Pick<FixedRemunerationItem, "workContract">) => item.workContract.owner.id === userId;
};
const itemIsCurrent = (item: FixedRemunerationItem) => {
  if (item.isDeleted) {
    return false;
  }

  if (!!item.endsOn && isBefore(parseISO(item.endsOn), oneYearAgo)) {
    return false;
  }

  if (item.nature.isPonctual) {
    const lastYear = subYears(new Date(), 1);

    return isAfter(parseISO(item.startsOn), lastYear);
  }

  if (!item.isCurrent) {
    return false;
  }

  if (isAfter(parseISO(item.startsOn), Date.now())) {
    return false;
  }
  return true;
};

export const getSalariesFromPagga = (
  users: LuccaEmployee[],
  allFixedItems: FixedRemunerationItem[],
  allVariableItems: VariableRemunerationItem[]
): LuccaUser[] => {
  const usersWithRemunerations = users.map((user) => {
    const filteredFixedItems = allFixedItems.filter(itemIsForUser(user.id));

    // Theoretical variable compensation is stored in the fixed items with the variable isInActualRemuneration set to false
    const [fixedItems, allVariableInFixedItems] = partition(
      filteredFixedItems,
      (item) => item.nature.isInActualRemuneration
    );

    const [currentFixedItems, historicalFixedItems] = partition(fixedItems, itemIsCurrent);

    const variableInFixedItems = allVariableInFixedItems.filter(itemIsCurrent);

    if (variableInFixedItems.length > 0) {
      return {
        ...user,
        fixedSalariesFromPagga: currentFixedItems,
        historicalFixedSalariesFromPagga: historicalFixedItems,
        variableBonusesFromPagga: variableInFixedItems.map((item) => {
          const multiplier = item.nature.isPonctual ? 1 : 12;

          return {
            ...item,
            period: { date: item.startsOn },
            amount: item.amount * multiplier,
          };
        }),
      };
    }

    // If no theoretical variable is provided, we estimate it from the payments
    // made during the last year
    const variableItems = allVariableItems
      .filter((item) => !item.isDeleted)
      .filter(itemIsForUser(user.id))
      .filter((item) => isFromOneYear(parseISO(item.period.date)));

    return {
      ...user,
      fixedSalariesFromPagga: currentFixedItems,
      historicalFixedSalariesFromPagga: historicalFixedItems,
      variableBonusesFromPagga: variableItems,
    };
  });

  return usersWithRemunerations;
};

export const mapPaggaFixedSalary = (company: Company, numberMonths: number, status: ExternalRemunerationStatus) => {
  return (item: FixedRemunerationItem): EmployeeData["remunerationItems"][number] => {
    const multiplier = item.nature.isPonctual ? 1 : numberMonths;

    const prefix = status === ExternalRemunerationStatus.HISTORICAL ? "historical-" : "";

    return {
      source: ExternalEmployeeSource.LUCCA,
      externalId: `${prefix}${item.id.toString()}`,
      amount: Math.round(item.amount * 100) * multiplier,
      status,
      date: parseISO(item.startsOn),
      numberMonths,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.LUCCA,
              externalId: `fixed-${item.nature.id.toString()}`,
            },
          },
          create: {
            source: ExternalEmployeeSource.LUCCA,
            externalId: `fixed-${item.nature.id.toString()}`,
            name: item.nature.name,
            mappedType: "FIXED_SALARY",
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    };
  };
};

export const mapPaggaVariableBonus = (company: Company) => {
  return (item: VariableRemunerationItem): EmployeeData["remunerationItems"][number] => {
    return {
      source: ExternalEmployeeSource.LUCCA,
      externalId: item.id.toString(),
      amount: Math.round(item.amount * 100),
      date: parseISO(item.period.date),
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.LUCCA,
              externalId: `variable-${item.nature.id.toString()}`,
            },
          },
          create: {
            source: ExternalEmployeeSource.LUCCA,
            externalId: `variable-${item.nature.id.toString()}`,
            name: item.nature.name,
            mappedType: "VARIABLE_BONUS",
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    };
  };
};
