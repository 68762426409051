import { type Currency, ExternalRemunerationType } from "@prisma/client";
import {
  computeEmployeeCompensation,
  type ComputeEmployeeCompensationInput,
  type ComputeEmployeeCompensationOptions,
} from "~/services/employee";
import { type RemunerationItemWithNature } from "~/services/employee/employee-mapping";
import { sumRemunerationItems } from "~/services/employee/sum-remuneration-items";

type ExternalEmployeeCompensationInput = {
  currency: Currency;
  remunerationItems: RemunerationItemWithNature[];
};

export const computeExternalEmployeeCompensation = (
  externalEmployee: ExternalEmployeeCompensationInput & { mappedEmployee: ComputeEmployeeCompensationInput | null },
  options: ComputeEmployeeCompensationOptions
) => {
  const targetCurrency =
    options.targetCurrency ?? externalEmployee.mappedEmployee?.currency ?? externalEmployee.currency;

  const compensation = computeEmployeeCompensation(
    externalEmployee.mappedEmployee ?? getExternalEmployeeCompensation(externalEmployee),
    {
      targetCurrency,
      ...options,
    }
  );

  return { compensation, currency: targetCurrency };
};

const getExternalEmployeeCompensation = (externalEmployee: ExternalEmployeeCompensationInput) => {
  const { remunerationItems, currency } = externalEmployee;

  return {
    currency,
    baseSalary: sumRemunerationItems(remunerationItems, ExternalRemunerationType.FIXED_SALARY),
    fixedBonus: sumRemunerationItems(remunerationItems, ExternalRemunerationType.FIXED_BONUS),
    onTargetBonus: sumRemunerationItems(remunerationItems, ExternalRemunerationType.VARIABLE_BONUS),
  };
};
