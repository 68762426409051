import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { type FeatureFlagsStatus } from "~/services/feature-flags";
import { type SubscriptionStatusWithPermissions } from "~/services/subscriptions/validate-subscription";
import * as actionPermissions from "./action-permissions";
import * as compensationReviewPermissions from "./compensation-review-permissions";
import * as featureAccessPermissions from "./feature-access-permissions";
import * as marketDataPermissions from "./market-data-permissions";
import { canAccessSalaryBandsTiering, canUpdateSalaryBands } from "./salary-bands-permissions";

// ## Action Permissions ##
// Can Manage Users
// Can Setup SSO

// ## Feature Access Permissions ##
// Can Access Additional Field
// Can Access Salary Band Market Data
// Can Access People Dashboard
// Can Access Raw Data
// Can Access Integrations
// Can Access Settings
// Can Access Account
// Can Access Compensation Review
// Can Access Salary Bands
// Can Access Business Units
// Can Access Imported Employees
// Can Access File Manager

// ## Market Data Permissions ##
// Can Access Market Data
// Can Access You Vs Market

// ## Salary Bands Permissions ##
// Can Update Salary Bands
// Can Access Salary Bands Tiering

// ## Compensation Review Permissions ##
// Can Access Compensation Review Bonus Allocation
// Can Access Off Cycle Reviews
// Can Create Compensation Review
// Can Delete Compensation Review

export type PermissionsParams = {
  user: NullableAuthenticatedUser;
  subscriptions: SubscriptionStatusWithPermissions;
  featureFlags?: FeatureFlagsStatus;
};

export const globalPermissions = {
  ...marketDataPermissions,
  ...compensationReviewPermissions,
  ...featureAccessPermissions,
  ...actionPermissions,
  canUpdateSalaryBands,
  canAccessSalaryBandsTiering,
};

export type Permissions = keyof typeof globalPermissions;

export type PermissionsStatus = Record<Permissions, boolean>;

// Default permissions with every permissions being false
export const defaultPermissions: PermissionsStatus = Object.fromEntries(
  Object.keys(globalPermissions).map((permission) => [permission, false])
) as Record<Permissions, boolean>;
