import { generateExternalEmployeesSoftDeleteSchema } from "~/lib/prisma-restrictions/schemas/generate-external-employees-soft-delete-schema";
import { generateExternalRemunerationItemHistoricalSchema } from "~/lib/prisma-restrictions/schemas/generate-external-remuneration-item-historical-schema";

export const generatePrismaRestrictionsSchemasFn = () => {
  return (options: { includeSoftDeletedExternalEmployees?: boolean; includeHistoricalRemunerationItems?: boolean }) => {
    const schemas = [];

    if (!options.includeSoftDeletedExternalEmployees) {
      schemas.push(generateExternalEmployeesSoftDeleteSchema());
    }

    if (!options.includeHistoricalRemunerationItems) {
      schemas.push(generateExternalRemunerationItemHistoricalSchema());
    }

    return schemas;
  };
};
