import { RemappingRequestStatus } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { array, mixed, number, object } from "yup";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { GET_COMPANY_REMAPPING_REQUEST_QUERY_KEY } from "~/pages/api/remapping-request/get-company-remapping-request";
import {
  type UpdateRemappingRequestResult,
  updateRemappingRequest,
} from "~/services/remapping-request/update-remapping-request";

export const UpdateRemappingRequestSchema = object({
  ids: array(number().required()).required(),
  status: mixed<RemappingRequestStatus>().oneOf(Object.values(RemappingRequestStatus)).required(),
});

export type UpdateRemappingRequestInput = YupOutputType<typeof UpdateRemappingRequestSchema>;

const handler: NextApiHandler<UpdateRemappingRequestResult> = async (req, res) => {
  const input = UpdateRemappingRequestSchema.validateSync(req.body, { abortEarly: false });

  const updateRemappingRequestResult = await updateRemappingRequest(req, input);

  return res.status(HttpStatus.OK).json(updateRemappingRequestResult);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessRawData: true,
  },
});

export const useUpdateRemappingRequestMutation = createMutation<typeof handler, typeof UpdateRemappingRequestSchema>({
  path: "/api/remapping-request/update-remapping-request",
  schema: UpdateRemappingRequestSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, GET_COMPANY_REMAPPING_REQUEST_QUERY_KEY);
    },
  }),
});
