const WORDS_TO_REMOVE = ["Junior", "Intermediate", "Senior", "Werkstudent"];
const PATTERNS_TO_REMOVE = [
  /\bremote/i,
  /\bfull-time/i,
  /\{/,
  /}/,
  /–/,
  /-/,
  /f\/h/i,
  /h\/f/i,
  /\//,
  /\?/,
  /\|/,
  /I\+D\+i/,
  /\d+%/,
  /\d+h/g,
  /\d{2,4}/g,
];
const UNPROCESSABLE_KEYWORDS = ["Example", "Job Title"];
const UNPROCESSABLE_KEYWORDS_REGEXP = new RegExp("\\b" + UNPROCESSABLE_KEYWORDS.join("|\\b"), "i");

/**
 * @throws UnprocessableError
 */
export const sanitizeJobNameForEmbedding = (jobName: string) => {
  if (jobName.match(UNPROCESSABLE_KEYWORDS_REGEXP) !== null) {
    throw new UnprocessableError(`Provided string contains words that make it unprocessable. String: ${jobName}`);
  }

  let name = jobName.replace(/\(.*?\)/g, "");

  for (const pattern of PATTERNS_TO_REMOVE) {
    name = name.replace(pattern, " ");
  }

  name = name.replace(new RegExp("\\b" + WORDS_TO_REMOVE.join("|\\b"), "i"), "");

  name = name.replace(/\s+/g, " ");

  return name.trim();
};

export class UnprocessableError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, UnprocessableError.prototype);
  }
}
