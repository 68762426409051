import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import {
  defaultPermissions,
  globalPermissions,
  type PermissionsStatus,
} from "~/services/user/permissions/authentication-options";
export const getActivePermissionsForUser = async (ctx: AppContext): Promise<PermissionsStatus> => {
  if (!ctx.user) {
    return defaultPermissions;
  }

  const user = getRequiredUser(ctx);

  if (user.isSuperAdmin) {
    return Object.fromEntries(
      Object.entries(globalPermissions).map(([permission]) => [permission, true])
    ) as PermissionsStatus;
  }

  return Object.fromEntries(
    Object.entries(globalPermissions).map(([permission, func]) => [
      permission,
      func({
        user,
        featureFlags: ctx.featureFlags,
        subscriptions: ctx.subscriptions,
      }),
    ])
  ) as PermissionsStatus;
};
