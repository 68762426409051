import { FormControl, type Input, Select, TextField } from "@mui/material";
import { type ComponentProps } from "react";
import { type Merge } from "type-fest";

type SelectProps = ComponentProps<typeof Select>;

type TextFieldProps = ComponentProps<typeof TextField>;
type InputProps = ComponentProps<typeof Input>;

type Props = React.PropsWithChildren<
  Merge<
    TextFieldProps,
    {
      selectValue: SelectProps["value"];
      onSelectChange: SelectProps["onChange"];
      SelectProps?: Omit<SelectProps, "value" | "onChange">;
      InputProps?: InputProps;
    }
  >
>;

export const SelectableTextField: React.FC<Props> = ({
  value,
  onChange,
  selectValue,
  onSelectChange,
  SelectProps,
  children,
  InputProps,
  ...TextFieldProps
}) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      InputProps={{
        sx: {
          paddingRight: 0,
        },
        endAdornment: (
          <FormControl size={TextFieldProps?.size} sx={{ minWidth: "auto" }}>
            <Select
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    padding: 0,
                    outline: "none",
                  },
                },
              }}
              value={selectValue}
              onChange={onSelectChange}
              {...SelectProps}
            >
              {children}
            </Select>
          </FormControl>
        ),
        ...InputProps,
      }}
      {...TextFieldProps}
    />
  );
};
