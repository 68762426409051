import { Skeleton, Slide, Stack, Typography } from "@mui/material";
import { DataValidationFlagOrigin } from "@prisma/client";
import React from "react";
import { Trans } from "react-i18next";
import { useBoolean } from "react-use";
import { OverrideEmployeeFlagModal } from "~/components/employee/employee-data-validation-flag/override-employee-flag-modal";
import { EmployeePicture } from "~/components/employee/employee-picture";
import { type ExternalEmployeeDrawerPossibleTab } from "~/components/external-employee/drawer/drawer-content";
import { MoreActionsButton } from "~/components/external-employee/drawer/more-actions-button";
import { ReconciliationAlert } from "~/components/external-employee/drawer/reconciliation-alert";
import { useEmployeeForm } from "~/components/external-employee/drawer/use-employee-form";
import { HeaderAlert } from "~/components/external-employee/header-alert";
import { Mode, useEditMode } from "~/components/external-employee/use-edit-mode";
import { Button } from "~/components/ui/core/button";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import { useGetActiveFlagByEmployeeId } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { useUpdateEmployeeDataValidationFlagMutation } from "~/pages/api/employee-data-validation-flag/update-employee-data-validation-flag";
import { formatDataValidationFlagType } from "~/services/employee-data-validation-flag/data-flagging";
import { formatExternalEmployeeName } from "~/services/external-employee";

export const EmployeeHeader: React.FC<{
  tab: ExternalEmployeeDrawerPossibleTab;
}> = ({ tab }) => {
  const { t } = useI18n();
  const subtitleElements: JSX.Element[] = [];
  const { mode } = useEditMode();
  const { externalEmployeeId, externalEmployee, form, unsavedChanges } = useEmployeeForm();

  if (externalEmployee) {
    if (externalEmployee.job) {
      subtitleElements.push(<>{externalEmployee.job.name}</>);
    }

    if (externalEmployee.level) {
      subtitleElements.push(<>{externalEmployee.level.name}</>);
    }

    if (externalEmployee.location) {
      subtitleElements.push(
        <>
          {externalEmployee.location.name}
          {externalEmployee.location.country && <span>,&nbsp;{externalEmployee.location.country.name}</span>}
        </>
      );
    }
  }

  return (
    <>
      <Stack spacing={4} alignItems="end" direction="row">
        <EmployeePicture
          className="border-2 border-white shadow-xl"
          size="4xl"
          picture={externalEmployee ? externalEmployee.picture : null}
          placeholderId={externalEmployeeId}
          pictureUrl={externalEmployee?.userPermissions?.user?.profilePictureUrl}
        />
        <Stack spacing={2} className="!mb-5 flex-grow">
          <Stack direction="row" alignItems="center">
            <Typography variant="h2" sx={{ height: "38px" }}>
              {!externalEmployee && <Skeleton width={200} />}
              {externalEmployee && formatExternalEmployeeName(externalEmployee)}
            </Typography>
            <Stack direction="row" gap={2} justifyContent="end" className="flex-grow">
              <MoreActionsButton />
              <Slide direction="left" in={mode === Mode.EDIT && tab === "overview"} mountOnEnter unmountOnExit>
                <div>
                  <Button
                    variant="contained"
                    type="submit"
                    isLoading={form.isSubmitting}
                    disabled={unsavedChanges === 0}
                  >
                    {t("components.external-employee-drawer.actions.save")}
                  </Button>
                </div>
              </Slide>
            </Stack>
          </Stack>

          {subtitleElements.length > 0 && (
            <Stack direction="row">
              {subtitleElements.map((element, index) => (
                <React.Fragment key={index}>
                  {!!index && (
                    <Typography variant="subtitle1" component="span" color="text.secondary" className="mx-1.5">
                      &ndash;
                    </Typography>
                  )}
                  <Typography variant="subtitle1" component="span" color="text.secondary">
                    {element}
                  </Typography>
                </React.Fragment>
              ))}
            </Stack>
          )}

          {!externalEmployee && (
            <Typography variant="subtitle1" component="span" color="text.secondary">
              <Skeleton width={300} />
            </Typography>
          )}
        </Stack>
      </Stack>
      <FlagAlert />
      {externalEmployee && !externalEmployee.mappedEmployee && (
        <ReconciliationAlert externalEmployee={externalEmployee} />
      )}
    </>
  );
};

const FlagAlert: React.FC = () => {
  const { user } = useSession();
  const { t } = useI18n();
  const { externalEmployee } = useEmployeeForm();

  const [showOverrideFlagModal, setShowOverrideFlagModal] = useBoolean(false);

  const { data: activeDataValidationFlag } = useGetActiveFlagByEmployeeId({
    options: {
      enabled: !!externalEmployee?.mappedEmployee,
    },
    input: {
      employeeId: externalEmployee?.mappedEmployee?.id,
    },
  });

  const updateEmployeeDataValidationFlag = useUpdateEmployeeDataValidationFlagMutation({
    successMessage: t("components.ui.employee-form.employee-flags-successfully-updated"),
  });

  const dismissFlag = async () => {
    if (!activeDataValidationFlag) {
      return;
    }

    await updateEmployeeDataValidationFlag.mutateAsync({
      id: activeDataValidationFlag.id,
      employeeId: activeDataValidationFlag.employeeId,
      comment: activeDataValidationFlag.comment,
      isLive: false,
    });
  };

  if (!activeDataValidationFlag) {
    return null;
  }

  return (
    <>
      <HeaderAlert visible={!!activeDataValidationFlag}>
        <Stack direction="row" className="items-center justify-between">
          <Stack>
            <Trans
              i18nKey="components.external-employee-drawer.feedback.employee-has-been-reported"
              values={{ type: formatDataValidationFlagType(activeDataValidationFlag.type) }}
            />
            <Typography variant="caption" component="blockquote">
              "{activeDataValidationFlag.comment}"
            </Typography>
          </Stack>

          <Stack direction="row" gap={2}>
            {user?.isSuperAdmin && activeDataValidationFlag.origin === DataValidationFlagOrigin.HRIS_SYNC && (
              <Button size="small" variant="outlined" color="error" onClick={() => setShowOverrideFlagModal(true)}>
                {t("common.override")}
              </Button>
            )}

            <Button size="small" variant="contained" color="error" onClick={() => dismissFlag()}>
              {t("common.dismiss")}
            </Button>
          </Stack>
        </Stack>
      </HeaderAlert>

      {!!activeDataValidationFlag && user?.isSuperAdmin && (
        <OverrideEmployeeFlagModal
          initialFlag={activeDataValidationFlag}
          isOpen={showOverrideFlagModal}
          onClose={() => setShowOverrideFlagModal(false)}
        />
      )}
    </>
  );
};
