import { Warning } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Link } from "~/components/ui/core/Link";
import { useI18n } from "~/lib/i18n/use-i18n";

export const EmployeeIssuesChip = ({ issuesCount }: { issuesCount: number }) => {
  const { t } = useI18n();
  return (
    <Link to="/account/data-validation/employees-data" className="origin-right transition hover:scale-105">
      <Chip
        variant="outlined"
        color="warning"
        icon={<Warning className="!ml-1.5" />}
        size="small"
        label={t("components.employee.employee-issues-chip.nb-issues", {
          count: issuesCount,
        })}
      />
    </Link>
  );
};
