import {
  type EmployeeMappingSkipReason,
  EmployeeStatus,
  type ExternalEmployee,
  ExternalEmployeeStatus,
  Prisma,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext, transaction } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { getId } from "~/lib/utils";
import { createHistoricalEmployee } from "~/services/employee/employee-create";
import { deleteEmployee } from "~/services/employee/employee-delete";
import {
  type ExternalEmployeeForPartialCheck,
  isValidPartialEmployee,
} from "~/services/external-employee/status-checkers";

export const externalEmployeeMappingUpdateArgs = Prisma.validator<Prisma.ExternalEmployeeArgs>()({
  include: {
    company: true,
    mappedEmployee: { include: { location: true } },
    job: { include: { mappedJob: true } },
    level: true,
    remunerationItems: { include: { nature: true } },
    currency: true,
    location: { include: { mappedLocation: true } },
    manager: { include: { mappedEmployee: true } },
  },
});

export type ExternalEmployeeForMappingUpdate = Prisma.ExternalEmployeeGetPayload<
  typeof externalEmployeeMappingUpdateArgs
>;

export const disconnectEarlierExternalEmployee = async (
  ctx: AppContext,
  { externalEmployeeId, employeeId }: { externalEmployeeId: number; employeeId: number }
) => {
  const alreadyMapped = await ctx.prisma.externalEmployee.findFirst({
    where: {
      id: { not: externalEmployeeId },
      mappedEmployeeId: employeeId,
    },
  });

  if (alreadyMapped) {
    logInfo(ctx, "[ext-emp] An external employee was already mapped to this employee. Disconnecting it.", {
      alreadyMappedId: alreadyMapped.id,
      employeeId,
    });
    await ctx.prisma.externalEmployee.update({
      where: { id: alreadyMapped.id },
      data: {
        status: ExternalEmployeeStatus.SKIPPED,
        mappedEmployee: { disconnect: true },
      },
    });
  }
};

export const mapToEmployee = async (
  ctx: AppContext,
  { externalEmployeeId, employeeId }: { externalEmployeeId: number; employeeId: number }
) => {
  logInfo(ctx, "[ext-emp] Mapping to a LIVE employee", { externalEmployeeId, employeeId });

  await disconnectEarlierExternalEmployee(ctx, { employeeId, externalEmployeeId });

  const { source, mappedEmployee } = await ctx.prisma.externalEmployee.update({
    where: { id: externalEmployeeId },
    data: {
      status: ExternalEmployeeStatus.MAPPED,
      mappedEmployee: { connect: { id: employeeId } },
      driftFields: [],
      mappingSkipReason: null,
    },
    select: {
      source: true,
      mappedEmployee: { select: { source: true } },
    },
  });

  if (source !== mappedEmployee?.source) {
    await createHistoricalEmployee(ctx, employeeId);

    await ctx.prisma.employee.update({ where: { id: employeeId }, data: { source } });
  }

  logInfo(ctx, "[ext-emp] Finished mapping to a LIVE employee", { externalEmployeeId, employeeId });
};

export const skipExternalEmployees = async (
  ctx: AppContext,
  params: {
    externalEmployees: ExternalEmployee[];
    mappingSkipReason: EmployeeMappingSkipReason;
  }
) => {
  const externalEmployeeIds = params.externalEmployees.map(getId);

  logInfo(ctx, "[mapping] Skipping external employees", {
    externalEmployeeIds,
  });

  const externalEmployees = await ctx.prisma.externalEmployee.findMany({
    where: { id: { in: externalEmployeeIds }, status: { not: ExternalEmployeeStatus.SKIPPED } },
    select: { id: true, mappedEmployeeId: true },
  });

  if (!externalEmployees.length) {
    return;
  }

  // Skip external employees
  await transaction(ctx, async () => {
    await mapSeries(externalEmployees, async (externalEmployee) => {
      await ctx.prisma.externalEmployee.update({
        where: { id: externalEmployee.id },
        data: {
          mappedEmployeeId: null,
          mappingSkipReason: params.mappingSkipReason,
          status: ExternalEmployeeStatus.SKIPPED,
        },
      });

      // Delete mapped employee
      if (externalEmployee.mappedEmployeeId !== null) {
        await ctx.prisma.employee.update({
          where: { id: externalEmployee.mappedEmployeeId },
          data: { status: EmployeeStatus.DELETED },
        });
      }
    });
  });
};

export const unmapExternalEmployee = async (
  ctx: AppContext,
  externalEmployee: ExternalEmployeeForPartialCheck & Pick<ExternalEmployee, "mappedEmployeeId">
) => {
  if (!externalEmployee.mappedEmployeeId) {
    const isPartial = isValidPartialEmployee(externalEmployee);

    await ctx.prisma.externalEmployee.update({
      where: { id: externalEmployee.id },
      data: {
        mappedEmployee: { disconnect: true },
        status: isPartial ? ExternalEmployeeStatus.PARTIAL : ExternalEmployeeStatus.UNMAPPED,
      },
    });
  } else {
    await deleteEmployee(ctx, externalEmployee.mappedEmployeeId, {
      reason: "delete-from-dataset",
    });
  }
};
