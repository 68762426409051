import { type Currency, type EmployeeLevel, type EmployeeLocation } from "@prisma/client";
import { noop } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { Ping } from "~/components/ui/core/Ping";
import { MarketPositioningChip } from "~/components/ui/market-positioning-chip";
import { useApi } from "~/hooks/useApi";
import { useI18n } from "~/lib/i18n/use-i18n";
import {
  type ComputeCompensationMarketPositioningResponse,
  ComputeCompensationMarketPositioningSchema,
} from "~/pages/api/compute-compensation-market-positioning";
import { getMarketPositioning } from "~/services/market-positioning";

type Props = {
  totalCash: number;
  currency: Pick<Currency, "id">;
  level: EmployeeLevel | null;
  location: Pick<EmployeeLocation, "id"> | null;
  jobId?: number;
};

export const EmployeeFormMarketPositioningChip: React.FC<Props> = ({ totalCash, currency, jobId, level, location }) => {
  const { apiFetch } = useApi();
  const { t } = useI18n();
  const [isComputing, setIsComputing] = useState(false);
  const [stats, setStats] = useState<ComputeCompensationMarketPositioningResponse | null>(null);

  const input = useMemo(
    () => ({
      jobId,
      level,
      locationId: location?.id,
      totalCash,
      currencyId: currency.id,
    }),
    [jobId, level, location, totalCash, currency]
  );

  const [debouncedInput] = useDebounce(input, 300);

  const computeStats = async (signal: AbortSignal) => {
    try {
      ComputeCompensationMarketPositioningSchema.validateSync(debouncedInput);
    } catch (error) {
      return null;
    }

    return await apiFetch<ComputeCompensationMarketPositioningResponse>(
      "/api/compute-compensation-market-positioning",
      {
        body: debouncedInput,
        signal,
        setIsLoading: setIsComputing,
      }
    );
  };

  useEffect(() => {
    const controller = new AbortController();

    computeStats(controller.signal).then(setStats).catch(noop);

    return () => {
      controller.abort();
    };
  }, [debouncedInput]);

  return (
    <div className="flex flex-col">
      {isComputing && (
        <div className="flex items-center">
          <Ping color="secondary" />
          <span className="ml-2 text-sm text-gray-600">Computing company target positioning...</span>
        </div>
      )}
      {!isComputing && stats && stats.meanPercentageDifferenceFromTarget !== null && (
        <div className="ml-0.5">
          <MarketPositioningChip positioning={getMarketPositioning(t, stats.meanPercentageDifferenceFromTarget)} />
        </div>
      )}
    </div>
  );
};
