import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import {
  type ImpersonationAccessHistory,
  getImpersonationAccessHistoryForCompany,
} from "~/services/impersonation/get-impersonation-access-history-for-company";

const ImpersonationAccessHistorySchema = object({
  companyId: number().required(),
});

const handler: NextApiHandler<ImpersonationAccessHistory> = async (req, res) => {
  const { companyId } = ImpersonationAccessHistorySchema.validateSync(req.body, {
    abortEarly: false,
  });

  const impersonationAccessHistory = await getImpersonationAccessHistoryForCompany(req, { companyId });

  res.status(HttpStatus.OK).json(impersonationAccessHistory);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSettings: true,
  },
});

export const FETCH_IMPERSONATION_ACCESS_HISTORY_QUERY_KEY: ApiRoute["pathname"] =
  "/api/auth/company-impersonation/fetch-impersonation-access-history";

export const useFetchImpersonationAccessHistoryQuery = createQuery<
  typeof handler,
  typeof ImpersonationAccessHistorySchema
>({
  path: FETCH_IMPERSONATION_ACCESS_HISTORY_QUERY_KEY,
  schema: ImpersonationAccessHistorySchema,
});
