import { QueueJobName } from "@prisma/client";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { baseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { sendJob } from "~/lib/queue/send-job";
import { type YupOutputType } from "~/lib/utils";
import { synchronizeSalaryRangeEmployees } from "~/services/salary-bands/configuration/synchronise-salary-range-employees";

const SyncSalaryRangeEmployeesJobDataSchema = baseJobDataSchema.concat(object({ salaryGridId: number().required() }));

export type SyncSalaryRangeEmployeesJobData = YupOutputType<typeof SyncSalaryRangeEmployeesJobDataSchema>;

export const syncSalaryRangeEmployeesWorkerService = async (ctx: AppContext, data: SyncSalaryRangeEmployeesJobData) => {
  const { salaryGridId } = SyncSalaryRangeEmployeesJobDataSchema.validateSync(data, { abortEarly: false });

  await synchronizeSalaryRangeEmployees(ctx, { salaryGridId });
};

export const sendSyncSalaryRangeEmployeesJob = async (ctx: AppContext, data: SyncSalaryRangeEmployeesJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.SYNC_SALARY_RANGE_EMPLOYEES,
    data,
    options: { singletonKey: data.salaryGridId.toString() },
  });
};
