import { type Job, Prisma, UserLocale } from "@prisma/client";
import { chain, keyBy, sumBy } from "lodash";
import { type TFunction } from "next-i18next";
import { type AppContext } from "~/lib/context";
import { CacheKeys } from "~/lib/external/redis/cache-keys";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { getLevelDefinitions } from "~/services/employee/employee-level";
import { getAllowedJobFamilyIds } from "~/services/job";

export type Suggestion = {
  job: { id: number; name: string; description: string | null; familyId: number };
  jobConfidenceScore: number;
  suggestionSkippedAt?: Date;
  companyExternalJobTitles?: string[];
};

type SimilarJobRow = { jobId: number; count: bigint };
type CompanyExternalJobTitles = { jobId: number; externalJobTitle: string };

const MINIMAL_SIMILARITY_SCORE = 0.5;

type ClassifyJobParams = {
  jobTitle: string;
  addCompanyExternalJobTitles?: boolean;
};

const fetchJobs = async (ctx: AppContext) => {
  const { remember, user } = ctx;
  const locale = user?.locale ?? UserLocale.EN;

  return remember(`${CacheKeys.JobsForClassification}-${locale}`, async () => {
    const jobs = await ctx.prisma.job.findMany({ include: { jobAliases: true } });

    return {
      jobsById: keyBy(jobs, ({ id }) => id),
      jobsByName: keyBy(jobs, ({ name }) => name.toLowerCase()),
    };
  });
};

export const prepareJobTitle = (t: TFunction, jobTitle: string) => {
  const EmployeeLevelsDetails = getLevelDefinitions(t);
  const INDIVIDUAL_CONTRIBUTOR_LEVELS = Object.values(EmployeeLevelsDetails)
    .filter((level) => level.track === "INDIVIDUAL_CONTRIBUTOR")
    .map(({ nameWithoutSeniority, alias }) => `${nameWithoutSeniority}|${alias}`);

  const levelsRegEx = new RegExp(`^(${INDIVIDUAL_CONTRIBUTOR_LEVELS.join("|")})\\s*`, "gim");
  return jobTitle.replace(levelsRegEx, "");
};

export const classifyJob = async (ctx: AppContext, params: ClassifyJobParams) => {
  const { remember, user, t } = ctx;
  const { jobTitle, addCompanyExternalJobTitles = false } = params;
  const locale = user?.locale ?? UserLocale.EN;

  const key = `${CacheKeys.ClassifiedJobs}-${jobTitle}-${locale}`;

  const suggestions = await remember(key, async () => {
    const { jobsById, jobsByName } = await fetchJobs(ctx);

    const perfectMatchingJob =
      jobsByName[jobTitle.toLowerCase()] ?? jobsByName[prepareJobTitle(t, jobTitle).toLowerCase()];
    if (perfectMatchingJob) {
      return [
        {
          job: perfectMatchingJob,
          jobConfidenceScore: 1,
        },
      ];
    }

    const similarJobs = await fetchSimilarJobs(ctx, jobTitle);

    const totalRows = sumBy(similarJobs, (job) => Number(job.count));

    return chain(similarJobs)
      .mapValues((job) => ({
        job: jobsById[job.jobId] as Job,
        jobConfidenceScore: Number(job.count) / totalRows,
      }))
      .orderBy(({ jobConfidenceScore }) => jobConfidenceScore, "desc")
      .take(10)
      .value();
  });

  const enrichedSuggestions = addCompanyExternalJobTitles
    ? await enrichSuggestionsWithCompanyExternalJobTitles(ctx, jobTitle, suggestions)
    : suggestions;

  return getAllowedJobSuggestions(enrichedSuggestions, user).slice(0, 3);
};

const enrichSuggestionsWithCompanyExternalJobTitles = async (
  ctx: AppContext,
  jobTitle: string,
  suggestions: Suggestion[]
) => {
  const { user } = ctx;

  if (!user?.companyId || !suggestions.length) {
    return suggestions;
  }

  const companyExternalJobTitles = await fetchCompanyExternalJobTitles(
    ctx,
    jobTitle,
    suggestions.map(({ job }) => job.id)
  );

  if (!companyExternalJobTitles.length) {
    return suggestions;
  }

  return suggestions.map((suggestion) => ({
    ...suggestion,
    companyExternalJobTitles: companyExternalJobTitles
      .filter(({ jobId }) => jobId === suggestion.job.id)
      .map(({ externalJobTitle }) => externalJobTitle),
  }));
};

const fetchSimilarJobs = async (ctx: AppContext, jobTitle: string): Promise<SimilarJobRow[]> => {
  await ctx.prisma.$executeRawUnsafe(`
    SET pg_trgm.similarity_threshold = ${MINIMAL_SIMILARITY_SCORE};
  `);
  return await ctx.prisma.$queryRaw`
      SELECT
        DISTINCT("jobId"),
        count("id") as count
      FROM "Employee"
      WHERE
        "status" = 'LIVE'
        AND  "externalJobTitle" % ${jobTitle}
      group by "jobId"
      order by count desc
    `;
};

const fetchCompanyExternalJobTitles = async (
  ctx: AppContext,
  jobTitle: string,
  jobIds: number[]
): Promise<CompanyExternalJobTitles[]> => {
  const { user } = ctx;

  if (!user?.companyId) {
    return [];
  }

  return await ctx.prisma.$queryRaw`
      SELECT
        "Job"."id" AS "jobId",
        "Employee"."externalJobTitle" as "externalJobTitle"
      FROM "Employee"
      JOIN "Job" ON "Job"."id" = "Employee"."jobId"
      WHERE
        "Employee"."status" = 'LIVE'
        AND "Employee"."companyId" = ${user.companyId}
        AND "jobId" IN (${Prisma.join(jobIds)})
      GROUP BY "Job"."id", "Employee"."externalJobTitle"
      ORDER BY SIMILARITY("Employee"."externalJobTitle", '${jobTitle}') DESC
    `;
};

const getAllowedJobSuggestions = (jobSuggestions: Suggestion[], user: NullableAuthenticatedUser) => {
  return jobSuggestions.filter(({ job }) => {
    if (!job) {
      return false;
    }

    const allowedJobFamilyIds = getAllowedJobFamilyIds(user);
    if (!allowedJobFamilyIds?.length) {
      return true;
    }

    return !!allowedJobFamilyIds.find((id) => job?.familyId === id);
  });
};
