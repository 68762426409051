import { compact, isNil } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type SyncPerformanceReviewsInput } from "~/pages/api/sync-performance-reviews";
import { findOrCreatePerformanceReviewCycle } from "~/services/performance-review/find-or-create-performance-review-cycle";
export const updateSelectedPerformanceReviewCycles = async (ctx: AppContext, input: SyncPerformanceReviewsInput) => {
  const user = getRequiredUser(ctx);

  if (isNil(input.selectedExternalPerformanceCycleIds)) return;

  const cycle = await findOrCreatePerformanceReviewCycle(ctx, {
    companyId: user.isSuperAdmin ? input.companyId ?? user.companyId : user.companyId,
    performanceReviewScope: input.performanceReviewScope,
  });

  return ctx.prisma.performanceReviewCycle.update({
    where: { id: cycle.id },
    data: {
      selectedCycleIds: compact(input.selectedExternalPerformanceCycleIds),
    },
  });
};

export type UpdateSelectedPerformanceReviewCycles = AsyncReturnType<typeof updateSelectedPerformanceReviewCycles>;
