import { accessibleBy } from "@casl/prisma";
import { type WhereInputPerModel } from "@casl/prisma/dist/types/prismaClientBoundTypes";
import { Prisma } from "@prisma/client";
import { compact, mapValues } from "lodash";
import { type ActionName, type AppAbility } from "~/services/permissions/app-ability";
import { type PermissionsSchema } from "~/services/permissions/permissions-schema";

export const applyDynamicPrismaRestrictions = (params: {
  ability: AppAbility;
  prismaRestrictionSchemas: Partial<PermissionsSchema>[];
  prismaAction: ActionName;
}) => {
  const baseReadRestrictions = accessibleBy(params.ability, "read");
  const baseActionRestrictions =
    params.prismaAction === "read" ? baseReadRestrictions : accessibleBy(params.ability, params.prismaAction);

  const mergeSchemas = (restrictions: WhereInputPerModel, prismaAction: ActionName) =>
    params.prismaRestrictionSchemas.reduce(
      (prismaRestrictions, schema) => mergePrismaRestrictions({ prismaRestrictions, prismaAction, schema }),
      restrictions
    );

  const readRestrictions = mergeSchemas(baseReadRestrictions, "read");
  const actionRestrictions =
    params.prismaAction === "read" ? readRestrictions : mergeSchemas(baseActionRestrictions, params.prismaAction);

  return {
    actionRestrictions,
    readRestrictions,
  };
};

/**
 * `prismaRestrictions` is originally a proxy that we can't manipulate, so we first convert it to a plain object.
 */
export const flattenPrismaRestrictions = (prismaRestrictions: WhereInputPerModel) => {
  return mapValues(Prisma.ModelName, (modelName) => {
    try {
      return prismaRestrictions[modelName];
    } catch {
      return false;
    }
  }) as WhereInputPerModel;
};

const mergePrismaRestrictions = (params: {
  prismaRestrictions: WhereInputPerModel;
  prismaAction: ActionName;
  schema: Partial<PermissionsSchema>;
}): WhereInputPerModel => {
  const flatPrismaRestrictions = flattenPrismaRestrictions(params.prismaRestrictions);

  return mapValues(flatPrismaRestrictions, (restrictions, modelName) => {
    const schemaRestrictions = params.schema[modelName as keyof PermissionsSchema]?.[params.prismaAction];

    if (!schemaRestrictions) return restrictions;

    return { OR: [{ AND: compact([restrictions, schemaRestrictions]) }] };
  }) as WhereInputPerModel;
};
