import { EmployeeStatus, ExternalEmployeeStatus } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type SkipExternalEmployeeMappingInput } from "~/pages/api/external-employee/skip-external-employee-mapping";
export const skipExternalEmployeeMapping = async (ctx: AppContext, input: SkipExternalEmployeeMappingInput) => {
  const user = getRequiredUser(ctx);

  const externalEmployee = await ctx.prisma.externalEmployee.findFirstOrThrow({
    where: { id: input.externalEmployeeId, companyId: user.companyId },
    select: { id: true, mappedEmployeeId: true, status: true },
  });

  if (externalEmployee.status === ExternalEmployeeStatus.SKIPPED) {
    return;
  }

  await ctx.prisma.externalEmployee.update({
    where: { id: externalEmployee.id },
    data: {
      status: ExternalEmployeeStatus.SKIPPED,
      mappingSkipReason: input.reason,
      mappedEmployee: { disconnect: true },
    },
  });

  if (externalEmployee.mappedEmployeeId !== null) {
    await ctx.prisma.employee.update({
      where: { id: externalEmployee.mappedEmployeeId },
      data: { status: EmployeeStatus.DELETED },
    });
  }
};

export type SkipExternalEmployeeMappingResult = AsyncReturnType<typeof skipExternalEmployeeMapping>;
