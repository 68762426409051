import { chain } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import { formatSalaryRangeName } from "~/services/salary-bands/helpers/format-salary-band-name";

export const fetchSalaryRangesForAutocomplete = async (ctx: AppContext, params: { salaryGridId: number }) => {
  const salaryRanges = await ctx.prisma.salaryRange.findMany({
    where: {
      band: { grid: whereSalaryGridIs({ salaryGridId: params.salaryGridId }) },
    },
    select: {
      id: true,
      level: { select: { id: true, name: true } },
      band: {
        select: {
          job: { select: { id: true, name: true } },
          location: { select: { id: true, name: true } },
        },
      },
    },
    orderBy: [
      { band: { job: { name: "asc" } } },
      { band: { location: { name: "asc" } } },
      { level: { position: "asc" } },
    ],
  });

  return chain(salaryRanges)
    .map((salaryRange) => ({ id: salaryRange.id, name: formatSalaryRangeName(salaryRange.band, salaryRange.level) }))
    .value();
};

export type FetchSalaryRangesForAutocompleteResult = AsyncReturnType<typeof fetchSalaryRangesForAutocomplete>;
