import { type Company, type Prisma } from "@prisma/client";
import { chain } from "lodash";
import { type AppContext } from "~/lib/context";
import { isNotNull } from "~/lib/utils";

export const CurrencyForDisplay = {
  select: {
    name: true,
    symbol: true,
    code: true,
    euroExchangeRate: true,
  },
} satisfies Prisma.CurrencyArgs;

export type CurrencyForDisplay = Prisma.CurrencyGetPayload<typeof CurrencyForDisplay>;

export const getDefaultCurrency = async (ctx: AppContext, params?: { company?: Pick<Company, "defaultCountryId"> }) => {
  const { user } = ctx;

  const company = params?.company ?? user?.company;

  if (!company) {
    return getEuroCurrency(ctx);
  }

  const defaultCountry = await ctx.prisma.country.findUniqueOrThrow({
    where: { id: company.defaultCountryId },
    include: { defaultCurrency: true },
  });

  if (!defaultCountry.defaultCurrency) {
    return getEuroCurrency(ctx);
  }

  return defaultCountry.defaultCurrency;
};

export const getEuroCurrency = async (ctx: AppContext) => {
  return ctx.prisma.currency.findUniqueOrThrow({ where: { code: "EUR" } });
};

export const getSuggestedCurrencies = async (ctx: AppContext, locationsIds?: number[]) => {
  const { user } = ctx;

  const whereClauses: Prisma.EmployeeLocationWhereInput[] = [];

  if (user) {
    whereClauses.push({ countryId: user.company.defaultCountryId });
  }

  if (locationsIds) {
    whereClauses.push({ id: { in: locationsIds } });
  } else {
    if (user) {
      whereClauses.push({ employees: { some: { companyId: user.companyId } } });
    }
  }

  const locations = await ctx.prisma.employeeLocation.findMany({
    where: whereClauses.length ? { OR: whereClauses } : {},
    include: {
      country: {
        include: { defaultCurrency: true },
      },
    },
  });

  return chain(locations)
    .map((location) => location.country.defaultCurrency)
    .filter(isNotNull)
    .uniqBy((currency) => currency.code)
    .value();
};
