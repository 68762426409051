import classNames from "classnames";
import React, { type TextareaHTMLAttributes } from "react";

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  dense?: boolean;
  grayed?: boolean;
};

export const TextareaInput: React.FC<Props> = (props) => {
  const { className, dense, grayed, disabled, ...rest } = props;

  return (
    <div className="relative w-full">
      <textarea
        className={classNames(className, {
          "w-full appearance-none rounded border border-gray-300 outline-none focus:ring": true,
          "py-2 px-3": !dense,
          "py-1 px-2 text-sm": dense,
          "hover:border-primary-400": !disabled,
          "text-gray-500": grayed || disabled,
          "text-gray-900": !grayed && !disabled,
        })}
        {...rest}
        value={rest.value ?? ""}
      />
    </div>
  );
};
