import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import {
  assertSafeCultureAmpIntegrationSettings,
  filterValidCultureAmpPerformanceReviewCycles,
  getCultureAmpPerformanceCycles,
} from "~/lib/performance-review/culture-amp";
import { type FetchAvailableCultureAmpPerformanceReviewCyclesInput } from "~/pages/api/performance-review/culture-amp/fetch-available-culture-amp-performance-review-cycles";
export const fetchAvailableCultureAmpPerformanceReviewCycles = async (
  ctx: AppContext,
  input: FetchAvailableCultureAmpPerformanceReviewCyclesInput
) => {
  const user = getRequiredUser(ctx);

  const performanceReviewIntegrationSettings = await ctx.prisma.performanceReviewIntegrationSettings.findUniqueOrThrow({
    where: {
      id: input.performanceReviewIntegrationSettingsId,
      companyId: user.isSuperAdmin ? input.companyId ?? user.companyId : user.companyId,
    },
  });

  const safeIntegrationSettings = assertSafeCultureAmpIntegrationSettings(performanceReviewIntegrationSettings);
  const performanceCycles = await getCultureAmpPerformanceCycles(safeIntegrationSettings);

  return filterValidCultureAmpPerformanceReviewCycles(performanceCycles);
};

export type AvailableCultureAmpPerformanceReviewCycles = AsyncReturnType<
  typeof fetchAvailableCultureAmpPerformanceReviewCycles
>;
