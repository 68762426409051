/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  CompensationFrequency,
  EmployeeMappingSkipReason,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
  IntegrationSource,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isFuture, parseISO } from "date-fns";
import { chain, compact, has } from "lodash";
import { type ParsedUrlQueryInput } from "querystring";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/build-custom-base-salary-remuneration-item";
import { companyHasAccessToBulkInviteUsers } from "~/lib/hris/helpers/company-has-access-to-bulk-invite-users";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/compute-additional-field-value-payloads";
import { getEmployeeProfilePicture } from "~/lib/hris/helpers/get-employee-profile-picture";
import { type IntegrationCustomFields, getMissingCustomFields } from "~/lib/hris/helpers/get-missing-custom-fields";
import { getNumberOfMonth } from "~/lib/hris/helpers/get-number-of-month";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/map-custom-remuneration-item";
import { type IntegrationConfig, type SourceConfig, type StaticModels } from "~/lib/integration";
import { logWarn } from "~/lib/logger";
import { buildExternalUrl } from "~/lib/url";
import { assertProps, isIn } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetch-company-integration-diagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/sync-external-employees";

const base: IntegrationConfig = {
  source: IntegrationSource.CHARLIEHR,
  companyId: 0,
  domain: null,
  clientId: config.charliehr.clientId,
  clientSecret: config.charliehr.clientSecret,
  anonymous: false,
  enabled: true,
  retryCount: 0,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  variableCustomFieldName: null,
  externalIdCustomFieldName: null,
  variableCustomFieldFrequency: null,
  holidayAllowanceCustomFieldName: null,
  holidayAllowanceCustomFieldFrequency: null,
  isCustomFieldOnlyHolidayAllowance: null,
  isHolidayAllowanceIncludedInBaseSalary: null,
  businessUnitCustomFieldName: null,
  locationCustomFieldName: null,
};

export const charliehrConfigs: SourceConfig = {
  "default": base,
  "anonymous": { ...base, anonymous: true },
  "custom fields": {
    ...base,
    anonymous: true,
    externalIdCustomFieldName: "team_member_note_type_EngxY8RkNAeqhZBkNXVlJ693",
    fteCustomFieldName: "team_member_note_type_vB7bq350GjyjSpK3wMgpZ9l2",
    levelCustomFieldName: "team_member_note_type_vB7bq350GjyjSpK3wMgpZ9lE",
    businessUnitCustomFieldName: "team_member_note_type_vB7bq350GjyjSpK3wMgpZ9gH",
  },
};

type Credentials = {
  clientId: string;
  clientSecret: string;
  anonymous: boolean;
  variableCustomFieldFrequency: string | null;
};

export type CharliehrEmployee = {
  id: string;
  profile_picture?: string;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  start_date: string | null;
  gender: "Female" | "Male";
  employment_status: "not started" | "ended" | "active";
  office: string;
  display_name?: string;
  work_email?: string;
  personal_email?: string;
  address?: string;
  phone_number?: string;
  job_title?: string;
  notes: {
    label: string;
    id: string;
    content: string;
    team_member_note_type: string;
  }[];
  manager?: string;
};

export type CharliehrSalary = {
  id: string;
  team_member: string;
  job_title: string;
  pay_rate: string;
  pay_currency: string;
  pay_frequency: "Year" | "Month" | string;
  employment_type?: "Permanent" | string;
  effective_date: string;
};

export type CharliehrOffice = {
  id: string;
  name: string;
  address: string[];
};

type CharliehrNoteType = {
  id: string;
  name: string;
  type?: "Text" | "Number" | "Checklist";
};

export type CompleteCharliehrEmployee = CharliehrEmployee & {
  location: CharliehrOffice | undefined;
  salary: CharliehrSalary | undefined;
  historicalSalaries: CharliehrSalary[];
};

type CharliehrReportResponse<T extends CharliehrEmployee | CharliehrOffice | CharliehrSalary | CharliehrNoteType> = {
  success: boolean;
  meta: { current_page: number; total_pages: number };
  data: T[];
};

export type CharliehrIntegrationSettingsInput = Credentials & IntegrationCustomFields;

const charliehrFetch = async <T extends CharliehrEmployee | CharliehrOffice | CharliehrSalary | CharliehrNoteType>(
  credentials: Credentials,
  endpoint: string,
  {
    method = "GET",
    query,
  }: {
    method?: "POST" | "GET";
    query?: ParsedUrlQueryInput;
  } = {}
): Promise<T[]> => {
  const baseUrl = `https://charliehr.com/api/v1/${endpoint}`;
  const token = `${credentials.clientId}:${credentials.clientSecret}`;

  const res = await fetch(buildExternalUrl(baseUrl, { ...query, format: "json" }), {
    method,
    headers: {
      "Accept": "application/json",
      "Authorization": `Token token=${token}`,
      "Content-Type": "application/json",
      "User-Agent": "Figures-Integration-Bot",
    },
  });

  if (!res.ok) {
    throw new Error(`[charliehr] ${res.status} ${res.statusText} : ${await res.text()}`);
  }

  const json: CharliehrReportResponse<T> = await res.json();

  if (json.meta.current_page === json.meta.total_pages) {
    return json.data;
  }

  const nextPage = await charliehrFetch<T>(credentials, endpoint, {
    query: {
      page: json.meta.current_page + 1,
    },
  });

  return [...json.data, ...nextPage];
};

const getCharliehrProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: CompleteCharliehrEmployee }
) => {
  if (!options.apiEmployee.profile_picture) {
    return undefined;
  }

  return getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id,
    source: IntegrationSource.CHARLIEHR,
    integrationSettings: options.integrationSettings,
    fetch: () =>
      fetch(options.apiEmployee.profile_picture as string, {
        headers: {
          "User-Agent": "Figures-Integration-Bot",
        },
      }),
  });
};

const getCustomField = (apiEmployee: CompleteCharliehrEmployee, fieldId: string | null) => {
  const note = apiEmployee.notes?.find((note) => note.team_member_note_type === fieldId);

  return note?.content;
};

export const mapCharliehrEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: CompleteCharliehrEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  // Handle custom fields
  const {
    fteCustomFieldName,
    levelCustomFieldName,
    externalIdCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    variableCustomFieldName,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    additionalFields = [],
    customRemunerationItems = [],
  } = integrationSettings;
  const customFte = getCustomField(apiEmployee, fteCustomFieldName);
  const customLevel = getCustomField(apiEmployee, levelCustomFieldName);
  const customEmployeeNumber = getCustomField(apiEmployee, externalIdCustomFieldName);
  const customBaseSalary = getCustomField(apiEmployee, baseSalaryCustomFieldName);
  const customVariable = getCustomField(apiEmployee, variableCustomFieldName);
  const holidayAllowanceValue = getCustomField(apiEmployee, holidayAllowanceCustomFieldName);
  const businessUnit = getCustomField(apiEmployee, businessUnitCustomFieldName);
  const customLocation = getCustomField(apiEmployee, locationCustomFieldName);
  const customJob = getCustomField(apiEmployee, jobCustomFieldName);

  const additionalFieldValues = additionalFields.map(({ hrisFieldName, id }) => ({
    additionalFieldId: id,
    value: getCustomField(apiEmployee, hrisFieldName) ?? null,
  }));

  const customRemunerationItemsValues = customRemunerationItems.map((customRemunerationItem) => ({
    ...customRemunerationItem,
    value: getCustomField(apiEmployee, customRemunerationItem.hrisFieldName),
  }));

  const countryCode = apiEmployee.location?.address?.slice(-1).pop();
  const country = value(() => {
    if (!countryCode) {
      return null;
    }
    return staticModels.countries.find((country) => country.alpha2 === countryCode);
  });

  const job = value(() => {
    const jobTitle = customJob ?? apiEmployee.salary?.job_title ?? apiEmployee.job_title;

    if (!jobTitle) return;

    return {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: jobTitle,
            },
          },
          create: {
            name: jobTitle,
            externalId: jobTitle,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    };
  });

  const location = value(() => {
    if (customLocation) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: customLocation,
              },
            },
            create: {
              externalId: customLocation,
              name: customLocation,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
              ...(country && { country: { connect: { id: country.id } } }),
            },
          },
        },
      };
    }

    if (apiEmployee.location) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: apiEmployee.location.id,
              },
            },
            create: {
              externalId: apiEmployee.location.id,
              name: apiEmployee.location.name,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
              ...(country && { country: { connect: { id: country.id } } }),
            },
          },
        },
      };
    }

    return null;
  });

  const currency = staticModels.currencies.find((currency) => currency.code === apiEmployee.salary?.pay_currency);

  // If the employee is not a permanent, we create them with a SKIPPED status
  const isAutoSkipped = has(apiEmployee, "salary") && apiEmployee.salary?.employment_type === "Freelancer";

  const hasAccessToEmails = await companyHasAccessToBulkInviteUsers(ctx, company.id);

  const input: EmployeeData["input"] = {
    source: IntegrationSource.CHARLIEHR,
    externalId: apiEmployee.id,
    status: isAutoSkipped ? ExternalEmployeeStatus.SKIPPED : ExternalEmployeeStatus.UNMAPPED,
    mappingSkipReason: isAutoSkipped ? EmployeeMappingSkipReason.NOT_PERMANENT_EMPLOYEE : null,
    firstName: apiEmployee.first_name,
    lastName: apiEmployee.last_name,
    email: hasAccessToEmails && apiEmployee.work_email ? apiEmployee.work_email : null,
    employeeNumber: customEmployeeNumber ? customEmployeeNumber : apiEmployee.id,
    gender: value(() => {
      if (!apiEmployee.gender) {
        return null;
      }
      if (apiEmployee.gender === "Female") {
        return Gender.FEMALE;
      }
      if (apiEmployee.gender === "Male") {
        return Gender.MALE;
      }
      return Gender.UNDISCLOSED;
    }),
    hireDate: apiEmployee.start_date ? parseISO(apiEmployee.start_date) : null,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency?.code ?? "EUR" },
    },
    ...location,
    ...job,
    ...(customLevel && {
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: customLevel,
            },
          },
          create: {
            externalId: customLevel,
            name: customLevel,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(businessUnit && { businessUnit }),
  };

  const numberMonths = getNumberOfMonth({
    externalId: location?.location?.connectOrCreate?.create?.externalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
    baseSalaryCustomFieldFrequency,
    company,
    customBaseSalary,
    numberMonths,
    source: ExternalEmployeeSource.CHARLIEHR,
  });

  const remunerationItems: EmployeeData["remunerationItems"] = customBaseSalaryRemunerationItem
    ? [customBaseSalaryRemunerationItem]
    : compact([
        apiEmployee.salary &&
          mapCharlieHrBaseSalary(ctx, {
            company,
            salary: apiEmployee.salary,
            externalRemunerationStatus: ExternalRemunerationStatus.LIVE,
            numberMonths,
          }),
        ...apiEmployee.historicalSalaries.map((salary) =>
          mapCharlieHrBaseSalary(ctx, {
            company,
            salary,
            externalRemunerationStatus: ExternalRemunerationStatus.HISTORICAL,
            numberMonths,
          })
        ),
      ]);

  if (customVariable && integrationSettings.variableCustomFieldFrequency) {
    const multiplier = match(integrationSettings.variableCustomFieldFrequency)
      .with(CompensationFrequency.MONTHLY, () => 12)
      .with(CompensationFrequency.QUARTERLY, () => 4)
      .with(CompensationFrequency.YEARLY, () => 1)
      .exhaustive();

    const amount = Math.round(parseInt(customVariable) * multiplier * 100);

    remunerationItems.push({
      source: IntegrationSource.CHARLIEHR,
      externalId: "variable-bonus",
      status: ExternalRemunerationStatus.LIVE,
      amount: amount,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: IntegrationSource.CHARLIEHR,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: IntegrationSource.CHARLIEHR,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    });
  }

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  return {
    input,
    managerExternalId: apiEmployee.manager,
    picturePath: !ignoreProfilePicture
      ? await getCharliehrProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    holidayAllowanceValue,
    // If one day they support hourly rates, we just have to update this (cf. bamboo)
    ...(customFte && { fte: customFte, ignoreFte: false }),
  };
};

const mapCharlieHrBaseSalary = (
  ctx: AppContext,
  params: {
    company: Company;
    salary?: CharliehrSalary;
    externalRemunerationStatus: ExternalRemunerationStatus;
    numberMonths: number;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, salary, externalRemunerationStatus, numberMonths } = params;

  if (!salary?.pay_rate) {
    return null;
  }

  const amount = parseInt(salary.pay_rate);

  if (amount === 0) {
    return null;
  }

  const interval = salary.pay_frequency;

  if (!["Month", "Year"].includes(interval)) {
    logWarn(ctx, `[sync] Unhandled CharlieHR fix salary interval`, { interval });
  }

  const multiplier = interval === "Month" ? numberMonths : 1;

  const yearlySalary = Math.round(amount * multiplier) * 100;

  const externalIdSuffix = match(externalRemunerationStatus)
    .with(ExternalRemunerationStatus.LIVE, () => "")
    .with(ExternalRemunerationStatus.HISTORICAL, () => `-historical-${salary.id}`)
    .exhaustive();

  return {
    source: IntegrationSource.CHARLIEHR,
    externalId: `fix-salary${externalIdSuffix}`,
    amount: yearlySalary,
    numberMonths,
    status: externalRemunerationStatus,
    date: salary?.effective_date ? parseISO(salary?.effective_date) : null,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: IntegrationSource.CHARLIEHR,
            externalId: "fix-salary",
          },
        },
        create: {
          source: IntegrationSource.CHARLIEHR,
          externalId: "fix-salary",
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  };
};

export const getCharliehrCustomFields = async (credentials: Credentials): Promise<CharliehrNoteType[]> => {
  const customFields = await charliehrFetch<CharliehrNoteType>(credentials, "team_member_note_types");

  return customFields
    .filter((field) => isIn(field.type, ["Text", "Number"]))
    .map((field) => ({ id: field.id, name: field.name }));
};

const anonymise = (credentials: Credentials) => {
  return (employee: CharliehrEmployee): CharliehrEmployee => {
    if (credentials.anonymous) {
      delete employee.first_name;
      delete employee.last_name;
      delete employee.date_of_birth;
      delete employee.profile_picture;
      delete employee.work_email;
      delete employee.personal_email;
      delete employee.address;
      delete employee.phone_number;
      delete employee.display_name;
    }
    return employee;
  };
};

export const getCharliehrEmployees = async (credentials: Credentials) => {
  // Get all relevant data from CharlieHR API
  const [employees, offices, salaries] = await Promise.all([
    charliehrFetch<CharliehrEmployee>(credentials, "team_members"),
    charliehrFetch<CharliehrOffice>(credentials, "offices"),
    charliehrFetch<CharliehrSalary>(credentials, "salaries"),
  ]);

  return employees
    .filter((employee) => employee.employment_status === "active")
    .map(anonymise(credentials))
    .map((employee) => {
      const location = offices.find((office) => office.id === employee.office);

      const employeeSalaries = salaries.filter((salary) => salary.team_member === employee.id);

      // Employees with no salaries are always taken into account
      if (employeeSalaries.length === 0) {
        return { ...employee, location, salary: undefined, historicalSalaries: [] };
      }

      const [salary, ...historicalSalaries] = chain(employeeSalaries)
        .filter((salary) => !isFuture(parseISO(salary.effective_date)))
        .orderBy("effective_date", "desc")
        .value();

      return {
        ...employee,
        location,
        salary,
        historicalSalaries,
      };
    });
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientId", "clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedCharliehrEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const charliehrEmployees = await getCharliehrEmployees(safeIntegrationSettings);

  return mapSeries(charliehrEmployees, (charliehrEmployee) =>
    mapCharliehrEmployee(ctx, company, charliehrEmployee, safeIntegrationSettings, staticModels, ignoreProfilePicture)
  );
};

export const getCharliehrDiagnostic = async (
  ctx: AppContext,
  input: CharliehrIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const [employee] = await charliehrFetch<CharliehrEmployee>(input, "team_members");

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your CharlieHR account",
        missingFields: [],
        availableFields: [],
      };
    }

    const availableFields = await getCharliehrCustomFields(input);

    const missingFields = getMissingCustomFields(input, availableFields);

    return {
      connection: true,
      connectionError: "",
      missingFields,
      availableFields,
    };
  } catch (error) {
    return {
      connection: false,
      connectionError: error.message,
      missingFields: [],
      availableFields: [],
    };
  }
};

export const getRawCharliehrEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<CompleteCharliehrEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getCharliehrEmployees(safeIntegrationSettings);
};
