import { UserRole } from "@prisma/client";
import { uniq } from "lodash";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type AuthenticatedUser } from "~/lib/session";
import { type UserExternalEmployeeManageeIds } from "~/services/external-employee/fetch-external-employee-managee-ids";
import { type PermissionsSchema, type UserPermissionsSchemaFilters } from "~/services/permissions/permissions-schema";
export const generateEmployeePermissionsSchema = (ctx: AppContext, filters: UserPermissionsSchemaFilters) => {
  const {
    isAdmin,
    isHr,
    inSameBusinessUnit,
    ownedByCompany,
    allowedJobIds,
    allowedJobFamilyIds,
    allowedLocationIds,
    allowedCountryIds,
    allowedLevels,
    externalEmployeeManageeIds,
  } = filters;

  const allowedExternalEmployeeIds = getAllowedExternalEmployeeIds(getRequiredUser(ctx), externalEmployeeManageeIds);

  const employeeIsAccessible = {
    ...ownedByCompany,
    ...(allowedExternalEmployeeIds && { externalEmployee: { id: { in: allowedExternalEmployeeIds } } }),
    ...(allowedJobIds?.length && { jobId: { in: allowedJobIds } }),
    ...(allowedJobFamilyIds?.length && { job: { familyId: { in: allowedJobFamilyIds } } }),
    ...(allowedLocationIds?.length && { locationId: { in: allowedLocationIds } }),
    ...(allowedCountryIds?.length && { location: { countryId: { in: allowedCountryIds } } }),
    ...(allowedLevels?.length && { level: { in: allowedLevels } }),
    ...(inSameBusinessUnit && { externalEmployee: inSameBusinessUnit }),
  };

  const externalEmployeeIsAccessible = {
    ...ownedByCompany,
    ...inSameBusinessUnit,
    ...(allowedExternalEmployeeIds && { id: { in: allowedExternalEmployeeIds } }),
  };

  return {
    Employee: {
      read: employeeIsAccessible,
      update: (isAdmin || isHr) && employeeIsAccessible,
      delete: (isAdmin || isHr) && employeeIsAccessible,
    },

    //should we limit to superAdmin?
    DatasetEmployee: {
      read: employeeIsAccessible,
      update: (isAdmin || isHr) && employeeIsAccessible,
      delete: (isAdmin || isHr) && employeeIsAccessible,
    },

    ExternalEmployee: {
      read: externalEmployeeIsAccessible,
      update: (isAdmin || isHr) && externalEmployeeIsAccessible,
      delete: (isAdmin || isHr) && externalEmployeeIsAccessible,
    },
  } satisfies Partial<PermissionsSchema>;
};

const getAllowedExternalEmployeeIds = (
  user: AuthenticatedUser,
  externalEmployeeManageeIds: UserExternalEmployeeManageeIds
) => {
  if (user.permissions.role === UserRole.ADMIN || user.permissions.role === UserRole.HR) {
    return null;
  }

  // Destructuring to prevent data mutation with `push`
  const allowedExternalEmployeeIds = [...(externalEmployeeManageeIds ?? [])];

  if (user.permissions.role === UserRole.EMPLOYEE && user.permissions.externalEmployeeId) {
    allowedExternalEmployeeIds.push(user.permissions.externalEmployeeId);
  }

  return uniq(allowedExternalEmployeeIds);
};
