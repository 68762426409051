import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { classifyLocation } from "~/services/locations/location-classification";

export const suggestMappingForExternalLocations = async (ctx: AppContext, params: { companyId: number }) => {
  logInfo(ctx, "[mapping] Suggesting mappings for external locations", { companyId: params.companyId });

  const externalLocations = await ctx.prisma.externalLocation.findMany({
    select: { id: true, name: true, suggestedLocationId: true, suggestedAt: true },
    where: {
      companyId: params.companyId,
      mappedLocationId: null,
      suggestionSkippedAt: null,
      suggestedLocationId: null,
    },
  });

  await mapSeries(externalLocations, async (externalLocation) => {
    const suggestion = await classifyLocation(ctx, externalLocation.name);

    if (suggestion?.location) {
      return ctx.prisma.externalLocation.update({
        where: { id: externalLocation.id },
        data: {
          suggestedLocationId: suggestion.location.id,
          suggestionConfidenceScore: 1,
          suggestedAt: new Date(),
        },
      });
    }

    if (externalLocation.suggestedLocationId) {
      return ctx.prisma.externalLocation.update({
        where: { id: externalLocation.id },
        data: { suggestedLocationId: null, suggestionConfidenceScore: null, suggestedAt: null },
      });
    }
  });
};
