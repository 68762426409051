import { omit } from "lodash";
import { DangerouslyIncludeSoftDeletedExternalEmployeesToken } from "~/lib/prisma-restrictions/schemas/generate-external-employees-soft-delete-schema";
import { type PrismaPayload } from "~/lib/prisma-types";

export const ApplyPrismaRestrictionsToken = Symbol("applyPrismaRestrictions") as unknown as string;
export const applyPrismaRestrictions = (): Record<string, boolean> => {
  return { [ApplyPrismaRestrictionsToken]: true };
};

export const DangerouslyIgnorePrismaRestrictionsToken = Symbol(
  "dangerouslyIgnorePrismaRestrictions"
) as unknown as string;
export const dangerouslyIgnorePrismaRestrictions = (): Record<string, boolean> => {
  return { [DangerouslyIgnorePrismaRestrictionsToken]: true };
};

export const DebugQueryToken = Symbol("debugQueryToken") as unknown as string;
export const debugQuery = (): Record<string, boolean> => {
  return { [DebugQueryToken]: true };
};

export const removeTokensFromPayload = (args: PrismaPayload) => {
  return omit(args, [
    ApplyPrismaRestrictionsToken,
    DangerouslyIgnorePrismaRestrictionsToken,
    DangerouslyIncludeSoftDeletedExternalEmployeesToken,
    DebugQueryToken,
  ]);
};
