import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { type CustomJob, UserRole } from "@prisma/client";
import { noop, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Button } from "~/components/ui/core/button";
import { ConfirmationModal } from "~/components/ui/core/ConfirmationModal";
import { LegacyFormControl } from "~/components/ui/core/LegacyFormControl";
import { LegacyModal } from "~/components/ui/core/LegacyModal";
import { LegacyText } from "~/components/ui/core/LegacyText";
import { TextInput } from "~/components/ui/core/TextInput";
import { JobSelector } from "~/components/ui/JobSelector";
import { useApi } from "~/hooks/useApi";
import { useForm } from "~/hooks/useForm";
import { useListJobsQuery } from "~/hooks/useQueries";
import { useSession } from "~/hooks/useSession";
import { type UpdateCustomJobResponse, UpdateCustomJobSchema } from "~/pages/api/update-custom-job";
import { CUSTOM_JOB_PREFIX } from "~/services/custom-jobs/custom-job-prefix";
import { type WeightedJob } from "~/services/job";

type Props = {
  isOpen: boolean;
  onClose: (editedCustomJob?: CustomJob) => void;
  customJobId: string;
};

type FormValues = {
  id: number | null;
  name: string | null;
  jobs: WeightedJob[];
  sharedWithCompany: boolean;
};

export const CustomJobEditionModal: React.FC<Props> = ({ isOpen, onClose, customJobId }) => {
  const { apiFetch } = useApi();
  const { user } = useSession();
  const [isDeletingCustomJob, setIsDeletingCustomJob] = useState(false);
  const [showCustomJobDeletionModal, setShowCustomJobDeletionModal] = useState(false);
  const isUserRecruiter = user?.permissions.role === UserRole.RECRUITER;

  const { data: jobsData } = useListJobsQuery({});
  const customJob = jobsData?.customJobs.find((job) => `${job.id}` === customJobId.slice(CUSTOM_JOB_PREFIX.length));

  const form = useForm<FormValues>({
    initialValues: {
      id: null,
      name: null,
      jobs: [],
      sharedWithCompany: false,
    },
    validationSchema: UpdateCustomJobSchema,
    onSubmit: async (values) => {
      const res = await apiFetch<UpdateCustomJobResponse>("/api/update-custom-job", {
        body: values,
        successMessage: `The ${values.name} job has been successfully updated`,
      });

      handleClose(res.customJob);
    },
  });

  useEffect(() => {
    if (!isOpen || !customJob) return;

    form.updateValues({
      id: customJob.id,
      name: customJob.name,
      jobs: customJob.items,
      sharedWithCompany: customJob.sharedWithCompany,
    });
  }, [customJob, isOpen]);

  const handleClose = (editedCustomJob?: CustomJob) => {
    onClose(editedCustomJob);

    form.resetForm();
  };

  const onWeightInputBlur = (changedJobIndex: number) => {
    if (form.values.jobs.length !== 2) {
      return;
    }

    const newJobs = [...form.values.jobs];
    const changedJob = newJobs[changedJobIndex] as WeightedJob;

    newJobs.forEach((item, index) => {
      if (index !== changedJobIndex) {
        item.weight = 100 - changedJob.weight;
      }
    });

    void form.setFieldValue("jobs", newJobs);
  };

  const weightsSum = sumBy(form.values.jobs, (item) => {
    return item.weight;
  });

  const onCustomJobDeletionConfirmation = async () => {
    if (!customJob) return;

    await apiFetch("/api/delete-custom-job", {
      body: { id: customJob.id },
      setIsLoading: setIsDeletingCustomJob,
      successMessage: `The ${customJob.name} job has been successfully deleted`,
    });

    handleClose();
  };

  return (
    <LegacyModal
      isOpen={isOpen}
      title="Edit custom job"
      onClose={() => {
        handleClose();
      }}
      className="w-[560px]"
    >
      <ConfirmationModal
        confirmLabel="Confirm"
        isLoading={isDeletingCustomJob}
        description={
          <Stack gap={3}>
            <Typography variant="h3">You're about to delete the custom job "{customJob?.name}".</Typography>

            <Stack>
              <Typography>This action cannot be undone.</Typography>
            </Stack>
          </Stack>
        }
        isOpen={showCustomJobDeletionModal}
        title="Confirm Custom Job Deletion"
        onClose={() => setShowCustomJobDeletionModal(false)}
        onConfirm={onCustomJobDeletionConfirmation}
      />

      <div className="mt-3 flex flex-col items-start">
        <form className="mt-4 w-full" onSubmit={form.handleSubmit}>
          <div className="flex">
            <LegacyFormControl className="flex-1" label="Figures Jobs">
              <Stack spacing={2}>
                {form.values.jobs.map((job, index) => (
                  <JobSelector key={index} className="w-full" jobIds={[`${job.job.id}`]} disabled onChange={noop} />
                ))}
              </Stack>
            </LegacyFormControl>

            {form.values.jobs.length > 1 && (
              <LegacyFormControl
                requiredHint
                className="ml-4"
                label="Weights"
                tooltip={
                  <div className="flex w-96 flex-col p-2">
                    <span>
                      Use weights to grant more importance to a specific job's market data into your combination.
                    </span>

                    <span className="mt-2">
                      For example, if your employee is spending 70% of their time doing Product Design and 30% Product
                      Management, you might want the weights to reflect this reality.
                    </span>
                  </div>
                }
              >
                <div className="flex flex-col space-y-1">
                  {form.values.jobs.map((item, index) => {
                    return (
                      <div key={item.job.id} className="flex items-center">
                        <TextInput
                          required
                          affix="%"
                          className="text-right"
                          max={100}
                          min={1}
                          type="number"
                          value={item.weight}
                          onBlur={() => {
                            onWeightInputBlur(index);
                          }}
                          onChange={(event) => {
                            const jobs = [...form.values.jobs];
                            const jobRow = jobs[index];

                            if (jobRow) {
                              jobRow.weight = parseInt(event.target.value);
                              void form.setFieldValue("jobs", jobs);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </LegacyFormControl>
            )}
          </div>

          <LegacyFormControl requiredHint className="mt-8" label="Job name">
            <div className="flex">
              <TextInput
                required
                value={form.values.name ?? ""}
                onChange={(event) => {
                  void form.setFieldValue("name", event.target.value);
                }}
              />
            </div>
          </LegacyFormControl>

          {!isUserRecruiter && (
            <LegacyFormControl className="mt-8" label="Sharing">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={form.values.sharedWithCompany}
                    onChange={(event) => {
                      void form.setFieldValue("sharedWithCompany", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography>Share this job with members of {user?.company.name ?? "my organisation"}</Typography>
                }
              />
            </LegacyFormControl>
          )}

          <div className="mt-8 flex items-center justify-end border-t pt-3">
            {form.values.jobs.length > 0 && weightsSum !== 100 && (
              <LegacyText className="mr-4" icon="warning">
                The provided weights sum doesn't add up to 100%.
              </LegacyText>
            )}

            <Button
              variant="outlined"
              disabled={isDeletingCustomJob}
              color="error"
              className="mr-2"
              onClick={() => setShowCustomJobDeletionModal(true)}
            >
              Delete custom job
            </Button>

            <Button variant="contained" disabled={!form.isValid || form.isSubmitting} type="submit">
              Update custom job
            </Button>
          </div>
        </form>
      </div>
    </LegacyModal>
  );
};
