import {
  CompanyMarketPositioningType,
  FundingRound,
  SalaryBandLevelTrack,
  SalaryGridMeasure,
  SalaryGridTiersMode,
} from "@prisma/client";
import { array, mixed, number, object, string } from "yup";
import { type YupOutputType } from "~/lib/utils";

export const BaseConfigurationChangeSchema = object({
  gridId: number().required(),
  userId: number().required(),
  actionId: string().required(),
  reason: string().nullable(),
});

export const JobStateSchema = object({
  id: number().required(),
  name: string().required(),
});

export type JobState = YupOutputType<typeof JobStateSchema>;

export const LocationStateSchema = object({
  id: number().required(),
  name: string().required(),
});

export type LocationState = YupOutputType<typeof LocationStateSchema>;

export const LevelStateSchema = object({
  id: number().required(),
  name: string().required(),
  track: mixed<SalaryBandLevelTrack>().oneOf(Object.values(SalaryBandLevelTrack)).required(),
});

export type LevelState = YupOutputType<typeof LevelStateSchema>;

export const SalaryBandBenchmarkedJobsSchema = array()
  .of(object({ job: object({ id: number().required(), name: string().required() }) }).required())
  .required();

export type SalaryBandBenchmarkedJobs = YupOutputType<typeof SalaryBandBenchmarkedJobsSchema>;

export const SalaryBandBenchmarkedLocationsSchema = array()
  .of(
    object({
      location: object({
        id: number().required(),
        name: string().required(),
        country: object({ id: number().required(), name: string().required() }),
      }),
    }).required()
  )
  .required();

export type SalaryBandBenchmarkedLocations = YupOutputType<typeof SalaryBandBenchmarkedLocationsSchema>;

export const SalaryBandStateSchema = object({
  id: number().required(),
  job: object({
    id: number().required(),
    name: string().required(),
  }).required(),
  location: object({ id: number().required(), name: string().required() }).required(),
  benchmarkedJobs: array().of(
    object({ job: object({ id: number().required(), name: string().required() }) }).required()
  ),
  benchmarkedLocations: array().of(
    object({
      location: object({
        id: number().required(),
        name: string().required(),
      }),
    }).required()
  ),
  measure: mixed<SalaryGridMeasure>().oneOf(Object.values(SalaryGridMeasure)).required(),
  ranges: array()
    .of(object({ id: number().required(), midpoint: number().required() }))
    .required(),
  grid: object({ width: number().required() }),
});

export type SalaryBandState = YupOutputType<typeof SalaryBandStateSchema>;

export const SalaryBandBenchmarkedJobsStateSchema = object({
  id: number().required(),
  job: object({
    id: number().required(),
    name: string().required(),
  }).required(),
  location: object({ id: number().required(), name: string().required() }).required(),
  benchmarkedJobs: SalaryBandBenchmarkedJobsSchema,
});

export type SalaryBandBenchmarkedJobsState = YupOutputType<typeof SalaryBandBenchmarkedJobsStateSchema>;
export const SalaryBandBenchmarkedLocationsStateSchema = object({
  id: number().required(),
  job: object({
    id: number().required(),
    name: string().required(),
  }).required(),
  location: object({ id: number().required(), name: string().required() }).required(),
  benchmarkedLocations: SalaryBandBenchmarkedLocationsSchema,
});

export type SalaryBandBenchmarkedLocationsState = YupOutputType<typeof SalaryBandBenchmarkedLocationsStateSchema>;

export const SalaryRangeStateSchema = object({
  id: number().required(),
  min: number().required(),
  midpoint: number().required(),
  max: number().required(),
  targetPercentileRank: number().min(0).nullable(),
  inferredPercentileRank: number().min(0).nullable(),
  band: object({
    id: number().required(),
    job: object({ id: number().required(), name: string().required() }).required(),
    location: object({ id: number().required(), name: string().required() }).required(),
  }),
  level: object({ id: number().required(), name: string().required() }).required(),
});

export type SalaryRangeState = YupOutputType<typeof SalaryRangeStateSchema>;

export const SalaryBandsWidthStateSchema = object({ id: number().required(), width: number().required() });

export type SalaryBandsWidthState = YupOutputType<typeof SalaryBandsWidthStateSchema>;

export const MarketPositioningSchema = object({
  id: number().required(),
  type: mixed<CompanyMarketPositioningType>().oneOf(Object.values(CompanyMarketPositioningType)).required(),
  industry: object({ id: number().required(), name: string().required() }).nullable(),
  min: number().nullable().defined(),
  max: number().nullable().defined(),
  fundingRounds: array(mixed<FundingRound>().oneOf(Object.values(FundingRound)).required()).nullable(),
}).nullable();

export const SalaryBandMarketPositioningStateSchema = object({
  id: number().required(),
  marketPositioning: MarketPositioningSchema,
});

export type SalaryBandMarketPositioningState = YupOutputType<typeof SalaryBandMarketPositioningStateSchema>;

export const SalaryGridStateSchema = object({
  id: number().required(),
  name: string().required(),
});

export type SalaryGridState = YupOutputType<typeof SalaryGridStateSchema>;

export const SalaryGridTiersStateSchema = object({
  tiersMode: mixed<SalaryGridTiersMode>().oneOf(Object.values(SalaryGridTiersMode)).required(),
  tiersNames: array().of(string().required()).required(),
});

export type SalaryGridTiersState = YupOutputType<typeof SalaryGridTiersStateSchema>;
