import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import {
  type ImpersonationAccessRequest,
  findImpersonationAccessRequest,
} from "~/services/impersonation/find-impersonation-access-request";

const RequestImpersonationAccessSchema = object({
  companyId: number().required(),
});

const handler: NextApiHandler<ImpersonationAccessRequest> = async (req, res) => {
  const { companyId } = RequestImpersonationAccessSchema.validateSync(req.body, {
    abortEarly: false,
  });

  const impersonationAccessRequest = await findImpersonationAccessRequest(req, { companyId });

  res.status(HttpStatus.OK).json(impersonationAccessRequest);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSettings: true,
  },
});

export const FETCH_IMPERSONATION_ACCESS_REQUEST_QUERY_KEY: ApiRoute["pathname"] =
  "/api/auth/company-impersonation/find-impersonation-access-request";

export const useFetchImpersonationAccessRequestQuery = createQuery<
  typeof handler,
  typeof RequestImpersonationAccessSchema
>({
  path: FETCH_IMPERSONATION_ACCESS_REQUEST_QUERY_KEY,
  schema: RequestImpersonationAccessSchema,
});
