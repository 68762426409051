import { isNil } from "lodash";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
export const validateRequestAuthorised = (ctx: AppContext, params: { companyId: number | null | undefined }) => {
  const { companyId } = params;

  if (isNil(companyId) || isNaN(companyId)) {
    return;
  }

  const user = getRequiredUser(ctx);
  const requestAuthorised = user.isSuperAdmin || companyId === user.companyId;

  if (!requestAuthorised) {
    throw Error(`Unauthorized action for user ${user.id} on company ${companyId}`);
  }
};
