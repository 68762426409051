import { type Prisma, UserProvider } from "@prisma/client";
import { route } from "nextjs-routes";
import { type ProfileData } from "~/services/auth/profile-data";
import { makeSamlRedirectionUrl } from "~/services/auth/saml/saml-urls";

export class SamlAuthenticationEnforcedError extends Error {
  samlAuthenticationUrl: string;

  constructor(email: string, redirect?: string) {
    super("SAML authentication enforced");
    Object.setPrototypeOf(this, SamlAuthenticationEnforcedError.prototype);

    this.samlAuthenticationUrl = route(makeSamlRedirectionUrl({ email, redirect }));
  }
}

export const selectCompanyForSamlAuthentication = {
  samlConfiguration: {
    select: {
      enforceSamlAuthentication: true,
    },
  },
} satisfies Prisma.CompanySelect;

export const enforceSamlAuthentication = (params: {
  company: Prisma.CompanyGetPayload<{ select: typeof selectCompanyForSamlAuthentication }>;
  profileData: Pick<ProfileData, "provider" | "email">;
}) => {
  if (params.profileData.provider === UserProvider.SAML) return;

  if (!params.company.samlConfiguration?.enforceSamlAuthentication) return;

  throw new SamlAuthenticationEnforcedError(params.profileData.email);
};
