import { type AdditionalField, type IntegrationSettings } from "@prisma/client";
import { isNotNull } from "~/lib/utils";

export type IntegrationCustomFields = Pick<
  IntegrationSettings,
  | "holidayAllowanceCustomFieldName"
  | "fteCustomFieldName"
  | "levelCustomFieldName"
  | "baseSalaryCustomFieldName"
  | "variableCustomFieldName"
  | "externalIdCustomFieldName"
  | "businessUnitCustomFieldName"
  | "locationCustomFieldName"
  | "jobCustomFieldName"
> & {
  additionalFields?: Pick<AdditionalField, "hrisFieldName" | "id">[];
  customRemunerationItems?: Pick<AdditionalField, "hrisFieldName" | "id">[];
};

export const getMissingCustomFields = (
  integrationSettings: IntegrationCustomFields,
  availableFields: {
    id: string;
    name: string;
  }[]
) => {
  return [
    integrationSettings.externalIdCustomFieldName,
    integrationSettings.levelCustomFieldName,
    integrationSettings.baseSalaryCustomFieldName,
    integrationSettings.variableCustomFieldName,
    integrationSettings.fteCustomFieldName,
    integrationSettings.holidayAllowanceCustomFieldName,
    integrationSettings.businessUnitCustomFieldName,
    integrationSettings.locationCustomFieldName,
    integrationSettings.jobCustomFieldName,
    ...(integrationSettings.additionalFields?.map(({ hrisFieldName }) => hrisFieldName) ?? []),
    ...(integrationSettings.customRemunerationItems?.map(({ hrisFieldName }) => hrisFieldName) ?? []),
  ]
    .filter(isNotNull)
    .filter((customFieldId) => !availableFields.find((field) => customFieldId === field.id.toString()));
};
