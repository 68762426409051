import { type AppContext } from "~/lib/context";
import { getAllowedJobFamilyIds } from "~/services/job";
import { whereCustomJobIsOwned } from "~/services/permissions/own-company-permissions";

export const fetchCustomJobs = async (ctx: AppContext) => {
  const { user } = ctx;

  if (!user) {
    return [];
  }

  const allowedJobFamilyIds = getAllowedJobFamilyIds(user);
  return ctx.prisma.customJob.findMany({
    where: {
      ...whereCustomJobIsOwned(user),
      ...(allowedJobFamilyIds?.length && { items: { every: { job: { familyId: { in: allowedJobFamilyIds } } } } }),
    },
    include: {
      user: true,
      items: {
        include: { job: true },
      },
    },
  });
};
