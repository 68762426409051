import type React from "react";
import { Mode, useEditMode } from "~/components/external-employee/use-edit-mode";
import { useExternalEmployeeSource } from "~/components/external-employee/use-external-employee-source";

type Props = {
  nonEditableChildren: JSX.Element | null;
  editableChildren?: JSX.Element;
};

export const SourceDependentEditableField: React.FC<Props> = ({ editableChildren, nonEditableChildren }) => {
  const source = useExternalEmployeeSource();
  const { mode } = useEditMode();

  if (mode === Mode.DISPLAY || source.isHRIS || !editableChildren) {
    return nonEditableChildren;
  }

  return editableChildren;
};
