import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  type FetchPerformanceReviewRatings,
  fetchPerformanceReviewRatings,
} from "~/services/performance-review/find-performance-review-ratings";

export const FetchPerformanceReviewRatingsSchema = object({
  companyId: number().required(),
});

export type FetchPerformanceReviewRatingsInput = YupOutputType<typeof FetchPerformanceReviewRatingsSchema>;

const handler: NextApiHandler<FetchPerformanceReviewRatings> = async (req, res) => {
  const input = await FetchPerformanceReviewRatingsSchema.validate(req.body, { abortEarly: false });

  const ratings = await fetchPerformanceReviewRatings(req, input);

  return res.status(HttpStatus.OK).json(ratings);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessImportedEmployees: true,
  },
});

export const FETCH_PERFORMANCE_REVIEW_RATINGS_QUERY_KEY: ApiRoute["pathname"] =
  "/api/performance-review/fetch-performance-review-ratings";

export const useFetchPerformanceReviewRatingsQuery = createQuery<
  typeof handler,
  typeof FetchPerformanceReviewRatingsSchema
>({
  path: FETCH_PERFORMANCE_REVIEW_RATINGS_QUERY_KEY,
  schema: FetchPerformanceReviewRatingsSchema,
});
