import { type Chip } from "@mui/material";
import { ExternalEmployeeStatus } from "@prisma/client";
import { type TFunction } from "i18next";
import { type ComponentProps } from "react";
import { match } from "ts-pattern";

export const formatExternalEmployeeStatus = (t: TFunction, status: ExternalEmployeeStatus) => {
  return match<ExternalEmployeeStatus, { label: string; color?: ComponentProps<typeof Chip>["color"] }>(status)
    .with(ExternalEmployeeStatus.SKIPPED, () => ({
      label: t("enum.external-employee-status.skipped"),
    }))
    .with(ExternalEmployeeStatus.UNMAPPED, () => ({
      label: t("enum.external-employee-status.unmapped"),
      color: "secondary-alt",
    }))
    .with(ExternalEmployeeStatus.NEEDS_REMAPPING, () => ({
      label: t("enum.external-employee-status.needs-remapping"),
      color: "secondary-alt",
    }))
    .with(ExternalEmployeeStatus.MAPPED, () => ({
      label: t("enum.external-employee-status.mapped"),
      color: "primary-alt",
    }))
    .with(ExternalEmployeeStatus.PARTIAL, () => ({
      label: t("enum.external-employee-status.partial"),
      color: "primary-alt",
    }))
    .exhaustive();
};
