import { Typography } from "@mui/material";
import { type Prisma } from "@prisma/client";
import React from "react";
import { EmployeePicture, type PictureForDisplay } from "~/components/employee/employee-picture";
import { HtmlTooltip } from "~/components/ui/core/HtmlTooltip";
import {
  ExternalEmployeeCard,
  type ExternalEmployeeForCard,
  externalEmployeeSelectForCard,
} from "~/components/ui/external-employee-card";
import { useI18n } from "~/lib/i18n/use-i18n";

type ExternalEmployeeForBadgeList = ExternalEmployeeForCard & { picture: PictureForDisplay | null };

type Props = {
  firstFiveEmployees: ExternalEmployeeForBadgeList[];
  count?: number;
};

export const selectForExternalEmployeeBadgeList = {
  ...externalEmployeeSelectForCard,
  picture: {
    select: {
      path: true,
      width: true,
      height: true,
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

export const ExternalEmployeeBadgeList: React.FC<Props> = ({ firstFiveEmployees: employees, count }) => {
  const { t } = useI18n();
  return (
    <div className="flex items-center">
      <div className="flex justify-between -space-x-2">
        {employees.map((employee) => {
          return (
            <HtmlTooltip key={employee.id} className="flex-1" title={<ExternalEmployeeCard employee={employee} />}>
              <div>
                <EmployeePicture
                  picture={employee.picture}
                  placeholderId={employee.id}
                  pictureUrl={employee.userPermissions?.user?.profilePictureUrl}
                />
              </div>
            </HtmlTooltip>
          );
        })}
      </div>

      {count && count > 5 && (
        <Typography className="ml-2 space-x-2 whitespace-nowrap text-xs text-gray-600">
          {t("components.ui.external-employee-badge-list.and-other", {
            count: count - 5,
          })}
        </Typography>
      )}
    </div>
  );
};
