import { InfoOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { type ForwardedRef, type HTMLAttributes } from "react";
import { useI18n } from "~/lib/i18n/use-i18n";

export const SearchForMoreResultsIndicator = ({
  props,
  ref,
  search,
}: {
  ref: ForwardedRef<HTMLUListElement>;
  search: string;
  props: HTMLAttributes<HTMLUListElement>;
}) => {
  const { t } = useI18n();
  return (
    <ul {...props} ref={ref}>
      {props.children}
      {!search && (
        <Stack className="-mb-2 bg-gray-50 py-3 px-5" direction="row" alignItems="center" spacing={2}>
          <InfoOutlined fontSize="small" className="text-gray-500" />
          <Typography variant="caption" color="gray.500">
            {t("components.core.search-for-more-results-indicator.search-for-more-results")}
          </Typography>
        </Stack>
      )}
    </ul>
  );
};
