import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import classNames from "classnames";
import React, { useMemo } from "react";

type Props = {
  text: string;
  search: string | null;

  options?: Parameters<typeof match>[2];
  highlightClassName?: string;
};

export const HighlightedText: React.FC<Props> = ({
  text,
  search,
  options,
  highlightClassName = "bg-secondary-100",
}) => {
  const res = useMemo(() => {
    if (!search) return [];
    const matches = match(text, search, options);
    return parse(text, matches);
  }, [text, search]);

  if (!search) return <>{text}</>;

  return (
    <>
      {res.map((part, index) => (
        <span key={index} className={classNames({ [highlightClassName]: part.highlight })}>
          {part.text}
        </span>
      ))}
    </>
  );
};
