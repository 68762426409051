import { type PerformanceReviewIntegrationSource } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { array, number, object, string } from "yup";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/get-required-user";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_DIAGNOSTIC_QUERY_KEY } from "~/pages/api/performance-review/fetch-company-performance-review-integration-diagnostic";
import { FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_SETTINGS_QUERY_KEY } from "~/pages/api/performance-review/fetch-company-performance-review-integration-settings";
import { FETCH_SELECTED_PERFORMANCE_REVIEW_CYCLES_QUERY_KEY } from "~/pages/api/performance-review/fetch-selected-performance-review-cycles";
import { PerformanceReviewScopeSchema } from "~/services/performance-review/find-or-create-performance-review-cycle";
import { updateSelectedPerformanceReviewCycles } from "~/services/performance-review/update-selected-performance-review-cycles";
import { syncPerformanceReviews } from "~/services/synchronization/sync-performance-reviews";

export type PerformanceReviewIntegrationDiagnostic = { connected: true } | { connected: false; error: string };
export type PerformanceReviewIntegrationDiagnostics = Partial<
  Record<PerformanceReviewIntegrationSource, PerformanceReviewIntegrationDiagnostic>
>;

export type PerformanceReviewData = {
  name: string;
  position: number;
  description: string;
  externalEmployeeIds: number[];
};

export const SyncPerformanceReviewsSchema = object({
  companyId: number().required(),
  selectedExternalPerformanceCycleIds: array().of(string()).nullable(),
  performanceReviewScope: PerformanceReviewScopeSchema,
});

export type SyncPerformanceReviewsInput = YupOutputType<typeof SyncPerformanceReviewsSchema>;

const handler: NextApiHandler<{ message: string }> = async (req, res) => {
  const user = getRequiredUser(req);

  const input = await SyncPerformanceReviewsSchema.validate(req.body, { abortEarly: false });

  if (!user.isSuperAdmin && user.companyId !== input.companyId) {
    return res.status(HttpStatus.UNAUTHORIZED).json({ message: "You are not allowed to perform this action" });
  }

  await updateSelectedPerformanceReviewCycles(req, input);

  const company = await req.prisma.company.findUniqueOrThrow({
    include: { performanceReviewIntegrationSettings: { where: { enabled: true } } },
    where: { id: input.companyId },
  });

  await syncPerformanceReviews(req, company, {
    performanceReviewScope: input.performanceReviewScope,
  });

  res.status(HttpStatus.ACCEPTED).json({ message: "Synchronisation scheduled" });
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessIntegrations: true },
});

export const useSyncPerformanceReviewsMutation = createMutation<typeof handler, typeof SyncPerformanceReviewsSchema>({
  path: "/api/sync-performance-reviews",
  schema: SyncPerformanceReviewsSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, FETCH_SELECTED_PERFORMANCE_REVIEW_CYCLES_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_SETTINGS_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_COMPANY_PERFORMANCE_REVIEW_INTEGRATION_DIAGNOSTIC_QUERY_KEY);
    },
  }),
});
