import { EmployeeLevel } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { mixed, number, object } from "yup";
import { api } from "~/lib/api";
import { computeTotalCashStatsForInput } from "~/services/employee-stats/compute-total-cash-stats-for-input";

export const ComputeCompensationMarketPositioningSchema = object({
  jobId: number().required(),
  level: mixed<EmployeeLevel>().oneOf(Object.values(EmployeeLevel)).required(),
  locationId: number().required(),
  currencyId: number().required(),
  totalCash: number().min(0).required(),
});

export type ComputeCompensationMarketPositioningResponse = {
  meanPercentageDifferenceFromTarget: number | null;
};

const handler: NextApiHandler<ComputeCompensationMarketPositioningResponse> = async (req, res) => {
  const input = ComputeCompensationMarketPositioningSchema.validateSync(req.body, {
    abortEarly: false,
  });

  const stats = await computeTotalCashStatsForInput(req, input);

  res.status(HttpStatus.OK).json({
    meanPercentageDifferenceFromTarget: stats.percentageDifference,
  });
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessRawData: true },
});
