import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  fetchSalaryRangesForAutocomplete,
  type FetchSalaryRangesForAutocompleteResult,
} from "~/services/salary-bands/access/fetch-salary-ranges-for-autocomplete";

export const FetchSalaryRangesForAutocompleteSchema = object({}).noUnknown();

export type FetchSalaryRangesForAutocompleteInput = YupOutputType<typeof FetchSalaryRangesForAutocompleteSchema>;

const handler: NextApiHandler<FetchSalaryRangesForAutocompleteResult> = async (req, res) => {
  const salaryGridId = req.user?.company.defaultSalaryGridId;
  if (!salaryGridId) return [];

  const salaryRanges = await fetchSalaryRangesForAutocomplete(req, { salaryGridId });

  return res.status(HttpStatus.OK).json(salaryRanges);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSalaryBands: true,
  },
});

export const FETCH_SALARY_RANGES_FOR_AUTOCOMPLETE_QUERY_KEY: ApiRoute["pathname"] =
  "/api/salary-bands/fetch-salary-ranges-for-autocomplete";

export const useFetchSalaryRangesForAutocompleteQuery = createQuery<
  typeof handler,
  typeof FetchSalaryRangesForAutocompleteSchema
>({
  path: FETCH_SALARY_RANGES_FOR_AUTOCOMPLETE_QUERY_KEY,
  schema: FetchSalaryRangesForAutocompleteSchema,
});
