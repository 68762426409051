import { type QueueJobName, type QueueJobStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";

export const updateQueueJobStatus = async (
  ctx: AppContext,
  params: { name: QueueJobName; singletonKey: string; status: QueueJobStatus; externalJobId: string | null }
) => {
  const { name, singletonKey, status, externalJobId } = params;

  await ctx.prisma.queueJob.upsert({
    where: { name_singletonKey: { name, singletonKey } },
    update: { status, externalJobId },
    create: params,
  });
};
