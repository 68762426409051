import { QueueJobName } from "@prisma/client";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { baseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { sendJob } from "~/lib/queue/send-job";
import { createCampaignEmployees } from "~/services/compensation-review/campaigns/admin/create-campaign-employees";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensation-review-context";

const CreateCompensationReviewCampaignEmployeesJobDataSchema = baseJobDataSchema.concat(
  object({
    campaignId: number().required(),
  })
);

export type CreateCompensationReviewCampaignEmployeesJobData =
  (typeof CreateCompensationReviewCampaignEmployeesJobDataSchema)["__outputType"];

export const createCompensationReviewCampaignEmployeesWorkerService = async (
  ctx: AppContext,
  data: CreateCompensationReviewCampaignEmployeesJobData
) => {
  const context = await fetchCompensationReviewCampaignContext(ctx, data);

  await createCampaignEmployees(context);
};

export const sendCreateCompensationReviewCampaignEmployeesJob = async (
  ctx: AppContext,
  data: CreateCompensationReviewCampaignEmployeesJobData
) => {
  await sendJob(ctx, {
    jobName: QueueJobName.CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES,
    data,
    options: { singletonKey: data.campaignId.toString() },
  });
};
