import { Alert, Typography } from "@mui/material";
import { type ExternalEmployee, OffCycleReviewRequestStatus } from "@prisma/client";
import React from "react";
import { type ExternalEmployeePanelSelect } from "~/components/external-employee/external-employee-panel-select";
import { useI18n } from "~/lib/i18n/use-i18n";
import { formatExternalEmployeeName } from "~/services/external-employee";

export const OffCycleReviewRequestsBanner: React.FC<{
  externalEmployee: Pick<ExternalEmployee, "externalId" | "firstName" | "lastName" | "employeeNumber">;
  request: NonNullable<ExternalEmployeePanelSelect["offCycleReviewRequests"][number]>;
}> = ({ externalEmployee, request }) => {
  const { t } = useI18n();

  return (
    <Alert severity="info" color="secondary" className="rounded-none">
      {request.status === OffCycleReviewRequestStatus.PENDING && (
        <Typography variant="caption">
          {t("components.external-employee-panel.pending-off-cycle-review-request", {
            name: formatExternalEmployeeName(externalEmployee),
          })}
        </Typography>
      )}

      {request.status === OffCycleReviewRequestStatus.ONGOING && (
        <Typography variant="caption">
          {t("components.external-employee-panel.ongoing-off-cycle-review-request", {
            name: formatExternalEmployeeName(externalEmployee),
          })}
        </Typography>
      )}
    </Alert>
  );
};
