import { FeatureFlagScope } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { FeatureFlagName } from "~/services/feature-flags";

export const companyHasAccessToBulkInviteUsers = async (ctx: AppContext, companyId: number) => {
  const canAccessBulkInviteUsersFeatureFlag = await ctx.prisma.featureFlag.findUnique({
    where: {
      name: FeatureFlagName.CAN_ACCESS_BULK_INVITE_USERS,
      OR: [
        { scope: FeatureFlagScope.PUBLIC },
        { scope: { not: FeatureFlagScope.DISABLED }, companies: { some: { id: companyId } } },
      ],
    },
    select: { id: true },
  });

  return !!canAccessBulkInviteUsersFeatureFlag;
};
