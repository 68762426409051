import { datadogRum } from "@datadog/browser-rum";
import { type NotifyMetadataSupportInput } from "~/pages/api/dev/notify-metadata-support";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";

export const useAppMetadataForCsm = ({ user }: { user: NullableAuthenticatedUser }) => {
  const rumContext = datadogRum.getInternalContext();

  if (!user || !rumContext) {
    return null;
  }

  return {
    userId: user.id,
    companyId: user.companyId,
    sessionId: rumContext.session_id ?? "Unable to get Session ID at the moment",
    sessionReplayUrl: datadogRum.getSessionReplayLink() ?? "Unable to get Session Link at the moment",
    url: window.location.href,
    view: window.location.pathname,
    params: JSON.stringify(Object.fromEntries(new URLSearchParams(window.location.search)), null, 4),
  } satisfies NotifyMetadataSupportInput;
};

export const formatAppMetadataForCsm = (metadata: NotifyMetadataSupportInput) => {
  return `- User ID: ${metadata.userId}

- Company ID: ${metadata.companyId}

- Session ID: ${metadata.sessionId}

- Session Replay: ${metadata.sessionReplayUrl}

- URL: ${metadata.url}

- View: ${metadata.view}

- Params: ${metadata.params}`;
};
