import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type AsyncReturnType } from "type-fest";
import { object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { OrderingSchema, PaginationSchema } from "~/lib/pagination";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { FilterOptionsSchema } from "~/services/employee-filter";
import { fetchOnboardingExternalEmployees } from "~/services/onboarding/external-employee";

export const FetchExternalEmployeesForOnboardingSchema = object({
  pagination: PaginationSchema.required(),
  order: OrderingSchema.required(),
  filters: FilterOptionsSchema.required(),
});

export type FetchExternalEmployeesForOnboardingInput = YupOutputType<typeof FetchExternalEmployeesForOnboardingSchema>;

const handler: NextApiHandler<AsyncReturnType<typeof fetchOnboardingExternalEmployees>> = async (req, res) => {
  const input = FetchExternalEmployeesForOnboardingSchema.validateSync(req.body, { abortEarly: false });

  const externalEmployees = await fetchOnboardingExternalEmployees(req, input);

  return res.status(HttpStatus.OK).json(externalEmployees);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessSettings: true },
});

export const FETCH_EXTERNAL_EMPLOYEES_FOR_ONBOARDING_QUERY_KEY: ApiRoute["pathname"] =
  "/api/onboarding/fetch-external-employees";

export const useFetchExternalEmployeesForOnboardingQuery = createQuery<
  typeof handler,
  typeof FetchExternalEmployeesForOnboardingSchema
>({
  path: FETCH_EXTERNAL_EMPLOYEES_FOR_ONBOARDING_QUERY_KEY,
  schema: FetchExternalEmployeesForOnboardingSchema,
});
