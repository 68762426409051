import { AdditionalFieldNature } from "@prisma/client";
import { chain, isNil, map, mapValues, omit, toPairs } from "lodash";

type AdditionalFieldsValueByNature = {
  [x: string]: {
    stringValue: string;
    dateValue?: Date;
    numberValue?: number;
    percentageValue?: number;
  };
};

type CountOfValues = {
  stringValue: number;
  dateValue: number;
  numberValue: number;
  percentageValue: number;
};

const determineNature = (countOfValues: CountOfValues) => {
  const { stringValue, dateValue, numberValue, percentageValue } = countOfValues;

  if (stringValue > 0 && dateValue === 0 && numberValue === 0 && percentageValue === 0) {
    return AdditionalFieldNature.STRING;
  }

  const withoutStringValue = omit(countOfValues, ["stringValue"]);
  if (Object.values(withoutStringValue).filter((value) => value > 0).length > 1) {
    return AdditionalFieldNature.STRING;
  }

  if (stringValue === dateValue) {
    return AdditionalFieldNature.DATE;
  }

  if (stringValue === numberValue) {
    return AdditionalFieldNature.NUMBER;
  }

  if (stringValue === percentageValue) {
    return AdditionalFieldNature.PERCENTAGE;
  }

  return AdditionalFieldNature.STRING;
};

export const getNatureByValues = (additionalFields: AdditionalFieldsValueByNature[]) => {
  //grouped by additional field name / value
  const groupedByName = chain(additionalFields)
    .flatMap((additionalField) => toPairs(additionalField))
    .groupBy((additionalField) => additionalField[0])
    .mapValues((group) => {
      return map(group, (additionalField) => additionalField[1]); // Extract the values and create an array
    })
    .value();

  // returns a count for each value type
  const countByNature = mapValues(groupedByName, (values: AdditionalFieldsValueByNature[]) => {
    return mapValues(values[0], (index, key) => {
      return values.filter((v) => !isNil(v[key])).length;
    });
  });

  //for each of the value, if the count is equal to the number of rows, then return the value name
  return mapValues(countByNature, (values: CountOfValues) => {
    return determineNature(values);
  }) as unknown as Record<string, AdditionalFieldNature>;
};
