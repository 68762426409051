import { CompanySurveyStatus } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { fetchCompanySurveyWithTags } from "~/services/company-survey/fetch-company-survey-with-tags";
export const getAssistedOnboardingStatus = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  const company = await ctx.prisma.company.findFirstOrThrow({
    where: {
      id: user.companyId,
    },
    select: {
      hasFinishedMapping: true,
      hasImportedEmployees: true,
      liveSurveyId: true,
      globalTargetPercentile: true,
    },
  });

  const { industryTags, revenueModelTags } = await fetchCompanySurveyWithTags(ctx, {
    companyId: user.companyId,
  });

  const tags = [...industryTags, ...revenueModelTags];
  const companyWithMarketPositioning = await ctx.prisma.company.findFirstOrThrow({
    where: {
      id: user.companyId,
    },
    include: {
      marketPositioning: {
        select: {
          type: true,
        },
      },
    },
  });

  const companyTargetPercentile = await ctx.prisma.companyTargetPercentile.count({
    where: {
      companyId: user.companyId,
    },
  });

  const liveSurvey = await ctx.prisma.companySurvey.findFirst({
    where: {
      companyId: user.companyId,
      status: CompanySurveyStatus.LIVE,
    },
    select: {
      employeesCount: true,
      hrms: true,
    },
  });

  const hasCompletedCompanySurvey =
    tags && tags.length > 0 && !!liveSurvey?.employeesCount && liveSurvey?.employeesCount > 0 && !!liveSurvey?.hrms;

  const hasCompletedCompensationPolicy =
    (company?.globalTargetPercentile !== null || companyTargetPercentile > 0) &&
    companyWithMarketPositioning.marketPositioning &&
    companyWithMarketPositioning.marketPositioning.type !== null;

  const hasFinishedMapping = company?.hasFinishedMapping;
  const hasImportedEmployees = company?.hasImportedEmployees;

  return {
    hasFinishedMapping: hasFinishedMapping ?? false,
    hasImportedEmployees: hasImportedEmployees ?? false,
    hasCompletedCompensationPolicy: hasCompletedCompensationPolicy ?? false,
    hasCompletedCompanySurvey: hasCompletedCompanySurvey ?? false,
  };
};

export type GetAssistedOnboardingStatusResult = AsyncReturnType<typeof getAssistedOnboardingStatus>;
