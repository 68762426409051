import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type AsyncReturnType } from "type-fest";
import { object, string } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { countExternalEmployeesByStatus } from "~/services/external-employee/external-employee-read";

export const CountExternalEmployeesByStatusSchema = object({
  query: string().nullable().defined(),
});

export type CountExternalEmployeesByStatusInput = YupOutputType<typeof CountExternalEmployeesByStatusSchema>;

const handler: NextApiHandler<AsyncReturnType<typeof countExternalEmployeesByStatus>> = async (req, res) => {
  const input = CountExternalEmployeesByStatusSchema.validateSync(req.body, { abortEarly: false });

  const employeesByStatus = await countExternalEmployeesByStatus(req, input);

  return res.status(HttpStatus.OK).json(employeesByStatus);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessRawData: true, canAccessIntegrations: true },
});

export const COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY: ApiRoute["pathname"] =
  "/api/external-employee/count-external-employees-by-status";

export const useCountExternalEmployeesByStatusQuery = createQuery<
  typeof handler,
  typeof CountExternalEmployeesByStatusSchema
>({
  path: COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY,
  schema: CountExternalEmployeesByStatusSchema,
});
