/**
 * Here is the kombo api schema https://api.kombo.dev/openapi.json
 */
import {
  type Company,
  CompensationFrequency,
  type Country,
  type Currency,
  EmployeeMappingSkipReason,
  type ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
  IntegrationSource,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isAfter, parseISO } from "date-fns";
import { compact, orderBy } from "lodash";
import { type ParsedUrlQueryInput } from "querystring";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { KomboTimeoutError } from "~/lib/api-error";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { companyHasAccessToBulkInviteUsers } from "~/lib/hris/helpers/company-has-access-to-bulk-invite-users";
import { getEmployeeProfilePicture } from "~/lib/hris/helpers/get-employee-profile-picture";
import { getNumberOfMonth } from "~/lib/hris/helpers/get-number-of-month";
import { getPayfitBonusItems, type PayfitVariableCompensation } from "~/lib/hris/kombo-payfit";
import { type IntegrationConfig, type SourceConfig, type StaticModels } from "~/lib/integration";
import { logWarn } from "~/lib/logger";
import { buildExternalUrl } from "~/lib/url";
import { assertProps, isIn, sleep } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetch-company-integration-diagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/sync-external-employees";

const base: Omit<IntegrationConfig, "source"> = {
  companyId: 0,
  clientId: null,
  domain: null,
  clientSecret: "",
  anonymous: false,
  enabled: true,
  retryCount: 0,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  variableCustomFieldName: null,
  externalIdCustomFieldName: null,
  variableCustomFieldFrequency: null,
  businessUnitCustomFieldName: null,
};

export const komboConfigs = (source: IntegrationSource): SourceConfig => ({
  default: { ...base, source },
  anonymous: { ...base, source, anonymous: true },
  // "custom fields": {
  //   ...base,
  //   anonymous: true,
  //   fteCustomFieldName: "FTE",
  //   levelCustomFieldName: "level",
  //   variableCustomFieldName: "bonusCom",
  //   externalIdCustomFieldName: "externalEmployeeId",
  //   variableCustomFieldFrequency: "YEARLY",
  //   businessUnitCustomFieldName: "department",
  // },
});

type Credentials = {
  clientSecret: string;
  anonymous: boolean;
};

export enum KomboGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NON_BINARY = "NON_BINARY",
  NOT_SPECIFIED = "NOT_SPECIFIED",
}

export enum KomboEmploymentStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  INACTIVE = "INACTIVE",
  LEAVE = "LEAVE",
}

export enum KomboEmploymentType {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  CONTRACT = "CONTRACT",
  INTERNSHIP = "INTERNSHIP",
  FREELANCE = "FREELANCE",
  WORKING_STUDENT = "WORKING_STUDENT",
  APPRENTICESHIP = "APPRENTICESHIP",
  TRAINING = "TRAINING",
}

export type KomboEmployee = {
  id: string;
  remote_id: string;
  employee_number: string | null;
  first_name?: string | null;
  last_name?: string | null;
  work_email?: string | null;
  display_full_name?: string | null;
  job_title: string | null;
  gender: KomboGender | null;
  employment_status: KomboEmploymentStatus | null;
  employment_type: KomboEmploymentType | null;
  avatar?: string | null;
  work_location_id?: string | null;
  manager_id: string | null;
  home_address: KomboAddress | null;
  date_of_birth?: string | null;
  start_date: string | null;
  termination_date?: string | null;
  remote_created_at?: string | null;
  changed_at: string;
  remote_deleted_at?: string | null;
  custom_fields?: {
    [key: string]: string | null;
    level: string | null;
    business_unit: string | null;
    fte: string | null;
    variable_bonus: string | null;
    holiday_allowance_value: string | null;
  };
  remote_data?: Record<string, string | number> | null;
  employments: KomboEmployment[];
  time_off_balances: [];
  manager: KomboManager | null;
  groups: [];
  teams: [];
  work_location?: KomboLocation;
};

export type KomboManager = {
  id: string;
  first_name: string | null;
  last_name: string | null;
  remote_id?: string | null;
};

export type KomboLocation = {
  id: string;
  remote_id: string;
  name: string;
  address?: KomboAddress;
  type?: string;
  changed_at: string;
  remote_deleted_at?: string;
  remote_data?: string;
};

export type KomboAddress = {
  city: string;
  country: string;
  raw?: string;
  state?: string;
  street_1: string;
  street_2?: string;
  zip_code: string;
};

export enum KomboPayPeriod {
  YEAR = "YEAR",
  HALF_YEAR = "HALF_YEAR",
  QUARTER = "QUARTER",
  TWO_MONTHS = "TWO_MONTHS",
  MONTH = "MONTH",
  HALF_MONTH = "HALF_MONTH",
  TWO_WEEKS = "TWO_WEEKS",
  WEEK = "WEEK",
  DAY = "DAY",
  HOUR = "HOUR",
}

export enum KomboPayFrequency {
  WEEKLY = "WEEKLY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY",
  SEMIMONTHLY = "SEMIMONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMIANNUALLY = "SEMIANNUALLY",
  ANNUALLY = "ANNUALLY",
  PRO_RATA = "PRO_RATA",
}

export type KomboEmployment = {
  id: string;
  remote_id: string | null;
  employee_id: string;
  job_title?: string | null;
  pay_rate: number | null;
  pay_period: KomboPayPeriod | null;
  pay_frequency: KomboPayFrequency | null;
  pay_currency: string | null;
  effective_date: string | null;
  changed_at: string;
  remote_deleted_at?: string | null;
  // This contains raw/specific data of each integration
  // This "/_VARIABLE_COMPENSATION" is only available on payfit integration
  remote_data?: Record<"/_VARIABLE_COMPENSATION", PayfitVariableCompensation> | null;
};

const SUPPORTED_EMPLOYMENT_PAY_PERIODS = [
  KomboPayPeriod.YEAR,
  KomboPayPeriod.HALF_YEAR,
  KomboPayPeriod.QUARTER,
  KomboPayPeriod.TWO_MONTHS,
  KomboPayPeriod.MONTH,
];

enum KomboIntegrationDetailsCategory {
  HRIS = "HRIS",
  ATS = "ATS",
}

enum KomboIntegrationDetailsStatus {
  ACTIVE = "ACTIVE",
  INVALID = "INVALID",
  INACTIVE = "INACTIVE",
}

type KomboIntegrationDetailsResponse = {
  status: "success";
  data: {
    id: string;
    tool: {
      id: string;
      label: string;
      logo_url: string;
      icon_url: string;
    };
    category: KomboIntegrationDetailsCategory;
    status: KomboIntegrationDetailsStatus;
    end_user: {
      organization_name: string;
      creator_email: string | null;
      origin_id: string | null;
    };
    created_at: string;
  };
};

type KomboResponse<T extends KomboEmployee> = {
  status: string;
  data: {
    next: string;
    results: T[];
  };
};

type KomboIntegrationSettingsInput = {
  clientSecret: string;
  anonymous: boolean;
};

const DEFAULT_LIMIT = 100;

export const deleteKomboIntegration = async (clientSecret: IntegrationSettingsForSync["clientSecret"]) => {
  const baseUrl = `https://api.kombo.dev/v1/integrations/${clientSecret}`;

  const res = await fetch(buildExternalUrl(baseUrl), {
    method: "DELETE",
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${config.kombo.apiKey}`,
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    const { message } = await res.json();
    throw new Error(`[kombo] ${res.status} ${res.statusText} : ${message}`);
  }

  const json: Pick<KomboIntegrationDetailsResponse, "status"> = await res.json();

  return json;
};

const diagnosticFetch = async (credentials: Credentials) => {
  const baseUrl = `https://api.kombo.dev/v1/integrations/${credentials.clientSecret}`;

  const res = await fetch(buildExternalUrl(baseUrl), {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${config.kombo.apiKey}`,
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    const { message } = await res.json();
    throw new Error(`[kombo] ${res.status} ${res.statusText} : ${message}`);
  }

  const json: KomboIntegrationDetailsResponse = await res.json();

  return json;
};

const komboFetch = async <T extends KomboEmployee>(
  ctx: AppContext,
  credentials: Credentials,
  endpoint: string,
  {
    method = "GET",
    query,
  }: {
    method?: "GET";
    query?: ParsedUrlQueryInput;
  } = {}
): Promise<T[]> => {
  const baseUrl = `https://api.kombo.dev/v1/hris/${endpoint}`;

  let res = await fetch(buildExternalUrl(baseUrl, { page_size: DEFAULT_LIMIT, include_deleted: false, ...query }), {
    method,
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${config.kombo.apiKey}`,
      "X-Integration-Id": credentials.clientSecret,
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    // When activating the integration Kombo will make a first sync of employees
    // During this first sync they return a 503 error
    const MAX_RETRY_DURATION = 400000; // 400s
    const limitTimestamp = Date.now() + MAX_RETRY_DURATION;
    let nbRetry = 1;

    while (Date.now() < limitTimestamp && !res.ok && res.status === 503) {
      await sleep(nbRetry * 1000);

      logWarn(ctx, `[kombo] Getting ${res.status} error on diagnostic call, retry #${nbRetry}`);

      res = await fetch(buildExternalUrl(baseUrl, { page_size: DEFAULT_LIMIT, include_deleted: false, ...query }), {
        method,
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${config.kombo.apiKey}`,
          "X-Integration-Id": credentials.clientSecret,
          "Content-Type": "application/json",
        },
      });

      nbRetry++;
    }

    if (!res.ok) {
      const { message } = await res.json();
      if (res.status === 503) {
        throw new KomboTimeoutError(`[kombo] ${res.status} ${res.statusText} : ${message}`);
      }
      throw new Error(`[kombo] ${res.status} ${res.statusText} : ${message}`);
    }
  }

  const json: KomboResponse<T> = await res.json();

  const isLastPageOfData = json.data.next === null;

  // If we have reached the end of paginated data just return what we have
  if (isLastPageOfData) {
    return json.data.results;
  }

  const nextPage = await komboFetch<T>(ctx, credentials, endpoint, {
    method,
    query: {
      ...query,
      cursor: json.data.next,
    },
  });

  return [...json.data.results, ...nextPage];
};

const getKomboProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: KomboEmployee }
) => {
  if (!options.apiEmployee.avatar) {
    return undefined;
  }

  return getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id,
    source: options.integrationSettings.source,
    integrationSettings: options.integrationSettings,
    fetch: () => fetch(options.apiEmployee.avatar as string),
  });
};

export const mapKomboEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: KomboEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  emailDomain?: string,
  defaultCountry?: Country,
  defaultCurrency?: Currency,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  const { variableCustomFieldFrequency } = integrationSettings;

  const country = value(() => {
    if (!apiEmployee.work_location?.address?.country) {
      return defaultCountry;
    }

    return (
      staticModels.countries.find((country) => country.alpha2 === apiEmployee.work_location?.address?.country) ??
      defaultCountry
    );
  });

  const currency = value(() => {
    if (apiEmployee.employments.length < 1) {
      return defaultCurrency;
    }

    const komboCurrency = staticModels.currencies.find(
      (currency) => currency.code === apiEmployee.employments[0]?.pay_currency
    );

    return komboCurrency ?? defaultCurrency;
  });

  const [currentEmployment, ...historicalEmployments] = orderBy(apiEmployee.employments, "effective_date", "desc");
  const jobTitle = apiEmployee.job_title ?? currentEmployment?.job_title;

  const isAutoSkipped = value(() => {
    if (
      !!apiEmployee.employment_type &&
      [
        KomboEmploymentType.INTERNSHIP,
        KomboEmploymentType.FREELANCE,
        KomboEmploymentType.WORKING_STUDENT,
        KomboEmploymentType.APPRENTICESHIP,
        KomboEmploymentType.TRAINING,
      ].includes(apiEmployee.employment_type)
    ) {
      return true;
    }

    if (
      apiEmployee.employment_type === "PART_TIME" &&
      !isIn(currentEmployment?.pay_period, SUPPORTED_EMPLOYMENT_PAY_PERIODS) &&
      !apiEmployee.custom_fields?.fte
    ) {
      return true;
    }

    return false;
  });

  const hasAccessToEmails = await companyHasAccessToBulkInviteUsers(ctx, company.id);

  const isEmailSyncable = value(() => {
    if (!hasAccessToEmails) {
      return false;
    }

    // check with actual user email domain
    if (emailDomain && apiEmployee.work_email?.includes(emailDomain)) {
      return true;
    }

    // check with company email domain
    if (!company.emailDomains) {
      return false;
    }

    return company.emailDomains.some((emailDomain) => apiEmployee.work_email?.includes(emailDomain));
  });

  const locationExternalId = apiEmployee?.work_location?.id ?? `${country?.id}`;

  const input: EmployeeData["input"] = {
    source: integrationSettings.source as ExternalEmployeeSource,
    externalId: apiEmployee.id,
    status: isAutoSkipped ? ExternalEmployeeStatus.SKIPPED : ExternalEmployeeStatus.UNMAPPED,
    ...(isAutoSkipped && {
      mappingSkipReason: EmployeeMappingSkipReason.NOT_PERMANENT_EMPLOYEE,
    }),
    firstName: apiEmployee.first_name,
    lastName: apiEmployee.last_name,
    email: isEmailSyncable && apiEmployee.work_email ? apiEmployee.work_email : null,
    employeeNumber: apiEmployee.employee_number ?? apiEmployee.id,
    gender: value(() => {
      if (!apiEmployee.gender) {
        return null;
      }
      if (apiEmployee.gender === KomboGender.FEMALE) {
        return Gender.FEMALE;
      }
      if (apiEmployee.gender === KomboGender.MALE) {
        return Gender.MALE;
      }
      return Gender.UNDISCLOSED;
    }),
    hireDate: apiEmployee.start_date ? parseISO(apiEmployee.start_date) : null,
    birthDate: apiEmployee.date_of_birth ? parseISO(apiEmployee.date_of_birth) : null,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency?.code || "EUR" },
    },
    ...((apiEmployee.work_location || country) && {
      location: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: locationExternalId,
            },
          },
          create: {
            externalId: locationExternalId,
            name: apiEmployee?.work_location?.name ?? `${country?.name}`,
            autoMappingEnabled: true,
            company: {
              connect: { id: company.id },
            },
            ...(country && { country: { connect: { id: country.id } } }),
          },
        },
      },
    }),
    ...(jobTitle && {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: jobTitle,
            },
          },
          create: {
            name: jobTitle,
            externalId: jobTitle,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(!!apiEmployee.custom_fields?.level && {
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: `${apiEmployee.custom_fields.level}`,
            },
          },
          create: {
            externalId: `${apiEmployee.custom_fields.level}`,
            name: `${apiEmployee.custom_fields.level}`,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(!!apiEmployee.custom_fields?.business_unit && {
      businessUnit: `${apiEmployee.custom_fields.business_unit}`,
    }),
  };

  const numberMonths = getNumberOfMonth({
    externalId: locationExternalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const remunerationItems: EmployeeData["remunerationItems"] = compact([
    currentEmployment &&
      mapKomboEmployment(ctx, {
        company,
        source: integrationSettings.source as ExternalEmployeeSource,
        employment: currentEmployment,
        externalRemunerationStatus: ExternalRemunerationStatus.LIVE,
        numberMonths,
      }),
    ...historicalEmployments.map((employment) =>
      mapKomboEmployment(ctx, {
        company,
        source: integrationSettings.source as ExternalEmployeeSource,
        employment,
        externalRemunerationStatus: ExternalRemunerationStatus.HISTORICAL,
        numberMonths,
      })
    ),
  ]);

  if (
    !!apiEmployee.custom_fields?.variable_bonus &&
    !isNaN(parseInt(apiEmployee.custom_fields.variable_bonus)) &&
    !!variableCustomFieldFrequency
  ) {
    const multiplier = match(variableCustomFieldFrequency)
      .with(CompensationFrequency.MONTHLY, () => 12)
      .with(CompensationFrequency.QUARTERLY, () => 4)
      .with(CompensationFrequency.YEARLY, () => 1)
      .exhaustive();

    const amount = Math.round(parseInt(apiEmployee.custom_fields.variable_bonus) * multiplier * 100);

    remunerationItems.push({
      source: integrationSettings.source as ExternalEmployeeSource,
      externalId: "variable-bonus",
      amount,
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: integrationSettings.source as ExternalEmployeeSource,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: integrationSettings.source as ExternalEmployeeSource,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    });
  }

  if (
    integrationSettings.source === IntegrationSource.KOMBO_PAYFIT &&
    integrationSettings.clientSecret.includes("payfit")
  ) {
    const variableAndBonuses = getPayfitBonusItems(apiEmployee.employments, company.id);

    remunerationItems.push(...variableAndBonuses);
  }

  const ignoreFte = value(() => {
    if (!currentEmployment?.pay_period) {
      return true;
    }

    return !isIn(currentEmployment.pay_period, SUPPORTED_EMPLOYMENT_PAY_PERIODS);
  });

  return {
    input,
    picturePath: !ignoreProfilePicture
      ? await getKomboProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    managerExternalId: value(() => {
      if (apiEmployee.manager) {
        return apiEmployee.manager.id;
      }

      if (apiEmployee.manager_id) {
        return apiEmployee.manager_id;
      }

      return undefined;
    }),
    ...(apiEmployee.custom_fields?.fte && {
      fte: apiEmployee.custom_fields.fte,
      ignoreFte,
    }),
    holidayAllowanceValue: apiEmployee.custom_fields?.holiday_allowance_value,
  };
};

const mapKomboEmployment = (
  ctx: AppContext,
  params: {
    company: Company;
    source: ExternalEmployeeSource;
    employment: KomboEmployment;
    externalRemunerationStatus: ExternalRemunerationStatus;
    numberMonths: number;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, employment, externalRemunerationStatus, source, numberMonths } = params;

  const amount = employment.pay_rate;

  if (!amount) {
    return null;
  }

  const interval = employment.pay_period;

  if (!interval || !isIn(interval, SUPPORTED_EMPLOYMENT_PAY_PERIODS)) {
    logWarn(ctx, `[sync] Unhandled Kombo fix salary interval`, { interval });
  }

  const numberOfMonthMultiplier = numberMonths / 12;

  const multiplier = match(interval)
    .with(KomboPayPeriod.YEAR, () => 1)
    .with(KomboPayPeriod.HALF_YEAR, () => 2 * numberOfMonthMultiplier)
    .with(KomboPayPeriod.QUARTER, () => 4 * numberOfMonthMultiplier)
    .with(KomboPayPeriod.TWO_MONTHS, () => 6 * numberOfMonthMultiplier)
    .with(KomboPayPeriod.MONTH, () => 12 * numberOfMonthMultiplier)
    .otherwise(() => 1);

  const externalIdSuffix = match(externalRemunerationStatus)
    .with(ExternalRemunerationStatus.LIVE, () => "")
    .with(ExternalRemunerationStatus.HISTORICAL, () => `-historical-${employment.id}`)
    .exhaustive();

  const yearlySalary = Math.round(amount * multiplier * 100);
  return {
    source,
    externalId: `fix-salary${externalIdSuffix}`,
    amount: yearlySalary,
    numberMonths,
    status: externalRemunerationStatus,
    date: employment.effective_date ? parseISO(employment.effective_date) : null,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source,
            externalId: "fix-salary",
          },
        },
        create: {
          source,
          externalId: "fix-salary",
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  };
};

const anonymize = (employee: KomboEmployee): KomboEmployee => {
  delete employee.first_name;
  delete employee.last_name;
  delete employee.display_full_name;
  delete employee.avatar;
  delete employee.date_of_birth;
  delete employee.work_email;
  return employee;
};

export const getKomboEmployees = async (ctx: AppContext, credentials: Credentials): Promise<KomboEmployee[]> => {
  const employees = await komboFetch<KomboEmployee>(ctx, credentials, "employees", {
    query: {
      employment_status: KomboEmploymentStatus.ACTIVE,
    },
  });

  const activeEmployees = employees.filter(
    (employee) => !employee.termination_date || isAfter(parseISO(employee.termination_date), new Date())
  );

  if (credentials.anonymous) {
    return activeEmployees.map(anonymize);
  }

  return activeEmployees;
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedKomboEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const komboEmployees = await getKomboEmployees(ctx, safeIntegrationSettings);

  const emailDomain = await value(async () => {
    const defaultUser = await ctx.prisma.user.findFirst({
      where: { companyId: company.id, deletedAt: null, blockedAt: null },
      orderBy: [{ id: "asc" }],
      select: { email: true },
    });

    if (!defaultUser) {
      return undefined;
    }

    const [, domainName] = defaultUser.email.split("@");

    return domainName;
  });

  const defaultCountry = staticModels.countries.find((country) => country.id === company.defaultCountryId);
  const defaultCurrency = staticModels.currencies.find((currency) => defaultCountry?.defaultCurrencyId === currency.id);

  return mapSeries(komboEmployees, (komboEmployee) =>
    mapKomboEmployee(
      ctx,
      company,
      komboEmployee,
      safeIntegrationSettings,
      staticModels,
      emailDomain,
      defaultCountry,
      defaultCurrency,
      ignoreProfilePicture
    )
  );
};

export const getKomboDiagnostic = async (
  ctx: AppContext,
  input: KomboIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const komboDiagnostic = await diagnosticFetch(input);

    if (
      komboDiagnostic.status !== "success" ||
      komboDiagnostic.data.category !== KomboIntegrationDetailsCategory.HRIS ||
      komboDiagnostic.data.status !== KomboIntegrationDetailsStatus.ACTIVE
    ) {
      return {
        connection: false,
        connectionError: "Please retry to connect to your HRIS",
        missingFields: [],
        availableFields: [],
      };
    }

    return {
      connection: true,
      connectionError: "",
      missingFields: [],
      availableFields: [],
    };
  } catch (error) {
    return {
      connection: false,
      connectionError: error.message,
      missingFields: [],
      availableFields: [],
    };
  }
};

export const getRawKomboEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<KomboEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getKomboEmployees(ctx, safeIntegrationSettings);
};
