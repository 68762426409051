import { chain } from "lodash";
import { type AppContext } from "~/lib/context";
import { getDefaultCurrency } from "~/services/currency";
import { type SalaryBandLocationForCreate } from "~/services/salary-bands/creation/create-new-salary-bands";

export const getSalaryBandCurrencyIdFromLocation = async (
  ctx: AppContext,
  salaryBandLocation: SalaryBandLocationForCreate
) => {
  const currencyIds = chain(salaryBandLocation.mappedLocations)
    .map(
      (salaryBandLocation) =>
        salaryBandLocation.externalLocation?.mappedLocation?.country?.defaultCurrencyId ??
        salaryBandLocation.externalLocation?.country?.defaultCurrencyId ??
        salaryBandLocation.location?.country?.defaultCurrencyId
    )
    .compact()
    .uniq()
    .value();

  const mostRepresentedCurrencyId = chain(currencyIds)
    .countBy()
    .entries()
    .maxBy(([, count]) => count)
    .head()
    .toNumber()
    .value();

  if (mostRepresentedCurrencyId) {
    return mostRepresentedCurrencyId;
  }

  const defaultCurrency = await getDefaultCurrency(ctx);

  return defaultCurrency.id;
};
