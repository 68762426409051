import { isNull, isNumber } from "lodash";
import { useEffect } from "react";
import { handleExceptionalRightsChange } from "~/components/layout/user-notices";
import { type SyncCallback, useTabSyncing } from "~/hooks/use-tab-syncing";
import { useSession } from "~/hooks/useSession";

export const useTabSyncingEvents = () => {
  const { user, impersonatingCompany, impersonatingUser } = useSession();
  const tabSyncing = useTabSyncing();

  const onUserRoleChange: SyncCallback<"user-role"> = (payload) => {
    if (user?.permissions?.role !== payload.role) {
      handleExceptionalRightsChange();
      return;
    }
  };

  const onSuperAdminRightsChange: SyncCallback<"super-admin"> = (payload) => {
    if (user?.isSuperAdmin !== payload.isSuperAdmin) {
      handleExceptionalRightsChange();
      return;
    }
  };

  const onUserImpersonationChange: SyncCallback<"user-impersonation"> = (payload) => {
    if (!user) {
      window.location.reload();
      return;
    }

    const hasStoppedImpersonating = impersonatingUser && isNull(payload.userId);
    const hasStartedImpersonating = !impersonatingUser && isNumber(payload.userId);
    const hasChangedImpersonation = impersonatingUser && isNumber(payload.userId) && payload.userId !== user.id;

    if (hasStoppedImpersonating || hasStartedImpersonating || hasChangedImpersonation) {
      handleExceptionalRightsChange();
      return;
    }
  };

  const onCompanyImpersonationChange: SyncCallback<"company-impersonation"> = (payload) => {
    if (!user) {
      window.location.reload();
      return;
    }

    const hasStoppedImpersonating = impersonatingCompany && isNull(payload.companyId);
    const hasStartedImpersonating = !impersonatingCompany && isNumber(payload.companyId);
    const hasChangedImpersonation =
      impersonatingCompany && isNumber(payload.companyId) && payload.companyId !== user.companyId;

    if (hasStoppedImpersonating || hasStartedImpersonating || hasChangedImpersonation) {
      handleExceptionalRightsChange();
      return;
    }
  };

  useEffect(() => {
    tabSyncing.listen("user-role", onUserRoleChange);
    tabSyncing.listen("super-admin", onSuperAdminRightsChange);
    tabSyncing.listen("user-impersonation", onUserImpersonationChange);
    tabSyncing.listen("company-impersonation", onCompanyImpersonationChange);

    return () => {
      tabSyncing.stopListening("user-role", onUserRoleChange);
      tabSyncing.stopListening("super-admin", onSuperAdminRightsChange);
      tabSyncing.stopListening("user-impersonation", onUserImpersonationChange);
      tabSyncing.stopListening("company-impersonation", onCompanyImpersonationChange);
    };
  }, [user, impersonatingCompany, impersonatingUser]);
};
