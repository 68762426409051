import { Check } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { type CustomJob, UserRole } from "@prisma/client";
import { compact, sumBy } from "lodash";
import React, { useEffect } from "react";
import { JobSelector } from "~/components/ui/JobSelector";
import { LegacyFormControl } from "~/components/ui/core/LegacyFormControl";
import { LegacyModal } from "~/components/ui/core/LegacyModal";
import { LegacyText } from "~/components/ui/core/LegacyText";
import { TextInput } from "~/components/ui/core/TextInput";
import { Button } from "~/components/ui/core/button";
import { useApi } from "~/hooks/useApi";
import { useForm } from "~/hooks/useForm";
import { useListJobsQuery } from "~/hooks/useQueries";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import { type CreateCustomJobResponse, CreateCustomJobSchema } from "~/pages/api/create-custom-job";
import { type WeightedJob } from "~/services/job";

type Props = {
  isOpen: boolean;
  onClose: (newCustomJob?: CustomJob) => void;
};

type FormValues = {
  name: string;
  jobs: WeightedJob[];
  sharedWithCompany: boolean;
};

export const CustomJobCreationModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useI18n();
  const { apiFetch } = useApi();
  const { user } = useSession();
  const isUserRecruiter = user?.permissions.role === UserRole.RECRUITER;

  const { data: jobsData } = useListJobsQuery();

  const form = useForm<FormValues>({
    initialValues: {
      name: "",
      jobs: [],
      sharedWithCompany: false,
    },
    validationSchema: CreateCustomJobSchema,
    onSubmit: async (values) => {
      const res = await apiFetch<CreateCustomJobResponse>("/api/create-custom-job", {
        body: {
          name: values.name,
          jobs: values.jobs,
          sharedWithCompany: values.sharedWithCompany,
        },
        successMessage: t("components.modals.custom-job-creation-modal.job-has-successfully-been-created", {
          name: values.name,
        }),
      });

      handleClose(res.customJob);
    },
  });

  const handleClose = (newCustomJob?: CustomJob) => {
    onClose(newCustomJob);

    form.resetForm();
  };

  const onWeightInputBlur = (changedJobIndex: number) => {
    if (form.values.jobs.length !== 2) {
      return;
    }

    const newJobs = [...form.values.jobs];
    const changedJob = newJobs[changedJobIndex] as WeightedJob;

    newJobs.forEach((item, index) => {
      if (index !== changedJobIndex) {
        item.weight = 100 - changedJob.weight;
      }
    });

    void form.setFieldValue("jobs", newJobs);
  };

  // Recompute weights when the number of jobs change
  useEffect(() => {
    void form.setFieldValue(
      "jobs",
      form.values.jobs.map((item) => {
        return {
          job: item.job,
          weight: Math.floor(100 / form.values.jobs.length),
        };
      })
    );
  }, [form.values.jobs.length]);

  const jobNamePlaceholder = form.values.jobs
    .map((item) => {
      return item.job.name;
    })
    .join(" × ");

  const weightsSum = sumBy(form.values.jobs, (item) => {
    return item.weight;
  });

  return (
    <LegacyModal
      isOpen={isOpen}
      title={t("components.modals.custom-job-creation-modal.new-custom-job")}
      onClose={() => {
        handleClose();
      }}
      className="w-[560px]"
    >
      <div className="mt-3 flex flex-col items-start">
        <LegacyText className="max-w-lg" icon="information">
          {t("components.modals.custom-job-creation-modal.combine-multiple-jobs")}
        </LegacyText>

        <form className="mt-4 w-full" onSubmit={form.handleSubmit}>
          <div className="mt-4 flex">
            <LegacyFormControl
              requiredHint
              className="flex-1"
              label={t("components.modals.custom-job-creation-modal.figures-jobs")}
            >
              <Stack spacing={2}>
                {form.values.jobs.map((job, index) => (
                  <JobSelector
                    key={index}
                    className="w-full"
                    jobIds={[`${job.job.id}`]}
                    onChange={([jobId]) => {
                      const newJobIds = [...form.values.jobs.map((item) => `${item.job.id}`)];
                      if (jobId) {
                        newJobIds[index] = jobId;
                      } else {
                        newJobIds.splice(index, 1);
                      }

                      const newJobs = newJobIds.map((jobId, index) => {
                        const job = jobsData?.jobs.find((job) => `${job.id}` === jobId);
                        if (!job) return null;

                        return { job, weight: form.values.jobs[index]?.weight ?? 100 };
                      });

                      void form.updateValues({ jobs: compact(newJobs) });
                    }}
                  />
                ))}
                <JobSelector
                  key={form.values.jobs.length}
                  className="w-full"
                  jobIds={[]}
                  placeholder={
                    form.values.jobs.length > 0
                      ? t("components.modals.custom-job-creation-modal.select-more-jobs-to-combine")
                      : t("components.modals.custom-job-creation-modal.select-at-least-2-jobs")
                  }
                  onChange={([jobId]) => {
                    if (!jobId) return;

                    const newJobIds = [...form.values.jobs.map((item) => `${item.job.id}`), jobId];
                    const newJobs = newJobIds.map((jobId, index) => {
                      const job = jobsData?.jobs.find((job) => `${job.id}` === jobId);
                      if (!job) return null;

                      return { job, weight: form.values.jobs[index]?.weight ?? 100 };
                    });

                    void form.updateValues({ jobs: compact(newJobs) });
                  }}
                />
              </Stack>
            </LegacyFormControl>

            {form.values.jobs.length > 1 && (
              <LegacyFormControl
                requiredHint
                className="ml-4"
                label={t("components.modals.custom-job-creation-modal.weights")}
                tooltip={
                  <div className="flex w-96 flex-col p-2">
                    <span>{t("components.modals.custom-job-creation-modal.use-weights")}</span>

                    <span className="mt-2">{t("components.modals.custom-job-creation-modal.for-example")}</span>
                  </div>
                }
              >
                <div className="flex flex-col space-y-1">
                  {form.values.jobs.map((item, index) => {
                    return (
                      <div key={item.job.id} className="flex items-center">
                        <TextInput
                          required
                          affix="%"
                          className="text-right"
                          max={100}
                          min={1}
                          type="number"
                          value={item.weight}
                          onBlur={() => {
                            onWeightInputBlur(index);
                          }}
                          onChange={(event) => {
                            const jobs = [...form.values.jobs];
                            const jobRow = jobs[index];

                            if (jobRow) {
                              jobRow.weight = parseInt(event.target.value);
                              void form.setFieldValue("jobs", jobs);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </LegacyFormControl>
            )}
          </div>

          <LegacyFormControl
            requiredHint
            className="mt-8"
            label={t("components.modals.custom-job-creation-modal.job-name")}
          >
            <div className="flex">
              <TextInput
                required
                placeholder={jobNamePlaceholder}
                value={form.values.name}
                onChange={(event) => {
                  void form.setFieldValue("name", event.target.value);
                }}
              />
              {!form.values.name && form.values.jobs?.length >= 1 && (
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={() => {
                    void form.setFieldValue("name", jobNamePlaceholder);
                  }}
                  startIcon={<Check className="ml-2.5" />}
                />
              )}
            </div>
          </LegacyFormControl>

          {!isUserRecruiter && (
            <LegacyFormControl className="mt-8" label={t("components.modals.custom-job-creation-modal.sharing")}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={form.values.sharedWithCompany}
                    onChange={(event) => {
                      void form.setFieldValue("sharedWithCompany", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography>
                    {t("components.modals.custom-job-creation-modal.share-this-job", {
                      companyName: user?.company.name ?? "my organisation",
                    })}
                  </Typography>
                }
              />
            </LegacyFormControl>
          )}

          <div className="mt-8 flex items-center justify-end border-t pt-3">
            {form.values.jobs.length > 0 && weightsSum !== 100 && (
              <LegacyText className="mr-4" icon="warning">
                {t("components.modals.custom-job-creation-modal.sum-doesnt-add-up")}
              </LegacyText>
            )}

            <Button variant="contained" disabled={!form.isValid || form.isSubmitting} type="submit">
              {t("components.modals.custom-job-creation-modal.create-custom-job")}
            </Button>
          </div>
        </form>
      </div>
    </LegacyModal>
  );
};
