import { type Prisma } from "@prisma/client";
import { compact, includes, lowerCase } from "lodash";
import { stripDiacritics } from "~/lib/utils";
import { LEVELS_MAP } from "~/services/employee/employee-level";
import { USER_ROLE_MAP } from "~/services/users/user-role-map";

export const searchUserInvitation = (query: string) => {
  const matches = { contains: query, mode: "insensitive" } as const;
  const lowerQuery = lowerCase(query);
  const userRoles = Object.entries(USER_ROLE_MAP)
    .filter(([key]) => includes(lowerCase(key), lowerQuery))
    .map(([, value]) => value);

  return {
    OR: compact([{ email: matches }, userRoles.length > 0 && { permissions: { role: { in: userRoles } } }]),
  } satisfies Prisma.UserWhereInput;
};

export const searchUser = (query: string) => {
  const matches = { contains: query, mode: "insensitive" } as const;
  const lowerQuery = lowerCase(query);
  const userRoles = Object.entries(USER_ROLE_MAP)
    .filter(([key]) => includes(lowerCase(key), lowerQuery))
    .map(([, value]) => value);

  return {
    OR: compact([
      { firstName: matches },
      { lastName: matches },
      { email: matches },
      userRoles.length > 0 && { permissions: { role: { in: userRoles } } },
    ]),
  } satisfies Prisma.UserWhereInput;
};

export const searchExternalEmployee = (query: string) => {
  const lowerQuery = lowerCase(stripDiacritics(query));
  const employeeLevels = Object.entries(LEVELS_MAP)
    .filter(([key]) => includes(lowerCase(key), lowerQuery))
    .map(([, value]) => value);

  return {
    AND: lowerQuery.split(" ").flatMap((part) => {
      const matches = { contains: part, mode: "insensitive" } as const;

      return {
        OR: compact([
          { normalisedColumns: matches },
          { mappedEmployee: { firstName: matches } },
          { mappedEmployee: { lastName: matches } },
          { mappedEmployee: { externalId: matches } },
          { mappedEmployee: { employeeNumber: matches } },
          { mappedEmployee: { job: { name: matches } } },
          { mappedEmployee: { job: { family: { name: matches } } } },
          { mappedEmployee: { location: { name: matches } } },
          { mappedEmployee: { location: { country: { name: matches } } } },
          employeeLevels.length > 0 && { mappedEmployee: { level: { in: employeeLevels } } },
          employeeLevels.length > 0 && { level: { mappedLevel: { in: employeeLevels } } },
          { level: { name: matches } },
          { job: { name: matches } },
          { job: { mappedJob: { name: matches } } },
          { location: { name: matches } },
          { location: { mappedLocation: { name: matches } } },
          { location: { country: { name: matches } } },
          { location: { mappedLocation: { country: { name: matches } } } },
          { additionalFieldValues: { some: { stringValue: matches } } },
        ]),
      };
    }),
  } satisfies Prisma.ExternalEmployeeWhereInput;
};
