import { UserRole } from "@prisma/client";
import { isNull } from "lodash";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { type AuthenticatedUser } from "~/lib/session";
import { isIn } from "~/lib/utils";
import {
  fetchRequiredAuthenticatedUser,
  type NullableAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";
import { canImpersonateUsers } from "~/services/user/permissions/action-permissions";

export const resolveImpersonatedUser = async (
  ctx: AppContext,
  params: {
    impersonatingUser: NullableAuthenticatedUser;
    impersonatedUserId: string | number | null;
  }
) => {
  if (isNull(params.impersonatingUser)) {
    return null;
  }

  if (isNull(params.impersonatedUserId)) {
    return null;
  }

  const hasUserImpersonationPermission = canImpersonateUsers({
    user: params.impersonatingUser,
    subscriptions: ctx.subscriptions,
  });

  // Re-calculating here, avoids risking a loop with the session
  if (!hasUserImpersonationPermission) {
    return null;
  }

  const impersonatedUser = await fetchRequiredAuthenticatedUser(ctx, {
    userId: +params.impersonatedUserId,
    ...(!params.impersonatingUser.isSuperAdmin && {
      extraWhere: {
        companyId: params.impersonatingUser.companyId,
      },
    }),
  });

  impersonatedUser.isSuperAdmin = false;

  if (!isUserAllowedToImpersonateRole({ user: params.impersonatingUser, role: impersonatedUser.permissions.role })) {
    return null;
  }

  return impersonatedUser;
};

export const isUserAllowedToImpersonateRole = (params: {
  user: Pick<AuthenticatedUser, "isSuperAdmin" | "permissions">;
  role: UserRole | null | undefined;
}): boolean => {
  if (params.user.isSuperAdmin) {
    return true;
  }

  if (!params.role) {
    return false;
  }

  const allowedRoles = match(params.user.permissions.role)
    .with(UserRole.ADMIN, () => [UserRole.ADMIN, UserRole.HR, UserRole.RECRUITER, UserRole.EMPLOYEE])
    .with(UserRole.HR, () => [UserRole.RECRUITER, UserRole.EMPLOYEE])
    .otherwise(() => []);

  return isIn(params.role, allowedRoles);
};
