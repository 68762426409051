import { type User } from "@prisma/client";
import { differenceInHours } from "date-fns";
import { type IncomingMessage } from "http";
import { isString } from "lodash";
import { nanoid } from "nanoid";
import { type GetServerSidePropsContext, type NextApiRequest, type NextApiResponse } from "next";
import { type Route } from "nextjs-routes";
import { type AsyncReturnType } from "type-fest";
import { config } from "~/config";
import { ApiAuthenticationError, ApiImpersonationError, PublicApiAuthenticationError } from "~/lib/api";
import { type AppContext } from "~/lib/context";
import tracer from "~/lib/datadog/tracing";
import { parseISO } from "~/lib/dates";
import { updateSegmentUser } from "~/lib/external/segment/server/client";
import {
  trackApiKeyAuthenticated,
  trackImpersonateCompany,
  trackImpersonateUser,
  trackStopImpersonateCompany,
  trackStopImpersonateUser,
  trackUserInteracted,
  trackUserMissingSession,
} from "~/lib/external/segment/server/events";
import { initContext } from "~/lib/init-context";
import { buildUser } from "~/lib/logger";
import { findAsyncInArray, fireAndForget } from "~/lib/utils";
import { AuthenticatedUserIncludes } from "~/services/auth/authenticated-user-includes";
import {
  fetchAuthenticatedUser,
  type fetchRequiredAuthenticatedUser,
  type NullableAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";
import { resolveImpersonatedUser } from "~/services/auth/user-impersonation/resolve-impersonated-user";
import { CompensationReviewScopeType } from "~/services/compensation-review/compensation-review-scope";
import { hasImpersonationAccess } from "~/services/impersonation/helper";
import { getActivePermissionsForUser } from "~/services/permissions/validate-permissions";
import { type PermissionsStatus } from "~/services/user/permissions/authentication-options";

export const SessionKey = {
  SESSION_HASH: "sessionHash",

  USER_ID: "userId",

  USER_HASH: "userHash",

  IMPERSONATED_COMPANY_ID: "companyId",
  IMPERSONATED_USER_ID: "impersonatedUserId",
};

export const getUserSessionKey = (userSessionHash: string) => `user-session:${userSessionHash}`;

export const signIn = async (req: IncomingMessage, user: Pick<User, "id">) => {
  const { hash: sessionHash } = await req.prisma.userSession.create({
    data: {
      hash: nanoid(16),
      userId: user.id,
    },
  });

  await saveSession(req, { sessionHash, userId: user.id });

  return sessionHash;
};

export const devSignIn = async (req: IncomingMessage) => {
  if (!config.app.isLocal || !config.dev.emailOverride) throw new Error("Forbidden");

  const devUser = await req.prisma.user.findUniqueOrThrow({ where: { email: config.dev.emailOverride } });

  await signIn(req, devUser);
};

export const saveSession = async (req: IncomingMessage, params: { sessionHash: string; userId: number }) => {
  req.session.set<string>(SessionKey.USER_ID, `${params.userId}`);
  req.session.set<string>(SessionKey.SESSION_HASH, `${params.sessionHash}`);

  await req.session.save();
};

export const impersonateUser = async (req: IncomingMessage, userId: number) => {
  req.session.set(SessionKey.IMPERSONATED_USER_ID, `${userId}`);

  await req.session.save();
  await trackImpersonateUser(req);
};

export const stopUserImpersonation = async (req: NextApiRequest) => {
  req.session.unset(SessionKey.IMPERSONATED_USER_ID);

  await req.session.save();
  await trackStopImpersonateUser(req);
};

export const impersonateCompany = async (req: IncomingMessage, { companyId }: { companyId: number }) => {
  req.session.set(SessionKey.IMPERSONATED_COMPANY_ID, `${companyId}`);
  req.session.unset(SessionKey.IMPERSONATED_USER_ID);

  await req.session.save();
  await trackImpersonateCompany(req);
};

export const stopCompanyImpersonation = async (req: NextApiRequest) => {
  req.session.unset(SessionKey.IMPERSONATED_USER_ID);
  req.session.unset(SessionKey.IMPERSONATED_COMPANY_ID);

  await req.session.save();
  await trackStopImpersonateCompany(req);
};

export const signOut = async (req: IncomingMessage) => {
  const userId = req.session.get<string>(SessionKey.USER_ID);
  const userIdInt = parseInt(userId ?? "");
  const sessionHash = req.session.get<string>(SessionKey.SESSION_HASH);

  if (!isNaN(userIdInt) && sessionHash) {
    await req.prisma.userSession.deleteMany({
      where: {
        AND: {
          hash: sessionHash,
          userId: userIdInt,
        },
      },
    });
  }

  if (sessionHash) {
    await req.redis.del(getUserSessionKey(sessionHash));
  }

  req.session.destroy();
};

export const validateSession = async (req: IncomingMessage, userId: string) => {
  const sessionHash = req.session.get<string>(SessionKey.SESSION_HASH);
  const { remember } = req;

  if (!sessionHash) {
    await signOut(req);
    return;
  }

  const session = await remember(getUserSessionKey(sessionHash), async () => {
    return req.prisma.userSession.findUnique({
      where: {
        hash_userId: {
          hash: sessionHash,
          userId: parseInt(userId),
        },
      },
    });
  });

  if (!session) {
    await signOut(req);
    await trackUserMissingSession(req, parseInt(userId));
  }
};

/*
 * Header passed through XHR requests to ensure the client & server impersonated companies are synchronised.
 * @see https://www.notion.so/figures-hr/Personio-API-keys-issue-b6d5aa1ccf0146969d9c0781b6162ed3
 */
export const ImpersonatedCompanyHeader = "X-Impersonated-Company-ID";

export type AuthenticationOptions = {
  optional?: boolean;
  apiKey?: (keyof (typeof config)["app"]["publicApiKey"])[];
  superAdmin?: boolean;
} & Partial<PermissionsStatus>;

export type AuthenticatedUser = AsyncReturnType<typeof fetchRequiredAuthenticatedUser>;

type GuardResult = {
  user: NullableAuthenticatedUser;
  impersonatingCompany?: boolean;
  impersonatingUser?: boolean;
  redirection?: Route;
};

export const guard = async (ctx: GetServerSidePropsContext, authenticationOptions: AuthenticationOptions = {}) => {
  try {
    return await _guard(ctx, authenticationOptions);
  } catch (authenticationError) {
    if (!authenticationOptions.optional) {
      throw authenticationError;
    }

    return { user: null };
  }
};

const _guard = async (
  ctx: GetServerSidePropsContext,
  authenticationOptions: AuthenticationOptions = {}
): Promise<GuardResult> => {
  const userId = ctx.req.session.get<string>(SessionKey.USER_ID);

  const targetUrl = ctx.resolvedUrl === "/index" ? "/" : ctx.resolvedUrl;

  if (!userId) {
    await initContext(ctx.req);

    ctx.req.user = null;
    return {
      user: null,
      ...(!authenticationOptions.optional && {
        redirection: {
          pathname: "/sign-in",
          query: {
            redirect: `${encodeURIComponent(targetUrl)}`,
            error: "no_session",
          },
        },
      }),
    };
  }

  let user = await fetchAuthenticatedUser(ctx.req, { userId: parseInt(userId) });
  let impersonatingUser: NullableAuthenticatedUser = null;
  const impersonatedUser = await resolveImpersonatedUser(ctx.req, {
    impersonatingUser: user,
    impersonatedUserId: ctx.req.session.get<string>(SessionKey.IMPERSONATED_USER_ID) ?? null,
  });

  if (impersonatedUser) {
    impersonatingUser = user;
    user = impersonatedUser;
  }

  if (!user) {
    await initContext(ctx.req);

    ctx.req.user = null;
    return {
      user: null,
      ...(!authenticationOptions.optional && {
        redirection: {
          pathname: "/sign-in",
          query: {
            redirect: `${encodeURIComponent(targetUrl)}`,
            error: "no_user",
          },
        },
      }),
    };
  }

  if (user.blockedAt) {
    await signOut(ctx.req);

    ctx.req.user = null;
    return {
      user: null,
      redirection: {
        pathname: "/sign-in",
        query: {
          error: "account_blocked",
        },
      },
    };
  }

  const companyIdParam = ctx.query["impersonate"] as string;

  if (user.isSuperAdmin && companyIdParam) {
    const hasValidImpersonationAccess = await hasImpersonationAccess(ctx.req, { companyId: parseInt(companyIdParam) });

    if (!hasValidImpersonationAccess) {
      return {
        user,
        redirection: { pathname: "/impersonation-forbidden" },
      };
    }

    ctx.req.session.set(SessionKey.IMPERSONATED_COMPANY_ID, companyIdParam);

    await ctx.req.session.save();
  }

  const companyId = ctx.req.session.get<string>(SessionKey.IMPERSONATED_COMPANY_ID);
  if (companyId) {
    Object.assign(user, {
      companyId: parseInt(companyId),
      company: await ctx.req.prisma.company.findUniqueOrThrow({
        where: { id: parseInt(companyId) },
        include: AuthenticatedUserIncludes["company"]["include"],
      }),
    });
  }

  populateCompensationReviewMetadata({ ctx });
  await initContext(ctx.req, user);

  if (authenticationOptions.superAdmin && !user.isSuperAdmin) {
    ctx.req.user = null;
    return {
      user: null,
      redirection: {
        pathname: ctx.req.headers.referer ?? "/sign-in",
        query: {
          ...(!ctx.req.headers.referer && { error: "forbidden" }),
        },
      } as Route,
    };
  }

  const permissionPredicates = Object.entries(authenticationOptions).filter(([permission]) =>
    permission.startsWith("can")
  );

  const invalidPermission = await findAsyncInArray(permissionPredicates, async ([permission, value]) => {
    const permissions = await getActivePermissionsForUser(ctx.req);
    return permissions[permission as keyof typeof permissions] !== value;
  });

  if (!!invalidPermission) {
    ctx.req.user = null;

    //need a better redirect -> do modal
    return {
      user: null,
      redirection: {
        pathname: ctx.req.headers.referer ?? "/?error=forbidden",
      } as Route,
    };
  }

  const impersonatingCompany = !!ctx.req.session.get<string>(SessionKey.IMPERSONATED_COMPANY_ID);

  await fireAndForget(updateUserLastActivity(ctx.req, user));

  initImpersonationContext(ctx.req, { user, impersonatingUser, impersonatingCompanyId: companyId ? +companyId : null });

  return {
    user,
    impersonatingCompany,
    impersonatingUser: !!impersonatingUser,
  };
};

type GuardApiResult = {
  user: NullableAuthenticatedUser;
};

function isAuthenticationValid(authorization: string | undefined, apiKey: AuthenticationOptions["apiKey"]) {
  if (!apiKey || !authorization) {
    return false;
  }

  const apiKeys = apiKey.map((key) => ({ apiKey: config["app"]["publicApiKey"][key], partner: key }));

  return apiKeys.find((item) => item.apiKey === authorization);
}

export const guardApi = async (
  req: NextApiRequest,
  res: NextApiResponse,
  authenticationOptions: AuthenticationOptions = {}
): Promise<GuardApiResult> => {
  try {
    return await _guardApi(req, res, authenticationOptions);
  } catch (authenticationError) {
    if (!authenticationOptions.optional) {
      throw authenticationError;
    }

    return { user: null };
  }
};

export const populateCompensationReviewMetadata = (params: {
  req?: NextApiRequest;
  ctx?: GetServerSidePropsContext;
}) => {
  if (params.ctx?.resolvedUrl.startsWith("/compensation-review/") && isString(params.ctx?.query?.campaignId)) {
    params.ctx.req.compensationReviewScope = {
      type: CompensationReviewScopeType.CAMPAIGN,
      id: +params.ctx.query.campaignId,
    };

    return;
  }

  if (!params.req?.url?.startsWith("/api/compensation-review")) {
    return;
  }

  if ("scope" in params.req.body) {
    params.req.compensationReviewScope = params.req.body.scope;
    return;
  }

  if ("campaignId" in params.req.body) {
    params.req.compensationReviewScope = {
      type: CompensationReviewScopeType.CAMPAIGN,
      id: params.req.body.campaignId,
    };
    return;
  }
};

const _guardApi = async (
  req: NextApiRequest,
  res: NextApiResponse,
  authenticationOptions: AuthenticationOptions = {}
): Promise<GuardApiResult> => {
  if (authenticationOptions.apiKey) {
    const result = isAuthenticationValid(req.headers.authorization, authenticationOptions.apiKey);

    if (!result) {
      throw new PublicApiAuthenticationError("Not authenticated");
    } else {
      await trackApiKeyAuthenticated(req, {
        ipAddress: req.ip,
        url: req.url as string,
        partner: result.partner,
      });

      await initContext(req);

      req.user = null;

      return { user: null };
    }
  }

  const userId = req.session.get<string>(SessionKey.USER_ID);

  if (!userId) {
    if (!authenticationOptions.optional) {
      throw new ApiAuthenticationError("Not authenticated");
    } else {
      await initContext(req);

      req.user = null;

      return { user: null };
    }
  }

  let user = await fetchAuthenticatedUser(req, { userId: parseInt(userId) });
  let impersonatingUser: NullableAuthenticatedUser = null;
  const impersonatedUser = await resolveImpersonatedUser(req, {
    impersonatingUser: user,
    impersonatedUserId: req.session.get<string>(SessionKey.IMPERSONATED_USER_ID) ?? null,
  });

  if (impersonatedUser) {
    impersonatingUser = user;
    user = impersonatedUser;
  }

  if (!user) {
    if (!authenticationOptions.optional) {
      throw new ApiAuthenticationError("Not authenticated");
    } else {
      await initContext(req);

      req.user = null;
      return { user: null };
    }
  }

  if (typeof window === "undefined") {
    tracer.setUser(buildUser(user));
  }

  if (user.blockedAt) {
    await signOut(req);

    throw new ApiAuthenticationError("Account blocked");
  }

  const companyId = req.session.get<string>(SessionKey.IMPERSONATED_COMPANY_ID);

  if (companyId) {
    const impersonatedCompanyId = req.headers[ImpersonatedCompanyHeader.toLowerCase()];

    if (impersonatedCompanyId && impersonatedCompanyId !== companyId) {
      throw new ApiImpersonationError("Impersonation Mismatch");
    }

    const hasValidImpersonationAccess = await hasImpersonationAccess(req, { companyId: +companyId });

    if (!hasValidImpersonationAccess) {
      await stopCompanyImpersonation(req);

      throw new ApiImpersonationError("Impersonation not allowed");
    }

    Object.assign(user, {
      companyId: parseInt(companyId),
      company: await req.prisma.company.findUniqueOrThrow({
        where: { id: parseInt(companyId) },
        include: AuthenticatedUserIncludes["company"]["include"],
      }),
    });
  }

  populateCompensationReviewMetadata({ req });
  await initContext(req, user);

  if (authenticationOptions.superAdmin && !user.isSuperAdmin) {
    throw new ApiAuthenticationError("Unauthorized access");
  }

  const permissionPredicates = Object.entries(authenticationOptions).filter(([permission]) =>
    permission.startsWith("can")
  );

  const invalidPermission = await findAsyncInArray(permissionPredicates, async ([permission, value]) => {
    const permissions = await getActivePermissionsForUser(req);
    return permissions[permission as keyof typeof permissions] !== value;
  });

  if (!!invalidPermission) {
    throw new ApiAuthenticationError("Unauthorized access");
  }

  await fireAndForget(updateUserLastActivity(req, user));

  initImpersonationContext(req, { user, impersonatingUser, impersonatingCompanyId: companyId ? +companyId : null });

  return { user };
};

export const initImpersonationContext = (
  ctx: AppContext,
  params: {
    user: AuthenticatedUser;
    impersonatingUser: NullableAuthenticatedUser;
    impersonatingCompanyId: number | null;
  }
) => {
  if (params.impersonatingCompanyId) {
    ctx.impersonatingCompanyId = params.impersonatingCompanyId;
  }

  if (params.impersonatingUser) {
    ctx.impersonation = {
      by: params.impersonatingUser,
      for: params.user,
    };
  }
};

const updateUserLastActivity = async (ctx: AppContext, user: AuthenticatedUser) => {
  const hasRecentActivity = user.lastActivityAt && differenceInHours(new Date(), parseISO(user.lastActivityAt)) < 12;

  if (!hasRecentActivity) {
    await ctx.prisma.user.update({
      data: {
        lastActivityAt: new Date(),
      },
      where: {
        id: user.id,
      },
    });

    await trackUserInteracted(ctx, user);

    if (!user.isSuperAdmin) {
      await updateSegmentUser(ctx, user, { impersonating: false });
    }
  }
};
