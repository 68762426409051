import { EmployeeLevel, type EmployeeUpdateStrategy, Gender } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { date, mixed, number, object, string } from "yup";
import { api } from "~/lib/api";
import { createMutation } from "~/lib/react-query";
import { updateEmployeeAggregate } from "~/services/employee-aggregate/update-employee-aggregate";

export const EmployeeAggregateUpdateSchema = object({
  updateReason: string().required(),
  updateStrategy: mixed<EmployeeUpdateStrategy>().oneOf(["NEW_VERSION", "PATCH"]).required(),
  currencyId: number().positive().optional().default(undefined),
  baseSalary: number().positive().optional().default(undefined),
  fixedBonus: number().min(0).optional().default(undefined),
  fixedBonusPercentage: number()
    .min(0)
    .optional()
    .default(undefined)
    .transform((value) => (value ? value / 100 : undefined)),
  onTargetBonus: number().min(0).optional().default(undefined),
  onTargetBonusPercentage: number()
    .min(0)
    .optional()
    .default(undefined)
    .transform((value) => (value ? value / 100 : undefined)),
  managerId: number().positive().nullable().optional().default(undefined),
  benchmarkJobId: number().positive().optional().default(undefined),
  benchmarkLevel: mixed<EmployeeLevel>().oneOf(Object.values(EmployeeLevel)).optional().default(undefined),
  benchmarkLocationId: number().positive().optional().default(undefined),
  performanceReviewRatingId: number().positive().nullable().optional().default(undefined),

  employeeNumber: string().optional().default(undefined),
  firstName: string().optional().default(undefined),
  lastName: string().optional().default(undefined),
  gender: mixed<Gender>()
    .oneOf([...Object.values(Gender)])
    .optional()
    .default(undefined),
  job: string().optional().default(undefined),
  level: string().optional().default(undefined),
  location: string().optional().default(undefined),
  fteDivider: number().min(0).max(1).nullable().optional().default(undefined),
  businessUnit: string().nullable().optional().default(undefined),
  hireDate: date().nullable().optional().default(undefined),
});

export const UpdateEmployeeAggregateInputSchema = object({
  externalEmployeeId: number().required(),
  values: EmployeeAggregateUpdateSchema,
});

const handler: NextApiHandler = async (req, res) => {
  const input = UpdateEmployeeAggregateInputSchema.validateSync(req.body, {
    abortEarly: false,
  });

  await updateEmployeeAggregate(req, {
    externalEmployeeId: input.externalEmployeeId,
    input: input.values,
  });

  // TODO keep it
  // await updateEmployeesStats(req, {
  //   companyId: employee.companyId,
  //   employeesIds: [employee.id],
  // });

  // TODO keep it
  // await synchroniseCompanySalaryRangeEmployees(req, { companyId: employee.companyId });

  res.status(HttpStatus.OK).json({
    message: "Employee updated",
  });
};

export default api(handler, {
  method: "POST",
  authentication: {
    canManageUsers: true,
  },
});

export const useUpdateEmployeeAggregateMutation = createMutation<
  typeof handler,
  typeof UpdateEmployeeAggregateInputSchema
>({
  path: "/api/employee-aggregate/update",
  schema: UpdateEmployeeAggregateInputSchema,
});
