import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import {
  type AvailableCultureAmpPerformanceReviewCycles,
  fetchAvailableCultureAmpPerformanceReviewCycles,
} from "~/services/performance-review/culture-amp/fetch-available-culture-amp-performance-review-cycles";

export const FetchAvailableCultureAmpPerformanceReviewCyclesSchema = object({
  companyId: number().required(),
  performanceReviewIntegrationSettingsId: number().required(),
});

export type FetchAvailableCultureAmpPerformanceReviewCyclesInput = YupOutputType<
  typeof FetchAvailableCultureAmpPerformanceReviewCyclesSchema
>;

const handler: NextApiHandler<AvailableCultureAmpPerformanceReviewCycles> = async (req, res) => {
  const input = await FetchAvailableCultureAmpPerformanceReviewCyclesSchema.validate(req.body, { abortEarly: false });

  const cycles = await fetchAvailableCultureAmpPerformanceReviewCycles(req, input);

  return res.status(HttpStatus.OK).json(cycles);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessIntegrations: true,
  },
});

export const FETCH_AVAILABLE_CULTURE_AMP_PERFORMANCE_REVIEW_CYCLES_QUERY_KEY: ApiRoute["pathname"] =
  "/api/performance-review/culture-amp/fetch-available-culture-amp-performance-review-cycles";

export const useFetchAvailableCultureAmpPerformanceReviewCyclesQuery = createQuery<
  typeof handler,
  typeof FetchAvailableCultureAmpPerformanceReviewCyclesSchema
>({
  path: FETCH_AVAILABLE_CULTURE_AMP_PERFORMANCE_REVIEW_CYCLES_QUERY_KEY,
  schema: FetchAvailableCultureAmpPerformanceReviewCyclesSchema,
});
