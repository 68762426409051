import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { array, number, object } from "yup";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY } from "~/pages/api/external-employee/count-external-employees-by-status";
import { FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY } from "~/pages/api/imported-employees/fetch-all-imported-employees";
import { unskipExternalEmployees } from "~/services/external-employee/unskip-external-employees";

const UnskipExternalEmployeesMappingSchema = object({
  externalEmployeeIds: array().of(number().required()).min(1).required(),
});

export type UnskipExternalEmployeesInput = YupOutputType<typeof UnskipExternalEmployeesMappingSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = UnskipExternalEmployeesMappingSchema.validateSync(req.body, { abortEarly: false });

  const count = await unskipExternalEmployees(req, input);

  res.status(HttpStatus.OK).json({ message: `${count} employees unskipped` });
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessIntegrations: true,
    canAccessRawData: true,
  },
});

export const useUnskipExternalEmployeesMutation = createMutation<
  typeof handler,
  typeof UnskipExternalEmployeesMappingSchema
>({
  path: "/api/external-employee/unskip-external-employees",
  schema: UnskipExternalEmployeesMappingSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY);
      await invalidateQuery(queryClient, COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY);
    },
  }),
});
