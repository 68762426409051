import { type ExternalEmployee } from "@prisma/client";
import { compact, type Falsey } from "lodash";
import { type AppContext } from "~/lib/context";
import { dangerouslyIgnorePrismaRestrictions } from "~/lib/prisma-tokens";
import { stripDiacritics } from "~/lib/utils";

export const normaliseExternalEmployeeColumns = async (
  ctx: AppContext,
  params: {
    externalEmployee: Pick<ExternalEmployee, "id"> &
      Partial<Pick<ExternalEmployee, "firstName" | "lastName" | "employeeNumber" | "externalId">>;
  }
) => {
  const normalisedColumns = await getNormalisedExternalEmployeeColumns(ctx, params);

  return ctx.prisma.externalEmployee.update({
    ...dangerouslyIgnorePrismaRestrictions(),
    where: { id: params.externalEmployee.id },
    data: { normalisedColumns },
    select: { id: true, normalisedColumns: true },
  });
};

export const getNormalisedExternalEmployeeColumns = async (
  ctx: AppContext,
  params: {
    externalEmployee: Pick<ExternalEmployee, "id"> &
      Partial<Pick<ExternalEmployee, "firstName" | "lastName" | "employeeNumber" | "externalId">>;
  }
) => {
  const originalExternalEmployee = await ctx.prisma.externalEmployee.findUniqueOrThrow({
    where: { id: params.externalEmployee.id },
    select: {
      firstName: true,
      lastName: true,
      employeeNumber: true,
      externalId: true,
      companyId: true,
    },
  });

  const integrationSettings = await ctx.prisma.integrationSettings.findFirst({
    where: {
      companyId: originalExternalEmployee.companyId,
      enabled: true,
    },
  });

  return makeNormalisedColumns([
    integrationSettings?.anonymous ? null : params.externalEmployee.firstName ?? originalExternalEmployee.firstName,
    integrationSettings?.anonymous ? null : params.externalEmployee.lastName ?? originalExternalEmployee.lastName,
    params.externalEmployee.employeeNumber ?? originalExternalEmployee.employeeNumber,
    params.externalEmployee.externalId ?? originalExternalEmployee.externalId,
  ]);
};

export const makeNormalisedColumns = (columnValues: (string | Falsey)[]) => {
  return compact(columnValues)
    .map((i) => stripDiacritics(i).trim())
    .join(" ");
};
