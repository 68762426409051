import { ArrowLeft, ArrowRight, ContentCopy, SkipNext } from "@mui/icons-material";
import { useState } from "react";
import { Button } from "~/components/ui/core/button";
import { LegacyHeading } from "~/components/ui/core/LegacyHeading";
import { LegacyText } from "~/components/ui/core/LegacyText";
import { useI18n } from "~/lib/i18n/use-i18n";
import { formatEmployeeName } from "~/services/employee";
import {
  type ExistingEmployeeSuggestion,
  type ExternalEmployeeRow,
} from "~/services/external-employee/external-employee-read";

type EmployeeReconciliationProps = {
  isSubmitting: boolean;
  onReconciliateClick: (suggestion: ExistingEmployeeSuggestion) => void;
  onSkipClick: () => void;
  existingEmployeeSuggestions: ExistingEmployeeSuggestion[];
  externalEmployee: ExternalEmployeeRow;
};

export const LegacyEmployeeReconciliation: React.FC<EmployeeReconciliationProps> = ({
  isSubmitting,
  onReconciliateClick,
  onSkipClick,
  existingEmployeeSuggestions,
  externalEmployee,
}) => {
  const { t } = useI18n();
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);

  const selectedSuggestion = existingEmployeeSuggestions[currentSuggestionIndex];

  const onClickPrevious = () => {
    if (currentSuggestionIndex > 0) {
      setCurrentSuggestionIndex(currentSuggestionIndex - 1);
    }
  };

  const onClickNext = () => {
    if (currentSuggestionIndex < existingEmployeeSuggestions.length - 1) {
      setCurrentSuggestionIndex(currentSuggestionIndex + 1);
    }
  };

  if (!selectedSuggestion) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="-mx-6 flex h-12 items-center justify-between bg-gray-100 px-3 pr-4">
        <LegacyHeading icon="warning" size="md">
          {t("components.ui.employee-form.employee-reconciliation.title")}
        </LegacyHeading>
      </div>
      <div className="my-5 grid grid-cols-1 gap-4">
        <div className="flex flex-col">
          <span className="text-sm text-gray-700">
            {existingEmployeeSuggestions.length === 1
              ? t("components.ui.employee-form.employee-reconciliation.same-employee")
              : t("components.ui.employee-form.employee-reconciliation.previously-imported")}
          </span>
          <div className="mt-5 flex justify-start">
            <Button
              variant="outlined"
              disabled={currentSuggestionIndex === 0}
              startIcon={<ArrowLeft />}
              onClick={onClickPrevious}
            />
            <LegacyText className="px-2">
              {currentSuggestionIndex + 1} / {existingEmployeeSuggestions.length}
            </LegacyText>
            <Button
              variant="outlined"
              disabled={currentSuggestionIndex === existingEmployeeSuggestions.length - 1}
              startIcon={<ArrowRight />}
              onClick={onClickNext}
            />
          </div>

          <div className="mt-5 flex text-sm">
            <div className="flex flex-col">
              <LegacyHeading size="xs">
                {t("components.ui.employee-form.employee-reconciliation.from-figures")}
              </LegacyHeading>
              <div className="mt-2 flex">
                <span className="w-24 text-gray-500">{t("components.ui.employee-form.employee")}</span>
                <span className="ml-2">{selectedSuggestion.employeeNumber}</span>
              </div>
              <div className="flex">
                <span className="w-24 text-gray-500">{t("common.name")}</span>
                <span className="ml-2">{formatEmployeeName(selectedSuggestion)}</span>
              </div>
              <div className="flex">
                <span className="w-24 text-gray-500">{t("common.job")}</span>
                <span className="ml-2">{selectedSuggestion.job.name}</span>
              </div>
              <div className="flex">
                <span className="w-24 text-gray-500">{t("common.location")}</span>
                <span className="ml-2">{selectedSuggestion.location.name}</span>
              </div>
            </div>
            {externalEmployee && (
              <div className="ml-6 flex flex-1 flex-col">
                <LegacyHeading size="xs">
                  {t("components.ui.employee-form.employee-reconciliation.from-source", {
                    source: externalEmployee.source,
                  })}
                </LegacyHeading>
                <div className="mt-2 flex">
                  <span className="w-24 text-gray-500">{t("components.ui.employee-form.employee")}</span>
                  <span className="ml-2">{externalEmployee.employeeNumber}</span>
                </div>
                <div className="flex">
                  <span className="w-24 text-gray-500">{t("common.name")}</span>
                  <span className="ml-2">
                    {externalEmployee.firstName} {externalEmployee.lastName}
                  </span>
                </div>
                <div className="flex">
                  <span className="w-24 text-gray-500">{t("common.job")}</span>
                  <span className="ml-2">{externalEmployee.job?.name}</span>
                </div>
                <div className="flex">
                  <span className="w-24 text-gray-500">{t("common.location")}</span>
                  <span className="ml-2">{externalEmployee.location?.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className="mt-5 flex items-center">
            <Button
              size="small"
              color="white"
              variant="contained"
              className="w-20"
              disabled={isSubmitting}
              startIcon={<SkipNext />}
              onClick={onSkipClick}
            >
              {t("common.no")}
            </Button>
            <Button
              size="small"
              variant="outlined"
              className="ml-2 w-20"
              disabled={isSubmitting}
              startIcon={<ContentCopy />}
              onClick={() => onReconciliateClick(selectedSuggestion)}
            >
              {t("common.yes")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
