import { type IntegrationSource } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { compact } from "lodash";
import { type NextApiHandler } from "next";
import { type AsyncReturnType } from "type-fest";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { formatAdditionalFieldValue } from "~/lib/additional-field";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { getAllAdditionalFields } from "~/services/additional-field/get-all-additional-fields";
import { fetchExternalEmployeeForForm } from "~/services/external-employee/external-employee-read";

export const FetchExternalEmployeeSchema = object({
  externalEmployeeId: number().required(),
}).noUnknown();
export type FetchExternalEmployeeInput = YupOutputType<typeof FetchExternalEmployeeSchema>;
export type FetchExternalEmployeeResponse = AsyncReturnType<typeof fetchExternalEmployeeForForm> & {
  additionalFields: {
    label: string;
    value: string | number;
    source: IntegrationSource | undefined;
  }[];
};

const handler: NextApiHandler<FetchExternalEmployeeResponse> = async (req, res) => {
  const input = FetchExternalEmployeeSchema.validateSync(req.body, { abortEarly: false });

  const externalEmployee = await fetchExternalEmployeeForForm(req, input.externalEmployeeId);
  const allAdditionalFields = await getAllAdditionalFields(req);

  const mappedAdditionalFields = compact(
    externalEmployee.additionalFieldValues.map((value) => {
      const field = allAdditionalFields.find((field) => field.id === value.additionalField.id);
      if (!field) return null;

      return {
        label: field.name,
        value: formatAdditionalFieldValue(value, field.nature),
        source: value.additionalField.integrationSettings?.source,
      };
    })
  );

  return res.status(HttpStatus.OK).json({ ...externalEmployee, additionalFields: mappedAdditionalFields });
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessRawData: true },
});

export const FETCH_EXTERNAL_EMPLOYEE_QUERY_KEY: ApiRoute["pathname"] = "/api/external-employee/fetch-external-employee";

export const useFetchExternalEmployeeQuery = createQuery<typeof handler, typeof FetchExternalEmployeeSchema>({
  path: FETCH_EXTERNAL_EMPLOYEE_QUERY_KEY,
  schema: FetchExternalEmployeeSchema,
});
