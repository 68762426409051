import { Clear } from "@mui/icons-material";
import { Divider, Stack } from "@mui/material";
import classNames from "classnames";
import { Button } from "~/components/ui/core/button";
import {
  ExternalEmployeesFilters,
  ExternalEmployeesOptionsFilter,
  useExternalEmployeesFilters,
} from "~/components/ui/core/external-employees-filters";
import { useI18n } from "~/lib/i18n/use-i18n";
import { type FetchEmployeeFilterOptionsResult } from "~/services/employee-filter";

type Props = {
  filterOptions: FetchEmployeeFilterOptionsResult;
  searchInput?: JSX.Element;
  className?: string;
  shallow?: boolean;
  withoutDivider?: boolean;
};

export const TableFilters: React.FC<Props> = ({
  filterOptions,
  searchInput,
  className,
  shallow,
  withoutDivider = false,
}) => {
  const { t } = useI18n();
  const filters = useExternalEmployeesFilters({ filterOptions, shallow });

  return (
    <Stack className={className}>
      <Stack direction="row" gap={3} className="px-2 py-4">
        {!!searchInput && searchInput}
        <ExternalEmployeesOptionsFilter filters={filters} />

        {!!filters.isFiltering && (
          <Button variant="contained" color="white" endIcon={<Clear fontSize="small" />} onClick={filters.clearFilters}>
            {t("components.core.table-filters.clear")}
          </Button>
        )}
      </Stack>

      {!!filters.selectedFilters.length && (
        <>
          {!withoutDivider && <Divider />}
          <Stack
            className={classNames({
              "pl-2": true,
              "py-4": !withoutDivider,
              "pb-4": withoutDivider,
            })}
          >
            <ExternalEmployeesFilters filterOptions={filterOptions} filters={filters} />
          </Stack>
        </>
      )}
    </Stack>
  );
};
