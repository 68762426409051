import { SalaryGridStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { logError } from "~/lib/logger";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import { computeMarketDataForBenchmarks } from "~/services/salary-bands/benchmark/compute-market-data-for-benchmarks";

export const updateSalaryRangesMarketData = async (
  ctx: AppContext,
  params: {
    companyId: number;
    salaryGridId?: number;
    salaryBandId?: number;
    salaryRangeIds?: number[];
    currencyId?: number;
  }
) => {
  const salaryRanges = await ctx.prisma.salaryRange.findMany({
    where: {
      ...(params.salaryRangeIds && {
        id: { in: params.salaryRangeIds },
      }),
      band: {
        ...(params.salaryBandId && {
          id: params.salaryBandId,
        }),
        grid: {
          companyId: params.companyId,
          status: { not: SalaryGridStatus.ARCHIVED },
          ...(params.salaryGridId && whereSalaryGridIs({ salaryGridId: params.salaryGridId })),
        },
      },
    },
    select: {
      id: true,
      inferredPercentileRank: true,
      targetPercentileRank: true,
      midpoint: true,
      level: {
        select: {
          benchmarkedLevels: {
            select: {
              level: true,
            },
          },
        },
      },
      band: {
        select: {
          measure: true,
          marketPositioning: true,
          currency: true,
          grid: {
            select: {
              company: {
                select: {
                  defaultCountryId: true,
                },
              },
            },
          },
          benchmarkedJobs: {
            select: {
              job: {
                select: {
                  id: true,
                  familyId: true,
                },
              },
            },
          },
          benchmarkedLocations: {
            select: {
              location: {
                select: { id: true },
              },
            },
          },
        },
      },
    },
  });

  const currency = params.currencyId
    ? await ctx.prisma.currency.findUniqueOrThrow({
        where: { id: params.currencyId },
      })
    : null;

  await mapSeries(salaryRanges, async (salaryRange) => {
    try {
      const marketData = await computeMarketDataForBenchmarks(ctx, {
        currency: params.currencyId && currency ? currency : salaryRange.band.currency,
        targetAmount: salaryRange.midpoint,
        benchmark: {
          jobIds: salaryRange.band.benchmarkedJobs.map((benchmarkedJob) => benchmarkedJob.job.id),
          locationIds: salaryRange.band.benchmarkedLocations.map(
            (benchmarkedLocation) => benchmarkedLocation.location.id
          ),
          levels: salaryRange.level.benchmarkedLevels.map((benchmarkedLevel) => benchmarkedLevel.level),
          marketPositioning: salaryRange.band.marketPositioning,
        },
        measure: salaryRange.band.measure,
      });

      await ctx.prisma.salaryRange.update({
        where: { id: salaryRange.id },
        data: {
          baseSalaryP10: marketData?.baseSalary?.p10 ?? null,
          baseSalaryP25: marketData?.baseSalary?.p25 ?? null,
          baseSalaryP50: marketData?.baseSalary?.p50 ?? null,
          baseSalaryP75: marketData?.baseSalary?.p75 ?? null,
          baseSalaryP90: marketData?.baseSalary?.p90 ?? null,
          baseSalaryDataQuality: marketData?.baseSalary.dataQuality ?? null,
          onTargetEarningsP10: marketData?.onTargetEarnings?.p10 ?? null,
          onTargetEarningsP25: marketData?.onTargetEarnings?.p25 ?? null,
          onTargetEarningsP50: marketData?.onTargetEarnings?.p50 ?? null,
          onTargetEarningsP75: marketData?.onTargetEarnings?.p75 ?? null,
          onTargetEarningsP90: marketData?.onTargetEarnings?.p90 ?? null,
          onTargetEarningsDataQuality: marketData?.onTargetEarnings?.dataQuality ?? null,
          distinctCompaniesCount: marketData?.distinctCompaniesCount ?? null,
          employeesCount: marketData?.employeesCount ?? null,
          inferredPercentileRank: marketData?.percentileRank ?? null,
        },
      });
    } catch (error) {
      logError(ctx, "[chore] Failed to update salary range market data", {
        salaryRangeId: salaryRange.id,
        error,
      });
    }
  });
};
