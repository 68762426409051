import { DataValidationFlagOrigin, EmployeeSource, type Prisma } from "@prisma/client";
import { ValidationError } from "yup";
import { ApiValidationError } from "~/lib/api";
import { type AppContext } from "~/lib/context";
import { normaliseExternalEmployeeColumns } from "~/lib/external/external-employee";
import { getRequiredUser } from "~/lib/get-required-user";
import { type YupInputType } from "~/lib/utils";
import { type EmployeeAggregateUpdateSchema } from "~/pages/api/employee-aggregate/update";
import { createEmployeeFlags } from "~/services/employee-data-validation-flag/data-flagging";
import {
  createHistoricalEmployee,
  createRemunerationItemsForExternalEmployee,
} from "~/services/employee/employee-create";
export type UpdateEmployeeAggregateInput = YupInputType<typeof EmployeeAggregateUpdateSchema>;

export const updateEmployeeAggregate = async (
  ctx: AppContext,
  params: {
    externalEmployeeId: number;
    input: UpdateEmployeeAggregateInput;
  }
) => {
  const user = getRequiredUser(ctx);

  const currentExternalEmployee = await ctx.prisma.externalEmployee.findFirstOrThrow({
    where: {
      id: params.externalEmployeeId,
    },
    include: {
      company: true,
      mappedEmployee: true,
      level: true,
      job: true,
      location: true,
    },
  });
  const companyId = currentExternalEmployee.companyId;

  await ensureInputIsValid(ctx, params.input, companyId);

  const externalEmployeePayload = buildExternalEmployeePayload(params.input, companyId);
  const externalEmployeeNeedsToBeUpdated = Object.keys(externalEmployeePayload).length > 0;
  if (externalEmployeeNeedsToBeUpdated) {
    await ctx.prisma.externalEmployee.update({
      where: { id: currentExternalEmployee.id },
      data: externalEmployeePayload,
    });

    if (params.input.managerId) {
      // (flag) eligible for pub/sub
      await setExternalEmployeeAsManager(ctx, params.input.managerId);
    }

    // (flag) eligible for pub/sub
    await normaliseExternalEmployeeColumns(ctx, {
      externalEmployee: { id: currentExternalEmployee.id },
    });
  }

  if (params.input.baseSalary !== undefined) {
    await createRemunerationItemsForExternalEmployee(
      ctx,
      { baseSalary: params.input.baseSalary },
      {
        companyId: currentExternalEmployee.companyId,
        source: EmployeeSource.MANUAL,
      },
      {
        externalEmployeeId: currentExternalEmployee.id,
        fixedBonus: params.input.fixedBonus,
        onTargetBonus: params.input.onTargetBonus,
        fixedBonusPercentage: params.input.fixedBonusPercentage,
        onTargetBonusPercentage: params.input.onTargetBonusPercentage,
      }
    );
  }

  const currentEmployee = currentExternalEmployee.mappedEmployee;
  if (!currentEmployee) {
    return;
  }

  const employeePayload = buildEmployeePayload(params.input);
  const employeeNeedsToBeUpdated = Object.keys(employeePayload).length > 0;
  if (!employeeNeedsToBeUpdated) {
    return;
  }

  if (params.input.updateStrategy === "NEW_VERSION") {
    // The HISTORICAL employee inherits the liveAt of the LIVE employee
    await createHistoricalEmployee(ctx, currentEmployee.id);
  }

  employeePayload["updateReason"] = params.input.updateReason;

  await ctx.prisma.employee.update({
    where: { id: currentEmployee.id },
    data: {
      ...employeePayload,
      // This new version of the employee is considered LIVE starting now
      liveAt: new Date(),
    },
  });

  // (flag) eligible for pub/sub
  await createEmployeeFlags(ctx, currentEmployee.id, user.companyId, DataValidationFlagOrigin.HRIS_SYNC);
};

const buildExternalEmployeePayload = (
  input: UpdateEmployeeAggregateInput,
  companyId: number
): Prisma.ExternalEmployeeUpdateInput => ({
  ...(input.managerId === null && { manager: { disconnect: true } }),
  ...(input.managerId && { manager: { connect: { id: input.managerId } } }),

  ...(input.currencyId && { currency: { connect: { id: input.currencyId } } }),

  ...(input.performanceReviewRatingId === null && {
    performanceReviewRating: { disconnect: true },
  }),
  ...(input.performanceReviewRatingId && {
    performanceReviewRating: { connect: { id: input.performanceReviewRatingId } },
  }),

  ...(input.employeeNumber && { employeeNumber: input.employeeNumber }),
  ...(input.firstName && { firstName: input.firstName }),
  ...(input.lastName && { lastName: input.lastName }),
  ...(input.gender && { gender: input.gender }),

  ...(input.fteDivider !== undefined && { fteDivider: input.fteDivider }),
  ...(input.businessUnit !== undefined && { businessUnit: input.businessUnit }),
  ...(input.hireDate !== undefined && { hireDate: input.hireDate }),

  ...(input.level && {
    level: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId,
            externalId: input.level,
          },
        },
        create: {
          name: input.level,
          externalId: input.level,
          company: { connect: { id: companyId } },
        },
      },
    },
  }),
  ...(input.level === "" && { level: { disconnect: true } }),

  ...(input.job && {
    job: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId,
            externalId: input.job,
          },
        },
        create: {
          name: input.job,
          externalId: input.job,
          company: { connect: { id: companyId } },
        },
      },
    },
  }),
  ...(input.job === "" && { job: { disconnect: true } }),

  ...(input.location && {
    location: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId,
            externalId: input.location,
          },
        },
        create: {
          name: input.location,
          externalId: input.location,
          company: { connect: { id: companyId } },
          autoMappingEnabled: false,
        },
      },
    },
  }),
  ...(input.location === "" && { location: { disconnect: true } }),
});

const buildEmployeePayload = (input: UpdateEmployeeAggregateInput): Prisma.EmployeeUpdateInput => ({
  ...(input.currencyId && { currency: { connect: { id: input.currencyId } } }),
  ...(input.baseSalary && { baseSalary: input.baseSalary }),
  ...((input.fixedBonus !== undefined || input.fixedBonusPercentage !== undefined) && {
    fixedBonus: input.fixedBonusPercentage !== undefined ? null : input.fixedBonus,
  }),
  ...((input.fixedBonus !== undefined || input.fixedBonusPercentage !== undefined) && {
    fixedBonusPercentage: input.fixedBonus !== undefined ? null : input.fixedBonusPercentage,
  }),
  ...((input.onTargetBonus !== undefined || input.onTargetBonusPercentage !== undefined) && {
    onTargetBonus: input.onTargetBonusPercentage !== undefined ? null : input.onTargetBonus,
  }),
  ...((input.onTargetBonus !== undefined || input.onTargetBonusPercentage !== undefined) && {
    onTargetBonusPercentage: input.onTargetBonus !== undefined ? null : input.onTargetBonusPercentage,
  }),
  ...(input.benchmarkJobId && { job: { connect: { id: input.benchmarkJobId } } }),
  ...(input.benchmarkLevel && { level: input.benchmarkLevel }),
  ...(input.benchmarkLocationId && { location: { connect: { id: input.benchmarkLocationId } } }),

  ...(input.firstName && { firstName: input.firstName }),
  ...(input.lastName && { lastName: input.lastName }),
  ...(input.gender && { gender: input.gender }),
  ...(input.hireDate !== undefined && { hireDate: input.hireDate }),

  ...(input.job && { externalJobTitle: input.job }),
});

const setExternalEmployeeAsManager = async (ctx: AppContext, externalEmployeeId: number) => {
  const permissions = await ctx.prisma.userPermissions.findUnique({
    where: { externalEmployeeId: externalEmployeeId },
    select: { id: true },
  });

  if (!permissions) {
    return;
  }

  await ctx.prisma.userPermissions.update({
    where: { id: permissions.id },
    data: { isManager: true },
  });
};

const ensureInputIsValid = async (ctx: AppContext, input: UpdateEmployeeAggregateInput, companyId: number) => {
  await ensureInputEntitiesValidity(ctx, input);

  await ensureEmployeeNumberValidityAndUnicity(ctx, input.employeeNumber, companyId);
};

type Guard = (ctx: AppContext, param: number) => Promise<object>;
const ensureInputEntitiesValidity = async (ctx: AppContext, input: UpdateEmployeeAggregateInput) => {
  const guards: [number | undefined | null, Guard][] = [
    [input.managerId, guardExternalEmployee],
    [input.currencyId, guardCurrency],
    [input.benchmarkLocationId, guardEmployeeLocation],
    [input.benchmarkJobId, guardJob],
    [input.performanceReviewRatingId, guardPerformanceReviewRating],
  ];

  try {
    await Promise.all(
      guards
        .filter(([param]) => !!param)
        .map(([param, guard]) => {
          return guard(ctx, param as number);
        })
    );
  } catch (e) {
    throw new ValidationError("a provided entity does not exist");
  }
};

const guardCurrency: Guard = async (ctx, currencyId) => {
  return ctx.prisma.currency.findUniqueOrThrow({ where: { id: currencyId } });
};

const guardExternalEmployee: Guard = async (ctx, externalEmployeeId) => {
  return ctx.prisma.externalEmployee.findUniqueOrThrow({ where: { id: externalEmployeeId } });
};

const guardEmployeeLocation: Guard = async (ctx, locationId) => {
  return ctx.prisma.employeeLocation.findUniqueOrThrow({ where: { id: locationId } });
};

const guardJob: Guard = async (ctx, jobId) => {
  return ctx.prisma.job.findUniqueOrThrow({ where: { id: jobId } });
};

const guardPerformanceReviewRating: Guard = async (ctx, performanceReviewRatingId) => {
  return ctx.prisma.performanceReviewRating.findUniqueOrThrow({ where: { id: performanceReviewRatingId } });
};

const ensureEmployeeNumberValidityAndUnicity = async (
  ctx: AppContext,
  employeeNumber: string | undefined,
  companyId: number
) => {
  if (employeeNumber === undefined) {
    return;
  }

  if (employeeNumber.length === 0) {
    throw new ApiValidationError("employee number can't be empty", {
      employeeNumber: ctx.t(
        "components.external-employee-drawer.feedback.validation-errors.employee-number-cant-be-empty"
      ),
    });
  }

  const externalEmployeesWithSameEmployeeNumber = await ctx.prisma.externalEmployee.findMany({
    where: { companyId, employeeNumber },
  });

  if (externalEmployeesWithSameEmployeeNumber.length > 0) {
    throw new ApiValidationError("employee number already exists", {
      employeeNumber: ctx.t("components.external-employee-drawer.feedback.validation-errors.employee-number-unicity"),
    });
  }
};
