import { throttle } from "lodash";
import { useEffect, useState } from "react";
import { isClient } from "~/lib/ssr";

type ScreenDimensions = {
  width: number;
  height: number;
  computed: boolean;
};

export const useScreenDimensions = (): ScreenDimensions => {
  const [dimensions, setDimensions] = useState<ScreenDimensions>({
    width: isClient ? window.innerWidth : 1200,
    height: isClient ? window.innerHeight : 800,
    computed: isClient ? true : false,
  });

  useEffect(() => {
    const onResize = throttle(() => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        computed: true,
      });
    }, 200);

    window.addEventListener("load", onResize, false);
    window.addEventListener("resize", onResize, false);

    return () => {
      window.removeEventListener("load", onResize, false);
      window.removeEventListener("resize", onResize, false);
    };
  }, []);

  return dimensions;
};
