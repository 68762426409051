import { ComparisonGeographicStrategy, type EmployeeLevel, EmployeeStatsComparisonScope } from "@prisma/client";
import { pick } from "lodash";
import { hasCountry, hasJob, hasLevel, hasLocation } from "~/services/employee";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetch-company-with-compensation-settings";
import { type EmployeeForStats } from "~/services/employee-stats/fetch-comparable-employees-for-stats";
import {
  countComparableCompanies,
  getComparedCountryIds,
  getComparedLocationIds,
} from "~/services/employee-stats/utils";
import { getMergedLevels } from "~/services/employee/employee-level";

//DOUBLE TYPED??

// This type is needed due to the recursive nature of the function,
// otherwise TS makes its return type to any
export type ComparableEmployeesWithinDataset = {
  companiesCount: number;
  comparedCountryIds: number[];
  comparedLocationIds: number[];
  comparedLevels: EmployeeLevel[];
  comparisonScope: EmployeeStatsComparisonScope;
  employees: EmployeeForStats[];
};

export const findComparableEmployees = (params: {
  company: CompanyWithCompensationSettings;
  employee: EmployeeForStats;
  marketEmployees: EmployeeForStats[];
}): ComparableEmployeesWithinDataset => {
  const comparedCountryIds = getComparedCountryIds(params.company, params.employee);
  const comparedLocationIds = getComparedLocationIds(params.company, params.employee);
  const comparedLevels = getMergedLevels(params.employee.level, {
    mergeAdvancedLevels: !params.company.useAdvancedLevels,
  }) as EmployeeLevel[];
  const comparisonParams = {
    comparedCountryIds,
    comparedLocationIds,
    comparedLevels,
    employeeCompanyId: params.company.id,
    jobId: params.employee.jobId,
    marketEmployees: params.marketEmployees,
  };

  return params.company.comparisonGeographicStrategy === ComparisonGeographicStrategy.ENTIRE_COUNTRY
    ? findComparableEmployeesForCountry(comparisonParams)
    : findComparableEmployeesForLocation(comparisonParams);
};

export type Comparison = ReturnType<typeof findComparableEmployees>;

const getLocationComparableEmployees = (params: {
  comparedLevels: EmployeeLevel[];
  comparedLocationIds: number[];
  jobId: EmployeeForStats["jobId"];
  marketEmployees: EmployeeForStats[];
}) => {
  return params.marketEmployees
    .filter(hasJob([params.jobId]))
    .filter(hasLevel(params.comparedLevels, []))
    .filter(hasLocation(params.comparedLocationIds));
};

const findComparableEmployeesForLocation = (params: {
  comparedCountryIds: number[];
  comparedLocationIds: number[];
  comparedLevels: EmployeeLevel[];
  employeeCompanyId: number;
  jobId: number;
  marketEmployees: EmployeeForStats[];
}) => {
  const employees = getLocationComparableEmployees(
    pick(params, ["comparedLevels", "comparedLocationIds", "jobId", "marketEmployees"])
  );

  const companiesCount = countComparableCompanies({ employees: employees });

  return {
    comparisonScope: EmployeeStatsComparisonScope.LOCATION,
    comparedCountryIds: params.comparedCountryIds,
    comparedLocationIds: params.comparedLocationIds,
    comparedLevels: params.comparedLevels,
    employees,
    companiesCount,
  };
};

const getCountryComparableEmployees = (params: {
  comparedCountryIds: number[];
  comparedLevels: EmployeeLevel[];
  jobId: EmployeeForStats["jobId"];
  marketEmployees: EmployeeForStats[];
}) => {
  return params.marketEmployees
    .filter(hasJob([params.jobId]))
    .filter(hasLevel(params.comparedLevels, []))
    .filter(hasCountry(params.comparedCountryIds));
};

const findComparableEmployeesForCountry = (params: {
  comparedCountryIds: number[];
  comparedLocationIds: number[];
  comparedLevels: EmployeeLevel[];
  employeeCompanyId: number;
  jobId: number;
  marketEmployees: EmployeeForStats[];
}) => {
  const employees = getCountryComparableEmployees(
    pick(params, ["comparedCountryIds", "comparedLevels", "jobId", "marketEmployees"])
  );
  const companiesCount = countComparableCompanies({ employees });

  return {
    comparisonScope: EmployeeStatsComparisonScope.COUNTRY,
    comparedLocationIds: params.comparedLocationIds,
    comparedCountryIds: params.comparedCountryIds,
    comparedLevels: params.comparedLevels,
    employees: employees,
    companiesCount: companiesCount,
  };
};
