import {
  type Company,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  type Prisma,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { createReadStream, statSync } from "fs";
import sizeOf from "image-size";
import { compact, isEmpty, isNil, isString, uniq } from "lodash";
import uniqid from "uniqid";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { traceBusinessService } from "~/lib/datadog/tracing";
import { makeNormalisedColumns } from "~/lib/external/external-employee";
import { buildHolidayAllowanceRemunerationItem } from "~/lib/hris/helpers/build-holiday-allowance-remuneration-item";
import { type StaticModels } from "~/lib/integration";
import { logDebug, logError, logInfo, logWarn } from "~/lib/logger";
import { pgProofRemunerationItems } from "~/lib/money";
import { dangerouslyIncludeSoftDeletedExternalEmployees } from "~/lib/prisma-restrictions/schemas/generate-external-employees-soft-delete-schema";
import { dangerouslyIncludeHistoricalExternalRemunerationItems } from "~/lib/prisma-restrictions/schemas/generate-external-remuneration-item-historical-schema";
import { createHistoricalEmployee } from "~/services/employee/employee-create";
import {
  computeDriftFields,
  getEmployeeUpdatePayload,
  requiresHistoricalVersion,
} from "~/services/employee/employee-drift";
import { isFromNetherlands } from "~/services/external-employee/is-from-netherlands";
import { transitionExternalEmployeeStatus } from "~/services/external-employee/status-helpers";
import { safeDeleteImage } from "~/services/image";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/sync-external-employees";

export const externalEmployeeJobSelectForSync = {
  id: true,
  name: true,
  skippedAt: true,
  mappedJobId: true,
  mappedJob: { select: { id: true } },
} satisfies Prisma.ExternalJobSelect;

export const externalEmployeeLevelSelectForSync = {
  id: true,
  name: true,
  skippedAt: true,
  mappedLevel: true,
} satisfies Prisma.ExternalLevelSelect;

export const externalEmployeeLocationSelectForSync = {
  id: true,
  skippedAt: true,
  name: true,
  country: { select: { id: true, alpha2: true } },
  mappedLocationId: true,
  mappedLocation: {
    select: {
      id: true,
      fallbackLocationId: true,
      country: { select: { id: true, alpha2: true } },
    },
  },
} satisfies Prisma.ExternalLocationSelect;

export const externalEmployeeSelectForSync = {
  id: true,
  firstName: true,
  lastName: true,
  email: true,
  birthDate: true,
  source: true,
  status: true,
  deletedAt: true,
  isFounder: true,
  hireDate: true,
  gender: true,
  currencyId: true,
  employeeNumber: true,
  mappedEmployeeId: true,
  companyId: true,
  managerExternalEmployeeId: true,
  externalId: true,
  driftFields: true,
  currency: true,
  fteDivider: true,
  picture: { select: { id: true, bucket: true, path: true } },
  mappedEmployee: {
    select: {
      id: true,
      firstName: true,
      lastName: true,
      birthDate: true,
      picture: { select: { id: true, bucket: true, path: true } },
      job: { select: { id: true } },
      location: {
        select: {
          id: true,
          country: { select: { id: true, alpha2: true } },
        },
      },
      currency: { select: { id: true } },
      user: { select: { id: true } },
    },
  },
  manager: { select: { id: true } },
  job: {
    select: externalEmployeeJobSelectForSync,
  },
  level: { select: externalEmployeeLevelSelectForSync },
  location: {
    select: externalEmployeeLocationSelectForSync,
  },
  remunerationItems: {
    select: {
      amount: true,
      asPercentage: true,
      status: true,
      date: true,
      nature: {
        select: {
          id: true,
          name: true,
          mappedType: true,
        },
      },
    },
  },
  businessUnit: true,
} satisfies Prisma.ExternalEmployeeSelect;

export type ExternalEmployeeForSync = Prisma.ExternalEmployeeGetPayload<{
  select: typeof externalEmployeeSelectForSync;
}>;

const uploadProfilePicture = async (ctx: AppContext, { picturePath }: { picturePath: string }) => {
  try {
    const { files } = ctx;

    const fileStats = statSync(picturePath);
    const { width, height } = sizeOf(picturePath) as { width: number; height: number };
    const path = `${config.files.prefixes.employeePictures}/${uniqid()}.png`;

    await files
      .putObject({
        Bucket: config.files.bucket,
        Key: `assets/${path}`,
        Body: createReadStream(picturePath),
        ContentType: "image/png",
        ContentLength: fileStats.size,
      })
      .promise();

    return { path, width, height, size: fileStats.size };
  } catch (error) {
    logWarn(ctx, "[sync] Error while copying employee profile picture", { error });

    return undefined;
  }
};

const handleEmployeeProfilePicture = async (
  ctx: AppContext,
  params: {
    picturePath: string | undefined;
    externalEmployee: ExternalEmployeeForSync | undefined;
    isIntegrationAnonymous: boolean | undefined;
  }
) => {
  if (!params.picturePath || params.isIntegrationAnonymous) {
    logInfo(ctx, "[sync] No profile picture to handle for employee", {
      companyId: params.externalEmployee?.companyId,
      externalEmployeeId: params.externalEmployee?.id,
      source: params.externalEmployee?.source,
    });
    return null;
  }

  if (params.externalEmployee?.picture?.id) {
    logInfo(ctx, "[sync] Deleting old profile picture for employee", {
      companyId: params.externalEmployee?.companyId,
      externalEmployeeId: params.externalEmployee?.id,
      source: params.externalEmployee?.source,
      picture: params.externalEmployee?.picture?.id,
    });

    await safeDeleteImage(ctx, params.externalEmployee.picture);
  }

  return uploadProfilePicture(ctx, { picturePath: params.picturePath });
};

const anonymiseExistingExternalEmployee = async (ctx: AppContext, externalEmployee: ExternalEmployeeForSync) => {
  const liveEmployee = externalEmployee.mappedEmployee;

  if (liveEmployee?.firstName || liveEmployee?.lastName || liveEmployee?.birthDate || liveEmployee?.picture) {
    await ctx.prisma.employee.update({
      where: { id: liveEmployee.id },
      data: {
        firstName: null,
        lastName: null,
        birthDate: null,
        picture: { disconnect: true },
      },
    });

    await safeDeleteImage(ctx, liveEmployee.picture);
  }

  if (externalEmployee.picture) {
    await ctx.prisma.externalEmployee.update({
      where: { id: externalEmployee.id },
      data: { picture: { disconnect: true } },
    });

    await safeDeleteImage(ctx, externalEmployee.picture);
  }
};

export const syncExternalEmployee = async (
  ctx: AppContext,
  externalEmployeeData: EmployeeData,
  params: {
    company: Company;
    staticModels: StaticModels;
    integrationSettings?: IntegrationSettingsForSync;
    fteFactor?: number;
    discriminatorKey?: "externalId" | "employeeNumber";
  }
): Promise<{ externalEmployee: ExternalEmployeeForSync; created: boolean }> => {
  return traceBusinessService(
    {
      tags: { "user.id": ctx.user?.id ?? "_cli_", "company.id": params.company.id },
      serviceName: "syncExternalEmployee",
    },

    async () => {
      const { discriminatorKey = "externalId" } = params;

      const {
        input,
        picturePath,
        remunerationItems,
        holidayAllowanceValue,
        additionalFieldValues = [],
      } = externalEmployeeData;

      // Ensure the location has a valid country
      if (!!input.location?.connectOrCreate?.create.country?.connect?.alpha2) {
        if (
          !params.staticModels.countries.find(
            ({ alpha2 }) => input.location?.connectOrCreate?.create.country?.connect?.alpha2 === alpha2
          )
        ) {
          delete input.location.connectOrCreate.create.country;
        }
      }
      // Upsert employee data. Record if it already existed
      const { externalEmployee, existingExternalEmployee } = await value(async () => {
        const existingExternalEmployees = await ctx.prisma.externalEmployee.findMany({
          ...dangerouslyIncludeSoftDeletedExternalEmployees(),
          where: {
            companyId: params.company.id,
            [discriminatorKey]: input[discriminatorKey],
          },
          select: externalEmployeeSelectForSync,
        });

        const existingExternalEmployee =
          existingExternalEmployees.find(({ source }) => source === input.source) ?? existingExternalEmployees[0];

        const profilePicture = await handleEmployeeProfilePicture(ctx, {
          picturePath,
          externalEmployee: existingExternalEmployee,
          isIntegrationAnonymous: params.integrationSettings?.anonymous,
        });

        if (profilePicture) {
          logInfo(ctx, "[sync] Uploaded new profile picture for employee", {
            companyId: existingExternalEmployee?.companyId,
            externalEmployeeId: existingExternalEmployee?.id,
            picture: profilePicture.path,
          });

          const { path, size, width, height } = profilePicture;

          input.picture = {
            create: {
              bucket: config.files.bucket,
              path,
              mimeType: "image/png",
              originalName: `${input.externalId}.png`,
              size: size,
              width,
              height,
            },
          };
        }

        const fteDivider = value(() => {
          if (!isString(externalEmployeeData.fte) || externalEmployeeData.ignoreFte) {
            return null;
          }

          const fteFactor = params.fteFactor ?? 1;
          const fteValue = parseFloat(externalEmployeeData.fte.replace(",", ".") ?? "1") / fteFactor;

          if (isNaN(fteValue) || fteValue >= 1 || fteValue === 0) {
            return null;
          }

          return fteValue;
        });

        if (!existingExternalEmployee) {
          const externalEmployee = await ctx.prisma.externalEmployee.create({
            data: {
              ...input,
              ...(!params.integrationSettings?.integrationLock?.fteDivider && { fteDivider }),
              ...(params.integrationSettings?.anonymous && {
                firstName: null,
                lastName: null,
                birthDate: null,
              }),
              normalisedColumns: makeNormalisedColumns([
                params.integrationSettings?.anonymous ? null : input.firstName,
                params.integrationSettings?.anonymous ? null : input.lastName,
                input.employeeNumber,
                input.externalId,
              ]),
            },
            select: externalEmployeeSelectForSync,
          });

          // If one of the job, location or level is skipped, immediately skip this external employee
          if (
            !!externalEmployee.job?.skippedAt ||
            !!externalEmployee.location?.skippedAt ||
            !!externalEmployee.level?.skippedAt
          ) {
            await ctx.prisma.externalEmployee.update({
              where: { id: externalEmployee.id },
              data: { status: ExternalEmployeeStatus.SKIPPED },
            });
          }

          return { externalEmployee };
        } else {
          const nextStatus = value(() => {
            // Restore deleted employees
            if (!!existingExternalEmployee.deletedAt)
              return {
                status: input.status,
                deletedAt: null,
              };
            // Skip employees when requested
            if (input.status === ExternalEmployeeStatus.SKIPPED) return { status: ExternalEmployeeStatus.SKIPPED };
            // Do not touch the status in other cases
            return { status: existingExternalEmployee.status };
          });

          const externalEmployee = await ctx.prisma.externalEmployee.update({
            ...dangerouslyIncludeSoftDeletedExternalEmployees(),
            where: { id: existingExternalEmployee.id },
            data: {
              source: input.source,
              picture: input.picture,
              externalId: input.externalId,
              employeeNumber: input.employeeNumber,
              firstName: input.firstName,
              lastName: input.lastName,
              email: input.email,
              birthDate: input.birthDate,
              hireDate: input.hireDate,
              company: input.company,
              currency: input.currency,
              location: input.location,
              job: input.job,
              level: input.level,
              businessUnit: input.businessUnit,

              ...nextStatus,

              ...(!params.integrationSettings?.integrationLock?.fteDivider && { fteDivider }),

              ...(!!input.gender && { gender: input.gender }),

              ...(params.integrationSettings?.anonymous && {
                firstName: null,
                lastName: null,
                birthDate: null,
                email: null,
              }),

              normalisedColumns: makeNormalisedColumns([
                params.integrationSettings?.anonymous ? null : input.firstName,
                params.integrationSettings?.anonymous ? null : input.lastName,
                input.employeeNumber,
                input.externalId,
              ]),
            },
            select: externalEmployeeSelectForSync,
          });

          // Some employees may have been imported before the integration was set as anonymous, so we check
          // if some of them need to be anonymised a posteriori
          if (params.integrationSettings?.anonymous) {
            await anonymiseExistingExternalEmployee(ctx, existingExternalEmployee);
          }

          return { externalEmployee, existingExternalEmployee };
        }
      });

      const isFixedBonusLocked = !!params.integrationSettings?.integrationLock?.fixedBonus;
      const isOnTargetBonusLocked = !!params.integrationSettings?.integrationLock?.onTargetBonus;

      if (existingExternalEmployee) {
        logInfo(ctx, "[sync] Deleting remuneration items for existing external employee", {
          companyId: params.company.id,
          externalEmployeeId: existingExternalEmployee.id,
          source: externalEmployee.source,
          isFixedBonusLocked,
          isOnTargetBonusLocked,
        });

        if (isFixedBonusLocked || isOnTargetBonusLocked) {
          await ctx.prisma.externalRemunerationItem.deleteMany({
            ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
            where: {
              employee: { id: existingExternalEmployee.id },
              nature: {
                mappedType: {
                  not: {
                    in: compact([
                      isFixedBonusLocked && ExternalRemunerationType.FIXED_BONUS,
                      isOnTargetBonusLocked && ExternalRemunerationType.VARIABLE_BONUS,
                    ]),
                  },
                },
              },
            },
          });
        } else {
          await ctx.prisma.externalRemunerationItem.deleteMany({
            ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
            where: { employee: { id: existingExternalEmployee.id } },
          });
        }
      }

      if (externalEmployee.status === ExternalEmployeeStatus.SKIPPED) {
        return { externalEmployee, created: false };
      }

      logInfo(ctx, "[sync] Synchronizing external employee", {
        companyId: params.company.id,
        externalEmployeeId: externalEmployee.id,
        source: externalEmployee.source,
      });

      const isNLEmployee = isFromNetherlands(externalEmployee);

      const allowedRemunerationItems = value(() => {
        if (!params.integrationSettings?.remunerationBlacklist) {
          return remunerationItems;
        }

        return remunerationItems
          .filter(({ nature }) => {
            if (!isOnTargetBonusLocked) {
              return true;
            }

            return nature.connectOrCreate?.create.mappedType !== ExternalRemunerationType.VARIABLE_BONUS;
          })
          .filter(({ nature }) => {
            const name = nature.connectOrCreate?.create.name;

            if (!name) {
              return true;
            }

            return !params.integrationSettings?.remunerationBlacklist.some((blacklistedItem) =>
              name.toLowerCase().includes(blacklistedItem.toLowerCase())
            );
          });
      });

      const holidayAllowanceRemunerationItem = buildHolidayAllowanceRemunerationItem(holidayAllowanceValue, {
        companyId: params.company.id,
        integrationSettings: params.integrationSettings,
        remunerationItems: allowedRemunerationItems,
        isNLEmployee,
      });

      const fteRemunerationItems = compact([...allowedRemunerationItems, holidayAllowanceRemunerationItem]).map(
        (item) => {
          if (item.nature.connectOrCreate?.create.mappedType !== ExternalRemunerationType.FIXED_SALARY) {
            return item;
          }

          if (externalEmployee.fteDivider === 0 || isNil(externalEmployee.fteDivider)) {
            return item;
          }

          item.amount = Math.round(item.amount / externalEmployee.fteDivider);

          return item;
        }
      );

      const finalRemunerationItems = await pgProofRemunerationItems(ctx, {
        externalEmployee,
        remunerationItems: fteRemunerationItems,
      });

      await mapSeries(finalRemunerationItems, async (item) => {
        logInfo(ctx, "[sync] Creating remuneration item", {
          companyId: params.company.id,
          externalEmployeeId: externalEmployee.id,
          itemId: item.externalId,
        });

        await ctx.prisma.externalRemunerationItem.deleteMany({
          ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
          where: {
            externalId: item.externalId,
            source: item.source,
            employeeId: externalEmployee.id,
          },
        });

        try {
          await ctx.prisma.externalRemunerationItem.create({
            data: {
              ...item,
              employee: { connect: { id: externalEmployee.id } },
            },
          });
        } catch (error) {
          logError(ctx, "[sync] Error while creating remuneration item", {
            error,
            item,
            externalEmployee: externalEmployee.id,
          });

          // TODO: Discuss this w/ the team
          // Do we really wanna fail?
          // Can't we sync all possible employees and somehow report the failing ones?
          // Using a flag or something.
          throw error;
        }
      });

      await mapSeries(additionalFieldValues, async (additionalFieldValuePayload) => {
        if (additionalFieldValuePayload.stringValue === null) return;

        logInfo(ctx, "[sync] Upserting additional field value", {
          companyId: params.company.id,
          externalEmployeeId: externalEmployee.id,
          additionalFieldValuePayload: additionalFieldValuePayload,
        });

        const additionalFieldValue = await ctx.prisma.additionalFieldValue.findUnique({
          where: {
            additionalFieldId_externalEmployeeId: {
              additionalFieldId: additionalFieldValuePayload.additionalField.connect.id,
              externalEmployeeId: externalEmployee.id,
            },
          },
          select: { id: true },
        });

        if (additionalFieldValue) {
          await ctx.prisma.additionalFieldValue.update({
            where: { id: additionalFieldValue.id },
            data: {
              stringValue: additionalFieldValuePayload.stringValue,
              dateValue: additionalFieldValuePayload.dateValue,
              numberValue: additionalFieldValuePayload.numberValue,
              percentageValue: additionalFieldValuePayload.percentageValue,
            },
          });
        } else {
          await ctx.prisma.additionalFieldValue.create({
            data: {
              externalEmployeeId: externalEmployee.id,
              additionalFieldId: additionalFieldValuePayload.additionalField.connect.id,
              companyId: additionalFieldValuePayload.company.connect.id,
              stringValue: additionalFieldValuePayload.stringValue,
              dateValue: additionalFieldValuePayload.dateValue,
              numberValue: additionalFieldValuePayload.numberValue,
              percentageValue: additionalFieldValuePayload.percentageValue,
            },
          });
        }
      });

      const isAlreadyMapped =
        existingExternalEmployee &&
        externalEmployee.mappedEmployeeId &&
        (externalEmployee.status === ExternalEmployeeStatus.MAPPED ||
          externalEmployee.status === ExternalEmployeeStatus.NEEDS_REMAPPING);

      if (isAlreadyMapped) {
        const newExternalEmployee = await ctx.prisma.externalEmployee.findUniqueOrThrow({
          where: { id: externalEmployee.id },
          select: externalEmployeeSelectForSync,
        });

        const updateEmployeePayload = getEmployeeUpdatePayload(ctx, existingExternalEmployee, newExternalEmployee);

        if (!isEmpty(updateEmployeePayload) && externalEmployee.mappedEmployeeId) {
          logDebug(ctx, `[drift] Auto-fixing fields for employee`, {
            updateEmployeePayload,
            employeeId: externalEmployee.mappedEmployeeId,
          });

          const shouldCreateHistoricalVersion = requiresHistoricalVersion(updateEmployeePayload);
          if (shouldCreateHistoricalVersion) {
            await createHistoricalEmployee(ctx, externalEmployee.mappedEmployeeId);
          }

          logInfo(ctx, "[employee] Update existing employee", {
            employeeId: externalEmployee.mappedEmployeeId,
            companyId: params.company.id,
            updatedFields: Object.keys(updateEmployeePayload),
            shouldCreateHistoricalVersion,
          });

          await ctx.prisma.employee.update({
            where: { id: externalEmployee.mappedEmployeeId },
            data: {
              ...updateEmployeePayload,
              updateReason: "Employee drift auto-fix",
              ...(shouldCreateHistoricalVersion && {
                // This new version of the employee is considered LIVE starting now
                liveAt: new Date(),
              }),
            },
          });
        }

        const newDrifts = computeDriftFields(existingExternalEmployee, newExternalEmployee);
        const driftFields = uniq([...existingExternalEmployee.driftFields, ...newDrifts]);

        await ctx.prisma.externalEmployee.update({
          where: { id: existingExternalEmployee.id },
          data: {
            driftFields,
            status: driftFields.length > 0 ? ExternalEmployeeStatus.NEEDS_REMAPPING : ExternalEmployeeStatus.MAPPED,
          },
        });
      } else {
        await transitionExternalEmployeeStatus(ctx, externalEmployee.id);
      }
      const reloadedExternalEmployee = await ctx.prisma.externalEmployee.findUniqueOrThrow({
        where: { id: externalEmployee.id },
        select: externalEmployeeSelectForSync,
      });
      return { externalEmployee: reloadedExternalEmployee, created: !existingExternalEmployee };
    }
  );
};

export const enrichRemunerationItems = async (
  ctx: AppContext,
  externalEmployeeData: EmployeeData,
  params: {
    companyId: number;
    integrationSettings?: IntegrationSettingsForSync;
    isNLEmployee: boolean;
  }
) => {
  // Exclude remuneration items with blacklisted terms
  const allowedRemunerationItems = value(() => {
    const liveRemunerationItems = externalEmployeeData.remunerationItems.filter(
      (item) => item.status === ExternalRemunerationStatus.LIVE
    );

    if (!params.integrationSettings?.remunerationBlacklist) {
      return liveRemunerationItems;
    }

    return liveRemunerationItems.filter(({ nature }) => {
      const name = nature.connectOrCreate?.create.name;

      if (!name) {
        return true;
      }

      return !params.integrationSettings?.remunerationBlacklist.some((blacklistedItem) =>
        name.toLowerCase().includes(blacklistedItem.toLowerCase())
      );
    });
  });

  const holidayAllowanceRemunerationItem = buildHolidayAllowanceRemunerationItem(
    externalEmployeeData.holidayAllowanceValue,
    {
      companyId: params.companyId,
      integrationSettings: params.integrationSettings,
      remunerationItems: allowedRemunerationItems,
      isNLEmployee: params.isNLEmployee,
    }
  );

  const fteRemunerationItems = compact([...allowedRemunerationItems, holidayAllowanceRemunerationItem]).map((item) => {
    if (item.nature.connectOrCreate?.create.mappedType !== ExternalRemunerationType.FIXED_SALARY) {
      return item;
    }

    item.amount = value(() => {
      const fte = getEmployeeFte(externalEmployeeData);

      return Math.round(item.amount / fte);
    });

    return item;
  });

  return fteRemunerationItems.map((item) => ({
    ...item,
    date: item.date ? new Date(item.date) : null,
  }));
};

export const getEmployeeFte = (externalEmployeeData: EmployeeData) => {
  if (isNil(externalEmployeeData.fte) || externalEmployeeData.ignoreFte) return 1;

  const fte = parseFloat(externalEmployeeData.fte ?? "1");

  if (isNaN(fte) || fte === 0) return 1;

  return fte;
};
