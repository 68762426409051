import {
  type EmployeeLevel,
  EmployeeStatus,
  Gender,
  type Prisma,
  type EmployeeLevel as PrismaEmployeeLevel,
} from "@prisma/client";
import { compact, isNil } from "lodash";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { roundTo } from "~/lib/math";
import { whereCompanyIsPartOfDataset } from "~/services/company";
import { generateEmployeeBucketHash } from "~/services/company-dashboard/generate-employee-bucket-hash";
import { getAllowedLevels } from "~/services/employee/employee-level";
import { getAllowedCountryIds } from "~/services/employee/employee-location";
import { getHeadcountRange } from "~/services/headcount";
import { getAllowedJobFamilyIds } from "~/services/job";
import {
  type GetMarketDataStatsEmployeesCompanyTagsOptions,
  type GetMarketDataStatsEmployeesOptions,
} from "~/services/market-data/get-market-data-stats";

export const getMarketDataEmployees = async (ctx: AppContext, options: GetMarketDataStatsEmployeesOptions = {}) => {
  const allowedEmployees = await fetchMarketDataEmployees(ctx, options);
  if (options.useTestData) {
    return allowedEmployees.map(anonymiseMarketDataEmployee);
  }

  return allowedEmployees;
};

export type MarketDataEmployee = AsyncReturnType<typeof fetchMarketDataEmployees>[number];

const fetchMarketDataEmployees = async (ctx: AppContext, options: GetMarketDataStatsEmployeesOptions) => {
  return ctx.prisma.employee.findMany({
    where: buildMarketDataStatsEmployeesWhere(ctx, options),
    select: marketDataEmployeeSelect,
  });
};

const marketDataEmployeeSelect = {
  id: true,
  companyId: true,
  locationId: true,
  currencyId: true,
  surveyId: true,
  jobId: true,
  gender: true,
  firstName: true,
  lastName: true,
  employeeNumber: true,
  company: { select: { tags: { select: { id: true } } } },
  level: true,
  baseSalary: true,
  fixedBonus: true,
  onTargetBonus: true,
  currency: { select: { euroExchangeRate: true, code: true } },
  survey: {
    select: {
      totalFunding: true,
      growthRate: true,
      lastFundingRound: true,
    },
  },
  job: {
    select: { name: true, familyId: true },
  },
  location: { select: { name: true, country: { select: { name: true, alpha2: true } } } },
} satisfies Prisma.EmployeeSelect;

const RefBaseSalaries: { [key in EmployeeLevel]: number } = {
  BEGINNER: 35_000,
  JUNIOR: 40_000,
  INTERMEDIATE: 45_000,
  SENIOR: 50_000,
  STAFF: 65_000,
  PRINCIPAL: 75_000,
  TEAM_LEAD: 60_000,
  MANAGER: 80_000,
  HEAD_OF: 100_000,
  DIRECTOR: 120_000,
  VP: 130_000,
  C_LEVEL: 140_000,
};

const getRandomNumberInRange = (params: { min: number; max: number }) => {
  const min = Math.ceil(params.min);
  const max = Math.floor(params.max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const anonymiseMarketDataEmployee = (employee: MarketDataEmployee): MarketDataEmployee => {
  // Base salaries are based on level and added a +/- 20% random variation
  const refBaseSalary = RefBaseSalaries[employee.level];
  const variation = getRandomNumberInRange({ min: -20, max: 20 }) / 100;
  const baseSalary = Math.round(refBaseSalary * (1 + variation) * 100);

  // 25% of employees have fixed bonuses between 100€ & 10000€
  const hasFixedBonus = getRandomNumberInRange({ min: 0, max: 100 }) <= 25;
  const fixedBonus = hasFixedBonus ? roundTo(getRandomNumberInRange({ min: 100, max: 10000 }), 100) * 100 : null;

  // 100% of sales have variable bonuses of 50% of their base salary with 80% realistic payout rate
  // 50% of marketing have 50% variable bonuses of 50% of their base salary with 80% realistic payout rate
  // Other job families don't have variable bonuses
  const variableBonusProbability = employee.job.familyId === 11 ? 100 : employee.job.familyId === 6 ? 50 : 0;
  const hasVariableBonus = getRandomNumberInRange({ min: 0, max: 100 }) <= variableBonusProbability;
  const onTargetBonus = hasVariableBonus ? baseSalary * 0.5 : null;

  // 60% of employees are male
  const gender = getRandomNumberInRange({ min: 0, max: 100 }) <= 60 ? Gender.MALE : Gender.FEMALE;

  return {
    ...employee,

    firstName: "Example",
    lastName: "Employee",

    employeeNumber: `${employee.id}`,

    baseSalary,
    onTargetBonus,
    fixedBonus,

    gender,
  };
};

const buildCompanyTagsWhere = (companyTagsInput: GetMarketDataStatsEmployeesCompanyTagsOptions) =>
  match(companyTagsInput.selectionMode)
    .with("AND", () => ({ company: { tags: { every: { id: { in: companyTagsInput.tagIds } } } } }))
    .with("OR", () => ({ company: { tags: { some: { id: { in: companyTagsInput.tagIds } } } } }))
    .with("NONE", () => ({ company: { tags: { none: { id: { in: companyTagsInput.tagIds } } } } }))
    .exhaustive();

const buildMarketDataStatsEmployeesWhere = (ctx: AppContext, options: GetMarketDataStatsEmployeesOptions) => {
  const { featureFlags, user } = ctx;
  const allowedJobFamilyIds = getAllowedJobFamilyIds(user);
  const allowedCountryIds = getAllowedCountryIds(user);
  const allowedLevels = getAllowedLevels(user, options.mergeAdvancedLevels).filter(
    (level) => level !== "MA0X"
  ) as PrismaEmployeeLevel[];
  const companySize = featureFlags.CAN_ACCESS_FIGURES_AI_V2
    ? getHeadcountRange(options.headcount)
    : options.companySize;

  return {
    company: whereCompanyIsPartOfDataset(user),
    status: EmployeeStatus.LIVE,
    employeeDataValidationFlags: { none: { isLive: true } },

    AND: compact([
      !isNil(options.externalEmployeeIds) && { externalEmployee: { id: { in: options.externalEmployeeIds } } },
      options.companyIds?.length && { companyId: { in: options.companyIds } },
      options.omitCompanyId && { companyId: { not: options.omitCompanyId } },
      options.fundingRounds && { survey: { lastFundingRound: { in: options.fundingRounds } } },
      companySize && companySize.min !== null && { survey: { employeesCount: { gte: companySize.min } } },
      companySize && companySize.max !== null && { survey: { employeesCount: { lte: companySize.max } } },
      options.growthRates && {
        OR: options.growthRates.map((growthRate) => ({
          AND: compact([
            growthRate.min !== null && { survey: { growthRate: { gte: growthRate.min } } },
            growthRate.max !== null && { survey: { growthRate: { lte: growthRate.max } } },
          ]),
        })),
      },
      options.labels && buildCompanyTagsWhere(options.labels),
      options.industries && buildCompanyTagsWhere(options.industries),
      options.levels?.length && { level: { in: options.levels } },
      allowedLevels.length && { level: { in: allowedLevels } },
      options.jobIds?.length && { job: { id: { in: options.jobIds } } },
      allowedJobFamilyIds?.length && { job: { familyId: { in: allowedJobFamilyIds } } },
      options.countryIds?.length && { location: { countryId: { in: options.countryIds } } },
      options.locationIds?.length && { location: { id: { in: options.locationIds } } },
      allowedCountryIds?.length && { location: { countryId: { in: allowedCountryIds } } },
      options.buckets?.length && { bucketHash: { in: options.buckets.map(generateEmployeeBucketHash) } },
    ]),
  } satisfies Prisma.EmployeeWhereInput;
};
