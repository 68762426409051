import { type AppContext } from "~/lib/context";
import { type PermissionsSchema, type UserPermissionsSchemaFilters } from "~/services/permissions/permissions-schema";

export const generateAccountPermissionsSchema = (ctx: AppContext, filters: UserPermissionsSchemaFilters) => {
  const { isAdmin, isHr, isSelf, ownedByUser, ownCompany, ownedByCompany } = filters;
  const isAdminOrHr = isAdmin || isHr;

  return {
    Company: {
      read: ownCompany,
      update: isAdminOrHr && ownCompany,
      delete: false,
    },

    User: {
      read: ownedByCompany,
      update: isAdmin && {
        OR: [isSelf, ownedByCompany],
      },
      delete: isAdmin && ownedByCompany,
    },

    UserPermissions: {
      read: ownedByCompany,
      update: isAdmin && ownedByCompany,
      delete: isAdmin && ownedByCompany,
    },

    FiltersPreset: {
      read: true,
      update: true,
      delete: true,
    },

    CompanyPermissions: {
      read: ownedByCompany,
      update: isAdmin && ownedByCompany,
      delete: isAdmin && ownedByCompany,
    },

    UserSession: {
      read: ownedByUser,
      update: false,
      delete: false,
    },

    UserAuthenticationToken: {
      read: true,
      update: true,
      delete: false,
    },

    UserFlags: {
      read: true,
      update: true,
      delete: false,
    },

    UserPermissionsPolicy: {
      read: ownedByCompany,
      update: isAdmin && ownedByCompany,
      delete: false,
    },
  } satisfies Partial<PermissionsSchema>;
};
