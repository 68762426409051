import { CompanySurveyStatus, CompanyTagCategory } from "@prisma/client";
import { partition } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { validateRequestAuthorised } from "~/lib/security";

export const fetchCompanySurveyWithTags = async (ctx: AppContext, input: { companyId: number }) => {
  const { companyId } = input;

  validateRequestAuthorised(ctx, { companyId });

  const survey = await ctx.prisma.companySurvey.findFirstOrThrow({
    where: {
      companyId,
      status: CompanySurveyStatus.LIVE,
    },
  });

  const companyWithTags = await ctx.prisma.company.findUnique({
    where: { id: companyId },
    select: {
      tags: {
        where: { category: { in: [CompanyTagCategory.INDUSTRY, CompanyTagCategory.REVENUE_MODEL] } },
      },
      defaultCountry: {
        select: { defaultCurrency: true },
      },
    },
  });

  const [industryTags, revenueModelTags] = partition(companyWithTags?.tags, (tag) => {
    return tag.category === CompanyTagCategory.INDUSTRY;
  });

  return {
    survey,
    industryTags,
    revenueModelTags,
    currency: companyWithTags?.defaultCountry.defaultCurrency,
  };
};

export type FetchCompanySurveyWithTagsResult = AsyncReturnType<typeof fetchCompanySurveyWithTags>;
