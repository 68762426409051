import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
export const getAdditionalMonthRulesForSync = async (ctx: AppContext) =>
  ctx.prisma.additionalMonthRule.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
    },
    select: {
      month: true,
      country: {
        select: {
          id: true,
        },
      },
    },
  });

type AdditionalMonthRulesForSync = AsyncReturnType<typeof getAdditionalMonthRulesForSync>;

export const getExternalLocationsForSync = async (ctx: AppContext, params: { countryIds: number[] }) =>
  ctx.prisma.externalLocation.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      countryId: {
        in: params.countryIds,
      },
    },
    select: {
      externalId: true,
      countryId: true,
    },
  });

type ExternalLocationsForSync = AsyncReturnType<typeof getExternalLocationsForSync>;

export const getNumberOfMonth = (params: {
  additionalMonthRules: AdditionalMonthRulesForSync;
  externalLocations: ExternalLocationsForSync;
  externalId?: string;
}) => {
  if (!params.externalId) {
    return 12;
  }

  const location = params.externalLocations.find(({ externalId }) => externalId === params.externalId);

  if (!location) {
    return 12;
  }

  const additionalMonthRule = params.additionalMonthRules.find(({ country }) => country.id === location.countryId);

  if (!additionalMonthRule) {
    return 12;
  }

  return additionalMonthRule.month;
};
