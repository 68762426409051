import { ComparisonGeographicStrategy, type Prisma } from "@prisma/client";
import { keyBy } from "lodash";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { assertNotNil } from "~/lib/utils";
import { getCapitalLocationsByCountryId } from "~/services/locations/location";
export const selectLocationForGeoStrategy = {
  id: true,
  name: true,
  fallbackLocationId: true,
  country: {
    select: {
      id: true,
      name: true,
    },
  },
} satisfies Prisma.EmployeeLocationSelect;

type EmployeeLocationForGeoStrategy = Prisma.EmployeeLocationGetPayload<{
  select: typeof selectLocationForGeoStrategy;
}>;

export const getGeographicStrategyLocationMapper = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: user.companyId },
    select: {
      comparisonGeographicStrategy: true,
      compareSpecificLocations: {
        select: {
          id: true,
        },
      },
    },
  });

  const countryCapitals = await getCapitalLocationsByCountryId(ctx);

  type MappedLocations = {
    locations: EmployeeLocationForGeoStrategy[];
    country?: EmployeeLocationForGeoStrategy["country"];
  };

  const employeeLocations = await ctx.prisma.employeeLocation.findMany({
    select: selectLocationForGeoStrategy,
  });

  const employeeLocationsById = keyBy(employeeLocations, (location) => location.id);

  return (location: EmployeeLocationForGeoStrategy) => {
    const singleLocation = (location?: EmployeeLocationForGeoStrategy) => {
      return { locations: [assertNotNil(location)] };
    };

    return match<ComparisonGeographicStrategy, MappedLocations>(company.comparisonGeographicStrategy)
      .with(ComparisonGeographicStrategy.COUNTRY_CAPITAL, () => {
        const countryCapitalId = countryCapitals[location.country.id];
        if (!countryCapitalId) {
          return singleLocation(location);
        }

        return singleLocation(employeeLocationsById[countryCapitalId]);
      })
      .with(ComparisonGeographicStrategy.SPECIFIC_LOCATION, () => {
        return {
          locations: company.compareSpecificLocations.map((location) =>
            assertNotNil(employeeLocationsById[location.id])
          ),
        };
      })
      .with(ComparisonGeographicStrategy.ENTIRE_COUNTRY, () => {
        const locations = employeeLocations.filter(
          (item) => item.country.id === location.country.id && !item.fallbackLocationId
        );

        return { locations, country: location.country };
      })
      .otherwise(() => {
        return singleLocation(location);
      });
  };
};
