import { Box, Card, FormControl, FormHelperText, InputAdornment, InputLabel, Stack } from "@mui/material";
import { Gender } from "@prisma/client";
import { isInteger, noop } from "lodash";
import React, { useEffect, useState } from "react";
import { match } from "ts-pattern";
import { PerformanceRatingTooltip } from "~/components/compensation-review/performance-rating-tooltip";
import { BaseField } from "~/components/external-employee/drawer/fields/base-field";
import { ExternalField } from "~/components/external-employee/drawer/fields/external-field";
import { PercentageField } from "~/components/external-employee/drawer/fields/percentage-field";
import { SourceDependentEditableField } from "~/components/external-employee/drawer/fields/source-dependent-editable-field";
import {
  Measurement,
  SwitchablePercentageMoneyField,
} from "~/components/external-employee/drawer/fields/switchable-percentage-money-field";
import { useEmployeeForm } from "~/components/external-employee/drawer/use-employee-form";
import { Mode, useEditMode } from "~/components/external-employee/use-edit-mode";
import { ExternalEmployeeSourceProvider } from "~/components/external-employee/use-external-employee-source";
import { JobSelector } from "~/components/ui/JobSelector";
import { Icon } from "~/components/ui/core/Icon";
import { Skeleton } from "~/components/ui/core/Skeleton";
import { CardFormControl } from "~/components/ui/core/card-form-control";
import { CardHeader } from "~/components/ui/core/card-header";
import { DatePicker } from "~/components/ui/core/date-picker";
import { CurrencySelector } from "~/components/ui/currency-selector";
import { ExpandedGenderSelector } from "~/components/ui/expanded-gender-selector";
import { ExternalEmployeeAutocomplete } from "~/components/ui/external-employee-autocomplete";
import { LevelSelector } from "~/components/ui/level-selector";
import { LocationSelector } from "~/components/ui/location-selector";
import { PerformanceReviewRatingSelector } from "~/components/ui/performance-review-rating-selector";
import { useApi } from "~/hooks/useApi";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { useMoney } from "~/hooks/useMoney";
import { useSession } from "~/hooks/useSession";
import { DateFormats, isValidParsableDate } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";
import { formatPercent, serializePercent } from "~/lib/math";
import { parseMoney } from "~/lib/money";
import { assertNotNil } from "~/lib/utils";
import { type ClassifyJobResponse } from "~/pages/api/classify-job";
import { type FetchExternalEmployeeResponse } from "~/pages/api/external-employee/fetch-external-employee";
import { formatLevel } from "~/services/employee/employee-level";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { formatGender } from "~/services/external-employee/gender";

type Props = {
  externalEmployee: FetchExternalEmployeeResponse;
};

export const Overview: React.FC = () => {
  const { externalEmployee } = useEmployeeForm();

  if (!externalEmployee) {
    return (
      <div className="pt-4">
        <SectionBasicsSkeleton />
      </div>
    );
  }

  return (
    <ExternalEmployeeSourceProvider source={externalEmployee.source}>
      <Stack gap={4} className="pt-4">
        <SectionBasics />
        <SectionWork />
        <SectionCompensation externalEmployee={externalEmployee} />
        <SectionBenchmark externalEmployee={externalEmployee} />
        <SectionPerformance externalEmployee={externalEmployee} />
        <SectionAdditionalFields externalEmployee={externalEmployee} />
      </Stack>
    </ExternalEmployeeSourceProvider>
  );
};

const Section: React.FC<React.PropsWithChildren<{ title: string; subtitle: string; actions?: JSX.Element }>> = ({
  title,
  subtitle,
  actions,
  children,
}) => {
  return (
    <Card className="w-full">
      <CardHeader title={title} description={subtitle} actions={actions} />
      <Stack className="pt-4" gap={4}>
        {children}
      </Stack>
    </Card>
  );
};

const SectionRow: React.FC<React.PropsWithChildren<{ label: string; description?: string }>> = ({
  label,
  description,
  children,
}) => {
  return <CardFormControl label={label} component="div" control={children} description={description} />;
};

const SectionBasicsSkeleton: React.FC = () => {
  const { t } = useI18n();

  return (
    <Section
      title={t("components.external-employee-drawer.overview.section.basics.title")}
      subtitle={t("components.external-employee-drawer.overview.section.basics.subtitle")}
    >
      <SectionRow label={t("components.external-employee-drawer.overview.label.name")}>
        <FormControl>
          <InputLabel>{t("components.external-employee-drawer.overview.label.firstname")}</InputLabel>
          <Skeleton width={208} height={40} />
        </FormControl>
        <FormControl>
          <InputLabel>{t("components.external-employee-drawer.overview.label.lastname")}</InputLabel>
          <Skeleton width={208} height={40} />
        </FormControl>
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.gender")}>
        <Skeleton width={208} height={40} />
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.employee-number")}>
        <Skeleton width={208} height={40} />
      </SectionRow>
    </Section>
  );
};

const SectionBasics: React.FC = () => {
  const { t } = useI18n();
  const { form } = useEmployeeForm();

  return (
    <Section
      title={t("components.external-employee-drawer.overview.section.basics.title")}
      subtitle={t("components.external-employee-drawer.overview.section.basics.subtitle")}
    >
      <SectionRow label={t("components.external-employee-drawer.overview.label.name")}>
        <SourceDependentEditableField
          nonEditableChildren={
            <ExternalField
              label={t("components.external-employee-drawer.overview.label.firstname")}
              value={form.values.firstName}
            />
          }
          editableChildren={
            <BaseField
              label={t("components.external-employee-drawer.overview.label.firstname")}
              value={form.values.firstName}
              error={!!form.errors.firstName}
              helperText={form.errors.firstName}
              onChange={(e) => {
                void form.updateValues({ firstName: e.target.value });
              }}
            />
          }
        />

        <SourceDependentEditableField
          nonEditableChildren={
            <ExternalField
              label={t("components.external-employee-drawer.overview.label.lastname")}
              value={form.values.lastName}
            />
          }
          editableChildren={
            <BaseField
              label={t("components.external-employee-drawer.overview.label.lastname")}
              error={!!form.errors.lastName}
              helperText={form.errors.lastName}
              value={form.values.lastName}
              onChange={(e) => {
                void form.updateValues({ lastName: e.target.value });
              }}
            />
          }
        />
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.gender")}>
        <SourceDependentEditableField
          nonEditableChildren={<ExternalField value={formatGender(t, form.values.gender ?? Gender.UNDISCLOSED)} />}
          editableChildren={
            <ExpandedGenderSelector
              value={form.values.gender ?? null}
              onChange={(e) => {
                void form.updateValues({ gender: e.target.value });
              }}
            />
          }
        />
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.employee-number")}>
        <SourceDependentEditableField
          nonEditableChildren={<ExternalField value={form.values.employeeNumber} />}
          editableChildren={
            <BaseField
              value={form.values.employeeNumber}
              error={!!form.errors.employeeNumber}
              helperText={form.errors.employeeNumber}
              onChange={(e) => {
                void form.updateValues({ employeeNumber: e.target.value });
              }}
            />
          }
        />
      </SectionRow>
    </Section>
  );
};

const SectionWork: React.FC = () => {
  const { t, formatDate } = useI18n();
  const { user } = useSession();
  const { form } = useEmployeeForm();
  const { CAN_ACCESS_BUSINESS_UNITS } = useFeatureFlags();

  return (
    <Section
      title={t("components.external-employee-drawer.overview.section.work.title")}
      subtitle={t("components.external-employee-drawer.overview.section.work.subtitle")}
    >
      <SectionRow label={t("components.external-employee-drawer.overview.label.role")}>
        <div>
          <SourceDependentEditableField
            nonEditableChildren={
              <ExternalField
                label={t("components.external-employee-drawer.overview.label.job-title")}
                value={form.values.job ?? undefined}
              />
            }
            editableChildren={
              <BaseField
                label={t("components.external-employee-drawer.overview.label.job-title")}
                value={form.values.job ?? undefined}
                onChange={(e) => {
                  void form.updateValues({ job: e.target.value });
                }}
              />
            }
          />
        </div>
        <div>
          <SourceDependentEditableField
            nonEditableChildren={
              <ExternalField
                label={t("components.external-employee-drawer.overview.label.level")}
                value={form.values.level ?? undefined}
              />
            }
            editableChildren={
              <BaseField
                label={t("components.external-employee-drawer.overview.label.level")}
                value={form.values.level ?? undefined}
                onChange={(e) => {
                  void form.updateValues({ level: e.target.value });
                }}
              />
            }
          />
        </div>
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.location")}>
        <SourceDependentEditableField
          nonEditableChildren={<ExternalField value={form.values.location ?? undefined} />}
          editableChildren={
            <BaseField
              value={form.values.location ?? undefined}
              onChange={(e) => {
                void form.updateValues({ location: e.target.value });
              }}
            />
          }
        />
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.reporting-to")}>
        <SourceDependentEditableField
          nonEditableChildren={
            <ExternalField value={form.values.manager ? formatExternalEmployeeName(form.values.manager) : undefined} />
          }
          editableChildren={
            <Box className="grow">
              <ExternalEmployeeAutocomplete
                value={form.values.manager ?? null}
                onChange={(value) => form.updateValues({ manager: value ?? null })}
                companyId={assertNotNil(user).companyId}
              />
            </Box>
          }
        />
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.fte-coeff")}>
        <SourceDependentEditableField
          nonEditableChildren={
            <ExternalField value={form.values.fteDivider ? formatPercent(form.values.fteDivider) : undefined} />
          }
          editableChildren={
            <BaseField
              value={form.values.fteDivider ? Math.floor(form.values.fteDivider * 100).toString() : undefined}
              error={!!form.errors.fteDivider}
              helperText={form.errors.fteDivider}
              onChange={(e) => {
                const val = e.target.value;
                void form.updateValues({ fteDivider: val === "" ? null : serializePercent(parseInt(val)) });
              }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
          }
        />
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.hire-date")}>
        <SourceDependentEditableField
          nonEditableChildren={
            <ExternalField
              value={
                form.values.hireDate
                  ? isValidParsableDate(form.values.hireDate)
                    ? formatDate(form.values.hireDate, DateFormats.FULL_DATE)
                    : form.values.hireDate.toString()
                  : undefined
              }
            />
          }
          editableChildren={
            <DatePicker
              value={form.values.hireDate ?? null}
              onChange={(value) => {
                void form.updateValues({ hireDate: value });
              }}
            />
          }
        />
      </SectionRow>
      {CAN_ACCESS_BUSINESS_UNITS && (
        <>
          <SectionRow label={t("components.external-employee-drawer.overview.label.business-unit")}>
            <SourceDependentEditableField
              nonEditableChildren={<ExternalField value={form.values.businessUnit ?? undefined} />}
              editableChildren={
                <BaseField
                  value={form.values.businessUnit ?? undefined}
                  onChange={(e) => {
                    const val = e.target.value;
                    void form.updateValues({ businessUnit: val === "" ? null : val });
                  }}
                />
              }
            />
          </SectionRow>
        </>
      )}
    </Section>
  );
};

const SectionCompensation: React.FC<Props> = ({ externalEmployee }) => {
  const { t } = useI18n();
  const { mode } = useEditMode();
  const [currency, setCurrency] = useState(externalEmployee.currency);
  const money = useMoney(currency);
  const { form } = useEmployeeForm();

  const [measurementFixedBonus, setMeasurementFixedBonus] = useState<Measurement>(
    form.values.fixedBonusPercentage !== undefined ? Measurement.PERCENTAGE : Measurement.CURRENCY
  );
  const [measurementOnTargetBonus, setMeasurementOnTargetBonus] = useState<Measurement>(
    form.values.onTargetBonusPercentage !== undefined ? Measurement.PERCENTAGE : Measurement.CURRENCY
  );

  const handleFixedBonusChange = (value: string, measurement: Measurement) => {
    match(measurement)
      .with(Measurement.CURRENCY, () => {
        const val = parseInt(value);
        if (value === "" || isInteger(val)) {
          void form.updateValues({ fixedBonus: isNaN(val) ? 0 : parseMoney(value), fixedBonusPercentage: undefined });
        }
      })
      .with(Measurement.PERCENTAGE, () => {
        void form.updateValues({
          fixedBonus: undefined,
          fixedBonusPercentage: value ? parseFloat(value) : undefined,
        });
      })
      .exhaustive();
  };

  const handleOnTargetBonusChange = (value: string, measurement: Measurement) => {
    match(measurement)
      .with(Measurement.CURRENCY, () => {
        const val = parseInt(value);
        if (value === "" || isInteger(val)) {
          void form.updateValues({
            onTargetBonus: isNaN(val) ? 0 : parseMoney(value),
            onTargetBonusPercentage: undefined,
          });
        }
      })
      .with(Measurement.PERCENTAGE, () => {
        void form.updateValues({
          onTargetBonus: undefined,
          onTargetBonusPercentage: value ? parseFloat(value) : undefined,
        });
      })
      .exhaustive();
  };

  return (
    <Section
      title={t("components.external-employee-drawer.overview.section.compensation.title")}
      subtitle={t("components.external-employee-drawer.overview.section.compensation.subtitle")}
      actions={
        <>
          {mode === Mode.EDIT && (
            <SourceDependentEditableField
              editableChildren={
                <CurrencySelector
                  value={currency}
                  formatLabel={(currency) => {
                    return (
                      <div className="flex items-center">
                        <span className="text-sm font-medium text-gray-700">{currency?.name}</span>
                        <Icon className="text-gray-500 " name="menu-down" />
                      </div>
                    );
                  }}
                  onChange={(event) => {
                    setCurrency(event.target.value);
                    form.updateValues({ currency: event.target.value });
                  }}
                />
              }
              nonEditableChildren={null}
            />
          )}
        </>
      }
    >
      <SectionRow label={t("components.external-employee-drawer.overview.label.base-salary")}>
        <SourceDependentEditableField
          nonEditableChildren={<ExternalField value={form.values.baseSalary} currency={currency} readonly={true} />}
          editableChildren={
            <BaseField
              value={form.values.baseSalary}
              currency={currency}
              error={!!form.errors.baseSalary}
              helperText={form.errors.baseSalary}
              onChange={(event) => {
                const val = parseInt(event.target.value);

                if (event.target.value === "" || isInteger(val)) {
                  void form.updateValues({ baseSalary: parseMoney(val) });
                }
              }}
            />
          }
        />
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.fixed-bonus")}>
        <SourceDependentEditableField
          nonEditableChildren={
            <>
              {(form.values.fixedBonus !== undefined || form.values.fixedBonusPercentage === undefined) && (
                <ExternalField value={form.values.fixedBonus} currency={currency} readonly={true} />
              )}
              {form.values.fixedBonusPercentage !== undefined && (
                <PercentageField value={form.values.fixedBonusPercentage} readonly={true} />
              )}
            </>
          }
          editableChildren={
            <SwitchablePercentageMoneyField
              currency={currency}
              amount={form.values.fixedBonus}
              percentage={form.values.fixedBonusPercentage}
              onChange={handleFixedBonusChange}
              onSelectChange={(measurement) => {
                setMeasurementFixedBonus(measurement);
              }}
            />
          }
        />

        {(measurementFixedBonus === Measurement.PERCENTAGE || mode === Mode.DISPLAY) &&
          form.values.fixedBonusPercentage !== undefined &&
          form.values.fixedBonus === undefined &&
          form.values.baseSalary !== undefined && (
            <FormHelperText sx={{ alignSelf: "center" }}>
              = {money.format((form.values.fixedBonusPercentage * form.values.baseSalary) / 100, { roundTo: 1 })}
            </FormHelperText>
          )}
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.variable-bonus")}>
        <SourceDependentEditableField
          nonEditableChildren={
            <>
              {(form.values.onTargetBonus !== undefined || form.values.onTargetBonusPercentage === undefined) && (
                <ExternalField value={form.values.onTargetBonus} currency={currency} readonly={true} />
              )}
              {form.values.onTargetBonusPercentage !== undefined && (
                <PercentageField value={form.values.onTargetBonusPercentage} readonly={true} />
              )}
            </>
          }
          editableChildren={
            <SwitchablePercentageMoneyField
              currency={currency}
              amount={form.values.onTargetBonus}
              percentage={form.values.onTargetBonusPercentage}
              onChange={handleOnTargetBonusChange}
              onSelectChange={(measurement) => {
                setMeasurementOnTargetBonus(measurement);
              }}
            />
          }
        />

        {(measurementOnTargetBonus === Measurement.PERCENTAGE || mode === Mode.DISPLAY) &&
          form.values.onTargetBonusPercentage !== undefined &&
          form.values.onTargetBonus === undefined &&
          form.values.baseSalary !== undefined && (
            <FormHelperText sx={{ alignSelf: "center" }}>
              = {money.format((form.values.onTargetBonusPercentage * form.values.baseSalary) / 100, { roundTo: 1 })}
            </FormHelperText>
          )}
      </SectionRow>
    </Section>
  );
};

const SectionBenchmark: React.FC<Props> = ({ externalEmployee }) => {
  const { t } = useI18n();
  const { form } = useEmployeeForm();
  const { apiFetch } = useApi();
  const { mode } = useEditMode();
  const { user } = useSession();

  const fetchJobsSuggestions = async (signal: AbortSignal, jobName: string) => {
    return apiFetch<ClassifyJobResponse>(`/api/classify-job`, {
      method: "GET",
      query: { job: jobName },
      signal,
    });
  };

  const [jobSuggestions, setJobSuggestions] = useState<ClassifyJobResponse | undefined>(undefined);

  useEffect(() => {
    if (!externalEmployee.job) {
      return;
    }

    const controller = new AbortController();
    fetchJobsSuggestions(controller.signal, externalEmployee.job.name).then(setJobSuggestions).catch(noop);

    return () => {
      controller.abort();
    };
  }, [externalEmployee.job]);

  return (
    <Section
      title={t("components.external-employee-drawer.overview.section.benchmark.title")}
      subtitle={t("components.external-employee-drawer.overview.section.benchmark.subtitle")}
    >
      <SectionRow label={t("components.external-employee-drawer.overview.label.benchmark-job-title")}>
        <Box className="grow">
          {mode === Mode.DISPLAY && (
            <BaseField fullWidth value={externalEmployee.job?.mappedJob?.name ?? undefined} readonly={true} />
          )}
          {mode === Mode.EDIT && (
            <JobSelector
              className="w-full"
              jobIds={form.values.benchmarkJobId ? [`${form.values.benchmarkJobId}`] : []}
              placeholder={t("components.ui.employee-form.select-a-job")}
              suggestions={jobSuggestions?.results ?? []}
              onChange={([jobId]) => {
                form.updateValues({ benchmarkJobId: jobId ? parseInt(jobId) : undefined });
              }}
              {...(externalEmployee.job && {
                jobRequest: {
                  externalJob: externalEmployee.job,
                },
              })}
            />
          )}
        </Box>
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.benchmark-level")}>
        {mode === Mode.DISPLAY && (
          <BaseField
            value={externalEmployee.level?.mappedLevel ? formatLevel(t, externalEmployee.level.mappedLevel) : undefined}
            readonly={true}
          />
        )}

        {mode === Mode.EDIT && (
          <Box className="grow">
            <LevelSelector
              dense={false}
              noFilter
              useAdvancedLevels={!!user?.company.useAdvancedLevels}
              placeholder={t("components.ui.employee-form.select-a-level")}
              value={form.values.benchmarkLevel ? [form.values.benchmarkLevel] : []}
              onChange={(event) => {
                const [level] = event.target.value;

                form.updateValues({ benchmarkLevel: level ?? undefined });
              }}
            />
          </Box>
        )}
      </SectionRow>
      <SectionRow label={t("components.external-employee-drawer.overview.label.benchmark-location")}>
        {mode === Mode.DISPLAY && (
          <BaseField value={externalEmployee.location?.mappedLocation?.name ?? undefined} readonly={true} />
        )}

        {mode === Mode.EDIT && (
          <Box className="grow">
            <LocationSelector
              dense={false}
              value={form.values.benchmarkLocation ? [form.values.benchmarkLocation] : []}
              onChange={(event) => {
                const [location] = event.target.value;
                form.updateValues({ benchmarkLocation: location ?? undefined });
              }}
              hideFallbackedLocation={false}
            />
          </Box>
        )}
      </SectionRow>
    </Section>
  );
};

const SectionPerformance: React.FC<Props> = ({ externalEmployee }) => {
  const { t } = useI18n();
  const { form } = useEmployeeForm();
  const { mode } = useEditMode();

  if (!externalEmployee.performanceReviewRating) {
    return null;
  }

  return (
    <Section
      title={t("components.external-employee-drawer.overview.section.performance.title")}
      subtitle={t("components.external-employee-drawer.overview.section.performance.subtitle")}
    >
      {mode === Mode.DISPLAY && (
        <div>
          <PerformanceRatingTooltip performanceRating={externalEmployee.performanceReviewRating} />
        </div>
      )}

      {mode === Mode.EDIT && (
        <PerformanceReviewRatingSelector
          className="max-w-lg"
          size="small"
          companyId={externalEmployee.companyId}
          value={form.values.performanceReviewRatingId}
          onChange={(performanceReviewRatingId) => {
            form.updateValues({ performanceReviewRatingId: performanceReviewRatingId ?? null });
          }}
        />
      )}
    </Section>
  );
};

const SectionAdditionalFields: React.FC<Props> = ({ externalEmployee }) => {
  const { t } = useI18n();

  if (externalEmployee.additionalFields.length === 0) {
    return null;
  }

  return (
    <Section
      title={t("components.external-employee-drawer.overview.section.additional-fields.title")}
      subtitle={t("components.external-employee-drawer.overview.section.additional-fields.subtitle")}
    >
      {externalEmployee.additionalFields.map((field) => {
        const extField = <ExternalField value={field.value} />;

        return (
          <SectionRow key={field.label} label={field.label}>
            {field.source ? (
              <ExternalEmployeeSourceProvider source={field.source}>{extField}</ExternalEmployeeSourceProvider>
            ) : (
              <>{extField}</>
            )}
          </SectionRow>
        );
      })}
    </Section>
  );
};
