import { ArrowLeft, ArrowRight, ContentCopy, SkipNext } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { HeaderAlert } from "~/components/external-employee/header-alert";
import { Button } from "~/components/ui/core/button";
import { DateFormats } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";
import { type FetchExternalEmployeeResponse } from "~/pages/api/external-employee/fetch-external-employee";
import {
  type FetchReconciliationSuggestionsResponse,
  useFetchReconciliationSuggestionsQuery,
} from "~/pages/api/external-employee/fetch-reconciliation-suggestions";
import { useReconciliateExternalEmployeeMutation } from "~/pages/api/external-employee/reconciliate";
import { formatEmployeeName } from "~/services/employee";

type SuggestedEmployee = FetchReconciliationSuggestionsResponse["employees"][number];

type Props = {
  externalEmployee: FetchExternalEmployeeResponse;
};

export const ReconciliationAlert: React.FC<Props> = ({ externalEmployee }) => {
  const [open, setOpen] = useState(true);

  const { data: suggestions, isLoading } = useFetchReconciliationSuggestionsQuery({
    input: { externalEmployeeId: externalEmployee.id },
  });

  const reconciliateExternalEmployee = useReconciliateExternalEmployeeMutation();
  const onReconciliateClick = async (suggestion: SuggestedEmployee) => {
    await reconciliateExternalEmployee.mutateAsync({
      externalEmployeeId: externalEmployee.id,
      employeeId: suggestion.id,
    });
    setOpen(false);
  };

  const hasData = !isLoading && !!suggestions;

  return (
    <HeaderAlert visible={open && hasData}>
      {hasData && (
        <EmployeeReconciliation
          employeeSuggestions={suggestions.employees}
          externalEmployee={externalEmployee}
          isSubmitting={reconciliateExternalEmployee.isLoading}
          onReconciliateClick={onReconciliateClick}
          onSkipClick={() => {
            // TODO @florian: https://github.com/figures-hr/figures/pull/5048#discussion_r1703865995
            setOpen(false);
          }}
        />
      )}
    </HeaderAlert>
  );
};

type EmployeeReconciliationProps = {
  isSubmitting: boolean;
  onReconciliateClick: (suggestion: SuggestedEmployee) => void;
  onSkipClick: () => void;
  employeeSuggestions: SuggestedEmployee[];
  externalEmployee: FetchExternalEmployeeResponse;
};

const EmployeeReconciliation: React.FC<EmployeeReconciliationProps> = ({
  isSubmitting,
  onReconciliateClick,
  onSkipClick,
  employeeSuggestions,
  externalEmployee,
}) => {
  const { t, formatDate } = useI18n();
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);

  const selectedSuggestion = employeeSuggestions[currentSuggestionIndex];

  const onClickPrevious = () => {
    if (currentSuggestionIndex > 0) {
      setCurrentSuggestionIndex(currentSuggestionIndex - 1);
    }
  };

  const onClickNext = () => {
    if (currentSuggestionIndex < employeeSuggestions.length - 1) {
      setCurrentSuggestionIndex(currentSuggestionIndex + 1);
    }
  };

  if (!selectedSuggestion) {
    return null;
  }

  return (
    <div>
      <Typography variant="h2" component="h2">
        {t("components.ui.employee-form.employee-reconciliation.title")}
      </Typography>
      <div className="my-4">
        <div className="flex flex-col">
          <Typography>
            {employeeSuggestions.length === 1
              ? t("components.ui.employee-form.employee-reconciliation.same-employee")
              : t("components.ui.employee-form.employee-reconciliation.previously-imported")}
          </Typography>
          <Stack direction="row" className="mt-5 items-center justify-start" gap={2}>
            <Button
              variant="outlined"
              className="w-10 min-w-fit px-0 pl-2"
              disabled={currentSuggestionIndex === 0}
              startIcon={<ArrowLeft />}
              onClick={onClickPrevious}
            />
            <Typography className="inline-block w-16 text-center">
              {currentSuggestionIndex + 1} / {employeeSuggestions.length}
            </Typography>
            <Button
              variant="outlined"
              className="w-10 min-w-fit px-0 pl-2"
              disabled={currentSuggestionIndex === employeeSuggestions.length - 1}
              startIcon={<ArrowRight />}
              onClick={onClickNext}
            />
          </Stack>

          <div className="mt-5 flex text-sm">
            <div className="flex flex-col">
              <Typography variant="subtitle1">
                {t("components.ui.employee-form.employee-reconciliation.from-figures")}
              </Typography>
              <div className="mt-2 flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("components.ui.employee-form.employee")}
                </Typography>
                <span className="ml-2">{selectedSuggestion.employeeNumber}</span>
              </div>
              <div className="flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("common.name")}
                </Typography>
                <span className="ml-2">{formatEmployeeName(selectedSuggestion)}</span>
              </div>
              <div className="flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("common.job")}
                </Typography>
                <span className="ml-2">{selectedSuggestion.job.name}</span>
              </div>
              <div className="flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("common.location")}
                </Typography>
                <span className="ml-2">{selectedSuggestion.location.name}</span>
              </div>
              <div className="flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("components.external-employee-drawer.overview.label.hire-date")}
                </Typography>
                <span className="ml-2">
                  {selectedSuggestion.hireDate ? formatDate(selectedSuggestion.hireDate, DateFormats.FULL_DATE) : null}
                </span>
              </div>
            </div>
            <div className="ml-6 flex flex-1 flex-col">
              <Typography variant="subtitle1">
                {t("components.ui.employee-form.employee-reconciliation.from-source", {
                  source: externalEmployee.source,
                })}
              </Typography>
              <div className="mt-2 flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("components.ui.employee-form.employee")}
                </Typography>
                <span className="ml-2">{externalEmployee.employeeNumber}</span>
              </div>
              <div className="flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("common.name")}
                </Typography>
                <span className="ml-2">
                  {externalEmployee.firstName} {externalEmployee.lastName}
                </span>
              </div>
              <div className="flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("common.job")}
                </Typography>
                <span className="ml-2">{externalEmployee.job?.name}</span>
              </div>
              <div className="flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("common.location")}
                </Typography>
                <span className="ml-2">{externalEmployee.location?.name}</span>
              </div>
              <div className="flex">
                <Typography variant="subtitle2" className="w-24 text-gray-500">
                  {t("components.external-employee-drawer.overview.label.hire-date")}
                </Typography>
                <span className="ml-2">
                  {externalEmployee.hireDate ? formatDate(externalEmployee.hireDate, DateFormats.FULL_DATE) : null}
                </span>
              </div>
            </div>
          </div>

          <Stack direction="row" className="mt-5 items-center" gap={2}>
            <Button
              size="small"
              color="white"
              variant="contained"
              disabled={isSubmitting}
              startIcon={<SkipNext />}
              onClick={onSkipClick}
            >
              {t("components.ui.employee-form.employee-reconciliation.no-different-employees")}
            </Button>
            <Button
              size="small"
              color="white"
              variant="contained"
              isLoading={isSubmitting}
              startIcon={<ContentCopy />}
              onClick={() => onReconciliateClick(selectedSuggestion)}
            >
              {t("components.ui.employee-form.employee-reconciliation.yes-same-employee")}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};
