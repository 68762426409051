import { EmployeeLevel, Gender } from "@prisma/client";
import { boolean, date, mixed, number, object, string } from "yup";
import { parseMoney } from "~/lib/money";

export const BaseEmployeeSchema = object({
  firstName: string().nullable(),
  lastName: string().nullable(),
  gender: mixed<Gender>()
    .oneOf([null, ...Object.values(Gender)])
    .defined()
    .nullable(),
  location: object({
    id: number().required(),
  })
    .nullable()
    .required(),
  job: object({
    id: number().required(),
  })
    .nullable()
    .required(),
  level: mixed<EmployeeLevel>()
    .oneOf([null, ...Object.values(EmployeeLevel)])
    .required(),
  externalLevel: string().nullable(),
  currency: object({
    id: number().required(),
  })
    .nullable()
    .required(),
  baseSalary: number()
    .positive()
    .required()
    .transform((value) => {
      return parseMoney(value);
    }),
  fixedBonusPercentage: number()
    .positive()
    .nullable()
    .transform((value) => (value !== null ? value / 100 : null)),
  fixedBonus: number()
    .positive()
    .nullable()
    .transform((value) => {
      return value ? parseMoney(value) : null;
    }),
  onTargetBonusPercentage: number()
    .positive()
    .nullable()
    .transform((value) => (value !== null ? value / 100 : null)),
  onTargetBonus: number()
    .positive()
    .nullable()
    .transform((value) => {
      return value ? parseMoney(value) : null;
    }),
  isFounder: boolean().required(),
  pictureId: number().nullable(),
  hireDate: date().nullable(),
  birthDate: date().nullable(),
  externalJobTitle: string().nullable(),
});

export const EmployeeSchema = BaseEmployeeSchema.concat(
  object({
    employeeNumber: string().required(),
  })
);

export const CreateEmployeeSchema = BaseEmployeeSchema.concat(
  object({
    employeeNumber: string().nullable(),
  })
);
