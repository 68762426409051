import {
  Chip,
  FormControlLabel,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { CompanyType, OnboardingStep } from "@prisma/client";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { FixedSizeList } from "react-window";
import { Icon } from "~/components/ui/core/Icon";
import { Image } from "~/components/ui/core/Image";
import { LegacyHeading } from "~/components/ui/core/LegacyHeading";
import { LoadingIndicator } from "~/components/ui/core/LoadingIndicator";
import { SidePanel } from "~/components/ui/core/SidePanel";
import { TextInput } from "~/components/ui/core/TextInput";
import { useListCompaniesQuery } from "~/hooks/useQueries";
import { useImpersonateCompanyMutation } from "~/pages/api/auth/company-impersonation/impersonate-company";
import { publicUrl } from "~/services/image";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const FilterMode = {
  ALL: "ALL",
  ONBOARDING: "ONBOARDING",
  VC: "VC",
  EXCLUDED: "EXCLUDED",
} as const;

type FilterMode = (typeof FilterMode)[keyof typeof FilterMode];

export const ImpersonatePanel: React.FC<Props> = (props) => {
  const [search, setSearch] = useState("");
  const [listHeight, setListHeight] = useState(400);
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.ALL);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { data: companiesData, isLoading } = useListCompaniesQuery(
    { includeVentureCapitals: true, onlyValidImpersonationAccess: true },
    { refetchOnWindowFocus: false, enabled: props.isOpen }
  );

  const impersonateCompanyMutation = useImpersonateCompanyMutation();

  const onImpersonateClick = async (companyId: number) => {
    props.onClose();

    await impersonateCompanyMutation.mutateAsync({ companyId });
  };

  const lowerSearch = search.toLowerCase();
  const filteredCompanies = (companiesData?.companies ?? [])
    .filter((company) => {
      if (filterMode === FilterMode.ONBOARDING) {
        return (
          company.type === CompanyType.PARTICIPANT && company.onboardingStep !== OnboardingStep.ONBOARDING_FINISHED
        );
      }

      if (filterMode === FilterMode.VC) {
        return company.type === CompanyType.VENTURE_CAPITAL;
      }

      if (filterMode === FilterMode.EXCLUDED) {
        return company.excludedFromDataset;
      }

      return true;
    })
    .filter((company) => {
      if (!search) {
        return true;
      }

      return company.name.toLowerCase().includes(lowerSearch) || `${company.id}`.includes(lowerSearch);
    })
    .sort((a, b) => a.id - b.id);

  useEffect(() => {
    if (companiesData) {
      setTimeout(() => {
        setListHeight(listRef.current?.clientHeight ?? 400);
      });
    }
  }, [companiesData]);

  useEffect(() => {
    if (!props.isOpen || !inputContainerRef.current) {
      return;
    }

    const timeout = setTimeout(() => {
      inputContainerRef.current?.querySelector("input")?.focus();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [props.isOpen, inputContainerRef.current]);

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    const onKey = (event: KeyboardEvent) => {
      if (event.key === "Enter" && filteredCompanies[0]) {
        event.preventDefault();
        void onImpersonateClick(filteredCompanies[0].id);
      }
    };

    window.addEventListener("keydown", onKey);

    return () => {
      window.removeEventListener("keydown", onKey);
    };
  }, [props.isOpen, filteredCompanies]);

  return (
    <SidePanel {...props} width="narrow" side="left" disableBottomPadding>
      <div className="flex h-full flex-col">
        <div className="sticky top-0 z-20 flex h-16 shrink-0 items-center bg-primary-300 pl-6 pr-4 shadow">
          <Typography variant="h2">Impersonate company</Typography>
        </div>
        <div ref={inputContainerRef} className="relative m-2">
          <Icon className="absolute left-0 z-10 mt-2.5 ml-2 text-gray-500" name="magnify" size="xl" />
          <TextInput
            autoFocus
            className="rounded-full pl-10"
            type="text"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </div>

        <RadioGroup
          className="mx-2"
          value={filterMode}
          onChange={(event) => setFilterMode(event.target.value as FilterMode)}
        >
          <Stack spacing={2} direction="row">
            <FormControlLabel
              value={FilterMode.ALL}
              labelPlacement="end"
              className="flex justify-between"
              control={<Radio />}
              label="All"
            />
            <FormControlLabel
              labelPlacement="end"
              value={FilterMode.ONBOARDING}
              className="flex justify-between"
              control={<Radio />}
              label="Onboarding"
            />
            <FormControlLabel
              labelPlacement="end"
              value={FilterMode.VC}
              className="flex justify-between"
              control={<Radio />}
              label="VC"
            />
            <FormControlLabel
              labelPlacement="end"
              value={FilterMode.EXCLUDED}
              className="flex justify-between"
              control={<Radio />}
              label="Excluded"
            />
          </Stack>
        </RadioGroup>

        {!isLoading && !filteredCompanies.length && (
          <div className="mt-16 flex flex-col items-center space-y-4">
            <Icon name="company" size="3xl" className="text-gray-600" />
            <LegacyHeading size="lg" className="!text-gray-600">
              No company
            </LegacyHeading>
          </div>
        )}

        {isLoading && (
          <div className="mt-16 flex flex-col items-center space-y-4">
            <LoadingIndicator />
          </div>
        )}

        <div className="h-full w-full flex-1" ref={listRef}>
          <FixedSizeList
            height={listHeight}
            width="100%"
            itemSize={73}
            itemCount={filteredCompanies.length}
            overscanCount={5}
          >
            {({ index, style }) => {
              const company = filteredCompanies[index];

              if (!company) {
                return null;
              }

              return (
                <ListItem style={style} key={index} component="div" disablePadding>
                  <ListItemButton onClick={() => onImpersonateClick(company.id)}>
                    {company.logo && (
                      <ListItemAvatar>
                        <Image
                          alt="company logo"
                          className="rounded"
                          height={(32 * company.logo.height) / company.logo.width}
                          src={publicUrl(company.logo)}
                          width={32}
                        />
                      </ListItemAvatar>
                    )}
                    <ListItemText
                      className={classNames({ "ml-14": !company.logo })}
                      primary={<Typography variant="subtitle2">{company.name}</Typography>}
                      secondary={
                        <Typography variant="caption" color="gray.500">
                          #{company.id}
                        </Typography>
                      }
                    />
                    {company.type === CompanyType.VENTURE_CAPITAL && (
                      <Chip label="VC" size="small" color="secondary-alt" className="ml-2" />
                    )}
                    {company.type === CompanyType.PARTICIPANT &&
                      company.onboardingStep !== OnboardingStep.ONBOARDING_FINISHED && (
                        <Chip label="Onboarding" size="small" color="primary-alt" className="ml-2" />
                      )}
                    {company.excludedFromDataset && (
                      <Chip label="Excluded" size="small" color="warning" className="ml-2" />
                    )}
                  </ListItemButton>
                </ListItem>
              );
            }}
          </FixedSizeList>
        </div>
      </div>
    </SidePanel>
  );
};
