import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { overrideEmployeeDataValidationFlag } from "~/services/employee-data-validation-flag/override-employee-data-validation-flag";

export const OverrideEmployeeDataValidationSchema = object({
  id: number().required(),
  employeeId: number().required(),
});

export type OverrideEmployeeDataValidationFlagInput = YupOutputType<typeof OverrideEmployeeDataValidationSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = OverrideEmployeeDataValidationSchema.validateSync(req.body, {
    abortEarly: false,
  });

  await overrideEmployeeDataValidationFlag(req, input);

  res.status(HttpStatus.OK).json({
    message: "Employee Data Validation Flag has been dismissed and won't be updated",
  });
};

export default api(handler, {
  method: "POST",
  authentication: { superAdmin: true },
});

export const useOverrideEmployeeDataValidationFlagMutation = createMutation<
  typeof handler,
  typeof OverrideEmployeeDataValidationSchema
>({
  path: "/api/admin/employee-data-validation/override-employee-data-validation-flag",
  schema: OverrideEmployeeDataValidationSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, GET_ACTIVE_FLAG_BY_EMPLOYEE_ID_QUERY_KEY);
    },
  }),
});
