import { type CompanyTag, type Currency, DataQuality, type FundingRound, type Headcount } from "@prisma/client";
import { mapSeries } from "bluebird";
import linearInterpolator from "linear-interpolator";
import { chain, meanBy } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { convertCurrency } from "~/lib/money";
import { isNotNull } from "~/lib/utils";
import { EmployeeLevel } from "~/services/employee/employee-level";
import { type FairPredictions, type QuantileMap, getFairPredictions } from "~/services/figures-ai-for-remuneration";
import { type JobsForPredictiveBenchmark } from "~/services/market-data/get-jobs-for-predictive-benchmark";
import { type PercentileBounds } from "~/services/percentiles";

export const getEmployeeLocationsForPredictiveBenchmark = async (
  ctx: AppContext,
  params: { locationIds: number[] }
) => {
  const locations = await ctx.prisma.employeeLocation.findMany({
    where: {
      id: { in: params.locationIds },
    },
    select: {
      id: true,
      name: true,
      translations: true,
      country: {
        select: {
          name: true,
          translations: true,
        },
      },
    },
  });

  return locations.map((location) => ({
    ...location,
    name: location.translations?.["EN" as keyof typeof location.translations] ?? location.name,
    country: {
      ...location.country,
      name:
        location.country.translations?.["EN" as keyof typeof location.country.translations] ?? location.country.name,
    },
  }));
};

export type EmployeeLocationsForPredictiveBenchmark = AsyncReturnType<
  typeof getEmployeeLocationsForPredictiveBenchmark
>;

export const getMarketDataPredictiveBenchmark = async (
  ctx: AppContext,
  params: {
    percentiles: PercentileBounds;
    targetPercentile?: number;
    currency: Currency;
    levels: EmployeeLevel[];
    jobs: JobsForPredictiveBenchmark;
    locations: EmployeeLocationsForPredictiveBenchmark;
    fundingRounds?: FundingRound[];
    headcount?: Headcount | null;
    industry?: CompanyTag | null;
  }
) => {
  const queries = params.levels.flatMap((level) =>
    params.jobs.flatMap((job) =>
      params.locations.map((location) => ({
        countryName: location.country.name,
        jobName: job.name,
        locationName: location.name,
        level: level === "MA0X" ? EmployeeLevel.C_LEVEL : level,
        fundingRounds: params.fundingRounds ?? [],
        headcount: params.headcount,
        industry: params.industry?.name ?? null,
      }))
    )
  );

  const predictions = await mapSeries(queries, async (query) => {
    const prediction = await getFairPredictions(ctx, query);
    if (!isValidPrediction(prediction)) {
      return null;
    }

    const convertFromEuro = (amount: number) => convertCurrency(amount, { euroExchangeRate: 1 }, params.currency);

    const convertMeasure = (measure: "baseSalary" | "onTargetEarnings" | "totalCash") => {
      const quantiles = prediction[`${measure}InEuros`];

      const low = interpolate(quantiles, params.percentiles.low);
      const middle = interpolate(quantiles, params.percentiles.middle);
      const high = interpolate(quantiles, params.percentiles.high);

      const target = params.targetPercentile ? interpolate(quantiles, params.targetPercentile) : null;

      return {
        min: convertFromEuro(quantiles.quantile10),
        median: convertFromEuro(quantiles.quantile50),
        max: convertFromEuro(quantiles.quantile90),
        lowest: convertFromEuro(quantiles.quantile10),
        low: convertFromEuro(low),
        middle: convertFromEuro(middle),
        high: convertFromEuro(high),
        highest: convertFromEuro(quantiles.quantile90),
        target: target ? convertFromEuro(target) : null,
        dataQuality: DataQuality.AI_ESTIMATED,
      };
    };

    return {
      baseSalary: convertMeasure("baseSalary"),
      onTargetEarnings: convertMeasure("onTargetEarnings"),
      totalCash: convertMeasure("totalCash"),
    };
  });

  const nonNullPredictions = predictions.filter(isNotNull);

  if (!nonNullPredictions.length) {
    return null;
  }

  return chain(["baseSalary", "onTargetEarnings", "totalCash"] as const)
    .keyBy()
    .mapValues((measure) => {
      return {
        min: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].min)),
        median: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].median)),
        max: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].max)),
        lowest: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].lowest)),
        low: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].low)),
        middle: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].middle)),
        high: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].high)),
        highest: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].highest)),
        target: Math.round(meanBy(nonNullPredictions, (prediction) => prediction[measure].target)),
        dataQuality: DataQuality.AI_ESTIMATED,
      };
    })
    .value();
};

export const getPredictivePercentileAmountForMeasure = async (
  ctx: AppContext,
  params: {
    currency: Currency;
    percentile: number;
    level: EmployeeLevel;
    jobName: string;
    countryName: string;
    locationName: string;
    fundingRounds?: FundingRound[];
    headcount?: Headcount | null;
    industry?: CompanyTag | null;
  }
) => {
  const query = {
    countryName: params.countryName,
    headcount: params.headcount,
    industry: params.industry?.name ?? null,
    jobName: params.jobName,
    fundingRounds: params.fundingRounds ?? [],
    level: params.level === "MA0X" ? EmployeeLevel.C_LEVEL : params.level,
    locationName: params.locationName,
  };

  const prediction = await getFairPredictions(ctx, query);
  if (!isValidPrediction(prediction)) {
    return null;
  }

  const convertMeasure = (measure: "baseSalary" | "onTargetEarnings" | "totalCash") => {
    const quantiles = prediction[`${measure}InEuros`];

    return {
      amountForPercentile: convertCurrency(
        interpolate(quantiles, params.percentile),
        { euroExchangeRate: 1 },
        params.currency
      ),
      dataQuality: DataQuality.AI_ESTIMATED,
    };
  };

  return {
    baseSalary: convertMeasure("baseSalary"),
    onTargetEarnings: convertMeasure("onTargetEarnings"),
    totalCash: convertMeasure("totalCash"),
  };
};

const interpolate = (quantiles: QuantileMap, percentile: number) => {
  const interpolate = linearInterpolator([
    [0.1, quantiles.quantile10],
    [0.25, quantiles.quantile25],
    [0.5, quantiles.quantile50],
    [0.75, quantiles.quantile75],
    [0.9, quantiles.quantile90],
  ]);

  return interpolate(percentile);
};

const isValidPrediction = (prediction: FairPredictions | null): prediction is FairPredictions => {
  return !!prediction && prediction.confidence >= 4;
};
