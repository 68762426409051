import { HelpOutline, MoreVert } from "@mui/icons-material";
import {
  Chip,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { type Currency, type EmployeeMappingSkipReason, ExternalEmployeeStatus } from "@prisma/client";
import classNames from "classnames";
import { capitalize, keyBy } from "lodash";
import { useRouter } from "next/router";
import React, { Fragment, useMemo, useState } from "react";
import CountryFlag from "react-country-flag";
import { ExternalEmployeeStatusChip } from "~/components/account/imported-employees/external-employee-status-chip";
import { EmployeePicture } from "~/components/employee/employee-picture";
import ExternalEmployeeDrawer, {
  useExternalEmployeeIdForDrawer,
} from "~/components/external-employee/drawer/external-employee-drawer";
import {
  ExternalEmployeePanel,
  useExternalEmployeePanel,
} from "~/components/external-employee/external-employee-panel";
import { Icon } from "~/components/ui/core/Icon";
import { SidePanel } from "~/components/ui/core/SidePanel";
import { type Column, EmptyCell, Table, useTableSelection } from "~/components/ui/core/Table";
import { Button } from "~/components/ui/core/button";
import { TableFilters } from "~/components/ui/core/table-filters";
import { EmployeeForm, type FormValues as EmployeeFormValues } from "~/components/ui/employee-form";
import { formatExternalEmployeeFieldsColumns } from "~/components/ui/format-external-employee-fields-columns";
import { Money } from "~/components/ui/money";
import { useApi } from "~/hooks/useApi";
import { useExternalEmployeesSyncProgress } from "~/hooks/useExternalEmployeesSyncSnackbar";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { useI18n } from "~/lib/i18n/use-i18n";
import { formatPercent } from "~/lib/math";
import { parseNumber, parseString } from "~/lib/query-params";
import { currentUrlWithQueryParams } from "~/lib/url";
import { useRefreshServerProps } from "~/lib/utils";
import { getStatusToShow } from "~/pages/account/imported-employees";
import { useSkipExternalEmployeeMappingMutation } from "~/pages/api/external-employee/skip-external-employee-mapping";
import { useUnskipExternalEmployeesMutation } from "~/pages/api/external-employee/unskip-external-employees";
import { type useFetchAllImportedEmployeesQuery } from "~/pages/api/imported-employees/fetch-all-imported-employees";
import { type MapExternalEmployeeInput, useMapExternalEmployeeMutation } from "~/pages/api/map-external-employee";
import { type TableColumnVisibilityKeys } from "~/pages/api/update-user-flag";
import { type GetAllAdditionalFieldsResult } from "~/services/additional-field/get-all-additional-fields";
import { computeEmployeeCompensation, formatEmployeeName } from "~/services/employee";
import { computeExternalEmployeeTotalRemuneration } from "~/services/external-employee";
import {
  type ExternalEmployeeRow,
  type FetchExternalEmployeesPaginationResult,
} from "~/services/external-employee/external-employee-read";
import { formatExternalEmployeeStatus } from "~/services/external-employee/external-employee-status";
import { type FetchPerformanceReviewRatings } from "~/services/performance-review/find-performance-review-ratings";

const COLUMNS_VISIBILITY_KEY: TableColumnVisibilityKeys = "externalEmployeesColumns";

export const EMPLOYEE_ID_KEY = "employee-id";

type Props = {
  currency: Currency;
  externalEmployees: FetchExternalEmployeesPaginationResult;
  refetchExternalEmployees: ReturnType<typeof useFetchAllImportedEmployeesQuery>["refetch"];
  additionalFields: GetAllAdditionalFieldsResult;
  externalEmployeesCountByStatus: { status: ExternalEmployeeStatus; count: number }[];
  status: ExternalEmployeeStatus;
  performanceReviewRatings: FetchPerformanceReviewRatings;
  useAdvancedLevels: boolean;
};

const getBaseSalary = (externalEmployee: FetchExternalEmployeesPaginationResult["items"][number]) => {
  if (externalEmployee.mappedEmployee) {
    const fromMappedEmployee = computeEmployeeCompensation(externalEmployee.mappedEmployee, {
      measure: "baseSalary",
      targetCurrency: externalEmployee.currency,
    });

    if (fromMappedEmployee) {
      return fromMappedEmployee;
    }
  }

  return computeExternalEmployeeTotalRemuneration(externalEmployee, "FIXED_SALARY");
};

const formatExternalEmployeeSource = (source: string) => capitalize(source.replace("KOMBO_", ""));

export const ExternalEmployeesList: React.FC<Props> = ({
  currency,
  externalEmployees,
  refetchExternalEmployees,
  additionalFields,
  externalEmployeesCountByStatus,
  status,
  performanceReviewRatings,
  useAdvancedLevels,
}) => {
  const { t, formatDate } = useI18n();
  const { apiFetch } = useApi();
  const { CAN_ACCESS_BUSINESS_UNITS, CAN_ACCESS_BULK_INVITE_USERS, CAN_ACCESS_EMPLOYEE_FORM_SUMMER24_EDITION } =
    useFeatureFlags();

  const router = useRouter();
  const refreshServerProps = useRefreshServerProps();
  const { inProgress: employeesSyncJobInProgress } = useExternalEmployeesSyncProgress();

  const {
    externalEmployeeId: externalEmployeeIdFromDrawer,
    pushExternalEmployeeIdInQueryParams,
    removeExternalEmployeeIdFromQueryParams,
  } = useExternalEmployeeIdForDrawer();

  const currentEmployeeId = useMemo(() => {
    if (CAN_ACCESS_EMPLOYEE_FORM_SUMMER24_EDITION) {
      return externalEmployeeIdFromDrawer;
    }

    return parseNumber(router.query, EMPLOYEE_ID_KEY);
  }, [router.query, externalEmployeeIdFromDrawer]);
  const [isSkippedEmployeeButtonDisabled, setDisableSkipEmployeeButton] = useState<boolean>(false);
  const [sortedEmployees, setSortedEmployees] = useState<ExternalEmployeeRow[]>(externalEmployees.items);

  const setCurrentEmployeeId = async (employeeId: number | null) => {
    if (CAN_ACCESS_EMPLOYEE_FORM_SUMMER24_EDITION) {
      if (employeeId === null) {
        await removeExternalEmployeeIdFromQueryParams();
      } else {
        await pushExternalEmployeeIdInQueryParams(employeeId);
      }

      return;
    }

    await router.push(
      currentUrlWithQueryParams(router, {
        [EMPLOYEE_ID_KEY]: employeeId,
      }),
      undefined,
      { shallow: true }
    );
  };

  const tableSelection = useTableSelection({
    pagination: externalEmployees,
    getRowSelectionIndex: (employee) => employee.id,
    enableFullSelection: true,
  });

  const externalEmployeePanel = useExternalEmployeePanel({
    rows: externalEmployees.items.map((externalEmployee) => ({
      externalEmployee,
      performanceRating: externalEmployee.performanceReviewRating,
    })),
  });

  const currentEmployeeIndex = currentEmployeeId
    ? sortedEmployees.findIndex((item) => {
        return item.id === currentEmployeeId;
      })
    : 0;

  const currentEmployee = externalEmployees.items.find((item) => {
    return item.id === currentEmployeeId;
  });

  const previousEmployee =
    sortedEmployees[(currentEmployeeIndex - 1 + sortedEmployees.length) % sortedEmployees.length];

  const nextEmployee = sortedEmployees[(currentEmployeeIndex + 1 + sortedEmployees.length) % sortedEmployees.length];
  const hasAtLeastOneBusinessUnitDefined = externalEmployees.items.some((employee) => !!employee.businessUnit);
  const isBusinessUnitColumnVisible = CAN_ACCESS_BUSINESS_UNITS && hasAtLeastOneBusinessUnitDefined;

  const columns: Column<ExternalEmployeeRow>[] = [
    {
      id: "status",
      name: t("pages.account.imported-employees.external-employees-list.status"),
      selector: (externalEmployee) => {
        return externalEmployee.status;
      },
      format: function Cell(externalEmployee) {
        return (
          <div className="flex">
            <ExternalEmployeeStatusChip status={externalEmployee.status} />
          </div>
        );
      },
    },
    {
      id: "source",
      name: t("pages.account.imported-employees.external-employees-list.source"),
      selector: "source",
      sortable: true,
      format: function Cell(externalEmployee) {
        return <Chip label={formatExternalEmployeeSource(externalEmployee.source)} size="small" />;
      },
    },
    {
      id: "employeeNumber",
      name: t("pages.account.imported-employees.external-employees-list.employee-number"),
      selector: "employeeNumber",
      align: "right",
      sortable: true,
    },
    {
      id: "name",
      name: t("pages.account.imported-employees.external-employees-list.name"),
      sortable: true,
      selector: (externalEmployee) => {
        return formatEmployeeName(externalEmployee).toLowerCase();
      },
      format: function Cell(externalEmployee) {
        return (
          <div className="flex items-center">
            <EmployeePicture
              picture={externalEmployee.picture}
              placeholderId={externalEmployee.id}
              pictureUrl={externalEmployee.userPermissions?.user?.profilePictureUrl}
            />
            <span className="ml-2">{formatEmployeeName(externalEmployee)}</span>
          </div>
        );
      },
    },
    {
      id: "job",
      name: t("pages.account.imported-employees.external-employees-list.job-title"),
      sortable: true,
      selector: (externalEmployee) => {
        return externalEmployee.mappedEmployee?.externalJobTitle ?? externalEmployee.job?.name;
      },
      format: function Cell(externalEmployee) {
        const jobTitle = externalEmployee.mappedEmployee?.externalJobTitle ?? externalEmployee.job?.name;

        return jobTitle || <EmptyCell />;
      },
    },
    {
      id: "level",
      name: t("pages.account.imported-employees.external-employees-list.level"),
      sortable: true,
      selector: (externalEmployee) => {
        return externalEmployee.mappedEmployee?.externalLevel ?? externalEmployee.level?.name;
      },
      format: function Cell(externalEmployee) {
        const level = externalEmployee.mappedEmployee?.externalLevel ?? externalEmployee.level?.name;

        return level || <EmptyCell />;
      },
    },
    {
      id: "location",
      name: t("pages.account.imported-employees.external-employees-list.location"),
      sortable: true,
      selector: (externalEmployee) => {
        return externalEmployee.mappedEmployee?.mappingLocation?.name ?? externalEmployee.location?.name;
      },
      format: function Cell(externalEmployee) {
        const location =
          externalEmployee.mappedEmployee?.mappingLocation ??
          externalEmployee.location?.mappedLocation ??
          externalEmployee.location;
        if (!location) {
          return <EmptyCell />;
        }

        return (
          <div className="flex items-center">
            {location.country && <CountryFlag svg className="mr-1.5" countryCode={location.country.alpha2} />}

            <span>{location.name}</span>
          </div>
        );
      },
    },
    {
      id: "fte",
      name: t("pages.account.imported-employees.external-employees-list.fte-coefficient"),
      formatName: function Header() {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <span>{t("pages.account.imported-employees.external-employees-list.fte-coefficient")}</span>
            <Tooltip
              title={
                <Stack spacing={1}>
                  <Typography variant="subtitle2">
                    {t("pages.account.imported-employees.external-employees-list.fte-coefficient-tooltip")}
                  </Typography>
                </Stack>
              }
            >
              <HelpOutline fontSize="small" color="info" />
            </Tooltip>
          </Stack>
        );
      },
      sortable: true,
      selector: (externalEmployee) => {
        return externalEmployee.fteDivider;
      },
      format: function Cell(externalEmployee) {
        if (!externalEmployee.fteDivider) {
          return <EmptyCell />;
        }

        return formatPercent(externalEmployee.fteDivider);
      },
    },
    {
      id: "baseSalary",
      name: t("pages.account.imported-employees.external-employees-list.base-salary"),
      align: "right",
      selector: (externalEmployee) => {
        return getBaseSalary(externalEmployee);
      },
      format: function Cell(externalEmployee) {
        const total = getBaseSalary(externalEmployee);

        if (!total) {
          return <EmptyCell />;
        }

        return <Money amount={total} currency={externalEmployee.currency} convertToCurrency={currency} />;
      },
    },
    {
      id: "email",
      name: t("pages.account.imported-employees.external-employees-list.email"),
      align: "left",
      sortable: true,
      selector: (externalEmployee) => {
        return externalEmployee.email;
      },
      visible: CAN_ACCESS_BULK_INVITE_USERS,
      format: function Cell(externalEmployee) {
        return externalEmployee.email ?? <EmptyCell />;
      },
    },
    ...formatExternalEmployeeFieldsColumns(t, {
      additionalFields,
      mapRowToExternalEmployee: (row: ExternalEmployeeRow) => row,
      visible: true,
      hideBusinessUnits: !isBusinessUnitColumnVisible,
      formatDate,
    }),
    {
      id: "actions",
      name: t("common.actions"),
      align: "right",
      size: 0,
      visible: !CAN_ACCESS_EMPLOYEE_FORM_SUMMER24_EDITION,
      format: function Cell(row) {
        return (
          <RowMenu
            onOpenEmployeePanel={() => externalEmployeePanel.open(row.id)}
            onOpenEditionPanel={async () => await setCurrentEmployeeId(row.id)}
          />
        );
      },
    },
  ];

  const moveToNextEmployee = async () => {
    if (nextEmployee) {
      await setCurrentEmployeeId(nextEmployee.id);
    } else {
      void router.push({ pathname: "/account/employees" });
    }
  };

  const skipExternalEmployeeMappingMutation = useSkipExternalEmployeeMappingMutation({
    successMessage: t("pages.account.imported-employees.external-employees-list.employee-has-been-skipped"),
  });

  const unskipExternalEmployeeMappingMutation = useUnskipExternalEmployeesMutation({
    successMessage: t("pages.account.imported-employees.external-employees-list.employee-has-been-unskipped"),
    onSuccess: () => tableSelection.resetSelection(),
  });

  const mapExternalEmployeeMutation = useMapExternalEmployeeMutation({
    successMessage: t("pages.account.imported-employees.external-employees-list.employee-successfully-mapped"),
  });

  const onSkip = async (externalEmployeeId: number, reason: EmployeeMappingSkipReason) => {
    setDisableSkipEmployeeButton(true);

    await skipExternalEmployeeMappingMutation.mutateAsync(
      {
        externalEmployeeId,
        reason,
      },
      {
        onSuccess: async () => {
          setDisableSkipEmployeeButton(false);
          const newStatus = getStatusToShow(
            parseString(router.query, "status"),
            keyBy(externalEmployeesCountByStatus, "status")
          );
          void router.replace({ pathname: router.route, query: { ...router.query, status: newStatus } });
          await moveToNextEmployee();
        },
      }
    );
  };

  const onUnskip = async (externalEmployeeIds: number[]) => {
    setDisableSkipEmployeeButton(true);
    await unskipExternalEmployeeMappingMutation.mutateAsync({ externalEmployeeIds });

    await moveToNextEmployee();
    setDisableSkipEmployeeButton(false);
  };

  const onSubmit = async (externalEmployee: ExternalEmployeeRow, values: EmployeeFormValues) => {
    setDisableSkipEmployeeButton(true);

    await mapExternalEmployeeMutation.mutateAsync({
      externalEmployeeId: externalEmployee.id,
      values: {
        ...values,
        ...(values.job && { job: { id: values.job.id } }),
      },
    } as MapExternalEmployeeInput);

    await moveToNextEmployee();

    setDisableSkipEmployeeButton(false);
  };

  const onReconciliate = async (externalEmployeeId: number, employeeId: number, values: EmployeeFormValues) => {
    await apiFetch("/api/reconciliate-external-employee", {
      body: {
        externalEmployeeId,
        employeeId,
        values,
      },
      successMessage: t("pages.account.imported-employees.external-employees-list.employee-successfully-reconciliated"),
    });

    await refreshServerProps();
    await setCurrentEmployeeId(null);
  };

  const tabsOptions = externalEmployeesCountByStatus
    .filter((statusCount) => statusCount.count > 0)
    .map((statusCount) => ({
      value: statusCount.status,
      label: (
        <span className="flex items-center space-x-2">
          <span className="capitalize">{formatExternalEmployeeStatus(t, statusCount.status).label}</span>
          <EmployeesCountTag employeeNumber={statusCount.count} selected={status === statusCount.status} />
        </span>
      ),
    }));

  const drawerSideButtons = (
    <>
      <Button
        variant="text"
        color="inherit"
        onClick={() => void setCurrentEmployeeId(null)}
        className="min-w-0 text-xl"
      >
        &times;
      </Button>

      <Button
        variant="text"
        color="inherit"
        onClick={() => {
          if (previousEmployee) {
            void setCurrentEmployeeId(previousEmployee.id);
          }
        }}
        className="h-9 min-w-0"
      >
        <Icon name="arrow-up" />
      </Button>

      <Button
        variant="text"
        color="inherit"
        onClick={() => {
          if (nextEmployee) {
            void setCurrentEmployeeId(nextEmployee.id);
          }
        }}
        className="h-9 min-w-0"
      >
        <Icon name="arrow-down" />
      </Button>
    </>
  );

  return (
    <Fragment>
      {CAN_ACCESS_EMPLOYEE_FORM_SUMMER24_EDITION && (
        <ExternalEmployeeDrawer
          isOpen={!!currentEmployeeId}
          onClose={async () => {
            await setCurrentEmployeeId(null);
            void refetchExternalEmployees();
          }}
          externalEmployeeId={currentEmployeeId}
          sideButtons={drawerSideButtons}
        />
      )}

      {!CAN_ACCESS_EMPLOYEE_FORM_SUMMER24_EDITION && (
        <SidePanel
          isOpen={!!currentEmployeeId}
          sideButtons={drawerSideButtons}
          onClose={() => {
            void setCurrentEmployeeId(null);
          }}
        >
          {currentEmployee && (
            <EmployeeForm
              useAdvancedLevels={useAdvancedLevels}
              defaultCurrency={currentEmployee.currency}
              performanceReviewRatings={performanceReviewRatings}
              employee={currentEmployee.mappedEmployee}
              existingEmployeeSuggestions={currentEmployee.existingEmployeeSuggestions}
              externalEmployee={currentEmployee}
              submitButtonText={t("pages.account.imported-employees.external-employees-list.submit")}
              onReconciliate={async (values, reconciliatedEmployeeId) => {
                if (!currentEmployee.existingEmployeeSuggestions?.length || !reconciliatedEmployeeId) {
                  return;
                }

                await onReconciliate(currentEmployee.id, reconciliatedEmployeeId, values);
              }}
              onSkip={async (reason) => {
                await onSkip(currentEmployee.id, reason);
              }}
              onUnskip={async () => {
                await onUnskip([currentEmployee.id]);
              }}
              onSubmit={async (values) => {
                await onSubmit(currentEmployee, values);
              }}
              isSkippedEmployeeButtonDisabled={isSkippedEmployeeButtonDisabled}
            />
          )}
        </SidePanel>
      )}

      <Table
        inCard
        tableSelection={status === ExternalEmployeeStatus.SKIPPED ? tableSelection : undefined}
        visibleColumnsKey={COLUMNS_VISIBILITY_KEY}
        renderHeader={(components) => (
          <Stack className="-mx-5">
            <Stack direction="row" justifyContent="space-between" className="px-4">
              {tabsOptions.length > 0 && (
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={status}
                  onChange={(event, status) => {
                    void router.push(currentUrlWithQueryParams(router, { status, page: null }));
                  }}
                >
                  {tabsOptions.map((option) => (
                    <ToggleButton size="small" key={option.value} value={option.value} className="space-x-2 px-4">
                      {option.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}
              <Stack direction="row" spacing={2} alignItems="center" className="ml-auto">
                {status === ExternalEmployeeStatus.SKIPPED && (
                  <Button
                    variant="contained"
                    disabled={tableSelection.isEmpty}
                    isLoading={unskipExternalEmployeeMappingMutation.isLoading}
                    onClick={async () => {
                      await unskipExternalEmployeeMappingMutation.mutateAsync({
                        externalEmployeeIds: tableSelection.selectedIds,
                      });
                    }}
                  >
                    {t("pages.account.imported-employees.external-employees-list.unskip-selected-employees", {
                      count: tableSelection.selectionSize,
                    })}
                  </Button>
                )}

                {components.visibilityDropDown("py-2")}
              </Stack>
            </Stack>

            <TableFilters
              className="mx-2"
              filterOptions={externalEmployees.meta.filterOptions}
              searchInput={components.searchInput}
            />

            <Divider />
          </Stack>
        )}
        navigation={{
          type: "url",
          search: {
            fullWidth: true,
            placeholder: t("pages.account.imported-employees.external-employees-list.filter-employees"),
            dense: true,
          },
          pagination: {
            meta: externalEmployees,
          },
        }}
        afterSort={(externalEmployee) => {
          setSortedEmployees(externalEmployee);
        }}
        columns={columns}
        data={externalEmployees.items}
        onRowClick={(externalEmployee) => {
          void setCurrentEmployeeId(externalEmployee.id);
        }}
        disabledClick={employeesSyncJobInProgress}
      />

      <SidePanel {...externalEmployeePanel.sidePanelProps}>
        {externalEmployeePanel.activeRow && (
          <ExternalEmployeePanel
            currency={currency}
            additionalFields={additionalFields}
            onEditClick={(externalEmployeeId) => {
              externalEmployeePanel.open(null);
              void setCurrentEmployeeId(externalEmployeeId);
            }}
            {...externalEmployeePanel.activeRow}
          />
        )}
      </SidePanel>
    </Fragment>
  );
};

type EmployeesCountTagProps = {
  employeeNumber: number;
  selected: boolean;
};

const EmployeesCountTag: React.FC<EmployeesCountTagProps> = ({ employeeNumber, selected }) => (
  <span
    className={classNames({
      "rounded-full px-2 text-sm": true,
      "bg-white text-gray-800": selected,
      "bg-gray-300": !selected,
    })}
  >
    {employeeNumber}
  </span>
);

const RowMenu: React.FC<{
  onOpenEmployeePanel: () => void;
  onOpenEditionPanel: () => void;
}> = ({ onOpenEmployeePanel, onOpenEditionPanel }) => {
  const { t } = useI18n();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const onMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => {
          setMenuAnchorEl(event.currentTarget);
        }}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList dense className="py-0 focus:outline-none">
          <MenuItem
            onClick={() => {
              onMenuClose();
              onOpenEmployeePanel();
            }}
          >
            <ListItemText>{t("common.open")}</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              onMenuClose();
              onOpenEditionPanel();
            }}
          >
            <ListItemText>{t("common.edit")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
