import React, { useContext } from "react";
import { defaultPermissions, type PermissionsStatus } from "~/services/user/permissions/authentication-options";
import {
  canAccessAdditionalField,
  canAccessExternalSalaryBandMarketData,
  canAccessFiguresSalaryBandMarketData,
} from "~/services/user/permissions/functions";
import { canUpdateSalaryGrid } from "~/services/user/permissions/salary-bands-permissions";
import { getUserRoles, type UserRoles } from "~/services/user/permissions/utils/get-user-roles";

type PermissionsMethods = {
  canAccessAdditionalField: typeof canAccessAdditionalField;
  canAccessFiguresSalaryBandMarketData: typeof canAccessFiguresSalaryBandMarketData;
  canAccessExternalSalaryBandMarketData: typeof canAccessExternalSalaryBandMarketData;
  canUpdateSalaryGrid: typeof canUpdateSalaryGrid;
};

type PermissionsProps = React.PropsWithChildren<{
  permissions: PermissionsStatus;
  role: UserRoles;
}>;

type PermissionsReturnType = PermissionsProps & { functions: PermissionsMethods };

export const PermissionsContext = React.createContext<PermissionsReturnType>({
  permissions: defaultPermissions,
  role: getUserRoles(null),
  functions: {
    canAccessAdditionalField,
    canAccessFiguresSalaryBandMarketData,
    canAccessExternalSalaryBandMarketData,
    canUpdateSalaryGrid,
  },
});

export const usePermissions = (): PermissionsReturnType => useContext(PermissionsContext);

export const PermissionsProvider: React.FC<PermissionsProps> = ({ permissions, role, children }) => {
  const functions = {
    canAccessAdditionalField,
    canAccessFiguresSalaryBandMarketData,
    canAccessExternalSalaryBandMarketData,
    canUpdateSalaryGrid,
  };
  return <PermissionsContext.Provider value={{ permissions, role, functions }}>{children}</PermissionsContext.Provider>;
};
