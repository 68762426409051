/* eslint-disable-next-line no-restricted-imports */
import { type ButtonProps, CircularProgress, Button as MuiButton } from "@mui/material";
import { type ComponentProps, forwardRef } from "react";
import { useBoolean } from "react-use";
import { ConfirmationModal } from "~/components/ui/core/ConfirmationModal";

type ButtonType = <C extends React.ElementType>(
  props: ButtonProps<
    C,
    {
      component?: C;
      isLoading?: boolean;
      confirmation?: Omit<ComponentProps<typeof ConfirmationModal>, "isOpen" | "isLoading" | "onConfirm" | "onClose">;
    }
  >,
  ref?: React.Ref<HTMLButtonElement>
) => React.ReactElement;

const CastedButton: ButtonType = (props, ref) => {
  const { children, isLoading, disabled, endIcon, onClick, confirmation, ...rest } = props;
  const [showConfirmation, setShowConfirmation] = useBoolean(false);

  return (
    <MuiButton
      ref={ref}
      disabled={disabled || isLoading}
      endIcon={isLoading ? <CircularProgress size={14} color="inherit" /> : endIcon}
      onClick={(event) => {
        if (props.confirmation) {
          setShowConfirmation();
        } else {
          onClick?.(event);
        }
      }}
      {...rest}
    >
      {children}

      {confirmation && (
        <ConfirmationModal
          isOpen={showConfirmation}
          isLoading={isLoading}
          onClose={setShowConfirmation}
          onConfirm={() => {
            setShowConfirmation(false);
            onClick?.();
          }}
          {...confirmation}
        />
      )}
    </MuiButton>
  );
};

export const Button = forwardRef(CastedButton) as ButtonType;
