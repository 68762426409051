import { distance } from "fastest-levenshtein";
import { chain } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { type FetchJobFamiliesForSelectorInput } from "~/pages/api/job/fetch-job-families-for-selector";
import { CUSTOM_JOB_PREFIX } from "~/services/custom-jobs/custom-job-prefix";
import { fetchCustomJobs } from "~/services/custom-jobs/fetch-custom-jobs";
import { fetchJobFamilies } from "~/services/job/fetchJobFamilies";

// Make sure to keep in sync with the CSS selector in index.css
export const CUSTOM_JOBS_FAMILY_ID = "custom-jobs";
export const MAPPED_JOBS_FAMILY_ID = "mapped-jobs";

export const fetchJobFamiliesForSelector = async (ctx: AppContext, input: FetchJobFamiliesForSelectorInput) => {
  const baseJobFamilies = await fetchJobFamilies(ctx);

  const mappedExternalJobs = await ctx.prisma.externalJob.findMany({
    where: {
      companyId: ctx.user?.companyId ?? -1,
      mappedJobId: { not: null },
    },
    select: {
      name: true,
      mappedJobId: true,
    },
  });

  const jobFamilies = baseJobFamilies.map((family) => {
    return {
      ...family,
      id: `${family.id}`,
      jobs: family.jobs.map((job) => {
        const aliases = chain([
          ...job.jobAliases.map((jobAlias) => jobAlias.name),
          ...mappedExternalJobs
            .filter((externalJob) => externalJob.mappedJobId === job.id)
            .map((externalJob) => externalJob.name),
        ])
          .uniqBy((word) => word.toLowerCase().replaceAll(" ", ""))
          .filter((alias) => alias.toLowerCase() !== job.name.toLowerCase())
          .orderBy((word) => distance(word, job.name), "desc")
          .value();

        return {
          id: `${job.id}`,
          name: job.name,
          aliases,
        };
      }),
    };
  });

  if (input.enableCustomJobs) {
    const customJobs = await fetchCustomJobs(ctx);

    if (customJobs.length > 0) {
      jobFamilies.unshift({
        id: CUSTOM_JOBS_FAMILY_ID,
        name: ctx.t("components.ui.job-selector.custom-jobs"),
        jobs: customJobs.map((customJob) => ({
          id: `${CUSTOM_JOB_PREFIX}${customJob.id}`,
          name: customJob.name,
          aliases: [],
        })),
      });
    }
  }

  return jobFamilies;
};

export type FetchJobFamiliesForSelectorResult = AsyncReturnType<typeof fetchJobFamiliesForSelector>;
