import { BusinessLogicError } from "~/lib/api";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { logInfo } from "~/lib/logger";
import { getFiguresCompanyId, getServiceAccountUserId } from "~/lib/utils";
export const relinquishTemporarySuperAdminRights = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  if (!user.isSuperAdmin) {
    throw new BusinessLogicError("You do not possess super admin rights");
  }

  if (user.id === getServiceAccountUserId()) {
    throw new BusinessLogicError("You are not allowed to relinquish super admin rights for this user");
  }

  // Required to make sure we check the proper company ID when impersonating
  const originalUser = await ctx.prisma.user.findUniqueOrThrow({
    where: { id: user.id },
    select: { companyId: true },
  });

  if (originalUser.companyId !== getFiguresCompanyId()) {
    throw new BusinessLogicError("You are not allowed to relinquish super admin rights");
  }

  const updatedLogs = await ctx.prisma.superAdminRightsLog.updateMany({
    where: {
      user: { id: user.id, isSuperAdmin: true },
      relinquishedAt: null,
    },
    data: { relinquishedAt: new Date() },
  });

  await ctx.prisma.user.update({
    where: { id: user.id },
    data: { isSuperAdmin: false },
  });

  logInfo(ctx, `[relinquish-temporary-super-admin-rights] Relinquished super admin rights`, {
    userId: user.id,
    updatedLogsCount: updatedLogs.count,
  });
};
