import {
  DataValidationFlagOrigin,
  type Employee,
  type EmployeeLocation,
  EmployeeSource,
  EmployeeStatus,
  type EmployeeUpdateStrategy,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  type Job,
  type Prisma,
  type PrismaClient,
} from "@prisma/client";
import { capitalize, omit, uniqueId } from "lodash";
import { BusinessLogicError } from "~/lib/api";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { logInfo } from "~/lib/logger";
import { dangerouslyIncludeHistoricalExternalRemunerationItems } from "~/lib/prisma-restrictions/schemas/generate-external-remuneration-item-historical-schema";
import { validateRequestAuthorised } from "~/lib/security";
import { type YupOutputType } from "~/lib/utils";
import { generateEmployeeBucketHash } from "~/services/company-dashboard/generate-employee-bucket-hash";
import { createEmployeeFlags } from "~/services/employee-data-validation-flag/data-flagging";
import { type CreateEmployeeSchema } from "~/services/employee/employee-schemas";

type CreateEmployeePayload = Parameters<PrismaClient["employee"]["create"]>[0]["data"];

export type EmployeeCreateInput = YupOutputType<typeof CreateEmployeeSchema>;

export type ExtraInput = {
  companyId: number;
  source: EmployeeSource;
  externalId: string;
  externalJobTitle?: string;
  updateReason?: string;
  updateStrategy?: EmployeeUpdateStrategy;
  shouldCreateExternalEmployee?: boolean;
};

export const mapInputForExternalEmployee = (
  input: EmployeeCreateInput,
  extraInput: ExtraInput,
  params: {
    employeeNumber: string;
    job: Job;
    location: EmployeeLocation;
  }
) => {
  return {
    source: extraInput.source,
    externalId: extraInput.externalId,
    employeeNumber: params.employeeNumber,
    firstName: input.firstName,
    lastName: input.lastName,
    gender: input.gender,
    hireDate: input.hireDate,
    birthDate: input.birthDate,
    isFounder: input.isFounder,

    ...(input.pictureId && {
      picture: {
        connect: { id: input.pictureId },
      },
    }),

    job: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId: extraInput.companyId,
            externalId: input.externalJobTitle ?? extraInput.externalJobTitle ?? params.job.name,
          },
        },
        create: {
          name: input.externalJobTitle ?? extraInput.externalJobTitle ?? params.job.name,
          externalId: input.externalJobTitle ?? extraInput.externalJobTitle ?? params.job.name,
          company: { connect: { id: extraInput.companyId } },
          mappedJob: { connect: { id: params.job.id } },
        },
      },
    },

    location: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId: extraInput.companyId,
            externalId: params.location.name,
          },
        },
        create: {
          name: params.location.name,
          externalId: params.location.name,
          autoMappingEnabled: true,
          company: { connect: { id: extraInput.companyId } },
          mappedLocation: { connect: { id: params.location.id } },
        },
      },
    },

    level: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId: extraInput.companyId,
            externalId: input.externalLevel ?? input.level,
          },
        },
        create: {
          name: input.externalLevel ?? input.level,
          externalId: input.externalLevel ?? input.level,
          company: { connect: { id: extraInput.companyId } },
          mappedLevel: input.level,
        },
      },
    },

    company: {
      connect: { id: extraInput.companyId },
    },

    currency: {
      connect: { id: input.currency.id },
    },
  } satisfies Omit<Prisma.ExternalEmployeeCreateWithoutMappedEmployeeInput, "status">;
};

export const createRemunerationItemsForExternalEmployee = async (
  ctx: AppContext,
  input: Pick<EmployeeCreateInput, "baseSalary">,
  extraInput: Pick<ExtraInput, "companyId" | "source">,
  params: {
    externalEmployeeId: number;
    fixedBonus?: number | null;
    fixedBonusPercentage?: number | null;
    onTargetBonus?: number | null;
    onTargetBonusPercentage?: number | null;
  }
) => {
  await ctx.prisma.externalRemunerationItem.deleteMany({
    ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
    where: { employeeId: params.externalEmployeeId },
  });

  const fixedSalaryNature = await ctx.prisma.externalRemunerationNature.upsert({
    where: {
      companyId_source_externalId: {
        companyId: extraInput.companyId,
        source: extraInput.source,
        externalId: "fix-salary",
      },
    },
    update: {},
    create: {
      source: extraInput.source,
      externalId: "fix-salary",
      name: "Fixed salary",
      mappedType: "FIXED_SALARY",
      company: {
        connect: {
          id: extraInput.companyId,
        },
      },
    },
  });

  const remunerationItems: Prisma.ExternalRemunerationItemCreateManyInput[] = [
    {
      source: extraInput.source,
      status: ExternalRemunerationStatus.LIVE,
      externalId: "fix-salary",
      amount: input.baseSalary,
      natureId: fixedSalaryNature.id,
      employeeId: params.externalEmployeeId,
    },
  ];

  if (params.fixedBonus || params.fixedBonusPercentage) {
    if (params.fixedBonus && params.fixedBonusPercentage) {
      throw new BusinessLogicError("Cannot have both fixed bonus and fixed bonus percentage");
    }

    const fixedBonusNature = await ctx.prisma.externalRemunerationNature.upsert({
      where: {
        companyId_source_externalId: {
          companyId: extraInput.companyId,
          source: extraInput.source,
          externalId: "fix-bonus",
        },
      },
      update: {},
      create: {
        source: extraInput.source,
        externalId: "fix-bonus",
        name: "Fixed bonus",
        mappedType: "FIXED_BONUS",
        company: {
          connect: {
            id: extraInput.companyId,
          },
        },
      },
    });

    remunerationItems.push({
      source: extraInput.source,
      status: ExternalRemunerationStatus.LIVE,
      externalId: "fix-bonus",
      amount: params.fixedBonusPercentage
        ? Math.round(input.baseSalary * params.fixedBonusPercentage)
        : (params.fixedBonus as number),
      asPercentage: params.fixedBonusPercentage ?? null,
      natureId: fixedBonusNature.id,
      employeeId: params.externalEmployeeId,
    });
  }

  if (params.onTargetBonus || params.onTargetBonusPercentage) {
    if (params.onTargetBonus && params.onTargetBonusPercentage) {
      throw new BusinessLogicError("Cannot have both onTargetBonus and onTargetBonusPercentage");
    }

    const onTargetBonusNature = await ctx.prisma.externalRemunerationNature.upsert({
      where: {
        companyId_source_externalId: {
          companyId: extraInput.companyId,
          source: extraInput.source,
          externalId: "variable-bonus",
        },
      },
      update: {},
      create: {
        source: extraInput.source,
        externalId: "variable-bonus",
        name: "Variable bonus",
        mappedType: "VARIABLE_BONUS",
        company: {
          connect: {
            id: extraInput.companyId,
          },
        },
      },
    });

    remunerationItems.push({
      source: extraInput.source,
      status: ExternalRemunerationStatus.LIVE,
      externalId: "variable-bonus",
      amount: params.onTargetBonusPercentage
        ? Math.round(input.baseSalary * params.onTargetBonusPercentage)
        : (params.onTargetBonus as number),
      asPercentage: params.onTargetBonusPercentage ?? null,
      natureId: onTargetBonusNature.id,
      employeeId: params.externalEmployeeId,
    });
  }

  await ctx.prisma.externalRemunerationItem.createMany({
    data: remunerationItems,
  });
};

export const mapInput = async (
  ctx: AppContext,
  input: EmployeeCreateInput,
  extraInput: ExtraInput
): Promise<CreateEmployeePayload> => {
  const user = getRequiredUser(ctx);

  const company = await ctx.prisma.company.findFirstOrThrow({
    where: { id: extraInput.companyId },
    include: {
      liveSurvey: true,
    },
  });

  if (!company.liveSurvey) {
    throw new Error("Company should have a live survey");
  }

  const job = await ctx.prisma.job.findUniqueOrThrow({ where: { id: input.job.id } });

  const location = await ctx.prisma.employeeLocation.findUniqueOrThrow({ where: { id: input.location.id } });

  const currency = await ctx.prisma.currency.findUniqueOrThrow({ where: { id: input.currency.id } });

  const employeeNumber = input.employeeNumber ? input.employeeNumber : `figures-${uniqueId()}`;
  const fixedBonus = input.fixedBonusPercentage
    ? Math.round(input.fixedBonusPercentage * input.baseSalary)
    : input.fixedBonus;
  const onTargetBonus = input.onTargetBonusPercentage
    ? Math.round(input.onTargetBonusPercentage * input.baseSalary)
    : input.onTargetBonus;

  return {
    user: {
      connect: { id: user.id },
    },
    company: {
      connect: { id: company.id },
    },
    survey: {
      connect: { id: company.liveSurvey.id },
    },
    job: {
      connect: { id: job.id },
    },
    location: {
      connect: { id: location.fallbackLocationId ?? location.id },
    },
    mappingLocation: {
      connect: { id: location.id },
    },
    currency: {
      connect: { id: currency.id },
    },

    ...(input.pictureId && {
      picture: {
        connect: { id: input.pictureId },
      },
    }),

    status: EmployeeStatus.LIVE,
    source: extraInput.source,
    level: input.level,
    externalJobTitle: input.externalJobTitle ?? extraInput.externalJobTitle ?? job.name,
    externalLevel: input.externalLevel ?? capitalize(input.level),

    firstName: input.firstName,
    lastName: input.lastName,
    gender: input.gender,
    employeeNumber,
    bucketHash: generateEmployeeBucketHash({
      countryId: location.countryId,
      jobId: job.id,
      level: input.level,
    }),

    baseSalary: input.baseSalary,
    fixedBonus,
    onTargetBonus,
    fixedBonusPercentage: input.fixedBonusPercentage,
    onTargetBonusPercentage: input.onTargetBonusPercentage,

    externalId: extraInput.externalId,
    hireDate: input.hireDate,
    birthDate: input.birthDate,
    isFounder: input.isFounder,

    ...(extraInput.updateReason && {
      updateReason: extraInput.updateReason,
    }),

    ...(extraInput.updateStrategy && {
      updateStrategy: extraInput.updateStrategy,
    }),

    liveAt: new Date(),
  };
};

export const createEmployee = async (
  ctx: AppContext,
  input: EmployeeCreateInput,
  extraInput: ExtraInput
): Promise<Employee> => {
  validateRequestAuthorised(ctx, { companyId: extraInput.companyId });

  const existingEmployee = await ctx.prisma.employee.findFirst({
    where: {
      externalId: extraInput.externalId,
      companyId: extraInput.companyId,
      ...(input.employeeNumber && { employeeNumber: input.employeeNumber }),
      status: EmployeeStatus.LIVE,
    },
  });

  if (existingEmployee) {
    throw new BusinessLogicError(`Employee #${input.employeeNumber ?? extraInput.externalId} already exists.`);
  }

  logInfo(ctx, "[employee] Creating new employee", { ...extraInput });

  const payload = await mapInput(ctx, input, extraInput);

  const employee = await ctx.prisma.employee.create({
    data: { ...payload, liveAt: new Date() },
    include: { externalEmployee: true, job: true, location: true },
  });

  await createEmployeeFlags(ctx, employee.id, employee.companyId, DataValidationFlagOrigin.HRIS_SYNC);

  const shouldCreateExternalEmployee = extraInput.shouldCreateExternalEmployee ?? true;

  if (extraInput.source === EmployeeSource.MANUAL && shouldCreateExternalEmployee) {
    const externalEmployeePayload = mapInputForExternalEmployee(input, extraInput, {
      job: employee.job,
      location: employee.location,
      employeeNumber: employee.employeeNumber,
    });

    const externalEmployee = await ctx.prisma.externalEmployee.upsert({
      where: {
        companyId_source_externalId: {
          companyId: extraInput.companyId,
          externalId: extraInput.externalId,
          source: ExternalEmployeeSource.MANUAL,
        },
      },
      create: {
        ...externalEmployeePayload,
        mappedEmployee: { connect: { id: employee.id } },
        status: ExternalEmployeeStatus.MAPPED,
      },
      update: {
        ...externalEmployeePayload,
        mappedEmployee: { connect: { id: employee.id } },
        status: ExternalEmployeeStatus.MAPPED,
      },
    });

    await createRemunerationItemsForExternalEmployee(ctx, input, extraInput, {
      externalEmployeeId: externalEmployee.id,
      fixedBonus: employee.fixedBonus,
      onTargetBonus: employee.onTargetBonus,
    });
  }

  logInfo(ctx, "[employee] Successfully created new employee", { employeeId: employee.id });

  return employee;
};

export const createHistoricalEmployee = async (ctx: AppContext, employeeId: number): Promise<void> => {
  const employee = await ctx.prisma.employee.findUniqueOrThrow({ where: { id: employeeId } });

  logInfo(ctx, "[employee] Create historical employee", {
    employeeId,
    companyId: employee.companyId,
  });

  // Create a new historical employee
  await ctx.prisma.employee.create({
    data: {
      ...omit(employee, "id", "liveEmployeeStatsId", "createdAt", "updatedAt"),
      status: EmployeeStatus.HISTORICAL,
    } as CreateEmployeePayload,
  });

  logInfo(ctx, "[employee] Successfully create historical employee", {
    employeeId: employee.id,
    companyId: employee.companyId,
  });
};
