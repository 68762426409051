import { Typography } from "@mui/material";
import { AdditionalFieldNature, type Prisma } from "@prisma/client";
import { compact } from "lodash";
import { type TFunction } from "next-i18next";
import { type Column, EmptyCell } from "~/components/ui/core/Table";
import { formatAdditionalFieldValue } from "~/lib/additional-field";
import { DateFormats } from "~/lib/dates";
import { isIn } from "~/lib/utils";
import {
  type GetAllAdditionalFieldsResult,
  type externalEmployeeFieldsSelectForDisplay,
} from "~/services/additional-field/get-all-additional-fields";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { formatGender } from "~/services/external-employee/gender";

export type Row = Prisma.ExternalEmployeeGetPayload<{
  select: typeof externalEmployeeFieldsSelectForDisplay;
}>;

export const formatExternalEmployeeFieldsColumns = <T,>(
  t: TFunction,
  params: {
    additionalFields?: GetAllAdditionalFieldsResult;
    mapRowToExternalEmployee: (row: T) => Row;
    visible?: boolean;
    readonly?: boolean;
    hidePerformanceRatings?: boolean;
    hideBusinessUnits?: boolean;
    formatDate: (date: Date | string, format: DateFormats) => string;
  }
): Column<T>[] => {
  const genderColumn: Column<T> = {
    id: "gender",
    name: t("components.ui.format-external-employee-fields-columns.gender"),
    sortable: !params.readonly,
    visible: params.visible ?? false,
    format: (row) => {
      const externalEmployee = params.mapRowToExternalEmployee(row);

      if (!externalEmployee.gender) return <EmptyCell />;

      return <Typography variant="caption">{formatGender(t, externalEmployee.gender)}</Typography>;
    },
  };

  const hireDateColumn: Column<T> = {
    id: "hire-date",
    name: t("components.ui.format-external-employee-fields-columns.hire-date"),
    align: "right",
    sortable: !params.readonly,
    visible: params.visible ?? false,
    format: (row) => {
      const externalEmployee = params.mapRowToExternalEmployee(row);

      if (!externalEmployee.hireDate) return <EmptyCell />;

      return (
        <Typography variant="caption">{params.formatDate(externalEmployee.hireDate, DateFormats.FULL_DATE)}</Typography>
      );
    },
  };

  const performanceReviewRatingColumn: Column<T> | undefined = !params.hidePerformanceRatings
    ? {
        id: "performance-review-rating",
        name: t("components.ui.format-external-employee-fields-columns.performance"),
        sortable: !params.readonly,
        visible: params.visible ?? false,
        format: (row) => {
          const externalEmployee = params.mapRowToExternalEmployee(row);

          if (!externalEmployee.performanceReviewRating) {
            return <EmptyCell />;
          }

          return <Typography variant="caption">{externalEmployee.performanceReviewRating.name}</Typography>;
        },
      }
    : undefined;

  const businessUnitColumns: Column<T>[] = !params.hideBusinessUnits
    ? [
        {
          id: "business-unit",
          name: t("components.ui.format-external-employee-fields-columns.business-unit"),
          sortable: !params.readonly,
          visible: params.visible ?? false,
          format: (row) => {
            const externalEmployee = params.mapRowToExternalEmployee(row);

            if (!externalEmployee.businessUnit) return <EmptyCell />;

            return <Typography variant="caption">{externalEmployee.businessUnit}</Typography>;
          },
        },
      ]
    : [];

  const additionalFieldsColumns: Column<T>[] = params.additionalFields
    ? params.additionalFields.map((additionalField) => ({
        id: `additional-field-${additionalField.id}`,
        name: additionalField.name,
        visible: params.visible ?? false,
        align: isIn(additionalField.nature, [
          AdditionalFieldNature.NUMBER,
          AdditionalFieldNature.DATE,
          AdditionalFieldNature.PERCENTAGE,
        ])
          ? "right"
          : "left",
        format: (row: T) => {
          const value = params
            .mapRowToExternalEmployee(row)
            .additionalFieldValues?.find(
              (additionalFieldValue) => additionalFieldValue.additionalFieldId === additionalField.id
            );

          if (!value) return <EmptyCell />;

          return <Typography variant="caption">{formatAdditionalFieldValue(value, additionalField.nature)}</Typography>;
        },
      }))
    : [];

  const managerColumn: Column<T> = {
    id: "manager",
    name: t("components.employee.employee-level.track.MANAGER"),
    sortable: !params.readonly,
    visible: params.visible ?? false,
    format: (row) => {
      const externalEmployee = params.mapRowToExternalEmployee(row);

      if (!externalEmployee.manager) {
        return <EmptyCell />;
      }

      return <Typography variant="caption">{formatExternalEmployeeName(externalEmployee.manager)}</Typography>;
    },
  };

  return compact([
    genderColumn,
    hireDateColumn,
    managerColumn,
    performanceReviewRatingColumn,
    ...businessUnitColumns,
    ...additionalFieldsColumns,
  ]);
};
