import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { mixed, object } from "yup";
import { api } from "~/lib/api";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { FETCH_IMPERSONATION_ACCESS_HISTORY_QUERY_KEY } from "~/pages/api/auth/company-impersonation/fetch-impersonation-access-history";
import { FETCH_IMPERSONATION_ACCESS_REQUEST_QUERY_KEY } from "~/pages/api/auth/company-impersonation/find-impersonation-access-request";
import { HAS_IMPERSONATION_ACCESS_QUERY_KEY } from "~/pages/api/auth/company-impersonation/has-impersonation-access";
import { approveImpersonationAccess } from "~/services/impersonation/approve-impersonation-access";

export const IMPERSONATION_ACCESS_TYPE = {
  PERMANENT: "PERMANENT",
  TEMPORARY: "TEMPORARY",
} as const;

export type ImpersonationAccessType = (typeof IMPERSONATION_ACCESS_TYPE)[keyof typeof IMPERSONATION_ACCESS_TYPE];

export const ApproveImpersonationAccessSchema = object({
  accessType: mixed().oneOf(Object.values(IMPERSONATION_ACCESS_TYPE)).required(),
});

export type ApproveImpersonationAccessSchemaInput = YupOutputType<typeof ApproveImpersonationAccessSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input: ApproveImpersonationAccessSchemaInput = ApproveImpersonationAccessSchema.validateSync(req.body, {
    abortEarly: false,
  });

  await approveImpersonationAccess(req, input);

  res.status(HttpStatus.CREATED).json({ message: "OK" });
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSettings: true,
  },
});

export const useApproveImpersonationAccessMutation = createMutation<
  typeof handler,
  typeof ApproveImpersonationAccessSchema
>({
  path: "/api/auth/company-impersonation/approve-impersonation-access",
  schema: ApproveImpersonationAccessSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, HAS_IMPERSONATION_ACCESS_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_IMPERSONATION_ACCESS_HISTORY_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_IMPERSONATION_ACCESS_REQUEST_QUERY_KEY);
    },
  }),
});
