import classNames from "classnames";
import React, { type HTMLAttributes } from "react";
import { type IconName, icons } from "~/components/ui/icons";

type Props = HTMLAttributes<SVGElement> & {
  name: IconName;
  size?: keyof typeof Sizes;
};

const Sizes = {
  "xs": "w-2 h-2",
  "sm": "w-3 h-3",
  "md": "w-4 h-4",
  "lg": "w-5 h-5",
  "xl": "w-6 h-6",
  "2xl": "w-7 h-7",
  "3xl": "w-8 h-8",
  "4xl": "w-10 h-10",
};

export const Icon: React.FC<Props> = ({ name, size = "md", className, ...svgProps }) => {
  const svg = icons[name]();
  const sizeClass = Sizes[size];

  return React.cloneElement(svg, {
    ...svgProps,
    className: classNames(className, sizeClass),
  });
};
