import { type Prisma } from "@prisma/client";
import { mapSeries } from "bluebird";
import { compact, keyBy } from "lodash";
import { type AppContext } from "~/lib/context";
import { logError, logInfo } from "~/lib/logger";

export const externalEmployeeSelectForSyncHierarchicalRelationships = {
  id: true,
  externalId: true,
  managerExternalEmployeeId: true,
  companyId: true,
} satisfies Prisma.ExternalEmployeeSelect;

type ExternalEmployeeForSyncHierarchicalRelationships = Prisma.ExternalEmployeeGetPayload<{
  select: typeof externalEmployeeSelectForSyncHierarchicalRelationships;
}>;

export type ExternalEmployeeWithManagerId = ExternalEmployeeForSyncHierarchicalRelationships & {
  managerExternalId: string | undefined;
};

export const syncHierarchicalRelationships = async (
  ctx: AppContext,
  externalEmployeesWithManagerId: Array<ExternalEmployeeWithManagerId>,
  companyId: number
) => {
  const externalEmployeesByExternalId = keyBy(externalEmployeesWithManagerId, ({ externalId }) => externalId);

  await mapSeries(externalEmployeesWithManagerId, async ({ managerExternalId, ...externalEmployee }) => {
    const managerExternalEmployee = managerExternalId ? externalEmployeesByExternalId[managerExternalId] : undefined;

    // We don't remove manager relationships when they disappear from the HRIS as they may have been defined on Figures
    if (!managerExternalEmployee) {
      return;
    }

    try {
      if (managerExternalEmployee.id !== externalEmployee.managerExternalEmployeeId) {
        await ctx.prisma.externalEmployee.update({
          where: { id: externalEmployee.id },
          data: { managerExternalEmployeeId: managerExternalEmployee.id },
        });
      }

      const permissions = await ctx.prisma.userPermissions.findUnique({
        where: { externalEmployeeId: managerExternalEmployee.id },
        select: { id: true },
      });

      if (!permissions) {
        return;
      }

      await ctx.prisma.userPermissions.update({
        where: { id: permissions.id },
        data: { isManager: true },
      });
    } catch (error) {
      logError(ctx, "[hris] Couldn't update employee manager", {
        companyId: externalEmployee.companyId,
        employee: externalEmployee,
        managerExternalId,
        error,
      });
    }
  });

  const managersWithNoManagees = await ctx.prisma.userPermissions.findMany({
    where: {
      isManager: true,
      externalEmployee: {
        id: {
          notIn: compact(
            externalEmployeesWithManagerId.map(({ managerExternalEmployeeId }) => managerExternalEmployeeId)
          ),
        },
        companyId,
      },
    },
    select: {
      id: true,
      externalEmployeeId: true,
      externalEmployee: {
        select: {
          _count: {
            select: { managees: true },
          },
        },
      },
    },
  });

  await mapSeries(managersWithNoManagees, async ({ id, externalEmployeeId, externalEmployee }) => {
    if (externalEmployee && externalEmployee._count.managees === 0) {
      logInfo(ctx, "[hris] Removing manager permissions", { externalEmployeeId });

      await ctx.prisma.userPermissions.update({
        where: { id },
        data: { isManager: false },
      });
    }
  });
};
