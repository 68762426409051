import { OpenInNew } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { chain, groupBy, orderBy } from "lodash";
import React from "react";
import { type Group, SelectInput } from "~/components/ui/core/BetterSelectInput";
import { Link } from "~/components/ui/core/Link";
import { TextInput } from "~/components/ui/core/TextInput";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import {
  EmployeeLevel,
  type EmployeeTrack,
  canAccessLevel,
  formatLevel,
  formatScopeLevel,
  formatTrack,
  getLevelMetaData,
} from "~/services/employee/employee-level";

type Event = {
  target: {
    name?: string;
    value: EmployeeLevel[];
  };
};

type Props = {
  name?: string;
  value: EmployeeLevel[];
  onChange: (event: Event) => void;
  onClose?: () => void;
  noFilter?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  dense?: boolean;
  placeholder?: string;
  includeMetaLevels?: boolean;
  excludedLevels?: EmployeeLevel[];
  useAdvancedLevels?: boolean;
  className?: string;
};

export const LevelSelector: React.FC<Props> = ({
  name,
  value,
  onChange,
  onClose,
  noFilter = false,
  multiple = false,
  disabled = false,
  dense = false,
  placeholder,
  includeMetaLevels = false,
  excludedLevels,
  useAdvancedLevels = true,
  className,
}) => {
  const { t } = useI18n();
  const { user } = useSession();

  const formatedPlaceholder = placeholder ?? t("components.ui.level-selector.access-to-all-levels");

  const levels = chain(EmployeeLevel)
    .filter((level) => {
      const levelDetails = getLevelMetaData(level);

      if (!useAdvancedLevels && levelDetails.advanced) {
        return false;
      }

      if (!includeMetaLevels && levelDetails.meta) {
        return false;
      }

      return true;
    })
    .filter((level) => {
      if (noFilter) {
        return true;
      }

      return canAccessLevel(t, user, level);
    })
    .mapValues((level) => {
      return {
        level,
        label: formatLevel(t, level),
        ...getLevelMetaData(level),
      };
    })
    .value();

  const levelsByTrack = groupBy(Object.values(levels), (level) => {
    return level.track;
  });

  const groups: Group<EmployeeLevel>[] = Object.values(levelsByTrack).map((levels) => {
    return {
      label: formatTrack(t, levels[0]?.track as EmployeeTrack),
      options: levels.map((level) => {
        return level.level;
      }),
    };
  });

  const formatOptionTooltip = (row: EmployeeLevel) => {
    return (
      <Stack spacing={2} className="p-3">
        <Typography variant="h5">{t("components.ui.level-selector.scope-of-influence")} </Typography>
        <Typography variant="caption" color="text.secondary">
          {formatScopeLevel(t, row).join(" ")}
        </Typography>

        <Link to="https://figures-hr.notion.site/Figures-Leveling-v2-f63ac304aaed461f82059559ab5d76cf" newTab>
          {t("components.ui.level-selector.more-details-here")}
          <OpenInNew fontSize="inherit" className="ml-1" />
        </Link>
      </Stack>
    );
  };

  return (
    <SelectInput
      dense={dense}
      disabled={disabled}
      excludedItems={excludedLevels}
      onClose={onClose}
      formatLabel={(levels) => {
        const label = orderBy(levels, (level) => {
          return Object.values(EmployeeLevel).indexOf(level);
        })
          .map((level) => formatLevel(t, level))
          .join(", ");

        return (
          <TextInput
            data-input
            readOnly
            className="w-full cursor-pointer select-none rounded"
            dense={dense}
            disabled={disabled}
            placeholder={
              formatedPlaceholder ??
              (multiple
                ? t("components.ui.level-selector.select-one-or-more-levels")
                : t("components.ui.level-selector.select-a-level"))
            }
            type="text"
            value={label ?? ""}
          />
        );
      }}
      formatOption={(level) => formatLevel(t, level)}
      formatOptionTooltip={formatOptionTooltip}
      groups={groups}
      multiple={multiple}
      name={name}
      optionToId={(level) => level}
      placeholder={
        multiple
          ? t("components.ui.level-selector.select-one-or-more-levels")
          : t("components.ui.level-selector.select-a-level")
      }
      value={value}
      className={className}
      onChange={onChange}
    />
  );
};
