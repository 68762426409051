import { type ExternalBenchmarkGroup, type Prisma, type SalaryBandLevel } from "@prisma/client";
import { intersectionBy } from "lodash";
import { type FetchSalaryBandLevelsResult } from "~/services/salary-bands/configuration/fetch-salary-band-levels";

type SalaryBandForFormat = Prisma.SalaryBandGetPayload<{
  select: {
    job: { select: { name: true } };
    location: { select: { name: true } };
  };
}>;

export const formatSalaryRangeName = (
  salaryBand: SalaryBandForFormat,
  salaryBandLevel: Pick<SalaryBandLevel, "name">
) => {
  return `${salaryBand.job.name} · ${salaryBand.location.name} · ${salaryBandLevel.name}`;
};

export const formatSalaryBandName = (salaryBand: SalaryBandForFormat) => {
  return `${salaryBand.job.name} · ${salaryBand.location.name}`;
};

export const formatExternalBenchmarkGroupName = (
  externalBenchmarkGroup: Pick<ExternalBenchmarkGroup, "jobName" | "locationName">
) => {
  return `${externalBenchmarkGroup.jobName} · ${externalBenchmarkGroup.locationName}`;
};

export const getSalaryBandSharedLevels = (
  salaryBandLevels: FetchSalaryBandLevelsResult,
  sharedLevels: FetchSalaryBandLevelsResult
) => {
  if (sharedLevels.length === 0) {
    return salaryBandLevels;
  }

  return intersectionBy(salaryBandLevels, sharedLevels, "id");
};
