import { EmployeeStatus, type ExternalEmployee, ExternalEmployeeStatus, type Prisma } from "@prisma/client";
import { compact } from "lodash";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { externalEmployeePanelSelect } from "~/components/external-employee/external-employee-panel-select";
import { value } from "~/components/helpers";
import { selectForExternalEmployeeBadgeList } from "~/components/ui/external-employee-badge-list";
import { locationSelectForSelector } from "~/components/ui/location-selector";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { logWarn } from "~/lib/logger";
import {
  buildPaginationResult,
  type OrderParams,
  type PaginationParams,
  prismaPaginationParams,
} from "~/lib/pagination";
import { getId, getKeys } from "~/lib/utils";
import { type CountExternalEmployeesByStatusInput } from "~/pages/api/external-employee/count-external-employees-by-status";
import { externalEmployeeFieldsSelectForDisplay } from "~/services/additional-field/get-all-additional-fields";
import { type FilterOptionsInput } from "~/services/employee-filter";
import {
  buildExternalEmployeeFilterWhereClauses,
  fetchExternalEmployeeFilterOptions,
} from "~/services/employee-filter/fetch-external-employee-filter-options";
import { companyEmployeesSelect } from "~/services/employee/employee-read";
import { externalEmployeeSelectForRemunerationComputation } from "~/services/external-employee";
import { matchForReconciliation } from "~/services/external-employee/reconciliation";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/select-external-employee-user-for-picture";
import { searchExternalEmployee } from "~/services/users/search";
export type FetchExternalEmployeesPaginationResult = AsyncReturnType<typeof fetchExternalEmployees>;

export type ExternalEmployeeRow = FetchExternalEmployeesPaginationResult["items"][number];

export type MappedEmployeeRow = NonNullable<ExternalEmployeeRow["mappedEmployee"]>;

export type ExistingEmployeeSuggestion = NonNullable<ExternalEmployeeRow["existingEmployeeSuggestions"]>[number];

export const countExternalEmployeesByStatus = async (
  ctx: AppContext,
  { query }: CountExternalEmployeesByStatusInput
) => {
  const user = getRequiredUser(ctx);

  const countByStatus = await ctx.prisma.externalEmployee.groupBy({
    by: ["status"],
    where: {
      companyId: user.companyId,
      ...(user.permissions.allowedCountries.length && {
        location: {
          countryId: {
            in: user.permissions.allowedCountries.map(getId),
          },
        },
      }),
      ...(query && { AND: searchExternalEmployee(query) }),
    },
    _count: true,
  });

  return getKeys(ExternalEmployeeStatus).map((status) => ({
    status,
    count: countByStatus.find((row) => row.status === status)?._count ?? 0,
  }));
};

const selectMappedEmployeeForFetch = {
  id: true,
  employeeNumber: true,
  firstName: true,
  lastName: true,
  gender: true,
  baseSalary: true,
  fixedBonus: true,
  fixedBonusPercentage: true,
  onTargetBonus: true,
  onTargetBonusPercentage: true,
  externalJobTitle: true,
  externalLevel: true,
  birthDate: true,
  hireDate: true,
  level: true,
  isFounder: true,
  source: true,
  updateReason: true,
  user: { select: { firstName: true, lastName: true, email: true } },
  currency: true,
  mappingLocation: {
    select: locationSelectForSelector,
  },
  jobId: true,
  locationId: true,
  job: {
    select: {
      id: true,
      name: true,
      description: true,
      descriptionTranslations: true,
      availableLevels: true,
    },
  },
  location: { select: { name: true, country: { select: { name: true } } } },
  picture: {
    select: {
      id: true,
      path: true,
      width: true,
      height: true,
    },
  },
  externalEmployee: {
    select: {
      performanceReviewRating: {
        select: {
          id: true,
          name: true,
        },
      },
    },
  },
} satisfies Prisma.ExternalEmployee$mappedEmployeeArgs["select"];

const selectExternalEmployeeForFetch = {
  ...externalEmployeePanelSelect,
  ...selectExternalEmployeeUserPicture,
  id: true,
  employeeNumber: true,
  firstName: true,
  lastName: true,
  birthDate: true,
  status: true,
  source: true,
  mappedEmployeeId: true,
  isFounder: true,
  performanceReviewRatingId: true,
  driftFields: true,
  email: true,
  fteDivider: true,
  job: {
    select: {
      id: true,
      name: true,
      mappedJobId: true,
      mappedJob: {
        select: {
          id: true,
          name: true,
          description: true,
          descriptionTranslations: true,
          availableLevels: true,
        },
      },
    },
  },
  level: { select: { id: true, name: true, mappedLevel: true } },
  location: {
    select: {
      id: true,
      name: true,
      country: { select: { name: true, alpha2: true } },
      mappedLocation: {
        select: locationSelectForSelector,
      },
    },
  },
  currency: true,
  mappedEmployee: {
    select: selectMappedEmployeeForFetch,
  },
  picture: true,
  ...externalEmployeeFieldsSelectForDisplay,
  ...externalEmployeeSelectForRemunerationComputation,
  ...selectExternalEmployeeUserPicture,
  businessUnit: true,
} satisfies Prisma.ExternalEmployeeSelect;

export const fetchExternalEmployees = async (
  ctx: AppContext,
  params: {
    query: string | null;
    status: ExternalEmployee["status"] | null;
    pagination: PaginationParams;
    order: OrderParams;
    filters?: FilterOptionsInput;
  }
) => {
  const user = getRequiredUser(ctx);

  const baseWhere: Prisma.ExternalEmployeeWhereInput = {
    companyId: user.companyId,
    status: params.status ?? ExternalEmployeeStatus.MAPPED,
    /**
     * User with country permission can only see employees from this/these country/ies
     * Filter external employees for user with permission
     */
    ...(user.permissions.allowedCountries.length && {
      location: {
        countryId: {
          in: user.permissions.allowedCountries.map(getId),
        },
      },
    }),
    ...(params.query && { AND: searchExternalEmployee(params.query) }),
  };

  const whereFilters = params.filters ? buildExternalEmployeeFilterWhereClauses(params.filters) : [];

  const where = {
    AND: compact([baseWhere, ...whereFilters]),
  };

  const direction = params.order.direction;
  const directionNullsLast = { sort: params.order.direction, nulls: "last" } as const;
  const orderBy = match<string | null, Prisma.Enumerable<Prisma.ExternalEmployeeOrderByWithRelationInput>>(
    params.order.column
  )
    .with("business-unit", () => ({ businessUnit: direction }))
    .with("source", () => ({ source: direction }))
    .with("employeeNumber", () => ({ employeeNumber: direction }))
    .with("name", () => [{ firstName: directionNullsLast }, { lastName: directionNullsLast }])
    .with("job", () => ({ job: { name: direction } }))
    .with("location", () => [{ location: { country: { name: direction } } }, { location: { name: direction } }])
    .with("gender", () => ({ gender: directionNullsLast }))
    .with("level", () => ({ level: { name: direction } }))
    .with("hire-date", () => ({ hireDate: directionNullsLast }))
    .with("fte", () => ({ fteDivider: direction }))
    .with("email", () => ({ email: direction }))
    .with("performance-review-rating", () => ({ performanceReviewRating: { position: direction } }))
    .otherwise((column) => {
      if (column) {
        logWarn(ctx, "[warn] Unhandled externalEmployee order column", { column });
      }

      return { employeeNumber: direction };
    });

  // Find all external employees that need mapping.
  const [externalEmployees, count, filterOptions] = await Promise.all([
    ctx.prisma.externalEmployee.findMany({
      where,
      ...prismaPaginationParams(params.pagination),
      orderBy,
      select: selectExternalEmployeeForFetch,
    }),
    ctx.prisma.externalEmployee.count({ where }),
    fetchExternalEmployeeFilterOptions(ctx, { where }),
  ]);

  // Filter the ones that are already mapped, or can't be reconciled.
  const externalEmployeesWithPotentialSuggestion = externalEmployees.filter((employee) => {
    if (employee.mappedEmployee) {
      return false;
    }

    return (
      !!employee.employeeNumber ||
      (!!employee.firstName && !!employee.lastName) ||
      (!!employee.hireDate && !!employee.job)
    );
  });

  // Find all existing employees of the company.
  const suggestions = externalEmployeesWithPotentialSuggestion.length
    ? await ctx.prisma.employee.findMany({
        where: {
          companyId: user.companyId,
          status: EmployeeStatus.LIVE,
        },
        include: {
          currency: true,
          job: {
            include: {
              family: true,
            },
          },
          location: {
            include: {
              country: true,
            },
          },
        },
      })
    : [];

  // Map the suggestions to the external employees.
  const externalEmployeesWithSuggestion = externalEmployees.map((employee) => {
    const existingEmployeeSuggestions = value(() => {
      // Skip suggestions if the external employee is already mapped
      if (employee.mappedEmployeeId) {
        return undefined;
      }

      return suggestions.filter((suggestion) =>
        matchForReconciliation({ externalEmployee: employee, suggestedEmployee: suggestion })
      );
    });

    return {
      ...employee,
      existingEmployeeSuggestions,
    };
  });

  return buildPaginationResult({
    items: externalEmployeesWithSuggestion,
    count,
    pagination: params.pagination,
    meta: {
      filterOptions,
    },
  });
};

export const fetchExternalEmployeeForForm = async (ctx: AppContext, externalEmployeeId: number) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalEmployee.findFirstOrThrow({
    where: { companyId: user.companyId, id: externalEmployeeId },
    include: {
      currency: true,
      picture: true,
      manager: {
        include: {
          picture: true,
          level: true,
          job: {
            include: {
              mappedJob: { include: { family: true } },
            },
          },
          ...selectExternalEmployeeUserPicture,
        },
      },
      job: {
        include: {
          mappedJob: { include: { family: true } },
        },
      },
      level: true,
      location: {
        include: {
          country: true,
          mappedLocation: { include: { country: true } },
        },
      },
      remunerationItems: { include: { nature: true } },
      mappedEmployee: { select: companyEmployeesSelect },
      offCycleReviewRequests: true,
      additionalFieldValues: {
        select: {
          id: true,
          dateValue: true,
          numberValue: true,
          stringValue: true,
          percentageValue: true,
          additionalField: {
            select: {
              id: true,
              integrationSettings: {
                select: {
                  source: true,
                },
              },
            },
          },
        },
      },
      performanceReviewRating: true,
      ...selectExternalEmployeeUserPicture,
    },
  });
};

export type ExternalEmployeeForForm = AsyncReturnType<typeof fetchExternalEmployeeForForm>;

export const fetchExternalEmployeesSampleByJob = async (ctx: AppContext, { jobId }: { jobId: number }) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalEmployee.findMany({
    select: selectForExternalEmployeeBadgeList,
    take: 5,
    where: {
      jobId,
      companyId: user.companyId,
    },
  });
};

export const fetchExternalEmployeesSampleByLevel = async (ctx: AppContext, { levelId }: { levelId: number }) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalEmployee.findMany({
    select: selectForExternalEmployeeBadgeList,
    take: 5,
    where: {
      levelId,
      companyId: user.companyId,
    },
  });
};

export const fetchExternalEmployeesSampleByLocation = async (
  ctx: AppContext,
  { locationId }: { locationId: number }
) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalEmployee.findMany({
    select: selectForExternalEmployeeBadgeList,
    take: 5,
    where: {
      locationId,
      companyId: user.companyId,
    },
  });
};
