import tracer from "dd-trace";
import { type AugmentedRequest } from "server";
import packageJson from "~/../package.json";
import { datadogEnv } from "~/lib/datadog/datadog-env";
import { flattenObject } from "~/lib/flatten-object";

if (tracer) {
  tracer.init({
    service: packageJson.name,
    version: packageJson.version,
    env: datadogEnv,
    logInjection: true,
    plugins: true,
    startupLogs: false,
    flushInterval: 100,
    runtimeMetrics: true,
    profiling: true,
  });

  tracer.use("redis", { service: `${packageJson.name}.redis` });
  tracer.use("ioredis", { service: `${packageJson.name}.ioredis` });
  tracer.use("winston", { service: `${packageJson.name}.winston` });
  tracer.use("koa", { service: `${packageJson.name}.koa` });
  tracer.use("next", {
    service: `${packageJson.name}.next`,
    hooks: {
      request: (span, req) => {
        if (!span || !req) {
          return;
        }

        const augmentedRequest = req as AugmentedRequest;

        if (!augmentedRequest || augmentedRequest.isStaticFile || augmentedRequest.isStatusApi) {
          return;
        }

        const query = flattenObject(augmentedRequest.parsedUrl?.query, "next.query");
        Object.entries(query).forEach(([key, value]) => {
          span.setTag(key, value);
        });

        const body = flattenObject(augmentedRequest.body, "next.body");
        Object.entries(body).forEach(([key, value]) => {
          span.setTag(key, value);
        });
      },
    },
  });
  tracer.use("http", { service: `${packageJson.name}.http` });
  tracer.use("http2", { service: `${packageJson.name}.http2` });
  tracer.use("dns", { service: `${packageJson.name}.dns` });
  tracer.use("pg", { service: `${packageJson.name}.pg` });
}

export const traceBusinessService = <T>(
  params: { serviceName: string; tags?: Record<string, unknown> },
  fn: () => Promise<T> | T
) => {
  return tracer.trace(
    "service.tracing",
    {
      tags: { "span.kind": "server", ...flattenObject(params.tags) },
      service: `${packageJson.name}.business-services`,
      resource: params.serviceName,
    },
    fn
  );
};

export default tracer;
