import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { type FetchCampaignResult, fetchCampaign } from "~/services/compensation-review/campaigns/fetch-campaign";

export const FetchCampaignSchema = object({
  campaignId: number().required(),
});

export type FetchCampaignInput = YupOutputType<typeof FetchCampaignSchema>;

const handler: NextApiHandler<FetchCampaignResult> = async (req, res) => {
  const input = FetchCampaignSchema.validateSync(req.body, { abortEarly: false });

  const campaign = await fetchCampaign(req, input);

  res.status(HttpStatus.OK).json(campaign);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessCompensationReview: true },
});

export const FETCH_CAMPAIGN_KEY: ApiRoute["pathname"] = "/api/compensation-review/campaigns/fetch-campaign";

export const useFetchCampaignQuery = createQuery<typeof handler, typeof FetchCampaignSchema>({
  path: FETCH_CAMPAIGN_KEY,
  schema: FetchCampaignSchema,
});
