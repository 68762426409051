import {
  type PerformanceReviewCycle,
  type PerformanceReviewIntegrationSettings,
  PerformanceReviewIntegrationSource,
} from "@prisma/client";
import { map } from "bluebird";
import { add, isAfter } from "date-fns";
import { chain, compact, orderBy } from "lodash";
import { type ParsedUrlQueryInput } from "querystring";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { logWarn } from "~/lib/logger";
import {
  type GetPerformanceReviews,
  type PerformanceReviewIntegrationConfig,
  type PerformanceReviewSourceConfig,
} from "~/lib/performance-review-integration";
import { buildExternalUrl } from "~/lib/url";
import { assertNotNil, assertProps, sleep } from "~/lib/utils";
import { type PerformanceReviewIntegrationDiagnostic } from "~/pages/api/sync-performance-reviews";

const base: PerformanceReviewIntegrationConfig = {
  source: PerformanceReviewIntegrationSource.CULTURE_AMP,
  companyId: 0,
  clientId: config.cultureAmp.clientId,
  clientSecret: config.cultureAmp.clientSecret,
  enabled: true,
};

export const cultureAmpConfigs: PerformanceReviewSourceConfig = {
  default: base,
};

type Credentials = {
  clientId: string;
  clientSecret: string;
};

const authorisations = new Map<string, { accessToken: string; expiresAt: Date }>();

type CultureAmpAccessToken = {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
};

const authenticate = async (credentials: Credentials) => {
  const res = await fetch(`${config.cultureAmp.apiUrl}/v1/oauth2/token`, {
    method: "POST",
    body: new URLSearchParams({
      client_id: credentials.clientId,
      client_secret: credentials.clientSecret,
      grant_type: "client_credentials",
      scope: `target-entity:${config.cultureAmp.apiScope}:employees-read,performance-evaluations-read`,
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept": "application/json",
    },
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(`Culture Amp authentication failed: [${json.error_reason}] ${json.error_description}`);
  }

  return json as CultureAmpAccessToken;
};

type CultureAmpResponse<T extends CultureAmpApiEmployee | CultureAmpApiPerformanceCycle | CultureAmpApiManagerReview> =
  {
    data: T[];
    pagination?: { afterKey: string };
  };

const MAX_CULTURE_AMP_API_RETRIES = 5;

export const cultureAmpFetch = async <
  T extends CultureAmpApiEmployee | CultureAmpApiPerformanceCycle | CultureAmpApiManagerReview,
>(
  credentials: Credentials,
  endpoint: string,
  params: { query?: ParsedUrlQueryInput; skipAuthentication?: boolean } = {}
): Promise<T[]> => {
  const handleRequest = async () => {
    const needsRefresh = value(() => {
      if (params.skipAuthentication) {
        return false;
      }

      const authorisation = authorisations.get(credentials.clientId);
      if (!authorisation) {
        return true;
      }

      return isAfter(new Date(), authorisation.expiresAt);
    });

    if (needsRefresh) {
      const token = await authenticate(credentials);
      const expiresAt = add(new Date(), { seconds: token.expires_in });

      authorisations.set(credentials.clientId, {
        accessToken: token.access_token,
        expiresAt,
      });
    }

    return fetch(buildExternalUrl(`${config.cultureAmp.apiUrl}/${endpoint}`, params.query), {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authorisations.get(credentials.clientId)?.accessToken}`,
      },
    });
  };

  let res = await handleRequest();

  // It might happen that we reach the rate limit of Culture Amp API
  if (!res.ok) {
    let nbRetry = 0;

    while (nbRetry++ < MAX_CULTURE_AMP_API_RETRIES && (res.status === 503 || res.status === 429)) {
      await sleep(nbRetry * 1000);

      res = await handleRequest();
    }

    if (!res.ok) {
      throw new Error(`CultureAmp error: [${res.status}] - ${res.statusText}`);
    }
  }

  const json: CultureAmpResponse<T> = await res.json();

  if (!json.pagination?.afterKey) {
    return json.data as T[];
  }

  const nextPage = await cultureAmpFetch<T>(credentials, endpoint, {
    query: {
      after_key: json.pagination.afterKey,
    },
    skipAuthentication: params.skipAuthentication,
  });

  return [...json.data, ...nextPage] as T[];
};

export const filterValidCultureAmpPerformanceReviewCycles = (cycles: CultureAmpApiPerformanceCycle[]) => {
  return cycles.filter(({ state }) => state === "active" || state === "closed");
};

export const getCultureAmpPerformanceReviews: GetPerformanceReviews = async (
  ctx,
  integrationSettings,
  performanceReviewCycle
) => {
  const safeIntegrationSettings = assertSafeCultureAmpIntegrationSettings(integrationSettings);

  const [employees, performanceCycles] = await Promise.all([
    getCultureAmpEmployees(safeIntegrationSettings),
    getSelectedCultureAmpPerformanceCycles(safeIntegrationSettings, performanceReviewCycle),
  ]);

  const cycles = filterValidCultureAmpPerformanceReviewCycles(performanceCycles);

  if (cycles.length === 0) {
    return [];
  }

  const managerReviewsForCycles = await map(
    cycles,
    async (cycle) => {
      return getCultureAmpManagerReviewsForPerformanceCycle(safeIntegrationSettings, {
        performanceCycleId: cycle.id,
      });
    },
    { concurrency: 5 }
  );

  const validManagerReviews = managerReviewsForCycles
    .flat()
    .filter((review) => review.performanceRating.rating)
    .filter((review) => review.completedAt);

  const performanceReviewData = await map(
    employees,
    async (employee) => {
      const externalEmployee = await mapCultureAmpEmployeeToExternalEmployee(ctx, employee);

      if (!externalEmployee) {
        logWarn(ctx, "[culture-amp] No external employee found for CultureAmp employee", { employeeId: employee.id });
        return null;
      }

      const managerReview = value(() => {
        const managerReviews = validManagerReviews.filter((review) => review.employeeId === employee.id);

        if (managerReviews.length === 0) {
          return null;
        }

        return orderBy(managerReviews, (review) => review.completedAt, "desc")[0];
      });

      if (!managerReview) {
        logWarn(ctx, "[culture-amp] No manager review found for employee", { employeeId: employee.id });
        return null;
      }

      const rating = assertNotNil(managerReview.performanceRating.rating);

      return {
        name: rating.title,
        position: rating.value,
        description: rating.description,
        externalEmployeeId: externalEmployee.id,
      };
    },
    { concurrency: 10 }
  );

  return chain(compact(performanceReviewData))
    .groupBy((review) => review.name)
    .map((reviews) => {
      const review = reviews[0] as (typeof reviews)[0];
      return {
        name: review.name,
        description: review.description,
        position: review.position,
        externalEmployeeIds: reviews.map((review) => review.externalEmployeeId),
      };
    })
    .value();
};

export type CultureAmpApiEmployee = {
  id: string;
  name: string;
  birthDate: string;
  startDate: string;
  endDate: string;
  employeeIdentifier: string;
  email: string;
};

export const getCultureAmpEmployees = async (credentials: Credentials) => {
  return cultureAmpFetch<CultureAmpApiEmployee>(credentials, "v1/employees");
};

export type CultureAmpApiPerformanceCycle = {
  id: string;
  name: string;
  state: "active" | "closed" | "ready";
  createdAt: string;
  updatedAt: string;
  processedAt: string;
};

export const getCultureAmpPerformanceCycles = async (credentials: Credentials) => {
  const cycles = await cultureAmpFetch<CultureAmpApiPerformanceCycle>(credentials, "v1/performance-cycles");

  return cycles.filter((cycle) => cycle.state === "closed");
};

export const getSelectedCultureAmpPerformanceCycles = async (
  credentials: Credentials,
  performanceCycle: Pick<PerformanceReviewCycle, "selectedCycleIds">
) => {
  const cycleIds = await value(async () => {
    if (performanceCycle.selectedCycleIds.length === 0) {
      const cycles = await getCultureAmpPerformanceCycles(credentials);
      return cycles.map((cycle) => cycle.id);
    }

    return performanceCycle.selectedCycleIds;
  });

  return map(
    cycleIds,
    async (performanceCycleId) => {
      return (await cultureAmpFetch(
        credentials,
        `v1/performance-cycles/${performanceCycleId}`
      )) as unknown as Promise<CultureAmpApiPerformanceCycle>;
    },
    { concurrency: 5 }
  );
};

export type CultureAmpApiManagerReview = {
  performanceCycleId: string;
  managerReviewId: string;
  employeeId: string;
  managerId: string;
  performanceRating: {
    ratingQuestion: {
      title: string;
      description: string;
    };
    ratingBuckets: {
      id: string;
      title: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    }[];
    rating?: {
      id: string;
      title: string;
      description: string;
      value: number;
      createdAt: string;
      updatedAt: string;
    };
  };
  processedAt: string;
  createdAt: string;
  updatedAt: string;
  completedAt: string;
};

export const getCultureAmpManagerReviewsForPerformanceCycle = async (
  credentials: Credentials,
  params: { performanceCycleId: string }
) => {
  return cultureAmpFetch<CultureAmpApiManagerReview>(
    credentials,
    `v1/performance-cycles/${params.performanceCycleId}/manager-reviews`
  );
};

export const getCultureAmpDiagnostic = async (
  ctx: AppContext,
  credentials: Credentials
): Promise<PerformanceReviewIntegrationDiagnostic> => {
  try {
    const employees = await getCultureAmpEmployees(credentials);

    if (employees.length === 0) {
      return {
        connected: false,
        error: "We could not find any employees within your CultureAmp account",
      } as const;
    }

    return { connected: true } as const;
  } catch (error) {
    return { connected: false, error: error.message } as const;
  }
};

export const assertSafeCultureAmpIntegrationSettings = (integrationSettings: PerformanceReviewIntegrationSettings) =>
  assertProps(integrationSettings, ["clientId", "clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeCultureAmpIntegrationSettings>;

export const mapCultureAmpEmployeeToExternalEmployee = async (ctx: AppContext, employee: CultureAmpApiEmployee) => {
  if (employee.employeeIdentifier) {
    const externalEmployee = await ctx.prisma.externalEmployee.findFirst({
      where: { employeeNumber: employee.employeeIdentifier },
      select: { id: true },
    });

    if (externalEmployee) {
      return externalEmployee;
    }
  }

  if (employee.email) {
    const externalEmployee = await ctx.prisma.externalEmployee.findFirst({
      where: { email: employee.email },
      select: { id: true },
    });

    if (externalEmployee) {
      return externalEmployee;
    }
  }

  const nameParts = employee.name.split(" ");

  const matchingEmployees = await ctx.prisma.externalEmployee.findMany({
    where: {
      OR: [{ lastName: { in: nameParts, mode: "insensitive" } }, { firstName: { in: nameParts, mode: "insensitive" } }],
    },
    select: { id: true, firstName: true, lastName: true },
  });

  return matchingEmployees.find((externalEmployee) => {
    return employee.name === `${externalEmployee.firstName} ${externalEmployee.lastName}`;
  });
};
