import { type CompensationReviewCampaign, ExternalEmployeeStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { assertNotNil } from "~/lib/utils";
import {
  type CompensationReviewScope,
  campaignScope,
  prismaCompensationReviewScope,
} from "~/services/compensation-review/compensation-review-scope";
import { getDefaultCurrency } from "~/services/currency";

export const refreshCampaignCurrencies = async (
  ctx: AppContext,
  params: {
    campaign: Pick<CompensationReviewCampaign, "id" | "companyId">;
  }
) => {
  const externalEmployeesCurrencies = await ctx.prisma.externalEmployee.groupBy({
    where: { companyId: params.campaign.companyId, status: { not: ExternalEmployeeStatus.SKIPPED } },
    by: "currencyId",
  });

  const companyCurrency = await getDefaultCurrency(ctx);

  const currencies = await ctx.prisma.currency.findMany({
    where: { id: { in: externalEmployeesCurrencies.map((row) => row.currencyId).concat(companyCurrency.id) } },
    select: {
      id: true,
      euroExchangeRate: true,
    },
  });

  const compensationReviewCurrencies = await mapSeries(currencies, async (currency) => {
    return findOrCreateCompensationReviewCurrency(ctx, {
      scope: campaignScope(params.campaign.id),
      companyId: params.campaign.companyId,
      currencyId: currency.id,
      euroExchangeRate: currency.euroExchangeRate,
    });
  });

  const campaignCurrency = assertNotNil(
    compensationReviewCurrencies.find((currency) => currency.currencyId === companyCurrency.id)
  );

  return {
    compensationReviewCurrencies,
    campaignCurrency,
  };
};

export const findOrCreateCompensationReviewCurrency = async (
  ctx: AppContext,
  params: {
    scope: CompensationReviewScope;
    companyId: number;
    currencyId: number;
    euroExchangeRate: number;
  }
) => {
  const existingCurrency = await ctx.prisma.compensationReviewCurrency.findFirst({
    where: {
      ...prismaCompensationReviewScope(params.scope),
      companyId: params.companyId,
      currencyId: params.currencyId,
    },
    select: { id: true, currencyId: true, euroExchangeRate: true },
  });

  if (existingCurrency) {
    return existingCurrency;
  }

  return ctx.prisma.compensationReviewCurrency.create({
    data: {
      ...prismaCompensationReviewScope(params.scope),
      companyId: params.companyId,
      currencyId: params.currencyId,
      euroExchangeRate: params.euroExchangeRate,
      isReferenceCurrency: true,
    },
    select: { id: true, currencyId: true, euroExchangeRate: true },
  });
};
