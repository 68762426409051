import { ArrowForward, CloudSync } from "@mui/icons-material";
import { Card, Stack, Typography } from "@mui/material";
import { type Currency, ExternalEmployeeStatus, QueueJobStatus, UserRole } from "@prisma/client";
import { keyBy, keys, maxBy } from "lodash";
import { type NextPage } from "next";
import { useRouter } from "next/router";
import { type GetServerSideProps } from "nextjs-routes";
import { useEffect } from "react";
import { type AsyncReturnType } from "type-fest";
import { ExternalEmployeesList } from "~/components/account/imported-employees/external-employees-list";
import { HeaderLayout } from "~/components/layout/header-layout";
import { SynchronizationErrorAlert } from "~/components/synchronization/error-alert";
import { NextLinkComposed } from "~/components/ui/core/Link";
import { Button } from "~/components/ui/core/button";
import { EmployeeIssuesChip } from "~/components/ui/employee/employee-issues-chip";
import { useExternalEmployeesSyncProgress } from "~/hooks/useExternalEmployeesSyncSnackbar";
import { usePermissions } from "~/hooks/usePermissions";
import { useSession } from "~/hooks/useSession";
import { formatRelativeDate } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";
import { parseOrderParams, parsePaginationParams } from "~/lib/pagination";
import { parseString } from "~/lib/query-params";
import { ssr } from "~/lib/ssr";
import { type CompanyLayoutRow, fetchCompany } from "~/pages/account";
import { fetchIssueCount } from "~/pages/account/data-validation/employees-data";
import { useGetAllAdditionalFieldQuery } from "~/pages/api/additional-field/get-all-additional-field";
import { useCountExternalEmployeesByStatusQuery } from "~/pages/api/external-employee/count-external-employees-by-status";
import { useFetchAllImportedEmployeesQuery } from "~/pages/api/imported-employees/fetch-all-imported-employees";
import { useFetchPerformanceReviewRatingsQuery } from "~/pages/api/performance-review/fetch-performance-review-ratings";
import { useSyncExternalEmployeesMutation } from "~/pages/api/sync-external-employees";
import { useSyncPerformanceReviewsMutation } from "~/pages/api/sync-performance-reviews";
import {
  getAllAdditionalFields,
  type GetAllAdditionalFieldsResult,
} from "~/services/additional-field/get-all-additional-fields";
import { getDefaultCurrency } from "~/services/currency";
import { parseSelectedFilterOptions } from "~/services/employee-filter";
import {
  countExternalEmployeesByStatus,
  fetchExternalEmployees,
  type FetchExternalEmployeesPaginationResult,
} from "~/services/external-employee/external-employee-read";
import {
  type FetchPerformanceReviewRatings,
  fetchPerformanceReviewRatings,
} from "~/services/performance-review/find-performance-review-ratings";

type Count = AsyncReturnType<typeof countExternalEmployeesByStatus>[number];

export const getStatusToShow = (
  queryParameter: string | null,
  countsByStatus: Partial<Record<ExternalEmployeeStatus, Count>>
): ExternalEmployeeStatus => {
  if (queryParameter && keys(ExternalEmployeeStatus).includes(queryParameter)) {
    const queryStatus = queryParameter as ExternalEmployeeStatus;

    if (!!countsByStatus[queryStatus]?.count) {
      return queryStatus;
    }
  }

  if (!!countsByStatus[ExternalEmployeeStatus.UNMAPPED]?.count) {
    return ExternalEmployeeStatus.UNMAPPED;
  }
  if (!!countsByStatus[ExternalEmployeeStatus.NEEDS_REMAPPING]?.count) {
    return ExternalEmployeeStatus.NEEDS_REMAPPING;
  }
  if (!!countsByStatus[ExternalEmployeeStatus.MAPPED]?.count) {
    return ExternalEmployeeStatus.MAPPED;
  }
  if (!!countsByStatus[ExternalEmployeeStatus.SKIPPED]?.count) {
    return ExternalEmployeeStatus.SKIPPED;
  }

  if (!!countsByStatus[ExternalEmployeeStatus.PARTIAL]?.count) {
    return ExternalEmployeeStatus.PARTIAL;
  }

  return ExternalEmployeeStatus.UNMAPPED;
};

const handler: GetServerSideProps<Props, "/account/imported-employees"> = async (ctx) => {
  const company = await fetchCompany(ctx.req);
  const currency = await getDefaultCurrency(ctx.req);
  const query = parseString(ctx.query, "query");
  const employeesCountByStatus = await countExternalEmployeesByStatus(ctx.req, { query });
  const countsByStatus = keyBy(employeesCountByStatus, "status");
  const status = getStatusToShow(parseString(ctx.query, "status"), countsByStatus);
  const initialExternalEmployees = await fetchExternalEmployees(ctx.req, {
    query,
    status,
    pagination: parsePaginationParams(ctx.query),
    order: parseOrderParams(ctx.query, { defaultColumn: "employeeNumber" }),
    filters: parseSelectedFilterOptions(ctx.query),
  });
  const performanceReviewRatings = await fetchPerformanceReviewRatings(ctx.req, { companyId: company.id });
  const issueCount = await fetchIssueCount(ctx.req, { companyId: company.id });
  const additionalFields = await getAllAdditionalFields(ctx.req);

  return {
    props: {
      currency,
      status,
      company,
      additionalFields,
      initialExternalEmployees,
      employeesCountByStatus,
      initialPerformanceReviewRatings: performanceReviewRatings,
      issueCount,
    },
  };
};

export const getServerSideProps = ssr(handler, {
  authentication: {
    canAccessImportedEmployees: true,
  },
});

type Props = {
  currency: Currency;
  company: CompanyLayoutRow;
  additionalFields: GetAllAdditionalFieldsResult;
  initialExternalEmployees: FetchExternalEmployeesPaginationResult;
  employeesCountByStatus: { status: ExternalEmployeeStatus; count: number }[];
  status: ExternalEmployeeStatus;
  initialPerformanceReviewRatings: FetchPerformanceReviewRatings;
  issueCount: number;
};

const Page: NextPage<Props> = ({
  currency,
  company,
  additionalFields: initialAdditionalFields,
  initialExternalEmployees,
  status,
  initialPerformanceReviewRatings,
  employeesCountByStatus: initialEmployeesCountByStatus,
  issueCount,
}) => {
  const router = useRouter();
  const { user } = useSession();
  const { t } = useI18n();

  const { permissions } = usePermissions();

  const { data: performanceReviewRatings } = useFetchPerformanceReviewRatingsQuery({
    input: { companyId: company.id },
    options: { initialData: initialPerformanceReviewRatings, enabled: permissions.canAccessIntegrations },
  });

  const syncPerformanceReviews = useSyncPerformanceReviewsMutation();

  const syncExternalEmployees = useSyncExternalEmployeesMutation();

  const isCurrentUserAdmin = user?.isSuperAdmin || user?.permissions.role === UserRole.ADMIN;
  const hasIntegration = company.integrationSettings.length > 0;
  const showUpdateFromIntegrationButton = hasIntegration && isCurrentUserAdmin;

  const { lastSynchronisedAt, lastSyncCreated, lastSyncDeleted, lastSyncUpdated } =
    maxBy(company.integrationSettings, (integration) => {
      return integration?.lastSynchronisedAt;
    }) || {};

  const { data: additionalFields } = useGetAllAdditionalFieldQuery({
    options: {
      initialData: initialAdditionalFields,
      enabled: permissions.canAccessIntegrations,
    },
  });

  const { data: employeesCountByStatus } = useCountExternalEmployeesByStatusQuery({
    input: { query: parseString(router.query, "query") },
    options: {
      initialData: initialEmployeesCountByStatus,
      enabled: permissions.canAccessRawData && permissions.canAccessIntegrations,
    },
  });

  const { data: externalEmployees, refetch: refetchExternalEmployees } = useFetchAllImportedEmployeesQuery({
    options: {
      initialData: initialExternalEmployees,
    },
    input: {
      query: parseString(router.query, "query"),
      status,
      pagination: parsePaginationParams(router.query),
      order: parseOrderParams(router.query, { defaultColumn: "employeeNumber" }),
      filters: parseSelectedFilterOptions(router.query),
    },
  });

  const { triggerSyncPolling, inProgress, jobStatus: externalSyncEmployeesStatus } = useExternalEmployeesSyncProgress();

  const onUpdateFromIntegrationsClick = async () => {
    await syncExternalEmployees.mutateAsync({ companyId: company.id });

    triggerSyncPolling();
  };

  useEffect(() => {
    if (externalSyncEmployeesStatus !== QueueJobStatus.COMPLETED) return;

    const syncPerformanceReviewsForEmployees = async () => {
      if (company.performanceReviewIntegrationSettings.length > 0) {
        await syncPerformanceReviews.mutateAsync({
          companyId: company.id,
          selectedExternalPerformanceCycleIds: null,
          performanceReviewScope: null,
        });
      }

      await refetchExternalEmployees();
    };

    void syncPerformanceReviewsForEmployees();
  }, [externalSyncEmployeesStatus]);

  return (
    <HeaderLayout
      title={
        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography variant="h1">{t("pages.imported-employees.figures-imported-employee-data")}</Typography>

          {issueCount > 0 && permissions.canManageUsers && <EmployeeIssuesChip issuesCount={issueCount} />}
        </Stack>
      }
      breadcrumbs={[{ disabled: true, text: t("components.layout.navbar.account") }]}
      extraActions={
        <Stack direction="row" spacing={2}>
          {showUpdateFromIntegrationButton && (
            <Button
              variant="outlined"
              isLoading={inProgress}
              startIcon={<CloudSync />}
              onClick={onUpdateFromIntegrationsClick}
            >
              {t("pages.imported-employees.update-from-integrations")}
            </Button>
          )}

          {isCurrentUserAdmin && (
            <Button
              variant="outlined"
              endIcon={<ArrowForward />}
              component={NextLinkComposed}
              to={{
                pathname: "/global-spreadsheet-import",
                query: { successUrl: router.asPath },
              }}
            >
              {t("pages.imported-employees.spreadsheet-update")}
            </Button>
          )}
        </Stack>
      }
    >
      <Card component={Stack} spacing={4}>
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Stack spacing={1} className="max-w-lg">
            <Typography variant="caption" color="text.secondary">
              {t("pages.imported-employees.these-employees-have-been-fetched-from")}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {t("pages.imported-employees.click-on-an-employee-to-start-mapping-them")}
            </Typography>
          </Stack>
          <Stack spacing={1} className="text-right">
            {lastSynchronisedAt && (
              <Typography variant="caption">
                {t("pages.imported-employees.last-synchronised", {
                  date: formatRelativeDate(lastSynchronisedAt, user?.locale),
                })}
              </Typography>
            )}
            {(lastSyncCreated ?? 0) > 0 && (
              <Typography variant="caption">
                {t("pages.imported-employees.new-employees-detected", {
                  lastSyncCreated,
                })}
              </Typography>
            )}
            {(lastSyncUpdated ?? 0) > 0 && (
              <Typography variant="caption">
                {t("pages.imported-employees.existing-employees-updated", {
                  lastSyncUpdated,
                })}
              </Typography>
            )}

            {(lastSyncDeleted ?? 0) > 0 && (
              <Typography variant="caption">
                {t("pages.imported-employees.unmapped-employees-deleted", {
                  lastSyncDeleted,
                })}
              </Typography>
            )}
          </Stack>
        </Stack>

        {externalSyncEmployeesStatus === QueueJobStatus.FAILED && (
          <SynchronizationErrorAlert hint={t("pages.imported-employees.try-update-from")} className="max-w-4xl" />
        )}

        <ExternalEmployeesList
          performanceReviewRatings={performanceReviewRatings}
          status={status}
          currency={currency}
          externalEmployees={externalEmployees}
          refetchExternalEmployees={refetchExternalEmployees}
          additionalFields={additionalFields}
          useAdvancedLevels={company.useAdvancedLevels}
          externalEmployeesCountByStatus={employeesCountByStatus}
        />
      </Card>
    </HeaderLayout>
  );
};

export default Page;
