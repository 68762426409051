import { ExternalEmployeeStatus, type Prisma } from "@prisma/client";
import { compact } from "lodash";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { logWarn } from "~/lib/logger";
import {
  buildPaginationResult,
  type OrderParams,
  type PaginationParams,
  prismaPaginationParams,
} from "~/lib/pagination";
import { getId } from "~/lib/utils";
import { type FilterOptionsInput } from "~/services/employee-filter";
import {
  buildExternalEmployeeFilterWhereClauses,
  fetchExternalEmployeeFilterOptions,
} from "~/services/employee-filter/fetch-external-employee-filter-options";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/select-external-employee-user-for-picture";

export type FetchOnboardingExternalEmployeesPaginationResult = AsyncReturnType<typeof fetchOnboardingExternalEmployees>;

export type OnboardingExternalEmployeeRow = FetchOnboardingExternalEmployeesPaginationResult["items"][number];

export const fetchOnboardingExternalEmployees = async (
  ctx: AppContext,
  params: {
    pagination: PaginationParams;
    order: OrderParams;
    filters?: FilterOptionsInput;
  }
) => {
  const user = getRequiredUser(ctx);

  const baseWhere: Prisma.ExternalEmployeeWhereInput = {
    companyId: user.companyId,
    status: { in: [ExternalEmployeeStatus.UNMAPPED, ExternalEmployeeStatus.PARTIAL] },
    /**
     * User with country permission can only see employees from this/these country/ies
     * Filter external employees for user with permission
     */
    ...(user.permissions.allowedCountries.length && {
      location: {
        countryId: {
          in: user.permissions.allowedCountries.map(getId),
        },
      },
    }),
  };

  const whereFilters = params.filters ? buildExternalEmployeeFilterWhereClauses(params.filters) : [];

  const where = {
    AND: compact([baseWhere, ...whereFilters]),
  };

  const direction = params.order.direction;
  const directionNullsLast = { sort: params.order.direction, nulls: "last" } as const;
  const orderBy = match<string | null, Prisma.Enumerable<Prisma.ExternalEmployeeOrderByWithRelationInput>>(
    params.order.column
  )
    .with("source", () => ({ source: direction }))
    .with("employeeNumber", () => ({ employeeNumber: direction }))
    .with("name", () => [{ firstName: directionNullsLast }, { lastName: directionNullsLast }])
    .with("job", () => ({ job: { name: direction } }))
    .with("location", () => [{ location: { country: { name: direction } } }, { location: { name: direction } }])
    .with("gender", () => ({ gender: directionNullsLast }))
    .with("level", () => ({ level: { name: direction } }))
    .otherwise((column) => {
      if (column) {
        logWarn(ctx, "[warn] Unhandled externalEmployee order column", { column });
      }

      return { employeeNumber: direction };
    });

  // Find all external employees that need mapping.
  const [externalEmployees, count] = await Promise.all([
    ctx.prisma.externalEmployee.findMany({
      where,
      ...prismaPaginationParams(params.pagination),
      orderBy,
      select: {
        id: true,
        firstName: true,
        lastName: true,
        employeeNumber: true,
        gender: true,
        source: true,
        currency: true,
        hireDate: true,
        birthDate: true,
        performanceReviewRatingId: true,
        picture: {
          select: {
            id: true,
            path: true,
            width: true,
            height: true,
          },
        },
        location: {
          // this is an include for the LocationSelector Component
          include: {
            country: true,
            mappedLocation: {
              include: {
                country: true,
              },
            },
          },
        },
        job: {
          include: {
            mappedJob: true,
          },
        },
        level: {
          select: {
            mappedLevel: true,
            name: true,
          },
        },
        remunerationItems: {
          select: {
            asPercentage: true,
            amount: true,
            status: true,
            date: true,
            nature: {
              select: { id: true, name: true, mappedType: true },
            },
          },
        },
        ...selectExternalEmployeeUserPicture,
      },
    }),
    ctx.prisma.externalEmployee.count({ where }),
  ]);

  const filterOptions = await fetchExternalEmployeeFilterOptions(ctx, { where });

  return buildPaginationResult({
    items: externalEmployees,
    count,
    pagination: params.pagination,
    meta: {
      filterOptions,
    },
  });
};

export const getPercentageMappedEmployees = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  const [nbMappedEmployees, nbTotalEmployees] = await Promise.all([
    ctx.prisma.externalEmployee.count({
      where: {
        companyId: user.companyId,
        status: {
          not: ExternalEmployeeStatus.UNMAPPED,
        },
      },
    }),
    ctx.prisma.externalEmployee.count({
      where: {
        companyId: user.companyId,
      },
    }),
  ]);

  return Math.round((nbMappedEmployees / nbTotalEmployees) * 100);
};
