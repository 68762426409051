import { type AppContext } from "~/lib/context";
import { type SpreadsheetRow } from "~/lib/external/google-sheets/client";
import { type ExternalEmployeeField } from "~/lib/spreadsheet/external-employee-fields";
import { type SpreadsheetSpec } from "~/lib/spreadsheet/templates";
import { getAllAdditionalFields } from "~/services/additional-field/get-all-additional-fields";
import {
  fetchPartialSpreadsheetExternalEmployees,
  type PartialSpreadsheetExternalEmployees,
} from "~/services/external-employee/fetch-partial-spreadsheet-external-employees";

export const ADDITIONAL_FIELD_KEY_PREFIX = "af_";

export const addPrefixToAdditionalFieldKey = (additionalFieldName: string) =>
  `${ADDITIONAL_FIELD_KEY_PREFIX}${additionalFieldName}`;

export const transformAdditionalFieldToSpreadsheetField = (
  additionalFieldName: string
): ExternalEmployeeField<string> => {
  const key = addPrefixToAdditionalFieldKey(additionalFieldName);

  return {
    key,
    rsiField: {
      label: additionalFieldName,
      key,
      fieldType: { type: "input" },
      example: `Example ${additionalFieldName}`,
      alternateMatches: [additionalFieldName],
    },
  };
};

export const generateSpreadsheetData = async (
  ctx: AppContext,
  params: {
    spreadsheetSpec: SpreadsheetSpec;
    columns: string[];
  }
) => {
  const additionalFields = await getAllAdditionalFields(ctx);
  const columns = getSpreadsheetFieldsFromSpec(params.spreadsheetSpec, params.columns);
  const additionalFieldColumns = additionalFields
    .filter((field) => params.columns.includes(addPrefixToAdditionalFieldKey(field.name)))
    .map(({ name }) => transformAdditionalFieldToSpreadsheetField(name));

  const columnHeaders = columns.map((column) => column.rsiField.label);
  const additionalFieldColumnHeaders = additionalFieldColumns.map((column) => column.rsiField.label);

  const spreadsheetHeaders = [...columnHeaders, ...additionalFieldColumnHeaders] as unknown as SpreadsheetRow<number>;

  const externalEmployeesForSpreadsheet = await fetchPartialSpreadsheetExternalEmployees(ctx);

  const getColumnsFromExternalEmployee = (externalEmployee: PartialSpreadsheetExternalEmployees[number]) => {
    const fromColumns = columns.map((column) => {
      const value =
        column.toSpreadsheet?.(externalEmployee) ?? externalEmployee[column.key as keyof typeof externalEmployee] ?? "";
      return value as string;
    });

    const fromAdditionalFields = additionalFieldColumns.map((column) => {
      const columnKey = column.key.replace(ADDITIONAL_FIELD_KEY_PREFIX, "");
      const additionalFieldValue = externalEmployee.additionalFieldValues.find(
        (field) => field.additionalField.name === columnKey
      );

      return additionalFieldValue?.stringValue ?? "";
    });

    return [...fromColumns, ...fromAdditionalFields] as unknown as SpreadsheetRow<number>;
  };

  return [spreadsheetHeaders, ...externalEmployeesForSpreadsheet.map(getColumnsFromExternalEmployee)];
};

export const getSpreadsheetFieldsFromSpec = (spreadsheetSpec: SpreadsheetSpec, columns: string[]) => {
  const requiredKeys = spreadsheetSpec.requiredColumns.map((column) => column.key);

  return spreadsheetSpec.availableColumns.filter(
    (column) => columns.includes(column.key) || requiredKeys.includes(column.key)
  );
};
