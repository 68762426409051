import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { EmployeeMappingSkipReason } from "@prisma/client";
import classNames from "classnames";
import { type TFunction } from "next-i18next";
import { match } from "ts-pattern";
import { Button } from "~/components/ui/core/button";
import { Icon } from "~/components/ui/core/Icon";
import { type IconName } from "~/components/ui/icons";
import { type SkipDataType } from "~/components/ui/skip-reason-selector-modal";

type MappingPromptProps = React.PropsWithChildren<{
  value: boolean;
  onChange: (value: boolean) => void;
}>;

export const MappingPrompt: React.FC<MappingPromptProps> = ({ value, onChange, children }) => {
  return (
    <div className="-mt-0.5 ml-1 flex items-center self-start rounded-b bg-primary-100 py-1 pl-1.5 pr-3.5 pt-1.5">
      <FormControlLabel
        control={
          <Switch
            checked={value}
            color="primary"
            onChange={(event) => {
              onChange(event.target.checked);
            }}
            size="small"
          />
        }
        label={
          <Stack direction="row" spacing={2} alignItems="center">
            <Icon className="text-gray-700" name="robot" size="sm" />
            <Typography variant="caption">{children}</Typography>
          </Stack>
        }
        labelPlacement="start"
      />
    </div>
  );
};

type FormHintProps = React.PropsWithChildren<{
  icon: IconName | null;
  warning?: boolean;
  showConfirmation?: boolean;
  onConfirm?: () => void;
}>;

export const FormHint: React.FC<FormHintProps> = ({ icon, showConfirmation, onConfirm, children, warning = false }) => {
  return (
    <div
      className={classNames({
        "-mt-0.5 ml-1 flex items-center self-start rounded-b bg-secondary-100 py-1 pl-1 pr-1.5 pt-1.5": true,
        "bg-secondary-300": warning,
      })}
    >
      {icon && <Icon name={icon} size="sm" />}
      <span className="ml-1 text-xs text-gray-700">{children}</span>
      {onConfirm && showConfirmation && (
        <Button color="secondary" className="ml-2 min-w-0" type="button" onClick={onConfirm}>
          <Icon name="check" />
        </Button>
      )}
    </div>
  );
};

export const formatEmployeeMappingSkipReason = (
  t: TFunction,
  reason: EmployeeMappingSkipReason,
  type: SkipDataType
): string => {
  return match(reason)
    .with(EmployeeMappingSkipReason.MISSING_LOCATION, () => {
      return t("components.employee.employee-form-utils.i-couldnt-find-the-appropriate-location");
    })
    .with(EmployeeMappingSkipReason.MISSING_JOB, () => {
      return t("components.employee.employee-form-utils.i-couldnt-find-the-appropriate-job");
    })
    .with(EmployeeMappingSkipReason.SKIPPED_JOB, () => {
      return t("components.employee.employee-form-utils.their-job-was-skipped");
    })
    .with(EmployeeMappingSkipReason.SKIPPED_LEVEL, () => {
      return t("components.employee.employee-form-utils.their-level-was-skipped");
    })
    .with(EmployeeMappingSkipReason.SKIPPED_LOCATION, () => {
      return t("components.employee.employee-form-utils.their-location-was-skipped");
    })
    .with(EmployeeMappingSkipReason.NOT_AN_EMPLOYEE, () => {
      return t("components.employee.employee-form-utils.this-is-not-a-salaried-employee");
    })
    .with(EmployeeMappingSkipReason.NOT_PERMANENT_EMPLOYEE, () => {
      return t("components.employee.employee-form-utils.theyre-not-a-permanent-employee");
    })
    .with(EmployeeMappingSkipReason.UNDISCLOSED, () => {
      return t("components.employee.employee-form-utils.i-dont-want-to-share", { type });
    })
    .with(EmployeeMappingSkipReason.OTHER, () => {
      return t("components.employee.employee-form-utils.another-reason");
    })
    .exhaustive();
};
