import { ComparisonGeographicStrategy, DataQuality } from "@prisma/client";
import { chain, concat } from "lodash";
import computePercentile from "percentile";
import { match } from "ts-pattern";
import { percentageDifferenceToTarget } from "~/lib/math";
import { getId } from "~/lib/utils";
import { type GetMarketDataStatsMeasure } from "~/pages/api/get-market-data-stats";
import { computeDataQuality } from "~/services/data-quality";
import { computeEmployeeCompensation } from "~/services/employee";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetch-company-with-compensation-settings";
import { type EmployeeForStats } from "~/services/employee-stats/fetch-comparable-employees-for-stats";
import { type Comparison } from "~/services/employee-stats/find-comparable-employees";
import { getMarketPositioningType } from "~/services/market-positioning";

export type Measure = Extract<GetMarketDataStatsMeasure, "baseSalary" | "onTargetEarnings" | "totalCash">;

export const getAmountForPercentile = (params: { amounts: number[]; amount: number; percentile: number }) => {
  const amountForPercentile = Math.round(computePercentile(params.percentile * 100, params.amounts) as number);
  const difference = amountForPercentile ? Math.round(params.amount - amountForPercentile) : null;
  const percentageDifference = amountForPercentile
    ? percentageDifferenceToTarget(params.amount, amountForPercentile)
    : null;

  return {
    amountForPercentile,
    difference,
    percentageDifference,
  };
};

export const getPercentileAmountForMeasure = (params: {
  employee: EmployeeForStats;
  measure: Measure;
  comparison: Comparison;
  targetPercentile: number;
}) => {
  const amounts = params.comparison.employees.map((employee) =>
    computeEmployeeCompensation(employee, { measure: params.measure, targetCurrency: params.employee.currency })
  );

  const amount = computeEmployeeCompensation(params.employee, {
    measure: params.measure,
    targetCurrency: params.employee.currency,
  });

  // concat amounts to add the employee itself
  const dataQuality = computeDataQuality(concat(amounts, amount), params.comparison.companiesCount);

  if (dataQuality === DataQuality.NONE) {
    return {
      amountForPercentile: null,
      difference: null,
      percentageDifference: null,
      marketPositioning: null,
      dataQuality: DataQuality.NONE,
    };
  }

  const { amountForPercentile, difference, percentageDifference } = getAmountForPercentile({
    amounts,
    amount,
    percentile: params.targetPercentile,
  });

  return {
    amountForPercentile,
    difference,
    percentageDifference,
    ...(percentageDifference && {
      marketPositioning: getMarketPositioningType(percentageDifference),
    }),
    dataQuality,
  };
};

export const countComparableCompanies = (params: { employees: EmployeeForStats[] }) => {
  return chain(params.employees)
    .uniqBy(({ companyId }) => companyId)
    .size()
    .value();
};

export const getComparedLocationIds = (company: CompanyWithCompensationSettings, employee: EmployeeForStats) =>
  match(company.comparisonGeographicStrategy)
    .with(ComparisonGeographicStrategy.EMPLOYEE_LOCATION, () => {
      return [employee.locationId];
    })
    .with(ComparisonGeographicStrategy.SPECIFIC_LOCATION, () => {
      const ids = company?.compareSpecificLocations.map(getId);
      return ids && ids.length > 0 ? ids : [employee.locationId];
    })
    .with(ComparisonGeographicStrategy.COUNTRY_CAPITAL, () => {
      return [employee.capitalLocationId ?? employee.locationId];
    })
    .with(ComparisonGeographicStrategy.ENTIRE_COUNTRY, () => [employee.locationId])
    .exhaustive();

export const getComparedCountryIds = (company: CompanyWithCompensationSettings, employee: EmployeeForStats) =>
  match(company.comparisonGeographicStrategy)
    .with(ComparisonGeographicStrategy.EMPLOYEE_LOCATION, () => [employee.location.countryId])
    .with(ComparisonGeographicStrategy.SPECIFIC_LOCATION, () => {
      const ids = company?.compareSpecificLocations.map((location) => location.countryId);
      return ids && ids.length > 0 ? ids : [employee.location.countryId];
    })
    .with(ComparisonGeographicStrategy.COUNTRY_CAPITAL, () => [employee.location.countryId])
    .with(ComparisonGeographicStrategy.ENTIRE_COUNTRY, () => [employee.location.countryId])
    .exhaustive();

export const getTargetPercentileForEmployee = (params: {
  company: CompanyWithCompensationSettings;
  employee: EmployeeForStats;
}) => {
  const targetPercentileSetting = params.company.companyTargetPercentiles.find(
    (target) => params.employee.job.familyId === target.jobFamilyId
  );

  return targetPercentileSetting?.value ?? params.company.globalTargetPercentile;
};
