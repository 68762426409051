import { Alert, Stack, Typography } from "@mui/material";
import { useI18n } from "~/lib/i18n/use-i18n";

type Props = {
  className?: string;
  hint: string;
};

export const SynchronizationErrorAlert: React.FC<Props> = ({ hint, ...props }) => {
  const { t } = useI18n();

  return (
    <Alert severity="error" {...props}>
      <Stack>
        <Typography className="mb-2" variant="subtitle2">
          {t("components.synchronization.error-alert.last-synchronisation-has-misstepped")}
        </Typography>
        <Typography variant="caption">{hint}</Typography>
        <Typography variant="caption">{t("components.synchronization.error-alert.if-troubles-persist")}</Typography>
      </Stack>
    </Alert>
  );
};
