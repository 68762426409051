import { type Product } from "@prisma/client";
import HttpStatus, { StatusCodes } from "http-status-codes";
import { keyBy, mapValues } from "lodash";
import { type NextApiHandler, type NextApiRequest, type NextApiResponse } from "next";
import { type Route } from "nextjs-routes";
import { type AugmentedRequest } from "server";
import { ValidationError } from "yup";
import { config } from "~/config";
import { type ApiRoutePath } from "~/hooks/useApi";
import { type AppContext } from "~/lib/context";
import { trackApiEndpointCalled } from "~/lib/external/segment/server/events";
import { translateYupError } from "~/lib/i18n/yup-errors";
import { logError, logInfo, logWarn } from "~/lib/logger";
import { RateLimitError, type RateLimitOptions } from "~/lib/rate-limits";
import { type AuthenticationOptions, guardApi } from "~/lib/session";
import { SamlAuthenticationEnforcedError } from "~/services/auth/saml/enforce-saml-authentication";
import { AuthenticationRequiresProviderConfirmationError } from "~/services/auth/user-provider-confirmation";
import { type FeatureFlagName } from "~/services/feature-flags";
import { productToPermission } from "~/services/subscriptions/utils";
import { XlsxImportError } from "~/services/xlsx-to-json";

export class ApiValidationError extends Error {
  error: string;
  fields: Record<string, string>;

  constructor(error: string, fields: Record<string, string>) {
    super("API Validation error");

    this.error = error;
    this.fields = fields;

    Object.setPrototypeOf(this, ApiValidationError.prototype);
  }
}

export class ApiAuthenticationError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiAuthenticationError.prototype);
  }
}

export class ApiFeatureAccessError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiFeatureAccessError.prototype);
  }
}

export class ApiImpersonationError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiImpersonationError.prototype);
  }
}

export class PublicApiAuthenticationError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiAuthenticationError.prototype);
  }
}

export class BusinessLogicError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, BusinessLogicError.prototype);
  }
}

export class ForbiddenError extends Error {
  constructor(
    message: string,
    public redirect?: Route
  ) {
    super(message);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

const handleAuthenticationError = (ctx: AppContext, res: NextApiResponse, error: ApiAuthenticationError) => {
  res.status(HttpStatus.UNAUTHORIZED);

  const req = ctx as AugmentedRequest;

  // Ignoring logs for auth logs LinkedIn Extension
  if ((req.parsedUrl?.pathname as ApiRoutePath) !== "/api/internal-partner/market-data-benchmark") {
    logWarn(ctx, "[api] Authentication error", { error });
  }

  return res.json({ error: error.message });
};

const handleAuthenticationRequiresProviderConfirmationError = (
  res: NextApiResponse,
  error: AuthenticationRequiresProviderConfirmationError
) => {
  return res.redirect(
    `/sign-in?authentication-requires-provider-confirmation=true&email=${error.email}&provider=${error.provider}`
  );
};

const handleSamlAuthenticationEnforcedError = (res: NextApiResponse, error: SamlAuthenticationEnforcedError) => {
  return res.redirect(error.samlAuthenticationUrl);
};

const handleFeatureAccessError = (ctx: AppContext, res: NextApiResponse, error: ApiAuthenticationError) => {
  res.status(HttpStatus.FORBIDDEN);
  logWarn(ctx, "[api] Feature access error", { error });

  return res.json({ error: error.message });
};

const handleForbiddenError = (ctx: AppContext, res: NextApiResponse, error: ForbiddenError) => {
  res.status(HttpStatus.FORBIDDEN);
  logWarn(ctx, "[api] Forbidden error", { error });

  return res.json({ error: error.message });
};

const handleImpersonationError = (ctx: AppContext, res: NextApiResponse, error: ApiAuthenticationError) => {
  res.status(HttpStatus.FORBIDDEN);
  logWarn(ctx, "[api] Impersonation error", { error });

  return res.json({
    key: "impersonation-forbidden",
    error: error.message,
  });
};

const handleRateLimitError = (ctx: AppContext, res: NextApiResponse, error: ApiAuthenticationError) => {
  res.status(HttpStatus.TOO_MANY_REQUESTS);
  logWarn(ctx, "[api] Rate Limit error", { error });

  return res.json({
    error: error.message,
  });
};

const handleValidationError = (ctx: AppContext, res: NextApiResponse, error: ValidationError) => {
  res.status(HttpStatus.UNPROCESSABLE_ENTITY);
  logWarn(ctx, "[api] Validation error", { error });

  return res.json({
    error: "Validation error",
    fields: mapValues(
      keyBy(error.inner, (field) => {
        return field.path;
      }),
      (field: ValidationError) => {
        return field.message;
      }
    ),
  });
};

const handleApiValidationError = (ctx: AppContext, res: NextApiResponse, error: ApiValidationError) => {
  res.status(HttpStatus.UNPROCESSABLE_ENTITY);
  logWarn(ctx, "[api] Validation error", { error });

  return res.json({
    error: error.error,
    fields: error.fields,
  });
};

const handleNotFoundError = (ctx: AppContext, res: NextApiResponse, error: Error) => {
  res.status(HttpStatus.NOT_FOUND);
  logWarn(ctx, "[api] Not found error", { error });

  return res.json({
    error: "Entity not found",
  });
};

const handleMethodNotAllowedError = (ctx: AppContext, res: NextApiResponse) => {
  res.status(HttpStatus.METHOD_NOT_ALLOWED);
  logInfo(ctx, "[api] Method not allowed");

  return res.end();
};

const handleBusinessLogicError = (ctx: AppContext, res: NextApiResponse, error: BusinessLogicError) => {
  res.status(HttpStatus.BAD_REQUEST);
  logWarn(ctx, "[api] Business logic error", { error });

  return res.json({ error: error.message });
};

const handleSpreadsheetImportError = (ctx: AppContext, res: NextApiResponse, error: XlsxImportError) => {
  res.status(StatusCodes.UNPROCESSABLE_ENTITY);
  logWarn(ctx, "[api] Spreadsheet import error", { error });

  return res.json({
    error: error.message,
    fields: error.rowErrors
      ? error.rowErrors.map((item) => {
          return {
            id: item.id ?? `-`,
            value: item.value,
            error: item.message,
            meta: {
              count: item.count,
            },
          };
        })
      : [],
  });
};

const handleGenericError = (ctx: AppContext, res: NextApiResponse, error: Error) => {
  res.status(HttpStatus.INTERNAL_SERVER_ERROR);
  logError(ctx, "[api] Generic error", { error });

  return res.json({
    error: "Internal server error",
    ...(config.app.debug && {
      message: error.message,
      stack: error.stack,
    }),
  });
};

export type ApiHandlerOptions = {
  authentication?: AuthenticationOptions;
  feature?: FeatureFlagName;
  product?: Product;
  method?: "GET" | "POST";
  rateLimit?: RateLimitOptions;
  access?: (ctx: AppContext) => Route | undefined;
};

export type ApiHandler<T> = (req: NextApiRequest, res: NextApiResponse<T>) => unknown | Promise<unknown>;

export const api = <T>(handler: ApiHandler<T>, options?: ApiHandlerOptions): NextApiHandler<T> => {
  return async (req, res) => {
    try {
      if (options?.method && options.method !== req.method) {
        return handleMethodNotAllowedError(req, res);
      }

      await guardApi(req, res, options?.authentication);

      if (options?.feature && !req.featureFlags[options.feature]) {
        throw new ApiFeatureAccessError(`User cannot access the feature "${options?.feature}"`);
      }

      if (options?.product && !req.subscriptions[productToPermission(options.product)]) {
        throw new ApiFeatureAccessError(`User cannot access the product "${options?.product}"`);
      }

      await handler(req, res);
    } catch (error) {
      if (error.name === "NotFoundError") {
        return handleNotFoundError(req, res, error);
      }

      if (error instanceof RateLimitError) {
        return handleRateLimitError(req, res, error);
      }

      if (error instanceof ForbiddenError) {
        return handleForbiddenError(req, res, error);
      }

      if (error instanceof ApiAuthenticationError) {
        return handleAuthenticationError(req, res, error);
      }

      if (error instanceof AuthenticationRequiresProviderConfirmationError) {
        return handleAuthenticationRequiresProviderConfirmationError(res, error);
      }

      if (error instanceof SamlAuthenticationEnforcedError) {
        return handleSamlAuthenticationEnforcedError(res, error);
      }

      if (error instanceof ApiFeatureAccessError) {
        return handleFeatureAccessError(req, res, error);
      }

      if (error instanceof ValidationError) {
        return handleValidationError(req, res, translateYupError(req.t, error));
      }

      if (error instanceof ApiValidationError) {
        return handleApiValidationError(req, res, error);
      }

      if (error instanceof ApiImpersonationError) {
        return handleImpersonationError(req, res, error);
      }

      if (error instanceof BusinessLogicError) {
        return handleBusinessLogicError(req, res, error);
      }

      if (error instanceof XlsxImportError) {
        return handleSpreadsheetImportError(req, res, error);
      }

      if (error instanceof Error) {
        handleGenericError(req, res, error);
      }
    } finally {
      await trackApiEndpointCalled(req, { req });
    }
  };
};
