import { InfoOutlined } from "@mui/icons-material";
import { Checkbox, Stack, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Modal, type ModalAction } from "~/components/ui/core/Modal";
import { type SelectAChoiceModal } from "~/components/ui/core/SelectAChoiceModal";
import { useRefreshServerProps } from "~/lib/utils";
import { useOverrideAllEmployeeDataValidationFlagMutation } from "~/pages/api/admin/employee-data-validation/override-all-employee-data-validation-flag-by-comment";
import { useOverrideEmployeeDataValidationFlagMutation } from "~/pages/api/admin/employee-data-validation/override-employee-data-validation-flag";
import { useGetActiveFlagByEmployeeId } from "~/pages/api/employee-data-validation-flag/get-active-flag-by-employee-id";
import { type GetActiveFlagByEmployeeIdResult } from "~/services/employee-data-validation-flag/get-employee-data-validation-flag-by-id";

type Props = {
  initialFlag: GetActiveFlagByEmployeeIdResult;
} & Pick<React.ComponentProps<typeof SelectAChoiceModal>, "isOpen" | "onClose">;

export const OverrideEmployeeFlagModal: React.FC<Props> = ({ initialFlag, isOpen, onClose }) => {
  const { data: flag } = useGetActiveFlagByEmployeeId({
    options: {
      initialData: initialFlag,
      enabled: isOpen && !!initialFlag,
    },
    input: {
      employeeId: initialFlag?.employeeId,
    },
  });

  //TODO can be removed once employee lists is migrated to react-query
  const refreshServerProps = useRefreshServerProps();

  const [overrideAll, setOverrideAll] = useState<boolean>(false);

  const overrideEmployeeDataValidationFlagMutation = useOverrideEmployeeDataValidationFlagMutation();
  const overrideAllEmployeeDataValidationFlagMutation = useOverrideAllEmployeeDataValidationFlagMutation();
  const flagCode = flag?.comment?.trim().split(" ")[0];

  const resetModal = async () => {
    await refreshServerProps();
    onClose();
  };

  const primaryAction = useMemo<ModalAction>(() => {
    return {
      label: overrideAll ? `Override all ${flagCode}` : "Override",
      onClick: async () => {
        if (flag && overrideAll) {
          await overrideAllEmployeeDataValidationFlagMutation.mutateAsync({
            comment: flag?.comment,
            companyId: flag?.companyId,
          });
        }

        if (flag && !overrideAll) {
          await overrideEmployeeDataValidationFlagMutation.mutateAsync({
            id: flag?.id,
            employeeId: flag?.employeeId,
          });
        }

        await resetModal();
      },
    };
  }, [flag, overrideAll]);

  const secondaryAction = useMemo<ModalAction>(
    () => ({
      label: "Cancel",
      onClick: () => {
        onClose();
      },
    }),
    []
  );

  return (
    <Modal
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      icon="message-question"
      className="w-[560px]"
      isOpen={isOpen}
      title="Override an automatic flag"
      onClose={onClose}
    >
      <Stack className="w-full" spacing={8}>
        <Typography variant="caption">
          This flag was automatically created during an HRIS sync (or spreadsheet upload). You can dismiss it as a super
          admin, so that it won't be re-flagged again. Please ensure that the data is valid before doing so.
        </Typography>
        <Stack spacing={2} direction="row" alignItems="center">
          <Tooltip title="This will override all the flags for this company with a similar error code">
            <InfoOutlined className="ml-1.5 h-3 w-3 text-gray-500" />
          </Tooltip>
          <Typography>Do you want to override all similar flags for this company?</Typography>
          <Checkbox
            checked={overrideAll}
            onChange={(event) => {
              setOverrideAll(event.target.checked);
            }}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
