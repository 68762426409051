import { type SalaryRange } from "@prisma/client";
import { roundTo } from "~/lib/math";

export const SALARY_RANGE_WIDTH_OPTIONS = [
  {
    label: "Narrow",
    value: 0.05,
  },
  {
    label: "Regular",
    value: 0.1,
  },
  {
    label: "Wide",
    value: 0.2,
  },
];

export const formatSalaryRangeWidth = (width: number) => {
  return SALARY_RANGE_WIDTH_OPTIONS.find((item) => item.value === width)?.label ?? "Custom";
};

export const getWidthsFromSalaryRange = (salaryRange: Pick<SalaryRange, "midpoint" | "min" | "max">) => {
  const lowerWidth = -roundTo(1 - salaryRange.min / salaryRange.midpoint, 0.01);
  const upperWidth = -roundTo(1 - salaryRange.max / salaryRange.midpoint, 0.01);
  const hasSameWidths = Math.abs(lowerWidth) === Math.abs(upperWidth);

  return { lowerWidth, upperWidth, hasSameWidths };
};
