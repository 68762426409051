import { ExternalEmployeeStatus, UserRole } from "@prisma/client";
import { difference, uniq } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { dangerouslyIgnorePrismaRestrictions } from "~/lib/prisma-tokens";
import { getId } from "~/lib/utils";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";

export const fetchExternalEmployeeManageeIds = async (
  ctx: AppContext,
  params: { managerExternalEmployeeId: number; dangerouslyIgnorePrismaRestrictions?: boolean }
) => {
  const externalEmployeesManagees = await recursivelyFetchExternalEmployeeManageeIds(ctx, {
    managerExternalEmployeeIds: [params.managerExternalEmployeeId],
    dangerouslyIgnorePrismaRestrictions: params.dangerouslyIgnorePrismaRestrictions,
  });

  // the .filter is a prevention if there is a cyclic manager organisation
  // if A manages B, B manages C, C manages A
  return externalEmployeesManagees.filter((id) => id !== params.managerExternalEmployeeId);
};

export const fetchUserExternalEmployeeManageeIdsIgnoringSuperAdmin = async (
  ctx: AppContext,
  params: { user: NullableAuthenticatedUser; dangerouslyIgnorePrismaRestrictions?: boolean }
) => {
  const { user, dangerouslyIgnorePrismaRestrictions = false } = params;

  if (user?.isSuperAdmin) {
    return null;
  }

  if (
    (user?.permissions.role === UserRole.ADMIN && !dangerouslyIgnorePrismaRestrictions) ||
    (user?.permissions.role === UserRole.HR && !dangerouslyIgnorePrismaRestrictions)
  ) {
    return null;
  }

  return fetchUserExternalEmployeeManageeIds(ctx, params);
};

export const fetchUserExternalEmployeeManageeIds = async (
  ctx: AppContext,
  params: { user: NullableAuthenticatedUser; dangerouslyIgnorePrismaRestrictions?: boolean }
) => {
  const { user } = params;

  // This function is used to handle (external) employees data restrictions.
  // It should only apply to users with MANAGER role.
  if (!user?.permissions.isManager) {
    return null;
  }

  // If a MANAGER user is not linked to an external employee, they should not be able to access external employee's data.
  if (!user.permissions.externalEmployeeId) {
    return [];
  }

  return fetchExternalEmployeeManageeIds(ctx, {
    managerExternalEmployeeId: user.permissions.externalEmployeeId,
    dangerouslyIgnorePrismaRestrictions: params.dangerouslyIgnorePrismaRestrictions,
  });
};

export type UserExternalEmployeeManageeIds = AsyncReturnType<typeof fetchUserExternalEmployeeManageeIds>;

const recursivelyFetchExternalEmployeeManageeIds = async (
  ctx: AppContext,
  params: {
    managerExternalEmployeeIds: number[];
    dangerouslyIgnorePrismaRestrictions?: boolean;
  }
): Promise<number[]> => {
  const externalEmployees = await ctx.prisma.externalEmployee.findMany({
    ...(params.dangerouslyIgnorePrismaRestrictions && { ...dangerouslyIgnorePrismaRestrictions() }),
    where: {
      managerExternalEmployeeId: { in: params.managerExternalEmployeeIds },
      status: { not: ExternalEmployeeStatus.SKIPPED },
    },
    select: { id: true },
  });

  const externalEmployeeIds = externalEmployees.map(getId);

  if (difference(externalEmployeeIds, params.managerExternalEmployeeIds).length > 0) {
    return recursivelyFetchExternalEmployeeManageeIds(ctx, {
      managerExternalEmployeeIds: uniq([...externalEmployeeIds, ...params.managerExternalEmployeeIds]),
      dangerouslyIgnorePrismaRestrictions: params.dangerouslyIgnorePrismaRestrictions,
    });
  }

  return externalEmployees.map(getId);
};
