import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { sendSyncSalaryRangeEmployeesJob } from "~/workers/synchronise-salary-range-employees";

export const synchroniseCompanySalaryRangeEmployees = async (ctx: AppContext, params: { companyId: number }) => {
  const salaryGrids = await ctx.prisma.salaryGrid.findMany({
    where: { companyId: params.companyId },
    select: { id: true },
  });

  await mapSeries(salaryGrids, async (salaryGrid) => {
    await sendSyncSalaryRangeEmployeesJob(ctx, { salaryGridId: salaryGrid.id });
  });
};
