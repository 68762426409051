import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { Link } from "~/components/ui/core/Link";
import { Button } from "~/components/ui/core/button";
import { config } from "~/config";
import { useIntercomForFigures } from "~/hooks/useIntercomForFigures";
import { disableSegmentTracking } from "~/lib/external/segment/web/client";
import { isIn } from "~/lib/utils";

export const CookiesWidget: React.FC = () => {
  const [isShown, setIsShown] = useLocalStorage("show-cookies-widget", !isIn(config.app.env, ["test", "development"]));
  const [isShownYet, setIsShownYet] = useState(false);
  const intercom = useIntercomForFigures();

  useEffect(() => {
    intercom.boot();
  }, []);

  useEffect(() => {
    if (!isShown) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsShownYet(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isShown]);

  useEffect(() => {
    const app = document.querySelector("#app");

    if (!isShown || !app) {
      return;
    }

    const onScroll = () => {
      setIsShownYet(true);
    };

    app.addEventListener("scroll", onScroll);

    return () => {
      app.removeEventListener("scroll", onScroll);
    };
  }, [isShown]);

  if (!isShownYet) {
    return null;
  }

  const onAccept = () => {
    setIsShown(false);
    setIsShownYet(false);
  };

  const onDecline = async () => {
    setIsShown(false);
    setIsShownYet(false);

    localStorage.setItem("disable-tracking", "true");

    await disableSegmentTracking();
    intercom.hardShutdown();

    location.reload();
  };

  return (
    <div className="fixed bottom-4 right-24 left-4 z-50 flex origin-right animate-cookies flex-col items-center rounded-lg border-4 border-[#E45300] bg-secondary-50 py-2 px-2 sm:bottom-5 sm:right-28 sm:left-auto sm:h-[60px] sm:flex-row sm:rounded-full sm:py-0 sm:px-4">
      <div className="max-w-lg leading-none">
        <span className="text-sm font-semibold">🍪 &nbsp; Can we use cookies?</span>
        <span className="ml-1 text-xs">
          They're used for performance & activity monitoring, as well as live-chat features.
        </span>
        <Link newTab className="ml-1 text-xs" to="https://legal.figures.hr/privacy-notice">
          Privacy policy
        </Link>
      </div>

      <div className="ml-0 mt-4 flex whitespace-nowrap sm:ml-4 sm:mt-0">
        <Button variant="contained" size="small" onClick={onAccept}>
          Accept all
        </Button>
        <Button variant="contained" size="small" color="white" className="ml-2" onClick={onDecline}>
          Decline
        </Button>
      </div>
    </div>
  );
};
