import { AddCircleOutline } from "@mui/icons-material";
import { Chip, Divider, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { DropDown } from "~/components/ui/core/drop-down";

type Props = {
  min?: number;
  max?: number;
  onChange: ([min, max]: [min: number | undefined, max: number | undefined]) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  label: string;
};

export const RangeFilter: React.FC<Props> = ({ min, max, onChange, disabled, className, label }) => {
  return (
    <DropDown
      disabled={disabled}
      className={className}
      ButtonProps={{
        endIcon: null,
      }}
      label={
        <Stack direction="row" spacing={2} alignItems="center">
          {!min && !max && <AddCircleOutline fontSize="small" className="text-gray-600" />}

          <Typography variant="subtitle1">{label}</Typography>

          {(!!min || !!max) && <Divider orientation="vertical" flexItem />}

          {!!min && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>Min:</Typography>
              <Chip color="primary-alt" size="small" label={`${min}`} />
            </Stack>
          )}

          {!!max && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>Max:</Typography>
              <Chip color="primary-alt" size="small" label={`${max}`} />
            </Stack>
          )}
        </Stack>
      }
    >
      <Stack spacing={4} className="w-[250px] p-2">
        <TextField
          value={min ?? undefined}
          label="Min"
          type="number"
          onChange={(event) => onChange([event.target.value ? +event.target.value : undefined, max])}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 1 }}
        />
        <TextField
          value={max ?? undefined}
          label="Max"
          type="number"
          onChange={(event) => onChange([min, event.target.value ? +event.target.value : undefined])}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 1 }}
        />
      </Stack>
    </DropDown>
  );
};
