import { EmployeeLevel, Gender } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { boolean, date, mixed, number, object, string } from "yup";
import { api } from "~/lib/api";
import { parseMoney } from "~/lib/money";
import { createMutation, invalidateQuery } from "~/lib/react-query";
import { type YupOutputType } from "~/lib/utils";
import { COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY } from "~/pages/api/external-employee/count-external-employees-by-status";
import { FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY } from "~/pages/api/imported-employees/fetch-all-imported-employees";
import { FETCH_EXTERNAL_EMPLOYEES_FOR_ONBOARDING_QUERY_KEY } from "~/pages/api/onboarding/fetch-external-employees";
import { GET_PERCENTAGE_OF_MAPPED_EMPLOYEES_QUERY_KEY } from "~/pages/api/onboarding/get-percentage-of-mapped-employees";
import { CreateEmployeeSchema } from "~/services/employee/employee-schemas";
import { mapExternalEmployee } from "~/services/mapping/map-external-employee";

const MapExternalEmployeeAdditionalSchema = object({
  alwaysMapLocation: boolean().required(),
  alwaysMapJob: boolean().required(),
  alwaysMapLevel: boolean().required(),
  performanceReviewRatingId: number().nullable(),
});

export const ExternalEmployeeSchema = CreateEmployeeSchema.concat(MapExternalEmployeeAdditionalSchema);

export type ExternalEmployeeInput = YupOutputType<typeof ExternalEmployeeSchema>;

export const PartialExternalEmployeeSchema = object({
  gender: mixed<Gender>()
    .oneOf([null, ...Object.values(Gender)])
    .nullable()
    .required(),
  externalLevel: string().nullable().required(),
  externalJobTitle: string().nullable().required(),
  externalLocation: string().nullable().required(),
  baseSalary: number().positive().required(),
  employeeNumber: string().nullable(),
  firstName: string().nullable(),
  lastName: string().nullable(),
  location: object({
    id: number().required(),
  }).nullable(),
  job: object({
    id: number().required(),
  }).nullable(),
  level: mixed<EmployeeLevel>()
    .oneOf([null, ...Object.values(EmployeeLevel)])
    .nullable(),
  currency: object({
    id: number().required(),
  })
    .nullable()
    .required(),
  fixedBonusPercentage: number().positive().nullable(),
  fixedBonus: number().positive().nullable(),
  onTargetBonusPercentage: number().positive().nullable(),
  onTargetBonus: number().positive().nullable(),
  pictureId: number().nullable(),
  hireDate: date().nullable(),
  birthDate: date().nullable(),
}).concat(MapExternalEmployeeAdditionalSchema);

export const MapExternalEmployeeSchema = object({
  externalEmployeeId: number().required(),
  values: PartialExternalEmployeeSchema,
});

export type MapExternalEmployeeInput = YupOutputType<typeof MapExternalEmployeeSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = MapExternalEmployeeSchema.validateSync(req.body, { abortEarly: false });
  const parsedInput = parseMoneyInput(input);

  await mapExternalEmployee(req, parsedInput);

  res.status(HttpStatus.OK).json({
    message: "Employee mapped",
  });
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessRawData: true },
});

export const useMapExternalEmployeeMutation = createMutation<typeof handler, typeof MapExternalEmployeeSchema>({
  path: "/api/map-external-employee",
  schema: MapExternalEmployeeSchema,
  options: ({ queryClient }) => ({
    onSuccess: async () => {
      await invalidateQuery(queryClient, FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY);
      await invalidateQuery(queryClient, COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY);
      await invalidateQuery(queryClient, FETCH_EXTERNAL_EMPLOYEES_FOR_ONBOARDING_QUERY_KEY);
      await invalidateQuery(queryClient, GET_PERCENTAGE_OF_MAPPED_EMPLOYEES_QUERY_KEY);
    },
  }),
});

const parseMoneyInput = (input: MapExternalEmployeeInput) => {
  input.values.baseSalary = parseMoney(input.values.baseSalary);
  input.values.fixedBonus = input.values.fixedBonus ? parseMoney(input.values.fixedBonus) : null;
  input.values.onTargetBonus = input.values.onTargetBonus ? parseMoney(input.values.onTargetBonus) : null;
  input.values.fixedBonusPercentage = input.values.fixedBonusPercentage
    ? input.values.fixedBonusPercentage / 100
    : null;
  input.values.onTargetBonusPercentage = input.values.onTargetBonusPercentage
    ? input.values.onTargetBonusPercentage / 100
    : null;

  return input;
};
