import { AddCircleOutline } from "@mui/icons-material";
import { Chip, Divider, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Button } from "~/components/ui/core/button";
import { DatePicker } from "~/components/ui/core/date-picker";
import { DropDown } from "~/components/ui/core/drop-down";
import { DateFormats } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";

type Props = {
  min?: Date;
  max?: Date;
  onChange: ([min, max]: [min: Date | undefined, max: Date | undefined]) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  label: string;
};

export const DateRangeFilter: React.FC<Props> = ({
  min: defaultMin,
  max: defaultMax,
  onChange,
  disabled,
  className,
  label,
}) => {
  const { formatDate } = useI18n();
  const [min, setMin] = useState<Date | undefined>(defaultMin);
  const [max, setMax] = useState<Date | undefined>(defaultMax);

  const useFormattedDate = (date: Date | undefined) => {
    return useMemo(() => {
      if (!date || Number.isNaN(date.getTime())) return "";

      return formatDate(date, DateFormats.FULL_DATE);
    }, [date]);
  };

  const formattedMin = useFormattedDate(min);
  const formattedMax = useFormattedDate(max);

  return (
    <DropDown
      disabled={disabled}
      className={className}
      ButtonProps={{
        endIcon: null,
      }}
      label={
        <Stack direction="row" spacing={2} alignItems="center">
          {!formattedMin && !formattedMax && <AddCircleOutline fontSize="small" className="text-gray-600" />}

          <Typography variant="subtitle1">{label}</Typography>

          {(!!formattedMin || !!formattedMax) && <Divider orientation="vertical" flexItem />}

          {!!formattedMin && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>From:</Typography>
              <Chip color="primary-alt" size="small" label={formattedMin} />
            </Stack>
          )}

          {!!formattedMax && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>To:</Typography>
              <Chip color="primary-alt" size="small" label={formattedMax} />
            </Stack>
          )}
        </Stack>
      }
    >
      <Stack spacing={4} className="w-[250px] p-2">
        <DatePicker
          label="From"
          value={min ?? null}
          onChange={(value) => {
            setMin(value ?? undefined);
          }}
        />

        <DatePicker
          autoFocus
          label="To"
          value={max ?? null}
          onChange={(value) => {
            setMax(value ?? undefined);
          }}
        />

        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Button
            className="flex-1"
            variant="outlined"
            color="gray"
            onClick={() => {
              setMin(undefined);
              setMax(undefined);
              onChange([undefined, undefined]);
            }}
          >
            Clear
          </Button>
          <Button variant="contained" className="flex-1" onClick={() => onChange([min, max])}>
            Apply
          </Button>
        </Stack>
      </Stack>
    </DropDown>
  );
};
