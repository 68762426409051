/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  type Currency,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
  IntegrationSource,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { parseISO } from "date-fns";
import { orderBy } from "lodash";
import { match } from "ts-pattern";
import { type JsonObject } from "type-fest";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { fetchWithRetry } from "~/lib/fetch-with-retry";
import { companyHasAccessToBulkInviteUsers } from "~/lib/hris/helpers/company-has-access-to-bulk-invite-users";
import { getEmployeeProfilePicture } from "~/lib/hris/helpers/get-employee-profile-picture";
import { getNumberOfMonth } from "~/lib/hris/helpers/get-number-of-month";
import { type IntegrationConfig, type SourceConfig, type StaticModels } from "~/lib/integration";
import { logWarn } from "~/lib/logger";
import { assertProps } from "~/lib/utils";
import { getEuroCurrency } from "~/services/currency";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetch-company-integration-diagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/sync-external-employees";

const base: IntegrationConfig = {
  source: IntegrationSource.PEOPLEHR,
  companyId: 0,
  domain: null,
  clientId: null,
  clientSecret: config.peoplehr.clientSecret,
  anonymous: false,
  enabled: true,
  retryCount: 0,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  variableCustomFieldName: null,
  externalIdCustomFieldName: null,
  variableCustomFieldFrequency: null,
  businessUnitCustomFieldName: null,
};

export const peoplehrConfigs: SourceConfig = {
  default: base,
  anonymous: { ...base, anonymous: true },
};

export type PeoplehrIntegrationSettingsInput = {
  clientSecret: string;
  anonymous: boolean;
};

export type PeopleHrEmployee = {
  EmployeeId: { DisplayValue: string };
  FirstName?: { DisplayValue: string };
  LastName?: { DisplayValue: string };
  EmailId?: { DisplayValue: string };
  StartDate: { DisplayValue: string };
  DateOfBirth?: { DisplayValue: string };
  JobRole: { DisplayValue: string };
  Company: { DisplayValue: string };
  Location: { DisplayValue: string };
  Department: { DisplayValue: string };
  Gender: { DisplayValue: string };
  EmployeeImage?: string;
  UniqueKey: string;
  // Those are some fields we don't use but might want to anonymise / ignore
  OtherName?: unknown;
  KnownAs?: unknown;
  NISNumber?: unknown;
  ContactDetail?: unknown;
  RightToWork?: unknown;
  BackgroundDetail?: unknown;
  BankDetail?: unknown;

  salaries?: PeopleHrSalary[];
  currency?: Currency;
};

export type PeopleHrSalary = {
  SalaryType: "Annual" | "Monthly" | "Weekly" | string;
  TotalSalaryAmount: string;
  Currency: string;
  EffectiveFrom: string;
  ChangeReason: string;
};

export type CompletePeopleHrEmployee = PeopleHrEmployee & {
  salaries: PeopleHrSalary[];
  currency: Currency;
};

export type PeopleHrResponse = {
  isError: boolean;
  Status: number;
  Message: string;
  Result: PeopleHrEmployee[] | PeopleHrSalary[];
};

const peopleHrFetch = async <T extends PeopleHrResponse["Result"][number]>(
  ctx: AppContext,
  credentials: PeoplehrIntegrationSettingsInput,
  params: { endpoint: string; action: string; body: JsonObject }
) => {
  const res = await fetchWithRetry(ctx, `https://api.peoplehr.net/${params.endpoint}`, {
    method: "POST",
    headers: { Accept: "application/json" },
    body: JSON.stringify({
      APIKey: credentials.clientSecret,
      Action: params.action,
      ...params.body,
    }),
  });

  if (!res.ok) {
    throw new Error(`People HR HTTP error : [${res.status}] - ${res.statusText}`);
  }

  const json: PeopleHrResponse = await res.json();

  if (json.isError) {
    throw new Error(`People HR API error : [${json.Status}] - ${json.Message}`);
  }

  return json.Result as T[];
};

const anonymise = (user: PeopleHrEmployee): PeopleHrEmployee => {
  delete user.FirstName;
  delete user.LastName;
  delete user.DateOfBirth;
  delete user.EmployeeImage;
  delete user.EmailId;

  return user;
};

export const getPeoplehrEmployees = async (
  ctx: AppContext,
  credentials: PeoplehrIntegrationSettingsInput
): Promise<PeopleHrEmployee[]> => {
  const baseEmployees = await peopleHrFetch<PeopleHrEmployee>(ctx, credentials, {
    endpoint: "Employee",
    action: "GetAllEmployeeDetail",
    body: {
      IncludeLeavers: false,
    },
  });

  const employees: PeopleHrEmployee[] = baseEmployees.map((employee: PeopleHrEmployee) => {
    // Erase sensitive data
    delete employee.NISNumber;
    delete employee.ContactDetail;
    delete employee.RightToWork;
    delete employee.BackgroundDetail;
    delete employee.BankDetail;
    if (credentials.anonymous) {
      return anonymise(employee);
    }
    return employee;
  });

  return employees;
};

const getPeoplehrProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: CompletePeopleHrEmployee }
) => {
  if (!options.apiEmployee.EmployeeImage) {
    return undefined;
  }

  return getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.UniqueKey,
    source: IntegrationSource.PEOPLEHR,
    integrationSettings: options.integrationSettings,
    fetch: () => fetch(options.apiEmployee.EmployeeImage as string),
  });
};

export const mapPeoplehrEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: CompletePeopleHrEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  const location = [
    apiEmployee.Company?.DisplayValue,
    apiEmployee.Location?.DisplayValue,
    apiEmployee.Department?.DisplayValue,
  ]
    .filter(Boolean)
    .join(" ");

  const hasAccessToEmails = await companyHasAccessToBulkInviteUsers(ctx, company.id);

  const input: EmployeeData["input"] = {
    source: IntegrationSource.PEOPLEHR,
    externalId: apiEmployee.UniqueKey,
    status: ExternalEmployeeStatus.UNMAPPED,
    firstName: apiEmployee.FirstName?.DisplayValue,
    lastName: apiEmployee.LastName?.DisplayValue,
    email: hasAccessToEmails && apiEmployee.EmailId?.DisplayValue ? apiEmployee.EmailId?.DisplayValue : null,
    employeeNumber: apiEmployee.EmployeeId?.DisplayValue ?? apiEmployee.UniqueKey,
    gender: value(() => {
      if (apiEmployee.Gender?.DisplayValue === "Female") {
        return Gender.FEMALE;
      }
      if (apiEmployee.Gender?.DisplayValue === "Male") {
        return Gender.MALE;
      }

      return null;
    }),
    birthDate: apiEmployee.DateOfBirth?.DisplayValue ? parseISO(apiEmployee.DateOfBirth.DisplayValue) : null,
    hireDate: apiEmployee.StartDate?.DisplayValue ? parseISO(apiEmployee.StartDate.DisplayValue) : null,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: apiEmployee.currency.code },
    },
    ...(location && {
      location: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: location,
            },
          },
          create: {
            externalId: location,
            name: location,
            autoMappingEnabled: true,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),

    ...(apiEmployee.JobRole?.DisplayValue && {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: apiEmployee.JobRole.DisplayValue,
            },
          },
          create: {
            name: apiEmployee.JobRole.DisplayValue,
            externalId: apiEmployee.JobRole.DisplayValue,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
  };

  const numberMonths = getNumberOfMonth({
    externalId: location,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const numberOfMonthMultiplier = numberMonths / 12;

  const remunerationItems: EmployeeData["remunerationItems"] = orderBy(
    apiEmployee.salaries,
    "EffectiveFrom",
    "desc"
  ).map((salary, index) => {
    const multiplier = match(salary.SalaryType)
      .with("Annual", () => 1)
      .with("Monthly", () => 12 * numberOfMonthMultiplier)
      .with("Weekly", () => 52 * numberOfMonthMultiplier)
      .otherwise(() => {
        logWarn(ctx, `[peoplehr] Unrecognized salary frequency`, {
          companyId: company.id,
          externalId: apiEmployee.UniqueKey,
          salary,
        });
        return 1;
      });

    const amount = parseInt(salary.TotalSalaryAmount) * multiplier;

    const externalIdSuffix = index === 0 ? "" : `-${index}`;

    return {
      source: IntegrationSource.PEOPLEHR,
      externalId: `fix-salary${externalIdSuffix}`,
      amount: Math.round(amount * 100),
      numberMonths,
      status: index === 0 ? ExternalRemunerationStatus.LIVE : ExternalRemunerationStatus.HISTORICAL,
      date: salary.EffectiveFrom ? parseISO(salary.EffectiveFrom) : null,
      reason: salary.ChangeReason,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: IntegrationSource.PEOPLEHR,
              externalId: "fix-salary",
            },
          },
          create: {
            source: IntegrationSource.PEOPLEHR,
            externalId: "fix-salary",
            name: "Fixed salary",
            mappedType: ExternalRemunerationType.FIXED_SALARY,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    };
  });

  return {
    input,
    picturePath: !ignoreProfilePicture
      ? await getPeoplehrProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
  };
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedPeoplehrEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const peopleHrEmployees = await getPeoplehrEmployees(ctx, safeIntegrationSettings);

  // Prefetch the relevant currencies
  const euro = await getEuroCurrency(ctx);
  const companyDefaultCurrency = value(() => {
    const country = staticModels.countries.find((country) => country.id === company.defaultCountryId);

    if (!country) return euro;
    const defaultCurrency = staticModels.currencies.find((currency) => currency.id === country.defaultCurrencyId);
    return defaultCurrency || euro;
  });

  const getCurrency = (symbol: string) => {
    const currency = staticModels.currencies.find((currency) => currency.symbol === symbol);

    if (!currency) {
      logWarn(ctx, "[peoplehr] Unsupported currency", { symbol, companyId: company.id });
      return companyDefaultCurrency;
    }

    return currency;
  };

  return mapSeries(peopleHrEmployees, async (employee) => {
    // Fetch salaries for the employee
    const salaries = await peopleHrFetch<PeopleHrSalary>(ctx, safeIntegrationSettings, {
      endpoint: "Salary",
      action: "GetSalaryDetail",
      body: {
        EmployeeId: employee.EmployeeId.DisplayValue,
        IsIncludeHistory: true,
      },
    });

    const employeeWithSalary = {
      ...employee,
      salaries,
      currency: getCurrency(salaries[0]?.Currency ?? companyDefaultCurrency.symbol),
    };

    return mapPeoplehrEmployee(
      ctx,
      company,
      employeeWithSalary,
      safeIntegrationSettings,
      staticModels,
      ignoreProfilePicture
    );
  });
};

export const getPeoplehrDiagnostic = async (
  ctx: AppContext,
  input: PeoplehrIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const [employee] = await getPeoplehrEmployees(ctx, input);

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your People HR account",
        missingFields: [],
        availableFields: [],
      };
    }

    return { connection: true, connectionError: "", missingFields: [], availableFields: [] };
  } catch (error) {
    return { connection: false, connectionError: error.message, missingFields: [], availableFields: [] };
  }
};

export const getRawPeoplehrEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<PeopleHrEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getPeoplehrEmployees(ctx, safeIntegrationSettings);
};
