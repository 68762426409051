import { MenuItem, Select, type SelectProps } from "@mui/material";
import React from "react";
import { useI18n } from "~/lib/i18n/use-i18n";
import { useFetchPerformanceReviewRatingsQuery } from "~/pages/api/performance-review/fetch-performance-review-ratings";

type Props = {
  value: number | null | undefined;
  companyId: number;
  onChange: (performanceRatingReviewId: number | null) => void;
};

export const PerformanceReviewRatingSelector: React.FC<Props & Omit<SelectProps, "onChange" | "value">> = ({
  companyId,
  onChange,
  ...props
}) => {
  const { t } = useI18n();
  const { data: performanceReviewRatings } = useFetchPerformanceReviewRatingsQuery({
    input: { companyId: companyId },
    options: { placeholderData: [] },
  });

  return (
    <Select
      {...props}
      onChange={(e) => {
        const value = e.target.value as string;

        if (value === "") {
          onChange(null);
        } else {
          onChange(parseInt(value));
        }
      }}
      value={props.value || ""}
    >
      <MenuItem key="none" value="">
        <em>{t("common.none")}</em>
      </MenuItem>

      {performanceReviewRatings.map((rating) => (
        <MenuItem key={rating.id} value={rating.id}>
          {rating.name}
        </MenuItem>
      ))}
    </Select>
  );
};
