import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { identity, isString } from "lodash";
import React, { type ForwardedRef, forwardRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { EmployeePicture } from "~/components/employee/employee-picture";
import { LoadingIndicator } from "~/components/ui/core/LoadingIndicator";
import { SearchForMoreResultsIndicator } from "~/components/ui/core/search-for-more-results-indicator";
import { useI18n } from "~/lib/i18n/use-i18n";
import { useAutocompleteCompanyExternalEmployeesQuery } from "~/pages/api/external-employee/fetch-autocomplete-external-employees-for-company";
import { formatEmployeeName } from "~/services/employee";
import { type AutocompleteCompanyExternalEmployees } from "~/services/external-employee/autocomplete-list-company-external-employees";

// weird but _count is not used in all autocomplete cases
export type EmployeeAutocompleteRow = Omit<AutocompleteCompanyExternalEmployees[number], "_count"> & {
  _count?: {
    managees: number;
  };
};

type Props = {
  onChange?: (event: EmployeeAutocompleteRow | null) => void;
  value: EmployeeAutocompleteRow | null;
  excludedValues?: EmployeeAutocompleteRow[];
  placeholder?: string;
  suggestion?: Pick<EmployeeAutocompleteRow, "email" | "firstName" | "lastName">;
  disabled?: boolean;
  searchPlaceholder?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => JSX.Element;
  companyId: number;
};

export const ExternalEmployeeAutocomplete: React.FC<Props> = ({
  onChange,
  value,
  companyId,
  disabled = false,
  renderInput,
  suggestion,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(suggestion?.lastName ?? "");
  const [debouncedSearch] = useDebounce(search, 300);
  const { t } = useI18n();

  const { data, isLoading } = useAutocompleteCompanyExternalEmployeesQuery({
    options: {
      enabled: isOpen,
    },
    input: {
      companyId,
      query: debouncedSearch,
      pagination: { page: 1, limit: 25 },
    },
  });
  const externalEmployees = data?.externalEmployees ?? [];
  const disableAutocomplete = disabled || (externalEmployees.length === 0 && !isLoading && !isOpen && search === "");

  const suggestedExternalEmployee = externalEmployees.find((employee) => {
    return (
      suggestion &&
      (employee.email === suggestion.email ||
        employee.firstName === suggestion.firstName ||
        employee.lastName === suggestion.lastName)
    );
  });

  const defaultRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} placeholder={t("components.ui.external-employee-autocomplete.select-an-employee")} />
  );

  return (
    <Autocomplete
      disabled={disableAutocomplete}
      value={value}
      onChange={(_, value) => {
        onChange?.(value);
      }}
      onInputChange={(_, value) => setSearch(value)}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      freeSolo={false}
      multiple={false}
      options={externalEmployees}
      groupBy={(option) => (option.id === suggestedExternalEmployee?.id ? "Suggestion" : "All Employees")}
      isOptionEqualToValue={(data, value) => data.id === value.id}
      renderOption={(props, employee) => {
        return (
          <MenuItem {...props} className="px-2">
            <Stack direction="row" gap={4} alignItems="center">
              <EmployeePicture
                className="shrink-0"
                picture={employee.picture}
                placeholderId={employee.id}
                pictureUrl={employee?.userPermissions?.user?.profilePictureUrl}
              />
              <Stack>
                <Typography noWrap>{formatEmployeeName(employee)}</Typography>
                <Typography noWrap variant="caption" color="text.secondary">
                  {employee.job?.name ?? t("components.ui.external-employee-autocomplete.no-job")} -{" "}
                  {employee.level?.name ?? t("components.ui.external-employee-autocomplete.no-level")}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>
        );
      }}
      noOptionsText={
        isLoading ? <LoadingIndicator size="xs" /> : t("components.ui.external-employee-autocomplete.no-results")
      }
      getOptionLabel={(option) => {
        return isString(option) ? option : formatEmployeeName(option);
      }}
      filterOptions={identity}
      renderInput={renderInput ?? defaultRenderInput}
      ListboxComponent={forwardRef(function ListboxComponent(props, ref: ForwardedRef<HTMLUListElement>) {
        return <SearchForMoreResultsIndicator props={props} ref={ref} search={search} />;
      })}
    />
  );
};
