import { UserRole } from "@prisma/client";
import { orderBy } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { ensure } from "~/lib/ensure";
import { getRequiredUser } from "~/lib/get-required-user";
import { type FetchCompensationReviewAdminsInput } from "~/pages/api/compensation-review/fetch-compensation-review-admins";
import { type CompensationReviewContext } from "~/services/compensation-review/compensation-review-context";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensation-review-scope";

export const fetchCompensationReviewAdmins = async (
  ctx: CompensationReviewContext,
  input: FetchCompensationReviewAdminsInput
) => {
  const user = getRequiredUser(ctx);

  ensure(() => ctx.permissions.canAccessConfiguration);

  const figuresAdmins = await ctx.prisma.user.findMany({
    where: { companyId: user.companyId, permissions: { role: UserRole.ADMIN }, blockedAt: null },
    select: {
      id: true,
      firstName: true,
      lastName: true,
      email: true,
      locale: true,
      permissions: {
        select: {
          role: true,
          isManager: true,
        },
      },
    },
  });

  const campaignAdmins = await ctx.prisma.compensationReviewAdmin.findMany({
    where: {
      ...prismaCompensationReviewScope(input.scope),
    },
    select: {
      id: true,
      user: {
        select: {
          id: true,
          firstName: true,
          lastName: true,
          email: true,
          locale: true,
          permissions: {
            select: {
              role: true,
              isManager: true,
            },
          },
        },
      },
    },
  });

  return orderBy(
    [
      ...campaignAdmins.map((admin) => ({ ...admin.user, id: admin.id, userId: admin.user.id, isFiguresAdmin: false })),
      ...figuresAdmins.map((user) => ({ ...user, userId: user.id, isFiguresAdmin: true })),
    ],
    ["firstName", "lastName"]
  );
};

export type FetchCompensationReviewAdminsResult = AsyncReturnType<typeof fetchCompensationReviewAdmins>;
