import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { type CreateCustomJobInput } from "~/pages/api/create-custom-job";
export const createCustomJob = async (ctx: AppContext, input: CreateCustomJobInput) => {
  const user = getRequiredUser(ctx);

  const jobs = await ctx.prisma.job.findMany({
    where: {
      id: {
        in: input.jobs.map((item) => {
          return item.job.id;
        }),
      },
    },
  });

  const description =
    `Custom job created by ${user.firstName} ${user.lastName}\n\n` +
    jobs
      .map((job) => {
        const weight =
          input.jobs.find((item) => {
            return item.job.id === job.id;
          })?.weight ?? 1;

        return `· ${job.name} (weight: ${Math.round(weight)})`;
      })
      .join("\n");

  return ctx.prisma.customJob.create({
    data: {
      user: {
        connect: { id: user.id },
      },
      company: {
        connect: { id: user.companyId },
      },
      name: input.name,
      description,
      sharedWithCompany: input.sharedWithCompany,
      items: {
        createMany: {
          data: input.jobs.map((item) => {
            return {
              jobId: item.job.id,
              weight: item.weight,
            };
          }),
        },
      },
    },
  });
};
