import { QueueJobName } from "@prisma/client";
import { type JobWithMetadata } from "pg-boss";
import { type AppContext } from "~/lib/context";
import { type BaseJobData } from "~/lib/queue/base-job-data-schema";
import { createCompensationReviewCampaignEmployeesWorkerService } from "~/workers/create-compensation-review-campaign-employees";
import { createSalaryBandsWorkerService } from "~/workers/create-salary-bands";
import { duplicateSalaryGridForNewVersionWorkerService } from "~/workers/duplicate-salary-grid-for-new-version";
import { importSpreadsheetWorkerService } from "~/workers/import-spreadsheet";
import { postSyncExternalEmployeesWorkerService } from "~/workers/post-sync-external-employees";
import { preSyncExternalEmployeesWorkerService } from "~/workers/pre-sync-external-employees";
import { syncExternalEmployeesWorkerService } from "~/workers/sync-external-employees";
import { syncSalaryRangeEmployeesWorkerService } from "~/workers/synchronise-salary-range-employees";
import { updateSalaryRangeSettingsWorkerService } from "~/workers/update-salary-range-settings";

export type JobPayload<T> = Pick<JobWithMetadata<T>, "id" | "singletonkey" | "data" | "retrycount" | "retrylimit">;

export type WorkerParams<T extends BaseJobData> = {
  jobName: QueueJobName;
  service: WorkerService<T>;
};

export type WorkerService<T extends BaseJobData> = (ctx: AppContext, data: T) => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Workers = { jobName: QueueJobName; service: WorkerService<any> }[];

export const getWorkers = () => {
  const workers: Workers = [];

  const register = <T extends BaseJobData>(params: WorkerParams<T>) => {
    workers.push(params);
  };

  register({
    jobName: QueueJobName.CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES,
    service: createCompensationReviewCampaignEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.CREATE_SALARY_BANDS,
    service: createSalaryBandsWorkerService,
  });

  register({
    jobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
    service: postSyncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES,
    service: preSyncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
    service: syncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.SYNC_SALARY_RANGE_EMPLOYEES,
    service: syncSalaryRangeEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.UPDATE_SALARY_RANGE_SETTINGS,
    service: updateSalaryRangeSettingsWorkerService,
  });

  register({
    jobName: QueueJobName.DUPLICATE_SALARY_GRID_FOR_NEW_VERSION,
    service: duplicateSalaryGridForNewVersionWorkerService,
  });

  register({
    jobName: QueueJobName.IMPORT_SPREADSHEET,
    service: importSpreadsheetWorkerService,
  });

  return workers;
};
