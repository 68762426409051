import { IntegrationSource } from "@prisma/client";
import { type ExternalLink } from "~/components/ui/core/Link";
import { type IconName } from "~/components/ui/icons";

export type UnsupportedIntegrationSource =
  | "ABSENCEIO"
  | "ADPWORKFORCENOW"
  | "BEXIO"
  | "CORNERSTONE"
  | "GUSTO"
  | "HOORAY"
  | "LISTOPAYE"
  | "NEWDEAL"
  | "NIBÉLIS"
  | "ONFOLK"
  | "PAIEPILOTE"
  | "PEOPLESPHERES"
  | "RECRUITEE"
  | "RIPPLING"
  | "UKGREADY"
  | "ZENEGY";

export type SupportedIntegration = {
  icon: IconName;
  isImplemented: true;
  logo: string;
  name: string;
  source: IntegrationSource;
  url: ExternalLink;
};

export type UnsupportedIntegration = {
  icon: IconName;
  isImplemented: false;
  logo: string;
  name: string;
  source: UnsupportedIntegrationSource;
  url: ExternalLink;
};

export const SUPPORTED_INTEGRATIONS: Record<IntegrationSource, SupportedIntegration> = {
  KOMBO_AFAS: {
    icon: "afas",
    isImplemented: true,
    logo: "afas",
    name: "AFAS Software",
    source: IntegrationSource.KOMBO_AFAS,
    url: "https://www.afas.com/",
  },
  ALEXISHR: {
    icon: "alexishr",
    isImplemented: true,
    logo: "alexishr",
    name: "AlexisHR",
    source: IntegrationSource.ALEXISHR,
    url: "https://alexishr.com/",
  },
  BAMBOO: {
    icon: "bamboo",
    isImplemented: true,
    logo: "bamboo",
    name: "BambooHR",
    source: IntegrationSource.BAMBOO,
    url: "https://bamboohr.com",
  },
  KOMBO_BREATHEHR: {
    icon: "breathehr",
    isImplemented: true,
    logo: "breathehr",
    name: "BreatheHR",
    source: IntegrationSource.KOMBO_BREATHEHR,
    url: "https://breathehr.com/",
  },
  KOMBO_CATALYSTONE: {
    icon: "catalystone",
    isImplemented: true,
    logo: "catalystone",
    name: "CatalystOne",
    source: IntegrationSource.KOMBO_CATALYSTONE,
    url: "https://catalystone.com/",
  },
  KOMBO_CEZANNEHR: {
    icon: "cezannehr",
    isImplemented: true,
    logo: "cezannehr",
    name: "CezanneHR",
    source: IntegrationSource.KOMBO_CEZANNEHR,
    url: "https://cezannehr.com/",
  },
  CHARLIEHR: {
    icon: "charliehr",
    isImplemented: true,
    logo: "charliehr",
    name: "CharlieHR",
    source: IntegrationSource.CHARLIEHR,
    url: "https://www.charliehr.com/",
  },
  KOMBO_DATEV: {
    icon: "datev",
    isImplemented: true,
    logo: "datev",
    name: "DATEV LODAS",
    source: IntegrationSource.KOMBO_DATEV,
    url: "https://www.datev.de/",
  },
  KOMBO_DEEL: {
    icon: "deel",
    isImplemented: true,
    logo: "deel",
    name: "Deel",
    source: IntegrationSource.KOMBO_DEEL,
    url: "https://www.deel.com/",
  },
  KOMBO_ENTRAID: {
    icon: "entraid",
    isImplemented: true,
    logo: "entraid",
    name: "Entra ID",
    source: IntegrationSource.KOMBO_ENTRAID,
    url: "https://learn.microsoft.com/fr-fr/entra/identity/",
  },
  KOMBO_EURECIA: {
    icon: "eurecia",
    isImplemented: true,
    logo: "eurecia",
    name: "Eurécia",
    source: IntegrationSource.KOMBO_EURECIA,
    url: "https://www.eurecia.com/",
  },
  FACTORIAL: {
    icon: "factorial",
    isImplemented: true,
    logo: "factorial",
    name: "Factorial",
    source: IntegrationSource.FACTORIAL,
    url: "https://factorialhr.com/",
  },
  KOMBO_GOOGLEWORKSPACE: {
    icon: "googleworkspace",
    isImplemented: true,
    logo: "googleworkspace",
    name: "Google Workspace",
    source: IntegrationSource.KOMBO_GOOGLEWORKSPACE,
    url: "https://workspace.google.com/",
  },
  KOMBO_HAILEYHR: {
    icon: "haileyhr",
    isImplemented: true,
    logo: "haileyhr",
    name: "HaileyHR",
    source: IntegrationSource.KOMBO_HAILEYHR,
    url: "https://www.haileyhr.com/",
  },
  KOMBO_HEAVENHR: {
    icon: "heavenhr",
    isImplemented: true,
    logo: "heavenhr",
    name: "HeavenHR",
    source: IntegrationSource.KOMBO_HEAVENHR,
    url: "https://www.heavenhr.com/",
  },
  HIBOB_DEPRECATED: {
    icon: "hibob",
    isImplemented: true,
    logo: "hibob-deprecated",
    name: "Hibob with API key",
    source: IntegrationSource.HIBOB_DEPRECATED,
    url: "https://www.hibob.com",
  },
  HIBOB: {
    icon: "hibob",
    isImplemented: true,
    logo: "hibob",
    name: "Hibob with service user",
    source: IntegrationSource.HIBOB,
    url: "https://www.hibob.com",
  },
  KOMBO_HIBOB: {
    icon: "hibob",
    isImplemented: true,
    logo: "hibob",
    name: "Hibob with service user",
    source: IntegrationSource.KOMBO_HIBOB,
    url: "https://www.hibob.com",
  },
  KOMBO_HRWORKS: {
    icon: "hrworks",
    isImplemented: true,
    logo: "hrworks",
    name: "HRworks",
    source: IntegrationSource.KOMBO_HRWORKS,
    url: "https://www.hrworks.de/",
  },
  HUMAANS: {
    icon: "humaans",
    isImplemented: true,
    logo: "humaans",
    name: "Humaans",
    source: IntegrationSource.HUMAANS,
    url: "https://humaans.io/",
  },
  KOMBO_IRISCASCADE: {
    icon: "iriscascade",
    isImplemented: true,
    logo: "iriscascade",
    name: "IRIS Cascade",
    source: IntegrationSource.KOMBO_IRISCASCADE,
    url: "https://www.iris.co.uk/",
  },
  KOMBO_KENJO: {
    icon: "kenjo",
    isImplemented: true,
    logo: "kenjo",
    name: "Kenjo",
    source: IntegrationSource.KOMBO_KENJO,
    url: "https://kenjo.io/",
  },
  LUCCA: {
    icon: "lucca",
    isImplemented: true,
    logo: "lucca",
    name: "Lucca",
    source: IntegrationSource.LUCCA,
    url: "https://lucca-hr.com",
  },
  KOMBO_MIRUS: {
    icon: "mirus",
    isImplemented: true,
    logo: "mirus",
    name: "Mirus",
    source: IntegrationSource.KOMBO_MIRUS,
    url: "https://mirus.ch/",
  },
  KOMBO_NMBRS: {
    icon: "nmbrs",
    isImplemented: true,
    logo: "nmbrs",
    name: "NMBRS",
    source: IntegrationSource.KOMBO_NMBRS,
    url: "https://www.nmbrs.com/",
  },
  KOMBO_OFFICIENT: {
    icon: "officient",
    isImplemented: true,
    logo: "officient",
    name: "Officient",
    source: IntegrationSource.KOMBO_OFFICIENT,
    url: "https://www.officient.io/",
  },
  KOMBO_OKTA: {
    icon: "okta",
    isImplemented: true,
    logo: "okta",
    name: "Okta",
    source: IntegrationSource.KOMBO_OKTA,
    url: "https://www.okta.com/",
  },
  KOMBO_ORACLEHCM: {
    icon: "oraclehcm",
    isImplemented: true,
    logo: "oraclehcm",
    name: "Oracle HCM",
    source: IntegrationSource.KOMBO_ORACLEHCM,
    url: "https://www.oracle.com/",
  },
  KOMBO_PAYFIT: {
    icon: "payfit",
    isImplemented: true,
    logo: "payfit",
    name: "Payfit",
    source: IntegrationSource.KOMBO_PAYFIT,
    url: "https://payfit.com/",
  },
  PEOPLEHR: {
    icon: "peoplehr",
    isImplemented: true,
    logo: "peoplehr",
    name: "PeopleHR",
    source: IntegrationSource.PEOPLEHR,
    url: "https://peoplehr.com/",
  },
  PERSONIO: {
    icon: "personio",
    isImplemented: true,
    logo: "personio",
    name: "Personio",
    source: IntegrationSource.PERSONIO,
    url: "https://personio.com",
  },
  KOMBO_PLANDAY: {
    icon: "planday",
    isImplemented: true,
    logo: "planday",
    name: "Planday",
    source: IntegrationSource.KOMBO_PLANDAY,
    url: "https://www.planday.com/",
  },
  KOMBO_REMOTE: {
    icon: "remote",
    isImplemented: true,
    logo: "remote",
    name: "Remote",
    source: IntegrationSource.KOMBO_REMOTE,
    url: "https://remote.com/",
  },
  KOMBO_SAGEHR: {
    icon: "sagehr",
    isImplemented: true,
    logo: "sagehr",
    name: "SageHR",
    source: IntegrationSource.KOMBO_SAGEHR,
    url: "https://sage.hr/",
  },
  SALARYDK: {
    icon: "salarydk",
    isImplemented: true,
    logo: "salarydk",
    name: "Salary.dk",
    source: IntegrationSource.SALARYDK,
    url: "https://salary.dk/",
  },
  KOMBO_SAPLING: {
    icon: "sapling",
    isImplemented: true,
    logo: "sapling",
    name: "Sapling",
    source: IntegrationSource.KOMBO_SAPLING,
    url: "https://www.kallidus.com/sapling-hr/",
  },
  KOMBO_SUCCESSFACTORS: {
    icon: "sapsuccessfactors",
    isImplemented: true,
    logo: "sapsuccessfactors",
    name: "SAP SuccessFactors",
    source: IntegrationSource.KOMBO_SUCCESSFACTORS,
    url: "https://www.sap.com/",
  },
  KOMBO_SESAMEHR: {
    icon: "sesamehr",
    isImplemented: true,
    logo: "sesamehr",
    name: "Sesame HR",
    source: IntegrationSource.KOMBO_SESAMEHR,
    url: "https://sesamehr.com/",
  },
  WORKDAY: {
    icon: "workday",
    isImplemented: true,
    logo: "workday",
    name: "Workday",
    source: IntegrationSource.WORKDAY,
    url: "https://www.workday.com/",
  },
  KOMBO_MANUAL: {
    icon: "manual",
    isImplemented: true,
    logo: "manual",
    name: "Manual integration",
    source: IntegrationSource.KOMBO_MANUAL,
    url: "https://figures.hr/",
  },
} as const;

export const UNSUPPORTED_INTEGRATIONS: Record<UnsupportedIntegrationSource, UnsupportedIntegration> = {
  ABSENCEIO: {
    icon: "absenceio",
    isImplemented: false,
    logo: "absenceio",
    name: "Absence.io",
    source: "ABSENCEIO",
    url: "https://www.absence.io/",
  },
  ADPWORKFORCENOW: {
    icon: "adpworkforcenow",
    isImplemented: false,
    logo: "adpworkforcenow",
    name: "ADP Workforce Now",
    source: "ADPWORKFORCENOW",
    url: "https://www.adp.com/",
  },
  BEXIO: {
    icon: "bexio",
    isImplemented: false,
    logo: "bexio",
    name: "Bexio",
    source: "BEXIO",
    url: "https://www.bexio.com/",
  },
  CORNERSTONE: {
    icon: "cornerstone",
    isImplemented: false,
    logo: "cornerstone",
    name: "Cornerstone",
    source: "CORNERSTONE",
    url: "https://www.cornerstoneondemand.com/",
  },
  GUSTO: {
    icon: "gusto",
    isImplemented: false,
    logo: "gusto",
    name: "Gusto",
    source: "GUSTO",
    url: "https://gusto.com/",
  },
  HOORAY: {
    icon: "hooray",
    isImplemented: false,
    logo: "hooray",
    name: "Hooray",
    source: "HOORAY",
    url: "https://www.hoorayhr.io",
  },
  LISTOPAYE: {
    icon: "listopaye",
    isImplemented: false,
    logo: "listopaye",
    name: "Listo Paye",
    source: "LISTOPAYE",
    url: "https://listopaye.fr/",
  },
  NEWDEAL: {
    icon: "newdeal",
    isImplemented: false,
    logo: "newdeal",
    name: "Newdeal (by Silae)",
    source: "NEWDEAL",
    url: "https://newdeal.io/",
  },
  NIBÉLIS: {
    icon: "nibelis",
    isImplemented: false,
    logo: "nibelis",
    name: "Nibélis",
    source: "NIBÉLIS",
    url: "https://www.nibelis.com/",
  },
  ONFOLK: {
    icon: "onfolk",
    isImplemented: false,
    logo: "onfolk",
    name: "Onfolk",
    source: "ONFOLK",
    url: "https://onfolk.com/",
  },
  PAIEPILOTE: {
    icon: "paiepilote",
    isImplemented: false,
    logo: "paiepilote",
    name: "Paiepilote",
    source: "PAIEPILOTE",
    url: "https://www.paiepilote.fr/",
  },
  PEOPLESPHERES: {
    icon: "peoplespheres",
    isImplemented: false,
    logo: "peoplespheres",
    name: "PeopleSpheres",
    source: "PEOPLESPHERES",
    url: "https://peoplespheres.com/",
  },
  RECRUITEE: {
    icon: "recruitee",
    isImplemented: false,
    logo: "recruitee",
    name: "Recruitee",
    source: "RECRUITEE",
    url: "https://recruitee.com/",
  },
  RIPPLING: {
    icon: "rippling",
    isImplemented: false,
    logo: "rippling",
    name: "Rippling",
    source: "RIPPLING",
    url: "https://www.rippling.com/",
  },
  UKGREADY: {
    icon: "ukgready",
    isImplemented: false,
    logo: "ukgready",
    name: "UKG Ready (ex Kronos)",
    source: "UKGREADY",
    url: "https://www.ukg.com/",
  },
  ZENEGY: {
    icon: "zenegy",
    isImplemented: false,
    logo: "zenegy",
    name: "Zenegy",
    source: "ZENEGY",
    url: "https://zenegy.com/",
  },
} as const;

export const INTEGRATIONS = { ...SUPPORTED_INTEGRATIONS, ...UNSUPPORTED_INTEGRATIONS } as const;
