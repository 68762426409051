import { type Prisma } from "@prisma/client";
import { type Column, Table } from "~/components/ui/core/Table";
import { useMoney } from "~/hooks/useMoney";
import { DateFormats } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";
import { formatEmployeeName } from "~/services/employee";
import { formatLevel } from "~/services/employee/employee-level";
import { formatGender } from "~/services/external-employee/gender";

const selectEmployeeForDiff = {
  employeeNumber: true,
  firstName: true,
  lastName: true,
  gender: true,
  level: true,
  externalLevel: true,
  baseSalary: true,
  onTargetBonus: true,
  fixedBonus: true,
  hireDate: true,
  birthDate: true,
  locationId: true,
  jobId: true,

  currency: { select: { code: true } },
  location: { select: { name: true, country: { select: { name: true } } } },
  job: { select: { name: true } },
} satisfies Prisma.EmployeeSelect;

type EmployeeForDiff = Prisma.EmployeeGetPayload<{ select: typeof selectEmployeeForDiff }>;

type EmployeeDiffProps = {
  employee: EmployeeForDiff;
  liveVersion: EmployeeForDiff;
};

type EmployeeField = {
  name: string;
  map: (employee: EmployeeForDiff) => string | number | boolean | null;
  format: (employee: EmployeeForDiff) => string | number | JSX.Element;
};

export const EmployeeDiff: React.FC<EmployeeDiffProps> = ({ employee, liveVersion }) => {
  const { t, formatDate } = useI18n();
  const money = useMoney(employee.currency);

  const fields: EmployeeField[] = [
    {
      name: "Name",
      map: (employee) => {
        return `${employee.firstName} ${employee.lastName}`;
      },
      format: (employee) => {
        return formatEmployeeName(employee);
      },
    },
    {
      name: "Location",
      map: (employee) => {
        return employee.locationId;
      },
      format: function Cell(employee) {
        return `${employee.location.name}, ${employee.location.country.name}`;
      },
    },
    {
      name: "Gender",
      map: (employee) => {
        return employee.gender;
      },
      format: function Cell(employee) {
        return employee.gender ? formatGender(t, employee.gender) : "∅";
      },
    },
    {
      name: "Job",
      map: (employee) => {
        return employee.jobId;
      },
      format: function Cell(employee) {
        return employee.job.name;
      },
    },
    {
      name: "Level",
      map: (employee) => {
        return employee.level;
      },
      format: function Cell(employee) {
        return formatLevel(t, employee.level);
      },
    },
    {
      name: "Your level",
      map: (employee) => {
        return employee.externalLevel;
      },
      format: function Cell(employee) {
        return employee.externalLevel ?? "∅";
      },
    },
    {
      name: "Base salary",
      map: (employee) => {
        return employee.baseSalary;
      },
      format: (employee) => {
        return money.format(employee.baseSalary);
      },
    },
    {
      name: "On target bonus",
      map: (employee) => {
        return employee.onTargetBonus;
      },
      format: (employee) => {
        return employee.onTargetBonus ? money.format(employee.onTargetBonus) : "∅";
      },
    },
    {
      name: "Fixed bonus",
      map: (employee) => {
        return employee.fixedBonus;
      },
      format: (employee) => {
        return employee.fixedBonus ? money.format(employee.fixedBonus) : "∅";
      },
    },
    {
      name: "Hire Date",
      map: (employee) => {
        return employee.hireDate ? +employee.hireDate : null;
      },
      format: (employee) => {
        return employee.hireDate ? formatDate(employee.hireDate, DateFormats.FULL_DATE) : "∅";
      },
    },
    {
      name: "Birth Date",
      map: (employee) => {
        return employee.birthDate ? +employee.birthDate : null;
      },
      format: (employee) => {
        return employee.birthDate ? formatDate(employee.birthDate, DateFormats.FULL_DATE) : "∅";
      },
    },
  ];

  const modifiedFields = fields.filter((field) => {
    return field.map(employee) !== field.map(liveVersion);
  });

  const columns: Column<EmployeeField>[] = [
    {
      id: "field",
      name: "Field",
      selector: (field) => {
        return field.name;
      },
      format: function Cell(field) {
        return <span className="text-sm text-gray-600">{field.name}</span>;
      },
    },
    {
      id: "currentValue",
      name: "Current value",
      selector: (field) => {
        return field.map(liveVersion);
      },
      format: function Cell(field) {
        return <div className="text-sm text-red-600">{field.format(liveVersion)}</div>;
      },
    },
    {
      id: "separator",
      format: function Cell() {
        return <>&rarr;</>;
      },
    },
    {
      id: "newValue",
      name: "New value",
      selector: (field) => {
        return field.map(employee);
      },
      format: function Cell(field) {
        return <div className="text-sm text-primary-600">{field.format(employee)}</div>;
      },
    },
  ];

  return <Table dense hideTableHead columns={columns} data={modifiedFields} />;
};
